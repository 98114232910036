import { Component, OnInit, inject } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Calibration } from 'src/app/_models/calibration';
import { ErrorObject } from 'src/app/_models/error';
import { FormBase } from 'src/app/_models/form-base';
import { Laboratory } from 'src/app/_models/laboratory';
import { OxygenCalibration } from 'src/app/_models/oxygen-calibration';
import { PhCalibration } from 'src/app/_models/ph-calibration';
import { TabOptions } from 'src/app/_models/tab-options';
import { User } from 'src/app/_models/user';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { CalibrationService } from 'src/app/_services/calibration.service';
import { IconButtonComponent } from '../../_components/buttons/icon-button/icon-button.component';
import { DropdownFormFieldComponent } from '../../_components/forms/dropdown-form-field/dropdown-form-field.component';
import { ButtonComponent } from '../../_components/buttons/button/button.component';
import { MatTooltip } from '@angular/material/tooltip';
import { MatIcon } from '@angular/material/icon';
import { DatePipe } from '@angular/common';
import { OptionTabToggleComponent } from '../../_components/selection-controls/option-tab-toggle/option-tab-toggle.component';
import { LoadingSpinnerComponent } from 'src/app/_components/loading-spinner/loading-spinner.component';

@Component({
  selector: 'app-device-calibrations',
  template: '',
  standalone: true,
})
export class DeviceCalibrationsComponent implements OnInit {
  protected route = inject(ActivatedRoute);
  protected formBuilder = inject(FormBuilder);
  protected authenticationService = inject(AuthenticationService);
  protected calibrationService = inject(CalibrationService);
  protected router = inject(Router);

  // Navigation
  navOptions: TabOptions[];
  deviceId: string;

  // Permissions
  laboratory: Laboratory;
  currentUser: User;

  // Table control variables
  currentFilters = [];
  currentSort: {
    sortBy: string;
    sortDirection: string;
  } = {
    sortBy: 'Name',
    sortDirection: 'asc',
  };

  // Pagination
  pageRequested = 1;
  pageSize = 5;
  totalCount = 0;
  totalPages = 0;
  currentPage = 1;
  hasNext = false;
  hasPrevious = false;

  loading = false;
  error: ErrorObject;

  calibrationsPerPageField = new FormBase({
    key: 'requestsPerPage',
    label: 'Page Size',
    type: 'dropdown',
    placeholder: '',
    disabled: false,
    required: false,
    value: '5', // Set default value to 5
    options: [
      { key: '5', value: '5' },
      { key: '10', value: '10' },
      { key: '25', value: '25' },
      { key: '50', value: '50' },
      { key: '100', value: '100' },
    ],
  });

  tableControls = this.formBuilder.group({
    [this.calibrationsPerPageField.key]: new FormControl(
      this.calibrationsPerPageField.value,
    ),
  });

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      this.deviceId = params.get('id');
    });

    this.currentUser = this.authenticationService.currentUserValue;
    this.laboratory = this.authenticationService.selectedLaboratory;

    this.navOptions = [
      {
        value: 'details',
        labelText: 'Details',
        link: `/device/${this.deviceId}/details`,
      },
      {
        value: 'od-calibrations',
        labelText: 'OD Calibrations',
        link: `/device/${this.deviceId}/od-calibrations`,
      },
      {
        value: 'oxygen-calibrations',
        labelText: 'O2 Calibrations',
        link: `/device/${this.deviceId}/oxygen-calibrations`,
      },
      {
        value: 'ph-calibrations',
        labelText: 'pH Calibrations',
        link: `/device/${this.deviceId}/ph-calibrations`,
      },
    ];

    if (this.calibrationService.paginationCache !== null) {
      this.pageSize = this.calibrationService.paginationCache.pageSize;
      this.pageRequested = this.calibrationService.paginationCache.pageNumber;
      this.tableControls.patchValue({
        [this.calibrationsPerPageField.key]: this.pageSize.toString(),
      });
    }
  }

  getClasses(sortBy: string) {
    // Get classes for sorting
    const active = this.currentSort.sortBy === sortBy ? 'active' : '';
    return [active];
  }

  viewCalibration(
    calibrationId: string,
    calibrationType: 'optical-density' | 'oxygen' | 'ph',
  ) {
    // View calibration
    void this.router.navigate([
      `/calibration/${calibrationType}/${calibrationId}/calibration-data`,
    ]);
  }
}

@Component({
  selector: 'app-od-calibrations',
  templateUrl: './od-calibrations/od-calibrations.component.html',
  styleUrls: ['./device-calibrations.component.scss'],
  standalone: true,
  imports: [
    OptionTabToggleComponent,
    MatIcon,
    MatTooltip,
    ButtonComponent,
    DropdownFormFieldComponent,
    IconButtonComponent,
    DatePipe,
    LoadingSpinnerComponent,
  ],
})
export class OdCalibrationsComponent
  extends DeviceCalibrationsComponent
  implements OnInit {
  protected route = inject(ActivatedRoute);
  protected formBuilder = inject(FormBuilder);
  protected authenticationService = inject(AuthenticationService);
  protected calibrationService = inject(CalibrationService);
  protected router = inject(Router);
  // Calibration vars
  calibrations: Calibration[];

  ngOnInit(): void {
    super.ngOnInit();

    this.getODCalibrations();

    // Subscribe to changes in the page size dropdown
    this.tableControls
      .get(this.calibrationsPerPageField.key)
      .valueChanges.subscribe((numOfCalibrations) => {
        this.pageSize = numOfCalibrations as number;
        // Reset page number to 1
        this.pageRequested = 1;
        this.getODCalibrations();
      });
  }

  getPrevious() {
    // Get previous page
    this.pageRequested = this.currentPage - 1;
    this.getODCalibrations();
  }

  getNext() {
    // Get next page
    this.pageRequested = this.currentPage + 1;
    this.getODCalibrations();
  }

  onSort(sortBy: string) {
    // Sort table by column
    if (this.currentSort.sortBy === sortBy) {
      if (this.currentSort.sortDirection === 'asc') {
        this.currentSort.sortDirection = 'desc';
      } else {
        this.currentSort.sortDirection = 'asc';
      }
    } else {
      this.currentSort.sortBy = sortBy;
      this.currentSort.sortDirection = 'asc';
    }
    this.getODCalibrations();
  }

  getODCalibrations() {
    if (this.loading) return;
    this.loading = true;
    this.error = null;
    // Get OD calibrations
    this.calibrationService
      .getCalibrationsByDeviceId(
        parseInt(this.deviceId),
        this.currentSort.sortBy,
        this.currentSort.sortDirection,
        this.pageSize,
        this.pageRequested,
      )
      .subscribe({
        next: (response) => {
          this.loading = false;
          this.calibrations = response.calibrationList;
          this.totalCount = response.paginationData.TotalCount;
          this.totalPages = response.paginationData.TotalPages;
          this.currentPage = response.paginationData.CurrentPage;
          this.hasNext = response.paginationData.HasNext;
          this.hasPrevious = response.paginationData.HasPrevious;
        },
        error: (error: ErrorObject) => {
          this.loading = false;
          this.error = error;
        },
      });
  }
}

@Component({
  selector: 'app-oxygen-calibrations',
  templateUrl: './oxygen-calibrations/oxygen-calibrations.component.html',
  styleUrls: ['./device-calibrations.component.scss'],
  standalone: true,
  imports: [
    OptionTabToggleComponent,
    MatIcon,
    MatTooltip,
    ButtonComponent,
    DropdownFormFieldComponent,
    IconButtonComponent,
    DatePipe,
    LoadingSpinnerComponent,
  ],
})
export class OxygenCalibrationsComponent
  extends DeviceCalibrationsComponent
  implements OnInit {
  protected route = inject(ActivatedRoute);
  protected formBuilder = inject(FormBuilder);
  protected authenticationService = inject(AuthenticationService);
  protected calibrationService = inject(CalibrationService);
  protected router = inject(Router);
  // Calibration vars
  oxygenCalibrations: OxygenCalibration[];

  ngOnInit(): void {
    super.ngOnInit();

    this.getOxygenCalibrations();

    // Subscribe to changes in the page size dropdown
    this.tableControls
      .get(this.calibrationsPerPageField.key)
      .valueChanges.subscribe((numOfCalibrations) => {
        this.pageSize = numOfCalibrations as number;
        // Reset page number to 1
        this.pageRequested = 1;
        this.getOxygenCalibrations();
      });
  }

  getPrevious() {
    // Get previous page
    this.pageRequested = this.currentPage - 1;
    this.getOxygenCalibrations();
  }

  getNext() {
    // Get next page
    this.pageRequested = this.currentPage + 1;
    this.getOxygenCalibrations();
  }

  onSort(sortBy: string) {
    // Sort table by column
    // Sort table by column
    if (this.currentSort.sortBy === sortBy) {
      if (this.currentSort.sortDirection === 'asc') {
        this.currentSort.sortDirection = 'desc';
      } else {
        this.currentSort.sortDirection = 'asc';
      }
    } else {
      this.currentSort.sortBy = sortBy;
      this.currentSort.sortDirection = 'asc';
    }
    this.getOxygenCalibrations();
  }

  getOxygenCalibrations() {
    if (this.loading) return;
    // Get Oxygen calibrations
    this.loading = true;
    this.error = null;
    // Get Oxygen calibrations
    this.calibrationService
      .getOxygenCalibrationsByDeviceId(
        this.deviceId,
        this.currentSort.sortBy,
        this.currentSort.sortDirection,
        this.pageSize,
        this.pageRequested,
      )
      .subscribe({
        next: (response) => {
          this.loading = false;
          this.oxygenCalibrations = response.calibrationList;
          this.totalCount = response.paginationData.TotalCount;
          this.totalPages = response.paginationData.TotalPages;
          this.currentPage = response.paginationData.CurrentPage;
          this.hasNext = response.paginationData.HasNext;
          this.hasPrevious = response.paginationData.HasPrevious;
        },
        error: (error: ErrorObject) => {
          this.error = error;
        },
      });
  }
}

@Component({
  selector: 'app-ph-calibrations',
  templateUrl: './ph-calibrations/ph-calibrations.component.html',
  styleUrls: ['./device-calibrations.component.scss'],
  standalone: true,
  imports: [
    OptionTabToggleComponent,
    MatIcon,
    MatTooltip,
    ButtonComponent,
    DropdownFormFieldComponent,
    IconButtonComponent,
    DatePipe,
    LoadingSpinnerComponent,
  ],
})
export class PhCalibrationsComponent
  extends DeviceCalibrationsComponent
  implements OnInit {
  protected route = inject(ActivatedRoute);
  protected formBuilder = inject(FormBuilder);
  protected authenticationService = inject(AuthenticationService);
  protected calibrationService = inject(CalibrationService);
  protected router = inject(Router);
  // Calibration vars
  phCalibrations: PhCalibration[];

  ngOnInit(): void {
    super.ngOnInit();
    this.getPhCalibrations();

    // Subscribe to changes in the page size dropdown
    this.tableControls
      .get(this.calibrationsPerPageField.key)
      .valueChanges.subscribe((numOfCalibrations) => {
        this.pageSize = numOfCalibrations as number;
        // Reset page number to 1
        this.pageRequested = 1;
        this.getPhCalibrations();
      });
  }

  getPrevious() {
    // Get previous page
    this.pageRequested = this.currentPage - 1;
    this.getPhCalibrations();
  }

  getNext() {
    // Get next page
    this.pageRequested = this.currentPage + 1;
    this.getPhCalibrations();
  }

  onSort(sortBy: string) {
    // Sort table by column
    if (this.currentSort.sortBy === sortBy) {
      if (this.currentSort.sortDirection === 'asc') {
        this.currentSort.sortDirection = 'desc';
      } else {
        this.currentSort.sortDirection = 'asc';
      }
    } else {
      this.currentSort.sortBy = sortBy;
      this.currentSort.sortDirection = 'asc';
    }
    this.getPhCalibrations();
  }

  getPhCalibrations() {
    if (this.loading) return;
    // Get pH calibrations
    this.loading = true;
    this.error = null;
    // Get pH calibrations
    this.calibrationService
      .getPHCalibrationsByDeviceId(
        this.deviceId,
        this.currentSort.sortBy,
        this.currentSort.sortDirection,
        this.pageSize,
        this.pageRequested,
      )
      .subscribe({
        next: (response) => {
          this.loading = false;
          this.phCalibrations = response.calibrationList;
          this.totalCount = response.paginationData.TotalCount;
          this.totalPages = response.paginationData.TotalPages;
          this.currentPage = response.paginationData.CurrentPage;
          this.hasNext = response.paginationData.HasNext;
          this.hasPrevious = response.paginationData.HasPrevious;
        },
        error: (error: ErrorObject) => {
          this.error = error;
        },
      });
  }
}
