import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatRole',
})
export class FormatRolePipe implements PipeTransform {
  transform(value: string): string {
    if (!value) {
      return ''; // Return empty string if null or empty
    }
    return value
      .replace(/_/g, ' ')
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  }
}

export const formatLabel = (role: string): string => {
  return role
    .replace(/_/g, ' ')
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
};
