<img id="logo" src="assets/img/ogi-logo.png" alt="" />
<div id="page">
  <div id="container">
    <h4>Check your inbox</h4>
    <p>
      We've sent you a link to reset your password. If you didn't receive
      anything press the button below to resend. If the problem persists, please
      contact help&#64;ogibiotec.com.
    </p>
    <form>
      <app-button
        type="submit"
        buttonStyle="standard"
        label="Request another reset link"
        buttonId="reset"
        hasFill="true"
        [routerLink]="'/forgot-password'"
      >
      </app-button>
      <a [routerLink]="'/login'" class="button-styling">Return to login</a>
    </form>
  </div>
</div>
