import { Pipe, PipeTransform } from '@angular/core';
import { Role } from '../_models/role';

@Pipe({
  name: 'roleCheck',
})
export class RoleCheckPipe implements PipeTransform {
  transform(currentRole: Role, prohibitedRoles: Role[]): boolean {
    return prohibitedRoles.includes(currentRole);
  }
}
