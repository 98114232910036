import {
  Component,
  ChangeDetectionStrategy,
  computed,
  input,
  output,
} from '@angular/core';

/**
 * A versatile button component that supports multiple styles and states.
 *
 * @example
 * <app-button
 *   buttonStyle="standard"
 *   label="Click Me"
 *   (buttonClicked)="handleClick()">
 * </app-button>
 */
@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent {
  /** The visual style of the button */
  buttonStyle = input<
    'standard' | 'secondary' | 'positive' | 'negative' | 'yellow' | 'green'
  >('standard');

  /** The HTML button type attribute */
  type = input<'button' | 'submit' | 'reset'>('button');

  /** The text to display on the button */
  label = input<string>('BUTTON');

  /** Optional ID for the button element */
  buttonId = input<string | undefined>(undefined);

  /** CSS class for label size styling */
  labelClass = input<'large' | ''>('');

  /** Whether the button is disabled */
  isDisabled = input<boolean>(false);

  /** Whether the button is in a processing/loading state */
  isProcessing = input<boolean>(false);

  /** Event emitted when the button is clicked */
  buttonClicked = output<void>();

  /**
   * Computed property that generates the CSS classes for the button
   * based on its current state and styling options
   */
  classes = computed(() => {
    const loading = this.isProcessing() ? 'loading-button' : '';
    const disabled = this.isDisabled() ? 'disabled' : '';
    const labelClassValue =
      this.labelClass() === 'large' ? 'button-large' : 'button-text';

    return [
      'button',
      `button--${this.labelClass()}`,
      loading,
      `button--${this.buttonStyle()}`,
      labelClassValue,
      disabled,
    ]
      .filter(Boolean)
      .join(' ');
  });

  /**
   * Handles the button click event and emits the buttonClicked event
   */
  clickButton = (): void => {
    this.buttonClicked.emit();
  };
}
