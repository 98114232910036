<div id="page">
  <div id="container">
    <app-svg-template
      [iconName]="logo"
      width="249"
      height="60"
      customViewBox="0 0 249 60"
    ></app-svg-template>
    <div *ngIf="tokenStatus === 1 && !accountCreated" id="form--container">
      <h5>Nearly there</h5>
      <p class="subtitle1">
        Please enter a password to finish setting up your account.
      </p>
      <p class="subtitle1">
        Password must be at least 12 characters long, contain at least one
        uppercase letter, one lowercase letter, one number, and one special
        character (&#64;#$%^&+!=)
      </p>
      <div class="error">
        <span *ngIf="createUserError?.message">Error: </span
        >{{ createUserError?.message }}
      </div>
      <form [formGroup]="createUserForm" (ngSubmit)="onSubmit()">
        <app-underlined-form-field
          iconName="lock"
          [field]="passwordField"
          [parentForm]="createUserForm"
        ></app-underlined-form-field>
        <app-underlined-form-field
          iconName="lock"
          [field]="confirmPasswordField"
          [parentForm]="createUserForm"
        >
        </app-underlined-form-field>
        <app-button
          type="submit"
          label="Create Account"
          buttonId="submit-btn"
          [isDisabled]="createUserForm.invalid"
          [isProcessing]="isProcessing"
        ></app-button>
      </form>
    </div>
    <div *ngIf="tokenStatus === 1 && accountCreated" id="form--container">
      <h5>Account Created</h5>
      <app-button
        type="button"
        label="Go to dashboard"
        buttonId="dashboard-btn"
        [routerLink]="['/dashboard']"
      ></app-button>
    </div>
    <div *ngIf="tokenStatus === 2" class="validation--error">
      <h5>Something went wrong</h5>
      <div class="error">
        <span *ngIf="validationError?.message">Error: </span
        >{{
          validationError?.message?.message
            ? validationError?.message?.message
            : validationError?.message
        }}
      </div>
    </div>
  </div>
</div>
