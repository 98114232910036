import {
  Component,
  EventEmitter,
  Input,
  Output,
  ChangeDetectionStrategy,
} from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormBase } from 'src/app/_models/form-base';

@Component({
  selector: 'app-radio-button',
  templateUrl: './radio-button.component.html',
  styleUrls: ['./radio-button.component.scss'],
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RadioButtonComponent {
  /**
   * @param {FormBase<string>} field Form field base for radio button group
   * @requires
   */
  @Input()
  field: FormBase<string>;

  /**
   * @param { FormGroup } parentForm Name of the parent form group
   * @requires
   */
  @Input()
  parentForm: FormGroup = new FormGroup({});

  /**
   * @param { string } currentValue The currently selected option from the radio group, if the form is being loaded with API data
   */
  @Input()
  currentValue: string;

  /**
   * @param { string } legendStyle The style of the label
   */
  @Input()
  legendStyle: 'standard' | 'small' = 'standard';

  @Output()
  radioButtonChange: EventEmitter<string> = new EventEmitter<string>();

  updateValue(value: string): void {
    this.radioButtonChange.emit(value);
  }
}
