<section>
  @if (isProcessing) {
    <div id="icon--container">
      <svg
        version="1.1"
        id="tick"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 37 37"
        xml:space="preserve"
        role="img"
      >
        <title>Loading spinner</title>
        <defs>
          <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" stop-color="#0c7bb4" />
            <stop offset="100%" stop-color="#5cba9a" />
          </linearGradient>
        </defs>
        <path
          class="path circ"
          d="
  M30.5,6.5L30.5,6.5c6.6,6.6,6.6,17.4,0,24l0,0c-6.6,6.6-17.4,6.6-24,0l0,0c-6.6-6.6-6.6-17.4,0-24l0,0C13.1-0.2,23.9-0.2,30.5,6.5z"
        />
      </svg>
    </div>
    <h6>{{ label }}</h6>
  }
</section>
