import { Component, OnInit, inject } from '@angular/core';
import {
  Validators,
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { IconNames } from 'src/app/_components/icons/icon-names';
import { MustMatch } from 'src/app/_helpers/must-match.validator';
import { passwordRegex } from 'src/app/_helpers/utils';
import { Checkbox } from 'src/app/_models/checkbox';
import { ErrorObject } from 'src/app/_models/error';
import { FormBase } from 'src/app/_models/form-base';
import { TokenStatus } from 'src/app/_models/token-status';
import { User } from 'src/app/_models/user';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { ButtonComponent } from '../../_components/buttons/button/button.component';
import { MatFormFieldComponent } from 'src/app/_components/forms/mat-form-field/mat-form-field.component';
import { CheckboxComponent } from 'src/app/_components/selection-controls/checkbox/checkbox.component';
import {
  passesLengthValidation,
  passesNumberValidation,
  passesSpecialCharValidation,
  passesUpperCaseValidation,
  passesLowerCaseValidation,
} from 'src/app/_helpers/utils';
import { ValidationRowComponent } from 'src/app/_components/validation-row/validation-row.component';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'app-add-user-confirmation',
  templateUrl: './add-user-confirmation.component.html',
  styleUrls: ['./add-user-confirmation.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    ButtonComponent,
    RouterLink,
    MatFormFieldComponent,
    CheckboxComponent,
    ValidationRowComponent,
    MatIcon,
  ],
})
export class AddUserConfirmationComponent implements OnInit {
  private readonly formBuilder = inject(FormBuilder);
  private readonly route = inject(ActivatedRoute);
  private readonly router = inject(Router);
  private readonly authService = inject(AuthenticationService);

  logo = IconNames.LogoColour;
  createUserError: ErrorObject;
  validationError: ErrorObject;
  token = null;
  tokenStatus = TokenStatus.Validating;
  email: string;
  user: User;
  isProcessing = false;
  accountCreated = false;

  passwordField = new FormBase({
    key: 'password',
    label: 'Password',
    type: 'password',
    placeholder: 'Password',
    disabled: false,
    required: true,
    value: '',
    options: [],
    autocomplete: 'new-password',
  });

  confirmPasswordField = new FormBase({
    key: 'confirmPassword',
    label: 'Confirm Password',
    type: 'password',
    placeholder: 'Re-enter Password',
    disabled: false,
    required: true,
    value: '',
    options: [],
  });

  termsAndConditions = new Checkbox(
    'termsAndConditions',
    'Agree to terms & conditions',
    'done',
  );

  createUserForm = this.formBuilder.group(
    {
      [this.passwordField.key]: [
        '',
        [
          Validators.required,
          Validators.minLength(8),
          Validators.pattern(passwordRegex),
        ],
      ],
      [this.confirmPasswordField.key]: ['', Validators.required],
      [this.termsAndConditions.id]: [false, Validators.required],
    },
    {
      validators: MustMatch(
        this.passwordField.key,
        this.confirmPasswordField.key,
      ),
    },
  );

  ngOnInit(): void {
    this.token = this.route.snapshot.queryParams.invitationToken as string;

    // Remove token from URL to prevent http referrer leak
    void this.router.navigate([], { relativeTo: this.route, replaceUrl: true });

    this.authService
      .validateUserToken(this.token as string)
      .pipe()
      .subscribe({
        next: (response) => {
          this.tokenStatus = TokenStatus.Valid;
          this.user = response;
        },
        error: (error: ErrorObject) => {
          this.tokenStatus = TokenStatus.Invalid;
          this.validationError = error;
        },
      });
  }

  /**
   * @remarks
   * Convenience getter for easy access to form fields
   *
   */
  get f(): FormGroup['controls'] {
    return this.createUserForm.controls;
  }

  passesValidation(validationType: string): boolean {
    switch (validationType) {
      case 'length':
        return passesLengthValidation(this.f.password.value as string);
      case 'uppercaseLetter':
        return passesUpperCaseValidation(this.f.password.value as string);
      case 'lowercaseLetter':
        return passesLowerCaseValidation(this.f.password.value as string);
      case 'number':
        return passesNumberValidation(this.f.password.value as string);
      case 'specialCharacter':
        return passesSpecialCharValidation(this.f.password.value as string);
      default:
        return false;
    }
  }

  onSubmit(): void {
    // If form is already being submitted, exit
    if (this.isProcessing) {
      return;
    }

    this.isProcessing = true;
    const newUser = new User(
      this.user.email,
      this.user.firstName,
      this.user.lastName,
      '',
      this.user.id,
      null,
      this.token as string,
    );
    this.authService
      .createAccount(
        newUser,
        this.f.password.value as string,
        this.f.termsAndConditions.value as boolean,
      )
      .pipe()
      .subscribe({
        next: () => {
          this.isProcessing = false;
          this.accountCreated = true;
        },
        error: (error: ErrorObject) => {
          this.createUserError = error;
          this.isProcessing = false;
        },
      });
  }
}
