<div id="title--container">
  <h4 *ngIf="calibrationType === 'optical-density'">
    Optical Density Calibration
  </h4>
  <app-icon-button
    *ngIf="
      calibrationType === 'optical-density' &&
      odCalibration?.isFinal &&
      !odCalibration?.isDeleted
    "
    label="Delete Calibration"
    buttonId="delete-calibration-btn"
    buttonStyle="negative"
    iconName="delete"
    buttonShape="square"
    (buttonClicked)="openModal('delete-calibration')"
    [isDisabled]="
      currentUser.role
        | roleCheck
          : [Role.Read_Access_User, Role.Run_Access_User, Role.Edit_Access_User]
    "
  ></app-icon-button>
  <app-status-indicator
    *ngIf="calibrationType === 'optical-density' && odCalibration?.isDeleted"
    status="Deleted"
  ></app-status-indicator>
  <h4 *ngIf="calibrationType === 'oxygen'">Oxygen Saturation Calibration</h4>
  <app-icon-button
    *ngIf="
      calibrationType === 'oxygen' &&
      oxygenCalibration?.isFinal &&
      !oxygenCalibration?.isDeleted
    "
    label="Delete Calibration"
    buttonId="delete-calibration-btn"
    buttonStyle="negative"
    iconName="delete"
    buttonShape="square"
    (buttonClicked)="openModal('delete-calibration')"
    [isDisabled]="
      currentUser.role
        | roleCheck
          : [Role.Read_Access_User, Role.Run_Access_User, Role.Edit_Access_User]
    "
  ></app-icon-button>
  <app-status-indicator
    *ngIf="calibrationType === 'oxygen' && oxygenCalibration?.isDeleted"
    status="Deleted"
  ></app-status-indicator>
  <h4 *ngIf="calibrationType === 'ph'">pH Calibration</h4>
  <app-icon-button
    *ngIf="
      calibrationType === 'ph' &&
      phCalibration?.isFinal &&
      !phCalibration?.isDeleted
    "
    label="Delete Calibration"
    buttonId="delete-calibration-btn"
    buttonStyle="negative"
    iconName="delete"
    buttonShape="square"
    (buttonClicked)="openModal('delete-calibration')"
    [isDisabled]="
      currentUser.role
        | roleCheck
          : [Role.Read_Access_User, Role.Run_Access_User, Role.Edit_Access_User]
    "
  ></app-icon-button>
  <app-status-indicator
    *ngIf="calibrationType === 'ph' && phCalibration?.isDeleted"
    status="Deleted"
  ></app-status-indicator>
</div>
<hr />
<div id="calibration--container">
  <div id="calibration-data--container">
    <div class="underlined-text--container">
      <p class="label">Date</p>
      <p *ngIf="calibrationType === 'optical-density'">
        {{
          odCalibration?.createdAt | date : "HH:mm:ss, dd/MM/yy" || "loading"
        }}
      </p>
      <p *ngIf="calibrationType === 'oxygen'">
        {{
          oxygenCalibration?.createdAt
            | date : "HH:mm:ss, dd/MM/yy" || "loading"
        }}
      </p>
      <p *ngIf="calibrationType === 'ph'">
        {{
          phCalibration?.createdAt | date : "HH:mm:ss, dd/MM/yy" || "loading"
        }}
      </p>
    </div>
    <div class="underlined-text--container">
      <p class="label">Calibration Name</p>
      <p *ngIf="calibrationType === 'optical-density'">
        {{ odCalibration?.name || "loading" }}
      </p>
      <p *ngIf="calibrationType === 'oxygen'">
        {{ oxygenCalibration?.name || "loading" }}
      </p>
      <p *ngIf="calibrationType === 'ph'">
        {{ phCalibration?.name || "loading" }}
      </p>
    </div>
    <div class="underlined-text--container">
      <p class="label">Save Location</p>
      <p *ngIf="calibrationType === 'optical-density'">
        {{ odCalibration?.saveLocationName || "loading" }}
      </p>
      <p *ngIf="calibrationType === 'oxygen'">
        {{ oxygenCalibration?.saveLocationName || "loading" }}
      </p>
      <p *ngIf="calibrationType === 'ph'">
        {{ phCalibration?.saveLocationName || "loading" }}
      </p>
    </div>
    <div class="underlined-text--container">
      <p class="label">Is calibration complete?</p>
      <p *ngIf="calibrationType === 'optical-density'">
        {{ odCalibration?.isFinal ? "Yes" : "No" }}
      </p>
      <p *ngIf="calibrationType === 'oxygen'">
        {{ oxygenCalibration?.isFinal ? "Yes" : "No" }}
      </p>
      <p *ngIf="calibrationType === 'ph'">
        {{ phCalibration?.isFinal ? "Yes" : "No" }}
      </p>
    </div>
    <app-icon-button
      *ngIf="calibrationType === 'optical-density'"
      [label]="calibrationLabel"
      buttonId="poll-cal-btn"
      (buttonClicked)="startCalibrationPolling()"
      [isProcessing]="calibrationPollingShouldContinue"
      iconName="autorenew"
      buttonShape="square"
      buttonStyle="outlined"
      [isDisabled]="
        odCalibration?.isFinal ||
        calibrationPollingSubscription !== null ||
        calibrationPollingShouldContinue
      "
    ></app-icon-button>
    <app-button
      *ngIf="calibrationType === 'optical-density'"
      label="Stop Polling"
      buttonId="stop-cal-polling-btn"
      [isDisabled]="calibrationPollingSubscription === null"
      (buttonClicked)="stopCalibrationPolling()"
    >
    </app-button>
    <app-icon-button
      *ngIf="calibrationType === 'oxygen'"
      [label]="calibrationLabel"
      buttonId="poll-cal-btn"
      (buttonClicked)="startOxygenPolling()"
      [isProcessing]="oxygenPollingShouldContinue"
      iconName="autorenew"
      buttonShape="square"
      buttonStyle="outlined"
      [isDisabled]="
        oxygenCalibration?.isFinal ||
        oxygenPollingSubscription !== null ||
        oxygenPollingShouldContinue
      "
    ></app-icon-button>
    <app-button
      *ngIf="calibrationType === 'oxygen'"
      label="Stop Polling"
      buttonId="stop-cal-polling-btn"
      [isDisabled]="oxygenPollingSubscription === null"
      (buttonClicked)="stopOxygenPolling()"
    >
    </app-button>
    <app-icon-button
      *ngIf="calibrationType === 'ph'"
      [label]="calibrationLabel"
      buttonId="poll-cal-btn"
      (buttonClicked)="startPHPolling()"
      [isProcessing]="phPollingShouldContinue"
      iconName="autorenew"
      buttonShape="square"
      buttonStyle="outlined"
      [isDisabled]="
        phCalibration?.isFinal ||
        phPollingSubscription !== null ||
        phPollingShouldContinue
      "
    ></app-icon-button>
    <app-button
      *ngIf="calibrationType === 'ph'"
      label="Stop Polling"
      buttonId="stop-cal-polling-btn"
      [isDisabled]="phPollingSubscription === null"
      (buttonClicked)="stopPHPolling()"
    >
    </app-button>
  </div>
  <hr />
  <p id="table-description" style="display: none">Calibration results table</p>

  <div *ngIf="calibrationType === 'optical-density'" class="section--container">
    <div *ngIf="odCalibration?.isFinal" class="subtitle1">
      Results
      <table aria-describedby="table-description">
        <thead>
          <tr>
            <th id="flasks" scope="col">Flasks</th>
            <th id="calibration-factor" scope="col">Calibration Factor</th>
            <th id="calibration-offset" scope="col">Calibration Offset</th>
            <th id="calibration-power" scope="col">Calibration Power</th>
            <th id="r-squared" scope="col">R<sup>2</sup></th>
            <th id="temperature" scope="col">Temperature</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td headers="flasks">A</td>
            <td headers="calibration-factor">
              {{ odCalibration?.isFinal ? odCalibration?.calFactor_A : "-" }}
            </td>
            <td headers="calibration-offset">
              {{ odCalibration?.isFinal ? odCalibration?.calOffset_A : "-" }}
            </td>
            <td headers="calibration-power">
              {{ odCalibration?.isFinal ? odCalibration?.calPower_A : "-" }}
            </td>
            <td headers="r-squared">
              {{ odCalibration?.isFinal ? odCalibration?.r2_A : "-" }}
            </td>
            <td headers="temperature">
              {{
                odCalibration?.isFinal
                  ? odCalibration?.resultsTemperatureA
                  : "-"
              }}
            </td>
          </tr>
          <tr>
            <td headers="flasks">B</td>
            <td headers="calibration-factor">
              {{ odCalibration?.isFinal ? odCalibration?.calFactor_B : "-" }}
            </td>
            <td headers="calibration-offset">
              {{ odCalibration?.isFinal ? odCalibration?.calOffset_B : "-" }}
            </td>
            <td headers="calibration-power">
              {{ odCalibration?.isFinal ? odCalibration?.calPower_B : "-" }}
            </td>
            <td headers="r-squared">
              {{ odCalibration?.isFinal ? odCalibration?.r2_B : "-" }}
            </td>
            <td headers="temperature">
              {{
                odCalibration?.isFinal
                  ? odCalibration?.resultsTemperatureB
                  : "-"
              }}
            </td>
          </tr>
          <tr>
            <td headers="flasks">C</td>
            <td headers="calibration-factor">
              {{ odCalibration?.isFinal ? odCalibration?.calFactor_C : "-" }}
            </td>
            <td headers="calibration-offset">
              {{ odCalibration?.isFinal ? odCalibration?.calOffset_C : "-" }}
            </td>
            <td headers="calibration-power">
              {{ odCalibration?.isFinal ? odCalibration?.calPower_C : "-" }}
            </td>
            <td headers="r-squared">
              {{ odCalibration?.isFinal ? odCalibration?.r2_C : "-" }}
            </td>
            <td headers="temperature">
              {{
                odCalibration?.isFinal
                  ? odCalibration?.resultsTemperatureC
                  : "-"
              }}
            </td>
          </tr>
          <tr>
            <td headers="flasks">D</td>
            <td headers="calibration-factor">
              {{ odCalibration?.isFinal ? odCalibration?.calFactor_D : "-" }}
            </td>
            <td headers="calibration-offset">
              {{ odCalibration?.isFinal ? odCalibration?.calOffset_D : "-" }}
            </td>
            <td headers="calibration-power">
              {{ odCalibration?.isFinal ? odCalibration?.calPower_D : "-" }}
            </td>
            <td headers="r-squared">
              {{ odCalibration?.isFinal ? odCalibration?.r2_D : "-" }}
            </td>
            <td headers="temperature">
              {{
                odCalibration?.isFinal
                  ? odCalibration?.resultsTemperatureD
                  : "-"
              }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div *ngIf="!odCalibration?.isFinal" class="subtitle1">
      <div class="finalise--container">
        <p class="subtitle1">
          If finalising your calibration, you may input up to 6 values for each
          reactor below
        </p>
        <div class="button--container">
          <app-icon-button
            [label]="voltageLabel"
            buttonId="poll-voltage-btn"
            (buttonClicked)="getVoltageValues()"
            [isProcessing]="voltagePollingShouldContinue"
            iconName="autorenew"
            buttonShape="square"
            buttonStyle="outlined"
            [isDisabled]="
              odCalibration?.isFinal ||
              voltageReadings !== null ||
              voltagePollingShouldContinue ||
              calibrationPollingSubscription !== null
            "
          ></app-icon-button>
          <app-button
            label="Stop Polling"
            buttonId="stop-voltage-polling-btn"
            [isDisabled]="voltagePollingSubscription === null"
            (buttonClicked)="stopVoltagePolling()"
          >
          </app-button>
          <app-button
            *ngIf="!hasSentFinalisationSignal"
            label="Finalise Calibration"
            buttonId="finalise-calibration-btn"
            [isDisabled]="
              odCalibration?.isFinal ||
              (currentUser.role
                | roleCheck : [Role.Read_Access_User, Role.Run_Access_User]) ||
              finalisationIsProcessing
            "
            (buttonClicked)="finaliseCalibration()"
            [isProcessing]="finalisationIsProcessing"
          ></app-button>
          <span *ngIf="hasSentFinalisationSignal" class="body2">
            Finalise message has been sent to device
          </span>
          <span *ngIf="finalisationError" class="error">
            {{ finalisationError.message }}
          </span>
        </div>
      </div>
      <mat-accordion class="example-headers-align" multi>
        <mat-expansion-panel [expanded]="false">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <h6>Reactor A Input Values</h6>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="flask--container">
            <div>
              Voltage: {{ voltageReadings?.flaskAVoltageValue1 }}
              <app-underlined-form-field
                [parentForm]="odCalibrationForm"
                [field]="flaskAValue1Field"
              ></app-underlined-form-field>
            </div>
            <div>
              Voltage: {{ voltageReadings?.flaskAVoltageValue2 }}
              <app-underlined-form-field
                [parentForm]="odCalibrationForm"
                [field]="flaskAValue2Field"
              ></app-underlined-form-field>
            </div>
            <div>
              Voltage: {{ voltageReadings?.flaskAVoltageValue3 }}
              <app-underlined-form-field
                [parentForm]="odCalibrationForm"
                [field]="flaskAValue3Field"
              ></app-underlined-form-field>
            </div>
            <div>
              Voltage: {{ voltageReadings?.flaskAVoltageValue4 }}
              <app-underlined-form-field
                [parentForm]="odCalibrationForm"
                [field]="flaskAValue4Field"
              ></app-underlined-form-field>
            </div>
            <div>
              Voltage: {{ voltageReadings?.flaskAVoltageValue5 }}
              <app-underlined-form-field
                [parentForm]="odCalibrationForm"
                [field]="flaskAValue5Field"
              ></app-underlined-form-field>
            </div>
            <div>
              Voltage: {{ voltageReadings?.flaskAVoltageValue6 }}
              <app-underlined-form-field
                [parentForm]="odCalibrationForm"
                [field]="flaskAValue6Field"
              ></app-underlined-form-field>
            </div>
          </div>
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="false">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <h6>Reactor B Input Values</h6>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="flask--container">
            <div>
              Voltage: {{ voltageReadings?.flaskBVoltageValue1 }}
              <app-underlined-form-field
                [parentForm]="odCalibrationForm"
                [field]="flaskBValue1Field"
              ></app-underlined-form-field>
            </div>
            <div>
              Voltage: {{ voltageReadings?.flaskBVoltageValue2 }}
              <app-underlined-form-field
                [parentForm]="odCalibrationForm"
                [field]="flaskBValue2Field"
              ></app-underlined-form-field>
            </div>
            <div>
              Voltage: {{ voltageReadings?.flaskBVoltageValue3 }}
              <app-underlined-form-field
                [parentForm]="odCalibrationForm"
                [field]="flaskBValue3Field"
              ></app-underlined-form-field>
            </div>
            <div>
              Voltage: {{ voltageReadings?.flaskBVoltageValue4 }}
              <app-underlined-form-field
                [parentForm]="odCalibrationForm"
                [field]="flaskBValue4Field"
              ></app-underlined-form-field>
            </div>
            <div>
              Voltage: {{ voltageReadings?.flaskBVoltageValue5 }}
              <app-underlined-form-field
                [parentForm]="odCalibrationForm"
                [field]="flaskBValue5Field"
              ></app-underlined-form-field>
            </div>
            <div>
              Voltage: {{ voltageReadings?.flaskBVoltageValue6 }}
              <app-underlined-form-field
                [parentForm]="odCalibrationForm"
                [field]="flaskBValue6Field"
              ></app-underlined-form-field>
            </div>
          </div>
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="false">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <h6>Reactor C Input Values</h6>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="flask--container">
            <div>
              Voltage: {{ voltageReadings?.flaskCVoltageValue1 }}
              <app-underlined-form-field
                [parentForm]="odCalibrationForm"
                [field]="flaskCValue1Field"
              ></app-underlined-form-field>
            </div>
            <div>
              Voltage: {{ voltageReadings?.flaskCVoltageValue2 }}
              <app-underlined-form-field
                [parentForm]="odCalibrationForm"
                [field]="flaskCValue2Field"
              ></app-underlined-form-field>
            </div>
            <div>
              Voltage: {{ voltageReadings?.flaskCVoltageValue3 }}
              <app-underlined-form-field
                [parentForm]="odCalibrationForm"
                [field]="flaskCValue3Field"
              ></app-underlined-form-field>
            </div>
            <div>
              Voltage: {{ voltageReadings?.flaskCVoltageValue4 }}
              <app-underlined-form-field
                [parentForm]="odCalibrationForm"
                [field]="flaskCValue4Field"
              ></app-underlined-form-field>
            </div>
            <div>
              Voltage: {{ voltageReadings?.flaskCVoltageValue5 }}
              <app-underlined-form-field
                [parentForm]="odCalibrationForm"
                [field]="flaskCValue5Field"
              ></app-underlined-form-field>
            </div>
            <div>
              Voltage: {{ voltageReadings?.flaskCVoltageValue6 }}
              <app-underlined-form-field
                [parentForm]="odCalibrationForm"
                [field]="flaskCValue6Field"
              ></app-underlined-form-field>
            </div>
          </div>
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="false">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <h6>Reactor D Input Values</h6>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="flask--container">
            <div>
              Voltage: {{ voltageReadings?.flaskDVoltageValue1 }}
              <app-underlined-form-field
                [parentForm]="odCalibrationForm"
                [field]="flaskDValue1Field"
              ></app-underlined-form-field>
            </div>
            <div>
              Voltage: {{ voltageReadings?.flaskDVoltageValue2 }}
              <app-underlined-form-field
                [parentForm]="odCalibrationForm"
                [field]="flaskDValue2Field"
              ></app-underlined-form-field>
            </div>
            <div>
              Voltage: {{ voltageReadings?.flaskDVoltageValue3 }}
              <app-underlined-form-field
                [parentForm]="odCalibrationForm"
                [field]="flaskDValue3Field"
              ></app-underlined-form-field>
            </div>
            <div>
              Voltage: {{ voltageReadings?.flaskDVoltageValue4 }}
              <app-underlined-form-field
                [parentForm]="odCalibrationForm"
                [field]="flaskDValue4Field"
              ></app-underlined-form-field>
            </div>
            <div>
              Voltage: {{ voltageReadings?.flaskDVoltageValue5 }}
              <app-underlined-form-field
                [parentForm]="odCalibrationForm"
                [field]="flaskDValue5Field"
              ></app-underlined-form-field>
            </div>
            <div>
              Voltage: {{ voltageReadings?.flaskDVoltageValue6 }}
              <app-underlined-form-field
                [parentForm]="odCalibrationForm"
                [field]="flaskDValue6Field"
              ></app-underlined-form-field>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
  <div *ngIf="calibrationType === 'oxygen'" class="section--container">
    <table aria-describedby="table-description">
      <thead>
        <tr>
          <th id="flasks" scope="col">Flasks</th>
          <th id="dphi" scope="col">dphi</th>
          <th id="temperature" scope="col">Temperature</th>
          <th id="pressure" scope="col">Pressure</th>
          <th id="has-error" scope="col">Error</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td headers="flasks">A</td>
          <td headers="dphi">
            {{
              oxygenCalibration?.isFinal
                ? oxygenSaturation === 0
                  ? oxygenCalibration?.dphi_0_A
                  : oxygenCalibration?.dphi_100_A
                : "-"
            }}
          </td>
          <td headers="temperature">
            {{
              oxygenCalibration?.isFinal
                ? oxygenSaturation === 0
                  ? oxygenCalibration?.temperature_0_A
                  : oxygenCalibration?.temperature_100_A
                : "-"
            }}
          </td>
          <td headers="pressure">
            {{
              oxygenCalibration?.isFinal
                ? oxygenSaturation === 0
                  ? oxygenCalibration?.pressure_0_A
                  : oxygenCalibration?.pressure_100_A
                : "-"
            }}
          </td>
          <td headers="has-error">
            {{
              oxygenCalibration?.isFinal
                ? oxygenSaturation === 0
                  ? oxygenCalibration?.hasError_0_A
                  : oxygenCalibration?.hasError_100_A
                : "-"
            }}
          </td>
        </tr>
        <tr>
          <td headers="flasks">B</td>
          <td headers="dphi">
            {{
              oxygenCalibration?.isFinal
                ? oxygenSaturation === 0
                  ? oxygenCalibration?.dphi_0_B
                  : oxygenCalibration?.dphi_100_B
                : "-"
            }}
          </td>
          <td headers="temperature">
            {{
              oxygenCalibration?.isFinal
                ? oxygenSaturation === 0
                  ? oxygenCalibration?.temperature_0_B
                  : oxygenCalibration?.temperature_100_B
                : "-"
            }}
          </td>
          <td headers="pressure">
            {{
              oxygenCalibration?.isFinal
                ? oxygenSaturation === 0
                  ? oxygenCalibration?.pressure_0_B
                  : oxygenCalibration?.pressure_100_B
                : "-"
            }}
          </td>
          <td headers="has-error">
            {{
              oxygenCalibration?.isFinal
                ? oxygenSaturation === 0
                  ? oxygenCalibration?.hasError_0_B
                  : oxygenCalibration?.hasError_100_B
                : "-"
            }}
          </td>
        </tr>
        <tr>
          <td headers="flasks">C</td>
          <td headers="dphi">
            {{
              oxygenCalibration?.isFinal
                ? oxygenSaturation === 0
                  ? oxygenCalibration?.dphi_0_C
                  : oxygenCalibration?.dphi_100_C
                : "-"
            }}
          </td>
          <td headers="temperature">
            {{
              oxygenCalibration?.isFinal
                ? oxygenSaturation === 0
                  ? oxygenCalibration?.temperature_0_C
                  : oxygenCalibration?.temperature_100_C
                : "-"
            }}
          </td>
          <td headers="pressure">
            {{
              oxygenCalibration?.isFinal
                ? oxygenSaturation === 0
                  ? oxygenCalibration?.pressure_0_C
                  : oxygenCalibration?.pressure_100_C
                : "-"
            }}
          </td>
          <td headers="has-error">
            {{
              oxygenCalibration?.isFinal
                ? oxygenSaturation === 0
                  ? oxygenCalibration?.hasError_0_C
                  : oxygenCalibration?.hasError_100_C
                : "-"
            }}
          </td>
        </tr>
        <tr>
          <td headers="flasks">D</td>
          <td headers="dphi">
            {{
              oxygenCalibration?.isFinal
                ? oxygenSaturation === 0
                  ? oxygenCalibration?.dphi_0_D
                  : oxygenCalibration?.dphi_100_D
                : "-"
            }}
          </td>
          <td headers="temperature">
            {{
              oxygenCalibration?.isFinal
                ? oxygenSaturation === 0
                  ? oxygenCalibration?.temperature_0_D
                  : oxygenCalibration?.temperature_100_D
                : "-"
            }}
          </td>
          <td headers="pressure">
            {{
              oxygenCalibration?.isFinal
                ? oxygenSaturation === 0
                  ? oxygenCalibration?.pressure_0_D
                  : oxygenCalibration?.pressure_100_D
                : "-"
            }}
          </td>
          <td headers="has-error">
            {{
              oxygenCalibration?.isFinal
                ? oxygenSaturation === 0
                  ? oxygenCalibration?.hasError_0_D
                  : oxygenCalibration?.hasError_100_D
                : "-"
            }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div *ngIf="calibrationType === 'ph'" class="section--container">
    <table aria-describedby="table-description">
      <thead>
        <tr>
          <th id="ph-flasks" scope="col">Flasks</th>
          <th id="slope" scope="col">Slope [%]</th>
          <th id="offset" scope="col">Offset [mV]</th>
          <th id="ph-calibration-factor" scope="col">Calibration Factor</th>
          <th id="ph-calibration-offset" scope="col">Calibration Offset</th>
          <th id="ph-r-squared" scope="col">R<sup>2</sup></th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td headers="ph-flasks">A</td>
          <td headers="slope">
            {{ phCalibration?.isFinal ? phCalibration?.slope_A : "-" }}
          </td>
          <td headers="offset">
            {{ phCalibration?.isFinal ? phCalibration?.offset_A : "-" }}
          </td>
          <td headers="ph-calibration-factor">
            {{ phCalibration?.isFinal ? phCalibration?.calFactor_A : "-" }}
          </td>
          <td headers="ph-calibration-offset">
            {{ phCalibration?.isFinal ? phCalibration?.calOffset_A : "-" }}
          </td>
          <td headers="ph-r-squared">
            {{ phCalibration?.isFinal ? phCalibration?.r2_A : "-" }}
          </td>
        </tr>
        <tr>
          <td headers="ph-flasks">B</td>
          <td headers="slope">
            {{ phCalibration?.isFinal ? phCalibration?.slope_B : "-" }}
          </td>
          <td headers="offset">
            {{ phCalibration?.isFinal ? phCalibration?.offset_B : "-" }}
          </td>
          <td headers="ph-calibration-factor">
            {{ phCalibration?.isFinal ? phCalibration?.calFactor_B : "-" }}
          </td>
          <td headers="ph-calibration-offset">
            {{ phCalibration?.isFinal ? phCalibration?.calOffset_B : "-" }}
          </td>
          <td headers="ph-r-squared">
            {{ phCalibration?.isFinal ? phCalibration?.r2_B : "-" }}
          </td>
        </tr>
        <tr>
          <td headers="ph-flasks">C</td>
          <td headers="slope">
            {{ phCalibration?.isFinal ? phCalibration?.slope_C : "-" }}
          </td>
          <td headers="offset">
            {{ phCalibration?.isFinal ? phCalibration?.offset_C : "-" }}
          </td>
          <td headers="ph-calibration-factor">
            {{ phCalibration?.isFinal ? phCalibration?.calFactor_C : "-" }}
          </td>
          <td headers="ph-calibration-offset">
            {{ phCalibration?.isFinal ? phCalibration?.calOffset_C : "-" }}
          </td>
          <td headers="ph-r-squared">
            {{ phCalibration?.isFinal ? phCalibration?.r2_C : "-" }}
          </td>
        </tr>
        <tr>
          <td headers="ph-flasks">D</td>
          <td headers="slope">
            {{ phCalibration?.isFinal ? phCalibration?.slope_D : "-" }}
          </td>
          <td headers="offset">
            {{ phCalibration?.isFinal ? phCalibration?.offset_D : "-" }}
          </td>
          <td headers="ph-calibration-factor">
            {{ phCalibration?.isFinal ? phCalibration?.calFactor_D : "-" }}
          </td>
          <td headers="ph-calibration-offset">
            {{ phCalibration?.isFinal ? phCalibration?.calOffset_D : "-" }}
          </td>
          <td headers="ph-r-squared">
            {{ phCalibration?.isFinal ? phCalibration?.r2_D : "-" }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div *ngIf="calibrationType === 'optical-density'" class="section--container">
    <div class="subtitle1">
      Data last updated: {{ timeOfLastUpdate | date : "HH:mm:ss, dd/MM/yy" }}
    </div>
    <app-calibration-graph
      *ngIf="renderCalibrationGraph; else loadingOD"
      [calibrationData]="calibrationData"
      [graphId]="'calibration-graph'"
      [yAxisLabel]="'Optical Density'"
    >
    </app-calibration-graph>
    <ng-template #loadingOD>
      <div class="loading--container">
        <app-loading-spinner
          *ngIf="dataIsProcessing"
          [label]="calibrationLoadingText"
        ></app-loading-spinner>
        <div *ngIf="dataError">{{ dataError.message }}</div>
      </div>
    </ng-template>
    <div class="button--container">
      <app-button
        *ngIf="!odCalibration?.isFinal"
        label="discard calibration"
        buttonStyle="negative"
        buttonId="discard-calibration-btn"
        [isDisabled]="
          odCalibration?.isFinal ||
          (currentUser.role
            | roleCheck : [Role.Read_Access_User, Role.Run_Access_User])
        "
        (buttonClicked)="openModal('confirm-discard')"
      ></app-button>
    </div>
  </div>
  <div *ngIf="calibrationType === 'oxygen'" class="section--container">
    <div class="subtitle1">
      Data last updated: {{ timeOfLastUpdate | date : "HH:mm:ss, dd/MM/yy" }}
    </div>
    <div class="selection--container">
      <app-radio-button
        id="toggle--container"
        [field]="saturationToggle"
        [parentForm]="selectSaturationForm"
        [currentValue]="
          selectSaturationForm?.controls[saturationToggle.key]?.value
        "
        type="round"
        (radioButtonChange)="
          handleOxygenSaturationToggle(
            selectSaturationForm?.controls[saturationToggle.key]?.value
          )
        "
      ></app-radio-button>
    </div>
    <app-oxygen-calibration-graph
      *ngIf="renderCalibrationGraph; else loadingOxygen"
      [calibrationData]="oxygenCalibrationData"
      [graphId]="'calibration-graph'"
      [yAxisLabel]="'dphi'"
    ></app-oxygen-calibration-graph>
    <ng-template #loadingOxygen>
      <div class="loading--container">
        <app-loading-spinner
          *ngIf="dataIsProcessing"
          [label]="calibrationLoadingText"
        ></app-loading-spinner>
        <div *ngIf="dataError">{{ dataError.message }}</div>
        <div
          *ngIf="!renderCalibrationGraph && !dataIsProcessing"
          class="subtitle1"
        >
          No data received
        </div>
      </div>
    </ng-template>
    <div *ngIf="!oxygenCalibration?.isFinal" class="button--container">
      <app-button
        label="discard calibration"
        buttonId="discard-calibration-btn"
        buttonStyle="negative"
        [isDisabled]="
          oxygenCalibration?.isFinal ||
          (currentUser.role
            | roleCheck : [Role.Read_Access_User, Role.Run_Access_User])
        "
        (buttonClicked)="openModal('confirm-discard')"
      ></app-button>
    </div>
  </div>
  <div *ngIf="calibrationType === 'ph'" class="section--container">
    <div class="subtitle1">
      Data last updated: {{ timeOfLastUpdate | date : "HH:mm:ss, dd/MM/yy" }}
    </div>
    <app-ph-calibration-graph
      *ngIf="renderCalibrationGraph; else loadingPH"
      [calibrationData]="phCalibrationData"
      [graphId]="'calibration-graph'"
      [yAxisLabel]="'Reading'"
    >
    </app-ph-calibration-graph>
    <ng-template #loadingPH>
      <div class="loading--container">
        <app-loading-spinner
          *ngIf="dataIsProcessing"
          [label]="calibrationLoadingText"
        ></app-loading-spinner>
        <div *ngIf="dataError">{{ dataError.message }}</div>
      </div>
    </ng-template>
    <div *ngIf="!phCalibration?.isFinal" class="button--container">
      <app-button
        label="discard calibration"
        buttonStyle="negative"
        buttonId="discard-calibration-btn"
        [isDisabled]="
          phCalibration?.isFinal ||
          (currentUser.role
            | roleCheck : [Role.Read_Access_User, Role.Run_Access_User])
        "
        (buttonClicked)="openModal('confirm-discard')"
      ></app-button>
    </div>
  </div>
</div>
<app-validation-modal
  id="confirm-discard"
  titleText="Confirm Discard"
  primaryText="Are you sure you want to discard this calibration?"
  buttonType="double-button"
  primaryButtonText="Confirm"
  primaryButtonId="discard-confirm-button"
  secondaryButtonId="discard-cancel-button"
  secondaryButtonText="Cancel"
  (modalButtonClicked)="discardCalibration()"
  validationText="delete"
  [isProcessing]="discardIsProcessing"
></app-validation-modal>
<app-modal
  id="discard-success-response"
  titleText="Calibration deleted"
  primaryText="Choose an action below"
  buttonType="double-button"
  primaryButtonText="Go to dashboard"
  primaryButtonId="success-primary-button"
  secondaryButtonText="Create new calibration"
  secondaryButtonId="success-secondary-button"
  modalStyle="success"
  (modalButtonClicked)="modalButtonClicked($event)"
  allowClose="false"
>
</app-modal>
<app-modal
  id="discard-error-response"
  titleText="Unable to delete calibration"
  [primaryText]="discardError?.message + '. Choose an action below'"
  buttonType="double-button"
  primaryButtonText="Go to dashboard"
  primaryButtonId="error-primary-button"
  secondaryButtonText="Close"
  secondaryButtonId="error-secondary-button"
  modalStyle="error"
  (modalButtonClicked)="modalButtonClicked($event)"
  allowClose="false"
>
</app-modal>
<app-validation-modal
  id="delete-calibration"
  titleText="Delete calibration?"
  validationText="delete"
  buttonType="double-button"
  primaryButtonText="Confirm delete"
  primaryButtonId="confirm-delete-button"
  secondaryButtonText="Cancel"
  secondaryButtonId="cancel-delete-button"
  (modalButtonClicked)="discardCalibration()"
  [error]="discardError"
></app-validation-modal>
