<button
  [attr.type]="type"
  [attr.id]="buttonId"
  (click)="clickButton()"
  [class]="classes"
  [disabled]="isDisabled"
  [attr.aria-label]="label"
  [title]="label"
>
  @if (iconName) {
    <mat-icon [attr.aria-label]="label">{{ iconName }}</mat-icon>
  }
  @if (displayText && label) {
    <span class="body2">{{ label }}</span>
  }
</button>
