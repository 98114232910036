/* eslint-disable brace-style */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
import {
  AfterViewInit,
  Component,
  Input,
  SimpleChanges,
  OnChanges,
  ChangeDetectionStrategy,
} from '@angular/core';
import * as d3 from 'd3';
import { fromEvent, debounceTime } from 'rxjs';
import { AxisDomain, AxisScale, select, style, zoom } from 'd3';
import {
  IOxygenCalibrationDevice,
  IOxygenCalibrationFlask,
  IOxygenValue,
} from 'src/app/_models/experiment-data';
import { MatSlideToggle } from '@angular/material/slide-toggle';

@Component({
  selector: 'app-oxygen-calibration-graph',
  templateUrl: './oxygen-calibration-graph.component.html',
  styleUrls: ['./oxygen-calibration-graph.component.scss'],
  standalone: true,
  imports: [MatSlideToggle],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OxygenCalibrationGraphComponent
  implements AfterViewInit, OnChanges
{
  private readonly flaskColours: Record<string, string> = {
    A: '#6ECF9C', // Lighter green
    B: '#6C73C1', // Blue - distinct in both normal and colorblind vision
    C: '#BE4FC1', // Purple - different brightness and hue from others
    D: '#CB9F50', // Gold/ochre - very different brightness level
  };

  private svg: d3.Selection<SVGGElement, unknown, HTMLElement, any>;
  private axisSvg: d3.Selection<SVGSVGElement, unknown, HTMLElement, any>;
  private x: d3.ScaleLinear<number, number>;
  private y: d3.ScaleLinear<number, number>;
  private readonly initialRadius = 4;
  private readonly maxRadius = 10;
  private readonly legendHeight = 30;
  private readonly legendItemWidth = 40;
  private readonly flaskLetters = Object.keys(this.flaskColours);
  private readonly legendWidth =
    this.legendItemWidth * this.flaskLetters.length;
  checked = false;

  private readonly margin = {
    left: 90,
    right: 5,
    top: 10,
    bottom: 80,
  };

  // Drawing state
  private isDrawing = false;
  private prevWidth: number | null = null;

  // Set to large by default
  private width: number;
  private height = 500;

  private readonly dataToRender: IRenderData[] = [];
  private readonly labelList: IPropertyColour[] = [];

  /**
   * @param {string} graphId The ID of the graph to be displayed
   * @description This is used to set the ID of the graph container
   * so that it can be referenced by the zoom function
   * @example 'scatter'
   */
  @Input()
  graphId = 'scatter';

  /**
   * @param {string} graphTitle The title of the graph to be displayed
   */
  @Input()
  graphTitle = 'Title Here';

  /**
   * @param {string} yAxisLabel The y-axis label to be used on the graph
   */
  @Input()
  yAxisLabel = 'Y Axis Label';

  /**
   * @param {string} dataType The type of data to be graphed
   */
  @Input()
  dataType = 'Oxygen';

  /**
   * @param {IOxygenCalibrationDevice} calibrationData The calibration data from the API to be graphed
   */
  @Input()
  calibrationData: IOxygenCalibrationDevice;

  ngAfterViewInit(): void {
    this.initialiseGraph();
  }

  ngOnChanges(changes: SimpleChanges): void {
    // Check if calibrationData exists in changes
    if ('calibrationData' in changes) {
      const change = changes.calibrationData;
      // Skip if this is the first change
      if (change.firstChange) {
        return;
      }

      // Skip if new value is undefined
      if (!change.currentValue) {
        return;
      }

      // Since we know there's a change and it has a value, update the graph
      this.parseData(change.currentValue, true);
    }
  }

  toggleChange(event: MatSlideToggle): void {
    this.checked = event.checked;
    if (this.checked) {
      this.svg.call(d3.zoom().on('zoom', null));
    } else {
      this.svg.call(
        d3
          .zoom()
          .extent([
            [0, 0],
            [this.width, this.height],
          ])
          .scaleExtent([1, 10])
          .on('zoom', this.updateChart),
      );
    }
  }

  private readonly initialiseGraph = (): void => {
    this.createSvg();

    if (this.calibrationData) {
      this.parseData(this.calibrationData);
    }

    // Set up the resize observer
    const resizeObservable$ = fromEvent(window, 'resize').pipe(
      debounceTime(300), // Adjust delay as needed
    );

    resizeObservable$.subscribe(() => {
      this.updateSvgDimensions();
    });
  };

  private readonly createSvg = (): void => {
    // Set the styling on the container to relative for the absolutely positioned tooltips
    d3.select('figure').style('position', 'relative');
    // Get the width of the parent container
    const containerWidth = (
      d3.select(`figure#${this.graphId}`).node() as HTMLElement
    ).offsetWidth;
    this.width = containerWidth - (this.margin.left + this.margin.right);
    // Create the main svg element for the axes
    this.axisSvg = d3
      .select(`figure#${this.graphId}`)
      .append('svg')
      // Set the dimensions and margins of the graph
      .attr('width', this.width + (this.margin.left + this.margin.right))
      .attr('height', this.height + this.margin.top + this.margin.bottom)
      .attr('overflow', 'visible');
    // Create the svg element for the graph
    this.svg = this.axisSvg
      .append('g')
      .attr(
        'transform',
        'translate(' +
          this.margin.left.toString() +
          ',' +
          this.margin.top.toString() +
          ')',
      );

    // Add a clip path: everything out of this area won't be drawn.
    this.svg
      .append('defs')
      .append('svg:clipPath')
      .attr('id', 'clip')
      .append('svg:rect')
      .attr('width', this.width)
      .attr('height', this.height)
      .attr('x', 0)
      .attr('y', 0);
    // Rectangle to capture pointer events for zoom
    this.svg
      .append('rect')
      .attr('width', this.width)
      .attr('height', this.height)
      .style('fill', 'none')
      .style('pointer-events', 'all');
    // Now let's add zoom to the svg element
    this.svg.call(
      d3
        .zoom()
        .extent([
          [0, 0],
          [this.width, this.height],
        ])
        .scaleExtent([1, 10])
        .on('zoom', this.updateChart),
    );

    // Append the axes to the main SVG (this.axisSvg)
    this.axisSvg
      .append('g')
      .attr('class', 'x-axis')
      .attr(
        'transform',
        `translate(${this.margin.left}, ${this.height + this.margin.top})`,
      );

    this.axisSvg
      .append('g')
      .attr('class', 'y-axis')
      .attr('transform', `translate(${this.margin.left}, ${this.margin.top})`);
  };

  private readonly parseData = (
    calibrationData: IOxygenCalibrationDevice,
    isUpdate = false,
  ): void => {
    const property = this.dataType;

    calibrationData.flasks.forEach((flask: IOxygenCalibrationFlask) => {
      const flaskLetter = flask.name.charAt(0);
      const deviceFlaskProperty = `${flask.name}_${property}`;

      flask.values?.forEach((calibrationValue: IOxygenValue, index: number) => {
        this.dataToRender.push({
          DeviceFlaskProperty: deviceFlaskProperty,
          OxygenDphi: calibrationValue.dphi,
          Sample: calibrationValue.sample,
          FlaskName: flask.name,
        });
      });

      this.labelList.push({
        deviceFlaskProperty,
        colour: this.flaskColours[flaskLetter],
      });
    });

    if (isUpdate) {
      this.updateGraphWithNewData(this.dataToRender);
    } else {
      this.drawScatterGraph();
    }
  };

  private updateSvgDimensions(): void {
    const containerWidth = (
      d3.select(`figure#${this.graphId}`).node() as HTMLElement
    ).offsetWidth;

    const containerHeight = (
      d3.select(`figure#${this.graphId}`).node() as HTMLElement
    ).offsetHeight;

    if (
      (this.prevWidth !== containerWidth || this.height !== containerHeight) &&
      !this.isDrawing
    ) {
      this.isDrawing = true;

      // Recalculate the width and height based on container size
      this.width = containerWidth - (this.margin.left + this.margin.right);
      this.height = containerHeight - (this.margin.top + this.margin.bottom);

      // Update the SVG dimensions
      this.axisSvg
        .attr('width', this.width + (this.margin.left + this.margin.right))
        .attr('height', this.height + (this.margin.top + this.margin.bottom));

      // Preserve the zoom transform by applying it to the dots as well
      const zoomTransform = d3.zoomTransform(this.svg.node());

      // Recreate the scales based on new dimensions
      this.x.range([0, this.width]);
      this.y.range([this.height, 0]);

      // Update the clip path dimensions to match the new graph dimensions
      this.svg
        .select('clipPath rect')
        .attr('width', this.width)
        .attr('height', this.height);

      // Update the axes with the current zoom transformation
      this.axisSvg
        .select<SVGGElement>('.x-axis')
        .attr(
          'transform',
          `translate(${this.margin.left}, ${this.height + this.margin.top})`,
        )
        .call(d3.axisBottom(this.x).ticks(10));

      // Reposition the x-axis label to center it based on new width
      this.axisSvg.select('.x-axis-label').attr('x', this.width / 2);

      // Update the y-axis
      this.axisSvg
        .select<SVGGElement>('.y-axis')
        .attr('transform', `translate(${this.margin.left}, ${this.margin.top})`)
        .call(d3.axisLeft(this.y).ticks(10));

      // Also reposition the y-axis label
      const vertOffset =
        this.height / 2 - (this.margin.bottom + this.margin.top);
      const horzOffset = this.margin.left - 20;
      this.axisSvg
        .select('.y-axis-label')
        .attr(
          'transform',
          `translate(-${horzOffset}, ${vertOffset}) rotate(-90)`,
        );

      // Reapply zoom transform to the dots as well
      this.svg
        .selectAll('circle')
        .attr('cx', (d: IRenderData) =>
          zoomTransform.rescaleX(this.x)(d.Sample),
        )
        .attr('cy', (d: IRenderData) =>
          zoomTransform.rescaleY(this.y)(d.OxygenDphi),
        );

      // Update the legend position
      if (this.axisSvg.select('.legend').size() > 0) {
        const legendX = (this.width - this.legendWidth) / 2 + this.margin.right;
        const legendY = this.height + this.margin.bottom + this.margin.top + 15;

        this.axisSvg
          .select('.legend')
          .attr(
            'transform',
            `translate(${legendX + this.margin.left}, ${legendY})`,
          );
      }

      // Save the new width for future resizing
      this.prevWidth = containerWidth;

      this.isDrawing = false;
    }
  }

  private readonly updateChart = (
    event: d3.D3ZoomEvent<SVGSVGElement, any>,
  ): void => {
    // Rescale the x and y axes based on the zoom transformation
    const newX = event.transform.rescaleX(this.x);
    const newY = event.transform.rescaleY(this.y);

    // Get the zoom scale factor (k)
    const zoomScale = event.transform.k;

    // Calculate the appropriate number of ticks based on the domain range and zoom level
    const xDomain = newX.domain();
    const domainWidth = xDomain[1] - xDomain[0];

    // Determine tick count - fewer ticks when zoomed out, more when zoomed in
    // but ensure we're showing integers only
    const tickCount = Math.min(Math.ceil(domainWidth), 20);
    // Update the x-axis with integer ticks
    this.axisSvg.select<SVGGElement>('.x-axis').call(
      d3
        .axisBottom(newX)
        .tickFormat(d3.format('d')) // Use 'd' format for integers
        .ticks(tickCount)
        // Force integer ticks only
        .tickValues(
          d3
            .range(Math.ceil(xDomain[0]), Math.floor(xDomain[1]) + 1)
            .filter((d) => d % Math.max(1, Math.floor(domainWidth / 10)) === 0),
        ),
    );
    this.axisSvg.select<SVGGElement>('.y-axis').call(d3.axisLeft(newY));

    // Apply the zoom transform to the circles, including scaling the radius
    this.svg
      .selectAll('circle')
      .attr('cx', (d: IRenderData) => newX(d.Sample))
      .attr('cy', (d: IRenderData) => newY(d.OxygenDphi))
      .attr('r', () => {
        // Calculate radius with zoom scale but cap at maximum
        const scaledRadius = this.initialRadius * zoomScale;
        return Math.min(
          Math.max(this.initialRadius, scaledRadius),
          this.maxRadius,
        );
      });
  };

  private readonly drawScatterGraph = (): void => {
    this.isDrawing = true; // Set the flag to true to prevent resizing
    // Clear existing content of the graph SVG
    this.svg.select('#graph-content').selectAll('*').remove();

    // Create a group for all graph content including regression lines
    const graphContent = this.svg
      .append('g')
      .attr('clip-path', 'url(#clip)')
      .attr('id', 'graph-content');

    const maxValue = Math.max(
      10,
      d3.max(this.dataToRender, (d) => d.Sample),
    );
    // First create x-axis
    this.x = d3
      .scaleLinear()
      .domain([0, maxValue])
      .range([0, this.width])
      .nice();

    const minY = d3.min(this.dataToRender, (d) => d.OxygenDphi);
    const maxY = d3.max(this.dataToRender, (d) => d.OxygenDphi);

    // Apply padding only as an absolute adjustment, depending on the sign
    const paddingFactor = 0.1; // 10% padding

    const paddedMinY = minY - Math.abs(minY * paddingFactor);
    const paddedMaxY = maxY + Math.abs(maxY * paddingFactor);

    // Limit decimal places
    const roundedMinY = parseFloat(paddedMinY.toFixed(2));
    const roundedMaxY = parseFloat(paddedMaxY.toFixed(2));

    // Now create the y axis
    this.y = d3
      .scaleLinear()
      .domain([roundedMinY, roundedMaxY])
      .range([this.height, 0])
      .nice();

    // Create the axes (but DON'T append them here)
    const xAxis = d3.axisBottom(this.x);
    const yAxis = d3.axisLeft(this.y);

    const applyAxis = (
      selection: d3.Selection<SVGGElement, unknown, HTMLElement, any>,
      axis: any,
    ) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      selection.call(axis);
    };

    // Add x-axis label
    this.axisSvg.select('.x-axis').selectAll('text').remove(); // Remove existing labels
    applyAxis(this.axisSvg.select('.x-axis'), xAxis);

    // Add y-axis label
    this.axisSvg.select('.y-axis').selectAll('text').remove(); // Remove existing labels
    applyAxis(this.axisSvg.select('.y-axis'), yAxis);

    this.axisSvg
      .select('.x-axis')
      .append('text')
      .attr('class', 'x-axis-label')
      .attr('x', this.width / 2)
      .attr('y', this.margin.bottom - 20)
      .attr('fill', '#000000')
      .attr('text-anchor', 'middle')
      .text('Sample')
      .style('font-family', '$proxima-nova')
      .style('font-weight', '300')
      .style('font-size', '2rem');

    // Attach the y-axis to the svg group
    const horzOffset = this.margin.left - 20;
    const vertOffset = this.height / 2 - (this.margin.bottom + this.margin.top);

    // Attach the y-axis to the svg group
    applyAxis(this.axisSvg.select('.y-axis'), yAxis);

    // Add y-axis label
    this.axisSvg
      .select('.y-axis')
      .append('text')
      .attr('class', 'y-axis-label')
      .attr('x', -this.margin.left)
      .attr('y', this.margin.top)
      .attr('fill', '#000000')
      .attr('text-anchor', 'middle')
      .attr('transform', `translate(-${horzOffset}, ${vertOffset}) rotate(-90)`)
      .style('font-family', '$proxima-nova')
      .style('font-weight', '300')
      .style('font-size', '2rem')
      .text(this.yAxisLabel);

    // Colour set up - can't pass in objects so need flat lists
    const domainScale = [];
    const rangeColour = [];

    this.labelList.forEach((element) => {
      domainScale.push(element.deviceFlaskProperty);
      rangeColour.push(element.colour);
    });
    const colour = d3.scaleOrdinal().domain(domainScale).range(rangeColour);

    // Now let's build the tooltip, hidden by default (with opacity 0)
    const tooltip = d3
      .select(`figure#${this.graphId}`)
      .append('div')
      .style('opacity', 0)
      .attr('class', 'body3')
      .attr('class', 'tooltip')
      .style('background-color', '#B4DFFF')
      .style('border', 'solid')
      .style('border-width', '1px')
      .style('border-radius', '5px')
      .style('border-color', '#0C7BB4')
      .style('padding', '10px')
      .style('font-family', '$proxima-nova')
      .style('font-weight', '300')
      .style('font-size', '1rem')
      .style('position', 'absolute');

    // And when the user hovers over a point, let's make it visible
    const mouseover = function () {
      tooltip.style('opacity', 1);
      d3.select(this).style('stroke', 'black').style('opacity', '1');
    };

    // Insert text into the tooltip and position it relative to the point hovered over
    const mousemove = function (e: MouseEvent, d: IRenderData) {
      const tooltipX = e.offsetX + 20;
      tooltip
        .html(
          `DPHI: ${d.OxygenDphi} <br> Sample: ${d.Sample}<br> Flask: ${d.FlaskName}`,
        )
        .style('left', tooltipX.toString() + 'px') //offset the tooltip to prevent clash with point
        .style('top', e.offsetY.toString() + 'px');
    };

    // Now hide the tooltip when the mouse leaves hoverover
    const mouseleave = function () {
      tooltip.style('opacity', 0);
      d3.select(this).style('stroke', 'none').style('opacity', 0.8);
    };

    graphContent
      .selectAll('circle')
      .data(this.dataToRender)
      .enter()
      .append('circle')
      .attr('cx', (d) => this.x(d.Sample))
      .attr('cy', (d) => this.y(d.OxygenDphi))
      .attr('r', this.initialRadius)
      .style('fill', (d): string => {
        // Explicitly return a string
        return colour(d.DeviceFlaskProperty) as string;
      })
      .on('mouseover', mouseover)
      .on('mousemove', mousemove)
      .on('mouseleave', mouseleave);

    const legendX = (this.width - this.legendWidth) / 2 + this.margin.right;
    const legendY = this.height + this.margin.bottom + this.margin.top + 15;

    // Remove existing legend
    this.axisSvg.select('.legend').remove();

    const legendGroup = this.axisSvg
      .append('g')
      .attr('class', 'legend')
      .attr('transform', `translate(${legendX + this.margin.left}, ${legendY})`)
      .style('pointer-events', 'none');

    this.flaskLetters.forEach((flaskLetter, index) => {
      const itemX = index * this.legendItemWidth;
      const color = this.flaskColours[flaskLetter];

      // Group for each legend item
      const legendItem = legendGroup
        .append('g')
        .attr('transform', `translate(${itemX}, 0)`);

      // Color circle
      legendItem
        .append('circle')
        .attr('cx', 5)
        .attr('cy', 5)
        .attr('r', 5)
        .attr('fill', color);

      // Flask label
      legendItem
        .append('text')
        .attr('x', 15)
        .attr('y', 9)
        .style('font-family', '$proxima-nova')
        .style('font-size', '11px')
        .text(flaskLetter);
    });

    this.isDrawing = false; // Set the flag to false to allow resizing
  };

  private showTooltip(e: MouseEvent, d: IRenderData): void {
    const tooltipX = e.offsetX + 20;
    const tooltip = d3.select(`figure#${this.graphId} .tooltip`);
    const tooltipText = `DPHI: ${d.OxygenDphi} <br> Sample: ${d.Sample}<br> Flask: ${d.FlaskName}`;
    tooltip
      .html(tooltipText)
      .style('left', tooltipX.toString() + 'px')
      .style('top', e.offsetY.toString() + 'px')
      .style('opacity', 1);
  }

  private hideTooltip(): void {
    d3.select(`figure#${this.graphId} .tooltip`).style('opacity', 0);
  }

  private readonly updateGraphWithNewData = (newData: IRenderData[]): void => {
    this.isDrawing = true;

    // First, update the scales with the new data
    const maxValue = Math.max(
      10,
      d3.max(newData, (d) => d.Sample),
    );

    this.x = d3
      .scaleLinear()
      .domain([0, maxValue])
      .range([0, this.width])
      .nice();

    const minY = d3.min(newData, (d) => d.OxygenDphi);
    const maxY = d3.max(newData, (d) => d.OxygenDphi);
    const paddingFactor = 0.1;
    const paddedMinY = minY - Math.abs(minY * paddingFactor);
    const paddedMaxY = maxY + Math.abs(maxY * paddingFactor);
    const roundedMinY = parseFloat(paddedMinY.toFixed(2));
    const roundedMaxY = parseFloat(paddedMaxY.toFixed(2));

    this.y = d3
      .scaleLinear()
      .domain([roundedMinY, roundedMaxY])
      .range([this.height, 0]);

    // Get current zoom transform
    const zoomTransform = d3.zoomTransform(this.svg.node());

    // Update axes with the new scales, considering zoom
    const xAxis = d3.axisBottom(zoomTransform.rescaleX(this.x));
    const yAxis = d3.axisLeft(zoomTransform.rescaleY(this.y));

    this.axisSvg.select('.x-axis').call(xAxis as any);
    this.axisSvg.select('.y-axis').call(yAxis as any);

    // Clear existing content
    this.svg.select('#graph-content').selectAll('*').remove();

    // Select the graph content
    const graphContent = this.svg.select('#graph-content');

    // Add new dots with the updated scales
    graphContent
      .selectAll('circle')
      .data(newData)
      .enter()
      .append('circle')
      .attr('cx', (d) => zoomTransform.rescaleX(this.x)(d.Sample))
      .attr('cy', (d) => zoomTransform.rescaleY(this.y)(d.OxygenDphi))
      .attr('r', () => {
        // Calculate radius with zoom scale but cap at maximum
        const scaledRadius = this.initialRadius * zoomTransform.k;
        return Math.min(
          Math.max(this.initialRadius, scaledRadius),
          this.maxRadius,
        );
      })
      .style('fill', (d): string => {
        const flaskLetter = d.FlaskName.charAt(0);
        return this.flaskColours[flaskLetter];
      })
      .on('mouseover', (e: MouseEvent, d: IRenderData) => {
        this.showTooltip(e, d);

        d3.select(e.target as SVGElement)
          .style('stroke', 'black')
          .style('opacity', '1');
      })
      .on('mouseleave', (e: MouseEvent) => {
        this.hideTooltip();
        d3.select(e.target as SVGElement)
          .style('stroke', 'none')
          .style('opacity', '0.8');
      });

    // Create a zoom handler that will update dots

    const zoomed = (event: d3.D3ZoomEvent<SVGSVGElement, any>) => {
      // Update axes
      const newX = event.transform.rescaleX(this.x);
      const newY = event.transform.rescaleY(this.y);

      // Calculate the appropriate number of ticks based on the domain range and zoom level
      const xDomain = newX.domain();
      const domainWidth = xDomain[1] - xDomain[0];

      // Determine tick values - ensuring integers only with appropriate spacing
      const tickValues = d3
        .range(Math.ceil(xDomain[0]), Math.floor(xDomain[1]) + 1)
        .filter((d) => d % Math.max(1, Math.floor(domainWidth / 10)) === 0);

      this.axisSvg
        .select('.x-axis')
        .call(
          d3
            .axisBottom(newX)
            .tickFormat(d3.format('d'))
            .tickValues(tickValues) as any,
        );

      this.axisSvg.select('.y-axis').call(d3.axisLeft(newY as any) as any);

      // Update dots
      graphContent
        .selectAll('circle')
        .attr('cx', (d: IRenderData) => newX(d.Sample))
        .attr('cy', (d: IRenderData) => newY(d.OxygenDphi))
        .attr('r', () => {
          // Calculate radius with zoom scale but cap at maximum
          const scaledRadius = this.initialRadius * event.transform.k;
          return Math.min(
            Math.max(this.initialRadius, scaledRadius),
            this.maxRadius,
          );
        });
    };

    // Set up zoom behavior
    this.svg.call(
      d3
        .zoom()
        .extent([
          [0, 0],
          [this.width, this.height],
        ])
        .scaleExtent([1, 10])
        .on('zoom', zoomed),
    );

    this.isDrawing = false;
  };
}

export interface IRenderData {
  DeviceFlaskProperty: string;
  OxygenDphi: number;
  Sample: number;
  FlaskName: string;
}

export interface IPropertyColour {
  deviceFlaskProperty: string;
  colour: string;
}
