import { Component, OnInit, inject } from '@angular/core';
import { FormBase } from 'src/app/_models/form-base';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { ModalService } from 'src/app/_services/modal.service';
import { ErrorObject } from 'src/app/_models/error';
import { UserService } from 'src/app/_services/user.service';
import { Subject } from 'rxjs';
import { Role } from 'src/app/_models/role';
import { User, UserCreate } from 'src/app/_models/user';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { Laboratory } from 'src/app/_models/laboratory';
import { ModalComponent } from '../../_components/modals/modal.component';
import { ErrorPageComponent } from '../../_components/error-page/error-page.component';
import { ButtonComponent } from '../../_components/buttons/button/button.component';
import { IconButtonComponent } from '../../_components/buttons/icon-button/icon-button.component';
import { UnderlinedDropdownFormFieldComponent } from '../../_components/forms/underlined-dropdown-form-field/underlined-dropdown-form-field.component';
import { UnderlinedFormFieldComponent } from '../../_components/forms/underlined-form-field/underlined-form-field.component';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    UnderlinedFormFieldComponent,
    UnderlinedDropdownFormFieldComponent,
    IconButtonComponent,
    ButtonComponent,
    ErrorPageComponent,
    ModalComponent,
  ],
})
export class AddUserComponent implements OnInit {
  protected formBuilder = inject(FormBuilder);
  protected modalService = inject(ModalService);
  protected userService = inject(UserService);
  private readonly authenticationService = inject(AuthenticationService);

  // Permissions
  laboratory: Laboratory;

  // Control vars
  isProcessing = false;
  error: ErrorObject = null;
  currentUser: User;

  availableLabs: Laboratory[] = [];
  selectedLabs: Laboratory[] = [];
  labSelectError: string;
  addUserSuccessMessage: string;

  allowNavigation: Subject<boolean> = new Subject<boolean>();

  firstNameField = new FormBase({
    key: 'firstName',
    label: 'First Name',
    type: 'text',
    placeholder: 'First name',
    disabled: false,
    required: true,
    value: '',
    options: [],
  });

  lastNameField = new FormBase({
    key: 'lastName',
    label: 'Last Name',
    type: 'text',
    placeholder: 'Last Name',
    disabled: false,
    required: true,
    value: '',
    options: [],
  });

  emailField = new FormBase({
    key: 'email',
    label: 'Email',
    type: 'email',
    placeholder: 'Email Address',
    disabled: false,
    required: true,
    value: '',
    options: [],
  });

  userRoleField = new FormBase({
    key: 'role',
    label: 'Role',
    type: 'select',
    placeholder: 'Select role',
    disabled: false,
    required: true,
    value: '',
    options: [
      { key: Role.Read_Access_User, value: 'Read Access User' },
      { key: Role.Run_Access_User, value: 'Run Access User' },
      { key: Role.Edit_Access_User, value: 'Edit Access User' },
      { key: Role.Lab_Administrator, value: 'Lab Administrator' },
    ],
  });

  availableLabsField = new FormBase({
    key: 'lab',
    label: 'User Laboratories',
    type: 'select',
    placeholder: 'Select lab',
    disabled: false,
    required: false,
    value: '',
    options: [],
  });

  addUserForm = this.formBuilder.group({
    [this.firstNameField.key]: ['', [Validators.required]],
    [this.lastNameField.key]: ['', [Validators.required]],
    [this.emailField.key]: ['', [Validators.required, Validators.email]],
    [this.userRoleField.key]: ['', [Validators.required]],
    [this.availableLabsField.key]: [],
  });

  ngOnInit(): void {
    this.currentUser = this.authenticationService.currentUserValue;
    this.setAvailableUserRoles(this.currentUser.role);
    this.setAvailableLabs(this.currentUser.role);
    this.laboratory = this.authenticationService.selectedLaboratory;
  }

  setAvailableUserRoles(role: Role): void {
    if (
      role === Role.OGI_Laboratory_Administrator ||
      role === Role.OGI_Super_Administrator
    ) {
      this.userRoleField.options.push({
        key: Role.OGI_Laboratory_Administrator,
        value: 'OGI Laboratory Administrator',
      });
    }

    if (role === Role.OGI_Super_Administrator) {
      this.userRoleField.options.push({
        key: Role.OGI_Super_Administrator,
        value: 'OGI Super Administrator',
      });
      this.userRoleField.options.push({
        key: Role.Super_User,
        value: 'Super User',
      });
    }
  }

  setAvailableLabs(role: Role): void {
    if (role === Role.OGI_Super_Administrator) {
      // Combine all labs from adminAllLabsListValue and currentUser.laboratories
      const allLabs = [
        ...(this.authenticationService.adminAllLabsListValue || []),
        ...(this.currentUser.laboratories || []),
      ];

      // Remove duplicate labs based on laboratoryId
      this.availableLabs = allLabs.filter(
        (lab, index, self) =>
          index === self.findIndex((l) => l.laboratoryId === lab.laboratoryId),
      );

      this.availableLabsField.options = this.availableLabs.map((lab) => {
        return { key: lab.laboratoryId, value: lab.laboratoryName };
      });
    } else {
      // If Lab_Administrator, Super_User, or Ogi_Laboratory_Administrator, they can only add labs they are associated with
      // Get all labs in the current organisation context
      this.availableLabs = this.currentUser.laboratories || [];
      this.availableLabsField.options = this.availableLabs.map((lab) => {
        return { key: lab.laboratoryId, value: lab.laboratoryName };
      });
    }

    // Now we can subscribe to the form changes
    this.addUserForm.get('lab').valueChanges.subscribe((labId) => {
      // Check if the lab is already in the selected labs
      const lab = this.availableLabs.find(
        (lab) => lab.laboratoryId === parseInt(labId),
      );
      if (lab && !this.selectedLabs.includes(lab)) {
        this.selectedLabs.push(lab);
      }
    });
  }

  openModal(modalId: string): void {
    this.modalService.open(modalId);
  }

  closeModal(modalId: string): void {
    this.modalService.close(modalId);
  }

  modalButtonClicked(buttonId: string): void {
    switch (buttonId) {
      case 'add-user-button':
        this.closeModal('create-account');
        break;
      case 'error-button':
        this.closeModal('error');
        break;
      case 'cancel-button':
        this.allowNavigation.next(false);
        this.closeModal('attention');
        break;
      case 'confirm-navigation':
        this.allowNavigation.next(true);
        this.closeModal('attention');
        break;
    }
  }

  removeLab(lab: Laboratory): void {
    this.selectedLabs = this.selectedLabs.filter(
      (selectedLab) => selectedLab.laboratoryId !== lab.laboratoryId,
    );
  }

  resetForm(): void {
    // Reset form and clear cache
    this.addUserForm.reset();
    this.addUserForm.markAsPristine();
    this.selectedLabs = [];
  }

  get f(): FormGroup['controls'] {
    return this.addUserForm.controls;
  }

  onSubmit(): void {
    // If form is already being submitted, exit
    if (this.isProcessing) {
      return;
    }

    this.error = null;
    this.labSelectError = null;
    this.addUserSuccessMessage = '';

    // If the current user is an Ogi_Super_Administrator, they can add a Super_User or other Ogi_Super_Administrator
    // without assigning a lab, otherwise a lab must be assigned
    if (
      this.selectedLabs?.length < 1 &&
      this.f.role.value !== Role.OGI_Super_Administrator &&
      this.f.role.value !== Role.Super_User
    ) {
      this.labSelectError = 'Please select at least one lab';
      return;
    }

    const labIds = this.selectedLabs.map((lab) => lab.laboratoryId);

    const user: UserCreate = {
      firstName: this.f.firstName.value as string,
      lastName: this.f.lastName.value as string,
      email: this.f.email.value as string,
      userType: this.f.role.value as Role,
      laboratoryIds: labIds,
    };

    this.isProcessing = true;
    this.userService
      .inviteUser(user)
      .pipe()
      .subscribe({
        next: (response: string) => {
          this.addUserSuccessMessage = response;
          this.isProcessing = false;
          this.resetForm();
          this.openModal('create-account');
        },
        error: (error: ErrorObject) => {
          this.isProcessing = false;
          this.error = error;
          this.openModal('error');
        },
      });
  }

  canExit(): Promise<boolean> | boolean {
    return this.isNavigationAllowed();
  }

  private isNavigationAllowed(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      if (this.addUserForm.pristine) {
        resolve(true);
      } else {
        this.openModal('attention');
        this.allowNavigation.subscribe((isConfirmed) => resolve(isConfirmed));
      }
    });
  }
}
