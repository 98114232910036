<fieldset
  class="select-container fieldset-reset"
  [class.select-container--round]="type() === 'round'"
  [class.select-container--square]="type() === 'square'"
  [attr.aria-label]="ariaLabel()"
>
  <!-- Include a legend if there's a description -->
  @if (ariaDescription()) {
    <legend class="sr-only">{{ ariaDescription() }}</legend>
  }
  @for (option of selectOptions(); track option.value) {
    <div
      class="radio-item body2"
      [class.radio-item--round]="type() === 'round'"
      [class.radio-item--square]="type() === 'square'"
    >
      <input
        [id]="option.value"
        [value]="option.value"
        type="radio"
        [attr.name]="groupName()"
        [attr.checked]="option.value === currentValue() ? true : null"
        (change)="updateValue(option.value)"
        [attr.aria-label]="option.labelText"
        [attr.aria-controls]="option.value"
      />
      <label
        [for]="option.value"
        class="label subtitle1"
        [class.label--round]="type() === 'round'"
        [class.label--square]="type() === 'square'"
      >
        {{ option.labelText }}
      </label>
    </div>
  }
</fieldset>
