<section>
  @if (dataReading) {
  <span class="subtitle2">{{ dataReading | formatCamelCase }}</span>
  } @if (iconName === 'wifi') {
  <mat-icon class="online">{{ iconName }}</mat-icon>
  } @if (iconName === 'wifi_off') {
  <mat-icon class="offline">{{ iconName }}</mat-icon>
  } @if (deviceStatus) {
  <app-status-indicator status="Active"></app-status-indicator>
  }
  <span class="body1">{{ title }}</span>
</section>
