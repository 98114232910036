<div *ngIf="laboratory; else noLab">
  <div id="title--container">
    <h4>pH Buffers</h4>
    <h5>{{ laboratory.laboratoryName }}</h5>
  </div>
  <hr />
  <div id="new-buffer--container">
    <div id="nav--container">
      <app-option-tab-toggle
        id="toggle--container"
        radioGroup="navigationToggle"
        [selectOptions]="navOptions"
        type="round"
      >
      </app-option-tab-toggle>
    </div>
    <app-underlined-form-field
      [parentForm]="bufferForm"
      [field]="bufferNameField"
    ></app-underlined-form-field>
    <p id="table-description" style="display: none">
      Buffer temperature pH value pairs
    </p>
    <table aria-describedby="table-description">
      <thead>
        <tr>
          <th id="temp" scope="col">Temperature</th>
          <th id="pH" scope="col">pH</th>
          <th id="remove" scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let pair of phTempValueList; let i = index">
          <td headers="temp">{{ pair.temperature }}</td>
          <td headers="pH">{{ pair.phValue | number : "1.2" }}</td>
          <td headers="remove">
            <app-icon-button
              id="hidden-btn"
              buttonId="remove-btn"
              buttonStyle="transparent"
              buttonShape="square"
              textColor="red"
              iconName="close"
              (buttonClicked)="removePhTempValuePairFromBuffer(index)"
            ></app-icon-button>
          </td>
        </tr>
        <tr *ngIf="phTempValueList.length < 1">
          <td headers="temp">-</td>
          <td headers="pH">-</td>
          <td headers="remove"></td>
        </tr>
      </tbody>
    </table>
    <span *ngIf="phTempValueList.length < 1"> Add values below </span>
    <div id="add-buffer--container">
      <app-underlined-form-field
        [parentForm]="bufferForm"
        [field]="bufferTemperatureField"
      ></app-underlined-form-field>
      <app-underlined-form-field
        [parentForm]="bufferForm"
        [field]="bufferPhField"
        [step]="0.01"
      ></app-underlined-form-field>
      <app-icon-button
        label="add to buffer"
        buttonId="add-device-btn"
        buttonShape="square"
        iconName="add_circle"
        (buttonClicked)="addPhTempValuePairToBuffer()"
      ></app-icon-button>
    </div>
    <div id="error--container" *ngIf="createBufferError">
      <p class="error">Error: {{ createBufferError?.message }}</p>
    </div>
    <div id="button--container">
      <app-button
        label="save buffer"
        type="submit"
        buttonId="save-btn"
        [isProcessing]="isProcessing"
        [isDisabled]="bufferForm.invalid || phTempValueList.length < 1"
        (buttonClicked)="onSubmit()"
      ></app-button>
    </div>
  </div>
</div>
<ng-template #noLab>
  <app-error-page
    title="No Lab found"
    primaryText="Sorry, you cannot access this page unless you are a member of a lab"
  ></app-error-page>
</ng-template>
<app-modal
  id="buffer-response"
  titleText="Buffer created"
  [primaryText]="createBufferSuccessMessage"
  buttonType="double-button"
  primaryButtonText="Go to dashboard"
  primaryButtonId="success-primary-button"
  secondaryButtonText="Create another buffer"
  secondaryButtonId="create"
  modalStyle="success"
  (modalButtonClicked)="modalButtonClicked($event)"
  allowClose="false"
>
</app-modal>
<app-modal
  id="attention"
  titleText="Attention"
  primaryText="Your changes have not been saved. Press cancel to return to the form and save your changes, or continue to discard."
  buttonType="double-button"
  primaryButtonText="Continue"
  primaryButtonId="confirm-navigation"
  secondaryButtonText="Cancel"
  secondaryButtonId="close-button"
  modalStyle="warning"
  (modalButtonClicked)="modalButtonClicked($event)"
></app-modal>
<app-modal
  id="error"
  titleText="Error"
  [primaryText]="createBufferError?.message"
  primaryButtonText="close"
  primaryButtonId="close-button"
  modalStyle="error"
  (modalButtonClicked)="modalButtonClicked($event)"
></app-modal>
