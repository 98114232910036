import {
  Component,
  EventEmitter,
  Input,
  Output,
  ChangeDetectionStrategy,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'app-checkbox-group-no-label',
  templateUrl: './checkbox-group-no-label.component.html',
  styleUrls: ['./checkbox-group-no-label.component.scss'],
  standalone: true,
  imports: [MatIcon],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckboxGroupNoLabelComponent {
  /**
   * @param {key: string, value: string} option Checkbox base field
   * @requires
   */
  @Input()
  option: { key: string; value: string };

  /**
   * @param {FormGroup} parentForm Parent form group name
   * @requires
   */
  @Input()
  parentForm: FormGroup = new FormGroup({});

  /**
   * @param {string} formArrayGroupName The name of the form array group
   * @requires
   */
  @Input()
  formArrayGroupName: string;

  /**
   * @param {boolean} isDisabled Set whether checkboxes are disabled
   */
  @Input()
  isDisabled = false;

  @Output()
  checkboxClicked: EventEmitter<Event> = new EventEmitter<Event>();

  /**
   * Gets the value of the checkbox's corresponding form control
   * @returns {boolean} The checkbox value
   */
  getChecked(): boolean {
    const controlAccessor = this.formArrayGroupName;
    const arrayKey = 'controls';
    const arrayIndex = Number(this.option.value);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return this.parentForm.controls[controlAccessor][arrayKey][arrayIndex]
      .value as boolean;
  }

  /**
   * @param {Event} event The click event to be emitted
   */
  updateValue(event: Event): void {
    this.checkboxClicked.emit(event);
  }
}
