<div id="title--container">
  <h4>Device {{ deviceId }}</h4>
  <h5>{{ laboratory?.laboratoryName }}</h5>
</div>
<hr />
<div id="inner-page--container">
  <div id="toggle--container">
    <app-option-tab-toggle
      radioGroup="navigationToggle"
      [selectOptions]="navOptions"
      type="round"
    >
    </app-option-tab-toggle>
  </div>
  <hr />
  <div id="device-details--container">
    <div id="header">
      <div id="device-details-title">
        <h6>Device Overview:</h6>
        @if (device?.softwareWarning) {
          <div class="body1 software-status">
            <mat-icon
              class="warning"
              aria-label="software update warning"
              matTooltip="Software update available"
              >update</mat-icon
            >
            A
            <a
              href="https://ogibiotec.com/resources/"
              target="_blank"
              class="body1"
            >
              software update</a
            >
            is available
          </div>
        }
        @if (!device?.softwareWarning) {
          <div class="body1 software">
            <mat-icon
              class="positive"
              aria-label="software up to date"
              matTooltip="Software up to date"
              >update</mat-icon
            >
            The device software is up to date
          </div>
        }
      </div>
      @if (device && !device.isDeleted) {
        <app-icon-button
          label="delete device"
          buttonId="delete-device-btn"
          buttonShape="square"
          buttonStyle="negative"
          iconName="delete"
          (buttonClicked)="openModal('delete-device')"
          [isDisabled]="
            isProcessing ||
            device.deviceStatusId !== DeviceSetupStatus.Inactive ||
            (currentUser.role
              | roleCheck
                : [
                    Role.Read_Access_User,
                    Role.Run_Access_User,
                    Role.Edit_Access_User,
                    Role.Lab_Administrator,
                    Role.OGI_Laboratory_Administrator,
                  ])
          "
          [isProcessing]="isProcessing"
        ></app-icon-button>
      }
    </div>
    <div id="card-container">
      @if (device && device?.deviceModules) {
        <app-device-card [device]="device"></app-device-card>
        <app-installed-modules-card
          [activeModules]="device?.deviceModules"
        ></app-installed-modules-card>
      }
    </div>
  </div>
  <hr />
  <div id="update-device--container">
    <form [formGroup]="deviceForm">
      <div class="section--container">
        <h6>Edit Device</h6>
        <div class="field-row">
          <app-underlined-form-field
            [parentForm]="deviceForm"
            [field]="nameField"
          ></app-underlined-form-field>
          @if (hasLCModule) {
            <app-icon-underlined-form-field
              [parentForm]="deviceForm"
              [field]="pumpTubeLengthField"
              [minValue]="minPumpTubeLength"
              [maxValue]="maxPumpTubeLength"
              [step]="pumpTubeLengthStep"
            ></app-icon-underlined-form-field>
          }
        </div>
        <div class="button--container">
          @if (!device?.isDeleted) {
            <app-button
              label="update device"
              buttonId="update-device-btn"
              buttonShape="square"
              type="submit"
              (buttonClicked)="updateDevice()"
              [isDisabled]="
                isUpdateButtonDisabled() ||
                (currentUser.role
                  | roleCheck
                    : [
                        Role.Read_Access_User,
                        Role.Run_Access_User,
                        Role.Edit_Access_User,
                      ])
              "
              [isProcessing]="isProcessing"
            ></app-button>
          }
          @if (device?.deviceStatusId !== 3) {
            <span class="body1">
              Note: Device cannot be updated when in use
            </span>
          }
        </div>
      </div>
    </form>
  </div>
</div>
<app-modal
  id="error"
  titleText="Error"
  [primaryText]="error?.message ? error.message : error"
  primaryButtonText="close"
  primaryButtonId="close-error-button"
  modalStyle="error"
  (modalButtonClicked)="modalButtonClicked($event)"
></app-modal>
<app-modal
  id="buffer-error"
  titleText="Error"
  [primaryText]="bufferError?.message ? bufferError.message : bufferError"
  primaryButtonText="close"
  primaryButtonId="close-buffer-error-button"
  modalStyle="error"
  (modalButtonClicked)="modalButtonClicked($event)"
></app-modal>
<app-modal
  id="update-device"
  titleText="Success"
  [primaryText]="updateDeviceSuccessMessage"
  buttonType="single-button"
  primaryButtonText="close"
  primaryButtonId="close-success-button"
  modalStyle="success"
  (modalButtonClicked)="modalButtonClicked($event)"
></app-modal>
<app-modal
  id="buffer-sent"
  titleText="Sending successful"
  primaryText="The buffer has been sent to device. Please allow a few minutes for the device to sync."
  buttonType="single-button"
  primaryButtonText="close"
  primaryButtonId="close-buffer-success-button"
  modalStyle="success"
  (modalButtonClicked)="modalButtonClicked($event)"
></app-modal>
<app-modal
  id="attention"
  titleText="Attention"
  primaryText="Your changes have not been saved. Press cancel to return to the form and save your changes, or continue to discard."
  buttonType="double-button"
  primaryButtonText="Continue"
  primaryButtonId="confirm-navigation"
  secondaryButtonText="Cancel"
  secondaryButtonId="cancel-button"
  modalStyle="warning"
  (modalButtonClicked)="modalButtonClicked($event)"
></app-modal>
<app-validation-modal
  id="delete-device"
  titleText="Delete device?"
  validationText="delete"
  buttonType="double-button"
  primaryButtonText="Confirm delete"
  primaryButtonId="confirm-delete-button"
  secondaryButtonText="Cancel"
  secondaryButtonId="cancel-delete-button"
  (modalButtonClicked)="deleteDevice()"
  [error]="error"
></app-validation-modal>
