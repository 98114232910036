import { Calibration } from './calibration';
import { DeviceSetupStatus } from './device-setup-status';
import { LastRead } from './experiment-data';
import { ExperimentStatus } from './experiment-status';
import { Module } from './module-enum';
import { OxygenCalibration } from './oxygen-calibration';
import { PhCalibration } from './ph-calibration';

export class Device {
  id: number;
  name: string;
  laboratoryId: number;
  macAddress?: string;
  hardwareVersion?: string;
  installedSoftwareVersion?: string;
  softwareWarning?: boolean;
  pumpTubeLength?: number;
  deviceStatusId?: DeviceSetupStatus;
  isOffline?: boolean;
  deviceModules?: Module[];
  isDeleted?: boolean;
  // Used to direct routing to the correct experiment from the device dashboard view when a device is running an experiment
  // and display experiment information on the device dashboard view
  experiment?: ExperimentSummary;
  // The ID of the current active OD calibration for device dashboard view routing
  odCalibrationId?: number;
  // The ID of either the oxygen calibration currently being used on an active experiment that the device is running
  // or of the current active oxygen calibration
  oxygenCalibrationId?: number;
  // The ID of either the ph calibration currently being used on an active experiment that the device is running
  phCalibrationId?: number;
  // Used in the active experiment view to display the last read values for the device
  lastRead?: LastRead[];
  // Tracks the OD calibration currently being used on an active experiment that the device is running
  calibration?: Calibration;
  // Tracks the oxygen calibration currently being used on an active experiment that the device is running
  oxygenCalibration?: OxygenCalibration;
  // Tracks the pH calibration currently being used on an active experiment that the device is running
  phCalibration?: PhCalibration;

  constructor(
    id: number,
    name: string,
    laboratoryId: number,
    macAddress?: string,
    hardwareVersion?: string,
    installedSoftwareVersion?: string,
    softwareWarning?: boolean
  ) {
    this.id = id;
    this.name = name;
    this.laboratoryId = laboratoryId;
    this.macAddress = macAddress || null;
    this.hardwareVersion = hardwareVersion || null;
    this.installedSoftwareVersion = installedSoftwareVersion || null;
    this.softwareWarning = softwareWarning || false;
  }
}

export interface DeviceErrorLog {
  id: number;
  deviceId: number;
  errorText: string;
  detailedErrorText: string;
  viewed: boolean;
  createdBy: Date;
  createdAt: Date;
}

export interface ExperimentSummary {
  id: number;
  name: string;
  startTime: Date;
  duration: number;
  experimentStatusId: ExperimentStatus;
}

export interface DeviceUpdateStatus {
  deviceStatusId: DeviceSetupStatus;
}

export interface DeviceUpdate {
  id: number;
  name: string;
  pumpTubeLength: number;
}
