import {
  Component,
  EventEmitter,
  Input,
  Output,
  OnChanges,
  ChangeDetectionStrategy,
} from '@angular/core';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'app-icon-button',
  templateUrl: './icon-button.component.html',
  styleUrls: ['./icon-button.component.scss'],
  standalone: true,
  imports: [MatIcon],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconButtonComponent implements OnChanges {
  @Input() iconName: string;
  @Input() buttonShape: 'round' | 'square' = 'round';
  @Input() buttonStyle:
    | 'transparent--blue'
    | 'transparent'
    | 'primary'
    | 'secondary'
    | 'pause'
    | 'outlined'
    | 'positive'
    | 'negative' = 'primary';
  @Input() textColor: 'red' | 'standard' = 'standard';
  @Input() label: string;
  @Input() labelClass: 'large' | '' = '';
  @Input() buttonType: 'button' | 'submit' | 'reset' = 'button';
  @Input() buttonId: string | undefined;
  @Input() isDisabled = false;
  @Input() isProcessing = false;

  @Output() buttonClicked: EventEmitter<void> = new EventEmitter<void>();

  public classes = '';
  public iconClasses = '';

  ngOnChanges(): void {
    this.updateIconClasses();
    this.updateButtonClasses();
  }

  private updateButtonClasses(): void {
    const disabled = this.isDisabled ? 'disabled' : '';
    const hasText = this.label ? 'icon-button--text' : '';
    const textColor = this.textColor
      ? `icon-button--text--${this.textColor}`
      : '';
    const labelClass =
      this.labelClass === 'large' ? 'button-large' : 'button-text';

    this.classes = [
      'icon-button',
      this.buttonShape,
      'button-text',
      `icon-button--${this.labelClass}`,
      `icon-button--${this.buttonStyle}`,
      textColor,
      hasText,
      labelClass,
      disabled,
    ]
      .filter(Boolean)
      .join(' ');
  }

  private updateIconClasses(): void {
    this.iconClasses = this.isProcessing ? 'spin' : '';
  }

  clickButton = (): void => {
    this.buttonClicked.emit();
  };
}
