import { Component, Input } from '@angular/core';
import { Device } from 'src/app/_models/device';
import { DeviceSetupStatus } from 'src/app/_models/device-setup-status';

@Component({
  selector: 'app-device-card',
  templateUrl: './device-card.component.html',
  styleUrl: './device-card.component.scss',
})
export class DeviceCardComponent {
  DeviceStatus = DeviceSetupStatus;
  /**
   * @param {Device} device The device to be displayed
   * @requires
   */
  @Input()
  device: Device;
}
