<div id="page">
  <div id="container">
    <app-svg-template
      [iconName]="logo"
      width="249"
      height="60"
      customViewBox="0 0 249 60"
    ></app-svg-template>
    <h5>Forgot Password</h5>
    <form [formGroup]="resetForm" (ngSubmit)="onSubmit()">
      <app-underlined-form-field [field]="emailField" [parentForm]="resetForm">
      </app-underlined-form-field>
      <span class="body2 error">
        <span *ngIf="requestError.message">Error: </span
        >{{ requestError.message }}
      </span>
      <app-button
        buttonType="submit"
        buttonStyle="standard"
        [label]="'Submit'"
        buttonId="login"
        (buttonClicked)="onSubmit()"
        [isDisabled]="resetForm.invalid"
        [isProcessing]="isProcessing"
      >
      </app-button>
    </form>
    <a [routerLink]="'/login'" class="button-styling">Return to login</a>
  </div>
</div>
