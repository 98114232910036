import { Injectable, inject } from '@angular/core';
import { httpOptions } from '../_helpers/utils';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { User, UserIndex } from '../_models/user';
import { PaginationData } from '../_models/pagination-data.model';
import { Role } from '../_models/role';
import { Laboratory } from '../_models/laboratory';

export interface LabResponse {
  lab?: Laboratory;
  message?: string;
}

@Injectable({
  providedIn: 'root',
})
export class LaboratoryService {
  private readonly http = inject(HttpClient);

  httpOptions = httpOptions;

  private readonly pageNumber$ = new BehaviorSubject<number>(null);
  private readonly pageSize$ = new BehaviorSubject<number>(null);

  onUpdatePageNumber = (pageNumber: number): void => {
    this.pageNumber$.next(pageNumber);
  };

  onUpdatePageSize = (pageSize: number): void => {
    this.pageSize$.next(pageSize);
  };

  public get paginationCache(): { pageNumber: number; pageSize: number } {
    if (this.pageNumber$.value !== null && this.pageSize$.value !== null) {
      return {
        pageNumber: this.pageNumber$.value,
        pageSize: this.pageSize$.value,
      };
    } else {
      return null;
    }
  }

  addLab(labName: string, orgId?: string): Observable<Laboratory | null> {
    return this.http
      .post<Laboratory | null>(
        `${environment.apiUrl}/laboratories`,
        {
          laboratoryName: labName,
          organisationId: orgId || null,
        },
        this.httpOptions,
      )
      .pipe(
        map((response) => {
          if (response.status === 200) {
            return response.body;
          }
          if (response.status === 204) {
            return null;
          } else throw Error('Something went wrong');
        }),
      );
  }

  getLab(id: number): Observable<Laboratory> {
    return this.http
      .get<Laboratory>(
        `${environment.apiUrl}/laboratories/${id}`,
        this.httpOptions,
      )
      .pipe(
        map((response) => {
          return response.body;
        }),
      );
  }

  updateLab(labId: number, labName: string): Observable<string> {
    return this.http
      .patch<string>(
        `${environment.apiUrl}/laboratories/${labId}`,
        {
          laboratoryName: labName,
        },
        this.httpOptions,
      )
      .pipe(
        map((response) => {
          if (response.status === 200) {
            return 'The Laboratory has been updated.';
          } else if (response.status === 204) {
            return 'Laboratory update request is pending approval; the status can be checked on the approvals page.';
          } else throw Error('Something went wrong');
        }),
      );
  }

  getLabMembers(
    labId: number,
    sortBy = 'LastName',
    sortOrder = 'asc',
    pageNumber = 1,
    pageSize = 5,
    userRole: Role = null,
  ): Observable<UserIndex> {
    let url = `${environment.apiUrl}/users/${labId}/members?OrderBy=${sortBy}&OrderByDirection=${sortOrder}&PageNumber=${pageNumber}&PageSize=${pageSize}`;
    if (userRole) {
      url += `&Role=${userRole}`;
    }
    return this.http.get<User[]>(url, this.httpOptions).pipe(
      map((response) => {
        // Set pagination data
        const paginationData = JSON.parse(
          response.headers.get('X-Pagination'),
        ) as PaginationData;
        const users = response.body;
        return { paginationData, users };
      }),
    );
  }

  removeUserFromLab(userId: number, labId: number): Observable<unknown> {
    return this.http
      .patch<unknown>(
        `${environment.apiUrl}/laboratories/${userId}/${labId}`,
        this.httpOptions,
      )
      .pipe(
        map((response) => {
          return response;
        }),
      );
  }

  // PATCH /laboratories/add-user/{userId}/{labId}
  addUserToLab(userId: number, labId: number): Observable<boolean> {
    return this.http
      .patch<boolean>(
        `${environment.apiUrl}/laboratories/add-user/${userId}/${labId}`,
        this.httpOptions,
      )
      .pipe(
        map((response) => {
          return response;
        }),
      );
  }

  // GET /users/{labId}/available-members
  getAvailableUsersForLab(labId: number, orgId?: number): Observable<User[]> {
    let url = `${environment.apiUrl}/users/${labId}/available-members`;
    if (orgId) {
      url = url + `?&organisationId=${orgId}`;
    }
    return this.http.get<User[]>(url, this.httpOptions).pipe(
      map((response) => {
        return response.body;
      }),
    );
  }

  deleteLab(labId: number): Observable<unknown> {
    return this.http.delete(
      `${environment.apiUrl}/laboratories/${labId}`,
      this.httpOptions,
    );
  }
}
