<div [ngClass]="containerClasses">
  <div
    *ngFor="let option of selectOptions"
    [ngClass]="buttonClasses"
    [routerLink]="option.link"
    routerLinkActive="selected"
  >
    <input
      [id]="option.value"
      [value]="option.value"
      type="radio"
      [attr.name]="radioGroup"
    />
    <label [for]="option.value" [ngClass]="labelClasses">
      {{ option.labelText }}
    </label>
  </div>
</div>
