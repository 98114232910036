@if (laboratory) {
  <div>
    <div id="title--container">
      <h4>New Calibration Setup</h4>
      <h5>{{ laboratory.laboratoryName }}</h5>
    </div>
    <hr />
    <div id="new-calibration--container">
      <app-radio-button
        id="toggle--container"
        [field]="calibrationTypeField"
        [parentForm]="calibrationForm"
        [currentValue]="
          calibrationForm?.controls[calibrationTypeField.key]?.value
        "
        type="round"
      ></app-radio-button>
      <div class="form-field--container">
        <div>
          @if (!devicesLoading && deviceList?.length > 0) {
            <app-underlined-dropdown-form-field
              [parentForm]="calibrationForm"
              [field]="deviceField"
            ></app-underlined-dropdown-form-field>
          }
          @if (!devicesLoading && (!deviceList || deviceList.length === 0)) {
            <span class="subtitle1">No devices available</span>
          }
          @if (devicesLoading) {
            <div class="subtitle1">
              <app-small-loading-spinner></app-small-loading-spinner>
            </div>
          }
        </div>
        <app-underlined-form-field
          [parentForm]="calibrationForm"
          [field]="calibrationNameField"
        ></app-underlined-form-field>
        @if (!saveLocationsLoading) {
          <div>
            @if (slotList?.length > 0) {
              <app-underlined-dropdown-form-field
                [parentForm]="calibrationForm"
                [field]="saveLocationField"
              ></app-underlined-dropdown-form-field>
            }
            @if (!slotList) {
              <span class="subtitle1">No save locations reported</span>
            }
            @if (slotList?.length === 0) {
              <span class="subtitle1"
                >Select a device to view save locations</span
              >
            }
          </div>
        } @else {
          <app-small-loading-spinner></app-small-loading-spinner>
        }
        @if (calibrationModuleType === ModuleEnum.pH) {
          <app-underlined-form-field
            [parentForm]="pHForm"
            [field]="temperatureField"
            [step]="tempStep"
          ></app-underlined-form-field>
        }
      </div>
      @if (
        calibrationModuleType === ModuleEnum.BaseModule ||
        calibrationModuleType === ModuleEnum.Oxygen
      ) {
        <div class="advanced--container">
          <div class="form-field--container">
            <div>
              <app-slide-toggle
                [parentForm]="temperatureValuesForm"
                [field]="temperatureControlAField"
              ></app-slide-toggle>
              <app-underlined-form-field
                [parentForm]="temperatureValuesForm"
                [field]="inputTemperatureAField"
                [step]="temperatureStepOD"
              ></app-underlined-form-field>
            </div>
            <div>
              <app-slide-toggle
                [parentForm]="temperatureValuesForm"
                [field]="temperatureControlBField"
              ></app-slide-toggle>
              <app-underlined-form-field
                [parentForm]="temperatureValuesForm"
                [field]="inputTemperatureBField"
                [step]="temperatureStepOD"
              ></app-underlined-form-field>
            </div>
            <div>
              <app-slide-toggle
                [parentForm]="temperatureValuesForm"
                [field]="temperatureControlCField"
              ></app-slide-toggle>
              <app-underlined-form-field
                [parentForm]="temperatureValuesForm"
                [field]="inputTemperatureCField"
                [step]="temperatureStepOD"
              ></app-underlined-form-field>
            </div>
            <div>
              <app-slide-toggle
                [parentForm]="temperatureValuesForm"
                [field]="temperatureControlDField"
              ></app-slide-toggle>
              <app-underlined-form-field
                [parentForm]="temperatureValuesForm"
                [field]="inputTemperatureDField"
                [step]="temperatureStepOD"
              ></app-underlined-form-field>
            </div>
          </div>
        </div>
      }
      @if (calibrationModuleType === ModuleEnum.pH) {
        <div class="advanced--container">
          <h6>Add 2-5 buffers</h6>
          <div class="form-field--container">
            <app-underlined-dropdown-form-field
              [parentForm]="pHForm"
              [field]="pHBuffer1Field"
            ></app-underlined-dropdown-form-field>
            <app-underlined-dropdown-form-field
              [parentForm]="pHForm"
              [field]="pHBuffer2Field"
            ></app-underlined-dropdown-form-field>
            <app-underlined-dropdown-form-field
              [parentForm]="pHForm"
              [field]="pHBuffer3Field"
            ></app-underlined-dropdown-form-field>
            <app-underlined-dropdown-form-field
              [parentForm]="pHForm"
              [field]="pHBuffer4Field"
            ></app-underlined-dropdown-form-field>
            <app-underlined-dropdown-form-field
              [parentForm]="pHForm"
              [field]="pHBuffer5Field"
            ></app-underlined-dropdown-form-field>
          </div>
        </div>
      }
      @if (error) {
        <div id="error--container">
          <p class="error">Error: {{ error.message }}</p>
        </div>
      }
      <div id="button--container">
        <app-button
          label="return to dashboard"
          buttonId="dashboard-btn"
          buttonStyle="yellow"
          routerLink="dashboard"
        ></app-button>
        @if (
          calibrationModuleType === ModuleEnum.BaseModule ||
          calibrationModuleType === ModuleEnum.Oxygen
        ) {
          <app-button
            label="continue"
            type="submit"
            buttonId="continue-btn"
            [isProcessing]="isProcessing"
            [isDisabled]="
              this.calibrationForm.invalid || this.temperatureValuesForm.invalid
            "
            (buttonClicked)="onSubmitCreateCalibrationForm()"
          ></app-button>
        }
        @if (calibrationModuleType === ModuleEnum.pH) {
          <app-button
            label="continue"
            type="submit"
            buttonId="continue-btn"
            [isProcessing]="isProcessing"
            [isDisabled]="this.calibrationForm.invalid || this.pHForm.invalid"
            (buttonClicked)="onSubmitCreateCalibrationForm()"
          ></app-button>
        }
      </div>
    </div>
  </div>
} @else {
  <app-error-page
    title="No Lab found"
    primaryText="Sorry, you cannot access this page unless you are a member of a lab"
  ></app-error-page>
}
