<button
  [type]="buttonType"
  [id]="buttonId"
  (click)="clickButton()"
  [ngClass]="classes"
  [disabled]="isDisabled"
>
  <div class="icon--container">
    @if (iconName && !isProcessing) {
    <mat-icon [ngClass]="iconClasses">{{ iconName }}</mat-icon>
    }
    <div>
      @if (isProcessing) {
      <div class="loader"></div>
      }
    </div>
  </div>
  @if (label) {
  <span>{{ label }}</span>
  }
</button>
