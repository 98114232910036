<label
  [ngClass]="classes"
  [for]="'device_' + device.deviceId.toString()"
  [formGroup]="parentForm"
  [attr.aria-label]="'device_' + device.deviceId.toString()"
>
  <!-- BASE MODULE SELECT-->
  <select
    *ngIf="calibrationType === 5"
    class="body1"
    [formControlName]="'device_' + device.deviceId.toString()"
    [id]="'device_' + device.deviceId.toString()"
  >
    <option disabled selected [ngValue]="null">{{ placeholder }}</option>
    <option
      *ngFor="let calibration of device.calibrationList"
      [value]="calibration.id"
    >
      {{ calibration.name }}
    </option>
  </select>
  <!-- OXYGEN MODULE SELECT-->
  <select
    *ngIf="calibrationType === 1"
    class="body1"
    [formControlName]="'device_' + device.deviceId.toString() + '_oxy'"
    [id]="'device_' + device.deviceId.toString() + '_oxy'"
  >
    <option disabled selected [ngValue]="null">{{ placeholder }}</option>
    <option
      *ngFor="let calibration of device.oxygenCalibrationList"
      [value]="calibration.id"
    >
      {{ calibration.name }}
    </option>
  </select>
  <!-- PH MODULE SELECT-->
  <select
    *ngIf="calibrationType === 6"
    class="body1"
    [formControlName]="'device_' + device.deviceId.toString() + '_ph'"
    [id]="'device_' + device.deviceId.toString() + '_ph'"
  >
    <option disabled selected [ngValue]="null">{{ placeholder }}</option>
    <option
      *ngFor="let calibration of device.pHCalibrationList"
      [value]="calibration.id"
    >
      {{ calibration.name }}
    </option>
  </select>
</label>
