<div id="dashboard--container">
  <div id="header--container">
    <h4>Welcome to your dashboard.</h4>
    <h5>{{ laboratory?.laboratoryName }}</h5>
  </div>
  <hr class="alt" />
  <div id="nav--container">
    <div class="button--container">
      <app-option-tab-toggle
        id="toggle--container"
        radioGroup="navigationToggle"
        [selectOptions]="navOptions"
        type="round"
      >
      </app-option-tab-toggle>
      <button
        mat-mini-fab
        aria-label="reset search form"
        title="Refresh"
        color="primary"
        (click)="getExperiments()"
      >
        <mat-icon>restart_alt</mat-icon>
      </button>
    </div>
    <div class="button--container">
      <app-search-field
        [field]="searchField"
        [parentForm]="experimentTableControls"
        (buttonClicked)="handleSearch($event)"
      >
      </app-search-field>
      <app-icon-button
        label="filters"
        buttonStyle="secondary"
        buttonId="filter-btn"
        iconName="filter_list"
        buttonShape="square"
        (buttonClicked)="openModal('filter')"
        title="Filters"
      ></app-icon-button>
    </div>
  </div>
  @if (experiments?.length > 0) {
    <div id="inner-page--container">
      <p id="table-description" style="display: none">
        Experiments summary table
      </p>
      <div id="table--container">
        <table aria-describedby="table-description">
          <thead>
            <tr>
              <th
                id="experiment"
                scope="col"
                (click)="onSort('Name')"
                [class.active]="currentSort.sortBy === 'Name'"
              >
                <div id="table-header">
                  <span>Experiment</span>
                  <mat-icon
                    aria-label="sort direction"
                    matTooltip="Sort direction"
                    >swap_vert</mat-icon
                  >
                </div>
              </th>
              <th
                id="experimentMode"
                scope="col"
                (click)="onSort('ExperimentModes')"
                [class.active]="currentSort.sortBy === 'ExperimentModes'"
              >
                <div id="table-header">
                  <span>Experiment Type</span>
                  <mat-icon
                    aria-label="sort direction"
                    matTooltip="Sort direction"
                    >swap_vert</mat-icon
                  >
                </div>
              </th>
              <th
                id="createdAt"
                scope="col"
                (click)="onSort('CreatedAt')"
                [class.active]="currentSort.sortBy === 'CreatedAt'"
              >
                <div id="table-header">
                  <span>Created At</span>
                  <mat-icon
                    aria-label="sort direction"
                    matTooltip="Sort direction"
                    >swap_vert</mat-icon
                  >
                </div>
              </th>
              <th
                id="startDate"
                scope="col"
                (click)="onSort('StartTime')"
                [class.active]="currentSort.sortBy === 'StartTime'"
              >
                <div id="table-header">
                  <span>Start Date</span>
                  <mat-icon
                    aria-label="sort direction"
                    matTooltip="Sort direction"
                    >swap_vert</mat-icon
                  >
                </div>
              </th>
              <th
                id="time"
                scope="col"
                (click)="onSort('TimeLeft')"
                [class.active]="currentSort.sortBy === 'TimeLeft'"
              >
                <div id="table-header">
                  <span>Time Left</span>
                  <mat-icon
                    aria-label="sort direction"
                    matTooltip="Sort direction"
                    >swap_vert</mat-icon
                  >
                </div>
              </th>
              <th
                id="status"
                scope="col"
                (click)="onSort('ExperimentStatusId')"
                [class.active]="currentSort.sortBy === 'ExperimentStatusId'"
              >
                <div id="table-header">
                  <span>Status</span>
                  <mat-icon
                    aria-label="sort direction"
                    matTooltip="Sort direction"
                    >swap_vert</mat-icon
                  >
                </div>
              </th>
              <th
                id="createdBy"
                scope="col"
                (click)="onSort('CreatedBy')"
                [class.active]="currentSort.sortBy === 'CreatedBy'"
              >
                <div id="table-header">
                  <span>Created By</span>
                  <mat-icon
                    aria-label="sort direction"
                    matTooltip="Sort direction"
                    >swap_vert</mat-icon
                  >
                </div>
              </th>
              <th id="view" scope="col"></th>
            </tr>
          </thead>
          <tbody>
            @for (experiment of experiments; track experiment.id) {
              <tr>
                <td headers="experiment">{{ experiment.name }}</td>
                <td headers="experimentMode">
                  {{
                    ExperimentMode[experiment.experimentMode] | formatCamelCase
                  }}
                  <app-active-modules
                    [activeModules]="experiment.modules"
                  ></app-active-modules>
                </td>
                <td headers="createdAt">
                  {{
                    experiment.createdAt
                      ? (experiment.createdAt | date: "dd/MM/yy, HH:mm a")
                      : "-"
                  }}
                </td>
                <td headers="startDate">
                  {{
                    experiment.startTime
                      ? (experiment.startTime | date: "dd/MM/yy, HH:mm a")
                      : "-"
                  }}
                </td>
                <td headers="time">
                  @if (
                    experiment.experimentStatusId === ExperimentStatus.Active &&
                    (currentTime$ | async)
                  ) {
                    {{
                      getTimeLeft(
                        experiment.startTime,
                        experiment.duration,
                        $any(currentTime$ | async)
                      ).days | number: "2.0"
                    }}
                    :
                    {{
                      getTimeLeft(
                        experiment.startTime,
                        experiment.duration,
                        $any(currentTime$ | async)
                      ).hours | number: "2.0"
                    }}
                    :
                    {{
                      getTimeLeft(
                        experiment.startTime,
                        experiment.duration,
                        $any(currentTime$ | async)
                      ).minutes | number: "2.0"
                    }}
                    :
                    {{
                      getTimeLeft(
                        experiment.startTime,
                        experiment.duration,
                        $any(currentTime$ | async)
                      ).seconds | number: "2.0"
                    }}
                  } @else {
                    <span>-</span>
                  }
                </td>
                <td headers="status">
                  <app-status-indicator
                    [status]="ExperimentStatus[experiment.experimentStatusId]"
                  ></app-status-indicator>
                </td>
                <td headers="createdBy">{{ experiment.createdBy }}</td>
                <td headers="view">
                  <div>
                    @if (
                      experiment.experimentStatusId === ExperimentStatus.Draft
                    ) {
                      <app-button
                        label="View Draft"
                        buttonId="experiment_{{ experiment?.id }}"
                        [routerLink]="
                          '/experiment/' + experiment.id + '/set-parameters'
                        "
                        [isDisabled]="
                          currentUser.role | roleCheck: [Role.Read_Access_User]
                        "
                      ></app-button>
                    }
                    @if (
                      experiment.experimentStatusId ===
                      ExperimentStatus.DraftLocked
                    ) {
                      <app-button
                        label="View Draft"
                        buttonId="experiment_{{ experiment?.id }}"
                        [routerLink]="
                          '/experiment/' + experiment.id + '/device-setup'
                        "
                        [isDisabled]="
                          currentUser.role | roleCheck: [Role.Read_Access_User]
                        "
                      ></app-button>
                    }
                    @if (
                      experiment.experimentStatusId ===
                        ExperimentStatus.Active ||
                      experiment.experimentStatusId ===
                        ExperimentStatus.Complete ||
                      experiment.experimentStatusId === ExperimentStatus.Paused
                    ) {
                      <app-button
                        label="View"
                        buttonId="experiment_{{ experiment?.id }}"
                        [routerLink]="
                          '/experiment/' + experiment.id + '/display'
                        "
                      ></app-button>
                    }
                  </div>
                </td>
              </tr>
            }
          </tbody>
        </table>
        <div id="pagination--container">
          <div class="experiment-status-filter--container">
            <legend>Experiments per page</legend>
            <app-dropdown-form-field
              id="experiments-per-page"
              [field]="experimentsPerPageField"
              [parentForm]="experimentTableControls"
            >
            </app-dropdown-form-field>
          </div>
          <div id="pagination--controls">
            <app-icon-button
              iconName="chevron_left"
              buttonId="previous"
              (buttonClicked)="getPrevious()"
              [isDisabled]="!hasPrevious"
            ></app-icon-button>
            <span> {{ currentPage }} / {{ totalPages }} </span>
            <app-icon-button
              iconName="chevron_right"
              buttonId="next"
              (buttonClicked)="getNext()"
              [isDisabled]="!hasNext"
            ></app-icon-button>
          </div>
        </div>
      </div>
    </div>
  }
  @if (!loading && (!experiments || experiments.length === 0)) {
    <div id="cta--container">
      <h4>No experiments to display</h4>
      <app-icon-button
        label="reset"
        buttonStyle="secondary"
        buttonId="filter-btn"
        iconName="restart_alt"
        buttonShape="square"
        (buttonClicked)="resetForm()"
      ></app-icon-button>
      @if (error) {
        <p class="error">Error: {{ error.message }}</p>
      }
    </div>
  }
  @if (loading && (!experiments || experiments.length === 0)) {
    <div id="loading--container">
      <app-loading-spinner></app-loading-spinner>
    </div>
  }
</div>
<app-filter-modal
  id="filter"
  buttonType="double-button"
  primaryButtonText="Apply Filters"
  primaryButtonId="continue-button"
  secondaryButtonText="Reset"
  secondaryButtonId="reset-button"
  [statusField]="experimentStatusFilters"
  [modeField]="experimentModeFilters"
  [parentForm]="experimentTableControls"
  [hideDeletedField]="true"
  (modalButtonClicked)="modalButtonClicked($event)"
  [isProcessing]="loading"
></app-filter-modal>
