import { Injectable } from '@angular/core';
import { httpOptions } from '../_helpers/utils';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import {
  ApprovalUpdate,
  DeviceChangeRequest,
  DeviceIndexResponse,
  LaboratoryChangeRequest,
  LaboratoryIndexResponse,
  OrganisationChangeRequest,
  OrganisationIndexResponse,
  BufferChangeRequest,
  BufferIndexResponse,
  RequestStatus,
  RequestType,
  UserChangeRequest,
  UserIndexResponse,
} from '../_models/requests';
import { PaginationData } from '../_models/pagination-data.model';

@Injectable({
  providedIn: 'root',
})
export class ApprovalsService {
  httpOptions = httpOptions;

  // Pagination and sort/filter observables for device approvals index view
  private readonly deviceRequestTableSort$ = new BehaviorSubject<{
    sortBy: string;
    sortDirection: string;
  }>(null);
  private readonly deviceRequestTableFilter$ = new BehaviorSubject<string[]>(
    []
  );
  private readonly deviceRequestPageNumber$ = new BehaviorSubject<number>(null);
  private readonly deviceRequestPageSize$ = new BehaviorSubject<number>(null);

  // Pagination and sort/filter observables for lab approvals index view
  private readonly labRequestTableSort$ = new BehaviorSubject<{
    sortBy: string;
    sortDirection: string;
  }>(null);
  private readonly labRequestTableFilter$ = new BehaviorSubject<string[]>([]);
  private readonly labRequestPageNumber$ = new BehaviorSubject<number>(null);
  private readonly labRequestPageSize$ = new BehaviorSubject<number>(null);

  // Pagination and sort/filter observables for device approvals index view
  private readonly userRequestTableSort$ = new BehaviorSubject<{
    sortBy: string;
    sortDirection: string;
  }>(null);
  private readonly userRequestTableFilter$ = new BehaviorSubject<string[]>([]);
  private readonly userRequestPageNumber$ = new BehaviorSubject<number>(null);
  private readonly userRequestPageSize$ = new BehaviorSubject<number>(null);

  // Pagination and sort/filter observables for organisation approvals index view
  private readonly organisationRequestTableSort$ = new BehaviorSubject<{
    sortBy: string;
    sortDirection: string;
  }>(null);
  private readonly organisationRequestTableFilter$ = new BehaviorSubject<
    string[]
  >([]);
  private readonly organisationRequestPageNumber$ = new BehaviorSubject<number>(
    null
  );
  private readonly organisationRequestPageSize$ = new BehaviorSubject<number>(
    null
  );

  // Pagination and sort/filter observables for buffers approvals index view
  private readonly bufferRequestTableSort$ = new BehaviorSubject<{
    sortBy: string;
    sortDirection: string;
  }>(null);
  private readonly bufferRequestTableFilter$ = new BehaviorSubject<string[]>(
    []
  );
  private readonly bufferRequestPageNumber$ = new BehaviorSubject<number>(null);
  private readonly bufferRequestPageSize$ = new BehaviorSubject<number>(null);

  constructor(private readonly http: HttpClient) {}

  onUpdateDeviceSort = (sort: {
    sortBy: string;
    sortDirection: string;
  }): void => {
    this.deviceRequestTableSort$.next(sort);
  };

  onUpdateDeviceFilter = (filter: string[]): void => {
    this.deviceRequestTableFilter$.next(filter);
  };

  onUpdateDevicePageNumber = (pageNumber: number): void => {
    this.deviceRequestPageNumber$.next(pageNumber);
  };

  onUpdateDevicePageSize = (pageSize: number): void => {
    this.deviceRequestPageSize$.next(pageSize);
  };

  public get deviceFilter(): string[] {
    return this.deviceRequestTableFilter$.value;
  }

  public get deviceSort(): { sortBy: string; sortDirection: string } {
    return this.deviceRequestTableSort$.value;
  }

  public get devicePaginationCache(): { pageNumber: number; pageSize: number } {
    if (
      this.deviceRequestPageNumber$.value !== null &&
      this.deviceRequestPageSize$.value !== null
    ) {
      return {
        pageNumber: this.deviceRequestPageNumber$.value,
        pageSize: this.deviceRequestPageSize$.value,
      };
    } else {
      return null;
    }
  }

  onUpdateLabSort = (sort: { sortBy: string; sortDirection: string }): void => {
    this.labRequestTableSort$.next(sort);
  };

  onUpdateLabFilter = (filter: string[]): void => {
    this.labRequestTableFilter$.next(filter);
  };

  onUpdateLabPageNumber = (pageNumber: number): void => {
    this.labRequestPageNumber$.next(pageNumber);
  };

  onUpdateLabPageSize = (pageSize: number): void => {
    this.labRequestPageSize$.next(pageSize);
  };

  public get labFilter(): string[] {
    return this.labRequestTableFilter$.value;
  }

  public get labSort(): { sortBy: string; sortDirection: string } {
    return this.labRequestTableSort$.value;
  }

  public get labPaginationCache(): { pageNumber: number; pageSize: number } {
    if (
      this.labRequestPageNumber$.value !== null &&
      this.labRequestPageSize$.value !== null
    ) {
      return {
        pageNumber: this.labRequestPageNumber$.value,
        pageSize: this.labRequestPageSize$.value,
      };
    } else {
      return null;
    }
  }

  onUpdateUserSort = (sort: {
    sortBy: string;
    sortDirection: string;
  }): void => {
    this.userRequestTableSort$.next(sort);
  };

  onUpdateUserFilter = (filter: string[]): void => {
    this.userRequestTableFilter$.next(filter);
  };

  onUpdateUserPageNumber = (pageNumber: number): void => {
    this.userRequestPageNumber$.next(pageNumber);
  };

  onUpdateUserPageSize = (pageSize: number): void => {
    this.userRequestPageSize$.next(pageSize);
  };

  public get userFilter(): string[] {
    return this.userRequestTableFilter$.value;
  }

  public get userSort(): { sortBy: string; sortDirection: string } {
    return this.userRequestTableSort$.value;
  }

  public get userPaginationCache(): { pageNumber: number; pageSize: number } {
    if (
      this.userRequestPageNumber$.value !== null &&
      this.userRequestPageSize$.value !== null
    ) {
      return {
        pageNumber: this.userRequestPageNumber$.value,
        pageSize: this.userRequestPageSize$.value,
      };
    } else {
      return null;
    }
  }

  onUpdateOrganisationSort = (sort: {
    sortBy: string;
    sortDirection: string;
  }): void => {
    this.organisationRequestTableSort$.next(sort);
  };

  onUpdateOrganisationFilter = (filter: string[]): void => {
    this.organisationRequestTableFilter$.next(filter);
  };

  onUpdateOrganisationPageNumber = (pageNumber: number): void => {
    this.organisationRequestPageNumber$.next(pageNumber);
  };

  onUpdateOrganisationPageSize = (pageSize: number): void => {
    this.organisationRequestPageSize$.next(pageSize);
  };

  public get organisationFilter(): string[] {
    return this.organisationRequestTableFilter$.value;
  }

  public get organisationSort(): { sortBy: string; sortDirection: string } {
    return this.organisationRequestTableSort$.value;
  }

  public get organisationPaginationCache(): {
    pageNumber: number;
    pageSize: number;
  } {
    if (
      this.organisationRequestPageNumber$.value !== null &&
      this.organisationRequestPageSize$.value !== null
    ) {
      return {
        pageNumber: this.organisationRequestPageNumber$.value,
        pageSize: this.organisationRequestPageSize$.value,
      };
    } else {
      return null;
    }
  }

  onUpdateBufferSort = (sort: {
    sortBy: string;
    sortDirection: string;
  }): void => {
    this.bufferRequestTableSort$.next(sort);
  };

  onUpdateBufferFilter = (filter: string[]): void => {
    this.bufferRequestTableFilter$.next(filter);
  };

  onUpdateBufferPageNumber = (pageNumber: number): void => {
    this.bufferRequestPageNumber$.next(pageNumber);
  };

  onUpdateBufferPageSize = (pageSize: number): void => {
    this.bufferRequestPageSize$.next(pageSize);
  };

  public get bufferFilter(): string[] {
    return this.bufferRequestTableFilter$.value;
  }

  public get bufferSort(): { sortBy: string; sortDirection: string } {
    return this.bufferRequestTableSort$.value;
  }

  public get bufferPaginationCache(): {
    pageNumber: number;
    pageSize: number;
  } {
    if (
      this.bufferRequestPageNumber$.value !== null &&
      this.bufferRequestPageSize$.value !== null
    ) {
      return {
        pageNumber: this.bufferRequestPageNumber$.value,
        pageSize: this.bufferRequestPageSize$.value,
      };
    } else {
      return null;
    }
  }

  // HTTP GET DEVICE APPROVALS
  getDeviceApprovals(
    sortBy = 'Name',
    sortOrder = 'asc',
    pageSize = 5,
    pageNumber = 1,
    requestStatus: RequestStatus = null,
    requestType: RequestType = null
  ): Observable<DeviceIndexResponse> {
    let url = `${environment.apiUrl}/device-requests?OrderBy=${sortBy}&OrderByDirection=${sortOrder}&PageSize=${pageSize}&PageNumber=${pageNumber}`;
    if (requestStatus) {
      url += `&RequestStatus=${requestStatus}`;
    }
    if (requestType) {
      url += `&RequestType=${requestType}`;
    }
    return this.http.get<DeviceChangeRequest[]>(url, this.httpOptions).pipe(
      map((response) => {
        // Set pagination data
        const paginationData = JSON.parse(
          response.headers.get('X-Pagination')
        ) as PaginationData;
        const deviceChangeRequests = response.body;
        return {
          paginationData,
          deviceChangeRequests,
        };
      })
    );
  }

  actionDeviceRequest(approvalUpdate: ApprovalUpdate): Observable<unknown> {
    return this.http
      .patch<unknown>(
        `${environment.apiUrl}/device-requests/${approvalUpdate.id}`,
        approvalUpdate,
        this.httpOptions
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getLabApprovals(
    sortBy = 'Name',
    sortOrder = 'asc',
    pageSize = 5,
    pageNumber = 1,
    requestStatus: RequestStatus = null,
    requestType: RequestType = null
  ): Observable<LaboratoryIndexResponse> {
    let url = `${environment.apiUrl}/laboratory-requests?OrderBy=${sortBy}&OrderByDirection=${sortOrder}&PageSize=${pageSize}&PageNumber=${pageNumber}`;
    if (requestStatus) {
      url += `&RequestStatus=${requestStatus}`;
    }
    if (requestType) {
      url += `&RequestType=${requestType}`;
    }
    return this.http.get<LaboratoryChangeRequest[]>(url, this.httpOptions).pipe(
      map((response) => {
        const paginationData = JSON.parse(
          response.headers.get('X-Pagination')
        ) as PaginationData;
        const laboratoryChangeRequests = response.body;
        return {
          paginationData,
          laboratoryChangeRequests,
        };
      })
    );
  }

  actionLabRequest(approvalUpdate: ApprovalUpdate): Observable<unknown> {
    return this.http
      .patch<unknown>(
        `${environment.apiUrl}/laboratory-requests/${approvalUpdate.id}`,
        approvalUpdate,
        this.httpOptions
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getUserApprovals(
    sortBy = 'Name',
    sortOrder = 'asc',
    pageSize = 5,
    pageNumber = 1,
    requestStatus: RequestStatus = null,
    requestType: RequestType = null
  ): Observable<UserIndexResponse> {
    let url = `${environment.apiUrl}/user-requests?OrderBy=${sortBy}&OrderByDirection=${sortOrder}&PageSize=${pageSize}&PageNumber=${pageNumber}`;
    if (requestStatus) {
      url += `&RequestStatus=${requestStatus}`;
    }
    if (requestType) {
      url += `&RequestType=${requestType}`;
    }
    return this.http.get<UserChangeRequest[]>(url, this.httpOptions).pipe(
      map((response) => {
        const paginationData = JSON.parse(
          response.headers.get('X-Pagination')
        ) as PaginationData;
        const userChangeRequests = response.body;
        return {
          paginationData,
          userChangeRequests,
        };
      })
    );
  }

  actionUserRequest(approvalUpdate: ApprovalUpdate): Observable<unknown> {
    return this.http
      .patch<unknown>(
        `${environment.apiUrl}/user-requests/${approvalUpdate.id}`,
        approvalUpdate,
        this.httpOptions
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getOrganisationApprovals(
    sortBy = 'Name',
    sortOrder = 'asc',
    pageSize = 5,
    pageNumber = 1,
    requestStatus: RequestStatus = null,
    requestType: RequestType = null
  ): Observable<OrganisationIndexResponse> {
    let url = `${environment.apiUrl}/organisation-requests?OrderBy=${sortBy}&OrderByDirection=${sortOrder}&PageSize=${pageSize}&PageNumber=${pageNumber}`;
    if (requestStatus) {
      url += `&RequestStatus=${requestStatus}`;
    }
    if (requestType) {
      url += `&RequestType=${requestType}`;
    }
    return this.http
      .get<OrganisationChangeRequest[]>(url, this.httpOptions)
      .pipe(
        map((response) => {
          const paginationData = JSON.parse(
            response.headers.get('X-Pagination')
          ) as PaginationData;
          const organisationChangeRequests = response.body;
          return {
            paginationData,
            organisationChangeRequests,
          };
        })
      );
  }

  actionOrganisationRequest(
    approvalUpdate: ApprovalUpdate
  ): Observable<unknown> {
    return this.http
      .patch<unknown>(
        `${environment.apiUrl}/organisation-requests/${approvalUpdate.id}`,
        approvalUpdate,
        this.httpOptions
      )
      .pipe(
        map((response) => {
          console.log(response);
          return response;
        })
      );
  }

  getBufferApprovals(
    sortBy = 'Name',
    sortOrder = 'asc',
    pageSize = 5,
    pageNumber = 1,
    requestStatus: RequestStatus = null,
    requestType: RequestType = null
  ): Observable<BufferIndexResponse> {
    let url = `${environment.apiUrl}/phbuffer-requests?OrderBy=${sortBy}&OrderByDirection=${sortOrder}&PageSize=${pageSize}&PageNumber=${pageNumber}`;
    if (requestStatus) {
      url += `&RequestStatus=${requestStatus}`;
    }
    if (requestType) {
      url += `&RequestType=${requestType}`;
    }
    return this.http.get<BufferChangeRequest[]>(url, this.httpOptions).pipe(
      map((response) => {
        const paginationData = JSON.parse(
          response.headers.get('X-Pagination')
        ) as PaginationData;
        const bufferChangeRequests = response.body;
        return {
          paginationData,
          bufferChangeRequests,
        };
      })
    );
  }

  actionBufferRequest(approvalUpdate: ApprovalUpdate): Observable<unknown> {
    return this.http
      .patch<unknown>(
        `${environment.apiUrl}/phbuffer-requests/${approvalUpdate.id}`,
        approvalUpdate,
        this.httpOptions
      )
      .pipe(
        map((response) => {
          console.log(response);
          return response;
        })
      );
  }
}
