/* eslint-disable brace-style */
import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { Laboratory } from 'src/app/_models/laboratory';
import { TabOptions } from 'src/app/_models/tab-options';
import { User } from 'src/app/_models/user';
import { AuthenticationService } from '../_services/authentication.service';
import {
  ApprovalUpdate,
  DeviceChangeRequest,
  LaboratoryChangeRequest,
  OrganisationChangeRequest,
  RequestStatus,
  RequestType,
  UserChangeRequest,
} from '../_models/requests';
import { ErrorObject } from '../_models/error';
import { ApprovalsService } from '../_services/approvals.service';
import { Role, RoleEnum } from '../_models/role';
import { FormBase } from '../_models/form-base';
import { FormBuilder, FormControl } from '@angular/forms';
import { ModalService } from '../_services/modal.service';
import { LicenseType } from '../_models/license-type';
import { PaginationData } from '../_models/pagination-data.model';
import { FormatRolePipe } from '../_helpers/format-role.pipe';
import { ProvideInfoModalComponent } from '../_components/modals/provide-info-modal/provide-info-modal.component';
import { ModalComponent } from '../_components/modals/modal.component';
import { LoadingSpinnerComponent } from '../_components/loading-spinner/loading-spinner.component';
import { IconButtonComponent } from '../_components/buttons/icon-button/icon-button.component';
import { DropdownFormFieldComponent } from '../_components/forms/dropdown-form-field/dropdown-form-field.component';
import { ButtonComponent } from '../_components/buttons/button/button.component';
import { MatTooltip } from '@angular/material/tooltip';
import { MatIcon } from '@angular/material/icon';
import { OptionTabToggleComponent } from '../_components/selection-controls/option-tab-toggle/option-tab-toggle.component';
import { DatePipe } from '@angular/common';

// TODO: sort and filter

@Component({
  selector: 'app-approvals',
  template: '',
  standalone: true,
})
export class ApprovalsComponent implements OnInit {
  protected authenticationService = inject(AuthenticationService);
  protected approvalsService = inject(ApprovalsService);
  protected formBuilder = inject(FormBuilder);
  protected modalService = inject(ModalService);

  //Routing
  navOptions: TabOptions[];
  // Permissions
  laboratory: Laboratory;
  currentUser: User;

  // To make them available in the template
  RequestType = RequestType;
  RequestStatus = RequestStatus;
  Role = Role;
  RoleEnum = RoleEnum;

  // Loading status
  loading = true;
  error: ErrorObject;

  // Table control variables
  currentFilters = [];
  currentSort: {
    sortBy: string;
    sortDirection: string;
  } = {
    sortBy: 'Name',
    sortDirection: 'asc',
  };

  selectedRequestType: RequestType = null;
  selectedRequestStatus: RequestStatus = null;

  // Pagination
  pageRequested = 1;
  pageSize = 5;
  totalCount = 0;
  totalPages = 0;
  currentPage = 1;
  hasNext = false;
  hasPrevious = false;

  requestsPerPageField = new FormBase({
    key: 'requestsPerPage',
    label: 'Page Size',
    type: 'dropdown',
    placeholder: '',
    disabled: false,
    required: false,
    value: '5', // Set default value to 5
    options: [
      { key: '5', value: '5' },
      { key: '10', value: '10' },
      { key: '25', value: '25' },
      { key: '50', value: '50' },
      { key: '100', value: '100' },
    ],
  });

  requestActionFilters = new FormBase({
    key: 'requestAction',
    label: 'Select action to filter by:',
    type: 'dropdown',
    placeholder: 'Filter by action',
    disabled: false,
    required: false,
    value: null,
    options: [
      { key: null, value: 'All' },
      { key: RequestType.Create, value: 'Create' },
      { key: RequestType.Update, value: 'Update' },
      { key: RequestType.Delete, value: 'Delete' },
    ],
  });

  requestStatusFilters = new FormBase({
    key: 'requestStatus',
    label: 'Request Status',
    type: 'dropdown',
    placeholder: '',
    disabled: false,
    required: false,
    value: null,
    options: [
      { key: null, value: 'All' },
      { key: RequestStatus.Pending, value: 'Pending' },
      { key: RequestStatus.Approved, value: 'Approved' },
      { key: RequestStatus.Rejected, value: 'Rejected' },
    ],
  });

  tableControls = this.formBuilder.group({
    [this.requestsPerPageField.key]: new FormControl(
      this.requestsPerPageField.value,
    ),
    [this.requestActionFilters.key]: new FormControl(
      this.requestActionFilters.value,
    ),
    [this.requestStatusFilters.key]: new FormControl(
      this.requestStatusFilters.value,
    ),
  });

  ngOnInit(): void {
    this.navOptions = [
      {
        value: 'labs',
        labelText: 'Labs',
        link: '/approvals/labs',
      },
      {
        value: 'users',
        labelText: 'Users',
        link: '/approvals/users',
      },
      {
        value: 'devices',
        labelText: 'Devices',
        link: '/approvals/devices',
      },
      {
        value: ' organisations',
        labelText: 'Organisations',
        link: '/approvals/organisations',
      },
    ];

    this.currentUser = this.authenticationService.currentUserValue;
    this.laboratory = this.authenticationService.selectedLaboratory;
  }

  updatePagination(paginationData: PaginationData, approvalType: string): void {
    const { CurrentPage, PageSize, TotalPages, HasNext, HasPrevious } =
      paginationData;
    this.currentPage = CurrentPage;
    this.pageSize = PageSize;
    this.totalPages = TotalPages;
    this.hasNext = HasNext;
    this.hasPrevious = HasPrevious;

    switch (approvalType) {
      case 'lab':
        this.approvalsService.onUpdateLabPageNumber(this.currentPage);
        this.approvalsService.onUpdateLabPageSize(this.pageSize);
        break;
      case 'user':
        this.approvalsService.onUpdateUserPageNumber(this.currentPage);
        this.approvalsService.onUpdateUserPageSize(this.pageSize);
        break;
      case 'device':
        this.approvalsService.onUpdateDevicePageNumber(this.currentPage);
        this.approvalsService.onUpdateDevicePageSize(this.pageSize);
        break;
      case 'organisation':
        this.approvalsService.onUpdateOrganisationPageNumber(this.currentPage);
        this.approvalsService.onUpdateOrganisationPageSize(this.pageSize);
        break;
    }
  }

  closeModal(modal: string): void {
    this.modalService.close(modal);
  }

  openModal(modal: string): void {
    this.modalService.open(modal);
  }

  modalButtonClicked(buttonId: string): void {
    switch (buttonId) {
      case 'close':
        this.closeModal('success');
        break;
      case 'close-rejection-success':
        this.closeModal('rejection-success');
        break;
      case 'close-rejection-warning':
        this.closeModal('rejection-fail');
        break;
    }
  }
}

@Component({
  selector: 'app-labs-approval-component',
  templateUrl: './labs/lab-approvals.component.html',
  styleUrls: ['./approvals.component.scss'],
  standalone: true,
  imports: [
    OptionTabToggleComponent,
    MatIcon,
    MatTooltip,
    ButtonComponent,
    DropdownFormFieldComponent,
    IconButtonComponent,
    LoadingSpinnerComponent,
    ModalComponent,
    ProvideInfoModalComponent,
    DatePipe,
  ],
})
export class LabsApprovalComponent
  extends ApprovalsComponent
  implements OnInit, OnDestroy
{
  laboratoryChangeRequests: LaboratoryChangeRequest[] = [];
  labApprovalUpdate: ApprovalUpdate = {
    id: null,
    requestStatus: null,
  };

  authenticationService = inject(AuthenticationService);
  approvalsService = inject(ApprovalsService);
  formBuilder = inject(FormBuilder);
  modalService = inject(ModalService);

  ngOnInit(): void {
    super.ngOnInit();

    // Check for saved sort, filter, and pagination settings
    if (this.approvalsService.labFilter !== null) {
      this.currentFilters = this.approvalsService.labFilter;
    }

    if (this.approvalsService.labSort !== null) {
      this.currentSort = this.approvalsService.labSort;
    }

    if (this.approvalsService.labPaginationCache !== null) {
      this.pageRequested = this.approvalsService.labPaginationCache.pageNumber;
      this.pageSize = this.approvalsService.labPaginationCache.pageSize;
      this.tableControls.patchValue({
        [this.requestsPerPageField.key]: [this.pageSize],
      });
    }

    this.getLabRequests();

    // Subscribe to the value changes of the lab requests page dropdown
    this.tableControls
      .get(this.requestsPerPageField.key)
      .valueChanges.subscribe((numOfPages) => {
        this.pageSize = numOfPages as number;
        // Also reset the page number to 1 as we are changing the pagination range
        this.pageRequested = 1;
        this.getLabRequests();
      });

    // Subscribe to the value changes of the request status dropdown
    this.tableControls
      .get(this.requestStatusFilters.key)
      .valueChanges.subscribe((status) => {
        this.selectedRequestStatus = status as RequestStatus;
        this.getLabRequests();
      });

    // Subscribe to the value changes of the request action dropdown
    this.tableControls
      .get(this.requestActionFilters.key)
      .valueChanges.subscribe((action) => {
        this.selectedRequestType = action as RequestType;
        this.getLabRequests();
      });
  }

  getPrevious(): void {
    this.pageRequested = this.currentPage - 1;
    this.getLabRequests();
  }

  getNext(): void {
    this.pageRequested = this.currentPage + 1;
    this.getLabRequests();
  }

  getLabRequests(): void {
    this.loading = true;
    this.error = null;
    this.approvalsService
      .getLabApprovals(
        this.currentSort.sortBy,
        this.currentSort.sortDirection,
        this.pageSize,
        this.pageRequested,
        this.selectedRequestStatus,
        this.selectedRequestType,
      )
      .subscribe({
        next: ({ laboratoryChangeRequests, paginationData }) => {
          this.laboratoryChangeRequests = laboratoryChangeRequests;
          this.updatePagination(paginationData, 'lab');
          this.loading = false;
        },
        error: (error: ErrorObject) => {
          this.loading = false;
          this.error = error;
          this.openModal('rejection-fail');
        },
      });
  }

  onSort(sortTerm: string): void {
    if (this.currentSort.sortBy === sortTerm) {
      if (this.currentSort.sortDirection === 'asc') {
        this.currentSort.sortDirection = 'desc';
      } else {
        this.currentSort.sortDirection = 'asc';
      }
    } else {
      this.currentSort.sortBy = sortTerm;
      this.currentSort.sortDirection = 'asc';
    }
    this.getLabRequests();
  }

  approveLabRequest(request: LaboratoryChangeRequest): void {
    this.loading = true;
    this.error = null;
    const payload: ApprovalUpdate = {
      id: request.id,
      requestStatus: RequestStatus.Approved,
    };

    this.approvalsService.actionLabRequest(payload).subscribe({
      next: () => {
        this.loading = false;
        // Open success modal
        this.openModal('success');
        // Make fresh request to get updated list
        this.getLabRequests();
      },
      error: (error: ErrorObject) => {
        this.error = error;
        this.loading = false;
        this.openModal('rejection-fail');
      },
    });
  }

  openRejectRequestModal(request: UserChangeRequest): void {
    this.labApprovalUpdate.id = request.id;
    this.labApprovalUpdate.requestStatus = RequestStatus.Rejected;
    // Open modal to provide reason for rejection
    this.openModal('provide-info');
  }

  rejectRequest(rejectionReason: ApprovalUpdate): void {
    this.loading = true;
    this.error = null;
    this.approvalsService.actionLabRequest(rejectionReason).subscribe({
      next: () => {
        this.loading = false;
        this.closeModal('provide-info');
        this.openModal('rejection-success');
        // Make fresh request to get updated list
        this.getLabRequests();
      },
      error: (error: ErrorObject) => {
        this.loading = false;
        this.error = error;
        this.closeModal('provide-info');
        this.openModal('rejection-fail');
      },
    });
  }

  ngOnDestroy(): void {
    // Reset the filter, sort, and pagination settings
    this.approvalsService.onUpdateLabFilter([]);
    this.approvalsService.onUpdateLabSort({
      sortBy: 'Name',
      sortDirection: 'asc',
    });
    this.approvalsService.onUpdateLabPageNumber(1);
    this.approvalsService.onUpdateLabPageSize(5);
  }
}

@Component({
  selector: 'app-users-approval-component',
  templateUrl: './users/user-approvals.component.html',
  styleUrls: ['./approvals.component.scss'],
  standalone: true,
  imports: [
    OptionTabToggleComponent,
    MatIcon,
    MatTooltip,
    ButtonComponent,
    DropdownFormFieldComponent,
    IconButtonComponent,
    LoadingSpinnerComponent,
    ModalComponent,
    ProvideInfoModalComponent,
    DatePipe,
    FormatRolePipe,
  ],
})
export class UsersApprovalComponent
  extends ApprovalsComponent
  implements OnInit, OnDestroy
{
  userChangeRequests: UserChangeRequest[] = [];
  userApprovalUpdate: ApprovalUpdate = {
    id: null,
    requestStatus: null,
  };

  // Make the Role enum available in the template
  Role = Role;

  authenticationService = inject(AuthenticationService);
  approvalsService = inject(ApprovalsService);
  formBuilder = inject(FormBuilder);
  modalService = inject(ModalService);

  ngOnInit(): void {
    super.ngOnInit();

    // Check for saved sort, filter, and pagination settings
    if (this.approvalsService.userFilter !== null) {
      this.currentFilters = this.approvalsService.userFilter;
    }

    if (this.approvalsService.userSort !== null) {
      this.currentSort = this.approvalsService.userSort;
    }

    if (this.approvalsService.userPaginationCache !== null) {
      this.pageRequested = this.approvalsService.userPaginationCache.pageNumber;
      this.pageSize = this.approvalsService.userPaginationCache.pageSize;
      this.tableControls.patchValue({
        [this.requestsPerPageField.key]: [this.pageSize],
      });
    }

    // Subscribe to the value changes of the user requests per page dropdown
    this.tableControls
      .get(this.requestsPerPageField.key)
      .valueChanges.subscribe((numOfPages) => {
        this.pageSize = numOfPages as number;
        // Also reset the page number to 1 as we are changing the pagination range
        this.pageRequested = 1;
        this.getUserRequests();
      });

    // Subscribe to the value change on experiment status dropdown
    this.tableControls
      .get(this.requestStatusFilters.key)
      .valueChanges.subscribe((action) => {
        this.selectedRequestStatus = action as RequestStatus;
        this.getUserRequests();
      });

    // Subscribe to the value changes of the request action dropdown
    this.tableControls
      .get(this.requestActionFilters.key)
      .valueChanges.subscribe((action) => {
        this.selectedRequestType = action as RequestType;
        this.getUserRequests();
      });

    this.getUserRequests();
  }

  getPrevious(): void {
    this.pageRequested = this.currentPage - 1;
    this.getUserRequests();
  }

  getNext(): void {
    this.pageRequested = this.currentPage + 1;
    this.getUserRequests();
  }

  getUserRequests(): void {
    this.loading = true;
    this.error = null;
    this.approvalsService
      .getUserApprovals(
        this.currentSort.sortBy,
        this.currentSort.sortDirection,
        this.pageSize,
        this.pageRequested,
        this.selectedRequestStatus,
        this.selectedRequestType,
      )
      .subscribe({
        next: (requests) => {
          this.userChangeRequests = requests.userChangeRequests;
          this.currentPage = requests.paginationData.CurrentPage;
          this.pageSize = requests.paginationData.PageSize;
          this.approvalsService.onUpdateUserPageNumber(this.currentPage);
          this.approvalsService.onUpdateUserPageSize(this.pageSize);
          this.totalPages = requests.paginationData.TotalPages;
          this.hasNext = requests.paginationData.HasNext;
          this.hasPrevious = requests.paginationData.HasPrevious;
          this.loading = false;
        },
        error: (error: ErrorObject) => {
          this.error = error;
          this.loading = false;
          this.openModal('rejection-fail');
        },
      });
  }

  onSort(sortTerm: string): void {
    if (this.currentSort.sortBy === sortTerm) {
      if (this.currentSort.sortDirection === 'asc') {
        this.currentSort.sortDirection = 'desc';
      } else {
        this.currentSort.sortDirection = 'asc';
      }
    } else {
      this.currentSort.sortBy = sortTerm;
      this.currentSort.sortDirection = 'asc';
    }
    this.getUserRequests();
  }

  approveRequest(request: UserChangeRequest): void {
    this.loading = true;
    this.error = null;
    const payload: ApprovalUpdate = {
      id: request.id,
      requestStatus: RequestStatus.Approved,
    };

    this.approvalsService.actionUserRequest(payload).subscribe({
      next: () => {
        this.loading = false;
        // Open success modal
        this.openModal('success');
        // Make fresh request to get updated list
        this.getUserRequests();
      },
      error: (error: ErrorObject) => {
        this.loading = false;
        this.error = error;
        this.openModal('rejection-fail');
      },
    });
  }

  openRejectRequestModal(request: LaboratoryChangeRequest): void {
    this.userApprovalUpdate.id = request.id;
    this.userApprovalUpdate.requestStatus = RequestStatus.Rejected;
    // Open modal to provide reason for rejection
    this.openModal('provide-info');
  }

  rejectRequest(rejectionReason: ApprovalUpdate): void {
    this.loading = true;
    this.error = null;
    this.approvalsService.actionUserRequest(rejectionReason).subscribe({
      next: () => {
        this.loading = false;
        this.closeModal('provide-info');
        this.openModal('rejection-success');
        // Make fresh request to get updated list
        this.getUserRequests();
      },
      error: (error: ErrorObject) => {
        this.loading = false;
        this.error = error;
        this.closeModal('provide-info');
        this.openModal('rejection-fail');
      },
    });
  }

  getLabList(userLabs: Laboratory[]): string {
    // Get the names of the labs the user is associated with
    // by iterating through the userLaboratories array
    return userLabs.map((lab) => lab.laboratoryName).join(', ');
  }

  ngOnDestroy(): void {
    // Reset the filter, sort, and pagination settings
    this.approvalsService.onUpdateUserFilter([]);
    this.approvalsService.onUpdateUserSort({
      sortBy: 'Name',
      sortDirection: 'asc',
    });
    this.approvalsService.onUpdateUserPageNumber(1);
    this.approvalsService.onUpdateUserPageSize(5);
  }
}

@Component({
  selector: 'app-devices-approval-component',
  templateUrl: './devices/device-approvals.component.html',
  styleUrls: ['./approvals.component.scss'],
  standalone: true,
  imports: [
    OptionTabToggleComponent,
    MatIcon,
    MatTooltip,
    ButtonComponent,
    DropdownFormFieldComponent,
    IconButtonComponent,
    LoadingSpinnerComponent,
    ModalComponent,
    ProvideInfoModalComponent,
    DatePipe,
  ],
})
export class DevicesApprovalComponent
  extends ApprovalsComponent
  implements OnInit, OnDestroy
{
  deviceChangeRequests: DeviceChangeRequest[] = [];
  deviceApprovalUpdate: ApprovalUpdate = {
    id: null,
    requestStatus: null,
  };

  authenticationService = inject(AuthenticationService);
  approvalsService = inject(ApprovalsService);
  formBuilder = inject(FormBuilder);
  modalService = inject(ModalService);

  ngOnInit(): void {
    super.ngOnInit();

    // Check for saved sort, filter, and pagination settings
    if (this.approvalsService.deviceFilter !== null) {
      this.currentFilters = this.approvalsService.deviceFilter;
    }

    if (this.approvalsService.deviceSort !== null) {
      this.currentSort = this.approvalsService.deviceSort;
    }

    if (this.approvalsService.devicePaginationCache !== null) {
      this.pageRequested =
        this.approvalsService.devicePaginationCache.pageNumber;
      this.pageSize = this.approvalsService.devicePaginationCache.pageSize;
      this.tableControls.patchValue({
        [this.requestsPerPageField.key]: [this.pageSize],
      });
    }

    this.getDeviceRequests();

    // Subscribe to the value changes of the device requests per page dropdown
    this.tableControls
      .get(this.requestsPerPageField.key)
      .valueChanges.subscribe((numOfPages) => {
        this.pageSize = numOfPages as number;
        // Also reset the page number to 1 as we are changing the pagination range
        this.pageRequested = 1;
        this.getDeviceRequests();
      });

    // Subscribe to the value changes of the request status dropdown
    this.tableControls
      .get(this.requestStatusFilters.key)
      .valueChanges.subscribe((status) => {
        this.selectedRequestStatus = status as RequestStatus;
        this.getDeviceRequests();
      });

    // Subscribe to the value changes of the request action dropdown
    this.tableControls
      .get(this.requestActionFilters.key)
      .valueChanges.subscribe((action) => {
        this.selectedRequestType = action as RequestType;
        this.getDeviceRequests();
      });
  }

  getPrevious(): void {
    this.pageRequested = this.currentPage - 1;
    this.getDeviceRequests();
  }

  getNext(): void {
    this.pageRequested = this.currentPage + 1;
    this.getDeviceRequests();
  }

  getDeviceRequests(): void {
    this.loading = true;
    this.error = null;
    this.approvalsService
      .getDeviceApprovals(
        this.currentSort.sortBy,
        this.currentSort.sortDirection,
        this.pageSize,
        this.pageRequested,
        this.selectedRequestStatus,
        this.selectedRequestType,
      )
      .subscribe({
        next: (requests) => {
          const { deviceChangeRequests, paginationData } = requests;
          this.deviceChangeRequests = deviceChangeRequests;
          this.updatePagination(paginationData, 'device');
          this.loading = false;
        },
        error: (error: ErrorObject) => {
          this.error = error;
          this.loading = false;
          this.openModal('rejection-fail');
        },
      });
  }

  onSort(sortTerm: string): void {
    if (this.currentSort.sortBy === sortTerm) {
      if (this.currentSort.sortDirection === 'asc') {
        this.currentSort.sortDirection = 'desc';
      } else {
        this.currentSort.sortDirection = 'asc';
      }
    } else {
      this.currentSort.sortBy = sortTerm;
      this.currentSort.sortDirection = 'asc';
    }
    this.getDeviceRequests();
  }

  approveDeviceRequest(request: DeviceChangeRequest): void {
    this.loading = true;
    this.error = null;
    const payload: ApprovalUpdate = {
      id: request.id,
      requestStatus: RequestStatus.Approved,
    };

    this.approvalsService.actionDeviceRequest(payload).subscribe({
      next: () => {
        this.loading = false;
        // Open success modal
        this.openModal('success');
        // Make fresh request to get updated list
        this.getDeviceRequests();
      },
      error: (error: ErrorObject) => {
        this.error = error;
        this.loading = false;
        this.openModal('rejection-fail');
      },
    });
  }

  openRejectRequestModal(request: DeviceChangeRequest): void {
    this.deviceApprovalUpdate.id = request.id;
    this.deviceApprovalUpdate.requestStatus = RequestStatus.Rejected;
    // Open modal to provide reason for rejection
    this.openModal('provide-info');
  }

  rejectRequest(rejectionReason: ApprovalUpdate): void {
    this.loading = true;
    this.error = null;
    this.approvalsService.actionDeviceRequest(rejectionReason).subscribe({
      next: () => {
        this.loading = false;
        this.closeModal('provide-info');
        this.openModal('rejection-success');
        // Make fresh request to get updated list
        this.getDeviceRequests();
      },
      error: (error: ErrorObject) => {
        this.loading = false;
        this.error = error;
        this.closeModal('provide-info');
        this.openModal('rejection-fail');
      },
    });
  }

  getActionedDate(request: DeviceChangeRequest): string | Date {
    return request.updatedAt === request.createdAt ? '-' : request.updatedAt;
  }

  ngOnDestroy(): void {
    // Reset the filter, sort, and pagination settings
    this.approvalsService.onUpdateDeviceFilter([]);
    this.approvalsService.onUpdateDeviceSort({
      sortBy: 'Name',
      sortDirection: 'asc',
    });
    this.approvalsService.onUpdateDevicePageNumber(1);
    this.approvalsService.onUpdateDevicePageSize(5);
  }
}

@Component({
  selector: 'app-organisations-approval-component',
  templateUrl: './organisations/organisation-approvals.component.html',
  styleUrls: ['./approvals.component.scss'],
  standalone: true,
  imports: [
    OptionTabToggleComponent,
    MatIcon,
    MatTooltip,
    ButtonComponent,
    DropdownFormFieldComponent,
    IconButtonComponent,
    LoadingSpinnerComponent,
    ModalComponent,
    ProvideInfoModalComponent,
    DatePipe,
  ],
})
export class OrganisationsApprovalComponent
  extends ApprovalsComponent
  implements OnInit, OnDestroy
{
  organisationChangeRequests: OrganisationChangeRequest[] = [];
  orgApprovalUpdate: ApprovalUpdate = {
    id: null,
    requestStatus: null,
  };

  // Make licence type enum available in the template
  LicenseType = LicenseType;

  authenticationService = inject(AuthenticationService);
  approvalsService = inject(ApprovalsService);
  formBuilder = inject(FormBuilder);
  modalService = inject(ModalService);

  ngOnInit(): void {
    super.ngOnInit();

    // Check for saved sort, filter, and pagination settings
    if (this.approvalsService.organisationFilter !== null) {
      this.currentFilters = this.approvalsService.organisationFilter;
    }

    if (this.approvalsService.organisationSort !== null) {
      this.currentSort = this.approvalsService.organisationSort;
    }

    if (this.approvalsService.organisationPaginationCache !== null) {
      this.pageRequested =
        this.approvalsService.organisationPaginationCache.pageNumber;
      this.pageSize =
        this.approvalsService.organisationPaginationCache.pageSize;
      this.tableControls.patchValue({
        [this.requestsPerPageField.key]: [this.pageSize],
      });
    }

    this.getOrgRequests();

    // Subscribe to the value changes of the org requests per page dropdown
    this.tableControls
      .get(this.requestsPerPageField.key)
      .valueChanges.subscribe((numOfPages) => {
        this.pageSize = numOfPages as number;
        // Also reset the page number to 1 as we are changing the pagination range
        this.pageRequested = 1;
        this.getOrgRequests();
      });

    // Subscribe to the value changes of the request status dropdown
    this.tableControls
      .get(this.requestStatusFilters.key)
      .valueChanges.subscribe((status) => {
        this.selectedRequestStatus = status as RequestStatus;
        this.getOrgRequests();
      });

    // Subscribe to the value changes of the request action dropdown
    this.tableControls
      .get(this.requestActionFilters.key)
      .valueChanges.subscribe((action) => {
        this.selectedRequestType = action as RequestType;
        this.getOrgRequests();
      });
  }

  getPrevious(): void {
    this.pageRequested = this.currentPage - 1;
    this.getOrgRequests();
  }

  getNext(): void {
    this.pageRequested = this.currentPage + 1;
    this.getOrgRequests();
  }

  getOrgRequests(): void {
    this.loading = true;
    this.error = null;
    this.approvalsService
      .getOrganisationApprovals(
        this.currentSort.sortBy,
        this.currentSort.sortDirection,
        this.pageSize,
        this.pageRequested,
        this.selectedRequestStatus,
        this.selectedRequestType,
      )
      .subscribe({
        next: (requests) => {
          const { organisationChangeRequests, paginationData } = requests;
          this.organisationChangeRequests = organisationChangeRequests;
          this.updatePagination(paginationData, 'organisation');
          this.loading = false;
        },
        error: (error: ErrorObject) => {
          this.error = error;
          this.loading = false;
          this.openModal('rejection-fail');
        },
      });
  }

  onSort(sortTerm: string): void {
    if (this.currentSort.sortBy === sortTerm) {
      if (this.currentSort.sortDirection === 'asc') {
        this.currentSort.sortDirection = 'desc';
      } else {
        this.currentSort.sortDirection = 'asc';
      }
    } else {
      this.currentSort.sortBy = sortTerm;
      this.currentSort.sortDirection = 'asc';
    }
    this.getOrgRequests();
  }

  approveOrgRequest(request: OrganisationChangeRequest): void {
    this.loading = true;
    this.error = null;
    const payload: ApprovalUpdate = {
      id: request.id,
      requestStatus: RequestStatus.Approved,
    };

    this.approvalsService.actionOrganisationRequest(payload).subscribe({
      next: () => {
        this.loading = false;
        // Open success modal
        this.openModal('success');
        // Make fresh request to get updated list
        this.getOrgRequests();
      },
      error: (error: ErrorObject) => {
        this.error = error;
        this.loading = false;
        this.openModal('rejection-fail');
      },
    });
  }

  openRejectRequestModal(request: OrganisationChangeRequest): void {
    this.orgApprovalUpdate.id = request.id;
    this.orgApprovalUpdate.requestStatus = RequestStatus.Rejected;
    // Open modal to provide reason for rejection
    this.openModal('provide-info');
  }

  rejectRequest(rejectionReason: ApprovalUpdate): void {
    this.loading = true;
    this.error = null;
    this.approvalsService.actionOrganisationRequest(rejectionReason).subscribe({
      next: () => {
        this.loading = false;
        this.closeModal('provide-info');
        this.openModal('rejection-success');
        // Make fresh request to get updated list
        this.getOrgRequests();
      },
      error: (error: ErrorObject) => {
        this.loading = false;
        this.error = error;
        this.closeModal('provide-info');
        this.openModal('rejection-fail');
      },
    });
  }

  ngOnDestroy(): void {
    // Reset the filter, sort, and pagination settings
    this.approvalsService.onUpdateOrganisationFilter([]);
    this.approvalsService.onUpdateOrganisationSort({
      sortBy: 'Name',
      sortDirection: 'asc',
    });
    this.approvalsService.onUpdateOrganisationPageNumber(1);
    this.approvalsService.onUpdateOrganisationPageSize(5);
  }
}