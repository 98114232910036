<section>
  <div class="header--container">
    <div class="title--container">
      <div class="status">
        <span class="subtitle2">{{ device.name }}</span>
        @if (device.isOffline) {
          <mat-icon class="offline">wifi_off</mat-icon>
        } @else {
          <mat-icon class="online">wifi</mat-icon>
        }
        <app-status-indicator [status]="statusClass"></app-status-indicator>
      </div>
      <span class="body2">
        API call last updated:
        {{ lastUpdateTime | date: "HH:mm a, dd/MM/yy" }}
      </span>
    </div>
    <div class="button--container">
      <app-basic-card
        fieldName="Ambient Temperature"
        [fieldReading]="experiment.ambientTemperature + '&deg;C'"
      ></app-basic-card>
      <!-- All experiments include optical density calibration -->
      <app-basic-card
        fieldName="Optical Density Calibration"
        [fieldReading]="device.calibration?.name"
      ></app-basic-card>
      @if (device.oxygenCalibration) {
        <app-basic-card
          fieldName="Oxygen Calibration"
          [fieldReading]="device.oxygenCalibration.name"
        ></app-basic-card>
      }
      @if (device.phCalibration) {
        <app-basic-card
          fieldName="pH Calibration"
          [fieldReading]="device.phCalibration.name"
        ></app-basic-card>
      }
      <app-icon-button
        [label]="
          device?.deviceStatusId === DeviceStatus.Paused
            ? 'resume'
            : 'pause device'
        "
        buttonId="pause-btn"
        buttonStyle="pause"
        iconName="pause"
        (buttonClicked)="clickButton(device.id)"
      >
      </app-icon-button>
    </div>
  </div>
  @if (device.lastRead?.length > 0) {
    <div class="cards--container">
      @for (reading of device.lastRead; track reading) {
        <app-small-data-card
          [dataReading]="reading.value"
          [dataReadingTime]="reading.time"
          [temperatureReading]="reading.temperatureValue"
          [flask]="reading.name"
          [deviceStatus]="device.deviceStatusId"
          [temperature]="experiment.ambientTemperature"
          [experiment]="experiment"
        ></app-small-data-card>
      }
    </div>
  } @else {
    <p class="body1 error">No data received</p>
  }
</section>
