import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IconNames } from 'src/app/_components/icons/icon-names';
import { ErrorObject } from 'src/app/_models/error';
import { FormBase } from 'src/app/_models/form-base';
import { AuthenticationService } from 'src/app/_services/authentication.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
  returnUrl: string;
  submitted = false;
  isProcessing = false;
  logo = IconNames.LogoColour;
  requestError = new ErrorObject(null, '');
  errors: string[] = [];

  emailField = new FormBase({
    key: 'email',
    label: 'Email',
    type: 'email',
    placeholder: 'Email Address',
    disabled: false,
    required: true,
    value: '',
    options: [],
    autocomplete: 'email',
  });

  resetForm = this.formBuilder.group({
    [this.emailField.key]: ['', [Validators.required, Validators.email]],
  });

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService
  ) {}

  ngOnInit(): void {
    // Get return URL from route parameters or default to home
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/';
  }

  /**
   * @remarks
   * Convenience getter for easy access to form fields
   *
   */
  get f(): FormGroup['controls'] {
    return this.resetForm.controls;
  }

  /**
   * @remarks
   * Method to request reset password link for user
   */
  onSubmit(): void {
    // If form is already being submitted, exit
    if (this.resetForm.invalid || this.isProcessing) {
      return;
    }

    this.isProcessing = true;

    // Otherwise, call auth service
    this.authenticationService
      .forgotPassword(this.f.email.value as string)
      .pipe()
      .subscribe({
        next: (responseSent: boolean) => {
          this.isProcessing = false;
          if (responseSent) {
            void this.router.navigate(['/reset-sent']);
          }
        },
        error: (error: ErrorObject) => {
          this.requestError = error;
          this.isProcessing = false;
        },
      });
  }
}
