import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { User, UserCreate, UserIndex, UserUpdate } from '../_models/user';
import { map } from 'rxjs/operators';
import { httpOptions } from '../_helpers/utils';
import { UserUpdateResponse } from '../_models/api-responses';
import { PaginationData } from '../_models/pagination-data.model';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private readonly http = inject(HttpClient);

  httpOptions = httpOptions;

  inviteUser(user: UserCreate): Observable<string> {
    return this.http
      .post<string>(
        `${environment.apiUrl}/users/invite`,
        user,
        this.httpOptions,
      )
      .pipe(
        map((response) => {
          if (response.status === 200) {
            return 'User invitation sent.';
          }
          if (response.status === 204) {
            return 'User invite request is pending approval; the status can be checked on the approvals page.';
          }
          return '';
        }),
      );
  }

  getUserDetails(userId: number): Observable<User> {
    return this.http
      .get<User>(`${environment.apiUrl}/users/${userId}`, this.httpOptions)
      .pipe(
        map((response) => {
          return response.body;
        }),
      );
  }

  updateUser(user: UserUpdate): Observable<UserUpdateResponse> {
    return this.http
      .patch<User>(
        `${environment.apiUrl}/users/${user?.id}`,
        user,
        this.httpOptions,
      )
      .pipe(
        map((response) => {
          const res: UserUpdateResponse = {};
          if (response.status === 200) {
            res.user = response.body;
            res.message = 'User details have been updated.';
            return res;
          }
          if (response.status === 204) {
            res.message =
              'Update user request is pending approval; the status can be checked on the approvals page.';
            return res;
          }
          return res;
        }),
      );
  }

  updatePassword(
    userId: number,
    currentPassword: string,
    newPassword: string,
  ): Observable<unknown> {
    return this.http
      .patch<unknown>(
        `${environment.apiUrl}/users/${userId}`,
        { currentPassword, NewPassword: newPassword },
        this.httpOptions,
      )
      .pipe(
        map((response) => {
          return response;
        }),
      );
  }

  deleteUser(userId: number): Observable<unknown> {
    return this.http
      .delete<unknown>(
        `${environment.apiUrl}/users/${userId}`,
        this.httpOptions,
      )
      .pipe(
        map((response) => {
          // Return a message based on the status code
          // Plus a boolean to indicate if the user should be logged out
          switch (response.status) {
            case 200:
              return {
                message: 'The user has been deleted.',
                logout: true,
              };
            case 204:
              return {
                message:
                  'Delete user request is pending approval; the status can be checked on the approvals page.',
                logout: false,
              };
            default:
              throw Error('Something went wrong');
          }
        }),
      );
  }

  getOrganisationUsers(
    id: number,
    sortBy = 'FirstName',
    sortOrder = 'asc',
    pageNumber = 1,
    pageSize = 5,
  ): Observable<UserIndex> {
    const url = `${environment.apiUrl}/users/${id}/organisation-members?OrderBy=${sortBy}&OrderByDirection=${sortOrder}&PageNumber=${pageNumber}&PageSize=${pageSize}`;
    return this.http.get<User[]>(url, this.httpOptions).pipe(
      map((response) => {
        // Set pagination data
        const paginationData = JSON.parse(
          response.headers.get('X-Pagination') ?? '{}',
        ) as PaginationData;
        const users = response.body;
        return { paginationData, users };
      }),
    );
  }
}
