import { ExperimentMode } from './experiment-mode';

export class CreateExperiment {
  useFluorescence: boolean;
  useOxygen: boolean;
  usePH: boolean;
  experimentMode: number;
  name: string;
  organism: string;
  strain: string;
  media: string;
  // Default module settings
  ambientTemperature?: number;
  samplingInterval?: number;
  duration?: number;
  speedRPMA?: number;
  speedRPMB?: number;
  speedRPMC?: number;
  speedRPMD?: number;
  temperatureControlA?: boolean;
  temperatureControlB?: boolean;
  temperatureControlC?: boolean;
  temperatureControlD?: boolean;
  cultureTemperatureA?: number;
  cultureTemperatureB?: number;
  cultureTemperatureC?: number;
  cultureTemperatureD?: number;
  // Turbidostat
  maintainOD?: boolean;
  targetODA?: number;
  targetODB?: number;
  targetODC?: number;
  targetODD?: number;
  turbAActive?: boolean;
  turbBActive?: boolean;
  turbCActive?: boolean;
  turbDActive?: boolean;
  // Chemostat
  chemoAActive?: boolean;
  chemoBActive?: boolean;
  chemoCActive?: boolean;
  chemoDActive?: boolean;
  flowRateA?: number;
  flowRateB?: number;
  flowRateC?: number;
  flowRateD?: number;
  // pH Control
  phControlA?: boolean;
  phControlB?: boolean;
  phControlC?: boolean;
  phControlD?: boolean;
  targetPHA?: number;
  targetPHB?: number;
  targetPHC?: number;
  targetPHD?: number;
  inputTypeA?: boolean;
  inputTypeB?: boolean;
  inputTypeC?: boolean;
  inputTypeD?: boolean;
  pumpDurationA?: number;
  pumpDurationB?: number;
  pumpDurationC?: number;
  pumpDurationD?: number;
  // Fluoro
  led1On?: boolean;
  led2On?: boolean;
  led3On?: boolean;
  led1Intensity?: number;
  led2Intensity?: number;
  led3Intensity?: number;
  salinityA?: number;
  salinityB?: number;
  salinityC?: number;
  salinityD?: number;
  notes?: string;
  laboratoryId?: number;

  constructor(
    useFluorescence: boolean,
    useOxygen: boolean,
    usePH: boolean,
    experimentMode: number,
    name: string,
    organism: string,
    strain: string,
    media: string,
    // Default module settings
    ambientTemperature?: number,
    samplingInterval?: number,
    duration?: number,
    speedRPMA?: number,
    speedRPMB?: number,
    speedRPMC?: number,
    speedRPMD?: number,
    temperatureControlA?: boolean,
    temperatureControlB?: boolean,
    temperatureControlC?: boolean,
    temperatureControlD?: boolean,
    cultureTemperatureA?: number,
    cultureTemperatureB?: number,
    cultureTemperatureC?: number,
    cultureTemperatureD?: number,
    // Turbidostat
    maintainOD?: boolean,
    targetODA?: number,
    targetODB?: number,
    targetODC?: number,
    targetODD?: number,
    turbAActive?: boolean,
    turbBActive?: boolean,
    turbCActive?: boolean,
    turbDActive?: boolean,
    // Chemostat
    chemoAActive?: boolean,
    chemoBActive?: boolean,
    chemoCActive?: boolean,
    chemoDActive?: boolean,
    flowRateA?: number,
    flowRateB?: number,
    flowRateC?: number,
    flowRateD?: number,
    // pH Control
    phControlA?: boolean,
    phControlB?: boolean,
    phControlC?: boolean,
    phControlD?: boolean,
    targetPHA?: number,
    targetPHB?: number,
    targetPHC?: number,
    targetPHD?: number,
    inputTypeA?: boolean,
    inputTypeB?: boolean,
    inputTypeC?: boolean,
    inputTypeD?: boolean,
    pumpDurationA?: number,
    pumpDurationB?: number,
    pumpDurationC?: number,
    pumpDurationD?: number,
    // Fluoro
    led1On?: boolean,
    led2On?: boolean,
    led3On?: boolean,
    led1Intensity?: number,
    led2Intensity?: number,
    led3Intensity?: number,
    // Oxygen
    salinityA?: number,
    salinityB?: number,
    salinityC?: number,
    salinityD?: number,
    notes?: string
  ) {
    this.useFluorescence = useFluorescence || false;
    this.useOxygen = useOxygen || false;
    this.usePH = usePH || false;
    this.experimentMode = experimentMode || ExperimentMode.BatchCulture;
    this.name = name;
    this.organism = organism;
    this.strain = strain;
    this.media = media;
    // Default module settings
    this.ambientTemperature = ambientTemperature;
    this.samplingInterval = samplingInterval;
    this.duration = duration;
    this.speedRPMA = speedRPMA;
    this.speedRPMB = speedRPMB;
    this.speedRPMC = speedRPMC;
    this.speedRPMD = speedRPMD;
    this.temperatureControlA = temperatureControlA;
    this.temperatureControlB = temperatureControlB;
    this.temperatureControlC = temperatureControlC;
    this.temperatureControlD = temperatureControlD;
    this.cultureTemperatureA = cultureTemperatureA;
    this.cultureTemperatureB = cultureTemperatureB;
    this.cultureTemperatureC = cultureTemperatureC;
    this.cultureTemperatureD = cultureTemperatureD;
    // Turbidostat
    this.maintainOD = maintainOD;
    this.targetODA = targetODA;
    this.targetODB = targetODB;
    this.targetODC = targetODC;
    this.targetODD = targetODD;
    this.turbAActive = turbAActive;
    this.turbBActive = turbBActive;
    this.turbCActive = turbCActive;
    this.turbDActive = turbDActive;
    // Chemostat
    this.chemoAActive = chemoAActive;
    this.chemoBActive = chemoBActive;
    this.chemoCActive = chemoCActive;
    this.chemoDActive = chemoDActive;
    this.flowRateA = flowRateA;
    this.flowRateB = flowRateB;
    this.flowRateC = flowRateC;
    this.flowRateD = flowRateD;
    // pH Control
    this.phControlA = phControlA;
    this.phControlB = phControlB;
    this.phControlC = phControlC;
    this.phControlD = phControlD;
    this.targetPHA = targetPHA;
    this.targetPHB = targetPHB;
    this.targetPHC = targetPHC;
    this.targetPHD = targetPHD;
    this.inputTypeA = inputTypeA;
    this.inputTypeB = inputTypeB;
    this.inputTypeC = inputTypeC;
    this.inputTypeD = inputTypeD;
    this.pumpDurationA = pumpDurationA;
    this.pumpDurationB = pumpDurationB;
    this.pumpDurationC = pumpDurationC;
    this.pumpDurationD = pumpDurationD;
    // Fluoro
    this.led1On = led1On;
    this.led2On = led2On;
    this.led3On = led3On;
    this.led1Intensity = led1Intensity;
    this.led2Intensity = led2Intensity;
    this.led3Intensity = led3Intensity;
    // Oxygen
    this.salinityA = salinityA;
    this.salinityB = salinityB;
    this.salinityC = salinityC;
    this.salinityD = salinityD;
    this.notes = notes;
  }
}
