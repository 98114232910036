<section id="modules">
  <span class="subtitle2">Installed modules</span>
  <div class="container">
    <div
      [class]="
        activeModules.includes(Module.BaseModule) ? 'module' : 'module inactive'
      "
    >
      <svg
        [attr.width]="width"
        [attr.height]="height"
        [attr.viewBox]="customViewBox"
        xmlns="http://www.w3.org/2000/svg"
        [innerHTML]="icon(opticalDensity)"
        role="img"
      ></svg>
      <div>
        Base Unit
        <div class="base-unit body3">
          <div>Stirring, OD Measurement,</div>
          <div>temperature measurement & control</div>
        </div>
      </div>
    </div>
    <div
      [class]="
        activeModules.includes(Module.Oxygen) ? 'module' : 'module inactive'
      "
    >
      <svg
        [attr.width]="width"
        [attr.height]="height"
        [attr.viewBox]="customViewBox"
        xmlns="http://www.w3.org/2000/svg"
        [innerHTML]="icon(oxygen)"
        role="img"
      ></svg>
      Oxygen
    </div>
    <div
      [class]="
        activeModules.includes(Module.Fluorescence)
          ? 'module'
          : 'module inactive'
      "
    >
      <svg
        [attr.width]="width"
        [attr.height]="height"
        [attr.viewBox]="customViewBox"
        xmlns="http://www.w3.org/2000/svg"
        [innerHTML]="icon(fluo)"
        role="img"
      ></svg>
      Fluorescence
    </div>
    <div
      [class]="
        activeModules.includes(Module.LiquidControl)
          ? 'module'
          : 'module inactive'
      "
    >
      <svg
        [attr.width]="width"
        [attr.height]="height"
        [attr.viewBox]="customViewBox"
        xmlns="http://www.w3.org/2000/svg"
        [innerHTML]="icon(liquidControl)"
        role="img"
      ></svg>
      Liquid Control
    </div>
    <div
      [class]="
        activeModules.includes(Module.AirControl) ? 'module' : 'module inactive'
      "
    >
      <svg
        [attr.width]="width"
        [attr.height]="height"
        [attr.viewBox]="customViewBox"
        xmlns="http://www.w3.org/2000/svg"
        [innerHTML]="icon(airControl)"
        role="img"
      ></svg>
      Air Control
    </div>
    <div
      [class]="activeModules.includes(Module.pH) ? 'module' : 'module inactive'"
    >
      <svg
        [attr.width]="width"
        [attr.height]="height"
        [attr.viewBox]="customViewBox"
        xmlns="http://www.w3.org/2000/svg"
        [innerHTML]="icon(pH)"
        role="img"
      ></svg>
      pH
    </div>
  </div>
</section>
