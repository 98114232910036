import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';
import { User } from './_models/user';
import { AuthenticationService } from './_services/authentication.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'Ogi Bio';
  appVersion = environment.appVersion;
  backendVersionString = environment.apiUrl;
  currentUser: User;

  constructor(private authenticationService: AuthenticationService) {
    this.authenticationService.currentUser.subscribe((x) => {
      this.currentUser = x;
    });
  }
}
