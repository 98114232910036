import {
  Component,
  EventEmitter,
  Input,
  Output,
  ChangeDetectionStrategy,
  OnChanges,
} from '@angular/core';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'app-nav-button',
  templateUrl: './nav-button.component.html',
  styleUrls: ['./nav-button.component.scss'],
  standalone: true,
  imports: [MatIcon],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavButtonComponent implements OnChanges {
  /**
   * @param {string} label The button text
   * @requires
   */
  @Input()
  label: string | null;

  /**
   * @param {string} iconName The required icon
   * @requires
   */
  @Input()
  iconName: string | undefined;

  /**
   * @param {string} type The type of button required
   */
  @Input()
  type: 'button' | 'submit' | 'reset' = 'button';

  /**
   * @param {string} buttonId The button id
   * @requires
   */
  @Input()
  buttonId: string | undefined;

  /**
   * @param {boolean} isDisabled Is the button disabled
   */
  @Input()
  isDisabled = false;

  /**
   * @param {boolean} hideLabel Display label text
   */
  @Input()
  displayText = false;

  /**
   * Output event on click
   */
  @Output()
  buttonClicked: EventEmitter<void> = new EventEmitter<void>();

  public classes = '';

  ngOnChanges(): void {
    this.updateClasses();
  }

  private updateClasses(): void {
    const disabled = this.isDisabled ? 'disabled' : '';
    const hasText = this.label && this.displayText ? 'icon-button--text' : '';
    this.classes = `icon-button ${hasText} ${disabled}`.trim();
  }

  clickButton = (): void => {
    this.buttonClicked.emit();
  };
}
