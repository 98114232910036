<div class="radio--container">
  <legend [ngClass]="classes">{{ field.label }}</legend>
  <span *ngIf="field.required" class="required" aria-required="true"></span>
  <div class="radio-options-container">
    <label
      *ngFor="let option of field.options"
      [for]="option.key"
      class="subtitle1"
      [formGroup]="parentForm"
    >
      <input
        type="radio"
        [id]="option.key"
        [attr.name]="field.key"
        [formControlName]="field.key"
        [value]="option.key"
        [attr.checked]="option.key === currentValue?.toString() ? '' : null"
        class="body1"
        (change)="updateValue($event)"
      />
      {{ option.value }}
    </label>
  </div>
</div>
