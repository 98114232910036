/* eslint-disable brace-style */
import { Component, OnInit, OnDestroy, inject } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { map, takeUntil } from 'rxjs/operators';
import { ActivatedRoute, Params, Router, RouterLink } from '@angular/router';
import { interval, Observable, Subject, Subscription } from 'rxjs';
import { clearFormArray, calculateTimeLeft } from 'src/app/_helpers/utils';
import { ChangelogEntry } from 'src/app/_models/changelog';
import { Device } from 'src/app/_models/device';
import { ErrorObject } from 'src/app/_models/error';
import { Experiment } from 'src/app/_models/experiment';
import { ExperimentData, IDevice } from 'src/app/_models/experiment-data';
import { FormBase } from 'src/app/_models/form-base';
import { TabOptions } from 'src/app/_models/tab-options';
import { ExperimentService } from 'src/app/_services/experiment.service';
import { IconNames } from 'src/app/_components/icons/icon-names';
import { ExperimentUpdate } from 'src/app/_models/experiment-update';
import { ChangelogResponse } from 'src/app/_models/api-responses';
import { ModalService } from 'src/app/_services/modal.service';
import { DeviceService } from 'src/app/_services/device.service';
import { ExperimentStatus } from 'src/app/_models/experiment-status';
import { DeviceSetupStatus } from 'src/app/_models/device-setup-status';
import { DataService } from 'src/app/_services/data.service';
import { Checkbox } from 'src/app/_models/checkbox';
import { User } from 'src/app/_models/user';
import { ExperimentMode } from 'src/app/_models/experiment-mode';
import { TimeComponent } from 'src/app/_models/time-component';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { Role } from 'src/app/_models/role';
import { Laboratory } from 'src/app/_models/laboratory';
import { stepValidator } from 'src/app/_helpers/step-validator.validator';
import { integerValidator } from 'src/app/_helpers/integer-validator.validator';
import { CsvService } from 'src/app/_services/csv.service';

import { FormatCamelCasePipe } from '../../_helpers/format-camel-case.pipe';
import { RoleCheckPipe } from '../../_helpers/role-check.pipe';
import { ModalComponent } from '../../_components/modals/modal.component';
import { DropdownFormFieldComponent } from '../../_components/forms/dropdown-form-field/dropdown-form-field.component';
import { MatTooltip } from '@angular/material/tooltip';
import { MatIcon } from '@angular/material/icon';
import { UnderlinedTextareaFieldComponent } from '../../_components/forms/underlined-textarea-field/underlined-textarea-field.component';
import { RadioButtonComponent } from '../../_components/selection-controls/radio-button/radio-button.component';
import { IconUnderlinedFormFieldComponent } from '../../_components/forms/icon-underlined-form-field/icon-underlined-form-field.component';
import { SlideToggleComponent } from '../../_components/selection-controls/slide-toggle/slide-toggle.component';
import { UnderlinedFormFieldComponent } from '../../_components/forms/underlined-form-field/underlined-form-field.component';
import { ValidationModalComponent } from '../../_components/modals/validation-modal/validation-modal.component';
import { ButtonComponent } from '../../_components/buttons/button/button.component';
import { UnderlinedDropdownFormFieldComponent } from '../../_components/forms/underlined-dropdown-form-field/underlined-dropdown-form-field.component';
import { SlideToggleGroupComponent } from '../../_components/selection-controls/slide-toggle-group/slide-toggle-group.component';
import { GraphCheckboxGroupComponent } from '../../_components/selection-controls/graph-checkbox-group/graph-checkbox-group.component';
import { CheckboxComponent } from '../../_components/selection-controls/checkbox/checkbox.component';
import { LoadingSpinnerComponent } from '../../_components/loading-spinner/loading-spinner.component';
import { GraphComponent } from '../../_components/graph/graph/graph.component';
import { ReducedSmallDataCardComponent } from '../../_components/cards/reduced-small-data-card/reduced-small-data-card.component';
import { StatusIndicatorComponent } from '../../_components/chips/status-indicator/status-indicator.component';
import { DeviceInfoCardComponent } from '../../_components/cards/device-info-card/device-info-card.component';
import { IconButtonComponent } from '../../_components/buttons/icon-button/icon-button.component';
import { CheckboxGroupComponent } from '../../_components/selection-controls/checkbox-group/checkbox-group.component';
import { AsyncPipe, DecimalPipe, DatePipe } from '@angular/common';
import { OptionTabToggleComponent } from '../../_components/selection-controls/option-tab-toggle/option-tab-toggle.component';

@Component({
  selector: 'app-active-experiment',
  template: '',
})
export class ActiveExperimentComponent implements OnInit, OnDestroy {
  protected route = inject(ActivatedRoute);
  protected formBuilder = inject(FormBuilder);
  protected experimentService = inject(ExperimentService);
  protected modalService = inject(ModalService);
  protected deviceService = inject(DeviceService);
  protected dataService = inject(DataService);
  protected router = inject(Router);
  protected authenticationService = inject(AuthenticationService);

  //Routing
  navOptions: TabOptions[];
  experimentId: number;
  // Permissions
  laboratory: Laboratory;
  currentUser: User;
  // Make role enum accessible in html template
  Role = Role;
  hasEditAccess = false;
  deviceCheckboxesAreInitialised = false;

  // experiment to be displayed
  experiment: Experiment;
  ExperimentStatus = ExperimentStatus;
  ExperimentMode = ExperimentMode;

  // Device controls
  devices: Device[] = [];

  // Lists to track devices to be displayed in the summary section
  selectedDevices: Array<Device> = [];

  // CSV download
  isDownloadingBaseModuleCsv = false;
  isDownloadingTurbidostatCsv = false;
  isDownloadingTemperatureCsv = false;
  isDownloadingOxygenCsv = false;
  isDownloadingFluorescenceCsv = false;
  isDownloadingPhCsv = false;

  // Graph controls
  graphWidth: number;
  graphHeight: number;

  // OD controls
  flasks = ['OD_A', 'OD_B', 'OD_C', 'OD_D'];
  renderODGraph = false;
  selectedODFlasks: Array<string> = [];

  // Temperature controls
  temperatureFlasks = ['TFlask_A', 'TFlask_B', 'TFlask_C', 'TFlask_D'];
  renderTemperatureGraph = false;
  selectedTemperatureFlasks: Array<string> = [];

  // Oxygen controls
  oxygenFlasks = [
    'UMolarA',
    'MbarA',
    'AirSaturationA',
    'UMolarB',
    'MbarB',
    'AirSaturationB',
    'UMolarC',
    'MbarC',
    'AirSaturationC',
    'UMolarD',
    'MbarD',
    'AirSaturationD',
  ];
  renderOxygenGraph = false;
  selectedOxygenFlasks: Array<string> = [];

  oxygenDataFilterField = new FormBase({
    key: 'oxygenDataFilter',
    label: 'Oxygen data filter',
    type: 'toggle',
    placeholder: 'Select data',
    disabled: false,
    required: false,
    value: null,
    options: [
      { key: 'true', value: 'Early' },
      { key: 'false', value: 'Late' },
      { key: null, value: 'Both' },
    ],
  });

  // pH controls
  flasksPH = ['PHA', 'PHB', 'PHC', 'PHD'];
  renderPHGraph = false;
  selectedPHFlasks: Array<string> = [];

  // Fluorescence controls
  renderFluorescenceGraph = false; // toggle to render graph if update is called
  isFetchingFluorescenceData = false; // toggle to render graph when fluoro button is pressed
  isFluorescenceFirstLoad = true; // toggle to track if this is first load of data (fluoroSelection1 set to default values)
  fluorescenceSelection1 = 'LED1_F1'; // Default selection
  fluorescenceSelection2: string;
  fluorescenceSelection3: string;
  ledSelection1: string;
  ledSelection2: string;
  ledSelection3: string;
  selectedFluorescenceFlasks: Array<string> = [];

  fluorescenceFlasks = ['A', 'B', 'C', 'D'];

  // Device summary form declarations
  deviceCheckboxesField = new FormBase({
    key: 'devices',
    label: 'Select device(s)',
    type: 'checkbox',
    placeholder: 'checkbox',
    disabled: false,
    required: false,
    value: '',
    options: [
      {
        key: '',
        value: '',
      },
    ],
  });

  deviceSelectorForm = this.formBuilder.group({
    [this.deviceCheckboxesField.key]: new FormArray([]),
  });

  // Graph checkbox form declarations
  odFlaskCheckboxesField = new FormBase({
    key: 'odFlasks',
    label: '',
    type: 'checkbox',
    placeholder: '',
    disabled: false,
    required: false,
    value: '',
    options: [
      {
        key: '',
        value: '',
      },
    ],
  });

  isLogarithmic = new Checkbox(
    'Logarithmic',
    'Graph OD logarithmically?',
    'done',
  );

  temperatureFlaskCheckboxesField = new FormBase({
    key: 'temperatureFlasks',
    label: '',
    type: 'checkbox',
    placeholder: '',
    disabled: false,
    required: false,
    value: '',
    options: [
      {
        key: '',
        value: '',
      },
    ],
  });

  oxygenFlaskCheckboxesField = new FormBase({
    key: 'oxygenFlasks',
    label: '',
    type: 'checkbox',
    placeholder: '',
    disabled: false,
    required: false,
    value: '',
    options: [
      {
        key: '',
        value: '',
      },
    ],
  });

  pHFlaskCheckboxesField = new FormBase({
    key: 'pHFlasks',
    label: '',
    type: 'checkbox',
    placeholder: '',
    disabled: false,
    required: false,
    value: '',
    options: [
      {
        key: '',
        value: '',
      },
    ],
  });

  // Fluorescence controls
  // LEDS
  ledSelection1Field = new FormBase({
    key: 'ledSelection1',
    label: 'LED',
    type: 'dropdown',
    placeholder: 'Select LED',
    disabled: false,
    required: false,
    value: '',
    options: [
      { key: 'LED1_', value: 'LED 1' },
      { key: 'LED2_', value: 'LED 2' },
      { key: 'LED3_', value: 'LED 3' },
    ],
  });

  ledSelection2Field = new FormBase({
    key: 'ledSelection2',
    label: 'LED',
    type: 'dropdown',
    placeholder: 'Select LED',
    disabled: false,
    required: false,
    value: '',
    options: [
      { key: 'LED1_', value: 'LED 1' },
      { key: 'LED2_', value: 'LED 2' },
      { key: 'LED3_', value: 'LED 3' },
    ],
  });

  ledSelection3Field = new FormBase({
    key: 'ledSelection3',
    label: 'LED',
    type: 'dropdown',
    placeholder: 'Select LED',
    disabled: false,
    required: false,
    value: '',
    options: [
      { key: 'LED1_', value: 'LED 1' },
      { key: 'LED2_', value: 'LED 2' },
      { key: 'LED3_', value: 'LED 3' },
    ],
  });

  // Fluorescence channels

  fluorescenceChannelSelection1Field = new FormBase({
    key: 'fluorescenceChannelSelection1',
    label: 'Channel',
    type: 'dropdown',
    placeholder: 'Select fluorescence channel',
    disabled: false,
    required: false,
    value: '',
    options: [
      { key: 'F1', value: 'Channel 1' },
      { key: 'F2', value: 'Channel 2' },
      { key: 'F3', value: 'Channel 3' },
      { key: 'F4', value: 'Channel 4' },
      { key: 'F5', value: 'Channel 5' },
      { key: 'F6', value: 'Channel 6' },
      { key: 'F7', value: 'Channel 7' },
      { key: 'F8', value: 'Channel 8' },
    ],
  });

  fluorescenceChannelSelection2Field = new FormBase({
    key: 'fluorescenceChannelSelection2',
    label: 'Channel',
    type: 'dropdown',
    placeholder: 'Select fluorescence channel',
    disabled: false,
    required: false,
    value: '',
    options: [
      { key: 'F1', value: 'Channel 1' },
      { key: 'F2', value: 'Channel 2' },
      { key: 'F3', value: 'Channel 3' },
      { key: 'F4', value: 'Channel 4' },
      { key: 'F5', value: 'Channel 5' },
      { key: 'F6', value: 'Channel 6' },
      { key: 'F7', value: 'Channel 7' },
      { key: 'F8', value: 'Channel 8' },
    ],
  });

  fluorescenceChannelSelection3Field = new FormBase({
    key: 'fluorescenceChannelSelection3',
    label: 'Channel',
    type: 'dropdown',
    placeholder: 'Select fluorescence channel',
    disabled: false,
    required: false,
    value: '',
    options: [
      { key: 'F1', value: 'Channel 1' },
      { key: 'F2', value: 'Channel 2' },
      { key: 'F3', value: 'Channel 3' },
      { key: 'F4', value: 'Channel 4' },
      { key: 'F5', value: 'Channel 5' },
      { key: 'F6', value: 'Channel 6' },
      { key: 'F7', value: 'Channel 7' },
      { key: 'F8', value: 'Channel 8' },
    ],
  });

  fluorescenceFlaskCheckboxesField = new FormBase({
    key: 'fluorescenceFlasks',
    label: '',
    type: 'checkbox',
    placeholder: '',
    disabled: false,
    required: false,
    value: '',
    options: [
      {
        key: '',
        value: '',
      },
    ],
  });

  graphCheckboxesForm = this.formBuilder.group({
    [this.odFlaskCheckboxesField.key]: new FormArray([]),
    [this.isLogarithmic.id]: [false, Validators.required],
    [this.oxygenDataFilterField.key]: new FormControl(null),
    [this.temperatureFlaskCheckboxesField.key]: new FormArray([]),
    [this.oxygenFlaskCheckboxesField.key]: new FormArray([]),
    [this.fluorescenceFlaskCheckboxesField.key]: new FormArray([]),
    [this.pHFlaskCheckboxesField.key]: new FormArray([]),
  });

  fluorescenceSelectionForm = this.formBuilder.group({
    [this.ledSelection1Field.key]: ['', Validators.required],
    [this.ledSelection2Field.key]: [''],
    [this.ledSelection3Field.key]: [''],
    [this.fluorescenceChannelSelection1Field.key]: ['', Validators.required],
    [this.fluorescenceChannelSelection2Field.key]: [''],
    [this.fluorescenceChannelSelection3Field.key]: [''],
  });

  private readonly unsubscribe$ = new Subject<void>();

  ngOnInit(): void {
    // Grab experiment ID from route
    this.route.params.subscribe((params: Params) => {
      this.experimentId = parseInt(params.id as string, 10);
    });

    this.authenticationService.currentUser
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((user: User) => {
        this.currentUser = user;
        // TODO: update to explicitly check for edit access, avoids accidental permissions being granted if new roles are added
        this.hasEditAccess = user?.role !== Role.Read_Access_User;
      });

    this.navOptions = [
      {
        value: 'display',
        labelText: 'Display',
        link: '/experiment/' + this.experimentId.toString() + '/display',
      },
      {
        value: 'control',
        labelText: 'Control',
        link: '/experiment/' + this.experimentId.toString() + '/control',
      },
    ];
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  get checkboxesFormArray(): FormArray {
    return this.deviceSelectorForm.get('devices') as FormArray;
  }

  get odFlaskCheckboxesFormArray(): FormArray {
    return this.graphCheckboxesForm.get(
      this.odFlaskCheckboxesField.key,
    ) as FormArray;
  }

  get temperatureFlaskCheckboxesFormArray(): FormArray {
    return this.graphCheckboxesForm.get(
      this.temperatureFlaskCheckboxesField.key,
    ) as FormArray;
  }

  get oxygenFlaskCheckboxesFormArray(): FormArray {
    return this.graphCheckboxesForm.get(
      this.oxygenFlaskCheckboxesField.key,
    ) as FormArray;
  }

  get fluorescenceFlaskCheckboxesFormArray(): FormArray {
    return this.graphCheckboxesForm.get(
      this.fluorescenceFlaskCheckboxesField.key,
    ) as FormArray;
  }

  get pHFlaskCheckboxesFormArray(): FormArray {
    return this.graphCheckboxesForm.get(
      this.pHFlaskCheckboxesField.key,
    ) as FormArray;
  }
}

@Component({
  selector: 'app-display-experiment',
  templateUrl: './display-experiment/display-experiment.component.html',
  styleUrls: ['./display-experiment/display-experiment.component.scss'],
  standalone: true,
  imports: [
    OptionTabToggleComponent,
    CheckboxGroupComponent,
    IconButtonComponent,
    DeviceInfoCardComponent,
    StatusIndicatorComponent,
    RouterLink,
    ReducedSmallDataCardComponent,
    GraphComponent,
    LoadingSpinnerComponent,
    CheckboxComponent,
    GraphCheckboxGroupComponent,
    SlideToggleGroupComponent,
    UnderlinedDropdownFormFieldComponent,
    ButtonComponent,
    ValidationModalComponent,
    AsyncPipe,
    DecimalPipe,
  ],
})
export class DisplayExperimentComponent
  extends ActiveExperimentComponent
  implements OnInit
{
  private readonly csvService = inject(CsvService);
  protected route = inject(ActivatedRoute);
  protected formBuilder = inject(FormBuilder);
  protected experimentService = inject(ExperimentService);
  protected modalService = inject(ModalService);
  protected deviceService = inject(DeviceService);
  protected dataService = inject(DataService);
  protected router = inject(Router);
  protected authenticationService = inject(AuthenticationService);

  // Control vars
  error: ErrorObject = null;
  dataDownloadError: ErrorObject = null;
  loadingDeviceSummary = false;
  // OD loading vars
  loadingOpticalDensity = false;
  opticalDensityLoadingText = 'Loading data...';
  isLogarithmicChecked = false;
  // Temperature loading vars
  loadingTemperature = false;
  temperatureLoadingText = 'Loading data...';
  // Oxygen loading vars
  loadingOxygen = false;
  oxygenLoadingText = 'Loading data...';
  // Fluorescence loading vars
  loadingFluorescence = false;
  fluorescenceLoadingText = 'Please select an LED and Channel to display data';
  updateSpinner = IconNames.Sync;
  // pH loading vars
  loadingPH = false;
  pHLoadingText = 'Loading data...';

  deviceListLastUpdated = new Date();

  pauseDeviceTitleText = '';
  pauseDeviceValidationText = '';
  pauseDeviceButtonText = '';

  // Pause/restart device selected
  selectedDeviceId: number = null;

  // Countdown controls for time left display
  currentTime$: Observable<Date>;

  // OD Graph
  odGraphId = 'odGraph';
  opticalDensityData: ExperimentData;
  selectedODData: ExperimentData;
  odDeviceTitles = [];

  // Temperature graph
  temperatureGraphId = 'temperatureGraph';
  temperatureData: ExperimentData;
  selectedTemperatureData: ExperimentData;
  temperatureDeviceTitles = [];

  // Oxygen graph
  oxygenGraphId = 'oxygenGraph';
  oxygenData: ExperimentData;
  oxygenDeviceTitles = [];
  selectedOxygenData: ExperimentData;

  // pH graph
  pHGraphId = 'pHGraph';
  pHData: ExperimentData;
  pHDeviceTitles = [];
  selectedPHData: ExperimentData;

  // Fluorescence graph
  fluoroLED1Selection$: Subscription;
  fluoroLED2Selection$: Subscription;
  fluoroLED3Selection$: Subscription;
  fluoroChannel1Selection$: Subscription;
  fluoroChannel2Selection$: Subscription;
  fluoroChannel3Selection$: Subscription;
  fluoroLED1Selection: string;
  fluoroLED2Selection: string;
  fluoroLED3Selection: string;
  fluoroChannel1Selection: string;
  fluoroChannel2Selection: string;
  fluoroChannel3Selection: string;
  fluoroSelection1: string;
  fluoroSelection2: string;
  fluoroSelection3: string;
  fluorescenceGraphId = 'fluorescenceGraph';
  fluorescenceData: ExperimentData;
  selectedFluorescenceData: ExperimentData;
  graphSize = 'medium'; // Set to medium by default
  fluorescenceDeviceTitles = [];
  // TODO - add all subs to parent list
  private subscription: Subscription;

  constructor() {
    super();
    this.currentTime$ = interval(1000).pipe(map(() => this.getCurrentTime()));
  }
  ngOnInit(): void {
    super.ngOnInit();

    this.initialiseGraphSubscriptions();

    // Now let's get the experiment from the API
    this.getExperiment();
  }

  initialiseGraphSubscriptions(): void {
    // Fluoro selection subscriptions
    this.fluoroLED1Selection$ = this.fluorescenceSelectionForm
      .get(this.ledSelection1Field.key)
      .valueChanges.subscribe((value: string) => {
        this.fluoroLED1Selection = value;
      });

    this.fluoroLED2Selection$ = this.fluorescenceSelectionForm
      .get(this.ledSelection2Field.key)
      .valueChanges.subscribe((value: string) => {
        this.fluoroLED2Selection = value;
      });

    this.fluoroLED3Selection$ = this.fluorescenceSelectionForm
      .get(this.ledSelection3Field.key)
      .valueChanges.subscribe((value) => {
        this.fluoroLED3Selection = value as string;
      });

    this.fluoroChannel1Selection$ = this.fluorescenceSelectionForm
      .get(this.fluorescenceChannelSelection1Field.key)
      .valueChanges.subscribe((value) => {
        this.fluoroChannel1Selection = value as string;
      });

    this.fluoroChannel2Selection$ = this.fluorescenceSelectionForm
      .get(this.fluorescenceChannelSelection2Field.key)
      .valueChanges.subscribe((value) => {
        this.fluoroChannel2Selection = value as string;
      });

    this.fluoroChannel3Selection$ = this.fluorescenceSelectionForm
      .get(this.fluorescenceChannelSelection3Field.key)
      .valueChanges.subscribe((value) => {
        this.fluoroChannel3Selection = value as string;
      });
  }

  get gc(): FormGroup['controls'] {
    return this.graphCheckboxesForm.controls;
  }

  getExperiment(updateDevicesOnly = false): void {
    this.loadingDeviceSummary = true;
    this.experimentService.getExperimentById(this.experimentId).subscribe({
      next: (response: Experiment) => {
        this.deviceListLastUpdated = new Date();
        this.error = null;
        this.loadingDeviceSummary = false;
        this.experiment = response;
        this.devices = response.devices;

        const updatedSelectedDevices: Device[] = [];
        this.selectedDevices?.forEach((selectedDevice: Device) => {
          const device = this.devices.find(
            (device) => device.id === selectedDevice.id,
          );
          if (device) updatedSelectedDevices.push(device);
        });
        this.selectedDevices = updatedSelectedDevices;

        this.initialiseDeviceCheckboxes(this.devices);
        this.initialiseODFlaskCheckboxes(this.devices);
        this.initialiseTemperatureFlaskCheckboxes(this.devices);
        this.initialiseOxygenFlaskCheckboxes(this.devices);
        this.initialisePHFlaskCheckboxes(this.devices);
        this.initialiseFluorescenceFlaskCheckboxes(this.devices);
        // Now we grab the experiment data, calling it in tandem with getExperiment() causes null
        // exception on backend
        if (!updateDevicesOnly && this.experiment.isDeleted === false) {
          this.getExperimentData();
        }
      },
      error: (error: ErrorObject) => {
        this.error = error;
        this.loadingDeviceSummary = false;
      },
    });
  }

  getExperimentData(): void {
    // First reset the current experiment data list
    this.opticalDensityData = null;
    this.loadingOpticalDensity = true;
    this.dataService
      .getBaseModuleData(this.experimentId, this.experiment.experimentMode)
      .subscribe({
        next: (response) => {
          if (response !== null) {
            this.opticalDensityData = response;
            this.loadingOpticalDensity = false;
            this.opticalDensityLoadingText =
              'Data loaded, select a flask to display';
          } else {
            this.loadingOpticalDensity = false;
            this.opticalDensityLoadingText =
              'No data available yet, please wait...';
          }
          this.getTemperatureData();
        },
        error: (error: ErrorObject) => {
          this.error = error;
          this.loadingOpticalDensity = false;
          this.opticalDensityLoadingText = 'Error loading data';
          this.getTemperatureData();
        },
      });
  }

  getTemperatureData(): void {
    // First reset the current temperature data list
    this.temperatureData = null;
    this.dataService.getTemperatureModuleData(this.experimentId).subscribe({
      next: (response) => {
        if (response !== null) {
          this.temperatureData = response;
          this.loadingTemperature = false;
          this.temperatureLoadingText =
            'Data loaded, select a flask to display';
        } else {
          this.loadingTemperature = false;
          this.temperatureLoadingText = 'No data available yet, please wait...';
        }
        if (this.experiment.useOxygen) {
          this.getOxygenData();
        }
      },
      error: (error: ErrorObject) => {
        this.error = error;
        this.loadingTemperature = false;
        this.temperatureLoadingText = 'Error loading data';
        if (this.experiment.useOxygen) {
          this.getOxygenData();
        }
      },
    });
  }

  getOxygenData(noCascade = false): void {
    // First reset the current oxygen data list
    this.oxygenData = null;
    // Get the value of the toggle (it can be 'true', 'false', or null)
    const toggleValue = this.graphCheckboxesForm.get('oxygenDataFilter')
      ?.value as unknown as string;

    this.dataService
      .getOxygenModuleData(this.experimentId, toggleValue)
      .subscribe({
        next: (response) => {
          if (response !== null) {
            this.oxygenData = response;
            this.updateSelectedOxygenData();
            this.loadingOxygen = false;
            this.oxygenLoadingText = 'Data loaded, select a flask to display';
          } else {
            this.oxygenData = null;
            this.selectedOxygenData = null;
            this.loadingOxygen = false;
            this.oxygenLoadingText = 'No data available yet, please wait...';
          }
          if (!noCascade && this.experiment.usePH) this.getPHData();
        },
        error: (error: ErrorObject) => {
          this.error = error;
          this.loadingOxygen = false;
          this.oxygenLoadingText = 'Error loading data';
          if (!noCascade && this.experiment.usePH) {
            this.getPHData();
          }
        },
      });
  }

  updateSelectedOxygenData(): void {
    // Initialise the selectedOxygenData object
    this.selectedOxygenData = {
      dataType: 'Oxygen',
      devices: [],
    };

    // Step 2: Create a deep copy of the oxygen devices from the backend data
    const devices: IDevice[] = JSON.parse(
      JSON.stringify(this.oxygenData?.devices),
    ) as IDevice[];

    // Step 3: Loop over each device and apply the selected flasks from checkboxes
    devices.forEach((device) => {
      device.flasks = []; // Start with an empty flask array for each device

      // Step 4: Loop through the device's available flasks
      this.oxygenData.devices.forEach((backendDevice) => {
        backendDevice.flasks?.forEach((flask) => {
          // Construct the flask key to match it with selected flasks
          const flaskKey = `device_${backendDevice.deviceId}_flask_${flask.name}`;

          // Check if this flask is selected (in checkboxes)
          const isSelectedFlask = this.selectedOxygenFlasks.includes(flaskKey);

          // If flask is selected, add it to the device's flasks in selectedOxygenData
          if (isSelectedFlask) {
            const targetDevice = devices.find(
              (d) => d.deviceId === backendDevice.deviceId,
            );
            targetDevice?.flasks.push(flask);
          }
        });
      });
    });

    // Step 5: Assign the filtered devices to selectedOxygenData
    this.selectedOxygenData.devices = devices;
  }

  getPHData(): void {
    // First reset the current pH data list
    this.pHData = null;
    this.dataService.getPhModuleData(this.experimentId).subscribe({
      next: (response) => {
        if (response !== null) {
          this.pHData = response;
          this.loadingPH = false;
          this.pHLoadingText = 'Data loaded, select a flask to display';
        } else {
          this.loadingPH = false;
          this.pHLoadingText = 'No data available yet, please wait...';
        }
      },
      error: (error: ErrorObject) => {
        this.error = error;
        this.loadingPH = false;
        this.pHLoadingText = 'Error loading data';
      },
    });
  }

  // User has to first make a selection of fluoresence channels, so we can't load data until they do
  getFluorescenceData(): void {
    // Construct the selection string from the form
    this.fluorescenceSelection1 =
      this.fluoroLED1Selection + this.fluoroChannel1Selection;
    this.fluorescenceSelection2 =
      this.fluoroLED2Selection + this.fluoroChannel2Selection;
    this.fluorescenceSelection3 =
      this.fluoroLED3Selection + this.fluoroChannel3Selection;
    // Check if a valid selection has been made to graph
    if (
      !this.fluorescenceSelection1 &&
      !this.fluorescenceSelection2 &&
      !this.fluorescenceSelection3
    ) {
      return;
    }
    // First reset the current fluorescence data list
    this.fluorescenceData = null;
    this.isFetchingFluorescenceData = true;
    this.renderFluorescenceGraph = false;

    // Now make a list of the selections that are not empty
    const selections: string[] = [];
    if (this.fluorescenceSelection1) {
      selections.push(this.fluorescenceSelection1);
    }
    if (this.fluorescenceSelection2) {
      selections.push(this.fluorescenceSelection2);
    }
    if (this.fluorescenceSelection3) {
      selections.push(this.fluorescenceSelection3);
    }
    this.dataService
      .getFluorescenceModuleData(
        this.experimentId,
        selections[0] || null,
        selections[1] || null,
        selections[2] || null,
      )
      .subscribe({
        next: (response) => {
          if (response !== null) {
            this.fluorescenceData = response;
            this.fluorescenceLoadingText =
              'Data loaded, select a flask to display';
          } else {
            this.fluorescenceLoadingText =
              'No data available yet, please wait...';
          }
          this.loadingFluorescence = false;
          this.isFetchingFluorescenceData = false;
        },
        error: (error: ErrorObject) => {
          this.error = error;
          this.loadingFluorescence = false;
          this.fluorescenceLoadingText = 'Error loading data';
          this.isFetchingFluorescenceData = false;
        },
      });
  }

  downloadBaseModuleCsv(): void {
    this.error = null;
    this.isDownloadingBaseModuleCsv = true;
    this.csvService.downloadModuleCsv('base', this.experimentId).subscribe({
      next: () => {
        this.isDownloadingBaseModuleCsv = false;
      },
      error: (error: ErrorObject) => {
        this.error = error;
        this.isDownloadingBaseModuleCsv = false;
      },
    });
  }

  downloadTurbidostatCsv(): void {
    this.error = null;
    this.isDownloadingTurbidostatCsv = true;
    this.csvService
      .downloadModuleCsv('turbidostat', this.experimentId)
      .subscribe({
        next: () => {
          this.isDownloadingTurbidostatCsv = false;
        },
        error: (error: ErrorObject) => {
          this.error = error;
          this.isDownloadingTurbidostatCsv = false;
        },
      });
  }

  downloadTemperatureCsv(): void {
    this.error = null;
    this.isDownloadingTemperatureCsv = true;
    this.csvService
      .downloadModuleCsv('temperature', this.experimentId)
      .subscribe({
        next: () => {
          this.isDownloadingTemperatureCsv = false;
        },
      });
  }

  downloadOxygenCsv(): void {
    this.error = null;
    this.isDownloadingOxygenCsv = true;
    this.csvService.downloadModuleCsv('oxygen', this.experimentId).subscribe({
      next: () => {
        this.isDownloadingOxygenCsv = false;
      },
      error: (error: ErrorObject) => {
        this.error = error;
        this.isDownloadingOxygenCsv = false;
      },
    });
  }

  downloadFluorescenceCsv(): void {
    this.error = null;
    this.isDownloadingFluorescenceCsv = true;
    this.csvService
      .downloadModuleCsv('fluorescence', this.experimentId)
      .subscribe({
        next: () => {
          this.isDownloadingFluorescenceCsv = false;
        },
        error: (error: ErrorObject) => {
          this.error = error;
          this.isDownloadingFluorescenceCsv = false;
        },
      });
  }

  downloadPhCsv(): void {
    this.error = null;
    this.isDownloadingPhCsv = true;
    this.csvService.downloadModuleCsv('ph', this.experimentId).subscribe({
      next: () => {
        this.isDownloadingPhCsv = false;
      },
      error: (error: ErrorObject) => {
        this.error = error;
        this.isDownloadingPhCsv = false;
      },
    });
  }

  /**
   * @description Initialises the device checkboxes used to select which devices to display
   * in the Device Summary view
   * @param {Device[]} deviceList list of devices attached to the current experiment from the API
   */
  initialiseDeviceCheckboxes = (deviceList: Device[]): void => {
    // Reset checkbox list
    this.deviceCheckboxesField.options = [];
    // Reset checkbox form array
    clearFormArray(this.checkboxesFormArray);
    // Now construct the checkbox array with new values
    deviceList.forEach((device) => {
      this.deviceCheckboxesField.options.push({
        key: 'device_' + device.id.toString(),
        value: device.name,
      });
      // If the device is in the selected devices list, set to true, otherwise false
      if (this.selectedDevices?.find((x) => x.id === device.id)) {
        this.checkboxesFormArray.push(new FormControl(true));
      } else {
        this.checkboxesFormArray.push(new FormControl(false));
      }
    });
    this.deviceCheckboxesAreInitialised = true;
  };

  /**
   * @description Initialises the flask checkboxes used to select which flasks to display the optical density data for on the graph
   * @param {Device[]} deviceList list of devices attached to the current experiment from the API
   */
  initialiseODFlaskCheckboxes = (deviceList: Device[]): void => {
    // Reset checkbox list
    this.odFlaskCheckboxesField.options = [];
    this.flasks.sort();
    // Reset checkbox form array
    clearFormArray(this.odFlaskCheckboxesFormArray);
    // Now construct the checkbox array with new values
    deviceList.forEach((device) => {
      this.flasks.forEach((flask) => {
        this.odDeviceTitles.push(device.name);
        this.odFlaskCheckboxesField.options.push({
          key: 'device_' + device.id.toString() + '_flask_' + flask,
          value: `${flask}`,
        });
        // If the device is in the selected devices list, set to true, otherwise false
        if (
          this.selectedODFlasks?.find(
            (x) => x === 'device_' + device.id.toString() + '_flask_' + flask,
          )
        ) {
          this.checkboxesFormArray.push(new FormControl(true));
        } else {
          this.checkboxesFormArray.push(new FormControl(false));
        }
      });
    });
    this.odDeviceTitles = this.odDeviceTitles.filter((x, i) => i % 4 === 4 - 1);
  };

  /**
   *  @description Initialises the flask checkboxes used to select which flasks to display the temperature data for on the graph
   * @param {Device[]} deviceList list of devices attached to the current experiment from the API
   */
  initialiseTemperatureFlaskCheckboxes = (deviceList: Device[]): void => {
    // Reset checkbox list
    this.temperatureFlaskCheckboxesField.options = [];
    this.temperatureFlasks.sort();
    // Reset checkbox form array
    clearFormArray(this.temperatureFlaskCheckboxesFormArray);
    // Now construct the checkbox array with new values
    deviceList.forEach((device) => {
      this.temperatureFlasks.forEach((flask) => {
        this.temperatureDeviceTitles.push(device.name);
        this.temperatureFlaskCheckboxesField.options.push({
          key: 'device_' + device.id.toString() + '_flask_' + flask,
          value: `${flask}`,
        });
        // If the device is in the selected devices list, set to true, otherwise false
        if (
          this.selectedTemperatureFlasks?.find(
            (x) => x === 'device_' + device.id.toString() + '_flask_' + flask,
          )
        ) {
          this.checkboxesFormArray.push(new FormControl(true));
        } else {
          this.checkboxesFormArray.push(new FormControl(false));
        }
      });
    });
    this.temperatureDeviceTitles = this.temperatureDeviceTitles.filter(
      (x, i) => i % 4 === 4 - 1,
    );
  };

  initialiseOxygenFlaskCheckboxes = (deviceList: Device[]): void => {
    // reset checkbox list
    this.oxygenFlaskCheckboxesField.options = [];
    this.oxygenFlasks.sort();
    // reset checkbox form array
    clearFormArray(this.oxygenFlaskCheckboxesFormArray);
    // now construct the checkbox array with new values
    deviceList.forEach((device) => {
      this.oxygenFlasks.forEach((flask) => {
        this.oxygenDeviceTitles.push(device.name);
        this.oxygenFlaskCheckboxesField.options.push({
          key: 'device_' + device.id.toString() + '_flask_' + flask,
          value: `${flask}`,
        });
        // if the device is in the selected devices list, set to true, otherwise false
        if (
          this.selectedOxygenFlasks?.find(
            (x) => x === 'device_' + device.id.toString() + '_flask_' + flask,
          )
        ) {
          this.checkboxesFormArray.push(new FormControl(true));
        } else {
          this.checkboxesFormArray.push(new FormControl(false));
        }
      });
    });
    this.oxygenDeviceTitles = this.oxygenDeviceTitles.filter(
      (x, i) => i % 4 === 4 - 1,
    );
  };

  initialisePHFlaskCheckboxes = (deviceList: Device[]): void => {
    // reset checkbox list
    this.pHFlaskCheckboxesField.options = [];
    this.flasksPH.sort();
    // reset checkbox form array
    clearFormArray(this.pHFlaskCheckboxesFormArray);
    // now construct the checkbox array with new values
    deviceList.forEach((device) => {
      this.flasksPH.forEach((flask) => {
        this.pHDeviceTitles.push(device.name);
        this.pHFlaskCheckboxesField.options.push({
          key: 'device_' + device.id.toString() + '_flask_' + flask,
          value: `${flask}`,
        });
        // if the device is in the selected devices list, set to true, otherwise false
        if (
          this.selectedPHFlasks?.find(
            (x) => x === 'device_' + device.id.toString() + '_flask_' + flask,
          )
        ) {
          this.checkboxesFormArray.push(new FormControl(true));
        } else {
          this.checkboxesFormArray.push(new FormControl(false));
        }
      });
    });
    this.pHDeviceTitles = this.pHDeviceTitles.filter((x, i) => i % 4 === 4 - 1);
  };

  initialiseFluorescenceFlaskCheckboxes = (deviceList: Device[]): void => {
    // reset checkbox list
    this.fluorescenceFlaskCheckboxesField.options = [];
    this.fluorescenceFlasks.sort();
    // reset checkbox form array
    clearFormArray(this.fluorescenceFlaskCheckboxesFormArray);
    // now construct the checkbox array with new values
    deviceList.forEach((device) => {
      this.fluorescenceFlasks.forEach((flask) => {
        this.fluorescenceDeviceTitles.push(device.name);
        this.fluorescenceFlaskCheckboxesField.options.push({
          key: 'device_' + device.id.toString() + '_flask_' + flask,
          value: `${flask}`,
        });
        // if the device is in the selected devices list, set to true, otherwise false
        if (
          this.selectedFluorescenceFlasks?.find(
            (x) => x === 'device_' + device.id.toString() + '_flask_' + flask,
          )
        ) {
          this.checkboxesFormArray.push(new FormControl(true));
        } else {
          this.checkboxesFormArray.push(new FormControl(false));
        }
      });
    });
    this.fluorescenceDeviceTitles = this.fluorescenceDeviceTitles.filter(
      (x, i) => i % 4 === 4 - 1,
    );
  };

  // ET - the below checkbox funcs could be refactored into a generic function
  // if a discriminator was passed with the click event to indicate which form array
  // the click came from

  /**
   * @description Handles the click event from the device summary checkboxes, and updates
   * the selectedDevices array accordingly
   * @param {Event} event The click event from the device checkbox
   */
  deviceCheckboxClicked(event: Event): void {
    const target = event.target as HTMLFormElement;
    // Find the id of the device that was clicked
    this.deviceCheckboxesField.options.forEach((element) => {
      if (element.key === target.name) {
        const cleanId = element.key.replace('device_', '');

        // Ugly but findIndex fails to find the index of the device in the array
        // may be related to the fact that the devices are nested objects when created (shallow copy?)
        let index = -1;
        for (const [i, device] of this.selectedDevices?.entries()) {
          if (device.id.toString() === cleanId) {
            index = i;
            break;
          }
        }

        if (target.checked === true) {
          // If device is not already in the list, push it, push it good
          if (index === -1) {
            this.selectedDevices.push(
              this.devices.filter((x) => x.id.toString() === cleanId)[0],
            );
          }
        }
        if (target.checked === false) {
          // If device is in the list, pop it, pop it good
          if (index !== -1) {
            this.selectedDevices.splice(index, 1);
          }
        }
      }
    });
  }

  // checkbox functions
  handleFlaskCheckboxClicked(event: Event, type: string): void {
    switch (type) {
      case 'od':
        this.flaskCheckboxClicked(event);
        break;
      case 'temperature':
        this.temperatureFlaskCheckboxClicked(event);
        break;
      case 'oxygen':
        this.oxygenFlaskCheckboxClicked(event);
        break;
      case 'fluorescence':
        this.fluorescenceFlaskCheckboxClicked(event);
        break;
      case 'pH':
        this.pHFlaskCheckboxClicked(event);
        break;
    }
  }

  handleLogarithmicCheckboxClicked(event: boolean): void {
    this.isLogarithmicChecked = event;

    this.renderODGraph = false;
    setTimeout(() => {
      this.logarithmicCheckboxClicked();
    }, 10);
  }

  flaskCheckboxClicked(event: Event): void {
    const target = event.target as HTMLFormElement;
    this.odFlaskCheckboxesField.options.forEach((element, i) => {
      if (element.key === target.name) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        this.graphCheckboxesForm.value[this.odFlaskCheckboxesField.key][i] =
          target.checked;
        const index = this.selectedODFlasks?.findIndex(
          (flask) => flask === element.key,
        );
        if (target.checked === true) {
          // If device is not already in the list, push it, push it good
          if (index === -1) {
            this.selectedODFlasks.push(element.key);
          }
        }
        if (target.checked === false) {
          // If device is in the list, pop it, pop it good
          if (index !== -1) {
            this.selectedODFlasks.splice(index, 1);
          }
        }
      }
    });

    this.selectedODData = {
      dataType: 'Optical Density',
      devices: [],
      experimentMode: this.experiment.experimentMode,
    };

    // This is a hacky way to deep copy the devices array and prevent it being passed by reference
    const devices: IDevice[] = JSON.parse(
      JSON.stringify(this.opticalDensityData?.devices),
    ) as IDevice[];

    devices.forEach((device) => {
      device.flasks = [];
    });

    this.opticalDensityData.devices.forEach((device, deviceIndex) => {
      device.flasks?.forEach((flask) => {
        const index = this.selectedODFlasks?.findIndex(
          (selectedFlask) =>
            selectedFlask ===
            `device_${device.deviceId.toString()}_flask_${flask.name}`,
        );
        if (index !== -1) {
          devices[deviceIndex].flasks.push(flask);
        }
      });
      this.selectedODData.devices = devices;
      this.renderODGraph = true;
    });
  }

  logarithmicCheckboxClicked(): void {
    this.renderODGraph = true;
  }

  temperatureFlaskCheckboxClicked(event: Event): void {
    const target = event.target as HTMLFormElement;
    this.temperatureFlaskCheckboxesField.options.forEach((element, i) => {
      if (element.key === target.name) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        this.graphCheckboxesForm.value[
          this.temperatureFlaskCheckboxesField.key
        ][i] = target.checked;
        const index = this.selectedTemperatureFlasks?.findIndex(
          (flask) => flask === element.key,
        );
        if (target.checked === true) {
          // If device is not already in the list, push it, push it good
          if (index === -1) {
            this.selectedTemperatureFlasks.push(element.key);
          }
        }
        if (target.checked === false) {
          // If device is in the list, pop it, pop it good
          if (index !== -1) {
            this.selectedTemperatureFlasks.splice(index, 1);
          }
        }
      }
    });

    this.selectedTemperatureData = {
      dataType: 'Temperature',
      devices: [],
    };

    const devices: IDevice[] = JSON.parse(
      JSON.stringify(this.temperatureData?.devices),
    ) as IDevice[];

    devices.forEach((device) => {
      device.flasks = [];
    });

    this.temperatureData.devices.forEach((device, deviceIndex) => {
      device.flasks?.forEach((flask) => {
        const index = this.selectedTemperatureFlasks?.findIndex(
          (selectedFlask) =>
            selectedFlask ===
            `device_${device.deviceId.toString()}_flask_${flask.name}`,
        );
        if (index !== -1) {
          devices[deviceIndex].flasks.push(flask);
        }
      });
      this.selectedTemperatureData.devices = devices;
      this.renderTemperatureGraph = true;
    });
  }

  oxygenFlaskCheckboxClicked(event: Event): void {
    const target = event.target as HTMLFormElement;
    this.oxygenFlaskCheckboxesField.options.forEach((element, i) => {
      if (element.key === target.name) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        this.graphCheckboxesForm.value[this.oxygenFlaskCheckboxesField.key][i] =
          target.checked;
        const index = this.selectedOxygenFlasks?.findIndex(
          (flask) => flask === element.key,
        );
        if (target.checked === true) {
          // If device is not already in the list, push it, push it good
          if (index === -1) {
            this.selectedOxygenFlasks.push(element.key);
          }
        }
        if (target.checked === false) {
          // If device is in the list, pop it, pop it good
          if (index !== -1) {
            this.selectedOxygenFlasks.splice(index, 1);
          }
        }
      }
    });

    this.selectedOxygenData = {
      dataType: 'Oxygen',
      devices: [],
    };

    const devices: IDevice[] = JSON.parse(
      JSON.stringify(this.oxygenData?.devices),
    ) as IDevice[];

    devices.forEach((device) => {
      device.flasks = [];
    });

    this.oxygenData.devices.forEach((device, deviceIndex) => {
      device.flasks?.forEach((flask) => {
        const index = this.selectedOxygenFlasks?.findIndex(
          (selectedFlask) =>
            selectedFlask ===
            `device_${device.deviceId.toString()}_flask_${flask.name}`,
        );
        if (index !== -1) {
          devices[deviceIndex].flasks.push(flask);
        }
      });
      this.selectedOxygenData.devices = devices;
      this.renderOxygenGraph = true;
    });
  }

  handleOxygenToggleClicked(): void {
    this.getOxygenData(true);
  }

  pHFlaskCheckboxClicked(event: Event): void {
    const target = event.target as HTMLFormElement;
    this.pHFlaskCheckboxesField.options.forEach((element, i) => {
      if (element.key === target.name) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        this.graphCheckboxesForm.value[this.pHFlaskCheckboxesField.key][i] =
          target.checked;
        const index = this.selectedPHFlasks?.findIndex(
          (flask) => flask === element.key,
        );
        if (target.checked === true) {
          // If device is not already in the list, push it, push it good
          if (index === -1) {
            this.selectedPHFlasks.push(element.key);
          }
        }
        if (target.checked === false) {
          // If device is in the list, pop it, pop it good
          if (index !== -1) {
            this.selectedPHFlasks.splice(index, 1);
          }
        }
      }
    });

    this.selectedPHData = {
      dataType: 'pH',
      devices: [],
    };

    const devices: IDevice[] = JSON.parse(
      JSON.stringify(this.pHData?.devices),
    ) as IDevice[];

    devices.forEach((device) => {
      device.flasks = [];
    });

    this.pHData.devices.forEach((device, deviceIndex) => {
      device.flasks?.forEach((flask) => {
        const index = this.selectedPHFlasks?.findIndex(
          (selectedFlask) =>
            selectedFlask ===
            `device_${device.deviceId.toString()}_flask_${flask.name}`,
        );
        if (index !== -1) {
          devices[deviceIndex].flasks.push(flask);
        }
      });
      this.selectedPHData.devices = devices;
      this.renderPHGraph = true;
    });
  }

  fluorescenceFlaskCheckboxClicked(event: Event): void {
    const target = event.target as HTMLFormElement;

    this.fluorescenceFlaskCheckboxesField.options.forEach((element, i) => {
      if (element.key === target.name) {
        // Update the form value for the checkbox
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        this.graphCheckboxesForm.value[
          this.fluorescenceFlaskCheckboxesField.key
        ][i] = target.checked;

        const stringIndex = element.key.indexOf('_flask_');

        // Construct the flask strings based on the fluorescence selections
        const flaskString1 = [
          element.key.slice(0, stringIndex + 7),
          `${this.fluorescenceSelection1 || ''}_`,
          element.key.slice(stringIndex + 7),
        ].join('');

        const flaskString2 = [
          element.key.slice(0, stringIndex + 7),
          `${this.fluorescenceSelection2 || ''}_`,
          element.key.slice(stringIndex + 7),
        ].join('');

        const flaskString3 = [
          element.key.slice(0, stringIndex + 7),
          `${this.fluorescenceSelection3 || ''}_`,
          element.key.slice(stringIndex + 7),
        ].join('');

        // Find indices for each flask string
        const index1 = this.selectedFluorescenceFlasks?.findIndex(
          (flask) => flask === flaskString1,
        );
        const index2 = this.selectedFluorescenceFlasks?.findIndex(
          (flask) => flask === flaskString2,
        );
        const index3 = this.selectedFluorescenceFlasks?.findIndex(
          (flask) => flask === flaskString3,
        );

        if (target.checked === true) {
          // If the flask string is not already in the list, push it
          if (index1 === -1 && this.fluorescenceSelection1) {
            this.selectedFluorescenceFlasks.push(flaskString1);
          }
          if (index2 === -1 && this.fluorescenceSelection2) {
            this.selectedFluorescenceFlasks.push(flaskString2);
          }
          if (index3 === -1 && this.fluorescenceSelection3) {
            this.selectedFluorescenceFlasks.push(flaskString3);
          }
        }

        if (target.checked === false) {
          // Remove the flask strings if they exist in the list
          if (index1 !== -1) {
            this.selectedFluorescenceFlasks.splice(index1, 1);
          }
          if (index2 !== -1) {
            this.selectedFluorescenceFlasks.splice(index2, 1);
          }
          if (index3 !== -1) {
            this.selectedFluorescenceFlasks.splice(index3, 1);
          }
        }
      }
    });

    // Update the selected fluorescence data object
    this.selectedFluorescenceData = {
      dataType: 'Fluorescence',
      devices: [],
    };

    // Create a deep copy of the devices and reset their flasks
    const devices: IDevice[] = JSON.parse(
      JSON.stringify(this.fluorescenceData?.devices),
    ) as IDevice[];

    devices.forEach((device) => {
      device.flasks = [];
    });

    // Iterate over each device and flask to populate the selected devices
    this.fluorescenceData.devices.forEach((device, deviceIndex) => {
      device.flasks?.forEach((flask) => {
        const flaskSelectionString = `device_${device.deviceId.toString()}_flask_${
          flask.name
        }`;

        // Check if the flask is selected based on fluorescence selection
        const indexSelection1 = this.selectedFluorescenceFlasks?.find(
          (selectedFlask) => selectedFlask === flaskSelectionString,
        );
        if (indexSelection1 !== null && indexSelection1 !== undefined) {
          devices[deviceIndex].flasks.push(flask);
        }

        const indexSelection2 = this.selectedFluorescenceFlasks?.find(
          (selectedFlask) => selectedFlask === flaskSelectionString,
        );
        if (indexSelection2 !== null && indexSelection2 !== undefined) {
          devices[deviceIndex].flasks.push(flask);
        }

        const indexSelection3 = this.selectedFluorescenceFlasks?.find(
          (selectedFlask) => selectedFlask === flaskSelectionString,
        );
        if (indexSelection3 !== null && indexSelection3 !== undefined) {
          devices[deviceIndex].flasks.push(flask);
        }
      });

      // Update selected fluorescence data with devices that have flasks
      this.selectedFluorescenceData.devices = devices;
      this.renderFluorescenceGraph = true;
    });
  }

  getCurrentTime(): Date {
    return new Date();
  }

  getTimeLeft(startTime: string, duration: number): TimeComponent {
    return calculateTimeLeft(startTime, duration);
  }

  closeModal(modal: string): void {
    this.modalService.close(modal);
  }

  openModal(modal: string): void {
    this.modalService.open(modal);
  }

  isDevicePaused(): boolean {
    const device = this.experiment.devices.find(
      (device) => device.id === this.selectedDeviceId,
    );
    return device.deviceStatusId === DeviceSetupStatus.Paused;
  }

  // TO DO - pass isDevicePaused to device info card to determine pause button label?

  pauseRestartButtonClicked(deviceId: number): void {
    this.selectedDeviceId = deviceId;
    const devicePaused = this.isDevicePaused();
    if (devicePaused) {
      this.pauseDeviceTitleText = 'Restart device';
      this.pauseDeviceValidationText = 'restart';
      this.pauseDeviceButtonText = 'restart';
    } else {
      this.pauseDeviceTitleText = 'Pause device';
      this.pauseDeviceValidationText = 'pause';
      this.pauseDeviceButtonText = 'pause';
    }
    this.openModal('pause-device');
  }

  pauseRestartDevices(): void {
    this.deviceService
      .pauseDeviceOnExperiment(
        this.selectedDeviceId.toString(),
        !this.isDevicePaused(),
      )
      .subscribe({
        next: () => {
          this.error = null;
          this.selectedDeviceId = null;
          this.getExperiment();
          this.closeModal('pause-device');
        },
        error: (error: ErrorObject) => {
          this.error = error;
          this.selectedDeviceId = null;
        },
      });
  }
}

@Component({
  selector: 'app-control-experiment',
  templateUrl: './control-experiment/control-experiment.component.html',
  styleUrls: ['./control-experiment/control-experiment.component.scss'],
  standalone: true,
  imports: [
    OptionTabToggleComponent,
    StatusIndicatorComponent,
    IconButtonComponent,
    ButtonComponent,
    UnderlinedFormFieldComponent,
    SlideToggleComponent,
    IconUnderlinedFormFieldComponent,
    RadioButtonComponent,
    UnderlinedTextareaFieldComponent,
    MatIcon,
    MatTooltip,
    DropdownFormFieldComponent,
    ValidationModalComponent,
    ModalComponent,
    DatePipe,
    RoleCheckPipe,
    FormatCamelCasePipe,
  ],
})
export class ControlExperimentComponent
  extends ActiveExperimentComponent
  implements OnInit
{
  protected route = inject(ActivatedRoute);
  protected formBuilder = inject(FormBuilder);
  protected experimentService = inject(ExperimentService);
  protected modalService = inject(ModalService);
  protected deviceService = inject(DeviceService);
  protected dataService = inject(DataService);
  protected router = inject(Router);
  protected authenticationService = inject(AuthenticationService);
  // Control vars
  // TODO: error per section
  error: ErrorObject = null;
  deleteError: ErrorObject = null;
  deleteIsProcessing = false;
  loading = false;

  stopExperimentProcessing = false;
  updateExperimentProcessing = false;
  pauseDevicesProcessing = false;

  ExperimentStatus = ExperimentStatus;
  devicesPaused = false;
  updateSpinner = IconNames.Sync;

  allowNavigation: Subject<boolean> = new Subject<boolean>();

  pauseDeviceTitleText = '';
  pauseDeviceValidationText = '';
  pauseDeviceButtonText = '';

  // Experiment update
  stopExperiment: ExperimentUpdate;
  updateExperiment: ExperimentUpdate;
  experimentDataLastUpdated: Date = new Date();

  // Experiment controls form
  minSampleTimeInMins = 1;
  maxSampleTimeInMins = 1000;
  sampleTimeStep = 1;
  minExperimentTimeInHours = 1;
  maxExperimentTimeInHours = 1000;
  experimentTimeStep = 1;
  minSpeed = 300;
  maxSpeed = 6520;
  speedStep = 20;
  minAmbientTemp = 0;
  maxAmbientTemp = 50;
  ambientTempStep = 0.5;
  ambientTempWarningMessage =
    'Warning: Temperature is outside of recommended range';
  // This will be set to the value of the ambient temperature field once
  // fetched from the API
  minCultureTemp = 37;
  maxCultureTemp = 50;
  cultureTempStep = 0.1;
  // Turbidostat
  minTargetOD = 0.1;
  maxTargetOD = 2;
  targetODStep = 0.05;
  // Chemostat
  minFlowRate = 0;
  maxFlowRate = 100;
  flowRateStep = 0.1;
  // pH Control
  minTargetPH = 0;
  maxTargetPH = 14;
  targetPHStep = 0.05;
  minPumpDuration = 0.3;
  maxPumpDuration = 15;
  pumpDurationStep = 0.1;
  // Fluorescence
  minLEDIntensity = 0;
  maxLEDIntensity = 255;
  ledIntensityStep = 1;
  // Oxygen
  minSalinity = 1;
  maxSalinity = 350;
  salinityStep = 1;
  displayTargetOD = true;

  // Table vars
  changelogList: ChangelogEntry[] = [];
  currentFilters = [];
  currentSort: {
    sortBy: string;
    sortDirection: string;
  } = {
    sortBy: 'CreatedAt',
    sortDirection: 'asc',
  };

  // Pagination
  pageRequested = 1;
  pageSize = 5;
  totalCount = 0;
  totalPages = 0;
  currentPage = 1;
  hasNext = false;
  hasPrevious = false;

  // Makes the ExperimentStatus enum available in the template
  ExperimentMode = ExperimentMode;
  Role = Role;

  // Experiment controls
  experimentNameField = new FormBase({
    key: 'name',
    label: 'Experiment name',
    type: 'text',
    placeholder: 'Experiment name',
    disabled: true,
    value: '',
    options: [],
  });

  organismField = new FormBase({
    key: 'organism',
    label: 'Organism',
    type: 'text',
    placeholder: 'Organism name',
    disabled: true,
    required: false,
    value: '',
    options: [],
  });

  strainField = new FormBase({
    key: 'strain',
    label: 'Strain',
    type: 'text',
    placeholder: 'Strain name',
    disabled: true,
    required: false,
    value: '',
    options: [],
  });

  mediaField = new FormBase({
    key: 'media',
    label: 'Media',
    type: 'text',
    placeholder: 'Media name',
    disabled: true,
    required: false,
    value: '',
    options: [],
  });

  // Base Unit
  ambientTemperatureField = new FormBase<number>({
    key: 'ambientTemperature',
    label: 'Ambient Temperature (°C)',
    type: 'number',
    placeholder: '0',
    disabled: true,
    required: false,
    value: null,
    options: [],
  });

  temperatureControlAField = new FormBase({
    key: 'temperatureControlA',
    label: 'Temperature Control A',
    type: 'toggle',
    placeholder: '',
    disabled: true,
    required: false,
    value: null,
    options: [],
  });

  temperatureControlBField = new FormBase({
    key: 'temperatureControlB',
    label: 'Temperature Control B',
    type: 'toggle',
    placeholder: '',
    disabled: true,
    required: false,
    value: null,
    options: [],
  });

  temperatureControlCField = new FormBase({
    key: 'temperatureControlC',
    label: 'Temperature Control C',
    type: 'toggle',
    placeholder: '',
    disabled: true,
    required: false,
    value: null,
    options: [],
  });

  temperatureControlDField = new FormBase({
    key: 'temperatureControlD',
    label: 'Temperature Control D',
    type: 'toggle',
    placeholder: '',
    disabled: true,
    required: false,
    value: null,
    options: [],
  });

  temperatureAField = new FormBase({
    key: 'cultureTemperatureA',
    label: 'Culture Temperature A (°C)',
    type: 'range',
    placeholder: '',
    disabled: true,
    required: false,
    value: '',
    options: [],
  });

  temperatureBField = new FormBase({
    key: 'cultureTemperatureB',
    label: 'Culture Temperature B (°C)',
    type: 'range',
    placeholder: '',
    disabled: true,
    required: false,
    value: '',
    options: [],
  });

  temperatureCField = new FormBase({
    key: 'cultureTemperatureC',
    label: 'Culture Temperature C (°C)',
    type: 'range',
    placeholder: '',
    disabled: true,
    required: false,
    value: '',
    options: [],
  });

  temperatureDField = new FormBase({
    key: 'cultureTemperatureD',
    label: 'Culture Temperature D (°C)',
    type: 'range',
    placeholder: '',
    disabled: true,
    required: false,
    value: '',
    options: [],
  });

  samplingTimeField = new FormBase<number>({
    key: 'samplingTimeField',
    label: 'Sampling Time (mins)',
    type: 'number',
    placeholder: '',
    disabled: true,
    required: false,
    value: 1,
    options: [],
  });

  experimentTimeField = new FormBase({
    key: 'experimentTimeField',
    label: 'Duration (hours)',
    type: 'number',
    placeholder: '0',
    disabled: true,
    required: false,
    value: 1,
    options: [],
  });

  speedAField = new FormBase({
    key: 'speedRPMAField',
    label: 'Stirring speed - flask A (RPM)',
    type: 'range',
    placeholder: '',
    disabled: true,
    required: false,
    value: this.experiment?.speedRPMA,
    options: [],
  });

  speedBField = new FormBase({
    key: 'speedRPMBField',
    label: 'Stirring speed - flask B (RPM)',
    type: 'range',
    placeholder: '',
    disabled: true,
    required: false,
    value: this.experiment?.speedRPMB,
    options: [],
  });

  speedCField = new FormBase({
    key: 'speedRPMCField',
    label: 'Stirring speed - flask C (RPM)',
    type: 'range',
    placeholder: '',
    disabled: true,
    required: false,
    value: this.experiment?.speedRPMC,
    options: [],
  });

  speedDField = new FormBase({
    key: 'speedRPMDField',
    label: 'Stirring speed - flask D (RPM)',
    type: 'range',
    placeholder: '',
    disabled: true,
    required: false,
    value: this.experiment?.speedRPMD,
    options: [],
  });

  setStirringSpeedAField = new FormBase<boolean>({
    key: 'setStirringSpeedAField',
    label: 'Set stirring speed of flask A to 0',
    type: 'toggle',
    placeholder: '',
    disabled: true,
    required: false,
    value: this.experiment?.speedRPMA === 0,
    options: [],
  });

  setStirringSpeedBField = new FormBase<boolean>({
    key: 'setStirringSpeedBField',
    label: 'Set stirring speed of flask B to 0',
    type: 'toggle',
    placeholder: '',
    disabled: true,
    required: false,
    value: this.experiment?.speedRPMB === 0,
    options: [],
  });

  setStirringSpeedCField = new FormBase<boolean>({
    key: 'setStirringSpeedCField',
    label: 'Set stirring speed of flask C to 0',
    type: 'toggle',
    placeholder: '',
    disabled: true,
    required: false,
    value: this.experiment?.speedRPMC === 0,
    options: [],
  });

  setStirringSpeedDField = new FormBase<boolean>({
    key: 'setStirringSpeedDField',
    label: 'Set stirring speed of flask D to 0',
    type: 'toggle',
    placeholder: '',
    disabled: true,
    required: false,
    value: this.experiment?.speedRPMD === 0,
    options: [],
  });

  // Turbidostat
  maintainODField = new FormBase<boolean>({
    key: 'maintainOD',
    label: 'Maintain initial OD',
    type: 'toggle',
    placeholder: '',
    disabled: true,
    required: false,
    value: this.experiment?.maintainOD,
    options: [],
  });

  targetODAField = new FormBase<number>({
    key: 'targetODA',
    label: 'Target OD - A',
    type: 'range',
    placeholder: '0',
    disabled: true,
    required: false,
    value: 0,
    options: [],
  });

  targetODBField = new FormBase<number>({
    key: 'targetODB',
    label: 'Target OD - B',
    type: 'range',
    placeholder: '0',
    disabled: true,
    required: false,
    value: 0,
    options: [],
  });

  targetODCField = new FormBase<number>({
    key: 'targetODC',
    label: 'Target OD - C',
    type: 'range',
    placeholder: '0',
    disabled: true,
    required: false,
    value: 0,
    options: [],
  });

  targetODDField = new FormBase<number>({
    key: 'targetODD',
    label: 'Target OD - D',
    type: 'range',
    placeholder: '0',
    disabled: true,
    required: false,
    value: 0,
    options: [],
  });

  turbAActiveField = new FormBase<boolean>({
    key: 'turbAActive',
    label: 'Turbidostat control - A',
    type: 'toggle',
    placeholder: '',
    disabled: true,
    required: false,
    value: false,
    options: [],
  });

  turbBActiveField = new FormBase<boolean>({
    key: 'turbBActive',
    label: 'Turbidostat control - B',
    type: 'toggle',
    placeholder: '',
    disabled: true,
    required: false,
    value: false,
    options: [],
  });

  turbCActiveField = new FormBase<boolean>({
    key: 'turbCActive',
    label: 'Turbidostat control - C',
    type: 'toggle',
    placeholder: '',
    disabled: true,
    required: false,
    value: false,
    options: [],
  });

  turbDActiveField = new FormBase<boolean>({
    key: 'turbDActive',
    label: 'Turbidostat control - D',
    type: 'toggle',
    placeholder: '',
    disabled: true,
    required: false,
    value: false,
    options: [],
  });

  // Chemostat
  chemoAActiveField = new FormBase<boolean>({
    key: 'chemoAActive',
    label: 'Chemostat control - A',
    type: 'toggle',
    placeholder: '',
    disabled: true,
    required: false,
    value: false,
    options: [],
  });

  chemoBActiveField = new FormBase<boolean>({
    key: 'chemoBActive',
    label: 'Chemostat control - B',
    type: 'toggle',
    placeholder: '',
    disabled: true,
    required: false,
    value: false,
    options: [],
  });

  chemoCActiveField = new FormBase<boolean>({
    key: 'chemoCActive',
    label: 'Chemostat control - C',
    type: 'toggle',
    placeholder: '',
    disabled: true,
    required: false,
    value: false,
    options: [],
  });

  chemoDActiveField = new FormBase<boolean>({
    key: 'chemoDActive',
    label: 'Chemostat control - D',
    type: 'toggle',
    placeholder: '',
    disabled: true,
    required: false,
    value: false,
    options: [],
  });

  flowRateAField = new FormBase<number>({
    key: 'flowRateA',
    label: 'Flow rate - A (ml/h)',
    type: 'range',
    placeholder: '0',
    disabled: true,
    required: false,
    value: null,
    options: [],
  });

  flowRateBField = new FormBase<number>({
    key: 'flowRateB',
    label: 'Flow rate - B (ml/h)',
    type: 'range',
    placeholder: '0',
    disabled: true,
    required: false,
    value: null,
    options: [],
  });

  flowRateCField = new FormBase<number>({
    key: 'flowRateC',
    label: 'Flow rate - C (ml/h)',
    type: 'range',
    placeholder: '0',
    disabled: true,
    required: false,
    value: null,
    options: [],
  });

  flowRateDField = new FormBase<number>({
    key: 'flowRateD',
    label: 'Flow rate - D (ml/h)',
    type: 'range',
    placeholder: '0',
    disabled: true,
    required: false,
    value: null,
    options: [],
  });

  // pH Control
  phControlAField = new FormBase<boolean>({
    key: 'phControlA',
    label: 'pH Control A',
    type: 'toggle',
    placeholder: '',
    disabled: true,
    required: true,
    value: true,
    options: [],
  });

  phControlBField = new FormBase<boolean>({
    key: 'phControlB',
    label: 'pH Control B',
    type: 'toggle',
    placeholder: '',
    disabled: true,
    required: false,
    value: true,
    options: [],
  });

  phControlCField = new FormBase<boolean>({
    key: 'phControlC',
    label: 'pH Control C',
    type: 'toggle',
    placeholder: '',
    disabled: true,
    required: false,
    value: true,
    options: [],
  });

  phControlDField = new FormBase<boolean>({
    key: 'phControlD',
    label: 'pH Control D',
    type: 'toggle',
    placeholder: '',
    disabled: true,
    required: false,
    value: true,
    options: [],
  });

  targetPHAField = new FormBase<number>({
    key: 'targetPHA',
    label: 'Target pH A',
    type: 'range',
    placeholder: '0',
    disabled: true,
    required: false,
    value: 7,
    options: [],
  });

  targetPHBField = new FormBase<number>({
    key: 'targetPHB',
    label: 'Target pH B',
    type: 'range',
    placeholder: '0',
    disabled: true,
    required: false,
    value: 7,
    options: [],
  });

  targetPHCField = new FormBase<number>({
    key: 'targetPHC',
    label: 'Target pH C',
    type: 'range',
    placeholder: '0',
    disabled: true,
    required: false,
    value: 7,
    options: [],
  });

  targetPHDField = new FormBase<number>({
    key: 'targetPHD',
    label: 'Target pH D',
    type: 'range',
    placeholder: '0',
    disabled: true,
    required: false,
    value: 7,
    options: [],
  });

  inputTypeAField = new FormBase<string>({
    key: 'inputTypeA',
    label: 'Input Type A',
    type: 'radio',
    placeholder: '',
    disabled: true,
    required: false,
    value: 'BaseA',
    options: [
      { key: 'BaseA', value: 'Base' },
      { key: 'AcidA', value: 'Acid' },
    ],
  });

  inputTypeBField = new FormBase<string>({
    key: 'inputTypeB',
    label: 'Input Type B',
    type: 'select',
    placeholder: '',
    disabled: true,
    required: false,
    value: 'BaseB',
    options: [
      { key: 'BaseB', value: 'Base' },
      { key: 'AcidB', value: 'Acid' },
    ],
  });

  inputTypeCField = new FormBase<string>({
    key: 'inputTypeC',
    label: 'Input Type C',
    type: 'select',
    placeholder: '',
    disabled: true,
    required: false,
    value: 'BaseC',
    options: [
      { key: 'BaseC', value: 'Base' },
      { key: 'AcidC', value: 'Acid' },
    ],
  });

  inputTypeDField = new FormBase<string>({
    key: 'inputTypeD',
    label: 'Input Type D',
    type: 'select',
    placeholder: '',
    disabled: true,
    required: false,
    value: 'BaseD',
    options: [
      { key: 'BaseD', value: 'Base' },
      { key: 'AcidD', value: 'Acid' },
    ],
  });

  pumpDurationAField = new FormBase<number>({
    key: 'pumpDurationA',
    label: 'Pump Duration A (seconds)',
    type: 'range',
    placeholder: '0',
    disabled: true,
    required: false,
    value: 5,
    options: [],
  });

  pumpDurationBField = new FormBase<number>({
    key: 'pumpDurationB',
    label: 'Pump Duration B (seconds)',
    type: 'range',
    placeholder: '0',
    disabled: true,
    required: false,
    value: 5,
    options: [],
  });

  pumpDurationCField = new FormBase<number>({
    key: 'pumpDurationC',
    label: 'Pump Duration C (seconds)',
    type: 'range',
    placeholder: '0',
    disabled: true,
    required: false,
    value: 5,
    options: [],
  });

  pumpDurationDField = new FormBase<number>({
    key: 'pumpDurationD',
    label: 'Pump Duration D (seconds)',
    type: 'range',
    placeholder: '0',
    disabled: true,
    required: false,
    value: 5,
    options: [],
  });

  // Fluro
  led1OnField = new FormBase<boolean>({
    key: 'led1On',
    label: 'LED 1 Always On',
    type: 'toggle',
    placeholder: '',
    disabled: true,
    required: false,
    value: false,
    options: [],
  });

  led2OnField = new FormBase<boolean>({
    key: 'led2On',
    label: 'LED 2 always on',
    type: 'toggle',
    placeholder: '',
    disabled: true,
    required: false,
    value: false,
    options: [],
  });

  led3OnField = new FormBase<boolean>({
    key: 'led3On',
    label: 'LED 3 always on',
    type: 'toggle',
    placeholder: '',
    disabled: true,
    required: false,
    value: false,
    options: [],
  });

  led1IntensityField = new FormBase<number>({
    key: 'led1Intensity',
    label: 'LED 1 intensity',
    type: 'range',
    placeholder: '0',
    disabled: true,
    required: false,
    value: 0,
    options: [],
  });

  led2IntensityField = new FormBase<number>({
    key: 'led2Intensity',
    label: 'LED 2 intensity',
    type: 'range',
    placeholder: '0',
    disabled: true,
    required: false,
    value: 0,
    options: [],
  });

  led3IntensityField = new FormBase<number>({
    key: 'led3Intensity',
    label: 'LED 3 intensity',
    type: 'range',
    placeholder: '0',
    disabled: true,
    required: false,
    value: 0,
    options: [],
  });

  // Oxygen
  salinityAField = new FormBase<number>({
    key: 'salinityA',
    label: 'Salinity A (g/l)',
    type: 'range',
    placeholder: '0',
    disabled: true,
    required: false,
    value: 0,
    options: [],
  });

  salinityBField = new FormBase<number>({
    key: 'salinityB',
    label: 'Salinity B (g/l)',
    type: 'range',
    placeholder: '0',
    disabled: true,
    required: false,
    value: 0,
    options: [],
  });

  salinityCField = new FormBase<number>({
    key: 'salinityC',
    label: 'Salinity C (g/l)',
    type: 'range',
    placeholder: '0',
    disabled: true,
    required: false,
    value: 0,
    options: [],
  });

  salinityDField = new FormBase<number>({
    key: 'salinityD',
    label: 'Salinity D (g/l)',
    type: 'range',
    placeholder: '0',
    disabled: true,
    required: false,
    value: 0,
    options: [],
  });

  // Notes
  noteField = new FormBase({
    key: 'noteField',
    label: 'Note',
    type: 'textarea',
    placeholder: 'Enter notes here...',
    disabled: true,
    required: false,
    value: '',
    options: [],
  });

  // Disable fields by default, only certain user roles and certain experiment
  // statuses will enable them
  experimentUpdateForm = this.formBuilder.group({
    [this.experimentNameField.key]: [
      { value: '', disabled: this.experimentNameField.disabled },
    ],
    [this.organismField.key]: [
      { value: '', disabled: this.organismField.disabled },
      [Validators.minLength(5), Validators.maxLength(100)],
    ],
    [this.strainField.key]: [
      { value: '', disabled: this.strainField.disabled },
      [Validators.minLength(5), Validators.maxLength(100)],
    ],
    [this.mediaField.key]: [
      { value: '', disabled: this.mediaField.disabled },
      [Validators.minLength(5), Validators.maxLength(100)],
    ],
    [this.samplingTimeField.key]: [
      {
        value: this.samplingTimeField.value,
        disabled: this.samplingTimeField.disabled,
      },
      [
        Validators.min(this.minSampleTimeInMins),
        Validators.max(this.maxSampleTimeInMins),
        stepValidator(this.sampleTimeStep),
        integerValidator(),
      ],
    ],
    [this.experimentTimeField.key]: [
      this.experimentTimeField.value,
      [
        Validators.min(this.minExperimentTimeInHours),
        Validators.max(this.maxExperimentTimeInHours),
        stepValidator(this.experimentTimeStep),
        integerValidator(),
      ],
    ],
    [this.speedAField.key]: [
      { value: this.speedAField.value, disabled: this.speedAField.disabled },
      [
        Validators.min(this.minSpeed),
        Validators.max(this.maxSpeed),
        stepValidator(this.speedStep),
      ],
    ],
    [this.speedBField.key]: [
      { value: this.speedBField.value, disabled: this.speedBField.disabled },
      [
        Validators.min(this.minSpeed),
        Validators.max(this.maxSpeed),
        stepValidator(this.speedStep),
      ],
    ],
    [this.speedCField.key]: [
      { value: this.speedCField.value, disabled: this.speedCField.disabled },
      [
        Validators.min(this.minSpeed),
        Validators.max(this.maxSpeed),
        stepValidator(this.speedStep),
      ],
    ],
    [this.speedDField.key]: [
      { value: this.speedDField.value, disabled: this.speedDField.disabled },
      [
        Validators.min(this.minSpeed),
        Validators.max(this.maxSpeed),
        stepValidator(this.speedStep),
      ],
    ],
    [this.setStirringSpeedAField.key]: [
      {
        value: this.setStirringSpeedAField.value,
        disabled: this.setStirringSpeedAField.disabled,
      },
    ],
    [this.setStirringSpeedBField.key]: [
      {
        value: this.setStirringSpeedBField.value,
        disabled: this.setStirringSpeedBField.disabled,
      },
    ],
    [this.setStirringSpeedCField.key]: [
      {
        value: this.setStirringSpeedCField.value,
        disabled: this.setStirringSpeedCField.disabled,
      },
    ],
    [this.setStirringSpeedDField.key]: [
      {
        value: this.setStirringSpeedDField.value,
        disabled: this.setStirringSpeedDField.disabled,
      },
    ],
    [this.ambientTemperatureField.key]: [
      { value: '', disabled: this.ambientTemperatureField.disabled },
    ],
    [this.temperatureControlAField.key]: [{ value: '', disabled: true }],
    [this.temperatureControlBField.key]: [{ value: '', disabled: true }],
    [this.temperatureControlCField.key]: [{ value: '', disabled: true }],
    [this.temperatureControlDField.key]: [{ value: '', disabled: true }],
    [this.temperatureAField.key]: [
      {
        value: this.temperatureAField.value,
        disabled: this.temperatureAField.disabled,
      },
      [
        Validators.min(this.minCultureTemp),
        Validators.max(this.maxAmbientTemp),
        stepValidator(this.cultureTempStep),
      ],
    ],
    [this.temperatureBField.key]: [
      {
        value: this.temperatureBField.value,
        disabled: this.temperatureBField.disabled,
      },
      [
        Validators.min(this.minCultureTemp),
        Validators.max(this.maxAmbientTemp),
        stepValidator(this.cultureTempStep),
      ],
    ],
    [this.temperatureCField.key]: [
      {
        value: this.temperatureCField.value,
        disabled: this.temperatureCField.disabled,
      },
      [
        Validators.min(this.minCultureTemp),
        Validators.max(this.maxAmbientTemp),
        stepValidator(this.cultureTempStep),
      ],
    ],
    [this.temperatureDField.key]: [
      {
        value: this.temperatureDField.value,
        disabled: this.temperatureDField.disabled,
      },
      [
        Validators.min(this.minCultureTemp),
        Validators.max(this.maxAmbientTemp),
        stepValidator(this.cultureTempStep),
      ],
    ],
    // Turbidostat
    [this.maintainODField.key]: [
      {
        value: this.maintainODField.value,
        disabled: this.maintainODField.disabled,
      },
    ],
    [this.targetODAField.key]: [
      {
        value: this.targetODAField.value,
        disabled: this.targetODAField.disabled,
      },
      Validators.compose([
        Validators.min(this.minTargetOD),
        Validators.max(this.maxTargetOD),
        stepValidator(this.targetODStep),
      ]),
    ],
    [this.targetODBField.key]: [
      {
        value: this.targetODBField.value,
        disabled: this.targetODBField.disabled,
      },
      Validators.compose([
        Validators.min(this.minTargetOD),
        Validators.max(this.maxTargetOD),
        stepValidator(this.targetODStep),
      ]),
    ],
    [this.targetODCField.key]: [
      {
        value: this.targetODCField.value,
        disabled: this.targetODCField.disabled,
      },
      Validators.compose([
        Validators.min(this.minTargetOD),
        Validators.max(this.maxTargetOD),
        stepValidator(this.targetODStep),
      ]),
    ],
    [this.targetODDField.key]: [
      {
        value: this.targetODDField.value,
        disabled: this.targetODDField.disabled,
      },
      Validators.compose([
        Validators.min(this.minTargetOD),
        Validators.max(this.maxTargetOD),
        stepValidator(this.targetODStep),
      ]),
    ],
    [this.turbAActiveField.key]: [
      {
        value: this.turbAActiveField.value,
        disabled: this.turbAActiveField.disabled,
      },
    ],
    [this.turbBActiveField.key]: [
      {
        value: this.turbBActiveField.value,
        disabled: this.turbBActiveField.disabled,
      },
    ],
    [this.turbCActiveField.key]: [
      {
        value: this.turbCActiveField.value,
        disabled: this.turbCActiveField.disabled,
      },
    ],
    [this.turbDActiveField.key]: [
      {
        value: this.turbDActiveField.value,
        disabled: this.turbDActiveField.disabled,
      },
    ],
    // Chemostat
    [this.chemoAActiveField.key]: [
      {
        value: this.chemoAActiveField.value,
        disabled: this.chemoAActiveField.disabled,
      },
    ],
    [this.chemoBActiveField.key]: [
      {
        value: this.chemoBActiveField.value,
        disabled: this.chemoBActiveField.disabled,
      },
    ],
    [this.chemoCActiveField.key]: [
      {
        value: this.chemoCActiveField.value,
        disabled: this.chemoCActiveField.disabled,
      },
    ],
    [this.chemoDActiveField.key]: [
      {
        value: this.chemoDActiveField.value,
        disabled: this.chemoDActiveField.disabled,
      },
    ],
    [this.flowRateAField.key]: [
      {
        value: this.flowRateAField.value,
        disabled: this.flowRateAField.disabled,
      },
      Validators.compose([
        Validators.min(this.minSpeed),
        Validators.max(this.maxSpeed),
        stepValidator(this.flowRateStep),
      ]),
    ],
    [this.flowRateBField.key]: [
      {
        value: this.flowRateBField.value,
        disabled: this.flowRateBField.disabled,
      },
      Validators.compose([
        Validators.min(this.minSpeed),
        Validators.max(this.maxSpeed),
        stepValidator(this.flowRateStep),
      ]),
    ],
    [this.flowRateCField.key]: [
      {
        value: this.flowRateCField.value,
        disabled: this.flowRateCField.disabled,
      },
      Validators.compose([
        Validators.min(this.minSpeed),
        Validators.max(this.maxSpeed),
        stepValidator(this.flowRateStep),
      ]),
    ],
    [this.flowRateDField.key]: [
      {
        value: this.flowRateDField.value,
        disabled: this.flowRateDField.disabled,
      },
      Validators.compose([
        Validators.min(this.minSpeed),
        Validators.max(this.maxSpeed),
        stepValidator(this.flowRateStep),
      ]),
    ],
    // pH controls
    [this.phControlAField.key]: [
      {
        value: this.phControlAField.value,
        disabled: this.phControlAField.disabled,
      },
    ],
    [this.phControlBField.key]: [
      {
        value: this.phControlBField.value,
        disabled: this.phControlBField.disabled,
      },
    ],
    [this.phControlCField.key]: [
      {
        value: this.phControlCField.value,
        disabled: this.phControlCField.disabled,
      },
    ],
    [this.phControlDField.key]: [
      {
        value: this.phControlDField.value,
        disabled: this.phControlDField.disabled,
      },
    ],
    [this.targetPHAField.key]: [
      {
        value: this.targetPHAField.value,
        disabled: this.targetPHAField.disabled,
      },
      Validators.compose([
        Validators.min(this.minTargetPH),
        Validators.max(this.maxTargetPH),
        stepValidator(this.targetPHStep),
      ]),
    ],
    [this.targetPHBField.key]: [
      {
        value: this.targetPHBField.value,
        disabled: this.targetPHBField.disabled,
      },
      Validators.compose([
        Validators.min(this.minTargetPH),
        Validators.max(this.maxTargetPH),
        stepValidator(this.targetPHStep),
      ]),
    ],
    [this.targetPHCField.key]: [
      {
        value: this.targetPHCField.value,
        disabled: this.targetPHCField.disabled,
      },
      Validators.compose([
        Validators.min(this.minTargetPH),
        Validators.max(this.maxTargetPH),
        stepValidator(this.targetPHStep),
      ]),
    ],
    [this.targetPHDField.key]: [
      {
        value: this.targetPHDField.value,
        disabled: this.targetPHDField.disabled,
      },
      Validators.compose([
        Validators.min(this.minTargetPH),
        Validators.max(this.maxTargetPH),
        stepValidator(this.targetPHStep),
      ]),
    ],
    [this.inputTypeAField.key]: [
      {
        value: this.inputTypeAField.value,
        disabled: this.inputTypeAField.disabled,
      },
    ],
    [this.inputTypeBField.key]: [
      {
        value: this.inputTypeBField.value,
        disabled: this.inputTypeBField.disabled,
      },
    ],
    [this.inputTypeCField.key]: [
      {
        value: this.inputTypeCField.value,
        disabled: this.inputTypeCField.disabled,
      },
    ],
    [this.inputTypeDField.key]: [
      {
        value: this.inputTypeDField.value,
        disabled: this.inputTypeDField.disabled,
      },
    ],
    [this.pumpDurationAField.key]: [
      {
        value: this.pumpDurationAField.value,
        disabled: this.pumpDurationAField.disabled,
      },
      Validators.compose([
        Validators.min(this.minPumpDuration),
        Validators.max(this.maxPumpDuration),
        stepValidator(this.pumpDurationStep),
      ]),
    ],
    [this.pumpDurationBField.key]: [
      {
        value: this.pumpDurationBField.value,
        disabled: this.pumpDurationBField.disabled,
      },
      Validators.compose([
        Validators.min(this.minPumpDuration),
        Validators.max(this.maxPumpDuration),
        stepValidator(this.pumpDurationStep),
      ]),
    ],
    [this.pumpDurationCField.key]: [
      {
        value: this.pumpDurationCField.value,
        disabled: this.pumpDurationCField.disabled,
      },
      Validators.compose([
        Validators.min(this.minPumpDuration),
        Validators.max(this.maxPumpDuration),
        stepValidator(this.pumpDurationStep),
      ]),
    ],
    [this.pumpDurationDField.key]: [
      {
        value: this.pumpDurationDField.value,
        disabled: this.pumpDurationDField.disabled,
      },
      Validators.compose([
        Validators.min(this.minPumpDuration),
        Validators.max(this.maxPumpDuration),
        stepValidator(this.pumpDurationStep),
      ]),
    ],
    // Fluoro
    [this.led1OnField.key]: [
      { value: this.led1OnField.value, disabled: this.led1OnField.disabled },
    ],
    [this.led2OnField.key]: [
      { value: this.led2OnField.value, disabled: this.led2OnField.disabled },
    ],
    [this.led3OnField.key]: [
      { value: this.led3OnField.value, disabled: this.led3OnField.disabled },
    ],
    [this.led1IntensityField.key]: [
      {
        value: this.led1IntensityField.value,
        disabled: this.led1IntensityField.disabled,
      },
      Validators.compose([
        Validators.min(this.minLEDIntensity),
        Validators.max(this.maxLEDIntensity),
        stepValidator(this.ledIntensityStep),
      ]),
    ],
    [this.led2IntensityField.key]: [
      {
        value: this.led2IntensityField.value,
        disabled: this.led2IntensityField.disabled,
      },
      Validators.compose([
        Validators.min(this.minLEDIntensity),
        Validators.max(this.maxLEDIntensity),
        stepValidator(this.ledIntensityStep),
      ]),
    ],
    [this.led3IntensityField.key]: [
      {
        value: this.led3IntensityField.value,
        disabled: this.led3IntensityField.disabled,
      },
      Validators.compose([
        Validators.min(this.minLEDIntensity),
        Validators.max(this.maxLEDIntensity),
        stepValidator(this.ledIntensityStep),
      ]),
    ],
    [this.salinityAField.key]: [
      { value: this.salinityAField.value, disabled: true },
      Validators.compose([
        Validators.min(this.minSalinity),
        Validators.max(this.maxSalinity),
        stepValidator(this.salinityStep),
      ]),
    ],
    [this.salinityBField.key]: [
      { value: this.salinityBField.value, disabled: true },
      Validators.compose([
        Validators.min(this.minSalinity),
        Validators.max(this.maxSalinity),
        stepValidator(this.salinityStep),
      ]),
    ],
    [this.salinityCField.key]: [
      { value: this.salinityCField.value, disabled: true },
      Validators.compose([
        Validators.min(this.minSalinity),
        Validators.max(this.maxSalinity),
        stepValidator(this.salinityStep),
      ]),
    ],
    [this.salinityDField.key]: [
      { value: this.salinityDField.value, disabled: true },
      Validators.compose([
        Validators.min(this.minSalinity),
        Validators.max(this.maxSalinity),
        stepValidator(this.salinityStep),
      ]),
    ],
    [this.noteField.key]: ['', [Validators.min(1), Validators.max(10000)]],
  });

  // Pagination form controls
  changeLogsPerPageField = new FormBase({
    key: 'changeLogsPerPage',
    label: 'Page size',
    type: 'dropdown',
    placeholder: '',
    disabled: false,
    required: false,
    value: '',
    options: [
      { key: '5', value: '5' },
      { key: '10', value: '10' },
      { key: '25', value: '25' },
      { key: '50', value: '50' },
      { key: '100', value: '100' },
    ],
  });
  paginationControls = this.formBuilder.group({
    [this.changeLogsPerPageField.key]: [this.pageSize.toString()],
  });

  ngOnInit(): void {
    super.ngOnInit();
    // Now get experiment
    this.getExperiment();

    if (this.experimentService.sort !== null) {
      this.currentSort = this.experimentService.sort;
    }

    if (this.experimentService.paginationCache !== null) {
      this.pageSize = this.experimentService.paginationCache.pageSize;
      this.pageRequested = this.experimentService.paginationCache.pageNumber;
      this.paginationControls.patchValue({
        [this.changeLogsPerPageField.key]: this.pageSize.toString(),
      });
    }

    if (this.hasEditAccess) {
      this.subscribeToValueChanges();
    }
  }

  /**
   * @remarks
   * Convenience getter for easy access to form fields
   *
   */
  get f(): FormGroup['controls'] {
    return this.experimentUpdateForm.controls;
  }

  closeModal(modal: string): void {
    this.modalService.close(modal);
  }

  openModal(modal: string): void {
    this.modalService.open(modal);
  }

  areDevicesPaused(): boolean {
    const paused = this.experiment.devices.find(
      (device) => device.deviceStatusId === DeviceSetupStatus.Paused,
    );
    if (paused) {
      return true;
    } else {
      return false;
    }
  }

  subscribeToValueChanges(): void {
    // If stirring speed is set to 0, disabled stirring speed slider field
    this.experimentUpdateForm
      .get(this.setStirringSpeedAField.key)
      .valueChanges.subscribe((value) => {
        if (this.experiment.experimentStatusId !== ExperimentStatus.Active) {
          this.experimentUpdateForm.controls[this.speedAField.key].setValue(
            this.experiment.speedRPMB,
          );
          this.experimentUpdateForm.controls[
            this.speedAField.key
          ].markAsDirty();
          this.experimentUpdateForm.controls[this.speedAField.key].disable();
        } else if (value === true) {
          this.experimentUpdateForm.controls[this.speedAField.key].setValue(
            this.minSpeed,
          );
          this.experimentUpdateForm.controls[
            this.speedAField.key
          ].markAsDirty();
          this.experimentUpdateForm.controls[this.speedAField.key].disable();
        } else {
          this.experimentUpdateForm.controls[
            this.speedAField.key
          ].markAsDirty();
          this.experimentUpdateForm.controls[this.speedAField.key].enable();
        }
      });

    this.experimentUpdateForm
      .get(this.setStirringSpeedBField.key)
      .valueChanges.subscribe((value) => {
        if (this.experiment.experimentStatusId !== ExperimentStatus.Active) {
          this.experimentUpdateForm.controls[this.speedBField.key].setValue(
            this.experiment.speedRPMB,
          );
          this.experimentUpdateForm.controls[
            this.speedBField.key
          ].markAsDirty();
          this.experimentUpdateForm.controls[this.speedBField.key].disable();
        } else if (value === true) {
          this.experimentUpdateForm.controls[this.speedBField.key].setValue(
            this.minSpeed,
          );
          this.experimentUpdateForm.controls[
            this.speedBField.key
          ].markAsDirty();
          this.experimentUpdateForm.controls[this.speedBField.key].disable();
        } else {
          this.experimentUpdateForm.controls[
            this.speedBField.key
          ].markAsDirty();
          this.experimentUpdateForm.controls[this.speedBField.key].enable();
        }
      });

    this.experimentUpdateForm
      .get(this.setStirringSpeedCField.key)
      .valueChanges.subscribe((value) => {
        if (this.experiment.experimentStatusId !== ExperimentStatus.Active) {
          this.experimentUpdateForm.controls[this.speedCField.key].setValue(
            this.experiment.speedRPMB,
          );
          this.experimentUpdateForm.controls[
            this.speedCField.key
          ].markAsDirty();
          this.experimentUpdateForm.controls[this.speedCField.key].disable();
        } else if (value === true) {
          this.experimentUpdateForm.controls[this.speedCField.key].setValue(
            this.minSpeed,
          );
          this.experimentUpdateForm.controls[
            this.speedCField.key
          ].markAsDirty();
          this.experimentUpdateForm.controls[this.speedCField.key].disable();
        } else {
          this.experimentUpdateForm.controls[
            this.speedCField.key
          ].markAsDirty();
          this.experimentUpdateForm.controls[this.speedCField.key].enable();
        }
      });

    this.experimentUpdateForm
      .get(this.setStirringSpeedDField.key)
      .valueChanges.subscribe((value) => {
        if (this.experiment.experimentStatusId !== ExperimentStatus.Active) {
          this.experimentUpdateForm.controls[this.speedDField.key].setValue(
            this.experiment.speedRPMB,
          );
          this.experimentUpdateForm.controls[
            this.speedDField.key
          ].markAsDirty();
          this.experimentUpdateForm.controls[this.speedDField.key].disable();
        } else if (value === true) {
          this.experimentUpdateForm.controls[this.speedDField.key].setValue(
            this.minSpeed,
          );
          this.experimentUpdateForm.controls[
            this.speedDField.key
          ].markAsDirty();
          this.experimentUpdateForm.controls[this.speedDField.key].disable();
        } else {
          this.experimentUpdateForm.controls[
            this.speedDField.key
          ].markAsDirty();
          this.experimentUpdateForm.controls[this.speedDField.key].enable();
        }
      });

    this.experimentUpdateForm
      .get(this.temperatureControlAField.key)
      .valueChanges.subscribe((value) => {
        if (value === true) {
          this.experimentUpdateForm.controls[
            this.temperatureAField.key
          ].enable();
          this.experimentUpdateForm.controls[
            this.temperatureAField.key
          ].setValue(this.experiment.cultureTemperatureA);
        } else if (value === false) {
          this.experimentUpdateForm.controls[
            this.temperatureAField.key
          ].disable();
          this.experimentUpdateForm.controls[
            this.temperatureAField.key
          ].setValue(null);
        }
      });

    this.experimentUpdateForm
      .get(this.temperatureControlBField.key)
      .valueChanges.subscribe((value) => {
        if (value === true) {
          this.experimentUpdateForm.controls[
            this.temperatureBField.key
          ].enable();
          this.experimentUpdateForm.controls[
            this.temperatureBField.key
          ].setValue(this.experiment.cultureTemperatureB);
        } else if (value === false) {
          this.experimentUpdateForm.controls[
            this.temperatureBField.key
          ].disable();
          this.experimentUpdateForm.controls[
            this.temperatureBField.key
          ].setValue(null);
        }
      });

    this.experimentUpdateForm
      .get(this.temperatureControlCField.key)
      .valueChanges.subscribe((value) => {
        if (value === true) {
          this.experimentUpdateForm.controls[
            this.temperatureCField.key
          ].enable();
          this.experimentUpdateForm.controls[
            this.temperatureCField.key
          ].setValue(this.experiment.cultureTemperatureC);
        } else if (value === false) {
          this.experimentUpdateForm.controls[
            this.temperatureCField.key
          ].disable();
          this.experimentUpdateForm.controls[
            this.temperatureCField.key
          ].setValue(null);
        }
      });

    this.experimentUpdateForm
      .get(this.temperatureControlDField.key)
      .valueChanges.subscribe((value) => {
        if (value === true) {
          this.experimentUpdateForm.controls[
            this.temperatureDField.key
          ].enable();
          this.experimentUpdateForm.controls[
            this.temperatureDField.key
          ].setValue(this.experiment.cultureTemperatureD);
        } else if (value === false) {
          this.experimentUpdateForm.controls[
            this.temperatureDField.key
          ].disable();
          this.experimentUpdateForm.controls[
            this.temperatureDField.key
          ].setValue(null);
        }
      });

    // pH Control
    this.experimentUpdateForm
      .get(this.phControlAField.key)
      .valueChanges.subscribe((value) => {
        if (value === true) {
          this.experimentUpdateForm.controls[this.targetPHAField.key].enable();
          this.experimentUpdateForm.controls[this.targetPHAField.key].setValue(
            this.experiment.targetPHA,
          );
          this.experimentUpdateForm.controls[
            this.pumpDurationAField.key
          ].enable();
          this.experimentUpdateForm.controls[
            this.pumpDurationAField.key
          ].setValue(this.experiment.pumpDurationA);
          this.experimentUpdateForm.controls[this.inputTypeAField.key].enable();
          this.experimentUpdateForm.controls[this.inputTypeAField.key].setValue(
            this.experiment.inputTypeA ? 'BaseA' : 'AcidA',
          );
        } else if (value === false) {
          this.experimentUpdateForm.controls[this.targetPHAField.key].disable();
          this.experimentUpdateForm.controls[this.targetPHAField.key].setValue(
            null,
          );
          this.experimentUpdateForm.controls[
            this.inputTypeAField.key
          ].disable();
          this.experimentUpdateForm.controls[this.inputTypeAField.key].setValue(
            null,
          );
          this.experimentUpdateForm.controls[
            this.pumpDurationAField.key
          ].disable();
          this.experimentUpdateForm.controls[
            this.pumpDurationAField.key
          ].setValue(null);
        }
      });

    this.experimentUpdateForm
      .get(this.phControlBField.key)
      .valueChanges.subscribe((value) => {
        if (value === true) {
          this.experimentUpdateForm.controls[this.targetPHBField.key].enable();
          this.experimentUpdateForm.controls[this.targetPHBField.key].setValue(
            this.experiment.targetPHB,
          );
          this.experimentUpdateForm.controls[
            this.pumpDurationBField.key
          ].enable();
          this.experimentUpdateForm.controls[
            this.pumpDurationBField.key
          ].setValue(this.experiment.pumpDurationB);
          this.experimentUpdateForm.controls[this.inputTypeBField.key].enable();
          this.experimentUpdateForm.controls[this.inputTypeBField.key].setValue(
            this.experiment.inputTypeB ? 'BaseB' : 'AcidB',
          );
        } else if (value === false) {
          this.experimentUpdateForm.controls[this.targetPHBField.key].disable();
          this.experimentUpdateForm.controls[this.targetPHBField.key].setValue(
            null,
          );
          this.experimentUpdateForm.controls[
            this.inputTypeBField.key
          ].disable();
          this.experimentUpdateForm.controls[this.inputTypeBField.key].setValue(
            null,
          );
          this.experimentUpdateForm.controls[
            this.pumpDurationBField.key
          ].disable();
          this.experimentUpdateForm.controls[
            this.pumpDurationBField.key
          ].setValue(null);
        }
      });

    this.experimentUpdateForm
      .get(this.phControlCField.key)
      .valueChanges.subscribe((value) => {
        if (value === true) {
          this.experimentUpdateForm.controls[this.targetPHCField.key].enable();
          this.experimentUpdateForm.controls[this.targetPHCField.key].setValue(
            this.experiment.targetPHC,
          );
          this.experimentUpdateForm.controls[
            this.pumpDurationCField.key
          ].enable();
          this.experimentUpdateForm.controls[
            this.pumpDurationCField.key
          ].setValue(this.experiment.pumpDurationC);
          this.experimentUpdateForm.controls[this.inputTypeCField.key].enable();
          this.experimentUpdateForm.controls[this.inputTypeCField.key].setValue(
            this.experiment.inputTypeC ? 'BaseC' : 'AcidC',
          );
        } else if (value === false) {
          this.experimentUpdateForm.controls[this.targetPHCField.key].disable();
          this.experimentUpdateForm.controls[this.targetPHCField.key].setValue(
            null,
          );
          this.experimentUpdateForm.controls[
            this.inputTypeCField.key
          ].disable();
          this.experimentUpdateForm.controls[this.inputTypeCField.key].setValue(
            null,
          );
          this.experimentUpdateForm.controls[
            this.pumpDurationCField.key
          ].disable();
          this.experimentUpdateForm.controls[
            this.pumpDurationCField.key
          ].setValue(null);
        }
      });

    this.experimentUpdateForm
      .get(this.phControlDField.key)
      .valueChanges.subscribe((value) => {
        if (value === true) {
          this.experimentUpdateForm.controls[this.targetPHDField.key].enable();
          this.experimentUpdateForm.controls[this.targetPHDField.key].setValue(
            this.experiment.targetPHD,
          );
          this.experimentUpdateForm.controls[
            this.pumpDurationDField.key
          ].enable();
          this.experimentUpdateForm.controls[
            this.pumpDurationDField.key
          ].setValue(this.experiment.pumpDurationD);
          this.experimentUpdateForm.controls[this.inputTypeDField.key].enable();
          this.experimentUpdateForm.controls[this.inputTypeDField.key].setValue(
            this.experiment.inputTypeD ? 'BaseD' : 'AcidD',
          );
        } else if (value === false) {
          this.experimentUpdateForm.controls[this.targetPHDField.key].disable();
          this.experimentUpdateForm.controls[this.targetPHDField.key].setValue(
            null,
          );
          this.experimentUpdateForm.controls[
            this.inputTypeDField.key
          ].disable();
          this.experimentUpdateForm.controls[this.inputTypeDField.key].setValue(
            null,
          );
          this.experimentUpdateForm.controls[
            this.pumpDurationDField.key
          ].disable();
          this.experimentUpdateForm.controls[
            this.pumpDurationDField.key
          ].setValue(null);
        }
      });
  }

  onStopExperiment(): void {
    if (!this.hasEditAccess || this.stopExperimentProcessing) return;

    this.stopExperimentProcessing = true;
    this.error = null;

    this.stopExperiment = new ExperimentUpdate(
      this.experiment.id,
      ExperimentStatus.Complete,
    );
    this.experimentService
      .updateActiveExperiment(this.stopExperiment)
      .subscribe({
        next: (response) => {
          this.error = null;
          this.stopExperimentProcessing = false;
          this.experiment = response;
          // Now initialise the forms with the updated experiment
          this.initialiseExperimentUpdateForm();
          // And get the update to the changelog
          this.getExperimentChangelog();
          // Close modal
          this.closeModal('stop-experiment');
        },
        error: (error: ErrorObject) => {
          this.error = error;
          this.stopExperimentProcessing = false;
          this.openModal('error');
        },
      });
  }

  pauseRestartButtonClicked(): void {
    if (!this.hasEditAccess) return;
    const devicePaused = this.areDevicesPaused();
    if (devicePaused) {
      this.pauseDeviceTitleText = 'Restart device';
      this.pauseDeviceValidationText = 'restart';
      this.pauseDeviceButtonText = 'restart';
    } else {
      this.pauseDeviceTitleText = 'Pause device';
      this.pauseDeviceValidationText = 'pause';
      this.pauseDeviceButtonText = 'pause';
    }
    this.openModal('pause-devices');
  }

  pauseDevicesOnExperiment(): void {
    if (!this.hasEditAccess || this.pauseDevicesProcessing) return;

    this.pauseDevicesProcessing = true;
    this.error = null;

    const pauseOrResume = this.areDevicesPaused() === true ? 'true' : 'false';
    this.experimentService
      .pauseDevicesOnExperiment(this.experiment.id.toString(), pauseOrResume)
      .subscribe({
        next: () => {
          this.error = null;
          this.pauseDevicesProcessing = false;
          this.getExperiment();
          this.closeModal('pause-devices');
        },
        error: (error: ErrorObject) => {
          this.error = error;
          this.pauseDevicesProcessing = false;
          this.openModal('error');
        },
      });
  }

  getExperiment(dontCallChangelog = false): void {
    this.loading = true;
    this.error = null;

    this.experimentService.getExperimentById(this.experimentId).subscribe({
      next: (response: Experiment) => {
        this.experimentDataLastUpdated = new Date();
        this.experiment = response;
        this.devices = response.devices;
        this.loading = false;
        // Now we can set min culture temp to the ambient temp
        this.minCultureTemp = this.experiment.ambientTemperature;
        // Initialise the forms with the data
        this.initialiseExperimentUpdateForm();
        // Fetch the experiment data (calling it in tandem with getExperiment causes null
        // exception on backend)
        if (!dontCallChangelog) {
          this.getExperimentChangelog();
        }
      },
      error: (error: ErrorObject) => {
        this.error = error;
        this.loading = false;
        this.openModal('error');
      },
    });
  }

  getExperimentChangelog(): void {
    this.error = null;
    this.experimentService
      .getExperimentChangelog(
        this.experimentId,
        this.currentSort.sortBy,
        this.currentSort.sortDirection,
        this.pageSize,
        this.pageRequested,
      )
      .subscribe({
        next: (response: ChangelogResponse) => {
          this.error = null;
          this.changelogList = response.changelogList;
          // Set pagination vars from response headers
          this.currentPage = response.paginationData.CurrentPage;
          this.pageSize = response.paginationData.PageSize;
          this.totalPages = response.paginationData.TotalPages;
          this.hasNext = response.paginationData.HasNext;
          this.hasPrevious = response.paginationData.HasPrevious;
          this.getExperiment(true);
        },
        error: (error: ErrorObject) => {
          // TODO: Handle error
          this.error = error;
        },
      });
  }

  initialiseExperimentUpdateForm(): void {
    this.experimentUpdateForm.patchValue({
      [this.experimentNameField.key]: this.experiment.name,
      [this.organismField.key]: this.experiment.organism,
      [this.strainField.key]: this.experiment.strain,
      [this.mediaField.key]: this.experiment.media,
      [this.samplingTimeField.key]: this.experiment.samplingInterval.toString(),
      [this.experimentTimeField.key]: this.experiment.duration.toString(),
      [this.speedAField.key]: this.experiment.speedRPMA,
      [this.speedBField.key]: this.experiment.speedRPMB,
      [this.speedCField.key]: this.experiment.speedRPMC,
      [this.speedDField.key]: this.experiment.speedRPMD,
      [this.setStirringSpeedAField.key]: this.experiment.speedRPMA === 0,
      [this.setStirringSpeedBField.key]: this.experiment.speedRPMB === 0,
      [this.setStirringSpeedCField.key]: this.experiment.speedRPMC === 0,
      [this.setStirringSpeedDField.key]: this.experiment.speedRPMD === 0,
      [this.ambientTemperatureField.key]:
        this.experiment.ambientTemperature.toString(),
      [this.temperatureControlAField.key]: this.experiment.temperatureControlA,
      [this.temperatureControlBField.key]: this.experiment.temperatureControlB,
      [this.temperatureControlCField.key]: this.experiment.temperatureControlC,
      [this.temperatureControlDField.key]: this.experiment.temperatureControlD,
      [this.temperatureAField.key]: this.experiment.cultureTemperatureA,
      [this.temperatureBField.key]: this.experiment.cultureTemperatureB,
      [this.temperatureCField.key]: this.experiment.cultureTemperatureC,
      [this.temperatureDField.key]: this.experiment.cultureTemperatureD,
      [this.maintainODField.key]: this.experiment.maintainOD,
      [this.targetODAField.key]: this.experiment.targetODA,
      [this.targetODBField.key]: this.experiment.targetODB,
      [this.targetODCField.key]: this.experiment.targetODC,
      [this.targetODDField.key]: this.experiment.targetODD,
      [this.turbAActiveField.key]: this.experiment.turbAActive,
      [this.turbBActiveField.key]: this.experiment.turbBActive,
      [this.turbCActiveField.key]: this.experiment.turbCActive,
      [this.turbDActiveField.key]: this.experiment.turbDActive,
      [this.chemoAActiveField.key]: this.experiment.chemoAActive,
      [this.chemoBActiveField.key]: this.experiment.chemoBActive,
      [this.chemoCActiveField.key]: this.experiment.chemoCActive,
      [this.chemoDActiveField.key]: this.experiment.chemoDActive,
      [this.flowRateAField.key]: this.experiment.flowRateA,
      [this.flowRateBField.key]: this.experiment.flowRateB,
      [this.flowRateCField.key]: this.experiment.flowRateC,
      [this.flowRateDField.key]: this.experiment.flowRateD,
      [this.phControlAField.key]: this.experiment.phControlA,
      [this.phControlBField.key]: this.experiment.phControlB,
      [this.phControlCField.key]: this.experiment.phControlC,
      [this.phControlDField.key]: this.experiment.phControlD,
      [this.targetPHAField.key]: this.experiment.targetPHA,
      [this.targetPHBField.key]: this.experiment.targetPHB,
      [this.targetPHCField.key]: this.experiment.targetPHC,
      [this.targetPHDField.key]: this.experiment.targetPHD,
      [this.inputTypeAField.key]: this.experiment.inputTypeA
        ? 'BaseA'
        : 'AcidA',
      [this.inputTypeBField.key]: this.experiment.inputTypeB
        ? 'BaseB'
        : 'AcidB',
      [this.inputTypeCField.key]: this.experiment.inputTypeC
        ? 'BaseC'
        : 'AcidC',
      [this.inputTypeDField.key]: this.experiment.inputTypeD
        ? 'BaseD'
        : 'AcidD',
      [this.pumpDurationAField.key]: this.experiment.pumpDurationA,
      [this.pumpDurationBField.key]: this.experiment.pumpDurationB,
      [this.pumpDurationCField.key]: this.experiment.pumpDurationC,
      [this.pumpDurationDField.key]: this.experiment.pumpDurationD,
      [this.led1OnField.key]: this.experiment.led1On,
      [this.led2OnField.key]: this.experiment.led2On,
      [this.led3OnField.key]: this.experiment.led3On,
      [this.led1IntensityField.key]: this.experiment.led1Intensity,
      [this.led2IntensityField.key]: this.experiment.led2Intensity,
      [this.led3IntensityField.key]: this.experiment.led3Intensity,
      [this.salinityAField.key]: this.experiment.salinityA,
      [this.salinityBField.key]: this.experiment.salinityB,
      [this.salinityCField.key]: this.experiment.salinityC,
      [this.salinityDField.key]: this.experiment.salinityD,
    });

    if (this.hasEditAccess && this.experiment.isDeleted === false) {
      this.experimentUpdateForm.controls[this.organismField.key].enable();
      this.experimentUpdateForm.controls[this.strainField.key].enable();
      this.experimentUpdateForm.controls[this.mediaField.key].enable();
      this.experimentUpdateForm.controls[this.experimentTimeField.key].enable();
      this.experimentUpdateForm.controls[this.samplingTimeField.key].enable();
      this.experimentUpdateForm.controls[
        this.setStirringSpeedAField.key
      ].enable();
      this.experimentUpdateForm.controls[
        this.setStirringSpeedBField.key
      ].enable();
      this.experimentUpdateForm.controls[
        this.setStirringSpeedCField.key
      ].enable();
      this.experimentUpdateForm.controls[
        this.setStirringSpeedDField.key
      ].enable();
      this.experimentUpdateForm.controls[this.speedAField.key].enable();
      this.experimentUpdateForm.controls[this.speedBField.key].enable();
      this.experimentUpdateForm.controls[this.speedCField.key].enable();
      this.experimentUpdateForm.controls[this.speedDField.key].enable();
      this.experimentUpdateForm.controls[
        this.temperatureControlAField.key
      ].enable();
      this.experimentUpdateForm.controls[
        this.temperatureControlBField.key
      ].enable();
      this.experimentUpdateForm.controls[
        this.temperatureControlCField.key
      ].enable();
      this.experimentUpdateForm.controls[
        this.temperatureControlDField.key
      ].enable();
      this.experimentUpdateForm.controls[this.temperatureAField.key].enable();
      this.experimentUpdateForm.controls[this.temperatureBField.key].enable();
      this.experimentUpdateForm.controls[this.temperatureCField.key].enable();
      this.experimentUpdateForm.controls[this.temperatureDField.key].enable();
      // LED controls should be enabled for all experiment types
      this.f.led1On.enable();
      this.f.led2On.enable();
      this.f.led3On.enable();
      this.f.led1Intensity.enable();
      this.f.led2Intensity.enable();
      this.f.led3Intensity.enable();

      switch (this.experiment.experimentMode) {
        case ExperimentMode.Turbidostat:
          this.f.turbAActive.enable();
          this.f.turbBActive.enable();
          this.f.turbCActive.enable();
          this.f.turbDActive.enable();
          // Target OD fields only editable if maintainOD is set to false
          if (
            this.experimentUpdateForm.controls[this.maintainODField.key]
              .value === false
          ) {
            this.f.targetODA.enable();
            this.f.targetODB.enable();
            this.f.targetODC.enable();
            this.f.targetODD.enable();
          }
          break;
        case ExperimentMode.Chemostat:
          this.f.chemoAActive.enable();
          this.f.chemoBActive.enable();
          this.f.chemoCActive.enable();
          this.f.chemoDActive.enable();
          this.f.flowRateA.enable();
          this.f.flowRateB.enable();
          this.f.flowRateC.enable();
          this.f.flowRateD.enable();
          break;
        case ExperimentMode.PHControl:
          this.f.phControlA.enable();
          this.f.phControlB.enable();
          this.f.phControlC.enable();
          this.f.phControlD.enable();
          // pH fields only editable if phControl is set to true
          if (this.f.phControlA.value === true) {
            this.f.targetPHA.enable();
            this.f.pumpDurationA.enable();
            this.f.inputTypeA.enable();
          }
          if (this.f.phControlB.value === true) {
            this.f.targetPHB.enable();
            this.f.pumpDurationB.enable();
            this.f.inputTypeB.enable();
          }
          if (this.f.phControlC.value === true) {
            this.f.targetPHC.enable();
            this.f.pumpDurationC.enable();
            this.f.inputTypeC.enable();
          }
          if (this.f.phControlD.value === true) {
            this.f.targetPHD.enable();
            this.f.pumpDurationD.enable();
            this.f.inputTypeD.enable();
          }
          break;
        default:
          break;
      }

      // If the experiment is not active, then only allow notes to be updated
      if (this.experiment.experimentStatusId !== ExperimentStatus.Active) {
        this.experimentUpdateForm.disable();
        this.f.noteField.enable();
      }
    }
    if (!this.hasEditAccess || this.experiment.isDeleted === true) {
      this.experimentUpdateForm.disable();
    }
    this.experimentUpdateForm.markAsPristine();
  }

  onSubmitExperimentUpdateForm(): void {
    // If form is already being submitted, exit
    if (this.updateExperimentProcessing) {
      return;
    }
    this.updateExperimentProcessing = true;
    this.error = null;

    this.updateExperiment = new ExperimentUpdate(
      this.experiment.id,
      undefined, // Experiment status doesn't get updated in this call
      undefined, // useFluoro not updateable
      undefined, // useOxygen not updateable
      undefined, // usePh not updateable
      undefined, // useSalinity not updateable
      undefined, // Name not updateable
      this.experimentUpdateForm.get(this.organismField.key).dirty
        ? (this.f.organism.value as string)
        : undefined,
      this.experimentUpdateForm.get(this.strainField.key).dirty
        ? (this.f.strain.value as string)
        : undefined,
      this.experimentUpdateForm.get(this.mediaField.key).dirty
        ? (this.f.media.value as string)
        : undefined,
      undefined, // Ambient temp not updateable
      this.experimentUpdateForm.get(this.temperatureControlAField.key).dirty
        ? (this.f.temperatureControlA.value as boolean)
        : undefined,
      this.experimentUpdateForm.get(this.temperatureControlBField.key).dirty
        ? (this.f.temperatureControlB.value as boolean)
        : undefined,
      this.experimentUpdateForm.get(this.temperatureControlCField.key).dirty
        ? (this.f.temperatureControlC.value as boolean)
        : undefined,
      this.experimentUpdateForm.get(this.temperatureControlDField.key).dirty
        ? (this.f.temperatureControlD.value as boolean)
        : undefined,
      this.experimentUpdateForm.get(this.temperatureAField.key).dirty
        ? (this.f.cultureTemperatureA.value as number)
        : undefined,
      this.experimentUpdateForm.get(this.temperatureBField.key).dirty
        ? (this.f.cultureTemperatureB.value as number)
        : undefined,
      this.experimentUpdateForm.get(this.temperatureCField.key).dirty
        ? (this.f.cultureTemperatureC.value as number)
        : undefined,
      this.experimentUpdateForm.get(this.temperatureDField.key).dirty
        ? (this.f.cultureTemperatureD.value as number)
        : undefined,
      this.experimentUpdateForm.get(this.samplingTimeField.key).dirty
        ? (this.f.samplingTimeField.value as number)
        : undefined,
      this.experimentUpdateForm.get(this.experimentTimeField.key).dirty
        ? (this.f.experimentTimeField.value as number)
        : undefined,
      this.experimentUpdateForm.get(this.speedAField.key).dirty
        ? (this.f.speedRPMAField.value as number)
        : undefined,
      this.experimentUpdateForm.get(this.speedBField.key).dirty
        ? (this.f.speedRPMBField.value as number)
        : undefined,
      this.experimentUpdateForm.get(this.speedCField.key).dirty
        ? (this.f.speedRPMCField.value as number)
        : undefined,
      this.experimentUpdateForm.get(this.speedDField.key).dirty
        ? (this.f.speedRPMDField.value as number)
        : undefined,
      undefined, // Maintain OD not updateable
      this.experimentUpdateForm.get(this.targetODAField.key).dirty
        ? (this.f.targetODA.value as number)
        : undefined,
      this.experimentUpdateForm.get(this.targetODBField.key).dirty
        ? (this.f.targetODB.value as number)
        : undefined,
      this.experimentUpdateForm.get(this.targetODCField.key).dirty
        ? (this.f.targetODC.value as number)
        : undefined,
      this.experimentUpdateForm.get(this.targetODDField.key).dirty
        ? (this.f.targetODD.value as number)
        : undefined,
      this.experimentUpdateForm.get(this.turbAActiveField.key).dirty
        ? (this.f.turbAActive.value as boolean)
        : undefined,
      this.experimentUpdateForm.get(this.turbBActiveField.key).dirty
        ? (this.f.turbBActive.value as boolean)
        : undefined,
      this.experimentUpdateForm.get(this.turbCActiveField.key).dirty
        ? (this.f.turbCActive.value as boolean)
        : undefined,
      this.experimentUpdateForm.get(this.turbDActiveField.key).dirty
        ? (this.f.turbDActive.value as boolean)
        : undefined,
      // Chemostat
      this.experimentUpdateForm.get(this.chemoAActiveField.key).dirty
        ? (this.f.chemoAActive.value as boolean)
        : undefined,
      this.experimentUpdateForm.get(this.chemoBActiveField.key).dirty
        ? (this.f.chemoBActive.value as boolean)
        : undefined,
      this.experimentUpdateForm.get(this.chemoCActiveField.key).dirty
        ? (this.f.chemoCActive.value as boolean)
        : undefined,
      this.experimentUpdateForm.get(this.chemoDActiveField.key).dirty
        ? (this.f.chemoDActive.value as boolean)
        : undefined,
      this.experimentUpdateForm.get(this.flowRateAField.key).dirty
        ? (this.f.flowRateA.value as number)
        : undefined,
      this.experimentUpdateForm.get(this.flowRateBField.key).dirty
        ? (this.f.flowRateB.value as number)
        : undefined,
      this.experimentUpdateForm.get(this.flowRateCField.key).dirty
        ? (this.f.flowRateC.value as number)
        : undefined,
      this.experimentUpdateForm.get(this.flowRateDField.key).dirty
        ? (this.f.flowRateD.value as number)
        : undefined,
      // pH Control - TODO: Add pH control fields
      this.experimentUpdateForm.get(this.phControlAField.key).dirty
        ? (this.f.phControlA.value as boolean)
        : undefined,
      this.experimentUpdateForm.get(this.phControlBField.key).dirty
        ? (this.f.phControlB.value as boolean)
        : undefined,
      this.experimentUpdateForm.get(this.phControlCField.key).dirty
        ? (this.f.phControlC.value as boolean)
        : undefined,
      this.experimentUpdateForm.get(this.phControlDField.key).dirty
        ? (this.f.phControlD.value as boolean)
        : undefined,
      this.experimentUpdateForm.get(this.targetPHAField.key).dirty
        ? (this.f.targetPHA.value as number)
        : undefined,
      this.experimentUpdateForm.get(this.targetPHBField.key).dirty
        ? (this.f.targetPHB.value as number)
        : undefined,
      this.experimentUpdateForm.get(this.targetPHCField.key).dirty
        ? (this.f.targetPHC.value as number)
        : undefined,
      this.experimentUpdateForm.get(this.targetPHDField.key).dirty
        ? (this.f.targetPHD.value as number)
        : undefined,
      this.experimentUpdateForm.get(this.inputTypeAField.key).dirty
        ? this.f[this.inputTypeAField.key].value === 'BaseA'
        : undefined,
      this.experimentUpdateForm.get(this.inputTypeBField.key).dirty
        ? this.f[this.inputTypeBField.key].value === 'BaseB'
        : undefined,
      this.experimentUpdateForm.get(this.inputTypeCField.key).dirty
        ? this.f[this.inputTypeCField.key].value === 'BaseC'
        : undefined,
      this.experimentUpdateForm.get(this.inputTypeDField.key).dirty
        ? this.f[this.inputTypeDField.key].value === 'BaseD'
        : undefined,
      this.experimentUpdateForm.get(this.pumpDurationAField.key).dirty
        ? (this.f.pumpDurationA.value as number)
        : undefined,
      this.experimentUpdateForm.get(this.pumpDurationBField.key).dirty
        ? (this.f.pumpDurationB.value as number)
        : undefined,
      this.experimentUpdateForm.get(this.pumpDurationCField.key).dirty
        ? (this.f.pumpDurationC.value as number)
        : undefined,
      this.experimentUpdateForm.get(this.pumpDurationDField.key).dirty
        ? (this.f.pumpDurationD.value as number)
        : undefined,
      // Fluoro
      this.experimentUpdateForm.get(this.led1OnField.key).dirty
        ? (this.f.led1On.value as boolean)
        : undefined,
      this.experimentUpdateForm.get(this.led2OnField.key).dirty
        ? (this.f.led2On.value as boolean)
        : undefined,
      this.experimentUpdateForm.get(this.led3OnField.key).dirty
        ? (this.f.led3On.value as boolean)
        : undefined,
      this.experimentUpdateForm.get(this.led1IntensityField.key).dirty
        ? (this.f.led1Intensity.value as number)
        : undefined,
      this.experimentUpdateForm.get(this.led2IntensityField.key).dirty
        ? (this.f.led2Intensity.value as number)
        : undefined,
      this.experimentUpdateForm.get(this.led3IntensityField.key).dirty
        ? (this.f.led3Intensity.value as number)
        : undefined,
      undefined, // Salinity not updateable
      undefined, // Salinity not updateable
      undefined, // Salinity not updateable
      undefined, // Salinity not updateable
      this.f.noteField.value as string,
    );

    this.experimentService
      .updateActiveExperiment(this.updateExperiment)
      .subscribe({
        next: () => {
          this.error = null;
          this.updateExperimentProcessing = false;
          this.modalService.open('update-experiment');
          // TODO - once API is fixed, use response to update experiment
          // and remove call to getExperiment from getExperimentChangelog
          // Get the update to the changelog
          this.getExperimentChangelog();
        },
        error: (error: ErrorObject) => {
          this.error = error;
          this.updateExperimentProcessing = false;
          this.openModal('error');
        },
      });
  }

  deleteExperiment(): void {
    // If request has already been submitted, exit
    if (this.deleteIsProcessing) {
      return;
    }

    this.deleteError = null;
    this.deleteIsProcessing = true;

    this.experimentService.deleteExperimentById(this.experimentId).subscribe({
      next: () => {
        this.deleteIsProcessing = false;
        this.closeModal('delete-experiment');
        this.openModal('delete-success');
      },
      error: (error: ErrorObject) => {
        this.deleteIsProcessing = false;
        this.deleteError = error;
        this.closeModal('delete-experiment');
        this.openModal('delete-error');
      },
    });
  }

  getPrevious(): void {
    this.pageRequested = this.currentPage - 1;
    this.getExperimentChangelog();
  }

  getNext(): void {
    this.pageRequested = this.currentPage + 1;
    this.getExperimentChangelog();
  }

  onSort(sortTerm: string): void {
    if (this.currentSort.sortBy === sortTerm) {
      if (this.currentSort.sortDirection === 'asc') {
        this.currentSort.sortDirection = 'desc';
      } else {
        this.currentSort.sortDirection = 'asc';
      }
    } else {
      this.currentSort.sortBy = sortTerm;
      this.currentSort.sortDirection = 'asc';
    }
    this.getExperimentChangelog();
  }

  modalButtonClicked(buttonId: string): void {
    switch (buttonId) {
      case 'cancel-button':
        this.allowNavigation.next(false);
        this.closeModal('attention');
        break;
      case 'confirm-navigation':
        this.allowNavigation.next(true);
        this.closeModal('attention');
        break;
      case 'close-button':
        this.allowNavigation.next(false);
        this.closeModal('error');
        break;
      case 'close-success-button':
        this.closeModal('update-experiment');
        break;
      case 'delete-success-button':
        this.closeModal('delete-success');
        this.allowNavigation.next(true);
        void this.router.navigate(['dashboard']);
        break;
      default:
        break;
    }
  }

  canExit(): Promise<boolean> | boolean {
    return this.isNavigationAllowed();
  }

  private isNavigationAllowed(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      if (this.experimentUpdateForm.pristine) {
        resolve(true);
      } else {
        this.modalService.open('attention');
        this.allowNavigation.subscribe((isConfirmed) => resolve(isConfirmed));
      }
    });
  }
}
