<img id="logo" src="assets/img/ogi-logo.png" alt="" />
<div id="page">
  <div id="container">
    @if (tokenStatus === 1 && !accountCreated) {
      <div id="form--container">
        <h4>Nearly there</h4>
        <p>Please enter a password to finish setting up your account.</p>
        <div class="error">
          @if (createUserError?.message) {
            <span>Error: </span>
            {{ createUserError?.message }}
          }
        </div>
        <form [formGroup]="createUserForm" (ngSubmit)="onSubmit()">
          <app-mat-form-field
            iconName="lock"
            [field]="passwordField"
            [parentForm]="createUserForm"
          ></app-mat-form-field>
          <app-mat-form-field
            iconName="lock"
            [field]="confirmPasswordField"
            [parentForm]="createUserForm"
          >
          </app-mat-form-field>
          <ul class="body2">
            <app-validation-row
              validationText="Password must be at least 12 characters long"
              [passesValidation]="passesValidation('length')"
            ></app-validation-row>
            <app-validation-row
              validationText="Password must contain at least one uppercase letter"
              [passesValidation]="passesValidation('uppercaseLetter')"
            ></app-validation-row>
            <app-validation-row
              validationText="Password must contain at least one lowercase letter"
              [passesValidation]="passesValidation('lowercaseLetter')"
            ></app-validation-row>
            <app-validation-row
              validationText="Password must contain at least one number"
              [passesValidation]="passesValidation('number')"
            ></app-validation-row>
            <app-validation-row
              validationText="Password must contain at least one special character (&#64;#$%^&+!=)"
              [passesValidation]="passesValidation('specialCharacter')"
            ></app-validation-row>
          </ul>
          <app-checkbox
            [checkbox]="termsAndConditions"
            [parentForm]="createUserForm"
          ></app-checkbox>
          <app-button
            type="submit"
            label="Create Account"
            buttonId="create-acc-btn"
            [isProcessing]="isProcessing"
          ></app-button>
          <!-- TODO: Add link to terms and conditions when available -->
          <a href="">Terms & Conditions</a>
        </form>
      </div>
    }
    @if (tokenStatus === 1 && accountCreated) {
      <div id="form--container">
        <mat-icon class="green-icon">check_circle</mat-icon>
        <h4>Account Created</h4>
        <app-button
          type="button"
          label="Go to login"
          buttonId="login-btn"
          [routerLink]="['/login']"
        ></app-button>
      </div>
    }
    @if (tokenStatus === 2) {
      <div id="validation--error">
        <mat-icon class="red-icon">error</mat-icon>
        <h4>Something went wrong</h4>
        <div class="error">
          @if (validationError?.message) {
            <span>Error: </span>
            {{
              validationError?.message?.message
                ? validationError?.message?.message
                : validationError?.message
            }}
          }
        </div>
      </div>
    }
  </div>
</div>
