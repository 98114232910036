import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Device } from 'src/app/_models/device';
import { DeviceSetupStatus } from 'src/app/_models/device-setup-status';

@Component({
  selector: 'app-device-setup-row',
  templateUrl: './device-setup-row.component.html',
  styleUrls: ['./device-setup-row.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeviceSetupRowComponent {
  DeviceSetupStatus = DeviceSetupStatus;
  /**
   * @param {Device} device The calibration summary for the device
   * @required
   */
  @Input()
  device: Device;

  /**
   * @param {boolean} isDisabled Is the button disabled
   */
  @Input()
  deviceComplete = false;
}
