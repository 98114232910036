import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ForgotPasswordComponent } from './account/forgot-password/forgot-password.component';
import { LoginComponent } from './account/login/login.component';
import { ResetPasswordComponent } from './account/reset-password/reset-password.component';
import { ResetSentComponent } from './account/reset-sent/reset-sent.component';
import {
  DevicesComponent,
  ErrorLogsComponent,
  ExperimentsComponent,
} from './dashboard/dashboard.component';
import { ResetSuccessfulComponent } from './account/reset-successful/reset-successful.component';
import { SelectDeviceComponent } from './experiments/select-device/select-device.component';
import { SetParametersComponent } from './experiments/set-parameters/set-parameters.component';
import { AuthGuard } from './_guards/auth.guard';
import { CanDeactivateGuard } from './_guards/can-deactivate.guard';
import { Role } from './_models/role';
import {
  ControlExperimentComponent,
  DisplayExperimentComponent,
} from './experiments/active-experiment/active-experiment.component';
import { DeviceSetupComponent } from './experiments/device-setup/device-setup.component';
import { CreateCalibrationComponent } from './calibration/create-calibration/create-calibration.component';
import { CalibrationDataComponent } from './calibration/calibration-data/calibration-data.component';
import { AddUserComponent } from './user/add-user/add-user.component';
import { UpdateDetailsComponent } from './user/update-details/update-details.component';
import { AddUserConfirmationComponent } from './account/add-user-confirmation/add-user-confirmation.component';
import { AddDeviceComponent } from './devices/add-device/add-device.component';
import { DeviceDetailsComponent } from './devices/device-details/device-details.component';
import { ViewBuffersComponent } from './buffer/view-buffers/view-buffers.component';
import {
  DevicesApprovalComponent,
  LabsApprovalComponent,
  OrganisationsApprovalComponent,
  UsersApprovalComponent,
} from './approvals/approvals.component';
import { AddLabComponent } from './laboratories/add-lab/add-lab.component';
import { LabDetailsComponent } from './laboratories/lab-details/lab-details.component';
import { UpdateUserComponent } from './user/update-user/update-user.component';
import {
  OdCalibrationsComponent,
  OxygenCalibrationsComponent,
  PhCalibrationsComponent,
} from './devices/device-calibrations/device-calibrations.component';
import { OverviewComponent } from './organisations/overview/overview.component';
import { CreateOrganisationComponent } from './organisations/create-organisation/create-organisation.component';
import { OrganisationComponent } from './organisations/organisation/organisation.component';
import { HelpComponent } from './help/help.component';

export interface RouteData {
  roles: Role[];
}

interface CustomRoute extends Routes {
  data?: RouteData;
}

const routes: CustomRoute = [
  {
    path: 'calibration',
    canActivate: [AuthGuard],
    children: [
      {
        path: 'create-calibration',
        component: CreateCalibrationComponent,
        canActivate: [AuthGuard],
        pathMatch: 'full',
        data: {
          roles: [
            Role.OGI_Super_Administrator,
            Role.OGI_Laboratory_Administrator,
            Role.Super_User,
            Role.Lab_Administrator,
            Role.Edit_Access_User,
          ],
        },
      },
      {
        path: ':calibrationType/:id/calibration-data',
        component: CalibrationDataComponent,
        canActivate: [AuthGuard],
        pathMatch: 'full',
      },
    ],
  },
  {
    path: 'buffer',
    children: [
      {
        path: 'view-buffers',
        component: ViewBuffersComponent,
        canActivate: [AuthGuard],
      },
    ],
  },
  {
    path: 'laboratories',
    children: [
      {
        path: 'add-lab',
        component: AddLabComponent,
        canActivate: [AuthGuard],
        pathMatch: 'full',
        data: {
          roles: [Role.OGI_Super_Administrator, Role.Super_User],
        },
      },
      {
        path: 'view-lab',
        component: LabDetailsComponent,
        canActivate: [AuthGuard],
      },
    ],
  },
  {
    path: 'experiment',
    canActivate: [AuthGuard],
    children: [
      {
        path: ':id',
        canActivate: [AuthGuard],
        children: [
          {
            path: 'display',
            component: DisplayExperimentComponent,
            canActivate: [AuthGuard],
            pathMatch: 'full',
          },
          {
            path: 'control',
            component: ControlExperimentComponent,
            canActivate: [AuthGuard],
            canDeactivate: [CanDeactivateGuard],
            pathMatch: 'full',
          },
          {
            path: 'set-parameters',
            component: SetParametersComponent,
            canActivate: [AuthGuard],
            canDeactivate: [CanDeactivateGuard],
            pathMatch: 'full',
            data: {
              roles: [
                Role.OGI_Super_Administrator,
                Role.OGI_Laboratory_Administrator,
                Role.Super_User,
                Role.Lab_Administrator,
                Role.Edit_Access_User,
                Role.Run_Access_User,
              ],
            },
          },
          {
            path: 'select-device',
            component: SelectDeviceComponent,
            canActivate: [AuthGuard],
            pathMatch: 'full',
            data: {
              roles: [
                Role.OGI_Super_Administrator,
                Role.OGI_Laboratory_Administrator,
                Role.Super_User,
                Role.Lab_Administrator,
                Role.Edit_Access_User,
              ],
            },
          },
          {
            path: 'device-setup',
            component: DeviceSetupComponent,
            canActivate: [AuthGuard],
            canDeactivate: [CanDeactivateGuard],
            pathMatch: 'full',
            data: {
              roles: [
                Role.OGI_Super_Administrator,
                Role.OGI_Laboratory_Administrator,
                Role.Super_User,
                Role.Lab_Administrator,
                Role.Edit_Access_User,
                Role.Run_Access_User,
              ],
            },
          },
        ],
      },
    ],
  },
  {
    path: 'dashboard',
    canActivate: [AuthGuard],
    children: [
      {
        path: 'devices',
        component: DevicesComponent,
        canActivate: [AuthGuard],
        pathMatch: 'full',
      },
      {
        path: 'experiments',
        component: ExperimentsComponent,
        canActivate: [AuthGuard],
        pathMatch: 'full',
      },
      {
        path: 'error-logs',
        component: ErrorLogsComponent,
        canActivate: [AuthGuard],
        pathMatch: 'full',
      },
      {
        path: '',
        redirectTo: '/dashboard/devices',
        pathMatch: 'full',
      },
    ],
  },
  {
    path: 'approvals',
    canActivate: [AuthGuard],
    children: [
      {
        path: 'labs',
        component: LabsApprovalComponent,
        canActivate: [AuthGuard],
        pathMatch: 'full',
        data: {
          roles: [Role.OGI_Super_Administrator],
        },
      },
      {
        path: 'users',
        component: UsersApprovalComponent,
        canActivate: [AuthGuard],
        pathMatch: 'full',
        data: {
          roles: [Role.OGI_Super_Administrator],
        },
      },
      {
        path: 'devices',
        component: DevicesApprovalComponent,
        canActivate: [AuthGuard],
        pathMatch: 'full',
        data: {
          roles: [Role.OGI_Super_Administrator],
        },
      },
      {
        path: 'organisations',
        component: OrganisationsApprovalComponent,
        canActivate: [AuthGuard],
        pathMatch: 'full',
        data: {
          roles: [Role.OGI_Super_Administrator],
        },
      },
      {
        path: '',
        redirectTo: '/approvals/labs',
        pathMatch: 'full',
      },
    ],
  },
  {
    path: 'user',
    canActivate: [AuthGuard],
    children: [
      {
        path: 'update-user/:id',
        component: UpdateUserComponent,
        canActivate: [AuthGuard],
        pathMatch: 'full',
        data: {
          roles: [Role.OGI_Super_Administrator, Role.Super_User],
        },
      },
      {
        path: 'add-user',
        component: AddUserComponent,
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard],
        pathMatch: 'full',
        data: {
          roles: [
            Role.OGI_Super_Administrator,
            Role.OGI_Laboratory_Administrator,
            Role.Super_User,
            Role.Lab_Administrator,
          ],
        },
      },
    ],
  },
  {
    path: 'account',
    canActivate: [AuthGuard],
    children: [
      {
        path: 'update-details',
        component: UpdateDetailsComponent,
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard],
        pathMatch: 'full',
      },
    ],
  },
  {
    path: 'help',
    canActivate: [AuthGuard],
    component: HelpComponent,
    pathMatch: 'full',
  },
  {
    path: 'device',
    canActivate: [AuthGuard],
    children: [
      {
        path: 'create-device',
        component: AddDeviceComponent,
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard],
        pathMatch: 'full',
        data: {
          roles: [
            Role.OGI_Super_Administrator,
            Role.OGI_Laboratory_Administrator,
            Role.Super_User,
            Role.Lab_Administrator,
          ],
        },
      },
      {
        path: ':id',
        canActivate: [AuthGuard],
        children: [
          {
            path: 'details',
            component: DeviceDetailsComponent,
            canActivate: [AuthGuard],
            canDeactivate: [CanDeactivateGuard],
            pathMatch: 'full',
          },
          {
            path: 'od-calibrations',
            component: OdCalibrationsComponent,
            canActivate: [AuthGuard],
            pathMatch: 'full',
          },
          {
            path: 'oxygen-calibrations',
            component: OxygenCalibrationsComponent,
            canActivate: [AuthGuard],
            pathMatch: 'full',
          },
          {
            path: 'ph-calibrations',
            component: PhCalibrationsComponent,
            canActivate: [AuthGuard],
            pathMatch: 'full',
          },
          {
            path: 'errors',
            component: PhCalibrationsComponent,
            canActivate: [AuthGuard],
            pathMatch: 'full',
          },
        ],
      },
    ],
  },
  {
    path: 'organisations',
    canActivate: [AuthGuard],
    children: [
      {
        path: 'administration',
        component: OverviewComponent,
        canActivate: [AuthGuard],
        pathMatch: 'full',
        data: {
          roles: [Role.OGI_Super_Administrator],
        },
      },
      {
        path: 'create-organisation',
        component: CreateOrganisationComponent,
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard],
        pathMatch: 'full',
        data: {
          roles: [Role.OGI_Super_Administrator],
        },
      },
      {
        path: ':id',
        canActivate: [AuthGuard],
        children: [
          {
            path: 'details',
            component: OrganisationComponent,
            canActivate: [AuthGuard],
            pathMatch: 'full',
            data: {
              roles: [Role.OGI_Super_Administrator, Role.Super_User],
            },
          },
        ],
      },
    ],
  },
  {
    path: 'create-account',
    component: AddUserConfirmationComponent,
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
  },
  {
    path: 'reset-sent',
    component: ResetSentComponent,
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent,
  },
  {
    path: 'reset-successful',
    component: ResetSuccessfulComponent,
  },
  { path: '**', redirectTo: 'dashboard/devices' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: false })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
