import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { User } from '../_models/user';
import { environment } from 'src/environments/environment';
import { Role } from '../_models/role';
import {
  ValidateUserTokenApiResponse,
  JWTResponse,
} from '../_models/api-responses';
import { httpOptions } from '../_helpers/utils';
import { Laboratory } from '../_models/laboratory';

interface UserClaims {
  userId: number;
  organisationId: number;
  roles: Role[];
  laboratories: Laboratory[];
}

interface LabResponse {
  laboratoryId: number;
  laboratoryName: string;
}

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<User | null>;
  public currentUser: Observable<User | null>;
  private selectedLabSubject: BehaviorSubject<Laboratory | null>;
  public selectedLab: Observable<Laboratory | null>;
  private adminAllLabsList: BehaviorSubject<Laboratory[] | null>;
  public adminAllLabs: Observable<Laboratory[] | null>;
  private loggedIn = new BehaviorSubject<boolean>(false);

  httpOptions = httpOptions;

  constructor(private http: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<User | null>(null);
    this.adminAllLabsList = new BehaviorSubject<Laboratory[] | null>(null);
    let cachedUser = localStorage.getItem('currentUser');
    let storage = localStorage;
    if (cachedUser) {
      this.currentUserSubject.next((JSON.parse(cachedUser) as User) || null);
    } else {
      storage = sessionStorage;
      cachedUser = sessionStorage.getItem('currentUser');
      if (cachedUser) {
        this.currentUserSubject.next((JSON.parse(cachedUser) as User) || null);
      }
    }

    this.currentUser = this.currentUserSubject.asObservable();
    if (this.currentUser) {
      this.loggedIn.next(true);
      // Since we're not going to make a call to the server to login, set labs from local storage
      this.selectedLabSubject = new BehaviorSubject<Laboratory | null>(
        this.currentUserSubject.value?.laboratories[0]
      );
      this.selectedLab = this.selectedLabSubject.asObservable();
    }
    // TODO: add user id to allLab list so that if it doesn't match the current user id, it will be cleared down
    if (
      this.currentUserValue?.role ===
      (Role.OGI_Super_Administrator || Role.Super_User)
    ) {
      const allLabs = storage.getItem('allLabs');
      if (allLabs) {
        this.adminAllLabsList = new BehaviorSubject<Laboratory[]>(
          JSON.parse(allLabs) as Laboratory[]
        );
      } else {
        this.adminAllLabsList = new BehaviorSubject<Laboratory[] | null>(null);
      }
      this.adminAllLabs = this.adminAllLabsList.asObservable();
    }
  }

  public get currentUserValue(): User | null {
    return this.currentUserSubject.value;
  }

  public get user(): Observable<User | null> {
    return this.currentUser;
  }

  public get isLoggedIn(): Observable<boolean> {
    return this.loggedIn.asObservable();
  }

  public get selectedLaboratory(): Laboratory {
    return this.selectedLabSubject.value;
  }

  public updateCurrentUser(user: User) {
    this.currentUserSubject.next(user);
    // Also update local storage
    localStorage.setItem('currentUser', JSON.stringify(user));
  }

  public setSelectedLaboratory(lab: Laboratory) {
    this.selectedLabSubject.next(lab);
  }

  public get adminAllLabsListValue(): Laboratory[] | [] {
    return this.adminAllLabsList.value;
  }

  login(
    username: string,
    password: string,
    rememberMe: boolean
  ): Observable<boolean> {
    const userLogin = JSON.stringify({
      username,
      password,
      rememberMe,
    });

    return this.http
      .post<void>(`${environment.apiUrl}/login`, userLogin, this.httpOptions)
      .pipe(
        switchMap((response) => {
          // Grab jwt from auth headers
          const token = response.headers.get('Authorization');

          // Now decode token
          const decodedPayload = JSON.parse(
            window.atob(token.split('.')[1])
          ) as JWTResponse;

          const userResponse = new User(
            decodedPayload.unique_name,
            decodedPayload.FirstName,
            decodedPayload.LastName
          );

          userResponse.role = this.determineHighestRole(decodedPayload.Roles);
          userResponse.id = decodedPayload.UserId;
          userResponse.token = token;
          userResponse.laboratories = JSON.parse(
            decodedPayload.Laboratories
          ) as Laboratory[];
          userResponse.organisationId = decodedPayload.UserOrgId;
          // If lab list is empty, and user is not an OGI_Super_Administrator or Super_User, then refuse login
          if (
            userResponse.laboratories.length === 0 &&
            userResponse.role !== Role.OGI_Super_Administrator &&
            userResponse.role !== Role.Super_User
          ) {
            return of(false);
          }
          // Inititally set isOwnLab to true for all labs
          userResponse.laboratories.forEach((lab) => {
            lab.isOwnLab = true;
          });
          // Also set the selected lab to the first lab in the list
          this.selectedLabSubject.next(userResponse.laboratories[0]);

          // If the user is an OGI_Super_Administrator or organisation Super_User, we need to get all labs
          // and store them in local storage
          if (
            userResponse.role === Role.OGI_Super_Administrator ||
            userResponse.role === Role.Super_User
          ) {
            return this.fetchAllLabs(token, userResponse.organisationId).pipe(
              tap((labs) => {
                labs.forEach((lab) => {
                  if (
                    !userResponse.laboratories.find(
                      (l) => l.laboratoryId === lab.laboratoryId
                    )
                  ) {
                    userResponse.laboratories.push({
                      laboratoryId: lab.laboratoryId,
                      laboratoryName: lab.laboratoryName,
                      isOwnLab: false,
                    });
                  }
                });
                // Store all labs in local storage
                localStorage.setItem(
                  'allLabs',
                  JSON.stringify(userResponse.laboratories)
                );
                this.adminAllLabsList.next(userResponse.laboratories);
              }),
              map(() => {
                if (userResponse && token) {
                  // Store user in local storage
                  const storage = rememberMe ? localStorage : sessionStorage;
                  storage.setItem('currentUser', JSON.stringify(userResponse));
                  this.currentUserSubject.next(userResponse);
                  this.loggedIn.next(true);
                }
                return true;
              }) // Emit when labs are fetched successfully
            );
          } else {
            if (userResponse && token) {
              // Store user in local storage
              const storage = rememberMe ? localStorage : sessionStorage;
              storage.setItem('currentUser', JSON.stringify(userResponse));
              this.currentUserSubject.next(userResponse);
              this.loggedIn.next(true);
            }
            return of(true);
          }
        }),
        catchError((error) => {
          console.error(error);
          return of(false);
        })
      );
  }

  private determineHighestRole(roles: Role[]): Role | null {
    if (roles.includes(Role.OGI_Super_Administrator)) {
      return Role.OGI_Super_Administrator;
    } else if (roles.includes(Role.OGI_Laboratory_Administrator)) {
      return Role.OGI_Laboratory_Administrator;
    } else if (roles.includes(Role.Super_User)) {
      return Role.Super_User;
    } else if (roles.includes(Role.Lab_Administrator)) {
      return Role.Lab_Administrator;
    } else if (roles.includes(Role.Edit_Access_User)) {
      return Role.Edit_Access_User;
    } else if (roles.includes(Role.Run_Access_User)) {
      return Role.Run_Access_User;
    } else if (roles.includes(Role.Read_Access_User)) {
      return Role.Read_Access_User;
    } else {
      return null;
    }
  }

  private fetchAllLabs(
    token: string,
    orgId: number
  ): Observable<LabResponse[]> {
    const labHttpOptions = {
      headers: this.httpOptions.headers,
      observe: 'response' as const,
    };
    labHttpOptions.headers = labHttpOptions.headers.set('Authorization', token);
    return this.http
      .get<LabResponse[]>(
        `${environment.apiUrl}/laboratories/organisation/${orgId}`,
        labHttpOptions
      )
      .pipe(
        map((response) => {
          return response.body;
        })
      );
  }

  logout(): void {
    this.http.post<unknown>(`${environment.apiUrl}/logout`, this.httpOptions);
    localStorage.removeItem('currentUser');
    sessionStorage.removeItem('currentUser');
    localStorage.removeItem('labList');
    sessionStorage.removeItem('labList');
    localStorage.removeItem('allLabs');
    sessionStorage.removeItem('allLabs');
    this.currentUserSubject.next(null);
    this.loggedIn.next(false);
    this.adminAllLabsList.next(null);
    this.selectedLabSubject.next(null);
  }

  forgotPassword(email: string): Observable<boolean> {
    const forgotPasswordObject = JSON.stringify({
      username: email,
    });
    return this.http
      .post<void>(
        `${environment.apiUrl}/account/forgot-password`,
        forgotPasswordObject,
        this.httpOptions
      )
      .pipe(
        map(() => {
          return true;
        })
      );
  }

  validateResetToken(
    resetToken: string,
    resetEmail: string
  ): Observable<boolean> {
    const validateTokenObject = JSON.stringify({
      token: resetToken,
      email: resetEmail,
    });
    return this.http
      .post<boolean>(
        `${environment.apiUrl}/account/validate-reset-token`,
        validateTokenObject,
        this.httpOptions
      )
      .pipe(
        map((response) => {
          return response.body;
        })
      );
  }

  resetPassword(
    token: string,
    password: string,
    email: string
  ): Observable<boolean> {
    const resetObject = { password, token, email };
    return this.http
      .post<boolean>(
        `${environment.apiUrl}/account/reset-password`,
        resetObject,
        this.httpOptions
      )
      .pipe(
        map((response) => {
          return response.body;
        })
      );
  }

  validateUserToken(token: string): Observable<User> {
    return this.http
      .get<ValidateUserTokenApiResponse>(
        `${environment.apiUrl}/users/invite/${token}`,
        this.httpOptions
      )
      .pipe(
        map((response) => {
          const { invitation } = response.body;
          const userResponse = new User(
            invitation.email,
            invitation.firstName,
            invitation.lastName,
            null,
            invitation.id
          );
          return userResponse;
        })
      );
  }

  createAccount(user: User, password: string): Observable<boolean> {
    const url = `${environment.apiUrl}/users?invitationToken=${user.token}`;
    const createUserObject = JSON.stringify({
      password: password,
    });
    return this.http.post<void>(url, createUserObject, this.httpOptions).pipe(
      switchMap((response) => {
        // Grab jwt from auth headers
        const token = response.headers.get('Authorization');

        // Now decode token
        const decodedPayload = JSON.parse(
          window.atob(token.split('.')[1])
        ) as JWTResponse;
        const userResponse = new User(
          decodedPayload.unique_name,
          decodedPayload.FirstName,
          decodedPayload.LastName
        );

        userResponse.id = decodedPayload.UserId;
        userResponse.token = token;
        userResponse.role = this.determineHighestRole(decodedPayload.Roles);
        userResponse.laboratories = JSON.parse(
          decodedPayload.Laboratories
        ) as Laboratory[];
        userResponse.organisationId = decodedPayload.UserOrgId;
        // If lab list is empty, and user is not an OGI_Super_Administrator or Super_User, then refuse login
        if (
          userResponse.laboratories.length === 0 &&
          user.role !== Role.OGI_Super_Administrator &&
          user.role !== Role.Super_User
        ) {
          return of(false);
        }
        // Inititally set isOwnLab to true for all labs
        userResponse.laboratories.forEach((lab) => {
          lab.isOwnLab = true;
        });

        // Also set the selected lab to the first lab in the list
        this.selectedLabSubject.next(userResponse.laboratories[0]);

        // Now if the user is an OGI_Super_Administrator, we need to get all labs
        // and store them in local storage, so we'll return the userResponse
        // and highest role
        if (
          userResponse.role === Role.OGI_Super_Administrator ||
          user.role === Role.Super_User
        ) {
          return this.fetchAllLabs(token, userResponse.organisationId).pipe(
            tap((labs) => {
              labs.forEach((lab) => {
                if (
                  !userResponse.laboratories.find(
                    (l) => l.laboratoryId === lab.laboratoryId
                  )
                ) {
                  userResponse.laboratories.push({
                    laboratoryId: lab.laboratoryId,
                    laboratoryName: lab.laboratoryName,
                    isOwnLab: false,
                  });
                }
              });
              // Store all labs in local storage
              localStorage.setItem(
                'allLabs',
                JSON.stringify(userResponse.laboratories)
              );
              this.adminAllLabsList.next(userResponse.laboratories);
            }),
            map(() => {
              if (userResponse && token) {
                // Store user in local storage
                localStorage.setItem(
                  'currentUser',
                  JSON.stringify(userResponse)
                );
                this.currentUserSubject.next(userResponse);
                this.loggedIn.next(true);
              }
              return true;
            }) // Emit when labs are fetched successfully
          );
        } else {
          if (userResponse && token) {
            // Store user in local storage
            localStorage.setItem('currentUser', JSON.stringify(userResponse));
            this.currentUserSubject.next(userResponse);
            this.loggedIn.next(true);
          }
          return of(true);
        }
      }),
      catchError((error) => {
        console.error(error);
        return of(false);
      })
    );
  }
}
