import {
  Component,
  ElementRef,
  Input,
  Output,
  OnDestroy,
  OnInit,
  AfterViewInit,
  EventEmitter,
  ViewChild,
} from '@angular/core';
import { ModalService } from 'src/app/_services/modal.service';
import { IconNames } from '../icons/icon-names';
import lottie from 'lottie-web';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnInit, AfterViewInit, OnDestroy {
  private element: HTMLElement;
  logo = IconNames.LogoColourSmall as string;

  /**
   * @param {string} id What is the id of the modal container?
   * @required
   */
  @Input()
  id: string;

  /**
   * @param {string} titleText What is the modal title?
   * @required
   */
  @Input()
  titleText: string;

  /**
   * @param {string} primaryText What is the primary modal text?
   */
  @Input()
  primaryText: string;

  /**
   * @param {string} animationType Does the modal have an animation?
   * @required
   */
  @Input()
  animationType: 'spinner' | 'test-tubes' | 'lock' | '' = '';

  /**
   * @param {string} modalStyle What style of modal is it?
   * @required
   */
  @Input()
  modalStyle: 'default' | 'success' | 'error' | 'warning' = 'default';

  /**
   * @param {string} buttonType Does the modal have a single or double button?
   * @required
   */
  @Input()
  buttonType: 'single-button' | 'double-button' = 'single-button';

  /**
   * @param {string} primaryButtonText What is the text for the primary button?
   * @required
   */
  @Input()
  primaryButtonText: 'Primary Button';

  /**
   * @param {string} primaryButtonId What is the id for the primary button?
   * @required
   */
  @Input()
  primaryButtonId: string;

  /**
   * @param {string} secondaryButtonText What is the text for the secondary button?
   */
  @Input()
  secondaryButtonText: string;

  /**
   * @param {string} secondaryButtonId What is the id for the secondary button?
   */
  @Input()
  secondaryButtonId: string;

  /**
   * @param {boolean} isProcessing Is the modal processing
   */
  @Input()
  isProcessing = false;

  /**
   * @param {boolean} hasCompleted Has the modal finished processing
   */
  @Input()
  hasCompleted = false;

  /**
   * @param {boolean} displayLogo Display logo
   */
  @Input()
  displayLogo = false;

  /**
   * @param {boolean} allowClose Whether user can close modal
   */
  @Input()
  allowClose = true;

  /**
   * Output event for clicked button on modal
   */
  @Output()
  modalButtonClicked: EventEmitter<string> = new EventEmitter<string>();

  /**
   * @param {ElementRef} animationContainer Reference to html element containing the animation.
   * @required
   */
  @ViewChild('lottieContainer') animationContainer: ElementRef<HTMLElement>;

  constructor(
    private el: ElementRef<HTMLElement>,
    private modalService: ModalService
  ) {
    this.element = el.nativeElement;
  }

  ngOnInit(): void {
    if (this.allowClose) {
      // Close modal on background click
      this.element.addEventListener('click', (e: Event) => {
        const target = e.target as HTMLElement;
        if (target.className === 'modal--container') {
          this.close();
        }
      });
    }

    // add self (this modal instance) to the modal service so it's accessible from controllers
    this.modalService.add(this);
  }

  ngAfterViewInit(): void {
    if (this.animationType === 'test-tubes' || this.animationType === 'lock') {
      this.initAnimation();
    }
  }

  buttonEventHandler(valueEmitted: string): void {
    this.modalButtonClicked.emit(valueEmitted);
  }

  initAnimation(): void {
    const animation = lottie.loadAnimation({
      container: this.animationContainer.nativeElement, // the dom element that will contain the animation
      renderer: 'svg',
      loop: true,
      autoplay: true,
      path: `../../assets/lottie/${this.animationType}.json`, // the path to the animation json
    });

    animation.play();
  }

  // open modal
  open(): void {
    this.element.style.display = 'block';
  }

  // close modal
  close(): void {
    // Reset valdiation text value and close modal
    this.element.style.display = 'none';
  }

  // Remove self from modal service when component is destroyed
  ngOnDestroy(): void {
    this.modalService.remove(this.id);
    this.element.remove();
  }

  /**
   * @returns {string[]} Returns a list of classes to be applied to the circle in the loading spinner animation
   */
  public get circleClasses(): string[] {
    const processing = this.isProcessing ? 'path circ' : '';
    const completed = this.hasCompleted ? 'path' : '';
    return [processing, completed];
  }

  /**
   * @returns {string[]} Returns a list of classes to be applied to the loading spinner for the completion state/tick display
   */
  public get tickClasses(): string[] {
    const processing = this.isProcessing ? 'path circ' : '';
    const completed = this.hasCompleted ? 'path tick' : '';
    return [processing, completed];
  }

  /**
   * @returns {string[]} Returns a list of classes to be applied dependant on which animation is being displayed
   */
  public get classes(): string[] {
    switch (this.animationType) {
      case 'spinner':
      case '':
        return [''];
      case 'test-tubes':
      case 'lock':
        return ['animation--container'];
      default:
        return [];
    }
  }

  /**
   * @returns {string[]} Returns a list of classes to be applied dependant on which animation is being displayed
   */
  public get buttonContainerClasses(): string[] {
    const style = this.buttonType === 'double-button' ? 'double' : '';
    return [`button--container ${style}`];
  }
}
