<button
  [class]="classes"
  (click)="clickButton()"
  [attr.id]="buttonId"
  [attr.type]="buttonType"
  [disabled]="isDisabled"
>
  <div class="icon--container">
    <mat-icon [class]="iconClasses">{{ iconName }}</mat-icon>
  </div>
  @if (label) {
    <span>{{ label }}</span>
  }
</button>
