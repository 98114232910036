/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import * as d3 from 'd3';
import { fromEvent, debounceTime, BehaviorSubject } from 'rxjs';
import { AxisDomain, AxisScale, ScaleLinear, select, style, zoom } from 'd3';
import {
  ICalibrationDevice,
  ICalibrationFlask,
  IOpticalDensityVoltage,
} from 'src/app/_models/experiment-data';
import { CalibrationResults } from 'src/app/_models/calibration';
import { MatSlideToggle } from '@angular/material/slide-toggle';

@Component({
  selector: 'app-calibration-graph',
  templateUrl: './calibration-graph.component.html',
  styleUrls: ['./calibration-graph.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatSlideToggle],
})
export class CalibrationGraphComponent implements OnChanges, AfterViewInit {
  private readonly flaskColours: Record<string, string> = {
    A: '#6ECF9C', // Lighter green
    B: '#6C73C1', // Blue - distinct in both normal and colorblind vision
    C: '#BE4FC1', // Purple - different brightness and hue from others
    D: '#CB9F50', // Gold/ochre - very different brightness level
  };

  // Graph state
  private svg: d3.Selection<SVGGElement, unknown, HTMLElement, any>;
  private axisSvg: d3.Selection<SVGSVGElement, unknown, HTMLElement, any>;
  private x: ScaleLinear<number, number>;
  private y: ScaleLinear<number, number>;
  private readonly initialRadius = 4;
  checked = false;

  private readonly margin = {
    left: 90,
    right: 5,
    top: 10,
    bottom: 50,
  };

  // Drawing state
  private isDrawing = false;
  private prevWidth: number | null = null;

  // Set to large by default
  private width: number;
  private height = 450;

  // Data state
  private readonly dataToRender: IRenderData[] = [];
  private readonly labelList: IPropertyColour[] = [];

  /**
   * @param {string} graphId The ID of the graph to be displayed
   * @description This is used to set the ID of the graph container
   * so that it can be referenced by the zoom function
   * @example 'scatter'
   */
  @Input()
  graphId = 'scatter';

  /**
   * @param {string} graphTitle The title of the graph to be displayed
   */
  @Input()
  graphTitle = 'Title Here';

  /**
   * @param {string} yAxisLabel The y-axis label to be used on the graph
   */
  @Input()
  yAxisLabel = 'Y Axis Label';

  /**
   * @param {string} dataType The type of data to be graphed
   */
  @Input()
  dataType = 'OD';

  /**
   * @param {ICalibrationDevice} calibrationData The calibration data from the API to be graphed
   */
  @Input()
  calibrationData: ICalibrationDevice;

  @Input()
  results: Partial<CalibrationResults>;

  ngAfterViewInit(): void {
    this.initialiseGraph();
  }

  ngOnChanges(changes: SimpleChanges): void {
    // console.log('changes', changes);
    // Check if calibrationData exists in changes
    if ('calibrationData' in changes) {
      const change = changes.calibrationData;
      // console.log('Detected change in calibrationData:', change);

      // Skip if this is the first change
      if (change.firstChange) {
        // console.log('First change detected, skipping update');
        return;
      }

      // Skip if new value is undefined
      if (!change.currentValue) {
        // console.log('New value is undefined, skipping update');
        return;
      }

      // Since we know there's a change and it has a value, update the graph
      // console.log('Updating graph with new calibration data');
      this.parseData(this.calibrationData, true);
    }
  }

  toggleChange(event: MatSlideToggle): void {
    this.checked = event.checked;
    if (this.checked) {
      this.svg.call(d3.zoom().on('zoom', null));
    } else {
      this.svg.call(
        d3
          .zoom()
          .extent([
            [0, 0],
            [this.width, this.height],
          ])
          .scaleExtent([1, 10])
          .on('zoom', this.updateChart),
      );
    }
  }

  private readonly initialiseGraph = (): void => {
    this.createSvg();

    if (this.calibrationData) {
      this.parseData(this.calibrationData);
    }

    if (this.results) {
      this.drawPowerLineCurves(this.results);
    }

    // Set up the resize observer
    const resizeObservable$ = fromEvent(window, 'resize').pipe(
      debounceTime(300), // Adjust delay as needed
    );

    resizeObservable$.subscribe(() => {
      this.updateSvgDimensions();
    });
  };

  private readonly createSvg = (): void => {
    // Set the styling on the container to relative for the absolutely positioned tooltips
    d3.select('figure').style('position', 'relative');
    // Get the width of the parent container
    const containerWidth = (
      d3.select(`figure#${this.graphId}`).node() as HTMLElement
    ).offsetWidth;
    this.width = containerWidth - (this.margin.left + this.margin.right);
    // Create main svg element for the axes
    this.axisSvg = d3
      .select(`figure#${this.graphId}`)
      .append('svg')
      // Set the dimensions and margins of the graph
      .attr('width', this.width + (this.margin.left + this.margin.right))
      .attr('height', this.height + (this.margin.top + this.margin.bottom));
    // Create a group for the graph content
    this.svg = this.axisSvg
      .append('g')
      .attr(
        'transform',
        'translate(' +
          this.margin.left.toString() +
          ',' +
          this.margin.top.toString() +
          ')',
      );

    // Add a clip path: everything out of this area won't be drawn.
    this.svg
      .append('defs')
      .append('svg:clipPath')
      .attr('id', 'clip')
      .append('svg:rect')
      .attr('width', this.width)
      .attr('height', this.height)
      .attr('x', 0)
      .attr('y', 0);
    // Rectangle to capture pointer events for zoom
    this.svg
      .append('rect')
      .attr('width', this.width)
      .attr('height', this.height)
      .style('fill', 'none')
      .style('pointer-events', 'all');
    // Now let's add zoom to the svg element
    this.svg.call(
      d3
        .zoom()
        .extent([
          [0, 0],
          [this.width, this.height],
        ])
        .scaleExtent([1, 10])
        .on('zoom', this.updateChart),
    );

    // Append the axes to the main SVG (this.axisSvg)
    this.axisSvg
      .append('g')
      .attr('class', 'x-axis')
      .attr(
        'transform',
        `translate(${this.margin.left}, ${this.height + this.margin.top})`,
      );

    this.axisSvg
      .append('g')
      .attr('class', 'y-axis')
      .attr('transform', `translate(${this.margin.left}, ${this.margin.top})`);
  };

  private readonly parseData = (
    calibrationData: ICalibrationDevice,
    isUpdate = false,
  ): void => {
    const property = this.dataType;

    calibrationData.flasks.forEach((flask: ICalibrationFlask) => {
      const flaskLetter = flask.name.charAt(0);
      const deviceFlaskProperty = `${flask.name}_${property}`;

      flask.values?.forEach((calibrationValue: IOpticalDensityVoltage) => {
        this.dataToRender.push({
          DeviceFlaskProperty: deviceFlaskProperty,
          OpticalDensity: calibrationValue.opticalDensity,
          Voltage: calibrationValue.voltage,
          FlaskName: flask.name,
        });
      });

      this.labelList.push({
        deviceFlaskProperty,
        colour: this.flaskColours[flaskLetter],
      });
    });

    if (isUpdate) {
      this.updateGraphWithNewData(this.dataToRender);
    } else {
      this.drawScatterGraph();
    }
  };

  private updateSvgDimensions(): void {
    const containerWidth = (
      d3.select(`figure#${this.graphId}`).node() as HTMLElement
    ).offsetWidth;

    const containerHeight = (
      d3.select(`figure#${this.graphId}`).node() as HTMLElement
    ).offsetHeight;

    if (
      (this.prevWidth !== containerWidth || this.height !== containerHeight) &&
      !this.isDrawing
    ) {
      this.isDrawing = true;

      // Recalculate the width and height based on container size
      this.width = containerWidth - (this.margin.left + this.margin.right);
      this.height = containerHeight - (this.margin.top + this.margin.bottom);

      // Update the SVG dimensions
      this.axisSvg
        .attr('width', this.width + (this.margin.left + this.margin.right))
        .attr('height', this.height + (this.margin.top + this.margin.bottom));

      // Preserve the zoom transform by applying it to the dots as well
      const zoomTransform = d3.zoomTransform(this.svg.node());

      // Recreate the scales based on new dimensions
      this.x.range([0, this.width]);
      this.y.range([this.height, 0]);

      // Update the clip path dimensions to match the new graph dimensions
      this.svg
        .select('clipPath rect')
        .attr('width', this.width)
        .attr('height', this.height); // Ensure the clip path is resized properly

      // Update the axes with the current zoom transformation
      this.axisSvg
        .select<SVGGElement>('.x-axis')
        .attr(
          'transform',
          `translate(${this.margin.left}, ${this.height + this.margin.top})`,
        )
        .call(d3.axisBottom(zoomTransform.rescaleX(this.x)));

      // Reposition the x-axis label to center it based on new width
      this.axisSvg.select('.x-axis-label').attr('x', this.width / 2);

      // Update the y-axis
      this.axisSvg
        .select<SVGGElement>('.y-axis')
        .attr('transform', `translate(${this.margin.left}, ${this.margin.top})`)
        .call(d3.axisLeft(zoomTransform.rescaleY(this.y)));

      // Also reposition the y-axis label
      const vertOffset =
        this.height / 2 - (this.margin.bottom + this.margin.top);
      const horzOffset = this.margin.left - 20;
      this.axisSvg
        .select('.y-axis-label')
        .attr(
          'transform',
          `translate(-${horzOffset}, ${vertOffset}) rotate(-90)`,
        );

      // Reapply zoom transform to the dots as well
      this.svg
        .selectAll('circle')
        .attr('cx', (d: IRenderData) =>
          zoomTransform.rescaleX(this.x)(d.OpticalDensity),
        )
        .attr('cy', (d: IRenderData) =>
          zoomTransform.rescaleY(this.y)(d.Voltage),
        );

      // Update power line curves
      this.drawPowerLineCurves(this.results);

      // Save the new width for future resizing
      this.prevWidth = containerWidth;

      this.isDrawing = false;
    }
  }

  private readonly updateChart = (
    event: d3.D3ZoomEvent<SVGSVGElement, any>,
  ): void => {
    // Rescale the x and y axes based on the zoom transformation
    const newX = event.transform.rescaleX(this.x);
    const newY = event.transform.rescaleY(this.y);

    // Get the zoom scale factor (k)
    const zoomScale = event.transform.k;

    // Update the axes with the rescaled axes
    this.axisSvg.select<SVGGElement>('.x-axis').call(d3.axisBottom(newX));
    this.axisSvg.select<SVGGElement>('.y-axis').call(d3.axisLeft(newY));

    // Apply the zoom transformation to the circles, including scaling the radius
    this.svg
      .selectAll('circle')
      .attr('cx', (d: IRenderData) => newX(d.OpticalDensity))
      .attr('cy', (d: IRenderData) => newY(d.Voltage))
      .attr('r', () =>
        Math.max(this.initialRadius, this.initialRadius * zoomScale),
      ); // Ensure radius doesn't drop below the initial size

    // Update power line curves
    this.drawPowerLineCurves(this.results);
  };

  private readonly drawScatterGraph = (): void => {
    this.isDrawing = true; // Set the flag to true to prevent resizing
    // Clear existing content of the graph SVG
    this.svg.select('#graph-content').selectAll('*').remove();

    // Create a group for all graph content including regression lines
    const graphContent = this.svg
      .append('g')
      .attr('clip-path', 'url(#clip)')
      .attr('id', 'graph-content');

    // First create x-axis
    this.x = d3
      .scaleLinear()
      .domain(
        d3.extent(this.dataToRender, (d) => d.OpticalDensity) as [
          number,
          number,
        ],
      )
      .range([0, this.width])
      .nice();

    const minY = d3.min(this.dataToRender, (d) => d.Voltage);
    const maxY = d3.max(this.dataToRender, (d) => d.Voltage);

    // Apply padding only as an absolute adjustment, depending on the sign
    const paddingFactor = 0.1; // 10% padding

    const paddedMinY = minY - Math.abs(minY * paddingFactor);
    const paddedMaxY = maxY + Math.abs(maxY * paddingFactor);

    // Limit decimal places
    const roundedMinY = parseFloat(paddedMinY.toFixed(2));
    const roundedMaxY = parseFloat(paddedMaxY.toFixed(2));

    // Now create the y axis
    this.y = d3
      .scaleLinear()
      .domain([roundedMinY, roundedMaxY])
      .range([this.height, 0])
      .nice();

    // Create the axes (but DON'T append them here)
    const xAxis = d3.axisBottom(this.x);
    const yAxis = d3.axisLeft(this.y);

    const applyAxis = (
      selection: d3.Selection<SVGGElement, unknown, HTMLElement, any>,
      axis: any,
    ) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      selection.call(axis);
    };

    // Add x-axis label
    this.axisSvg.select('.x-axis').selectAll('text').remove(); // Remove existing labels

    // Attach the x-axis to the svg group
    applyAxis(this.axisSvg.select('.x-axis'), xAxis);

    // Add x-axis label
    this.axisSvg
      .select('.x-axis')
      .append('text')
      .attr('class', 'x-axis-label')
      .attr('x', this.width / 2)
      .attr('y', this.margin.bottom - 10)
      .attr('fill', '#000000')
      .attr('text-anchor', 'middle')
      .text('Optical Density')
      .style('font-family', '$proxima-nova')
      .style('font-weight', '300')
      .style('font-size', '2rem');

    // Attach the y-axis to the svg group
    const horzOffset = this.margin.left - 20;
    const vertOffset = this.height / 2 - (this.margin.bottom + this.margin.top);
    applyAxis(this.axisSvg.select('.y-axis'), yAxis);

    // Add y-axis label
    this.axisSvg
      .select('.y-axis')
      .append('text')
      .attr('class', 'y-axis-label')
      .attr('x', -this.margin.left + 10)
      .attr('y', 10)
      .attr('fill', '#000000')
      .attr('text-anchor', 'middle')
      .attr('transform', `translate(-${horzOffset}, ${vertOffset}) rotate(-90)`)
      .style('font-family', '$proxima-nova')
      .style('font-weight', '300')
      .style('font-size', '2rem')
      .text(this.yAxisLabel);

    // Colour set up - can't pass in objects so need flat lists
    const domainScale = [];
    const rangeColour = [];

    this.labelList.forEach((element) => {
      domainScale.push(element.deviceFlaskProperty);
      rangeColour.push(element.colour);
    });
    const colour = d3.scaleOrdinal().domain(domainScale).range(rangeColour);

    // Now let's build the tooltip, hidden by default (with opacity 0)
    const tooltip = d3
      .select(`figure#${this.graphId}`)
      .append('div')
      .style('opacity', 0)
      .attr('class', 'body3')
      .attr('class', 'tooltip')
      .style('background-color', '#B4DFFF')
      .style('border', 'solid')
      .style('border-width', '1px')
      .style('border-radius', '5px')
      .style('border-color', '#0C7BB4')
      .style('padding', '10px')
      .style('font-family', '$proxima-nova')
      .style('font-weight', '300')
      .style('font-size', '1rem')
      .style('position', 'absolute');

    // And when the user hovers over a point, let's make it visible
    const mouseover = function () {
      tooltip.style('opacity', 1);
      d3.select(this).style('stroke', 'black').style('opacity', '1');
    };

    // Insert text into the tooltip and position it relative to the point hovered over
    const mousemove = function (e: MouseEvent, d: IRenderData) {
      const tooltipX = e.offsetX + 20;
      tooltip
        .html(
          `Optical Density: ${d.OpticalDensity} <br> Voltage: ${d.Voltage} <br> Flask: ${d.FlaskName}`,
        )
        .style('left', tooltipX.toString() + 'px') //offset the tooltip to prevent clash with point
        .style('top', e.offsetY.toString() + 'px');
    };

    // Now hide the tooltip when the mouse leaves hoverover
    const mouseleave = function () {
      tooltip.style('opacity', 0);
      d3.select(this).style('stroke', 'none').style('opacity', 0.8);
    };

    graphContent
      .selectAll('circle')
      .data(this.dataToRender)
      .enter()
      .append('circle')
      .attr('cx', (d) => this.x(d.OpticalDensity))
      .attr('cy', (d) => this.y(d.Voltage))
      .attr('r', this.initialRadius) // update to either use const or allow input of radius size value desired
      .style('fill', (d): string => {
        // Explicitly return a string
        return colour(d.DeviceFlaskProperty) as string;
      })
      .on('mouseover', mouseover)
      .on('mousemove', mousemove)
      .on('mouseleave', mouseleave);

    const legendGroup = this.svg
      .append('g')
      .attr('class', 'legend')
      .attr('transform', `translate(20, 20)`); // Keep same top-left position

    Object.entries(this.flaskColours).forEach(([flaskLetter, color], index) => {
      const legendRow = legendGroup
        .append('g')
        .attr('transform', `translate(0, ${index * 20})`); // Stack vertically with 20px spacing

      // Colored square
      legendRow
        .append('rect')
        .attr('width', 12)
        .attr('height', 12)
        .attr('fill', color);

      // Flask label
      legendRow
        .append('text')
        .attr('x', 16)
        .attr('y', 10)
        .style('font-family', '$proxima-nova')
        .style('font-size', '12px')
        .text(`Flask ${flaskLetter}`);
    });

    // Check if we have calibration results
    this.drawPowerLineCurves(this.results);

    this.isDrawing = false; // Set the flag to false to allow resizing
  };

  private showTooltip(e: MouseEvent, d: IRenderData): void {
    const tooltipX = e.offsetX + 20;
    const tooltip = d3.select(`figure#${this.graphId} .tooltip`);
    const tooltipText = `Optical Density: ${d.OpticalDensity} <br> Voltage: ${d.Voltage} <br> Flask: ${d.FlaskName}`;
    tooltip
      .html(tooltipText)
      .style('left', tooltipX.toString() + 'px')
      .style('top', e.offsetY.toString() + 'px')
      .style('opacity', 1);
  }

  private hideTooltip(): void {
    d3.select(`figure#${this.graphId} .tooltip`).style('opacity', 0);
  }

  private readonly updateGraphWithNewData = (newData: IRenderData[]): void => {
    this.isDrawing = true;

    // First, update the scales with the new data
    this.x = d3
      .scaleLinear()
      .domain(d3.extent(newData, (d) => d.OpticalDensity) as [number, number])
      .range([0, this.width]);

    const minY = d3.min(newData, (d) => d.Voltage);
    const maxY = d3.max(newData, (d) => d.Voltage);
    const paddingFactor = 0.1;
    const paddedMinY = minY - Math.abs(minY * paddingFactor);
    const paddedMaxY = maxY + Math.abs(maxY * paddingFactor);
    const roundedMinY = parseFloat(paddedMinY.toFixed(2));
    const roundedMaxY = parseFloat(paddedMaxY.toFixed(2));

    this.y = d3
      .scaleLinear()
      .domain([roundedMinY, roundedMaxY])
      .range([this.height, 0]);

    // Get current zoom transform
    const zoomTransform = d3.zoomTransform(this.svg.node());

    // Update axes with the new scales, considering zoom
    const xAxis = d3.axisBottom(zoomTransform.rescaleX(this.x));
    const yAxis = d3.axisLeft(zoomTransform.rescaleY(this.y));

    this.axisSvg.select('.x-axis').call(xAxis as any);
    this.axisSvg.select('.y-axis').call(yAxis as any);

    // Clear existing content
    this.svg.select('#graph-content').selectAll('*').remove();

    // Select the graph content
    const graphContent = this.svg.select('#graph-content');

    // Initial radius and calculate current radius based on zoom
    const currentRadius = Math.max(
      this.initialRadius,
      this.initialRadius * zoomTransform.k,
    );

    // Add new dots with the updated scales
    graphContent
      .selectAll('circle')
      .data(newData)
      .enter()
      .append('circle')
      .attr('cx', (d) => zoomTransform.rescaleX(this.x)(d.OpticalDensity))
      .attr('cy', (d) => zoomTransform.rescaleY(this.y)(d.Voltage))
      .attr('r', currentRadius)
      .style('fill', (d): string => {
        const flaskLetter = d.FlaskName.charAt(0);
        return this.flaskColours[flaskLetter];
      })
      .on('mouseover', (e: MouseEvent, d: IRenderData) => {
        this.showTooltip(e, d);

        d3.select(e.target as SVGElement)
          .style('stroke', 'black')
          .style('opacity', '1');
      })
      .on('mouseleave', (e: MouseEvent) => {
        this.hideTooltip();
        d3.select(e.target as SVGElement)
          .style('stroke', 'none')
          .style('opacity', '0.8');
      });

    // Update power line curves
    this.drawPowerLineCurves(this.results);

    // Create a zoom handler that will update both dots and lines

    const zoomed = (event: d3.D3ZoomEvent<SVGSVGElement, any>) => {
      // Update axes
      const newX = event.transform.rescaleX(this.x);
      const newY = event.transform.rescaleY(this.y);
      this.axisSvg.select('.x-axis').call(d3.axisBottom(newX as any) as any);
      this.axisSvg.select('.y-axis').call(d3.axisLeft(newY as any) as any);

      // Update dots
      graphContent
        .selectAll('circle')
        .attr('cx', (d: IRenderData) => newX(d.OpticalDensity))
        .attr('cy', (d: IRenderData) => newY(d.Voltage))
        .attr('r', () =>
          Math.max(this.initialRadius, this.initialRadius * event.transform.k),
        );

      // Update power line curves
      graphContent.selectAll('path').attr('d', (d: any) => {
        const line = d3
          .line<{ x: number; y: number }>()
          .x((p) => newX(p.x))
          .y((p) => newY(p.y))
          .curve(d3.curveMonotoneX);
        return line(d);
      });
    };

    // Set up zoom behavior
    this.svg.call(
      d3
        .zoom()
        .extent([
          [0, 0],
          [this.width, this.height],
        ])
        .scaleExtent([1, 10])
        .on('zoom', zoomed),
    );

    this.isDrawing = false;
  };

  private drawPowerLineCurves(results: Partial<CalibrationResults>): void {
    if (!results || !this.labelList) {
      return;
    }
    // Use labelList to determine active flasks, and check if results are present
    // If results are present, draw the power line curve
    // If results are not present, continue with the existing graph
    // console.log('labelList', this.labelList);
    this.labelList.forEach((label) => {
      const flask = label.deviceFlaskProperty.split('_')[0];
      const factor = results[`calFactor_${flask}` as keyof CalibrationResults];
      const offset = results[`calOffset_${flask}` as keyof CalibrationResults];
      const power = results[`calPower_${flask}` as keyof CalibrationResults];
      // If we have all required results for this flask, draw the power line curve
      if (factor && offset && power) {
        this.drawPowerLineCurve(flask, {
          factor: Number(factor),
          offset: Number(offset),
          power: Number(power),
        });
      }
    });
  }

  private drawPowerLineCurve(
    flaskName: string,
    calibration: { factor: number; offset: number; power: number },
  ): void {
    // Get OD range from dataToRender
    const flaskData = this.dataToRender.filter((d) =>
      d.FlaskName.startsWith(flaskName),
    );

    const odExtent = d3.extent(flaskData, (d) => d.OpticalDensity);

    if (odExtent[0] === undefined || odExtent[1] === undefined) {
      return;
    }

    const points = [];
    const numPoints = 100;

    // Get current zoom transform
    const zoomTransform = d3.zoomTransform(this.svg.node());
    const xScale = zoomTransform.rescaleX(this.x);
    const yScale = zoomTransform.rescaleY(this.y);

    // Generate points using OD as input
    for (let i = 0; i < numPoints; i++) {
      const opticalDensity = d3.interpolate(
        odExtent[0],
        odExtent[1],
      )(i / (numPoints - 1));

      const voltage =
        calibration.offset +
        calibration.factor * Math.pow(opticalDensity, calibration.power);

      points.push({ x: opticalDensity, y: voltage });
    }

    const flaskLetter = flaskName.charAt(0);
    const flaskColour = this.flaskColours[flaskLetter];

    // Create line generator
    const line = d3
      .line<{ x: number; y: number }>()
      .x((d) => xScale(d.x))
      .y((d) => yScale(d.y));

    // remove existing line
    this.svg.select(`#graph-content .power-law-${flaskName}`).remove();

    // Draw new curve with more explicit error checking
    this.svg
      .select('#graph-content')
      .append('path')
      .datum(points)
      .attr('class', `power-law-${flaskName}`)
      .attr('fill', 'none')
      .attr('stroke', flaskColour)
      .attr('stroke-width', 2)
      .attr('d', line);
  }
}

export interface IRenderData {
  DeviceFlaskProperty: string;
  OpticalDensity: number;
  Voltage: number;
  FlaskName: string;
}

export interface IPropertyColour {
  deviceFlaskProperty: string;
  colour: string;
}
