import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DeviceCalibrationSummary } from 'src/app/_models/device-calibration-summary';
import { Module } from 'src/app/_models/module-enum';

@Component({
  selector: 'app-dynamic-dropdown-form-field',
  templateUrl: './dynamic-dropdown-form-field.component.html',
  styleUrls: ['./dynamic-dropdown-form-field.component.scss'],
})
export class DynamicDropdownFormFieldComponent {
  /**
   * @param {FormGroup} parentForm The parent form of the dropdown
   */
  @Input()
  parentForm: FormGroup;

  /**
   * @param {DeviceCalibrationSummary} Device The device to be referenced
   */
  @Input()
  device: DeviceCalibrationSummary;

  /**
   * @param {boolean} isDisabled Should the select be disabled
   */
  @Input()
  isDisabled = false;

  /**
   * @param {string} placeholder String placeholder to be displayed on select by default
   */
  @Input()
  placeholder = 'Placeholder';

  /**
   * @param {Module} calibrationType Enum to denote what calibration type the dropdown is for
   */
  @Input()
  calibrationType: Module = Module.BaseModule;

  /**
   * Gets a list of css classes to be applied to the select
   * @returns {string[]} css class list
   */
  public get classes(): string[] {
    const disabled = this.isDisabled ? 'disabled' : '';
    return ['dropdown-form-field', 'body1', disabled];
  }

  /**
   * Gets the current value of the form control that corresponds to the select
   * @returns {string} the form control value
   */
  public get value(): string {
    const controlName = `device_${this.device.deviceId.toString()}`;
    return this.parentForm.controls[controlName].value as string;
  }
}
