<section class="toggle--container">
  <label [for]="field.key" [formGroup]="parentForm">
    <div class="label--container body1">
      {{ field.label }}
      @if (field.required) {
      <span class="required" aria-required="true"> *</span>
      }
    </div>

    <div class="toggle">
      <!-- Bind the toggle group to the form control -->
      <mat-button-toggle-group
        [formControlName]="field.key"
        [disabled]="field.disabled"
        aria-label="Dynamic toggle"
        class="button-toggle-group"
        (change)="onToggleClick($event.value)"
      >
        <!-- Use @for to loop through the options array -->
        @for (option of field.options; track field.options) {
        <mat-button-toggle [value]="option.key">
          {{ option.value }}
        </mat-button-toggle>
        }
      </mat-button-toggle-group>
    </div>
  </label>
</section>
