<div *ngIf="laboratory; else noLab" id="dashboard--container">
  <div id="header--container">
    <h4>Welcome to your dashboard.</h4>
    <h5>{{ laboratory?.laboratoryName }}</h5>
  </div>
  <hr class="alt" />

  <div id="nav--container">
    <app-option-tab-toggle
      id="toggle--container"
      radioGroup="navigationToggle"
      [selectOptions]="navOptions"
      type="round"
    >
    </app-option-tab-toggle>
    <div class="button--container">
      <app-search-field
        [field]="searchField"
        [parentForm]="deviceTableControls"
        (buttonClicked)="getDevices()"
      >
      </app-search-field>
      <app-icon-button
        label="filters"
        buttonStyle="secondary"
        buttonId="filter-btn"
        iconName="filter_list"
        buttonShape="square"
        (buttonClicked)="openModal('filter')"
      ></app-icon-button>
      <button
        mat-mini-fab
        aria-label="new experiment"
        (click)="onNewExperiment()"
        [disabled]="
          currentUser.role
            | roleCheck : [Role.Read_Access_User, Role.Run_Access_User]
        "
      >
        <mat-icon>science</mat-icon>
      </button>
      <button
        mat-mini-fab
        aria-label="new calibration"
        (click)="onNewCalibration()"
        [disabled]="
          selectedDeviceList?.length > 1 || currentUser.role
            | roleCheck : [Role.Read_Access_User, Role.Run_Access_User]
        "
      >
        <mat-icon>timeline</mat-icon>
      </button>
    </div>
  </div>
  <div id="inner-page--container" *ngIf="devices?.length > 0">
    <p id="table-description" style="display: none">Device summary table</p>
    <div id="table--container">
      <table aria-describedby="table-description">
        <thead>
          <tr>
            <th
              id="device"
              scope="col"
              (click)="onSort('Name')"
              [ngClass]="getClasses('Name')"
            >
              <div id="table-header">
                <span>Device</span>
                <mat-icon
                  aria-label="sort direction"
                  matTooltip="Sort direction"
                  >swap_vert</mat-icon
                >
              </div>
            </th>
            <th
              id="status"
              scope="col"
              (click)="onSort('DeviceStatusId')"
              [ngClass]="getClasses('DeviceStatusId')"
            >
              <div id="table-header">
                <span>Status</span>
                <mat-icon
                  aria-label="sort direction"
                  matTooltip="Sort direction"
                  >swap_vert</mat-icon
                >
              </div>
            </th>
            <th
              id="time"
              scope="col"
              (click)="onSort('TimeLeft')"
              [ngClass]="getClasses('TimeLeft')"
            >
              <div id="table-header">
                <span>Time Left</span>
                <mat-icon
                  aria-label="sort direction"
                  matTooltip="Sort direction"
                  >swap_vert</mat-icon
                >
              </div>
            </th>
            <th
              id="experiment"
              scope="col"
              (click)="onSort('ExperimentName')"
              [ngClass]="getClasses('ExperimentName')"
            >
              <div id="table-header">
                <span>Experiment</span>
                <mat-icon
                  aria-label="sort direction"
                  matTooltip="Sort direction"
                  >swap_vert</mat-icon
                >
              </div>
            </th>
            <th id="view" scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let device of devices; let i = index">
            <td headers="device">
              <div id="device-name--container">
                {{ device.name }}
                <mat-icon
                  *ngIf="!device.isOffline"
                  class="online"
                  aria-label="online"
                  matTooltip="Online"
                  >wifi</mat-icon
                >
                <mat-icon
                  *ngIf="device.isOffline"
                  class="offline"
                  aria-label="offline"
                  matTooltip="Offline"
                  >wifi_off</mat-icon
                >
                <mat-icon
                  *ngIf="device?.softwareWarning"
                  class="warning"
                  aria-label="software update available"
                  matTooltip="Software update available"
                  >update</mat-icon
                >
              </div>
              <app-active-modules
                [activeModules]="device.deviceModules"
              ></app-active-modules>
            </td>
            <td headers="status">
              <app-status-indicator
                *ngIf="device"
                [status]="DeviceSetupStatus[device.deviceStatusId]"
              ></app-status-indicator>
            </td>
            <td
              headers="time"
              *ngIf="
                (device?.deviceStatusId === DeviceSetupStatus.Active ||
                  device?.deviceStatusId === DeviceSetupStatus.Paused) &&
                  currentTime$ | async as t;
                else nocount
              "
            >
              {{
                getTimeLeft(
                  device?.experiment?.startTime,
                  device?.experiment?.duration,
                  t
                ).days | number : "2.0"
              }}
              :
              {{
                getTimeLeft(
                  device.experiment?.startTime,
                  device.experiment?.duration,
                  t
                ).hours | number : "2.0"
              }}
              :
              {{
                getTimeLeft(
                  device.experiment?.startTime,
                  device.experiment?.duration,
                  t
                ).minutes | number : "2.0"
              }}
              :
              {{
                getTimeLeft(
                  device.experiment?.startTime,
                  device.experiment?.duration,
                  t
                ).seconds | number : "2.0"
              }}
            </td>
            <ng-template #nocount>
              <td headers="time">-</td>
            </ng-template>
            <td headers="experiment">
              {{ device.experiment?.name || "No experiment assigned" }}
            </td>
            <td headers="view" id="view-cell">
              <div>
                <app-button
                  label="View Device"
                  buttonId="device_{{ device?.id }}"
                  (buttonClicked)="onViewDeviceDetails(device.id)"
                >
                </app-button>
                <app-button
                  *ngIf="
                    device?.deviceStatusId === DeviceSetupStatus.Active ||
                      device?.deviceStatusId === DeviceSetupStatus.Paused ||
                      device?.deviceStatusId === DeviceSetupStatus.Complete;
                    else draft
                  "
                  label="View Experiment"
                  buttonId="experiment_{{ device?.experiment?.id }}"
                  [routerLink]="
                    '/experiment/' + device?.experiment?.id + '/display'
                  "
                ></app-button>
                <app-button
                  *ngIf="
                    device?.deviceStatusId === 9 &&
                    device.odCalibrationId !== null
                  "
                  label="View Active Calibration"
                  buttonId="calibration_{{ device?.odCalibrationId }}"
                  [routerLink]="
                    '/calibration/optical-density/' +
                    device?.odCalibrationId +
                    '/calibration-data'
                  "
                ></app-button>
                <app-button
                  *ngIf="
                    device?.deviceStatusId === 12 &&
                    device.oxygenCalibrationId !== (null || undefined)
                  "
                  label="View Active Calibration"
                  buttonId="calibration_{{ device?.oxygenCalibrationId }}"
                  [routerLink]="
                    '/calibration/oxygen/' +
                    device?.oxygenCalibrationId +
                    '/calibration-data'
                  "
                ></app-button>
                <app-button
                  *ngIf="
                    device?.deviceStatusId === 13 &&
                    device.phCalibrationId !== (null || undefined)
                  "
                  label="View Active Calibration"
                  buttonId="calibration_{{ device?.phCalibrationId }}"
                  [routerLink]="
                    '/calibration/ph/' +
                    device?.phCalibrationId +
                    '/calibration-data'
                  "
                ></app-button>
                <ng-template #draft>
                  <app-button
                    *ngIf="
                      device?.experiment?.experimentStatusId ===
                      ExperimentStatus.Draft
                    "
                    label="Start experiment"
                    buttonId="experiment_{{ device?.experiment?.id }}"
                    [routerLink]="
                      '/experiment/' +
                      device?.experiment?.id +
                      '/set-parameters'
                    "
                    [isDisabled]="
                      currentUser.role | roleCheck : [Role.Read_Access_User]
                    "
                  ></app-button>
                  <app-button
                    *ngIf="
                      device?.experiment?.experimentStatusId ===
                      ExperimentStatus.DraftLocked
                    "
                    label="Start experiment"
                    buttonId="experiment_{{ device?.experiment?.id }}"
                    [routerLink]="
                      '/experiment/' + device?.experiment?.id + '/device-setup'
                    "
                    [isDisabled]="
                      currentUser.role | roleCheck : [Role.Read_Access_User]
                    "
                  ></app-button>
                </ng-template>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <div id="pagination--container">
        <div class="filter--container">
          <label for="devices-per-page">Devices per page</label>
          <app-dropdown-form-field
            id="devices-per-page"
            [field]="devicesPerPageField"
            [parentForm]="deviceTableControls"
          >
          </app-dropdown-form-field>
        </div>
        <div id="pagination--controls">
          <app-icon-button
            iconName="chevron_left"
            buttonId="previous"
            (buttonClicked)="getPrevious()"
            [isDisabled]="!hasPrevious"
          ></app-icon-button>
          <span> {{ currentPage }} / {{ totalPages }} </span>
          <app-icon-button
            iconName="chevron_right"
            buttonId="next"
            (buttonClicked)="getNext()"
            [isDisabled]="!hasNext"
          ></app-icon-button>
        </div>
      </div>
    </div>
  </div>
  <div
    id="cta--container"
    *ngIf="!loading && (!devices || devices.length === 0)"
  >
    <h4>No devices detected</h4>
    <app-icon-button
      label="reset"
      buttonStyle="secondary"
      buttonId="filter-btn"
      iconName="restart_alt"
      buttonShape="square"
      (buttonClicked)="resetForm()"
    ></app-icon-button>
    <p *ngIf="error" class="error">Error: {{ error.message }}</p>
    <section id="button--container" *ngIf="!devices">
      <app-button
        label="Add New Device"
        buttonId="new-device-btn"
        hasFill="true"
        [routerLink]="'/device/create-device'"
        [isDisabled]="
          currentUser.role
            | roleCheck
              : [
                  Role.Read_Access_User,
                  Role.Run_Access_User,
                  Role.Edit_Access_User
                ]
        "
      >
      </app-button>
    </section>
  </div>
  <div id="loading--container" *ngIf="loading && !devices">
    <app-loading-spinner></app-loading-spinner>
  </div>
</div>

<ng-template #noLab
  ><div
    *ngIf="currentUser.role === Role.Super_User; else noLabOtherUsers"
    id="create-lab--container"
  >
    <h4>Let's get started</h4>
    <div id="button--container">
      <app-icon-button
        label="Create Laboratory"
        routerLink="/laboratories/add-lab"
        iconName="add_circle"
        buttonId="add-lab-btn"
        buttonStyle="secondary"
        buttonShape="square"
      >
      </app-icon-button>
    </div>
  </div>
  <ng-template #noLabOtherUser>
    <app-error-page
      title="No Lab found"
      primaryText="Please contact your lab administrator to be added to a lab"
    ></app-error-page>
  </ng-template>
</ng-template>
<app-filter-modal
  id="filter"
  buttonType="double-button"
  primaryButtonText="Apply Filters"
  primaryButtonId="continue-button"
  secondaryButtonText="Reset"
  secondaryButtonId="reset-button"
  [modulesField]="deviceModuleFilters"
  [statusField]="deviceStatusFilters"
  [offlineField]="deviceOfflineField"
  [deletedField]="includeDeletedDevicesField"
  [parentForm]="deviceTableControls"
  [hideDeletedField]="
    currentUser.role
      | roleCheck
        : [
            Role.Read_Access_User,
            Role.Run_Access_User,
            Role.Edit_Access_User,
            Role.Super_User,
            Role.Lab_Administrator,
            Role.OGI_Laboratory_Administrator
          ]
  "
  (modalButtonClicked)="modalButtonClicked($event)"
  (moduleCheckboxClicked)="moduleCheckboxClicked($event)"
  [isProcessing]="loading"
></app-filter-modal>
