@if (!isLoading) {
  <div id="title--container">
    <h4>{{ organisation?.organisationName }}</h4>
    <div class="button--container">
      <app-icon-button
        iconName="add_circle"
        buttonId="add-lab-btn"
        buttonStyle="secondary"
        (buttonClicked)="addLabButtonClicked()"
        buttonShape="square"
        label="Add Laboratory"
        routerLink="/laboratories/add-lab"
        [isDisabled]="
          currentUser.role
            | roleCheck
              : [
                  Role.Read_Access_User,
                  Role.Run_Access_User,
                  Role.Edit_Access_User,
                  Role.Lab_Administrator,
                  Role.OGI_Laboratory_Administrator,
                ]
        "
      >
      </app-icon-button>
      @if (currentUser.role | roleCheck: [Role.OGI_Super_Administrator]) {
        <app-icon-button
          label="delete organisation"
          buttonStyle="negative"
          buttonId="delete-org-btn"
          iconName="delete"
          buttonShape="square"
          (buttonClicked)="openModal('delete-organisation')"
          [isDisabled]="
            currentUser.role
              | roleCheck
                : [
                    Role.Read_Access_User,
                    Role.Run_Access_User,
                    Role.Edit_Access_User,
                    Role.Lab_Administrator,
                    Role.OGI_Laboratory_Administrator,
                    Role.Super_User,
                  ]
          "
        ></app-icon-button>
      }
    </div>
  </div>
  <hr />
  <div id="org--container">
    <div id="org-details">
      <div>
        <h6>Organisation Details</h6>
        <div class="form--container">
          <form [formGroup]="editOrgForm">
            <app-mat-form-field
              [parentForm]="editOrgForm"
              [field]="orgNameField"
            ></app-mat-form-field>
          </form>
          <div class="update-button--container">
            <app-button
              label="Update Organisation"
              buttonId="update-btn"
              (buttonClicked)="onSubmit()"
              [isDisabled]="
                isProcessing || editOrgForm.invalid || editOrgForm.pristine
              "
              [isProcessing]="isProcessing"
            >
            </app-button>
          </div>
        </div>
      </div>
      <div id="card--container">
        <app-reduced-small-data-card
          title="License Type"
          [dataReading]="LicenseType[organisation.licenseType]"
        ></app-reduced-small-data-card>
        <app-reduced-small-data-card
          title="Number of Devices"
          [dataReading]="
            organisation?.numberOfDevices +
            (organisation.licenseType === LicenseType.Basic
              ? '/3'
              : organisation.licenseType === LicenseType.Custom
                ? '/4'
                : '')
          "
        ></app-reduced-small-data-card>
        <app-reduced-small-data-card
          title="Number of Users"
          [dataReading]="organisation?.numberOfUsers + '/10'"
        ></app-reduced-small-data-card>
      </div>
    </div>
    <div id="nav--container">
      <app-alt-option-tab-toggle
        id="toggle--container"
        groupName="navigationToggle"
        [selectOptions]="navOptions"
        type="round"
        [(currentValue)]="selectedTabOption"
      >
      </app-alt-option-tab-toggle>
    </div>
    @if (selectedTabOption === "members") {
      @if ((!users || users?.length === 0) && !isLoading) {
        <div>
          <p id="no-users">
            {{ organisation.organisationName }} does not have any members.
          </p>
        </div>
      }
      <p id="table-description" style="display: none">User table</p>
      @if (users?.length > 0) {
        <div id="table--container">
          <table aria-describedby="table-description">
            <thead>
              <tr>
                <th
                  id="first-name"
                  scope="col"
                  (click)="onSort('FirstName')"
                  [class.active]="currentSort.sortBy === 'FirstName'"
                >
                  <div id="table-header">
                    <span>First Name</span>
                    <mat-icon
                      aria-label="sort direction"
                      matTooltip="Sort direction"
                      >swap_vert</mat-icon
                    >
                  </div>
                </th>
                <th
                  id="last-name"
                  scope="col"
                  (click)="onSort('LastName')"
                  [class.active]="currentSort.sortBy === 'LastName'"
                >
                  <div id="table-header">
                    <span>Last Name</span>
                    <mat-icon
                      aria-label="sort direction"
                      matTooltip="Sort direction"
                      >swap_vert</mat-icon
                    >
                  </div>
                </th>
                <th
                  id="email"
                  scope="col"
                  (click)="onSort('UserEmail')"
                  [class.active]="currentSort.sortBy === 'UserEmail'"
                >
                  <div id="table-header">
                    <span>Email</span>
                    <mat-icon
                      aria-label="sort direction"
                      matTooltip="Sort direction"
                      >swap_vert</mat-icon
                    >
                  </div>
                </th>
                <th id="role" scope="col">
                  <div id="table-header">
                    <span>Role</span>
                  </div>
                </th>
                <th id="role" scope="col">
                  <div id="table-header">
                    <span>Laboratories</span>
                  </div>
                </th>
                <th id="actions" scope="col">
                  <div id="table-header">
                    <span>Actions</span>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              @for (user of users; track user.id) {
                <tr>
                  <td>{{ user.firstName }}</td>
                  <td>{{ user.lastName }}</td>
                  <td>{{ user.email }}</td>
                  <td>{{ user.role | formatRole }}</td>
                  <td>
                    @for (lab of user.labNames; track lab) {
                      <p>{{ lab }}</p>
                    }
                  </td>
                  <td id="button--container">
                    <app-button
                      label="Edit User"
                      buttonId="edit-btn"
                      (buttonClicked)="onEditUser(user.id)"
                      [isDisabled]="
                        currentUser.role
                          | roleCheck
                            : [
                                Role.Lab_Administrator,
                                Role.OGI_Laboratory_Administrator,
                                Role.Read_Access_User,
                                Role.Run_Access_User,
                                Role.Edit_Access_User,
                              ]
                      "
                    >
                    </app-button>
                  </td>
                </tr>
              }
            </tbody>
          </table>
          <div id="pagination--container">
            <div class="filter--container">
              <label for="users-per-page">Users per page</label>
              <app-dropdown-form-field
                id="users-per-page"
                [field]="usersPerPageField"
                [parentForm]="usersTableControls"
              >
              </app-dropdown-form-field>
            </div>
            <div id="pagination--controls">
              <app-icon-button
                iconName="chevron_left"
                buttonId="previous"
                (buttonClicked)="getPrevious()"
                [isDisabled]="!hasPrevious"
              ></app-icon-button>
              <span> {{ currentPage }} / {{ totalPages }} </span>
              <app-icon-button
                iconName="chevron_right"
                buttonId="next"
                (buttonClicked)="getNext()"
                [isDisabled]="!hasNext"
              ></app-icon-button>
            </div>
          </div>
        </div>
      }
    }
    @if (selectedTabOption === "labs") {
      @if (
        (!organisation?.labSummary || organisation?.labSummary?.length === 0) &&
        !isLoading
      ) {
        <div>
          <p id="no-users">
            {{ organisation.organisationName }} does not have any labs.
          </p>
        </div>
      }
      <p id="table-description" style="display: none">Laboratories table</p>
      <div id="table--container">
        <table aria-describedby="table-description">
          <thead>
            <tr>
              <th id="lab-name" scope="col">
                <div id="table-header">
                  <span>Name</span>
                </div>
              </th>
              <th id="error-log-count" scope="col">
                <div id="table-header">
                  <span>Total Error Log Count</span>
                </div>
              </th>
              <th id="unviewed-error-log-count" scope="col">
                <div id="table-header">
                  <span>Unviewed Error Log Count</span>
                </div>
              </th>
              <!-- Only show if current user is a super admin -->
              @if (
                currentUser.role | roleCheck: [Role.OGI_Super_Administrator]
              ) {
                <th id="is-deleted" scope="col">
                  <div id="table-header">
                    <span>Is Deleted</span>
                  </div>
                </th>
              }
              <th>View Lab</th>
            </tr>
          </thead>
          <tbody>
            @for (lab of organisation?.labSummary; track lab.laboratoryId) {
              <tr>
                <td>{{ lab.laboratoryName }}</td>
                <td>{{ lab.totalErrorLogCount }}</td>
                <td>{{ lab.unviewedErrorLogCount }}</td>
                <!-- Only show if current user is a super admin -->
                @if (
                  currentUser.role | roleCheck: [Role.OGI_Super_Administrator]
                ) {
                  <td>{{ lab.isDeleted }}</td>
                }
                <td>
                  <app-icon-button
                    buttonId="view-lab-btn"
                    buttonStyle="transparent"
                    buttonShape="square"
                    iconName="arrow_forward"
                    (buttonClicked)="onViewLab(lab)"
                  ></app-icon-button>
                </td>
              </tr>
            }
            @if (organisation?.labSummary.length === 0) {
              <tr class="no-labs-row">
                <td colspan="3">No labs</td>
              </tr>
            }
          </tbody>
        </table>
      </div>
    }
  </div>
} @else {
  <div id="loading-spinner">
    <app-loading-spinner></app-loading-spinner>
  </div>
}
<app-modal
  id="success"
  titleText="Success"
  [primaryText]="successModalPrimaryText"
  buttonType="single-button"
  primaryButtonText="close"
  primaryButtonId="close-button"
  modalStyle="success"
  (modalButtonClicked)="modalButtonClicked($event)"
></app-modal>
<app-modal
  id="update-error"
  titleText="Error"
  [primaryText]="error?.message"
  buttonType="single-button"
  primaryButtonText="close"
  primaryButtonId="close-button"
  modalStyle="error"
  (modalButtonClicked)="modalButtonClicked($event)"
></app-modal>
<app-validation-modal
  id="delete-organisation"
  titleText="Delete organisation"
  validationText="delete"
  buttonType="double-button"
  primaryButtonText="Delete"
  primaryButtonId="primary-button"
  secondaryButtonText="Close"
  secondaryButtonId="secondary-button"
  (modalButtonClicked)="deleteOrganisation()"
  [error]="error"
></app-validation-modal>
