<div
  class="underlined-form-field__container"
  [class.disabled]="this.parentForm.controls[this.field.key].disabled"
  [class.error]="hasErrors"
>
  <mat-form-field [formGroup]="parentForm" [floatLabel]="'always'">
    <mat-label>{{ field.label }}</mat-label>
    <input
      matInput
      [id]="field.key"
      [placeholder]="field.placeholder"
      [type]="inputType"
      [formControlName]="field.key"
      [autocomplete]="field.autocomplete"
      [required]="field.required"
      [attr.aria-required]="field.required"
      [disabled]="this.parentForm.controls[this.field.key].disabled"
    />
    @if (iconName && field.type === "password") {
      <mat-icon matSuffix (click)="togglePassword()">{{ iconName }}</mat-icon>
    }
    @if (iconName && field.type !== "password") {
      <mat-icon matSuffix>{{ iconName }}</mat-icon>
    }
    @if (hasErrors) {
      <mat-error>{{ errorsList[0] }}</mat-error>
    }
    @if (showWarning) {
      <mat-error>{{ warningMessage }}</mat-error>
    }
  </mat-form-field>
  @if (displayTooltip) {
    <div class="minMaxValues" role="tooltip">
      <mat-icon>info</mat-icon>
      <div class="top">
        Min: {{ minValue }}<br />Max: {{ maxValue }}
        <i></i>
      </div>
      <div class="tooltip-accessibility">
        Min: {{ minValue }}<br />Max: {{ maxValue }}
      </div>
    </div>
  }
</div>
