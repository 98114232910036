import { Buffer } from './buffer';

export class PhCalibration {
  // Create ph cal required fields
  deviceId: number;
  name: string;
  saveLocationName: string;
  controlTemperature: boolean;
  temperature: number;
  phBufferEnums: Buffer[];
  // Final ph cal fields
  slope_A?: number;
  slope_B?: number;
  slope_C?: number;
  slope_D?: number;
  offset_A?: number;
  offset_B?: number;
  offset_C?: number;
  offset_D?: number;
  calFactor_A?: number;
  calFactor_B?: number;
  calFactor_C?: number;
  calFactor_D?: number;
  calOffset_A?: number;
  calOffset_B?: number;
  calOffset_C?: number;
  calOffset_D?: number;
  r2_A?: number;
  r2_B?: number;
  r2_C?: number;
  r2_D?: number;
  id?: number;
  isFinal?: boolean;
  laboratoryId?: number;
  createdAt?: Date;
  isDeleted?: boolean;
  deviceName?: string;

  constructor(
    deviceId: number,
    name: string,
    saveLocationName: string,
    controlTemperature: boolean,
    temperature: number,
    phBufferEnums: Buffer[],
    slope_A?: number,
    slope_B?: number,
    slope_C?: number,
    slope_D?: number,
    offset_A?: number,
    offset_B?: number,
    offset_C?: number,
    offset_D?: number,
    calFactor_A?: number,
    calFactor_B?: number,
    calFactor_C?: number,
    calFactor_D?: number,
    calOffset_A?: number,
    calOffset_B?: number,
    calOffset_C?: number,
    calOffset_D?: number,
    r2_A?: number,
    r2_B?: number,
    r2_C?: number,
    r2_D?: number,
    id?: number,
    isFinal?: boolean,
  ) {
    this.deviceId = deviceId;
    this.name = name;
    this.saveLocationName = saveLocationName;
    this.controlTemperature = controlTemperature;
    this.temperature = temperature;
    this.phBufferEnums = phBufferEnums || [];
    this.slope_A = slope_A;
    this.slope_B = slope_B;
    this.slope_C = slope_C;
    this.slope_D = slope_D;
    this.offset_A = offset_A;
    this.offset_B = offset_B;
    this.offset_C = offset_C;
    this.offset_D = offset_D;
    this.calFactor_A = calFactor_A;
    this.calFactor_B = calFactor_B;
    this.calFactor_C = calFactor_C;
    this.calFactor_D = calFactor_D;
    this.calOffset_A = calOffset_A;
    this.calOffset_B = calOffset_B;
    this.calOffset_C = calOffset_C;
    this.calOffset_D = calOffset_D;
    this.r2_A = r2_A;
    this.r2_B = r2_B;
    this.r2_C = r2_C;
    this.r2_D = r2_D;
    this.id = id;
    this.isFinal = isFinal;
  }
}
