<div class="search" [formGroup]="parentForm">
  <input
    [type]="field.type"
    [formControlName]="field.key"
    [placeholder]="field.placeholder"
    class="search-term"
  />
  <button
    (click)="clickButton('clear-search')"
    type="button"
    class="close-button"
    title="Clear search"
  >
    <mat-icon>close</mat-icon>
  </button>
  <button
    (click)="clickButton('search')"
    type="submit"
    class="search-button"
    title="Search"
  >
    <mat-icon>search</mat-icon>
  </button>
</div>
