<div [ngClass]="containerClasses" [formGroup]="parentForm">
  <label [ngClass]="labelClasses" [for]="field.key"> {{ field.label }} </label>
  @if (field.required) {
  <span class="overline" aria-required="true"> * </span>
  }
  <div [ngClass]="optionsContainerClasses" [formArrayName]="field.key">
    @for (option of field.options; track $index) {
    <label [for]="option.key" [ngClass]="classes">
      <input
        type="checkbox"
        [id]="option.key"
        [attr.name]="option.key"
        [ngClass]="inputClasses"
        (change)="updateValue($event)"
        [checked]="initialiseCheckboxes($index)"
        [disabled]="isDisabled"
        [formControlName]="$index"
      />
      @if (checkboxStyle === 'default' || checkboxStyle === 'list') {
      <div class="checkmark--background">
        <div class="checkmark">
          <mat-icon>done</mat-icon>
        </div>
      </div>
      } @if (checkboxStyle === 'tabs-small' || showModuleIcon) {
      <app-active-modules [activeModules]="[option.key]"> </app-active-modules>
      }
      {{ option.value }}
      <div class="body3 options">
        @for (opt of option.options; track option.options) {
        <div>{{ opt }}</div>
        }
      </div>
    </label>
    }
  </div>
  @if (hasErrors) {
  <ul>
    @for (error of errorsList; track errorsList) {}
    <li class="underlined-form-field-error caption error">
      {{ error }}
    </li>
  </ul>
  }
</div>
