import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IconNames } from 'src/app/_components/icons/icon-names';
import { Checkbox } from 'src/app/_models/checkbox';
import { ErrorObject } from 'src/app/_models/error';
import { FormBase } from 'src/app/_models/form-base';
import { AuthenticationService } from 'src/app/_services/authentication.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  emailField = new FormBase({
    key: 'email',
    label: 'Email',
    type: 'email',
    placeholder: 'Email Address',
    disabled: false,
    required: true,
    value: '',
    options: [],
    autocomplete: 'email',
  });

  passwordField = new FormBase({
    key: 'password',
    label: 'Password',
    type: 'password',
    placeholder: 'Password',
    disabled: false,
    required: true,
    value: '',
    options: [],
    autocomplete: 'current-password',
  });

  rememberMe = new Checkbox('rememberMe', 'Remember me', 'done');

  loginForm = this.formBuilder.group({
    [this.emailField.key]: ['', [Validators.required, Validators.email]],
    [this.passwordField.key]: ['', Validators.required],
    [this.rememberMe.id]: [false, Validators.required],
  });

  returnUrl: string;
  submitted = false;
  isProcessing = false;
  logo = IconNames.LogoColour;
  errors: string[];
  loginError = new ErrorObject(null, '');

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService
  ) {
    // If already logged in, check return url and redirect
    if (this.authenticationService.isLoggedIn) {
      if (this.route.snapshot.queryParams.returnUrl) {
        void this.router.navigate([this.route.snapshot.queryParams.returnUrl]);
      } else {
        void this.router.navigate(['/']);
      }
    }
  }

  ngOnInit(): void {
    // Get return URL from route parameters or default to home
    this.returnUrl =
      (this.route.snapshot.queryParams.returnUrl as string) || '/';
  }

  /**
   * @remarks
   * Convenience getter for easy access to form fields
   *
   */
  get f(): FormGroup['controls'] {
    return this.loginForm.controls;
  }

  /**
   * @remarks
   * Method to login user or return appropriate validation errors to form fields
   */
  onSubmit(): void {
    // If form is already being submitted, exit
    if (this.isProcessing) {
      return;
    }
    // Reset login error object
    this.loginError.message = '';
    this.isProcessing = true;
    this.authenticationService
      .login(
        this.f.email.value as string,
        this.f.password.value as string,
        this.f.rememberMe.value as boolean
      )
      .pipe()
      .subscribe({
        next: (response: boolean) => {
          if (response) {
            this.isProcessing = false;
            void this.router.navigate([this.returnUrl]);
          } else {
            this.isProcessing = false;
            this.loginError = new ErrorObject(null, 'An error occurred');
          }
        },
        error: (error: ErrorObject) => {
          this.loginError = error;
          this.isProcessing = false;
        },
      });
  }
}
