<div [ngClass]="classes">
  <mat-form-field [formGroup]="parentForm" [floatLabel]="'always'">
    <mat-label>{{ field.label }}</mat-label>
    <input
      matInput
      [placeholder]="field.placeholder"
      [type]="inputType"
      [formControlName]="field.key"
      [autocomplete]="field.autocomplete"
      [required]="field.required"
      [attr.aria-required]="field.required"
      [disabled]="this.parentForm.controls[this.field.key].disabled"
    />
    <mat-icon
      *ngIf="iconName && field.type === 'password'"
      matSuffix
      (click)="togglePassword()"
      >{{ iconName }}</mat-icon
    >
    <mat-icon *ngIf="iconName && field.type !== 'password'" matSuffix>{{
      iconName
    }}</mat-icon>
    <mat-error *ngIf="hasErrors">{{ errorsList[0] }}</mat-error>
    <mat-error *ngIf="showWarning">{{ warningMessage }}</mat-error>
  </mat-form-field>
  <div *ngIf="displayTooltip" class="minMaxValues" role="tooltip">
    <mat-icon>info</mat-icon>
    <div class="top">
      Min: {{ minValue }}<br />Max: {{ maxValue }}
      <i></i>
    </div>
    <div class="tooltip-accessibility">
      Min: {{ minValue }}<br />Max: {{ maxValue }}
    </div>
  </div>
</div>
