<div id="experiment--container">
  <div id="nav--container">
    <app-option-tab-toggle
      radioGroup="navigationToggle"
      [selectOptions]="navOptions"
      type="round"
      id="toggle--container"
    >
    </app-option-tab-toggle>
  </div>
  <hr />
  <div id="inner-page--container" *ngIf="devices.length > 0; else placeholder">
    <div id="header--container">
      <div class="title--container">
        <h4 id="header">Experiment Controls</h4>
        <span class="body1">
          Last updated:
          {{ experimentDataLastUpdated | date : "MMMM d, y, h:mm a" }}
          &nbsp; Experiment status:
          <app-status-indicator
            *ngIf="experiment?.isDeleted === false"
            [status]="ExperimentStatus[experiment?.experimentStatusId]"
          ></app-status-indicator>
          <app-status-indicator
            *ngIf="experiment?.isDeleted"
            status="Deleted"
          ></app-status-indicator>
          &nbsp; Experiment mode:
          {{ ExperimentMode[experiment?.experimentMode] | formatCamelCase }}
        </span>
        <span> </span>
      </div>
      <div class="button--container">
        <app-icon-button
          label="update"
          buttonId="update-btn"
          buttonStyle="outlined"
          (buttonClicked)="getExperiment()"
          [isProcessing]="loading"
          iconName="autorenew"
          buttonShape="square"
          buttonStyle="outlined"
        ></app-icon-button>
        <app-button
          label="stop experiment"
          buttonId="stop-btn"
          buttonStyle="negative"
          (buttonClicked)="openModal('stop-experiment')"
          [isProcessing]="stopExperimentProcessing"
          [isDisabled]="
            experiment?.experimentStatusId !== ExperimentStatus.Active ||
            experiment?.isDeleted === true ||
            (currentUser.role | roleCheck : [Role.Read_Access_User])
          "
        >
        </app-button>
        <app-icon-button
          label="Delete Experiment"
          buttonId="delete-btn"
          buttonStyle="negative"
          iconName="delete"
          buttonShape="square"
          (buttonClicked)="openModal('delete-experiment')"
          [isDisabled]="
            experiment?.isDeleted === true ||
            deleteIsProcessing ||
            (currentUser.role
              | roleCheck
                : [
                    Role.Read_Access_User,
                    Role.Run_Access_User,
                    Role.Edit_Access_User
                  ])
          "
        ></app-icon-button>
      </div>
    </div>
    <hr />
    <div id="experiment-controls--container">
      <div class="experiment-controls-form--container">
        <app-underlined-form-field
          [parentForm]="experimentUpdateForm"
          [field]="experimentNameField"
        ></app-underlined-form-field>
        <app-underlined-form-field
          [parentForm]="experimentUpdateForm"
          [field]="organismField"
        ></app-underlined-form-field>
        <app-underlined-form-field
          [parentForm]="experimentUpdateForm"
          [field]="strainField"
        ></app-underlined-form-field>
        <app-underlined-form-field
          [parentForm]="experimentUpdateForm"
          [field]="mediaField"
        ></app-underlined-form-field>
        <app-underlined-form-field
          [parentForm]="experimentUpdateForm"
          [field]="samplingTimeField"
          [minValue]="minSampleTimeInMins"
          [maxValue]="maxSampleTimeInMins"
          [displayTooltip]="true"
        ></app-underlined-form-field>
        <app-underlined-form-field
          [parentForm]="experimentUpdateForm"
          [field]="experimentTimeField"
          [minValue]="minExperimentTimeInHours"
          [maxValue]="maxExperimentTimeInHours"
          [displayTooltip]="true"
        ></app-underlined-form-field>
      </div>
      <div class="button--container">
        <app-icon-button
          iconName="pause_circle"
          [label]="
            areDevicesPaused() === true ? 'restart devices' : 'pause devices'
          "
          buttonId="pause-btn"
          buttonStyle="pause"
          (buttonClicked)="pauseRestartButtonClicked()"
          [isProcessing]="pauseDevicesProcessing"
          [isDisabled]="
            experiment?.isDeleted === true ||
            (experiment?.experimentStatusId !== ExperimentStatus.Active &&
              experiment?.experimentStatusId !== ExperimentStatus.Paused) ||
            (currentUser.role | roleCheck : [Role.Read_Access_User])
          "
        >
        </app-icon-button>
      </div>
    </div>
    <hr />
    <div id="base-unit--container">
      <div id="module-title--container">
        <h6>Base Unit</h6>
      </div>
      <div id="base-unit--form">
        <app-underlined-form-field
          [parentForm]="experimentUpdateForm"
          [field]="ambientTemperatureField"
          [minValue]="minAmbientTemp"
          [maxValue]="maxAmbientTemp"
          [displayTooltip]="true"
          [step]="ambientTempStep"
        ></app-underlined-form-field>
        <div class="grid--container">
          <app-slide-toggle
            [parentForm]="experimentUpdateForm"
            [field]="temperatureControlAField"
          ></app-slide-toggle>
          <app-icon-underlined-form-field
            [parentForm]="experimentUpdateForm"
            [field]="temperatureAField"
            [minValue]="minCultureTemp"
            [maxValue]="maxCultureTemp"
            [step]="cultureTempStep"
          ></app-icon-underlined-form-field>
        </div>
        <div class="grid--container">
          <app-slide-toggle
            [parentForm]="experimentUpdateForm"
            [field]="temperatureControlBField"
          ></app-slide-toggle>
          <app-icon-underlined-form-field
            [parentForm]="experimentUpdateForm"
            [field]="temperatureBField"
            [minValue]="minCultureTemp"
            [maxValue]="maxCultureTemp"
            [step]="cultureTempStep"
          ></app-icon-underlined-form-field>
        </div>
        <div class="grid--container">
          <app-slide-toggle
            [parentForm]="experimentUpdateForm"
            [field]="temperatureControlCField"
          ></app-slide-toggle>
          <app-icon-underlined-form-field
            [parentForm]="experimentUpdateForm"
            [field]="temperatureCField"
            [minValue]="minCultureTemp"
            [maxValue]="maxCultureTemp"
            [step]="cultureTempStep"
          ></app-icon-underlined-form-field>
        </div>
        <div class="grid--container">
          <app-slide-toggle
            [parentForm]="experimentUpdateForm"
            [field]="temperatureControlDField"
          ></app-slide-toggle>
          <app-icon-underlined-form-field
            [parentForm]="experimentUpdateForm"
            [field]="temperatureDField"
            [minValue]="minCultureTemp"
            [maxValue]="maxCultureTemp"
            [step]="cultureTempStep"
          ></app-icon-underlined-form-field>
        </div>
        <div class="grid--container">
          <app-slide-toggle
            [parentForm]="experimentUpdateForm"
            [field]="setStirringSpeedAField"
          ></app-slide-toggle>
          <app-icon-underlined-form-field
            [parentForm]="experimentUpdateForm"
            [field]="speedAField"
            [minValue]="minSpeed"
            [maxValue]="maxSpeed"
            [step]="speedStep"
          ></app-icon-underlined-form-field>
        </div>
        <div class="grid--container">
          <app-slide-toggle
            [parentForm]="experimentUpdateForm"
            [field]="setStirringSpeedBField"
          ></app-slide-toggle>
          <app-icon-underlined-form-field
            [parentForm]="experimentUpdateForm"
            [field]="speedBField"
            [minValue]="minSpeed"
            [maxValue]="maxSpeed"
            [step]="speedStep"
          ></app-icon-underlined-form-field>
        </div>
        <div class="grid--container">
          <app-slide-toggle
            [parentForm]="experimentUpdateForm"
            [field]="setStirringSpeedCField"
          ></app-slide-toggle>
          <app-icon-underlined-form-field
            [parentForm]="experimentUpdateForm"
            [field]="speedCField"
            [minValue]="minSpeed"
            [maxValue]="maxSpeed"
            [step]="speedStep"
          ></app-icon-underlined-form-field>
        </div>
        <div class="grid--container">
          <app-slide-toggle
            [parentForm]="experimentUpdateForm"
            [field]="setStirringSpeedDField"
          ></app-slide-toggle>
          <app-icon-underlined-form-field
            [parentForm]="experimentUpdateForm"
            [field]="speedDField"
            [minValue]="minSpeed"
            [maxValue]="maxSpeed"
            [step]="speedStep"
          ></app-icon-underlined-form-field>
        </div>
      </div>
    </div>
    <hr />
    <div class="control--container">
      <div id="module-title--container">
        <h6>Turbidostat</h6>
        <span
          *ngIf="experiment.experimentMode !== ExperimentMode['Turbidostat']"
          class="subtitle1"
          >(Module not selected)</span
        >
      </div>
      <div class="control--form">
        <div id="status--container">
          <span class="subtitle1">Maintain initial OD: </span>
          <app-status-indicator
            [status]="experiment?.maintainOD === true ? 'True' : 'False'"
          ></app-status-indicator>
        </div>
        <div *ngIf="displayTargetOD">
          <div class="grid--container">
            <app-slide-toggle
              [parentForm]="experimentUpdateForm"
              [field]="turbAActiveField"
            ></app-slide-toggle>
            <app-icon-underlined-form-field
              [parentForm]="experimentUpdateForm"
              [field]="targetODAField"
              [minValue]="minTargetOD"
              [maxValue]="maxTargetOD"
              [step]="targetODStep"
            ></app-icon-underlined-form-field>
          </div>
          <div class="grid--container">
            <app-slide-toggle
              [parentForm]="experimentUpdateForm"
              [field]="turbBActiveField"
            ></app-slide-toggle>
            <app-icon-underlined-form-field
              [parentForm]="experimentUpdateForm"
              [field]="targetODBField"
              [minValue]="minTargetOD"
              [maxValue]="maxTargetOD"
              [step]="targetODStep"
            ></app-icon-underlined-form-field>
          </div>
          <div class="grid--container">
            <app-slide-toggle
              [parentForm]="experimentUpdateForm"
              [field]="turbCActiveField"
            ></app-slide-toggle>
            <app-icon-underlined-form-field
              [parentForm]="experimentUpdateForm"
              [field]="targetODCField"
              [minValue]="minTargetOD"
              [maxValue]="maxTargetOD"
              [step]="targetODStep"
            ></app-icon-underlined-form-field>
          </div>
          <div class="grid--container">
            <app-slide-toggle
              [parentForm]="experimentUpdateForm"
              [field]="turbDActiveField"
            ></app-slide-toggle>
            <app-icon-underlined-form-field
              [parentForm]="experimentUpdateForm"
              [field]="targetODDField"
              [minValue]="minTargetOD"
              [maxValue]="maxTargetOD"
              [step]="targetODStep"
            ></app-icon-underlined-form-field>
          </div>
        </div>
      </div>
    </div>
    <hr />
    <div class="control--container">
      <div id="module-title--container">
        <h6>Chemostat</h6>
        <span
          *ngIf="experiment.experimentMode !== ExperimentMode['Chemostat']"
          class="subtitle1"
          >(Module not selected)</span
        >
      </div>
      <div class="control--form">
        <div class="grid--container">
          <app-slide-toggle
            [parentForm]="experimentUpdateForm"
            [field]="chemoAActiveField"
          ></app-slide-toggle>
          <app-icon-underlined-form-field
            [parentForm]="experimentUpdateForm"
            [field]="flowRateAField"
            [minValue]="minFlowRate"
            [maxValue]="maxFlowRate"
            [step]="flowRateStep"
          ></app-icon-underlined-form-field>
        </div>
        <div class="grid--container">
          <app-slide-toggle
            [parentForm]="experimentUpdateForm"
            [field]="chemoBActiveField"
          ></app-slide-toggle>
          <app-icon-underlined-form-field
            [parentForm]="experimentUpdateForm"
            [field]="flowRateBField"
            [minValue]="minFlowRate"
            [maxValue]="maxFlowRate"
            [step]="flowRateStep"
          ></app-icon-underlined-form-field>
        </div>
        <div class="grid--container">
          <app-slide-toggle
            [parentForm]="experimentUpdateForm"
            [field]="chemoCActiveField"
          ></app-slide-toggle>
          <app-icon-underlined-form-field
            [parentForm]="experimentUpdateForm"
            [field]="flowRateCField"
            [minValue]="minFlowRate"
            [maxValue]="maxFlowRate"
            [step]="flowRateStep"
          ></app-icon-underlined-form-field>
        </div>
        <div class="grid--container">
          <app-slide-toggle
            [parentForm]="experimentUpdateForm"
            [field]="chemoDActiveField"
          ></app-slide-toggle>
          <app-icon-underlined-form-field
            [parentForm]="experimentUpdateForm"
            [field]="flowRateDField"
            [minValue]="minFlowRate"
            [maxValue]="maxFlowRate"
            [step]="flowRateStep"
          ></app-icon-underlined-form-field>
        </div>
      </div>
    </div>
    <hr />
    <div class="control--container">
      <div id="module-title--container">
        <h6>pH Control</h6>
        <span *ngIf="experiment.experimentMode !== ExperimentMode['PHControl']"
          >(Module not selected)</span
        >
      </div>
      <div class="control--form">
        <div class="grid--container">
          <app-slide-toggle
            [parentForm]="experimentUpdateForm"
            [field]="phControlAField"
          ></app-slide-toggle>
          <app-radio-button
            id="ph-radio-a"
            [field]="inputTypeAField"
            [parentForm]="experimentUpdateForm"
            [currentValue]="
              experimentUpdateForm?.controls[inputTypeAField.key]?.value
            "
            legendStyle="small"
          ></app-radio-button>
        </div>
        <app-icon-underlined-form-field
          [parentForm]="experimentUpdateForm"
          [field]="targetPHAField"
          [minValue]="minTargetPH"
          [maxValue]="maxTargetPH"
          [step]="targetPHStep"
        ></app-icon-underlined-form-field>
        <app-icon-underlined-form-field
          [parentForm]="experimentUpdateForm"
          [field]="pumpDurationAField"
          [minValue]="minPumpDuration"
          [maxValue]="maxPumpDuration"
          [step]="pumpDurationStep"
        ></app-icon-underlined-form-field>
        <div class="grid--container">
          <app-slide-toggle
            [parentForm]="experimentUpdateForm"
            [field]="phControlBField"
          ></app-slide-toggle>
          <app-radio-button
            id="ph-radio-b"
            [field]="inputTypeBField"
            [parentForm]="experimentUpdateForm"
            [currentValue]="
              experimentUpdateForm?.controls[inputTypeBField.key]?.value
            "
            legendStyle="small"
          ></app-radio-button>
        </div>
        <app-icon-underlined-form-field
          [parentForm]="experimentUpdateForm"
          [field]="targetPHBField"
          [minValue]="minTargetPH"
          [maxValue]="maxTargetPH"
          [step]="targetPHStep"
        ></app-icon-underlined-form-field>
        <app-icon-underlined-form-field
          [parentForm]="experimentUpdateForm"
          [field]="pumpDurationBField"
          [minValue]="minPumpDuration"
          [maxValue]="maxPumpDuration"
          [step]="pumpDurationStep"
        ></app-icon-underlined-form-field>
        <div class="grid--container">
          <app-slide-toggle
            [parentForm]="experimentUpdateForm"
            [field]="phControlCField"
          ></app-slide-toggle>
          <app-radio-button
            id="ph-radio-c"
            [field]="inputTypeCField"
            [parentForm]="experimentUpdateForm"
            [currentValue]="
              experimentUpdateForm?.controls[inputTypeCField.key]?.value
            "
            legendStyle="small"
          ></app-radio-button>
        </div>
        <app-icon-underlined-form-field
          [parentForm]="experimentUpdateForm"
          [field]="targetPHCField"
          [minValue]="minTargetPH"
          [maxValue]="maxTargetPH"
          [step]="targetPHStep"
        ></app-icon-underlined-form-field>
        <app-icon-underlined-form-field
          [parentForm]="experimentUpdateForm"
          [field]="pumpDurationCField"
          [minValue]="minPumpDuration"
          [maxValue]="maxPumpDuration"
          [step]="pumpDurationStep"
        ></app-icon-underlined-form-field>
        <div class="grid--container">
          <app-slide-toggle
            [parentForm]="experimentUpdateForm"
            [field]="phControlDField"
          ></app-slide-toggle>
          <app-radio-button
            id="ph-radio-d"
            [field]="inputTypeDField"
            [parentForm]="experimentUpdateForm"
            [currentValue]="
              experimentUpdateForm?.controls[inputTypeDField.key]?.value
            "
            legendStyle="small"
          ></app-radio-button>
        </div>
        <app-icon-underlined-form-field
          [parentForm]="experimentUpdateForm"
          [field]="targetPHDField"
          [minValue]="minTargetPH"
          [maxValue]="maxTargetPH"
          [step]="targetPHStep"
        ></app-icon-underlined-form-field>
        <app-icon-underlined-form-field
          [parentForm]="experimentUpdateForm"
          [field]="pumpDurationDField"
          [minValue]="minPumpDuration"
          [maxValue]="maxPumpDuration"
          [step]="pumpDurationStep"
        ></app-icon-underlined-form-field>
      </div>
    </div>
    <hr />
    <div class="control--container">
      <div id="module-title--container">
        <h6>Fluorescence</h6>
        <span *ngIf="!experiment.useFluorescence" class="subtitle1"
          >(Module not selected)</span
        >
      </div>
      <div class="control--form">
        <div class="grid--container">
          <app-slide-toggle
            [parentForm]="experimentUpdateForm"
            [field]="led1OnField"
          ></app-slide-toggle>
          <app-icon-underlined-form-field
            [parentForm]="experimentUpdateForm"
            [field]="led1IntensityField"
            [minValue]="minLEDIntensity"
            [maxValue]="maxLEDIntensity"
            [step]="ledIntensityStep"
          ></app-icon-underlined-form-field>
        </div>
        <div class="grid--container">
          <app-slide-toggle
            [parentForm]="experimentUpdateForm"
            [field]="led2OnField"
          ></app-slide-toggle>
          <app-icon-underlined-form-field
            [parentForm]="experimentUpdateForm"
            [field]="led2IntensityField"
            [minValue]="minLEDIntensity"
            [maxValue]="maxLEDIntensity"
            [step]="ledIntensityStep"
          ></app-icon-underlined-form-field>
        </div>
        <div class="grid--container">
          <app-slide-toggle
            [parentForm]="experimentUpdateForm"
            [field]="led3OnField"
          ></app-slide-toggle>
          <app-icon-underlined-form-field
            [parentForm]="experimentUpdateForm"
            [field]="led3IntensityField"
            [minValue]="minLEDIntensity"
            [maxValue]="maxLEDIntensity"
            [step]="ledIntensityStep"
          ></app-icon-underlined-form-field>
        </div>
      </div>
    </div>
    <hr />
    <div class="control--container">
      <div id="module-title--container">
        <h6>Oxygen</h6>
        <span *ngIf="!experiment.useOxygen" class="subtitle1"
          >(Module not selected)</span
        >
      </div>
      <div class="control--form">
        <app-icon-underlined-form-field
          [parentForm]="experimentUpdateForm"
          [field]="salinityAField"
          [minValue]="minSalinity"
          [maxValue]="maxSalinity"
          [step]="salinityStep"
        ></app-icon-underlined-form-field>
        <app-icon-underlined-form-field
          [parentForm]="experimentUpdateForm"
          [field]="salinityBField"
          [minValue]="minSalinity"
          [maxValue]="maxSalinity"
          [step]="salinityStep"
        ></app-icon-underlined-form-field>
        <app-icon-underlined-form-field
          [parentForm]="experimentUpdateForm"
          [field]="salinityCField"
          [minValue]="minSalinity"
          [maxValue]="maxSalinity"
          [step]="salinityStep"
        ></app-icon-underlined-form-field>
        <app-icon-underlined-form-field
          [parentForm]="experimentUpdateForm"
          [field]="salinityDField"
          [minValue]="minSalinity"
          [maxValue]="maxSalinity"
          [step]="salinityStep"
        ></app-icon-underlined-form-field>
      </div>
    </div>
    <hr />
    <div id="notes--container">
      <div id="module-title--container">
        <h6>Notes</h6>
      </div>
      <div id="notes--form">
        <app-underlined-textarea-field
          [parentForm]="experimentUpdateForm"
          [field]="noteField"
        ></app-underlined-textarea-field>
      </div>
      <div class="button--container">
        <app-button
          label="save"
          buttonId="apply-btn"
          type="submit"
          [isProcessing]="updateExperimentProcessing"
          [isDisabled]="
            experiment?.isDeleted === true ||
            experimentUpdateForm.pristine ||
            updateExperimentProcessing ||
            experiment?.experimentStatusId !== ExperimentStatus.Active ||
            (currentUser.role | roleCheck : [Role.Read_Access_User])
          "
          (click)="onSubmitExperimentUpdateForm()"
        ></app-button>
        <app-button
          label="undo"
          buttonId="undo-btn"
          [isDisabled]="
            experiment?.isDeleted === true ||
              updateExperimentProcessing ||
              experiment?.experimentStatusId !== ExperimentStatus.Active ||
              currentUser.role | roleCheck : [Role.Read_Access_User]
          "
          (click)="initialiseExperimentUpdateForm()"
        ></app-button>
      </div>
    </div>
    <hr />
    <div id="history--container">
      <h6>History</h6>
      <p id="table-description" style="display: none">Changelog table</p>
      <table aria-describedby="table-description">
        <thead>
          <tr>
            <th
              id="date"
              scope="col"
              (click)="onSort('CreatedAt')"
              [ngClass]="getClasses('CreatedAt')"
            >
              <div id="table-header">
                Date
                <mat-icon
                  aria-label="sort direction"
                  matTooltip="Sort direction"
                  >swap_vert</mat-icon
                >
              </div>
            </th>
            <th
              id="note"
              scope="col"
              (click)="onSort('NoteText')"
              [ngClass]="getClasses('NoteText')"
            >
              <div id="table-header">
                Note
                <mat-icon
                  aria-label="sort direction"
                  matTooltip="Sort direction"
                  >swap_vert</mat-icon
                >
              </div>
            </th>
            <th
              id="change"
              scope="col"
              (click)="onSort('ChangeText')"
              [ngClass]="getClasses('ChangeText')"
            >
              <div id="table-header">
                Logged Change
                <mat-icon
                  aria-label="sort direction"
                  matTooltip="Sort direction"
                  >swap_vert</mat-icon
                >
              </div>
            </th>
            <th
              id="user"
              scope="col"
              (click)="onSort('CreatedBy')"
              [ngClass]="getClasses('CreatedBy')"
            >
              <div id="table-header">
                User
                <mat-icon
                  aria-label="sort direction"
                  matTooltip="Sort direction"
                  >swap_vert</mat-icon
                >
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let log of changelogList; let i = index">
            <td headers="date">
              {{ log.createdAt | date : "dd/MM/YY - h:mm a" }}
            </td>
            <td headers="note">{{ log.noteText }}</td>
            <td headers="change">
              {{ log.changeText }}
            </td>
            <td headers="user">{{ log.createdBy }}</td>
          </tr>
        </tbody>
      </table>
      <div id="pagination--container">
        <app-dropdown-form-field
          id="changelogs-per-page"
          [field]="changeLogsPerPageField"
          [parentForm]="paginationControls"
        >
        </app-dropdown-form-field>
        <div id="pagination--controls">
          <app-icon-button
            iconName="chevron_left"
            buttonId="previous"
            (buttonClicked)="getPrevious()"
            [isDisabled]="!hasPrevious"
          ></app-icon-button>
          <span> {{ currentPage }} / {{ totalPages }} </span>
          <app-icon-button
            iconName="chevron_right"
            buttonId="next"
            (buttonClicked)="getNext()"
            [isDisabled]="!hasNext"
          ></app-icon-button>
        </div>
      </div>
    </div>
  </div>
  <ng-template #placeholder>
    <div id="error--container" *ngIf="error">
      <h4>Something went wrong.</h4>
      <p class="error">Error: {{ error.message }}</p>
    </div>
  </ng-template>
</div>
<app-validation-modal
  id="stop-experiment"
  titleText="Stop experiment"
  primaryText="Are you sure you want to stop this experiment?"
  validationText="stop"
  buttonType="double-button"
  primaryButtonText="Stop experiment"
  primaryButtonId="primary-button"
  secondaryButtonText="Close"
  secondaryButtonId="secondary-button"
  (modalButtonClicked)="onStopExperiment()"
  [error]="error"
></app-validation-modal>
<app-validation-modal
  id="pause-devices"
  [titleText]="pauseDeviceTitleText"
  [validationText]="pauseDeviceValidationText"
  buttonType="double-button"
  [primaryButtonText]="pauseDeviceButtonText"
  primaryButtonId="primary-button"
  secondaryButtonText="Close"
  secondaryButtonId="secondary-button"
  (modalButtonClicked)="pauseDevicesOnExperiment()"
  [error]="error"
></app-validation-modal>
<app-modal
  id="error"
  titleText="Error"
  [primaryText]="error?.message"
  buttonType="single-button"
  primaryButtonText="close"
  primaryButtonId="close-button"
  modalStyle="error"
  (modalButtonClicked)="modalButtonClicked($event)"
></app-modal>
<app-modal
  id="attention"
  titleText="Attention"
  primaryText="Your changes have not been saved. Press cancel to return to the form and save your changes, or continue to discard."
  buttonType="double-button"
  primaryButtonText="Continue"
  primaryButtonId="confirm-navigation"
  secondaryButtonText="Cancel"
  secondaryButtonId="cancel-button"
  modalStyle="warning"
  (modalButtonClicked)="modalButtonClicked($event)"
></app-modal>
<app-modal
  id="update-experiment"
  modalStyle="success"
  titleText="Success"
  primaryText="Experiment updated."
  primaryButtonText="close"
  primaryButtonId="close-success-button"
  (modalButtonClicked)="modalButtonClicked($event)"
></app-modal>
<app-validation-modal
  id="delete-experiment"
  titleText="Delete experiment?"
  validationText="delete"
  buttonType="double-button"
  primaryButtonText="Confirm delete"
  primaryButtonId="confirm-delete-button"
  (modalButtonClicked)="deleteExperiment()"
  secondaryButtonText="Cancel"
  secondaryButtonId="cancel-delete-button"
  [error]="deleteError"
></app-validation-modal>
<app-modal
  id="delete-success"
  titleText="Success"
  primaryText="Experiment was deleted."
  primaryButtonText="Go to dashboard"
  primaryButtonId="delete-success-button"
  modalStyle="success"
  (modalButtonClicked)="modalButtonClicked($event)"
  allowClose="false"
>
</app-modal>
<app-modal
  id="delete-error"
  titleText="Unable to delete experiment"
  [primaryText]="deleteError?.message + '. Choose an action below'"
  buttonType="double-button"
  primaryButtonText="Go to dashboard"
  primaryButtonId="error-primary-button"
  secondaryButtonText="Close"
  secondaryButtonId="error-secondary-button"
  modalStyle="error"
  (modalButtonClicked)="modalButtonClicked($event)"
  allowClose="false"
>
</app-modal>
