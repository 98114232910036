<div id="title--container">
  <h4>Organisations</h4>
  <app-icon-button
    label="add new organisation"
    buttonStyle="secondary"
    buttonId="create-org-btn"
    iconName="add_circle"
    buttonShape="square"
    (buttonClicked)="onCreateNewOrgansation()"
  ></app-icon-button>
</div>
<hr />
<div id="org--container">
  @if ((!organisations || organisations?.length === 0) && !isFirstLoad) {
    <div id="inner-page--container">
      <p id="no-users">No organisations found.</p>
    </div>
  }
  @if (isFirstLoad) {
    <div id="loading-spinner">
      <app-loading-spinner label="Fetching organisations"></app-loading-spinner>
    </div>
  }
  @if (organisations?.length > 0) {
    <div id="inner-page--container">
      <p id="table-description" style="display: none">Organisation table</p>
      <div id="table--container">
        <table aria-describedby="table-description">
          <thead>
            <tr>
              <th
                id="name"
                scope="col"
                (click)="onSort('Name')"
                [class.active]="currentSort.sortBy === 'Name'"
              >
                <div id="table-header">
                  <span>Name</span>
                  <mat-icon
                    aria-label="sort direction"
                    matTooltip="Sort direction"
                    >swap_vert</mat-icon
                  >
                </div>
              </th>
              <th
                id="license-type"
                scope="col"
                (click)="onSort('LicenseType')"
                [class.active]="currentSort.sortBy === 'LicenseType'"
              >
                <div id="table-header">
                  <span>License Type</span>
                  <mat-icon
                    aria-label="sort direction"
                    matTooltip="Sort direction"
                    >swap_vert</mat-icon
                  >
                </div>
              </th>
              <th
                id="num-users"
                scope="col"
                (click)="onSort('NumberOfUsers')"
                [class.active]="currentSort.sortBy === 'NumberOfUsers'"
              >
                <div id="table-header">
                  <span>Number of Users</span>
                  <mat-icon
                    aria-label="sort direction"
                    matTooltip="Sort direction"
                    >swap_vert</mat-icon
                  >
                </div>
              </th>
              <th
                id="super-user"
                scope="col"
                (click)="onSort('SuperUserEmail')"
                [class.active]="currentSort.sortBy === 'SuperUserEmail'"
              >
                <div id="table-header">
                  <span>Super User</span>
                  <mat-icon
                    aria-label="sort direction"
                    matTooltip="Sort direction"
                    >swap_vert</mat-icon
                  >
                </div>
              </th>
              <th
                id="num-devices"
                scope="col"
                (click)="onSort('NumberOfDevices')"
                [class.active]="currentSort.sortBy === 'NumberOfDevices'"
              >
                <div id="table-header">
                  <span>Number of Devices</span>
                  <mat-icon
                    aria-label="sort direction"
                    matTooltip="Sort direction"
                    >swap_vert</mat-icon
                  >
                </div>
              </th>
              <th
                id="num-labs"
                scope="col"
                (click)="onSort('NumberOfLabs')"
                [class.active]="currentSort.sortBy === 'NumberOfLabs'"
              >
                <div id="table-header">
                  <span>Number of Labs</span>
                </div>
              </th>
              <th
                id="num-errors"
                scope="col"
                (click)="onSort('NumberOfErrors')"
                [class.active]="currentSort.sortBy === 'NumberOfErrors'"
              >
                <div id="table-header">
                  <span>Unviewed Device Errors</span>
                </div>
              </th>
              <th
                id="num-errors"
                scope="col"
                (click)="onSort('ViewOrganisation')"
                [class.active]="currentSort.sortBy === 'ViewOrganisation'"
              >
                <div id="table-header">
                  <span>View Organisation</span>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            @for (org of organisations; track org.id) {
              <tr (click)="viewOrgDetails(org.id)" class="selectable-row">
                <td>{{ org.organisationName }}</td>
                <td>{{ LicenseType[org.licenseType] }}</td>
                <td>{{ org.numberOfUsers }}</td>
                <td>
                  {{ org.superUser.superUserEmail }} <br />
                  Invite accepted:
                  {{ org.superUser.inviteAccepted ? "Yes" : "No" }}
                </td>
                <td>{{ org.numberOfDevices }}</td>
                <td>{{ org.labSummary?.length }}</td>
                <td>{{
                  org.labSummary?.unviewedErrorLogCount?.length || "-"
                }}</td>
                <td>
                  <app-icon-button
                    buttonId="view-org-btn"
                    buttonStyle="transparent--blue"
                    buttonShape="square"
                    iconName="arrow_forward"
                    (buttonClicked)="viewOrganisation(org)"
                  ></app-icon-button>
                </td>
              </tr>
            }
          </tbody>
        </table>
        <div id="pagination--container">
          <div class="filter--container">
            <label for="orgs-per-page">Organisations per page</label>
            <app-dropdown-form-field
              id="orgs-per-page"
              [field]="orgsPerPageField"
              [parentForm]="orgTableControls"
            >
            </app-dropdown-form-field>
          </div>
          <div id="pagination--controls">
            <app-icon-button
              iconName="chevron_left"
              buttonId="previous"
              (buttonClicked)="getPrevious()"
              [isDisabled]="!hasPrevious"
            ></app-icon-button>
            <span> {{ currentPage }} / {{ totalPages }} </span>
            <app-icon-button
              iconName="chevron_right"
              buttonId="next"
              (buttonClicked)="getNext()"
              [isDisabled]="!hasNext"
            ></app-icon-button>
          </div>
        </div>
      </div>
      @if (selectedOrganisation) {
        <div id="org-detail--container">
          <h6>{{ selectedOrganisation.organisationName }} Labs</h6>
          <div class="inner-labs--container subtitle1">
            <span>Lab Name</span>
            <span>Lab ID</span>
            <span>Unviewed Errors</span>
            <span>Total Device Errors</span>
            <span>View Lab</span>
          </div>
          @for (
            lab of selectedOrganisation.labSummary;
            track lab.laboratoryId
          ) {
            <div class="inner-labs--container title body1">
              <span>{{ lab.laboratoryName }}</span>
              <span>{{ lab.laboratoryId }}</span>
              <span>{{ lab.unviewedErrorLogCount?.length || "0" }}</span>
              <span>{{ lab.totalErrorLogCount || "0" }}</span>
              <app-icon-button
                buttonId="view-lab-btn"
                buttonStyle="transparent--blue"
                buttonShape="square"
                iconName="arrow_forward"
                (buttonClicked)="onViewLab(lab)"
              ></app-icon-button>
            </div>
          }
        </div>
      } @else {
        <h5>Select an organisation to view labs</h5>
      }
    </div>
  }
</div>
<app-modal
  id="attention-lab"
  titleText="Attention"
  primaryText="You are about to view a laboratory in a different organisation. Press cancel to return to the page, or continue to view laboratory."
  buttonType="double-button"
  primaryButtonText="Continue"
  primaryButtonId="confirm-lab-navigation"
  secondaryButtonText="Cancel"
  secondaryButtonId="cancel-lab-button"
  modalStyle="warning"
  (modalButtonClicked)="modalButtonClicked($event)"
></app-modal>
<app-modal
  id="attention-org"
  titleText="Attention"
  primaryText="You are about to view another organisation. Press cancel to return to the page, or continue to view organisation."
  buttonType="double-button"
  primaryButtonText="Continue"
  primaryButtonId="confirm-org-navigation"
  secondaryButtonText="Cancel"
  secondaryButtonId="cancel-org-button"
  modalStyle="warning"
  (modalButtonClicked)="modalButtonClicked($event)"
></app-modal>
