import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { ErrorObject } from 'src/app/_models/error';
import { FormBase } from 'src/app/_models/form-base';
import { Laboratory } from 'src/app/_models/laboratory';
import { LicenseType } from 'src/app/_models/license-type';
import { OrganisationIndexView } from 'src/app/_models/organisation';
import { Role } from 'src/app/_models/role';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { ModalService } from 'src/app/_services/modal.service';
import { OrganisationService } from 'src/app/_services/organisation.service';

@Component({
  selector: 'app-organisation-details',
  templateUrl: './organisation-details.component.html',
  styleUrls: ['./organisation-details.component.scss'],
})
export class OrganisationDetailsComponent implements OnInit {
  organisation: OrganisationIndexView;
  organisationId: number;
  currentUser = this.authService.currentUserValue;
  Role = Role;
  successModalPrimaryText: string;

  // Controls
  isProcessing = false;
  isLoading = true;
  allowNavigation: Subject<boolean> = new Subject<boolean>();
  error: ErrorObject = null;

  // Make licence type enum available in the template
  LicenseType = LicenseType;

  orgNameField = new FormBase({
    key: 'name',
    label: 'Organisation Name',
    type: 'text',
    placeholder: 'Organisation Name',
    disabled: false,
    required: true,
    value: '',
  });

  editOrgForm = this.formBuilder.group({
    [this.orgNameField.key]: [
      {
        value: this.orgNameField.value,
        disabled:
          this.currentUser.role !== Role.OGI_Super_Administrator &&
          this.currentUser.role !== Role.Super_User,
      },
      [
        (Validators.required,
        Validators.minLength(3),
        Validators.maxLength(100)),
      ],
    ],
  });

  constructor(
    private organisationService: OrganisationService,
    private authService: AuthenticationService,
    private modalService: ModalService,
    private formBuilder: FormBuilder,
    private router: Router,
    protected route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      this.organisationId = params.id as number;
    });
    this.getOrganisation();
  }

  getOrganisation(): void {
    this.organisationService.getOrganisation(this.organisationId).subscribe({
      next: (organisation) => {
        this.organisation = organisation;
        this.updateFormValues(organisation);
        this.isLoading = false;
      },
      error: (error: ErrorObject) => {
        this.error = error;
        this.isLoading = false;
      },
    });
  }

  updateFormValues(org: OrganisationIndexView): void {
    this.editOrgForm.reset();
    this.editOrgForm.patchValue({
      [this.orgNameField.key]: org.organisationName,
    });
    this.editOrgForm.markAsPristine();
  }

  onSubmit(): void {
    this.isProcessing = true;
    this.error = null;

    this.organisationService
      .updateOrganisation(this.editOrgForm.value.name, this.organisation.id)
      .subscribe({
        next: () => {
          this.isProcessing = false;
          this.successModalPrimaryText = 'Organisation successfully updated.';
          this.modalService.open('success');
        },
        error: (error: ErrorObject) => {
          this.error = error;
          this.isProcessing = false;
          this.modalService.open('update-error');
        },
      });
  }

  deleteOrganisation(): void {
    this.isProcessing = true;
    this.error = null;

    this.organisationService
      .deleteOrganisation(this.organisation.id)
      .subscribe({
        next: () => {
          this.isProcessing = false;
          this.closeModal('delete-organisation');
          this.successModalPrimaryText =
            'Delete organisation request has been submitted for approval.';
          this.modalService.open('success');
        },
        error: (error: ErrorObject) => {
          this.error = error;
          this.isProcessing = false;
          this.modalService.open('update-error');
        },
      });
  }

  onViewLab(laboratory: Laboratory): void {
    this.authService.setSelectedLaboratory(laboratory);
    // as lab context has changed, navigate to dashboard
    void this.router.navigate(['/dashboard']);
  }

  addLabButtonClicked(): void {
    void this.router.navigate(['/laboratories/add-lab'], {
      state: { data: this.organisation.id },
    });
  }

  openModal(modalId: string): void {
    this.modalService.open(modalId);
  }

  closeModal(modalId: string): void {
    this.modalService.close(modalId);
  }

  modalButtonClicked(buttonId: string): void {
    switch (buttonId) {
      case 'close-button':
        this.closeModal('success');
        this.closeModal('update-error');
        this.error = null;
        break;
    }
  }
}
