<img id="logo" src="assets/img/ogi-logo.png" alt="" />
<div id="page">
  <div id="container">
    <h4>
      {{ tokenTitle }}
      @if (tokenStatus === 0) {
        <span class="validating">...</span>
      }
    </h4>
    @if (tokenStatus === 2) {
      <p class="error expired">
        {{ requestError.message }}
      </p>
      <app-button
        label="Request new reset link"
        buttonId="forgot-password"
        hasFill="true"
        [routerLink]="'/forgot-password'"
      ></app-button>
    }
    @if (tokenStatus === 1) {
      <div id="form--container">
        <span class="error">
          <span *ngIf="resetError.message">Error: </span
          >{{ resetError.message }}
        </span>
        <form [formGroup]="resetForm" (ngSubmit)="onSubmit()">
          <app-mat-form-field
            iconName="lock"
            [field]="passwordField"
            [parentForm]="resetForm"
          ></app-mat-form-field>
          <app-mat-form-field
            iconName="lock"
            [field]="confirmPasswordField"
            [parentForm]="resetForm"
          >
          </app-mat-form-field>
          <ul class="body2">
            <app-validation-row
              validationText="Password must be at least 12 characters long"
              [passesValidation]="passesValidation('length')"
            ></app-validation-row>
            <app-validation-row
              validationText="Password must contain at least one uppercase letter"
              [passesValidation]="passesValidation('uppercaseLetter')"
            ></app-validation-row>
            <app-validation-row
              validationText="Password must contain at least one lowercase letter"
              [passesValidation]="passesValidation('lowercaseLetter')"
            ></app-validation-row>
            <app-validation-row
              validationText="Password must contain at least one number"
              [passesValidation]="passesValidation('number')"
            ></app-validation-row>
            <app-validation-row
              validationText="Password must contain at least one special character (&#64;#$%^&+!=)"
              [passesValidation]="passesValidation('specialCharacter')"
            ></app-validation-row>
          </ul>
          <app-button
            type="submit"
            label="Reset Password"
            buttonId="submit-btn"
            [isDisabled]="resetForm.invalid"
            [isProcessing]="isProcessing"
          ></app-button>
          <a [routerLink]="'/login'" class="button-styling">Return to login</a>
        </form>
      </div>
    }
  </div>
</div>
