import { Component, OnInit, inject } from '@angular/core';
import {
  FormBuilder,
  Validators,
  FormControl,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { Router, ActivatedRoute, Params, RouterLink } from '@angular/router';
import { IconNames } from 'src/app/_components/icons/icon-names';
import { Calibration } from 'src/app/_models/calibration';
import { Device } from 'src/app/_models/device';
import { DeviceCalibrationSummary } from 'src/app/_models/device-calibration-summary';
import { DeviceResponse } from 'src/app/_models/api-responses';
import { DeviceSetupStatus } from 'src/app/_models/device-setup-status';
import { ErrorObject } from 'src/app/_models/error';
import { FormBase } from 'src/app/_models/form-base';
import { Module } from 'src/app/_models/module-enum';
import { OxygenCalibration } from 'src/app/_models/oxygen-calibration';
import { PhCalibration } from 'src/app/_models/ph-calibration';
import { CalibrationService } from 'src/app/_services/calibration.service';
import { DeviceService } from 'src/app/_services/device.service';
import { ExperimentService } from 'src/app/_services/experiment.service';
import { Experiment } from 'src/app/_models/experiment';
import {
  ExperimentDeviceUpdate,
  ExperimentUpdate,
} from 'src/app/_models/experiment-update';
import { ExperimentMode } from 'src/app/_models/experiment-mode';
import { ModalService } from 'src/app/_services/modal.service';
import { Subject } from 'rxjs';
import { ExperimentStatus } from 'src/app/_models/experiment-status';
import { User } from 'src/app/_models/user';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { Role } from 'src/app/_models/role';
import { RoleCheckPipe } from '../../_helpers/role-check.pipe';
import { ValidationModalComponent } from '../../_components/modals/validation-modal/validation-modal.component';
import { ModalComponent } from '../../_components/modals/modal.component';
import { DynamicDropdownFormFieldComponent } from '../../_components/forms/dynamic-dropdown-form-field/dynamic-dropdown-form-field.component';
import { MatIcon } from '@angular/material/icon';
import { IconButtonComponent } from '../../_components/buttons/icon-button/icon-button.component';
import { SmallLoadingSpinnerComponent } from '../../_components/small-loading-spinner/small-loading-spinner.component';
import { DropdownFormFieldComponent } from '../../_components/forms/dropdown-form-field/dropdown-form-field.component';
import { SlideToggleComponent } from '../../_components/selection-controls/slide-toggle/slide-toggle.component';
import { ButtonComponent } from '../../_components/buttons/button/button.component';
import { StatusIndicatorComponent } from '../../_components/chips/status-indicator/status-indicator.component';

@Component({
  selector: 'app-select-device',
  templateUrl: './select-device.component.html',
  styleUrls: ['./select-device.component.scss'],
  standalone: true,
  imports: [
    StatusIndicatorComponent,
    ButtonComponent,
    SlideToggleComponent,
    DropdownFormFieldComponent,
    SmallLoadingSpinnerComponent,
    IconButtonComponent,
    FormsModule,
    ReactiveFormsModule,
    MatIcon,
    RouterLink,
    DynamicDropdownFormFieldComponent,
    ModalComponent,
    ValidationModalComponent,
    RoleCheckPipe,
  ],
})
export class SelectDeviceComponent implements OnInit {
  private readonly formBuilder = inject(FormBuilder);
  private readonly calibrationService = inject(CalibrationService);
  private readonly deviceService = inject(DeviceService);
  private readonly experimentService = inject(ExperimentService);
  private readonly authenticationService = inject(AuthenticationService);
  private readonly router = inject(Router);
  protected route = inject(ActivatedRoute);
  private readonly modalService = inject(ModalService);

  // Permissions
  currentUser: User;

  // Control vars
  odCalibrationLoading = false;
  oxygenCalibrationLoading = false;
  pHCalibrationLoading = false;
  devicesLoading = false;
  error: ErrorObject = null;
  deviceError: ErrorObject = null;
  odError: ErrorObject = null;
  oxyError: ErrorObject = null;
  pHError: ErrorObject = null;
  lockError: ErrorObject = null;
  deleteError: ErrorObject = null;

  // Load handlers
  loading = false;
  isProcessing = false;
  draftIsLocked = false;
  hasDevicesToRemove = false;
  deleteIsProcessing = false;

  // Make enums available to the template
  DeviceSetupStatus = DeviceSetupStatus;
  ExperimentStatus = ExperimentStatus;
  Role = Role;
  Module = Module;

  allowNavigation: Subject<boolean> = new Subject<boolean>();

  /**
   * @param {DeviceCalibrationSummary[]} selectedDeviceList A list of the devices to attach
   * to a new experiment, the device statuses and their associated calibration options
   */
  selectedDeviceList: DeviceCalibrationSummary[] = [];

  /**
   * @param {DeviceCalibrationSummary} savedDeviceList A list of the devices that have been saved
   * to the experiment
   * This is used to check if the user has made changes to the device setup
   * and to allow the user to discard changes
   */
  savedDeviceList: DeviceCalibrationSummary[] = [];

  /**
   * @param {IconNames} loadingSpinner The loading placeholder
   */
  spinner = IconNames.Sync;

  /** @param {Set<Module>} requiredDeviceModules A list of required modules on device index */
  requiredDeviceModules: Set<Module> = new Set<Module>();

  // Created experiment
  experiment: Experiment;
  experimentUpdate: ExperimentUpdate;
  experimentId: number = null;

  /**
   * @param {FormBase<string>} deviceSelectField
   */
  deviceSelectField = new FormBase<string>({
    key: 'device-select',
    label: 'Select a device',
    type: 'select',
    placeholder: 'Select device',
    disabled: false,
    required: false,
    value: '',
    options: [],
  });

  inactiveDeviceToggleField = new FormBase<boolean>({
    key: 'inactive-device-toggle',
    label: 'Inactive devices only',
    type: 'toggle',
    disabled: false,
    required: false,
    value: false,
  });

  onlineDeviceToggleField = new FormBase<boolean>({
    key: 'online-device-toggle',
    label: 'Online devices only',
    type: 'toggle',
    disabled: false,
    required: false,
    value: false,
  });

  /**
   *  @param {FormGroup} experimentSetupForm The form to hold the calibration selects
   */
  experimentSetupForm = this.formBuilder.group({
    [this.deviceSelectField.key]: ['', []],
  });

  deviceFilterForm = this.formBuilder.group({
    [this.inactiveDeviceToggleField.key]: [false, []],
    [this.onlineDeviceToggleField.key]: [false, []],
  });

  /**
   * @param {Device[]} inactiveDeviceList A list of inactive devices that can be added to a new experiment
   */
  availableDeviceList: Device[] = [];

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      this.experimentId = parseInt(params.id as string, 10);
    });

    this.currentUser = this.authenticationService.currentUserValue;

    if (this.experimentId !== 0 && this.experimentId !== null) {
      this.getExperimentFromApi(this.experimentId);
    }

    // Subscribe to value changes on the inactive and online device toggles
    this.deviceFilterForm
      .get('inactive-device-toggle')
      .valueChanges.subscribe(() => {
        this.getDevicesFromApi();
      });

    this.deviceFilterForm
      .get('online-device-toggle')
      .valueChanges.subscribe(() => {
        this.getDevicesFromApi();
      });
  }

  getExperimentFromApi(experimentId: number): void {
    this.error = null;
    this.loading = true;
    this.experimentService.getExperimentById(experimentId).subscribe({
      next: (response: Experiment) => {
        this.experiment = response;
        switch (this.experiment.experimentMode) {
          case ExperimentMode.Chemostat:
          case ExperimentMode.Turbidostat:
            this.requiredDeviceModules.add(Module.LiquidControl);
            break;
          case ExperimentMode.PHControl:
            this.requiredDeviceModules.add(Module.pH);
            this.requiredDeviceModules.add(Module.LiquidControl);
            break;
        }
        if (response.useOxygen) {
          this.requiredDeviceModules.add(Module.Oxygen);
        }
        if (response.useFluorescence) {
          this.requiredDeviceModules.add(Module.Fluorescence);
        }
        if (response.usePH) {
          this.requiredDeviceModules.add(Module.pH);
        }
        // Reset the selected device list
        this.selectedDeviceList = [];
        if (this.experiment.devices.length > 0) {
          this.experiment.devices.forEach((x) => {
            this.selectedDeviceList.push({
              deviceId: x.id,
              deviceName: x.name,
              deviceStatusId: x.deviceStatusId,
              calibrationList: [],
              calibrationId: x.calibration?.id,
              experimentId: this.experiment.id,
              oxygenCalibrationList: [],
              oxygenCalibrationId: x.oxygenCalibration?.id,
              pHCalibrationId: x.phCalibration?.id,
              pHCalibrationList: [],
              isOffline: x.isOffline,
              pumpTubeLength: x.pumpTubeLength,
            });
          });
          if (
            this.experiment.experimentStatusId === ExperimentStatus.DraftLocked
          ) {
            this.draftIsLocked = true;
          }
          this.loading = false;
          this.createCalibrationFormControls(this.selectedDeviceList);
        } else {
          this.getDevicesFromApi();
        }
      },
      error: (error: ErrorObject) => {
        this.error = error;
        this.loading = false;
        this.openModal('error');
      },
    });
  }

  getDevicesFromApi(): void {
    this.devicesLoading = true;
    this.deviceError = null;
    this.deviceService
      .getDevices(
        'Name',
        'desc',
        null,
        null,
        [...this.requiredDeviceModules],
        this.deviceFilterForm.get('inactive-device-toggle').value
          ? DeviceSetupStatus.Inactive
          : null,
        this.deviceFilterForm.get('online-device-toggle').value ? false : null,
      )
      .subscribe({
        next: (response: DeviceResponse) => {
          // Set up the inactive device list, removing devices in the selected device list
          this.availableDeviceList = [];
          this.deviceSelectField.options = [];
          response.deviceList.forEach((device: Device) => {
            const deviceEntryIndex = this.selectedDeviceList.findIndex(
              (x) => x.deviceId === device.id,
            );
            if (deviceEntryIndex === -1) {
              this.availableDeviceList.push(device);
              this.deviceSelectField.options.push({
                key: `device_${device.id}`,
                value: device.name,
              });
            } else {
              // Otherwise, make sure the device properties are up to date
              this.selectedDeviceList[deviceEntryIndex].deviceName =
                device.name;
              this.selectedDeviceList[deviceEntryIndex].deviceStatusId =
                device.deviceStatusId;
              this.selectedDeviceList[deviceEntryIndex].isOffline =
                device.isOffline;
            }
          });
          this.devicesLoading = false;
          if (
            this.experiment.experimentStatusId === ExperimentStatus.DraftLocked
          ) {
            this.experimentSetupForm.disable();
          }
        },
        error: (error: ErrorObject) => {
          this.deviceError = error;
        },
      });
  }

  createCalibrationFormControls(
    deviceSummaryList: DeviceCalibrationSummary[],
  ): void {
    deviceSummaryList.forEach((deviceSummary, index) => {
      const controlName = `device_${deviceSummary.deviceId.toString()}`;
      // create a form control for the od calibration select
      this.experimentSetupForm.addControl(
        controlName,
        new FormControl<string>(null, Validators.required),
      );
      // create a form control for the oxygen calibration select
      const oxyControlName = `device_${deviceSummary.deviceId.toString()}_oxy`;
      this.experimentSetupForm.addControl(
        oxyControlName,
        new FormControl<string>(
          null,
          this.requiredDeviceModules.has(Module.Oxygen)
            ? Validators.required
            : null,
        ),
      );
      // create a form control for the ph calibration select
      const phControlName = `device_${deviceSummary.deviceId.toString()}_ph`;
      this.experimentSetupForm.addControl(
        phControlName,
        new FormControl<string>(
          null,
          this.requiredDeviceModules.has(Module.pH)
            ? Validators.required
            : null,
        ),
      );
      // // Let's also initialise the ph calibration list for the device
      // deviceSummary.pHCalibrationList = [];
      // Once we're done, let's grab the available calibrations from the API
      if (deviceSummaryList.length - 1 === index) {
        this.getDeviceCalibrations(deviceSummaryList);
      }
    });
  }

  getDeviceCalibrations(deviceIdList: DeviceCalibrationSummary[]): void {
    this.odCalibrationLoading = true;
    this.odError = null;
    // before we fetch calibrations, make sure calibration list is constructed
    this.calibrationService
      // We only want calibrations that are currently loaded onto device and that have been completed
      .getCalibrationsByDeviceArray(deviceIdList, true, true)
      .subscribe({
        next: (response: Calibration[]) => {
          // For each calibration returned, find corresponding DeviceCalibrationSummary
          // and push to list
          response.forEach((calibration) => {
            // Find the index of the device related to the calibration
            const deviceEntryIndex = deviceIdList.findIndex(
              (x) => x.deviceId === calibration.deviceId,
            );
            // as long the device exists (we know it does but let's be super careful)
            if (deviceEntryIndex !== -1) {
              const mappedIndex = deviceIdList[deviceEntryIndex].calibrationList
                .map((c) => c.id)
                .findIndex((cId) => cId === calibration.id);
              // If the calibration isn't already in the list
              if (mappedIndex === -1) {
                // Add the calibration to the selected device's calibration list
                deviceIdList[deviceEntryIndex].calibrationList.push(
                  calibration,
                );
              }
            }
          });
          // If the device is already added to the experiment, we need to set the selected calibration
          // in the form control
          deviceIdList.forEach((device) => {
            if (device.calibrationId) {
              this.experimentSetupForm
                .get(`device_${device.deviceId}`)
                .setValue(device.calibrationId.toString());
            }
          });
          this.odCalibrationLoading = false;
          // Now we've got the calibrations for the selected devices, we can
          // fetch the oxygen calibrations if required
          if (this.requiredDeviceModules.has(Module.Oxygen)) {
            this.getDeviceOxygenCalibrations(deviceIdList);
          } else if (this.requiredDeviceModules.has(Module.pH)) {
            this.getDevicePhCalibrations(deviceIdList);
          } else {
            // Now we've got the response from the API for the selected device calibrations we
            // can make a call to the API for inactive devices available to be added to the experiment
            this.getDevicesFromApi();
          }
        },
        error: (error: ErrorObject) => {
          this.odError = error;
          this.odCalibrationLoading = false;
        },
      });
  }

  getDeviceOxygenCalibrations(deviceIdList: DeviceCalibrationSummary[]): void {
    this.oxygenCalibrationLoading = true;
    this.oxyError = null;
    this.calibrationService
      .getOxygenCalibrationsByDeviceArray(deviceIdList, true, true)
      .subscribe({
        next: (response: OxygenCalibration[]) => {
          // For each calibration returned, find corresponding DeviceCalibrationSummary
          // and push to list
          response.forEach((calibration) => {
            // Find the index of the device related to the calibration
            const deviceEntryIndex = deviceIdList.findIndex(
              (x) => x.deviceId === calibration.deviceId,
            );
            // as long the device exists (we know it does but let's be super careful)
            if (deviceEntryIndex !== -1) {
              const mappedIndex = deviceIdList[
                deviceEntryIndex
              ].oxygenCalibrationList
                .map((c) => c.id)
                .findIndex((cId) => cId === calibration.id);
              // If the calibration isn't already in the list
              if (mappedIndex === -1) {
                // Add the calibration to the selected device's calibration list
                deviceIdList[deviceEntryIndex].oxygenCalibrationList.push(
                  calibration,
                );
                // And add required to oxy form controls
                this.experimentSetupForm.controls[
                  `device_${deviceIdList[deviceEntryIndex].deviceId}_oxy`
                ].setValidators(Validators.required);
              }
            }
          });
          // If the device is already added to the experiment, we need to set the selected calibration
          // in the form control
          deviceIdList.forEach((device) => {
            if (device.oxygenCalibrationId) {
              this.experimentSetupForm
                .get(`device_${device.deviceId}_oxy`)
                .setValue(device.oxygenCalibrationId.toString());
            }
          });
          this.oxygenCalibrationLoading = false;
          // Now we've got the calibrations for the selected devices, we can
          // fetch the oxygen calibrations if required
          if (this.requiredDeviceModules.has(Module.pH)) {
            this.getDevicePhCalibrations(deviceIdList);
          } else {
            // Now we've got the response from the API for the selected device calibrations we
            // can make a call to the API for inactive devices available to be added to the experiment
            this.getDevicesFromApi();
          }
        },
        error: (error: ErrorObject) => {
          this.oxyError = error;
          this.oxygenCalibrationLoading = false;
        },
      });
  }

  getDevicePhCalibrations(deviceIdList: DeviceCalibrationSummary[]): void {
    this.pHError = null;
    this.pHCalibrationLoading = true;
    this.calibrationService
      .getPhCalibrationsByDeviceArray(deviceIdList, true, true)
      .subscribe({
        next: (response: PhCalibration[]) => {
          // For each calibration returned, find corresponding DeviceCalibrationSummary
          // and push to list
          response.forEach((calibration) => {
            // Find the index of the device related to the calibration
            const deviceEntryIndex = deviceIdList.findIndex(
              (x) => x.deviceId === calibration.deviceId,
            );
            // as long the device exists (we know it does but let's be super careful)
            if (deviceEntryIndex !== -1) {
              const mappedIndex = deviceIdList[
                deviceEntryIndex
              ].pHCalibrationList
                .map((c) => c.id)
                .findIndex((cId) => cId === calibration.id);
              // If the calibration isn't already in the list
              if (mappedIndex === -1) {
                // Add the calibration to the selected device's calibration list
                deviceIdList[deviceEntryIndex].pHCalibrationList.push(
                  calibration,
                );
                // And add required to ph form controls
                this.experimentSetupForm.controls[
                  `device_${deviceIdList[deviceEntryIndex].deviceId}_ph`
                ].setValidators(Validators.required);
              }
            }
          });
          // If the device is already added to the experiment, we need to set the selected calibration
          // in the form control
          deviceIdList.forEach((device) => {
            if (device.pHCalibrationId) {
              this.experimentSetupForm
                .get(`device_${device.deviceId}_ph`)
                .setValue(device.pHCalibrationId.toString());
            }
          });
          this.pHCalibrationLoading = false;
          this.getDevicesFromApi();
        },
        error: (error: ErrorObject) => {
          this.pHError = error;
          this.pHCalibrationLoading = false;
        },
      });
  }

  addDeviceToExperiment(): void {
    // add to selected device list, (pop from inactive and reset select?),
    // then call get device calibrations
    const selectedDeviceId =
      this.experimentSetupForm.controls['device-select'].value;
    const parsedDeviceId = parseInt(selectedDeviceId.replace('device_', ''));
    const deviceIndex = this.availableDeviceList.findIndex(
      (d) => d.id === parsedDeviceId,
    );
    const deviceCalibrationSummary: DeviceCalibrationSummary = {
      deviceId: parseInt(selectedDeviceId.replace('device_', '')),
      deviceName: this.availableDeviceList[deviceIndex].name,
      deviceStatusId: this.availableDeviceList[deviceIndex].deviceStatusId,
      isOffline: this.availableDeviceList[deviceIndex].isOffline,
      calibrationList: [],
      pumpTubeLength: this.availableDeviceList[deviceIndex].pumpTubeLength,
    };
    deviceCalibrationSummary.oxygenCalibrationList = [];
    deviceCalibrationSummary.pHCalibrationList = [];
    this.experimentSetupForm.controls['device-select'].setValue('');
    this.selectedDeviceList.push(deviceCalibrationSummary);
    this.createCalibrationFormControls([deviceCalibrationSummary]);
  }

  removeDeviceFromExperiment(deviceId: number): void {
    const deviceIndex = this.selectedDeviceList.findIndex(
      (x) => x.deviceId === deviceId,
    );
    if (deviceIndex !== -1) {
      this.selectedDeviceList.splice(deviceIndex, 1);
    }

    // remove corresponding calibration drop down control from form group
    this.experimentSetupForm.removeControl(`device_${deviceId}`);
    // If the oxygen module is required, remove the oxygen calibration drop down control from form group
    if (this.requiredDeviceModules.has(Module.Oxygen)) {
      this.experimentSetupForm.removeControl(`device_${deviceId}_oxy`);
    }
    // If the pH module is required, remove the pH calibration drop down control from form group
    if (this.requiredDeviceModules.has(Module.pH)) {
      this.experimentSetupForm.removeControl(`device_${deviceId}_ph`);
    }
    this.hasDevicesToRemove = true;
    // // And refresh the available device list
    this.getDevicesFromApi();
  }

  onSaveDevices(): void {
    // If form is already being submitted, exit
    if (this.isProcessing) {
      return;
    }

    this.isProcessing = true;
    this.error = null;

    this.selectedDeviceList.forEach((deviceSummary) => {
      deviceSummary.calibrationId = parseInt(
        this.experimentSetupForm.controls[`device_${deviceSummary.deviceId}`]
          .value,
      );

      // Think we should check if useOxygen and usePh are true before setting these values
      deviceSummary.oxygenCalibrationId = parseInt(
        this.experimentSetupForm.controls[
          `device_${deviceSummary.deviceId}_oxy`
        ].value || null,
      );
      if (Number.isNaN(deviceSummary.oxygenCalibrationId)) {
        deviceSummary.oxygenCalibrationId = null;
      }

      deviceSummary.pHCalibrationId = parseInt(
        this.experimentSetupForm.controls[`device_${deviceSummary.deviceId}_ph`]
          .value || null,
      );
      if (Number.isNaN(deviceSummary.pHCalibrationId)) {
        deviceSummary.pHCalibrationId = null;
      }
    });

    const addDevicesToDraftExperiment: ExperimentDeviceUpdate = {
      deviceBaseCalibrations: this.selectedDeviceList,
      deviceOxygenCalibrations: null,
      devicePHCalibrations: null,
    };

    if (this.experiment.useOxygen) {
      addDevicesToDraftExperiment.deviceOxygenCalibrations =
        this.selectedDeviceList;
    }

    if (this.experiment.usePH) {
      addDevicesToDraftExperiment.devicePHCalibrations =
        this.selectedDeviceList;
    }

    this.experimentService
      .addDevicesToDraftExperiment(
        this.experiment.id,
        addDevicesToDraftExperiment,
      )
      .subscribe({
        next: () => {
          this.isProcessing = false;
          this.openModal('success');
          this.hasDevicesToRemove = false;
          this.experimentSetupForm.markAsPristine();
        },
        error: (error: ErrorObject) => {
          this.error = error;
          this.isProcessing = false;
          this.openModal('error');
        },
      });
  }

  onDiscardChanges(): void {
    this.getExperimentFromApi(this.experimentId);
  }

  onLockDraft(): void {
    this.error = null;
    this.experimentService.lockDraftExperiment(this.experimentId).subscribe({
      next: () => {
        this.draftIsLocked = true;
        this.getExperimentFromApi(this.experimentId);
        this.openModal('lock-success');
      },
      error: (error: ErrorObject) => {
        this.error = error;
        this.openModal('error');
      },
    });
  }

  deleteDraft(): void {
    // If request has already been submitted, exit
    if (this.isProcessing || this.deleteIsProcessing) {
      return;
    }

    this.deleteError = null;
    this.deleteIsProcessing = true;

    this.experimentService.deleteExperimentById(this.experimentId).subscribe({
      next: () => {
        this.deleteIsProcessing = false;
        this.closeModal('delete-draft');
        this.openModal('delete-success');
      },
      error: (error: ErrorObject) => {
        this.deleteIsProcessing = false;
        this.deleteError = error;
        this.closeModal('delete-draft');
        this.openModal('delete-error');
      },
    });
  }

  openModal(modalId: string): void {
    this.modalService.open(modalId);
  }

  closeModal(modalId: string): void {
    this.modalService.close(modalId);
  }

  modalButtonClicked(buttonId: string): void {
    switch (buttonId) {
      case 'close-button':
        this.modalService.close('error');
        break;
      case 'cancel-button':
        this.allowNavigation.next(false);
        this.modalService.close('attention');
        break;
      case 'confirm-navigation':
        this.allowNavigation.next(true);
        this.modalService.close('attention');
        break;
      case 'close-sucess-button':
        this.modalService.close('success');
        break;
      case 'primary-lock':
        this.setUpDevices();
        break;
      case 'secondary-lock':
        this.goToDashboard();
        break;
      case 'delete-success-button':
        this.closeModal('delete-success');
        this.allowNavigation.next(true);
        void this.router.navigate(['/dashboard']);
        break;
    }
  }

  goBack(): void {
    void this.router.navigate([
      `/experiment/${this.experimentId}/set-parameters`,
    ]);
  }

  setUpDevices(): void {
    void this.router.navigate([
      `/experiment/${this.experimentId}/device-setup`,
    ]);
  }

  goToDashboard(): void {
    void this.router.navigate(['/dashboard']);
  }

  canExit(): Promise<boolean> | boolean {
    return this.isNavigationAllowed();
  }

  private isNavigationAllowed(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      if (this.experimentSetupForm.pristine) {
        resolve(true);
      } else {
        this.openModal('attention');
        this.allowNavigation.subscribe((isConfirmed) => resolve(isConfirmed));
      }
    });
  }
}
