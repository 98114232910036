export enum Buffer {
  'Custom' = 0,
  'pH 1.68' = 1,
  'pH 4.01' = 2,
  'pH 6.86' = 3,
  'pH 7.00' = 4,
  'pH 9.18' = 5,
  'pH 9.21' = 6,
  'pH 10.01' = 7,
  'pH 12.45' = 8,
}
