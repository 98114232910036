import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-basic-card',
  templateUrl: './basic-card.component.html',
  styleUrls: ['./basic-card.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BasicCardComponent {
  /**
   * @param {string} fieldName The name of the field to be displayed
   * @requires
   */
  @Input()
  fieldName: string;

  /**
   * @param {string} fieldReading The reading from the field, if relevant
   */
  @Input()
  fieldReading: string;
}
