import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-nav-button',
  templateUrl: './nav-button.component.html',
  styleUrls: ['./nav-button.component.scss'],
})
export class NavButtonComponent {
  /**
   * @param {string} label The button text
   * @requires
   */
  @Input()
  label: string | null;

  /**
   * @param {string} iconName The required icon
   * @requires
   */
  @Input()
  iconName: string | undefined;

  /**
   * @param {string} type The type of button required
   */
  @Input()
  type: 'button' | 'submit' | 'reset' = 'button';

  /**
   * @param {string} buttonId The button id
   * @requires
   */
  @Input()
  buttonId: string | undefined;

  /**
   * @param {boolean} isDisabled Is the button disabled
   */
  @Input()
  isDisabled = false;

  /**
   * @param {boolean} hideLabel Display label text
   */
  @Input()
  displayText = false;

  /**
   * Output event on click
   */
  @Output()
  buttonClicked: EventEmitter<void> = new EventEmitter<void>();

  clickButton = (): void => {
    this.buttonClicked.emit();
  };

  public get classes(): string[] {
    const disabled = this.isDisabled ? 'disabled' : '';
    const hasText = this.label && this.displayText ? 'icon-button--text' : '';
    return ['icon-button', hasText, disabled];
  }
}
