<div class="checkbox__container" [formGroup]="parentForm">
  <div class="device-title subtitle2">
    <span *ngFor="let title of checkboxTitles">{{ title }}</span>
  </div>
  <div class="checkbox-options__container">
    <label
      *ngFor="let option of field.options; let i = index"
      [for]="option.key"
      [ngClass]="classes"
    >
      <input
        type="checkbox"
        [id]="option.key"
        [attr.name]="option.key"
        class="body1"
        (change)="updateValue($event)"
        [attr.checked]="initialiseCheckboxes(i)"
        [disabled]="isDisabled"
      />
      <div class="checkmark--background">
        <div class="checkmark">
          <mat-icon>done</mat-icon>
        </div>
      </div>
      {{ option.value }}
    </label>
  </div>
  <ul *ngIf="hasErrors">
    <li
      class="underlined-form-field-error caption error"
      *ngFor="let error of errorsList"
    >
      {{ error }}
    </li>
  </ul>
</div>
