import { Component } from '@angular/core';
import { IconNames } from 'src/app/_components/icons/icon-names';
import { RouterLink } from '@angular/router';
import { ButtonComponent } from '../../_components/buttons/button/button.component';

@Component({
  selector: 'app-reset-sent',
  templateUrl: './reset-sent.component.html',
  styleUrls: ['./reset-sent.component.scss'],
  standalone: true,
  imports: [RouterLink, ButtonComponent],
})
export class ResetSentComponent {
  logo = IconNames.LogoColour;
}
