import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ChangeDetectionStrategy,
  inject,
} from '@angular/core';
import {
  FormBuilder,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { FormBase } from 'src/app/_models/form-base';
import { ApprovalUpdate } from 'src/app/_models/requests';
import { ModalService } from 'src/app/_services/modal.service';
import { ButtonComponent } from '../../buttons/button/button.component';
import { UnderlinedTextareaFieldComponent } from '../../forms/underlined-textarea-field/underlined-textarea-field.component';

@Component({
  selector: 'app-provide-info-modal',
  templateUrl: './provide-info-modal.component.html',
  styleUrls: ['./provide-info-modal.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    UnderlinedTextareaFieldComponent,
    ButtonComponent,
  ],
})
export class ProvideInfoModalComponent implements OnInit, OnDestroy {
  private readonly modalService = inject(ModalService);
  private readonly fb = inject(FormBuilder);
  private readonly elementRef = inject<ElementRef<HTMLElement>>(ElementRef);

  private readonly element: HTMLElement;
  isProcessing = false;

  infoField = new FormBase({
    key: 'infoText',
    label: 'Reason for request rejection',
    type: 'text',
    placeholder: 'Enter information here...',
    disabled: false,
    required: true,
    value: '',
    options: [],
  });

  infoForm = this.fb.group({
    infoText: ['', [Validators.required]],
  });

  /**
   * @param {string} id What is the id of the modal container?
   * @required
   */
  @Input()
  id: string;

  /**
   * @param {ApprovalUpdate} approvalRequest What is the approval request object?
   */
  @Input()
  approvalRequest: ApprovalUpdate;

  /**
   * Output event for clicked button on modal
   */
  // tslint:disable-next-line: no-output-on-prefix
  @Output()
  modalButtonClicked: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  rejectionConfirmed: EventEmitter<ApprovalUpdate> =
    new EventEmitter<ApprovalUpdate>();

  constructor() {
    this.element = this.elementRef.nativeElement;
  }

  ngOnInit(): void {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const modal = this;

    // Close modal on background click - only if not processing a rejection
    if (!this.isProcessing) {
      this.element.addEventListener('click', (e: Event) => {
        const target = e.target as HTMLElement;
        if (target.className === 'modal--container') {
          modal.close();
        }
      });
    }
    // add self (this modal instance) to the modal service so it's accessible from controllers
    this.modalService.add(this);
    document.body.appendChild(this.element);
  }

  buttonEventHandler(): void {
    this.approvalRequest.requestInfo = this.infoForm.value.infoText;
    this.rejectionConfirmed.emit(this.approvalRequest);
    this.isProcessing = true;
  }

  // open modal
  open(): void {
    this.element.style.display = 'block';
  }

  // close modal
  close(): void {
    // Reset valdiation text value and close modal
    this.infoForm.reset();
    this.approvalRequest.id = null;
    this.approvalRequest.requestInfo = '';
    this.element.style.display = 'none';
  }

  // Remove self from modal service when component is destroyed
  ngOnDestroy(): void {
    this.modalService.remove(this.id);
    this.element.remove();
  }
}
