<section>
  <div class="header--container">
    <div class="title--container">
      <div class="status">
        <span class="subtitle2">{{ device.name }}</span>
        <mat-icon *ngIf="!device.isOffline" class="online">wifi</mat-icon>
        <mat-icon *ngIf="device.isOffline" class="offline">wifi_off</mat-icon>
      </div>
      <span class="body2">
        API call last updated:
        {{ lastUpdateTime | date : "HH:mm:ss, dd/MM/yy" }}
      </span>
    </div>
    <div class="button--container">
      <app-basic-card
        fieldName="Ambient Temperature"
        [fieldReading]="experiment.ambientTemperature + '&deg;C'"
      ></app-basic-card>
      <app-basic-card
        fieldName="Optical Density Calibration"
        [fieldReading]="device.calibration?.name"
      ></app-basic-card>
      <app-basic-card
        *ngIf="device.oxygenCalibration"
        fieldName="Oxygen Calibration"
        [fieldReading]="device.oxygenCalibration.name"
      ></app-basic-card>
      <app-basic-card
        *ngIf="device.phCalibration"
        fieldName="pH Calibration"
        [fieldReading]="device.phCalibration.name"
      ></app-basic-card>
      <app-icon-button
        [label]="
          device?.deviceStatusId === DeviceStatus.Paused
            ? 'restart device'
            : 'pause device'
        "
        buttonId="pause-btn"
        buttonStyle="pause"
        iconName="pause"
        (buttonClicked)="clickButton(device.id)"
      >
      </app-icon-button>
    </div>
  </div>
  <div
    *ngIf="device.lastRead?.length > 0; else noData"
    class="cards--container"
  >
    <app-small-data-card
      *ngFor="let reading of device.lastRead"
      [dataCategory]="selectedDataCategory"
      [dataReading]="reading.value"
      [dataReadingTime]="reading.time"
      [temperatureReading]="reading.temperatureValue"
      [flask]="reading.name"
      [deviceStatus]="device.deviceStatusId"
      [temperature]="experiment.ambientTemperature"
      [experiment]="experiment"
    ></app-small-data-card>
  </div>
  <ng-template #noData>
    <p class="body1 error">No data received</p>
  </ng-template>
</section>
