import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy, inject } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ChangelogEntry } from '../_models/changelog';
import {
  ChangelogResponse,
  ExperimentResponse,
} from 'src/app/_models/api-responses';
import { Experiment } from '../_models/experiment';
import {
  ExperimentDeviceUpdate,
  ExperimentUpdate,
} from '../_models/experiment-update';
import { CreateExperiment } from '../_models/experiment-create';
import { PaginationData } from '../_models/pagination-data.model';
import { ExperimentStatus } from '../_models/experiment-status';
import { httpOptions } from '../_helpers/utils';
import { AuthenticationService } from './authentication.service';
import { ExperimentMode } from '../_models/experiment-mode';

@Injectable({
  providedIn: 'root',
})
export class ExperimentService implements OnDestroy {
  private readonly http = inject(HttpClient);
  private readonly authenticationService = inject(AuthenticationService);

  httpOptions = httpOptions;
  currentLaboratoryId: number;

  // Pagination and sort/filter observables for dashboard index view
  private readonly tableSort$ = new BehaviorSubject<{
    sortBy: string;
    sortDirection: string;
  }>(null);
  private readonly tableFilter$ = new BehaviorSubject<string[]>([]);
  private readonly pageNumber$ = new BehaviorSubject<number>(null);
  private readonly pageSize$ = new BehaviorSubject<number>(null);

  private readonly createExperimentFormSubject$ =
    new BehaviorSubject<CreateExperiment>(null);

  private readonly destroy$ = new Subject<void>();

  constructor() {
    this.authenticationService.selectedLab
      .pipe(takeUntil(this.destroy$))
      .subscribe((x) => {
        this.currentLaboratoryId = x?.laboratoryId;
      });
  }

  onUpdateSort = (sort: { sortBy: string; sortDirection: string }): void => {
    this.tableSort$.next(sort);
  };

  onUpdatePageNumber = (pageNumber: number): void => {
    this.pageNumber$.next(pageNumber);
  };

  onUpdatePageSize = (pageSize: number): void => {
    this.pageSize$.next(pageSize);
  };

  onUpdateCreateExperimentFormCache = (
    createExperimentFormCache: CreateExperiment,
  ): void => {
    this.createExperimentFormSubject$.next(createExperimentFormCache);
  };

  public get sort(): { sortBy: string; sortDirection: string } {
    return this.tableSort$.value;
  }

  public get paginationCache(): { pageNumber: number; pageSize: number } {
    if (this.pageNumber$.value !== null && this.pageSize$.value !== null) {
      return {
        pageNumber: this.pageNumber$.value,
        pageSize: this.pageSize$.value,
      };
    } else {
      return null;
    }
  }

  public get filter(): string[] {
    return this.tableFilter$.value;
  }

  public get createExperimentFormCache(): CreateExperiment {
    return this.createExperimentFormSubject$.value;
  }

  // HTTP GET /EXPERIMENT
  getExperimentById(id: number): Observable<Experiment> {
    return this.http
      .get<Experiment>(`${environment.apiUrl}/experiments/${id}`)
      .pipe(
        map((response) => {
          return response;
        }),
      );
  }

  // HTTP GET /EXPERIMENT
  getExperiments(
    laboratoryId: number,
    sortBy = 'CreatedAt',
    sortOrder = 'desc',
    pageSize = 5,
    pageNumber = 1,
    experimentStatusId: ExperimentStatus = null,
    experimentModeId: ExperimentMode = null,
    search: string = null,
  ): Observable<ExperimentResponse> {
    let url = `${environment.apiUrl}/experiments?LaboratoryId=${laboratoryId}&`;
    url =
      url +
      `OrderBy=${sortBy}&OrderByDirection=${sortOrder}&PageSize=${pageSize}&PageNumber=${pageNumber}`;
    if (experimentStatusId) {
      url = url + `&ExperimentStatus=${experimentStatusId}`;
    }
    if (experimentModeId) {
      url = url + `&ExperimentMode=${experimentModeId}`;
    }
    if (search) {
      url = url + `&search=${search}`;
    }
    return this.http.get<Experiment[]>(url, this.httpOptions).pipe(
      map((response) => {
        const experimentList: Experiment[] = [];
        // Set pagination data
        const paginationData = JSON.parse(
          response.headers.get('X-Pagination'),
        ) as PaginationData;
        if (response.status === 200) {
          response.body.forEach((e: Experiment) => {
            experimentList.push(e);
          });
        }
        return {
          paginationData,
          experimentList,
        };
      }),
    );
  }

  // HTTP PATCH /EXPERIMENT
  updateDraftExperiment(
    updatedExperiment: ExperimentUpdate,
  ): Observable<Experiment> {
    updatedExperiment.laboratoryId = this.currentLaboratoryId;
    return this.http
      .patch<Experiment>(
        `${environment.apiUrl}/experiments/${updatedExperiment.id}/draft-params`,
        updatedExperiment,
      )
      .pipe(
        map((response: Experiment) => {
          return response;
        }),
      );
  }

  addDevicesToDraftExperiment(
    experimentId: number,
    deviceCalibrations: ExperimentDeviceUpdate,
  ): Observable<Experiment> {
    return this.http
      .patch<Experiment>(
        `${environment.apiUrl}/experiments/${experimentId}/draft-devices`,
        deviceCalibrations,
      )
      .pipe(
        map((response: Experiment) => {
          // TODO: Check if this is necessary
          return response;
        }),
      );
  }

  lockDraftExperiment(experimentId: number): Observable<Experiment> {
    return this.http
      .patch<Experiment>(
        `${environment.apiUrl}/experiments/${experimentId}/lock-draft`,
        '',
      )
      .pipe(
        map((response: Experiment) => {
          return response;
        }),
      );
  }

  // HTTP POST /EXPERIMENT
  startExperiment(experimentId: number): Observable<void> {
    return this.http
      .patch<string>(
        `${environment.apiUrl}/experiments/${experimentId}/start-experiment`,
        '',
      )
      .pipe(
        map(() => {
          return;
        }),
      );
  }

  // HTTP PATCH /EXPERIMENT
  updateActiveExperiment(
    updatedExperiment: ExperimentUpdate,
  ): Observable<Experiment> {
    updatedExperiment.laboratoryId = this.currentLaboratoryId;
    return this.http
      .patch<Experiment>(
        `${environment.apiUrl}/experiments/${updatedExperiment.id}/active`,
        updatedExperiment,
      )
      .pipe(
        map((response: Experiment) => {
          return response;
        }),
      );
  }

  // HTTP POST /EXPERIMENT
  createExperiment(newExperiment: CreateExperiment): Observable<Experiment> {
    newExperiment.laboratoryId = this.currentLaboratoryId;
    return this.http
      .post<Experiment>(`${environment.apiUrl}/experiments`, newExperiment)
      .pipe(
        map((response: Experiment) => {
          return response;
        }),
      );
  }

  // HTTP PUT /experiment/experimentId?pauseOrResume=true
  pauseDevicesOnExperiment(
    experimentId: string,
    pauseOrResume: string,
  ): Observable<string> {
    return this.http
      .put<string>(
        `${environment.apiUrl}/experiments/${experimentId}/pause?pauseOrResume=${pauseOrResume}`,
        '',
      )
      .pipe(
        map((response: string) => {
          return response;
        }),
      );
  }

  // HTTP GET /CHANGELOG
  getExperimentChangelog(
    id: number,
    sortBy = 'CreatedAt',
    sortOrder = 'asc',
    pageSize = 10,
    pageNumber = 1,
  ): Observable<ChangelogResponse> {
    return this.http
      .get<
        ChangelogEntry[]
      >(`${environment.apiUrl}/change-logs?OrderBy=${sortBy}&OrderByDirection=${sortOrder}&PageSize=${pageSize}&PageNumber=${pageNumber}&ExperimentId=${id}&LaboratoryId=${this.currentLaboratoryId}`, this.httpOptions)
      .pipe(
        map((response) => {
          // Set pagination data
          const paginationData = JSON.parse(
            response.headers.get('X-Pagination'),
          ) as PaginationData;

          return { changelogList: response.body, paginationData };
        }),
      );
  }

  // DELETE /experiment - TODO check URL
  deleteExperimentById(id: number): Observable<string> {
    return this.http
      .delete<string>(`${environment.apiUrl}/experiments/${id}`)
      .pipe(
        map((response: string) => {
          return response;
        }),
      );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
