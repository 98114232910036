/* eslint-disable brace-style */
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { TabOptions } from 'src/app/_models/tab-options';
import { Component, OnInit, OnDestroy, inject } from '@angular/core';
import { Device } from '../_models/device';
import { Experiment } from '../_models/experiment';
import { DeviceService } from '../_services/device.service';
import { ExperimentService } from '../_services/experiment.service';
import { ErrorObject } from '../_models/error';
import { FormArray, FormBuilder, FormControl } from '@angular/forms';
import { FormBase } from '../_models/form-base';
import { calculateTimeLeft, clearFormArray } from '../_helpers/utils';
import { interval, Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { DeviceCalibrationSummary } from '../_models/device-calibration-summary';
import { DeviceSetupStatus } from '../_models/device-setup-status';
import { ExperimentStatus } from '../_models/experiment-status';
import { TimeComponent } from '../_models/time-component';
import { Module } from '../_models/module-enum';
import { AuthenticationService } from '../_services/authentication.service';
import { User } from '../_models/user';
import { Role } from '../_models/role';
import { Laboratory } from '../_models/laboratory';
import { ErrorLogService } from '../_services/error-log.service';
import { ErrorLog } from '../_models/error-log';
import { ModalService } from '../_services/modal.service';
import { ModalComponent } from '../_components/modals/modal.component';
import { CheckboxGroupNoLabelComponent } from '../_components/selection-controls/checkbox-group-no-label/checkbox-group-no-label.component';
import { RoleCheckPipe } from '../_helpers/role-check.pipe';
import { FilterModalComponent } from '../_components/modals/filter-modal/filter-modal.component';
import { LoadingSpinnerComponent } from '../_components/loading-spinner/loading-spinner.component';
import { DropdownFormFieldComponent } from '../_components/forms/dropdown-form-field/dropdown-form-field.component';
import { ButtonComponent } from '../_components/buttons/button/button.component';
import { StatusIndicatorComponent } from '../_components/chips/status-indicator/status-indicator.component';
import { ActiveModulesComponent } from '../_components/chips/active-modules/active-modules.component';
import { MatTooltip } from '@angular/material/tooltip';
import { MatIcon } from '@angular/material/icon';
import { MatMiniFabButton } from '@angular/material/button';
import { IconButtonComponent } from '../_components/buttons/icon-button/icon-button.component';
import { SearchFieldComponent } from '../_components/forms/search-field/search-field.component';
import { OptionTabToggleComponent } from '../_components/selection-controls/option-tab-toggle/option-tab-toggle.component';
import { AsyncPipe, DecimalPipe, DatePipe } from '@angular/common';
import { MatIconButtonComponent } from '../_components/buttons/mat-icon-button/mat-icon-button.component';
import { ExperimentMode } from '../_models/experiment-mode';
import { FormatCamelCasePipe } from '../_helpers/format-camel-case.pipe';

@Component({
  selector: 'app-dashboard',
  template: '',
  standalone: true,
})
export class DashboardComponent implements OnInit {
  protected route = inject(ActivatedRoute);
  protected router = inject(Router);
  protected formBuilder = inject(FormBuilder);
  protected authenticationService = inject(AuthenticationService);

  //RoutingdevicesPerPageField
  navOptions: TabOptions[];
  // Permissions
  laboratory: Laboratory;
  currentUser: User;
  hasCreateAccess = false;
  // Make role enum available in the template
  Role = Role;

  ngOnInit(): void {
    this.navOptions = [
      {
        value: 'devices',
        labelText: 'Devices',
        link: '/dashboard/devices',
      },
      {
        value: 'experiments',
        labelText: 'Experiments',
        link: '/dashboard/experiments',
      },
      {
        value: 'error-logs',
        labelText: 'Error Logs',
        link: '/dashboard/error-logs',
      },
    ];
    this.currentUser = this.authenticationService.currentUserValue;
  }
}

@Component({
  selector: 'app-devices-component',
  templateUrl: './devices/devices.component.html',
  styleUrls: ['./devices/devices.component.scss'],
  standalone: true,
  imports: [
    OptionTabToggleComponent,
    SearchFieldComponent,
    IconButtonComponent,
    MatMiniFabButton,
    MatIcon,
    MatTooltip,
    ActiveModulesComponent,
    StatusIndicatorComponent,
    ButtonComponent,
    RouterLink,
    DropdownFormFieldComponent,
    LoadingSpinnerComponent,
    FilterModalComponent,
    AsyncPipe,
    DecimalPipe,
    RoleCheckPipe,
    MatIconButtonComponent,
    MatIcon,
  ],
})
export class DevicesComponent
  extends DashboardComponent
  implements OnInit, OnDestroy
{
  private readonly deviceService = inject(DeviceService);
  private readonly modalService = inject(ModalService);
  protected route = inject(ActivatedRoute);
  protected router = inject(Router);
  protected formBuilder = inject(FormBuilder);
  protected authenticationService = inject(AuthenticationService);

  private readonly unsubscribe$ = new Subject<void>();

  // Countdown controls for time left display
  currentTime$: Observable<Date>;
  // Makes the DeviceSetupStatus enum available in the template
  DeviceSetupStatus = DeviceSetupStatus;
  // Makes the ExperimentStatus enum available in the template
  ExperimentStatus = ExperimentStatus;

  // Network controls
  error: ErrorObject = null;
  loading = false;

  // Table data
  devices: Device[] = null;

  // Table control variables
  currentFilters = [];

  currentSort: {
    sortBy: string;
    sortDirection: string;
  } = {
    sortBy: 'DeviceStatusId',
    sortDirection: 'asc',
  };
  selectedDeviceList: DeviceCalibrationSummary[] = [];
  selectedModuleList: Module[] = [];
  selectedDeviceStatus: DeviceSetupStatus = null;

  // Pagination
  pageRequested = 1;
  pageSize = 5;
  totalCount = 0;
  totalPages = 0;
  currentPage = 1;
  hasNext = false;
  hasPrevious = false;

  deviceModuleFilters = new FormBase({
    key: 'moduleFilters',
    label: 'Filter by installed modules:',
    type: 'checkbox',
    placeholder: '',
    disabled: false,
    required: false,
    value: '',
    options: [
      { key: Module.Fluorescence, value: 'Fluorescence' },
      { key: Module.LiquidControl, value: 'Liquid Control' },
      { key: Module.Oxygen, value: 'Oxygen' },
      { key: Module.pH, value: 'pH' },
      { key: Module.AirControl, value: 'Air Control' },
    ],
  });

  deviceStatusFilters = new FormBase({
    key: 'statusFilters',
    label: 'Device status',
    type: 'dropdown',
    placeholder: 'Select...',
    disabled: false,
    required: false,
    value: null,
    options: [
      { key: null, value: 'All' },
      { key: DeviceSetupStatus.Inactive, value: 'Inactive' },
      { key: DeviceSetupStatus.Awaiting, value: 'Awaiting' },
      { key: DeviceSetupStatus['Flasks Inserted'], value: 'Flasks Inserted' },
      { key: DeviceSetupStatus.Active, value: 'Active' },
      { key: DeviceSetupStatus.Paused, value: 'Paused' },
      {
        key: DeviceSetupStatus['Active OD Calibration'],
        value: 'Active OD calibration',
      },
      {
        key: DeviceSetupStatus['Active Oxygen Calibration'],
        value: 'Active O2 calibration',
      },
      {
        key: DeviceSetupStatus['Active pH Calibration'],
        value: 'Active pH calibration',
      },
    ],
  });

  devicesPerPageField = new FormBase({
    key: 'devicesPerPage',
    label: 'Page Size',
    type: 'dropdown',
    placeholder: '',
    disabled: false,
    required: false,
    value: this.pageSize,
    options: [
      { key: 5, value: '5' },
      { key: 10, value: '10' },
      { key: 25, value: '25' },
      { key: 50, value: '50' },
      { key: 100, value: '100' },
    ],
  });

  searchField = new FormBase<boolean>({
    key: 'search',
    label: 'Search',
    type: 'text',
    placeholder: 'Search...',
    disabled: false,
    required: false,
    value: null,
    options: [],
  });

  deviceOfflineField = new FormBase<boolean>({
    key: 'deviceOffline',
    label: 'Online devices only',
    type: 'toggle',
    placeholder: '',
    disabled: false,
    required: false,
    value: null,
    options: [],
  });

  includeDeletedDevicesField = new FormBase<boolean>({
    key: 'includeDeletedDevices',
    label: 'Deleted devices',
    type: 'toggle',
    placeholder: '',
    disabled: false,
    required: false,
    value: null,
    options: [],
  });

  deviceTableControls = this.formBuilder.group({
    [this.deviceModuleFilters.key]: new FormArray([]),
    [this.deviceStatusFilters.key]: new FormControl(
      this.deviceStatusFilters.value,
    ),
    [this.searchField.key]: new FormControl(this.searchField.value),
    [this.devicesPerPageField.key]: new FormControl(this.pageSize),
    [this.deviceOfflineField.key]: new FormControl(
      this.deviceOfflineField.value,
    ),
    [this.includeDeletedDevicesField.key]: new FormControl(
      this.includeDeletedDevicesField.value,
    ),
  });

  constructor() {
    super();
    this.currentTime$ = interval(1000).pipe(map(() => new Date()));
  }

  ngOnInit(): void {
    super.ngOnInit();

    // Check for saved sort, filter and pagination values
    if (this.deviceService.filter !== null) {
      this.currentFilters = this.deviceService.filter;
    }

    if (this.deviceService.sort !== null) {
      this.currentSort = this.deviceService.sort;
    }

    if (this.deviceService.paginationCache !== null) {
      this.pageSize = this.deviceService.paginationCache.pageSize;
      this.pageRequested = this.deviceService.paginationCache.pageNumber;
      this.deviceTableControls.patchValue({
        [this.devicesPerPageField.key]: [this.pageSize],
      });
    }

    this.initialiseCheckboxes();

    this.laboratory = this.authenticationService.selectedLaboratory;

    if (this.laboratory) {
      this.getDevices();
    }
    // Subscribe to the value change on lab id
    this.authenticationService.selectedLab
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((laboratory) => {
        const ownLab = this.currentUser.laboratories.find(
          (x) => x.laboratoryId === laboratory?.laboratoryId,
        );
        const fetchDevices =
          this.currentUser.role === Role.Super_User && !ownLab?.isOwnLab
            ? false
            : true;
        if (this.laboratory?.laboratoryId !== laboratory?.laboratoryId) {
          this.laboratory = laboratory;
          // When the lab id changes, make a fresh call to get the devices and experiments
          if (this.laboratory !== null && fetchDevices) {
            this.getDevices();
          }
        }
      });

    // Subscribe to the value change on devices per page dropdown
    this.deviceTableControls
      .get(this.devicesPerPageField.key)
      .valueChanges.subscribe((numOfDevices) => {
        this.pageSize = numOfDevices as number;
        // Now also reset page number request to one as we are changing the pagination range
        this.pageRequested = 1;
        this.getDevices();
      });

    // Subscribe to the value change on device status dropdown
    this.deviceTableControls
      .get(this.deviceStatusFilters.key)
      .valueChanges.subscribe((status) => {
        this.selectedDeviceStatus = status as DeviceSetupStatus;
      });
  }

  ngOnDestroy(): void {
    // Emit a value to unsubscribe from all subscriptions
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  get checkboxesFormArray(): FormArray {
    return this.deviceTableControls.get('moduleFilters') as FormArray;
  }

  closeModal(modal: string): void {
    this.modalService.close(modal);
  }

  openModal(modal: string): void {
    this.modalService.open(modal);
  }

  modalButtonClicked(buttonId: string): void {
    switch (buttonId) {
      case 'reset-button':
        this.resetForm();
        break;
      case 'continue-button':
        this.getDevices();
        this.closeModal('filter');
        break;
    }
  }

  dashboardButtonClicked(buttonId: string): void {
    switch (buttonId) {
      case 'add-lab':
        void this.router.navigate(['/laboratories/add-lab'], {
          state: { data: this.currentUser.organisationId },
        });
        break;
      case 'join-lab':
        void this.router.navigate(['/laboratories/view-lab']);
        break;
      case 'add-users':
        void this.router.navigate(['/user/add-user']);
        break;
      case 'add-device':
        void this.router.navigate(['/device/create-device']);
        break;
    }
  }

  initialiseCheckboxes(): void {
    // Reset checkbox form array
    clearFormArray(this.checkboxesFormArray);
    this.deviceModuleFilters.options.forEach(() => {
      const control = this.formBuilder.control(false);
      this.checkboxesFormArray.push(control);
    });
  }

  resetCheckboxes(): void {
    const resetValues = this.checkboxesFormArray.controls.map(() => false);
    this.checkboxesFormArray.patchValue(resetValues);
    this.deviceTableControls.markAsPristine();
    this.deviceTableControls.markAsUntouched();
    this.getDevices();
  }

  resetForm(): void {
    this.selectedModuleList = [];
    this.deviceTableControls.patchValue({
      [this.includeDeletedDevicesField.key]: null,
      [this.deviceOfflineField.key]: null,
      [this.searchField.key]: null,
      [this.deviceStatusFilters.key]: null,
    });
    this.resetCheckboxes();
  }

  handleSearch(action: string): void {
    switch (action) {
      case 'search':
        this.getDevices();
        break;
      case 'clear-search':
        this.deviceTableControls.patchValue({ search: null });
        this.getDevices();
        break;
    }
  }

  moduleCheckboxClicked(event: Event): void {
    const target = event.target as HTMLFormElement;
    const module = parseInt(target.name) as Module;
    if (target.checked === true) {
      this.selectedModuleList.push(module);
    }
    if (target.checked === false) {
      this.selectedModuleList = this.selectedModuleList.filter(
        (x) => x !== module,
      );
    }
  }

  getDevices(): void {
    this.loading = true;
    // reset error object and then call device index endpoint
    this.error = null;

    const isOffline = this.deviceTableControls.controls['deviceOffline']
      .value as boolean;

    const includeDeleted = this.deviceTableControls.controls[
      'includeDeletedDevices'
    ].value as boolean;

    const searchValue = this.deviceTableControls.controls['search']
      .value as string;

    this.deviceService
      .getDevices(
        this.currentSort.sortBy,
        this.currentSort.sortDirection,
        this.pageSize,
        this.pageRequested,
        this.selectedModuleList,
        this.selectedDeviceStatus,
        isOffline ? false : null,
        includeDeleted ? true : null,
        typeof searchValue === 'string' ? searchValue : null,
      )
      .subscribe({
        next: (response) => {
          // Set pagination vars from response headers
          this.currentPage = response.paginationData.CurrentPage;
          this.pageSize = response.paginationData.PageSize;
          this.deviceService.onUpdatePageNumber(this.currentPage);
          this.deviceService.onUpdatePageSize(this.pageSize);
          this.totalPages = response.paginationData.TotalPages;
          this.hasNext = response.paginationData.HasNext;
          this.hasPrevious = response.paginationData.HasPrevious;
          this.loading = false;
          // Set device list from response
          this.devices = response.deviceList;
        },
        error: (error: ErrorObject) => {
          this.error = error;
          this.loading = false;
          this.devices = [];
        },
      });
  }

  getTimeLeft(startTime: string, duration: number): TimeComponent {
    return calculateTimeLeft(startTime, duration);
  }

  getPrevious(): void {
    this.pageRequested = this.currentPage - 1;
    this.getDevices();
  }

  getNext(): void {
    this.pageRequested = this.currentPage + 1;
    this.getDevices();
  }

  onSort(sortTerm: string): void {
    if (this.currentSort.sortBy === sortTerm) {
      if (this.currentSort.sortDirection === 'asc') {
        this.currentSort.sortDirection = 'desc';
      } else {
        this.currentSort.sortDirection = 'asc';
      }
    } else {
      this.currentSort.sortBy = sortTerm;
      this.currentSort.sortDirection = 'asc';
    }
    this.getDevices();
  }

  onNewCalibration(): void {
    void this.router.navigate([`calibration/create-calibration`]);
  }

  onNewExperiment(): void {
    // route to new experiment page with selected device summary list
    void this.router.navigate(['experiment/0/set-parameters']);
  }

  onViewExperiment(experimentId: string): void {
    void this.router.navigate([`experiment/${experimentId}/display`]);
  }

  onViewDeviceDetails(deviceId: string): void {
    void this.router.navigate([`device/${deviceId}/details`]);
  }
}

@Component({
  selector: 'app-experiments-component',
  templateUrl: './experiments/experiments.component.html',
  styleUrls: ['./experiments/experiments.component.scss'],
  standalone: true,
  imports: [
    OptionTabToggleComponent,
    SearchFieldComponent,
    MatIcon,
    MatTooltip,
    StatusIndicatorComponent,
    ButtonComponent,
    RouterLink,
    DropdownFormFieldComponent,
    IconButtonComponent,
    LoadingSpinnerComponent,
    AsyncPipe,
    DecimalPipe,
    DatePipe,
    RoleCheckPipe,
    FilterModalComponent,
    ActiveModulesComponent,
    MatMiniFabButton,
    FormatCamelCasePipe,
  ],
})
export class ExperimentsComponent
  extends DashboardComponent
  implements OnInit, OnDestroy
{
  private readonly experimentService = inject(ExperimentService);
  protected route = inject(ActivatedRoute);
  protected formBuilder = inject(FormBuilder);
  protected router = inject(Router);
  protected authenticationService = inject(AuthenticationService);
  protected modalService = inject(ModalService);

  // Countdown controls for time left display
  currentTime$: Observable<Date>;
  // Makes the ExperimentStatus enum available in the template
  ExperimentStatus = ExperimentStatus;
  ExperimentMode = ExperimentMode;
  private readonly unsubscribe$ = new Subject<void>();

  // Network controls
  error: ErrorObject = null;
  loading = false;

  // Table data
  devices: Device[] = null;
  experiments: Experiment[] = [];
  activeModules = null;

  // Pagination
  pageRequested = 1;
  pageSize = 5;
  totalCount = 0;
  totalPages = 0;
  currentPage = 1;
  hasNext = false;
  hasPrevious = false;

  // Table control variables
  currentFilters = [];
  currentSort: {
    sortBy: string;
    sortDirection: string;
  } = {
    sortBy: 'CreatedAt',
    sortDirection: 'desc',
  };
  experimentStatusFilter = null;
  experimentModeFilter = null;

  experimentModeFilters = new FormBase({
    key: 'experimentModeFilters',
    label: 'Experiment mode',
    type: 'dropdown',
    placeholder: 'Select...',
    disabled: false,
    required: false,
    value: null,
    options: [
      { key: null, value: 'All' },
      { key: ExperimentMode.BatchCulture, value: 'Batch Culture' },
      { key: ExperimentMode.Chemostat, value: 'Chemostat' },
      { key: ExperimentMode.PHControl, value: 'pH Control' },
      { key: ExperimentMode.Turbidostat, value: 'Turbidostat' },
    ],
  });

  experimentStatusFilters = new FormBase({
    key: 'statusFilters',
    label: 'Experiment status',
    type: 'dropdown',
    placeholder: 'Select...',
    disabled: false,
    required: false,
    value: null,
    options: [
      { key: null, value: 'All' },
      { key: ExperimentStatus.Active, value: 'Active' },
      { key: ExperimentStatus.DraftLocked, value: 'Draft Locked' },
      { key: ExperimentStatus.Draft, value: 'Draft' },
      { key: ExperimentStatus.Paused, value: 'Paused' },
      { key: ExperimentStatus.Complete, value: 'Complete' },
    ],
  });

  experimentsPerPageField = new FormBase({
    key: 'experimentsPerPage',
    label: 'Page Size',
    type: 'dropdown',
    placeholder: '',
    disabled: false,
    required: false,
    value: this.pageSize,
    options: [
      { key: 5, value: '5' },
      { key: 10, value: '10' },
      { key: 25, value: '25' },
      { key: 50, value: '50' },
      { key: 100, value: '100' },
    ],
  });

  searchField = new FormBase<boolean>({
    key: 'search',
    label: 'Search',
    type: 'text',
    placeholder: 'Search...',
    disabled: false,
    required: false,
    value: null,
    options: [],
  });

  experimentTableControls = this.formBuilder.group({
    [this.experimentStatusFilters.key]: new FormControl(
      this.experimentStatusFilters.value,
      [],
    ),
    [this.experimentModeFilters.key]: new FormControl(
      this.experimentModeFilters.value,
      [],
    ),
    [this.experimentsPerPageField.key]: [this.experimentsPerPageField.value],
    [this.searchField.key]: new FormControl(this.searchField.value),
  });

  constructor() {
    super();
    this.currentTime$ = interval(1000).pipe(map(() => new Date()));
  }

  ngOnInit(): void {
    super.ngOnInit();
    if (this.experimentService.paginationCache !== null) {
      this.pageSize = this.experimentService.paginationCache.pageSize;
      this.pageRequested = this.experimentService.paginationCache.pageNumber;
      this.experimentTableControls.patchValue({
        [this.experimentsPerPageField.key]: [this.pageSize],
      });
    }

    // Get current lab value
    this.laboratory = this.authenticationService.selectedLaboratory;
    // get the list of experiments
    this.getExperiments();
    // Subscribe to the value change on lab id
    this.authenticationService.selectedLab
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((laboratory) => {
        if (this.laboratory?.laboratoryId !== laboratory?.laboratoryId) {
          this.laboratory = laboratory;
          // When the lab id changes, make a fresh call to get the devices and experiments
          if (this.laboratory !== null) {
            this.getExperiments();
          }
        }
      });

    // Subscribe to the value change on devices per page dropdown
    this.experimentTableControls
      .get(this.experimentsPerPageField.key)
      .valueChanges.subscribe((numOfDevices) => {
        this.pageSize = numOfDevices as number;
        // Now also reset page number request to one as we are changing the pagination range
        this.pageRequested = 1;
        this.getExperiments();
      });

    // Subscribe to the value change on experiment status dropdown
    this.experimentTableControls
      .get(this.experimentStatusFilters.key)
      .valueChanges.subscribe((status) => {
        this.experimentStatusFilter = status as ExperimentStatus;
      });

    // Subscribe to the value change on experiment mode dropdown
    this.experimentTableControls
      .get(this.experimentModeFilters.key)
      .valueChanges.subscribe((status) => {
        this.experimentModeFilter = status as ExperimentMode;
      });
  }

  getTimeLeft(startTime: string, duration: number): TimeComponent {
    return calculateTimeLeft(startTime, duration);
  }

  getExperiments(): void {
    this.loading = true;
    // reset error object
    this.error = null;

    const searchValue = this.experimentTableControls.controls['search'].value;

    this.experimentService
      .getExperiments(
        this.laboratory.laboratoryId,
        this.currentSort.sortBy,
        this.currentSort.sortDirection,
        this.pageSize,
        this.pageRequested,
        this.experimentStatusFilter as ExperimentStatus,
        this.experimentModeFilter as ExperimentMode,
        typeof searchValue === 'string' ? searchValue : null,
      )
      .subscribe({
        next: (response) => {
          // Set pagination vars from response headers
          this.currentPage = response.paginationData.CurrentPage;
          this.pageSize = response.paginationData.PageSize;
          this.experimentService.onUpdatePageNumber(this.currentPage);
          this.experimentService.onUpdatePageSize(this.pageSize);
          this.totalPages = response.paginationData.TotalPages;
          this.hasNext = response.paginationData.HasNext;
          this.hasPrevious = response.paginationData.HasPrevious;
          this.loading = false;
          // Set experiment list from response
          this.experiments = response.experimentList;
          this.experiments.forEach((experiment: Experiment) => {
            experiment.modules = [];
            experiment.useFluorescence
              ? experiment.modules.push(Module.Fluorescence)
              : null;
            experiment.useOxygen
              ? experiment.modules.push(Module.Oxygen)
              : null;
            experiment.usePH ? experiment.modules.push(Module.pH) : null;
          });
        },
        error: (error: ErrorObject) => {
          this.error = error;
          this.loading = false;
        },
      });
  }

  onStartExperiment(experiment: Experiment): void {
    void this.router.navigate(['experiment/set-parameters'], {
      state: { data: experiment },
    });
  }

  getPrevious(): void {
    this.pageRequested = this.currentPage - 1;
    this.getExperiments();
  }

  getNext(): void {
    this.pageRequested = this.currentPage + 1;
    this.getExperiments();
  }

  onSort(sortTerm: string): void {
    if (this.currentSort.sortBy === sortTerm) {
      if (this.currentSort.sortDirection === 'asc') {
        this.currentSort.sortDirection = 'desc';
      } else {
        this.currentSort.sortDirection = 'asc';
      }
    } else {
      this.currentSort.sortBy = sortTerm;
      this.currentSort.sortDirection = 'asc';
    }
    this.getExperiments();
  }

  closeModal(modal: string): void {
    this.modalService.close(modal);
  }

  openModal(modal: string): void {
    this.modalService.open(modal);
  }

  resetForm(): void {
    this.experimentStatusFilter = null;
    this.experimentModeFilter = null;
    this.experimentTableControls.patchValue({
      [this.experimentStatusFilters.key]: null,
      [this.experimentModeFilters.key]: null,
      [this.searchField.key]: null,
    });
    this.getExperiments();
  }

  handleSearch(action: string): void {
    switch (action) {
      case 'search':
        this.getExperiments();
        break;
      case 'clear-search':
        this.experimentTableControls.patchValue({ search: null });
        this.getExperiments();
        break;
    }
  }

  modalButtonClicked(buttonId: string): void {
    switch (buttonId) {
      case 'reset-button':
        this.resetForm();
        break;
      case 'continue-button':
        this.getExperiments();
        this.closeModal('filter');
        break;
    }
  }

  ngOnDestroy(): void {
    // Emit a value to unsubscribe from all subscriptions
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}

@Component({
  selector: 'app-error-logs-component',
  templateUrl: './error-logs/error-logs.component.html',
  styleUrls: ['./error-logs/error-logs.component.scss'],
  standalone: true,
  imports: [
    OptionTabToggleComponent,
    SearchFieldComponent,
    IconButtonComponent,
    MatIcon,
    MatTooltip,
    CheckboxGroupNoLabelComponent,
    ButtonComponent,
    DropdownFormFieldComponent,
    LoadingSpinnerComponent,
    ModalComponent,
    DatePipe,
    RoleCheckPipe,
    MatMiniFabButton,
    FilterModalComponent,
  ],
})
export class ErrorLogsComponent extends DashboardComponent implements OnInit {
  private readonly errorLogService = inject(ErrorLogService);
  private readonly modalService = inject(ModalService);
  protected route = inject(ActivatedRoute);
  protected formBuilder = inject(FormBuilder);
  protected router = inject(Router);
  protected authenticationService = inject(AuthenticationService);

  errorLogs: ErrorLog[] = [];
  selectedLogs: ErrorLog[] = [];
  detailViewLog: ErrorLog = null;
  isLoading = true;
  isProcessing = false;
  error: ErrorObject = null;
  displayResetButton = false;
  errorLogFilter = null;

  // Pagination
  pageRequested = 1;
  pageSize = 5;
  totalCount = 0;
  totalPages = 0;
  currentPage = 1;
  hasNext = false;
  hasPrevious = false;

  // Table control variables
  currentFilters = [];
  currentSort: {
    sortBy: string;
    sortDirection: string;
  } = {
    sortBy: 'Name',
    sortDirection: 'asc',
  };

  errorLogViewedField = new FormBase<boolean>({
    key: 'errorLogViewed',
    label: 'Error log viewed',
    type: 'dropdown',
    placeholder: 'Select...',
    disabled: false,
    required: false,
    value: null,
    options: [
      { key: null, value: 'All' },
      { key: 'viewed', value: 'Viewed' },
      { key: 'unviewed', value: 'Unviewed' },
    ],
  });

  errorLogsPerPageField = new FormBase({
    key: 'errorLogsPerPage',
    label: 'Page Size',
    type: 'dropdown',
    placeholder: '',
    disabled: false,
    required: false,
    value: this.pageSize,
    options: [
      { key: 5, value: '5' },
      { key: 10, value: '10' },
      { key: 25, value: '25' },
      { key: 50, value: '50' },
      { key: 100, value: '100' },
    ],
  });

  errorLogCheckboxes = new FormBase({
    key: 'checks',
    label: 'Checks',
    type: 'checkbox',
    placeholder: 'checkbox',
    disabled: false,
    required: false,
    value: '',
    options: [],
  });

  searchField = new FormBase<boolean>({
    key: 'search',
    label: 'Search',
    type: 'text',
    placeholder: 'Search...',
    disabled: false,
    required: false,
    value: null,
    options: [],
  });

  errorLogTableControls = this.formBuilder.group({
    [this.errorLogsPerPageField.key]: new FormControl(
      this.errorLogsPerPageField.value,
    ),
    [this.errorLogCheckboxes.key]: new FormArray([]),
    [this.searchField.key]: new FormControl(this.searchField.value),
    [this.errorLogViewedField.key]: new FormControl(this.searchField.value),
  });

  ngOnInit(): void {
    super.ngOnInit();
    if (this.errorLogService.paginationCache !== null) {
      this.pageSize = this.errorLogService.paginationCache.pageSize;
      this.pageRequested = this.errorLogService.paginationCache.pageNumber;
    }

    // Check for saved sort, filter and pagination values
    if (this.errorLogService.filter !== null) {
      this.currentFilters = this.errorLogService.filter;
    }

    if (this.errorLogService.sort !== null) {
      this.currentSort = this.errorLogService.sort;
    }

    if (this.errorLogService.paginationCache !== null) {
      this.pageSize = this.errorLogService.paginationCache.pageSize;
      this.pageRequested = this.errorLogService.paginationCache.pageNumber;
      this.errorLogTableControls.patchValue({
        [this.errorLogsPerPageField.key]: this.pageSize.toString(),
      });
    }

    // Get current lab value
    this.laboratory = this.authenticationService.selectedLaboratory;
    // Do an initial fetch of devices
    this.getLogs();
  }

  setTableFilterSubscriptions(): void {
    // Subscribe to the value change on devices per page dropdown
    this.errorLogTableControls
      .get(this.errorLogsPerPageField.key)
      .valueChanges.subscribe((numOfDevices) => {
        this.pageSize = numOfDevices as number;
        // Now also reset page number request to one as we are changing the pagination range
        this.pageRequested = 1;
        this.getLogs();
      });
  }

  resetSearch(): void {
    this.errorLogTableControls.patchValue({
      [this.searchField.key]: null,
    });
    this.getLogs();
  }

  getLogs(): void {
    this.isLoading = true;
    this.error = null;
    this.displayResetButton = false;

    const searchValue = this.errorLogTableControls.controls['search']
      .value as string;

    this.errorLogService
      .getErrorLogs(
        this.currentSort.sortBy,
        this.currentSort.sortDirection,
        this.pageSize,
        this.pageRequested,
        typeof searchValue === 'string' ? searchValue : null,
        this.errorLogFilter
          ? (this.errorLogFilter as boolean)
          : this.errorLogFilter === false
            ? false
            : null,
      )
      .subscribe({
        next: (response) => {
          this.errorLogs = response.errorLogs;
          this.initialiseCheckboxes();
          this.currentPage = response.paginationData.CurrentPage;
          this.pageSize = response.paginationData.PageSize;
          this.errorLogService.onUpdatePageNumber(this.currentPage);
          this.errorLogService.onUpdatePageSize(this.pageSize);
          this.totalPages = response.paginationData.TotalPages;
          this.hasNext = response.paginationData.HasNext;
          this.hasPrevious = response.paginationData.HasPrevious;
          this.isLoading = false;

          if (response.errorLogs.length === 0 && searchValue) {
            this.displayResetButton = true;
          }
        },
        error: (error: ErrorObject) => {
          this.error = error;
          this.isLoading = false;
        },
      });
  }

  onSetErrorLogAsRead(errorLogIds: number[], setAsViewed: boolean): void {
    this.errorLogService
      .updateErrorLogViewed(errorLogIds, setAsViewed)
      .subscribe({
        next: () => {
          this.getLogs();
        },
        error: (error: ErrorObject) => {
          this.error = error;
        },
      });
  }

  onMarkAllAsViewed(): void {
    this.errorLogService
      .updateErrorLogViewed(
        this.selectedLogs.map((x) => x.id),
        true,
      )
      .subscribe({
        next: () => {
          this.selectedLogs = [];
          this.getLogs();
        },
        error: (error: ErrorObject) => {
          this.error = error;
        },
      });
  }

  onViewErrorLog(errorLog: ErrorLog): void {
    this.detailViewLog = errorLog;
    this.openModal('error-log-modal');
  }

  closeModal(modal: string): void {
    this.modalService.close(modal);
  }

  openModal(modal: string): void {
    this.modalService.open(modal);
  }

  handleSearch(action: string): void {
    switch (action) {
      case 'search':
        this.getLogs();
        break;
      case 'clear-search':
        this.errorLogTableControls.patchValue({ search: null });
        this.getLogs();
        break;
    }
  }

  modalButtonClicked(buttonId: string): void {
    switch (buttonId) {
      case 'reset-button':
        this.errorLogFilter = null;
        this.errorLogTableControls.patchValue({
          [this.errorLogViewedField.key]: null,
        });
        this.getLogs();
        break;
      case 'close':
        this.closeModal('error-log-modal');
        break;
      case 'continue-button':
        const filter = this.errorLogTableControls.controls['errorLogViewed']
          .value as string;
        this.errorLogFilter =
          filter === 'viewed' ? true : filter === 'unviewed' ? false : null;
        this.getLogs();
        this.closeModal('filter');
        break;
    }
  }

  getPrevious(): void {
    this.pageRequested = this.currentPage - 1;
    this.getLogs();
  }

  getNext(): void {
    this.pageRequested = this.currentPage + 1;
    this.getLogs();
  }

  onSort(sortTerm: string): void {
    if (this.currentSort.sortBy === sortTerm) {
      if (this.currentSort.sortDirection === 'asc') {
        this.currentSort.sortDirection = 'desc';
      } else {
        this.currentSort.sortDirection = 'asc';
      }
    } else {
      this.currentSort.sortBy = sortTerm;
      this.currentSort.sortDirection = 'asc';
    }
    this.getLogs();
  }

  get checkboxesFormArray(): FormArray {
    return this.errorLogTableControls.get('checks') as FormArray;
  }

  getCheckbox(i: number): { key: string | number; value: string } {
    return this.errorLogCheckboxes.options[i];
  }

  checkboxClicked(event: Event): void {
    const target = event.target as HTMLFormElement;
    this.errorLogCheckboxes.options.forEach((e, i) => {
      if (e.key === target.name) {
        const checks = this.errorLogTableControls.value.checks as boolean[];
        checks[i] = !checks[i];
        this.checkboxesFormArray.controls[i].setValue(
          !this.checkboxesFormArray.controls[i].value,
        );
        this.checkboxesFormArray.markAsTouched();
      }
    });
  }

  initialiseCheckboxes(): void {
    // Reset checkbox form array
    clearFormArray(this.checkboxesFormArray);
    // Reset checkbox list
    this.errorLogCheckboxes.options = [];
    // Now construct the checkbox array with the new values
    this.errorLogs.forEach((log, index) => {
      this.errorLogCheckboxes.options.push({
        key: 'log_' + log.id.toString(),
        value: index.toString(),
      });
      if (this.selectedLogs.find((x) => x.id === log.id)) {
        this.checkboxesFormArray.push(new FormControl(true));
      } else {
        this.checkboxesFormArray.push(new FormControl(false));
      }
    });
  }

  errorLogCheckboxClicked(event: Event): void {
    const target = event.target as HTMLFormElement;
    this.errorLogCheckboxes.options.forEach((e, i) => {
      if (e.key === target.name) {
        const checks = this.errorLogTableControls.value.checks as boolean[];
        checks[i] = !checks[i];
        this.checkboxesFormArray.controls[i].setValue(
          !this.checkboxesFormArray.controls[i].value,
        );
        const cleanId = e.key.replace('log_', '');
        let index = -1;
        if (this.selectedLogs) {
          for (const [i, log] of this.selectedLogs.entries()) {
            if (log?.id === parseInt(cleanId)) {
              index = i;
              break;
            }
          }
        }

        if (target.checked === true && index === -1) {
          // If device is not already in the list, push it, push it good
          const selectedLog = this.errorLogs.filter(
            (x) => x.id === parseInt(cleanId),
          )[0];
          this.selectedLogs.push(selectedLog);
        }

        if (target.checked === false && index !== -1) {
          // If device is in the list, pop it, pop it good
          this.selectedLogs.splice(index, 1);
        }
      }
    });
  }
}
