<div id="title--container">
  <h4>pH Buffers</h4>
</div>
<hr />
<div id="new-buffer--container">
  <div id="nav--container">
    <app-option-tab-toggle
      id="toggle--container"
      radioGroup="navigationToggle"
      [selectOptions]="navOptions"
      type="round"
    >
    </app-option-tab-toggle>
  </div>
  <div id="table--container" *ngIf="phBuffers?.length > 0">
    <table aria-describedby="table-description">
      <thead>
        <tr>
          <th
            id="phBuffer"
            scope="col"
            (click)="onSort('Name')"
            [ngClass]="getClasses('Name')"
          >
            <div id="table-header">
              <span>Buffer</span>
              <mat-icon aria-label="sort direction" matTooltip="Sort direction"
                >swap_vert</mat-icon
              >
            </div>
          </th>
          <th id="phTempList" scope="col">Temperature / pH</th>
          <th id="devices" scope="col">Devices</th>
          <th id="delete" scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let buffer of phBuffers; let i = index">
          <td headers="phBuffer">{{ buffer.name }}</td>
          <td headers="phTempList">
            <div
              *ngIf="buffer?.temperaturePHValuePairs.length > 0; else noPairs"
            >
              <div
                *ngFor="
                  let phTemp of buffer?.temperaturePHValuePairs;
                  let i = index
                "
                id="phTempListItem"
              >
                <div>Temp: {{ phTemp.temperature }}</div>
                <div>pH: {{ phTemp.phValue }}</div>
              </div>
            </div>
            <ng-template #noPairs>-</ng-template>
          </td>
          <td headers="devices">
            <div *ngIf="buffer?.devices.length > 0; else noDevices">
              <div *ngFor="let device of buffer?.devices; let i = index">
                {{ device.name }}
              </div>
            </div>
            <ng-template #noDevices>-</ng-template>
          </td>
          <td headers="delete">
            <app-icon-button
              *ngIf="buffer?.devices.length === 0"
              buttonId="remove-btn"
              buttonStyle="transparent"
              buttonShape="square"
              textColor="red"
              iconName="close"
              (buttonClicked)="deleteBuffer(buffer.id)"
              [isDisabled]="
                currentUser.role
                  | roleCheck
                    : [
                        Role.Read_Access_User,
                        Role.Run_Access_User,
                        Role.Edit_Access_User
                      ]
              "
            ></app-icon-button>
          </td>
        </tr>
      </tbody>
    </table>

    <div id="pagination--container">
      <div class="experiment-status-filter--container">
        <app-dropdown-form-field
          id="experiments-per-page"
          [field]="buffersPerPageField"
          [parentForm]="bufferTableControls"
        >
        </app-dropdown-form-field>
      </div>
      <div id="pagination--controls">
        <app-icon-button
          iconName="chevron_left"
          buttonId="previous"
          (buttonClicked)="getPrevious()"
          [isDisabled]="!hasPrevious"
        ></app-icon-button>
        <span> {{ currentPage }} / {{ totalPages }} </span>
        <app-icon-button
          iconName="chevron_right"
          buttonId="next"
          (buttonClicked)="getNext()"
          [isDisabled]="!hasNext"
        ></app-icon-button>
      </div>
    </div>
  </div>
  <div
    id="cta--container"
    *ngIf="!isLoading && (!phBuffers || phBuffers.length === 0)"
  >
    <h4>No buffers to display</h4>
    <p *ngIf="error" class="error">Error: {{ getBuffersError.message }}</p>
  </div>
  <div
    id="loading--container"
    *ngIf="isLoading && (!phBuffers || phBuffers.length === 0)"
  >
    <app-loading-spinner></app-loading-spinner>
  </div>
</div>
<app-modal
  id="delete-success"
  titleText="Success"
  [primaryText]="deleteBufferSuccessMessage"
  primaryButtonText="Close"
  primaryButtonId="close-approval"
  modalStyle="success"
  (modalButtonClicked)="closeModal('delete-success')"
></app-modal>

<app-modal
  id="discard-error-response"
  titleText="Unable to delete buffer"
  [primaryText]="deleteBufferError?.message"
  buttonType="single-button"
  primaryButtonText="Close"
  primaryButtonId="error-primary-button"
  modalStyle="error"
  (modalButtonClicked)="closeModal('discard-error-response')"
  allowClose="false"
>
</app-modal>
