<div class="radio--container">
  <legend class="radio-label" [class.small]="legendStyle === 'small'">{{
    field.label
  }}</legend>
  @if (field.required) {
    <span class="required" aria-required="true"></span>
  }
  <div class="radio-options-container">
    @for (option of field.options; track option.key) {
      <label [for]="option.key" class="subtitle1" [formGroup]="parentForm">
        <input
          type="radio"
          [id]="option.key"
          [attr.name]="field.key"
          [formControlName]="field.key"
          [value]="option.key"
          [attr.checked]="option.key === currentValue?.toString() ? '' : null"
          class="body1"
          (change)="updateValue($event)"
        />
        {{ option.value }}
      </label>
    }
  </div>
</div>
