<button
  [type]="type"
  [id]="buttonId"
  (click)="clickButton()"
  [ngClass]="classes"
  [disabled]="isDisabled"
>
  <span>{{ label }}</span>
  <div>
    @if (isProcessing) {
    <div class="loader"></div>
    }
  </div>
</button>
