import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { IconNames } from '../../icons/icon-names';
import { Experiment } from 'src/app/_models/experiment';
import { SvgTemplateComponent } from '../../svg-template/svg-template.component';
import { DecimalPipe, DatePipe } from '@angular/common';

@Component({
  selector: 'app-small-data-card',
  templateUrl: './small-data-card.component.html',
  styleUrls: ['./small-data-card.component.scss'],
  standalone: true,
  imports: [SvgTemplateComponent, DecimalPipe, DatePipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SmallDataCardComponent implements OnInit {
  /**
   * @param {number} dataReading data reading to be displayed
   * @requires
   */
  @Input()
  dataReading: number;

  /**
   * @param {string} temperatureReading temperature value to be displayed
   */
  @Input()
  temperatureReading: number;

  /**
   * @param {string} dataReadingTime of time of the data reading
   * @requires
   */
  @Input()
  dataReadingTime: string;

  @Input()
  experiment: Experiment;

  /**
   * @param {string} flask flask name to be displayed
   */
  @Input()
  flask: 'Flask A' | 'Flask B' | 'Flask C' | 'Flask D';

  /**
   * @param {string} temperature temperature to be displayed
   */
  @Input()
  temperature: string;

  time: Date;

  ngOnInit(): void {
    this.time = new Date(this.dataReadingTime);
  }

  public get flaskIcon(): string {
    switch (this.flask) {
      case 'Flask A':
        return IconNames.FlaskA;
      case 'Flask B':
        return IconNames.FlaskB;
      case 'Flask C':
        return IconNames.FlaskC;
      case 'Flask D':
        return IconNames.FlaskD;
      default:
        return '';
    }
  }

  public get speedRPMReading(): number {
    switch (this.flask) {
      case 'Flask A':
        return this.experiment.speedRPMA;
      case 'Flask B':
        return this.experiment.speedRPMB;
      case 'Flask C':
        return this.experiment.speedRPMC;
      case 'Flask D':
        return this.experiment.speedRPMD;
      default:
        return null;
    }
  }
}
