import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

// Helper function to get the number of decimal places in a number
function getDecimalPlaces(num: number): number {
  const match = ('' + num).match(/(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/);
  if (!match) {
    return 0;
  }
  return Math.max(
    0,
    // Number of digits right of decimal point.
    (match[1] ? match[1].length : 0) -
      // Adjust for scientific notation.
      (match[2] ? +match[2] : 0)
  );
}

export function stepValidator(step: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.value === null || control.value === undefined) {
      return null; // Don't validate empty values
    }

    const value = parseFloat(control.value as string);

    // Handle precision issues by comparing integers instead
    const multiplier = Math.pow(10, getDecimalPlaces(step));
    const adjustedValue = Math.round(value * multiplier);
    const adjustedStep = Math.round(step * multiplier);

    const isValid = adjustedValue % adjustedStep === 0;

    return !isValid
      ? { step: { step, actual: control.value as number } }
      : null;
  };
}
