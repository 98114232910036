export enum Role {
  // OGI roles
  OGI_Super_Administrator = 'OGI_Super_Administrator',
  OGI_Laboratory_Administrator = 'OGI_Laboratory_Administrator',
  // General roles
  Super_User = 'Super_User',
  Lab_Administrator = 'Lab_Administrator',
  Edit_Access_User = 'Edit_Access_User',
  Run_Access_User = 'Run_Access_User',
  Read_Access_User = 'Read_Access_User',
}

export enum RoleEnum {
  // OGI roles
  OGI_Super_Administrator = 1,
  OGI_Laboratory_Administrator = 2,
  // General roles
  Super_User = 3,
  Lab_Administrator = 4,
  Edit_Access_User = 5,
  Run_Access_User = 6,
  Read_Access_User = 7,
}
