<section class="toggle--container">
  <label [for]="field.key" [formGroup]="parentForm" class="toggle-label">
    <div class="label--container body1">
      {{ field.label }}
      @if (field.required) {
        <span class="required" aria-required="true"> * </span>
      }
    </div>
    <mat-slide-toggle
      [id]="field.key"
      [formControlName]="field.key"
      [disabled]="field.disabled"
      ngDefaultControl
      color="primary"
    ></mat-slide-toggle>
  </label>
</section>
