<nav class="navbar--container">
  <div
    [class.container--collapsed]="sidebarCollapsed"
    [class.container--expanded]="!sidebarCollapsed"
  >
    <div id="arrow--container">
      @if (sidebarCollapsed) {
        <app-nav-button
          (click)="buttonClicked()"
          iconName="chevron_right"
          buttonId="expand-btn"
          label="Expand"
        ></app-nav-button>
      }
      @if (!sidebarCollapsed) {
        <app-nav-button
          (click)="buttonClicked()"
          iconName="chevron_left"
          buttonId="collapse-btn"
          label="Collapse"
        ></app-nav-button>
      }
    </div>
    <div id="logo--container">
      @if (sidebarCollapsed) {
        <img
          id="cropped-logo"
          [routerLink]="'dashboard/devices'"
          src="assets/img/ogi-icon-logo.png"
          alt="OGI Logo"
        />
      }
      @if (!sidebarCollapsed) {
        <img
          id="full-logo"
          [routerLink]="'dashboard/devices'"
          src="assets/img/ogi-logo.png"
          alt="OGI Logo"
        />
      }
    </div>
    <div id="menu--container">
      <div id="options--container">
        <div class="subsection--container">
          @if (!sidebarCollapsed) {
            <app-dropdown-form-field
              id="lab-list"
              [field]="laboratorySelectField"
              [parentForm]="labSelectForm"
              dropdownStyle="nav"
            ></app-dropdown-form-field>
          }
        </div>
        <app-nav-button
          routerLink="dashboard/devices"
          iconName="dashboard"
          buttonId="dashboard-btn"
          label="Dashboard"
          [displayText]="!sidebarCollapsed"
        ></app-nav-button>
        <app-nav-button
          routerLink="experiment/0/set-parameters"
          iconName="science"
          buttonId="experiment-btn"
          label="New Experiment"
          [displayText]="!sidebarCollapsed"
          [isDisabled]="
            checkSuperUserPermissions() ||
            (currentUser.role
              | roleCheck: [Role.Read_Access_User, Role.Run_Access_User])
          "
        ></app-nav-button>
        <app-nav-button
          routerLink="calibration/create-calibration"
          iconName="timeline"
          buttonId="calibration-btn"
          label="New Calibration"
          [displayText]="!sidebarCollapsed"
          [isDisabled]="
            checkSuperUserPermissions() ||
            (currentUser.role
              | roleCheck: [Role.Read_Access_User, Role.Run_Access_User])
          "
        ></app-nav-button>
        <app-nav-button
          routerLink="buffer/view-buffers"
          label="pH Buffers"
          iconName="opacity"
          buttonId="buffer-lrg-btn"
          [displayText]="!sidebarCollapsed"
          [isDisabled]="
            checkSuperUserPermissions() ||
            (currentUser.role
              | roleCheck: [Role.Read_Access_User, Role.Run_Access_User])
          "
        ></app-nav-button>

        <app-nav-button
          routerLink="device/create-device"
          iconName="stream"
          buttonId="devices-btn"
          label="Add Device"
          [displayText]="!sidebarCollapsed"
          [isDisabled]="
            checkSuperUserPermissions() ||
            (currentUser.role
              | roleCheck
                : [
                    Role.Read_Access_User,
                    Role.Run_Access_User,
                    Role.Edit_Access_User,
                  ])
          "
        >
        </app-nav-button>
        <app-nav-button
          routerLink="user/add-user"
          iconName="person_add"
          buttonId="add-user-btn"
          label="Add User"
          [displayText]="!sidebarCollapsed"
          [isDisabled]="
            !selectedLab ||
            (currentUser.role
              | roleCheck
                : [
                    Role.Read_Access_User,
                    Role.Run_Access_User,
                    Role.Edit_Access_User,
                  ])
          "
        ></app-nav-button>
        <app-nav-button
          routerLink="laboratories/view-lab"
          iconName="home"
          buttonId="view-lab-btn"
          label="View Lab"
          [displayText]="!sidebarCollapsed"
          [isDisabled]="!selectedLab"
        ></app-nav-button>
        <app-nav-button
          [routerLink]="'organisations/' + currentOrganisation + '/details'"
          iconName="location_city"
          buttonId="view-org-btn"
          label="View Organisation"
          [displayText]="!sidebarCollapsed"
          [isDisabled]="
            currentUser.role
              | roleCheck
                : [
                    Role.Read_Access_User,
                    Role.Run_Access_User,
                    Role.Edit_Access_User,
                    Role.Lab_Administrator,
                    Role.OGI_Laboratory_Administrator,
                  ]
          "
        ></app-nav-button>
      </div>
      @if (currentUser.role === Role.OGI_Super_Administrator) {
        <div id="admin--container">
          <app-nav-button
            routerLink="approvals/labs"
            iconName="admin_panel_settings"
            buttonId="approvals-btn"
            label="Approvals"
            [displayText]="!sidebarCollapsed"
          >
          </app-nav-button>
          <app-nav-button
            routerLink="organisations/administration"
            iconName="location_city"
            buttonId="view-orgs-btn"
            label="Organisations"
            [displayText]="!sidebarCollapsed"
          ></app-nav-button>
        </div>
      }
      <div id="user--container">
        @if (
          currentUser.role === Role.Super_User ||
          currentUser.role === Role.OGI_Super_Administrator
        ) {
          <app-nav-button
            routerLink="help"
            iconName="help"
            buttonId="help-btn"
            label="Help"
            [displayText]="!sidebarCollapsed"
          ></app-nav-button>
        }
        <app-nav-button
          routerLink="account/update-details"
          iconName="account_circle"
          buttonId="user-btn"
          label="Account"
          [displayText]="!sidebarCollapsed"
        ></app-nav-button>
        <app-nav-button
          (buttonClicked)="onLogout()"
          iconName="logout"
          buttonId="logout-btn"
          label="Logout"
          [displayText]="!sidebarCollapsed"
        ></app-nav-button>
      </div>
    </div>
  </div>
</nav>
