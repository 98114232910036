<div id="experiment--container">
  <div id="nav--container">
    <app-option-tab-toggle
      id="toggle--container"
      radioGroup="navigationToggle"
      [selectOptions]="navOptions"
      type="round"
    >
    </app-option-tab-toggle>
  </div>
  <hr />
  <div id="inner-page--container">
    <div id="error--container" *ngIf="error">
      <h4>Something went wrong</h4>
      <p class="error">Error: {{ error.message }}</p>
    </div>
    <div id="select-device--container">
      <app-checkbox-group
        id="checkbox--container"
        *ngIf="devices"
        [parentForm]="deviceSelectorForm"
        [field]="deviceCheckboxesField"
        [formArrayGroupName]="deviceCheckboxesField.key"
        (checkboxClicked)="deviceCheckboxClicked($event)"
      ></app-checkbox-group>
      <app-icon-button
        label="update"
        buttonId="update-btn"
        (buttonClicked)="getExperiment()"
        [isProcessing]="loadingDeviceSummary"
        iconName="autorenew"
        buttonShape="square"
        buttonStyle="outlined"
      ></app-icon-button>
    </div>
    <hr />
    <div id="device--container">
      <section
        class="device-summary--container"
        *ngIf="selectedDevices?.length > 0; else placeholder"
      >
        <div *ngFor="let device of selectedDevices; let index = i">
          <app-device-info-card
            *ngIf="selectedDevices.length > 0"
            [device]="device"
            [selectedDataCategory]="selectedCategory"
            [lastUpdateTime]="deviceListLastUpdated"
            [experiment]="experiment"
            (pauseDeviceClicked)="pauseRestartButtonClicked($event)"
          ></app-device-info-card>
        </div>
      </section>
    </div>
    <ng-template #placeholder>
      <section id="placeholder--container">
        <h4>No devices selected</h4>
        <p class="body1">
          Use the checkboxes above to choose device summaries to display
        </p>
      </section>
    </ng-template>
    <hr />
    <section id="data--container">
      <div class="experiment-overview--container">
        <div class="header--container">
          <h4>Experiment Overview</h4>
          <app-status-indicator
            *ngIf="experiment?.isDeleted === false"
            [status]="ExperimentStatus[experiment?.experimentStatusId]"
          ></app-status-indicator>
          <app-status-indicator
            *ngIf="experiment?.isDeleted"
            status="Deleted"
          ></app-status-indicator>
          <div class="buttons--container">
            <app-icon-button
              label="Update Graphs"
              buttonId="update-btn"
              buttonStyle="outlined"
              (buttonClicked)="getExperimentData()"
              [isProcessing]="loadingOpticalDensity"
              iconName="autorenew"
              buttonShape="square"
            ></app-icon-button>
            <app-icon-button
              label="make parameter changes"
              buttonId="control-btn"
              [routerLink]="'/experiment/' + experiment?.id + '/control'"
              [isDisabled]="
                experiment?.experimentStatusId !== ExperimentStatus.Active
              "
              buttonStyle="outlined"
              iconName="swap_vert"
              buttonShape="square"
            ></app-icon-button>
          </div>
        </div>
        <div class="card--container">
          <app-reduced-small-data-card
            title="Experiment Mode"
            [dataReading]="ExperimentMode[experiment?.experimentMode]"
          >
          </app-reduced-small-data-card>
          <app-reduced-small-data-card
            title="Organism"
            [dataReading]="experiment?.organism"
          >
          </app-reduced-small-data-card>
          <app-reduced-small-data-card
            title="Strain"
            [dataReading]="experiment?.strain"
          >
          </app-reduced-small-data-card>
          <app-reduced-small-data-card
            title="Experiment Name"
            [dataReading]="experiment?.name"
          >
          </app-reduced-small-data-card>
          <app-reduced-small-data-card
            title="Media"
            [dataReading]="experiment?.media"
          >
          </app-reduced-small-data-card>
          <div class="time-left--container">
            <div>
              <span
                *ngIf="
                  experiment?.experimentStatusId === ExperimentStatus.Active &&
                    currentTime$ | async as t
                "
                class="subtitle2"
              >
                {{
                  getTimeLeft(experiment.startTime, experiment.duration).days
                    | number : "2.0"
                }}
                :
                {{
                  getTimeLeft(experiment.startTime, experiment.duration).hours
                    | number : "2.0"
                }}
                :
                {{
                  getTimeLeft(experiment.startTime, experiment.duration).minutes
                    | number : "2.0"
                }}
                :
                {{
                  getTimeLeft(experiment.startTime, experiment.duration).seconds
                    | number : "2.0"
                }}
              </span>
              <span
                *ngIf="
                  experiment?.experimentStatusId === ExperimentStatus.Complete
                "
              >
                --:--
              </span>
            </div>
            <div>
              <span class="body1">Time Left</span>
            </div>
          </div>
        </div>
      </div>
      <div id="lower">
        <!-- Optical Density graph -->
        <div class="graph--container-outer" aria-live="polite">
          <div class="graph--container-title">
            <h5>Optical Density</h5>
            <div class="download--container">
              <app-icon-button
                label="download base module data"
                buttonId="download-base-module-btn"
                buttonStyle="outlined"
                iconName="download"
                buttonShape="square"
                (buttonClicked)="downloadBaseModuleCsv()"
                [isProcessing]="isDownloadingBaseModuleCsv"
                [isDisabled]="isDownloadingBaseModuleCsv"
              ></app-icon-button>
              @if (experiment && experiment.experimentMode ===
              ExperimentMode.Turbidostat) {
              <app-icon-button
                label="download turbidostat data"
                buttonId="download-turbidostat-btn"
                buttonStyle="outlined"
                iconName="download"
                buttonShape="square"
                (buttonClicked)="downloadTurbidostatCsv()"
                [isProcessing]="isDownloadingTurbidostatCsv"
                [isDisabled]="isDownloadingTurbidostatCsv"
              ></app-icon-button>
              }
            </div>
          </div>
          <app-graph
            *ngIf="renderODGraph && selectedODData; else loadingOD"
            [experimentData]="selectedODData"
            [screenSize]="graphSize"
            [graphId]="odGraphId"
            [yAxisLabel]="'Optical Density'"
            [isLogarithmic]="isLogarithmicChecked"
          ></app-graph>
          <ng-template #loadingOD>
            <div class="loading--container">
              <app-loading-spinner
                [label]="opticalDensityLoadingText"
              ></app-loading-spinner>
            </div>
          </ng-template>
          <div class="device-select__container">
            <app-checkbox
              [checkbox]="isLogarithmic"
              [parentForm]="graphCheckboxesForm"
              (checkboxSelected)="handleLogarithmicCheckboxClicked($event)"
            ></app-checkbox>
            <app-graph-checkbox-group
              id="od--checks"
              *ngIf="devices"
              [checkboxTitles]="odDeviceTitles"
              [parentForm]="graphCheckboxesForm"
              [field]="odFlaskCheckboxesField"
              [formArrayGroupName]="odFlaskCheckboxesField.key"
              (checkboxClicked)="handleFlaskCheckboxClicked($event, 'od')"
            ></app-graph-checkbox-group>
          </div>
        </div>
        <!-- Temperature graph -->
        <div class="graph--container-outer" aria-live="polite">
          <div class="graph--container-title">
            <h5>Temperature</h5>
            <app-icon-button
              label="download data"
              buttonId="download-btn"
              buttonStyle="outlined"
              iconName="download"
              buttonShape="square"
              (buttonClicked)="downloadTemperatureCsv()"
              [isProcessing]="isDownloadingTemperatureCsv"
              [isDisabled]="isDownloadingTemperatureCsv"
            ></app-icon-button>
          </div>
          <app-graph
            *ngIf="
              renderTemperatureGraph && selectedTemperatureData;
              else loadingTemperature
            "
            [experimentData]="selectedTemperatureData"
            [screenSize]="graphSize"
            [graphId]="temperatureGraphId"
            [yAxisLabel]="'Temperature'"
          ></app-graph>
          <ng-template #loadingTemperature>
            <div class="loading--container">
              <app-loading-spinner
                [label]="temperatureLoadingText"
              ></app-loading-spinner>
            </div>
          </ng-template>
          <div class="device-select__container">
            <app-graph-checkbox-group
              id="temperature--checks"
              *ngIf="devices"
              [checkboxTitles]="temperatureDeviceTitles"
              [parentForm]="graphCheckboxesForm"
              [field]="temperatureFlaskCheckboxesField"
              [formArrayGroupName]="temperatureFlaskCheckboxesField.key"
              (checkboxClicked)="
                handleFlaskCheckboxClicked($event, 'temperature')
              "
            ></app-graph-checkbox-group>
          </div>
        </div>
        <!-- Oxygen graph -->
        <div class="graph--container-outer" aria-live="polite">
          <div class="graph--container-title">
            <h5>Oxygen</h5>
            <app-icon-button
              label="download data"
              buttonId="download-btn"
              buttonStyle="outlined"
              iconName="download"
              buttonShape="square"
              (buttonClicked)="downloadOxygenCsv()"
              [isProcessing]="isDownloadingOxygenCsv"
              [isDisabled]="isDownloadingOxygenCsv"
            ></app-icon-button>
          </div>
          <app-graph
            *ngIf="renderOxygenGraph && selectedOxygenData; else loadingOxygen"
            [experimentData]="selectedOxygenData"
            [screenSize]="graphSize"
            [graphId]="oxygenGraphId"
            [yAxisLabel]="'Oxygen'"
          ></app-graph>
          <ng-template #loadingOxygen>
            <div class="loading--container">
              <app-loading-spinner
                [label]="oxygenLoadingText"
              ></app-loading-spinner>
            </div>
          </ng-template>
          <div class="device-select__container">
            <app-slide-toggle-group
              [field]="oxygenDataFilterField"
              [parentForm]="graphCheckboxesForm"
              (toggleClicked)="handleOxygenToggleClicked()"
            ></app-slide-toggle-group>
            <app-graph-checkbox-group
              id="oxygen--checks"
              *ngIf="devices"
              [checkboxTitles]="oxygenDeviceTitles"
              [parentForm]="graphCheckboxesForm"
              [field]="oxygenFlaskCheckboxesField"
              [formArrayGroupName]="oxygenFlaskCheckboxesField.key"
              (checkboxClicked)="handleFlaskCheckboxClicked($event, 'oxygen')"
            ></app-graph-checkbox-group>
          </div>
        </div>
        <!-- pH graph -->
        <div class="graph--container-outer" aria-live="polite">
          <div class="graph--container-title">
            <h5>pH</h5>
            <app-icon-button
              label="download data"
              buttonId="download-btn"
              buttonStyle="outlined"
              iconName="download"
              buttonShape="square"
              (buttonClicked)="downloadPhCsv()"
              [isProcessing]="isDownloadingPhCsv"
              [isDisabled]="isDownloadingPhCsv"
            ></app-icon-button>
          </div>
          <app-graph
            *ngIf="renderPHGraph && selectedPHData; else loadingPH"
            [experimentData]="selectedPHData"
            [screenSize]="graphSize"
            [graphId]="pHGraphId"
            [yAxisLabel]="'pH'"
          ></app-graph>
          <ng-template #loadingPH>
            <div class="loading--container">
              <app-loading-spinner
                [label]="pHLoadingText"
              ></app-loading-spinner>
            </div>
          </ng-template>
          <div class="device-select__container">
            <app-graph-checkbox-group
              id="pH--checks"
              *ngIf="devices"
              [checkboxTitles]="pHDeviceTitles"
              [parentForm]="graphCheckboxesForm"
              [field]="pHFlaskCheckboxesField"
              [formArrayGroupName]="pHFlaskCheckboxesField.key"
              (checkboxClicked)="handleFlaskCheckboxClicked($event, 'pH')"
            ></app-graph-checkbox-group>
          </div>
        </div>
        <!-- Fluorescence graph -->
        <div class="graph--container-outer" aria-live="polite">
          <div class="graph--container-title">
            <h5>Fluorescence</h5>
            <app-icon-button
              label="download data"
              buttonId="download-btn"
              buttonStyle="outlined"
              iconName="download"
              buttonShape="square"
              (buttonClicked)="downloadFluorescenceCsv()"
              [isProcessing]="isDownloadingFluorescenceCsv"
              [isDisabled]="isDownloadingFluorescenceCsv"
            ></app-icon-button>
          </div>
          <app-graph
            *ngIf="
              renderFluorescenceGraph && selectedFluorescenceData;
              else loadingFluorescence
            "
            [experimentData]="selectedFluorescenceData"
            [screenSize]="graphSize"
            [graphId]="fluorescenceGraphId"
            [yAxisLabel]="'Fluorescence'"
          ></app-graph>
          <ng-template #loadingFluorescence>
            <div class="loading--container">
              <app-loading-spinner
                [label]="fluorescenceLoadingText"
              ></app-loading-spinner>
            </div>
          </ng-template>
          <div class="fluoro-select__container">
            <div class="device-select__container-left">
              <div class="selection__container">
                <span class="subtitle2"> Selection 1:</span>
                <app-underlined-dropdown-form-field
                  id="led-selection-1"
                  [field]="ledSelection1Field"
                  [parentForm]="fluorescenceSelectionForm"
                >
                </app-underlined-dropdown-form-field>
                <app-underlined-dropdown-form-field
                  id="fluoro-selection-1"
                  [field]="fluorescenceChannelSelection1Field"
                  [parentForm]="fluorescenceSelectionForm"
                >
                </app-underlined-dropdown-form-field>
              </div>
              <div class="selection__container">
                <span class="subtitle2"> Selection 2:</span>
                <app-underlined-dropdown-form-field
                  id="led-selection-2"
                  [field]="ledSelection2Field"
                  [parentForm]="fluorescenceSelectionForm"
                >
                </app-underlined-dropdown-form-field>
                <app-underlined-dropdown-form-field
                  id="fluoro-selection-2"
                  [field]="fluorescenceChannelSelection2Field"
                  [parentForm]="fluorescenceSelectionForm"
                >
                </app-underlined-dropdown-form-field>
              </div>
              <div class="selection__container">
                <span class="subtitle2"> Selection 3:</span>
                <app-underlined-dropdown-form-field
                  id="led-selection-3"
                  [field]="ledSelection3Field"
                  [parentForm]="fluorescenceSelectionForm"
                >
                </app-underlined-dropdown-form-field>
                <app-underlined-dropdown-form-field
                  id="fluoro-selection-3"
                  [field]="fluorescenceChannelSelection3Field"
                  [parentForm]="fluorescenceSelectionForm"
                >
                </app-underlined-dropdown-form-field>
              </div>
            </div>
            <app-button
              buttonStyle="standard"
              label="Graph selection"
              (buttonClicked)="getFluorescenceData()"
              [isProcessing]="
                !renderFluorescenceGraph && isFetchingFluorescenceData
              "
              [isDisabled]="
                !fluorescenceSelectionForm.valid ||
                fluorescenceSelectionForm.pristine
              "
            ></app-button>
          </div>
          <div class="device-select__container">
            <app-graph-checkbox-group
              id="fluorescence--checks"
              *ngIf="devices"
              [checkboxTitles]="fluorescenceDeviceTitles"
              [parentForm]="graphCheckboxesForm"
              [field]="fluorescenceFlaskCheckboxesField"
              [formArrayGroupName]="fluorescenceFlaskCheckboxesField.key"
              (checkboxClicked)="
                handleFlaskCheckboxClicked($event, 'fluorescence')
              "
            ></app-graph-checkbox-group>
          </div>
        </div>
      </div>
    </section>
  </div>
</div>
<app-validation-modal
  id="pause-device"
  [titleText]="pauseDeviceTitleText"
  [validationText]="pauseDeviceValidationText"
  buttonType="double-button"
  [primaryButtonText]="pauseDeviceButtonText"
  primaryButtonId="primary-button"
  secondaryButtonText="Close"
  secondaryButtonId="secondary-button"
  (modalButtonClicked)="pauseRestartDevices()"
  [error]="error"
></app-validation-modal>
