<div id="device-setup--container" [ngClass]="classes">
  <h6>
    {{ device.name }} /
    {{ device.id }}
  </h6>
  <div id="device-setup-row">
    <div id="section1">
      <div>
        <div class="number-icon"><span>1</span></div>
      </div>
      <span>Follow instructions on device & insert flasks into device</span>
    </div>
    <div id="section2">
      <div id="chevron-container" [ngClass]="chevronClasses">
        <svg
          width="60"
          height="60"
          viewBox="0 0 50 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            id="chevron1"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M11.4686 26.1315C10.8438 25.5066 10.8438 24.4936 11.4686 23.8687L19.3373 16.0001L11.4686 8.13147C10.8438 7.50663 10.8438 6.49357 11.4686 5.86873C12.0935 5.24389 13.1065 5.24389 13.7314 5.86873L22.7314 14.8687C23.3562 15.4936 23.3562 16.5066 22.7314 17.1315L13.7314 26.1315C13.1065 26.7563 12.0935 26.7563 11.4686 26.1315Z"
            fill="#D2EDFB"
          />
          <path
            id="chevron2"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M20.4686 26.1315C19.8438 25.5066 19.8438 24.4936 20.4686 23.8687L28.3373 16.0001L20.4686 8.13147C19.8438 7.50663 19.8438 6.49357 20.4686 5.86873C21.0935 5.24389 22.1065 5.24389 22.7314 5.86873L31.7314 14.8687C32.3562 15.4936 32.3562 16.5066 31.7314 17.1315L22.7314 26.1315C22.1065 26.7563 21.0935 26.7563 20.4686 26.1315Z"
            fill="#B4DFFF"
          />
          <path
            id="chevron3"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M29.4686 26.1315C28.8438 25.5066 28.8438 24.4936 29.4686 23.8687L37.3373 16.0001L29.4686 8.13147C28.8438 7.50663 28.8438 6.49357 29.4686 5.86873C30.0935 5.24389 31.1065 5.24389 31.7314 5.86873L40.7314 14.8687C41.3562 15.4936 41.3562 16.5066 40.7314 17.1315L31.7314 26.1315C31.1065 26.7563 30.0935 26.7563 29.4686 26.1315Z"
            fill="#0C7BB4"
          />
        </svg>
      </div>
    </div>
    <div id="section3">
      <div id="button-tick--container">
        <div id="tick--container" *ngIf="device.deviceStatusId === 5">
          <svg
            width="70"
            height="70"
            viewBox="-5 0 50 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              class="circ path"
              d="
M30.5,6.5L30.5,6.5c6.6,6.6,6.6,17.4,0,24l0,0c-6.6,6.6-17.4,6.6-24,0l0,0c-6.6-6.6-6.6-17.4,0-24l0,0C13.1-0.2,23.9-0.2,30.5,6.5z"
            />
            <polyline
              class="tick path"
              points="11.6,20 15.9,24.2 26.4,13.8 "
              stroke-linecap="round"
            />
          </svg>
        </div>
      </div>
    </div>
    <div id="section4">
      <div>
        <div class="number-icon"><span>2</span></div>
      </div>
      <span>Ready to start experiment</span>
    </div>
  </div>
  <hr />
</div>
