<div id="dashboard--container">
  <div id="header--container">
    <h4>Welcome to your dashboard.</h4>
    <h5>{{ laboratory?.laboratoryName }}</h5>
  </div>
  <hr class="alt" />
  <div id="nav--container">
    <app-option-tab-toggle
      id="toggle--container"
      radioGroup="navigationToggle"
      [selectOptions]="navOptions"
      type="round"
    >
    </app-option-tab-toggle>
    <div id="nav-button-container">
      <app-search-field
        [field]="searchField"
        [parentForm]="errorLogTableControls"
        (buttonClicked)="getLogs()"
      >
      </app-search-field>
      <div class="button--container">
        <app-icon-button
          label="mark all as viewed"
          buttonStyle="secondary"
          buttonId="viewed-btn"
          buttonShape="square"
          (buttonClicked)="onMarkAllAsViewed()"
          [isDisabled]="
            (currentUser.role
              | roleCheck : [Role.Read_Access_User, Role.Run_Access_User]) ||
            selectedLogs.length === 0
          "
        ></app-icon-button>
      </div>
    </div>
  </div>
  <div id="inner-page--container" *ngIf="errorLogs?.length > 0">
    <p id="table-description" style="display: none">Error log summary table</p>
    <div id="table--container">
      <table aria-describedby="table-description">
        <thead>
          <tr>
            <th id="check" scope="col"></th>
            <th id="device-name" scope="col">
              <div id="table-header">
                <span>Device</span>
              </div>
            </th>
            <th id="error-text" scope="col">
              <div id="table-header">
                <span>Error Text</span>
              </div>
            </th>
            <th
              id="created-at"
              scope="col"
              (click)="onSort('CreatedAt')"
              [ngClass]="getClasses('CreatedAt')"
            >
              <div id="table-header">
                <span>Created At</span>
                <mat-icon
                  aria-label="sort direction"
                  matTooltip="Sort direction"
                  >swap_vert</mat-icon
                >
              </div>
            </th>
            <th
              id="viewed"
              scope="col"
              (click)="onSort('Viewed')"
              [ngClass]="getClasses('Viewed')"
            >
              <div id="table-header">
                <span>Viewed?</span>
                <mat-icon
                  aria-label="sort direction"
                  matTooltip="Sort direction"
                  >swap_vert</mat-icon
                >
              </div>
            </th>
            <th id="viewed-btn" scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let errorLog of errorLogs; let i = index">
            <td headers="check" id="checkbox-column">
              <app-checkbox-group-no-label
                [option]="getCheckbox(i)"
                [parentForm]="errorLogTableControls"
                [formArrayGroupName]="errorLogCheckboxes.key"
                (checkboxClicked)="errorLogCheckboxClicked($event)"
                [isDisabled]="errorLog.viewed"
              >
              </app-checkbox-group-no-label>
            </td>
            <td headers="device-name">{{ errorLog.deviceName }}</td>
            <td headers="error-text" id="error-text-col">
              <span class="error-text">{{ errorLog.errorText }}</span>
              <app-icon-button
                label="view detail"
                buttonStyle="transparent--blue"
                buttonId="detail-btn"
                buttonShape="square"
                (buttonClicked)="onViewErrorLog(errorLog)"
              ></app-icon-button>
            </td>
            <td headers="created-at">
              {{ errorLog.createdAt | date : "HH:mm:ss, dd/MM/yy" }}
            </td>
            <td headers="viewed" id="viewed-col">
              <mat-icon *ngIf="errorLog.viewed" class="positive"
                >check</mat-icon
              >
              <mat-icon *ngIf="!errorLog.viewed" class="negative"
                >close</mat-icon
              >
            </td>
            <td headers="viewed-btn" id="view-cell">
              <div>
                <app-button
                  *ngIf="!errorLog.viewed"
                  label="Mark as Viewed"
                  [buttonId]="errorLog?.id"
                  (buttonClicked)="onSetErrorLogAsRead([errorLog.id], true)"
                >
                </app-button>
                <app-button
                  *ngIf="errorLog.viewed"
                  label="Mark as Unviewed"
                  [buttonId]="errorLog?.id"
                  (buttonClicked)="onSetErrorLogAsRead([errorLog.id], false)"
                >
                </app-button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div id="pagination--container">
        <div class="logs-per-page--container">
          <label for="logs-per-page">Error logs per page</label>
          <app-dropdown-form-field
            id="logs-per-page"
            [field]="errorLogsPerPageField"
            [parentForm]="errorLogTableControls"
          >
          </app-dropdown-form-field>
        </div>
        <div id="pagination--controls">
          <app-icon-button
            iconName="chevron_left"
            buttonId="previous"
            (buttonClicked)="getPrevious()"
            [isDisabled]="!hasPrevious"
          ></app-icon-button>
          <span> {{ currentPage }} / {{ totalPages }} </span>
          <app-icon-button
            iconName="chevron_right"
            buttonId="next"
            (buttonClicked)="getNext()"
            [isDisabled]="!hasNext"
          ></app-icon-button>
        </div>
      </div>
    </div>
  </div>
  <div
    id="cta--container"
    *ngIf="!isLoading && (!errorLogs || errorLogs.length === 0)"
  >
    <h4>No error logs detected</h4>
    <app-icon-button
      *ngIf="displayResetButton"
      label="reset"
      buttonStyle="secondary"
      buttonId="filter-btn"
      iconName="restart_alt"
      buttonShape="square"
      (buttonClicked)="resetSearch()"
    ></app-icon-button>
  </div>
  <p *ngIf="error" class="error">Error: {{ error.message }}</p>
</div>
<div id="loading--container" *ngIf="isLoading && !errorLogs">
  <app-loading-spinner></app-loading-spinner>
</div>
<app-modal
  id="error-log-modal"
  [titleText]="detailViewLog?.errorText"
  [primaryText]="detailViewLog?.detailedErrorText"
  primaryButtonText="Close"
  primaryButtonId="close"
  (modalButtonClicked)="modalButtonClicked()"
></app-modal>
