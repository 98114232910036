import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ErrorObject } from 'src/app/_models/error';
import { FormBase } from 'src/app/_models/form-base';
import { PhBuffer } from 'src/app/_models/ph-buffer';
import { Role } from 'src/app/_models/role';
import { TabOptions } from 'src/app/_models/tab-options';
import { User } from 'src/app/_models/user';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { DeviceService } from 'src/app/_services/device.service';
import { LaboratoryService } from 'src/app/_services/laboratory.service';
import { ModalService } from 'src/app/_services/modal.service';

@Component({
  selector: 'app-view-buffers',
  templateUrl: './view-buffers.component.html',
  styleUrls: ['./view-buffers.component.scss'],
})
export class ViewBuffersComponent implements OnInit {
  getBuffersError: ErrorObject;
  deleteBufferError: ErrorObject;
  isLoading = false;
  isProcessing = false;
  phBuffers: PhBuffer[] = [];
  navOptions: TabOptions[];
  currentUser: User;
  currentLabOrgId: number;
  deleteBufferSuccessMessage: string;

  // Make Role enum available in template
  Role = Role;

  // Pagination
  pageRequested = 1;
  pageSize = 5;
  totalCount = 0;
  totalPages = 0;
  currentPage = 1;
  hasNext = false;
  hasPrevious = false;

  // Table control variables
  currentSort: {
    sortBy: string;
    sortDirection: string;
  } = {
    sortBy: 'Name',
    sortDirection: 'asc',
  };

  buffersPerPageField = new FormBase({
    key: 'buffersPerPage',
    label: 'Page Size',
    type: 'dropdown',
    placeholder: '',
    disabled: false,
    required: false,
    value: '5',
    options: [
      { key: '5', value: '5' },
      { key: '10', value: '10' },
      { key: '25', value: '25' },
      { key: '50', value: '50' },
      { key: '100', value: '100' },
    ],
  });

  bufferTableControls = this.formBuilder.group({
    [this.buffersPerPageField.key]: [this.buffersPerPageField.value],
  });

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly deviceService: DeviceService,
    private readonly modalService: ModalService,
    private readonly authenticationService: AuthenticationService,
    private readonly laboratoryService: LaboratoryService
  ) {}

  ngOnInit(): void {
    this.navOptions = [
      {
        value: 'addBuffer',
        labelText: 'Add new buffer',
        link: '/buffer/create-buffer',
      },
      {
        value: 'viewBuffers',
        labelText: 'View buffers',
        link: '/buffer/view-buffers',
      },
    ];

    this.currentUser = this.authenticationService.currentUserValue;

    const labId = this.authenticationService.selectedLaboratory.laboratoryId;

    // Get current lab to get org id
    this.getLab(labId);

    // Subscribe to the value change on buffers per page dropdown
    this.bufferTableControls
      .get(this.buffersPerPageField.key)
      .valueChanges.subscribe((numOfDevices) => {
        this.pageSize = numOfDevices as number;
        // Now also reset page number request to one as we are changing the pagination range
        this.pageRequested = 1;
        this.getPhBuffers();
      });
  }

  getPrevious(): void {
    this.pageRequested = this.currentPage - 1;
    this.getPhBuffers();
  }

  getNext(): void {
    this.pageRequested = this.currentPage + 1;
    this.getPhBuffers();
  }

  getClasses(sortTerm: string): string[] {
    const active = this.currentSort.sortBy === sortTerm ? 'active' : '';
    return [active];
  }

  onSort(sortTerm: string): void {
    if (this.currentSort.sortBy === sortTerm) {
      if (this.currentSort.sortDirection === 'asc') {
        this.currentSort.sortDirection = 'desc';
      } else {
        this.currentSort.sortDirection = 'asc';
      }
    } else {
      this.currentSort.sortBy = sortTerm;
      this.currentSort.sortDirection = 'asc';
    }
    this.getPhBuffers();
  }

  getLab(labId: number): void {
    this.isLoading = true;
    this.getBuffersError = null;
    this.laboratoryService.getLab(labId).subscribe({
      next: (response) => {
        this.currentLabOrgId = response.organisationId;
        this.getPhBuffers();
      },
      error: (error: ErrorObject) => {
        this.isLoading = false;
        this.getBuffersError = error;
      },
    });
  }

  getPhBuffers(): void {
    this.isLoading = true;
    this.getBuffersError = null;
    // Only pass in org id if Ogi_Super_Administrator is viewing lab in different org
    this.deviceService
      .getBuffers(
        this.currentSort.sortBy,
        this.currentSort.sortDirection,
        this.pageSize,
        this.pageRequested,
        this.currentLabOrgId.toString() !==
          this.currentUser.organisationId.toString()
          ? this.currentLabOrgId
          : null
      )
      .subscribe({
        next: (response) => {
          const { buffers, paginationData } = response;
          const { CurrentPage, PageSize, TotalPages, HasNext, HasPrevious } =
            paginationData;

          this.phBuffers = buffers;

          // Set pagination vars from response headers
          this.currentPage = CurrentPage;
          this.pageSize = PageSize;
          this.totalPages = TotalPages;
          this.hasNext = HasNext;
          this.hasPrevious = HasPrevious;
          this.deviceService.onUpdatePageNumber(this.currentPage);
          this.deviceService.onUpdatePageSize(this.pageSize);
          this.isLoading = false;
        },
        error: (error: ErrorObject) => {
          this.isLoading = false;
          this.getBuffersError = error;
        },
      });
  }

  deleteBuffer(bufferId: string): void {
    // If request has already been submitted, exit
    if (this.isProcessing) {
      return;
    }

    this.deleteBufferError = null;
    this.deleteBufferSuccessMessage = '';

    this.deviceService.deleteBuffer(bufferId).subscribe({
      next: (response: string) => {
        this.isProcessing = false;
        if (response) {
          this.deleteBufferSuccessMessage = response;
          this.openModal('delete-success');
        }
        this.getPhBuffers();
      },
      error: (error: ErrorObject) => {
        this.isProcessing = false;
        this.deleteBufferError = error;
        this.openModal('discard-error-response');
      },
    });
  }

  openModal(modalId: string): void {
    this.modalService.open(modalId);
  }

  closeModal(modalId: string): void {
    if (modalId === 'discard-error-response') {
      // Reset discard response modal text
      this.deleteBufferError = null;
    }
    this.modalService.close(modalId);
  }
}
