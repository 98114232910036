<div class="modal--container">
  <div class="modal-body" [ngClass]="classes">
    <div *ngIf="displayLogo" class="icon--container">
      <app-svg-template
        [iconName]="logo"
        width="108"
        height="27"
        customViewBox="0 0 108 27"
      ></app-svg-template>
    </div>
    <div
      #lottieContainer
      id="lottie-container"
      *ngIf="animationType === 'test-tubes' || animationType === 'lock'"
    ></div>
    <mat-icon *ngIf="modalStyle === 'success'" class="green-icon"
      >check_circle</mat-icon
    >
    <mat-icon *ngIf="modalStyle === 'error'" class="red-icon">error</mat-icon>
    <mat-icon *ngIf="modalStyle === 'warning'" class="red-icon"
      >warning</mat-icon
    >
    <h4>{{ titleText }}</h4>
    <p class="body1 primaryText">
      {{ primaryText }}
    </p>
    <div id="icon--container" *ngIf="animationType === 'spinner'">
      <svg
        version="1.1"
        id="tick"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 37 37"
        xml:space="preserve"
      >
        <defs>
          <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" stop-color="#0c7bb4" />
            <stop offset="100%" stop-color="#5cba9a" />
          </linearGradient>
        </defs>
        <path
          [ngClass]="circleClasses"
          d="
	M30.5,6.5L30.5,6.5c6.6,6.6,6.6,17.4,0,24l0,0c-6.6,6.6-17.4,6.6-24,0l0,0c-6.6-6.6-6.6-17.4,0-24l0,0C13.1-0.2,23.9-0.2,30.5,6.5z"
        />
        <polyline
          [ngClass]="tickClasses"
          points="
11.6,20 15.9,24.2 26.4,13.8 "
          stroke-linecap="round"
        />
      </svg>
    </div>
    <div [ngClass]="buttonContainerClasses">
      <app-button
        *ngIf="buttonType === 'double-button'"
        buttonStyle="secondary"
        [label]="secondaryButtonText"
        [buttonId]="secondaryButtonId"
        (buttonClicked)="buttonEventHandler(secondaryButtonId)"
        [isDisabled]="isProcessing"
      ></app-button>
      <app-button
        *ngIf="buttonType"
        buttonStyle="standard"
        [label]="primaryButtonText"
        [buttonId]="primaryButtonId"
        (buttonClicked)="buttonEventHandler(primaryButtonId)"
        [isDisabled]="isProcessing"
      ></app-button>
    </div>
  </div>
</div>
<div class="modal--backdrop"></div>
