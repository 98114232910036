<div id="title--container">
  <h4>Approvals</h4>
  <h5>{{ laboratory?.laboratoryName }}</h5>
</div>
<hr />
@if (!loading) {
  <div id="approvals--container">
    <div id="nav--container">
      <app-option-tab-toggle
        id="toggle--container"
        radioGroup="navigationToggle"
        [selectOptions]="navOptions"
        type="round"
      >
      </app-option-tab-toggle>
    </div>
    @if (deviceChangeRequests?.length > 0) {
      <div id="inner-page--container">
        <p id="table-description" style="display: none">
          Device change requests table
        </p>
        <div id="table--container">
          <table aria-describedby="table-description">
            <thead>
              <tr>
                <th
                  id="request-type"
                  scope="col"
                  (click)="onSort('RequestType')"
                  [class.active]="currentSort.sortBy === 'RequestType'"
                >
                  <div id="table-header">
                    <span>Action</span>
                    <mat-icon
                      aria-label="sort direction"
                      matTooltip="Sort direction"
                      >swap_vert</mat-icon
                    >
                  </div>
                </th>
                <th id="request-description" scope="col">
                  <div id="table-header">
                    <span>Description</span>
                  </div>
                </th>
                <th
                  id="device-name"
                  scope="col"
                  (click)="onSort('Name')"
                  [class.active]="currentSort.sortBy === 'Name'"
                >
                  <div id="table-header">
                    <span>Name</span>
                    <mat-icon
                      aria-label="sort direction"
                      matTooltip="Sort direction"
                      >swap_vert</mat-icon
                    >
                  </div>
                </th>
                <th id="mac-address" scope="col">
                  <div id="table-header">
                    <span>MAC Address</span>
                  </div>
                </th>
                <th id="pump-tube" scope="col">
                  <div id="table-header">
                    <span>Pump Tube Length</span>
                  </div>
                </th>
                <th
                  id="lab"
                  scope="col"
                  (click)="onSort('laboratoryId')"
                  [class.active]="currentSort.sortBy === 'laboratoryId'"
                >
                  <div id="table-header">
                    <span>Laboratory</span>
                    <mat-icon
                      aria-label="sort direction"
                      matTooltip="Sort direction"
                      >swap_vert</mat-icon
                    >
                  </div>
                </th>
                <th
                  id="status"
                  scope="col"
                  (click)="onSort('RequestStatus')"
                  [class.active]="currentSort.sortBy === 'RequestStatus'"
                >
                  <div id="table-header">
                    <span>Status</span>
                    <mat-icon
                      aria-label="sort direction"
                      matTooltip="Sort direction"
                      >swap_vert</mat-icon
                    >
                  </div>
                </th>
                <th
                  id="requested-by"
                  scope="col"
                  (click)="onSort('RequestedBy')"
                  [class.active]="currentSort.sortBy === 'RequestedBy'"
                >
                  <div id="table-header">
                    <span>Requested By</span>
                    <mat-icon
                      aria-label="sort direction"
                      matTooltip="Sort direction"
                      >swap_vert</mat-icon
                    >
                  </div>
                </th>
                <th
                  id="request-created"
                  scope="col"
                  (click)="onSort('CreatedAt')"
                  [class.active]="currentSort.sortBy === 'CreatedAt'"
                >
                  <div id="table-header">
                    <span>Requested On</span>
                    <mat-icon
                      aria-label="sort direction"
                      matTooltip="Sort direction"
                      >swap_vert</mat-icon
                    >
                  </div>
                </th>
                <th
                  id="actioned-by"
                  scope="col"
                  (click)="onSort('ActionedBy')"
                  [class.active]="currentSort.sortBy === 'ActionedBy'"
                >
                  <div id="table-header">
                    <span>Actioned By</span>
                    <mat-icon
                      aria-label="sort direction"
                      matTooltip="Sort direction"
                      >swap_vert</mat-icon
                    >
                  </div>
                </th>
                <th
                  id="request-actioned"
                  (click)="onSort('UpdatedAt')"
                  [class.active]="currentSort.sortBy === 'UpdatedAt'"
                >
                  <div id="table-header">
                    <span>Actioned On</span>
                  </div>
                </th>
                <th
                  id="info"
                  scope="col"
                  [class.active]="currentSort.sortBy === 'Info'"
                >
                  <div id="table-header">
                    <span>Info</span>
                  </div>
                </th>

                <th id="action" scope="col">
                  <div id="table-header">
                    <span>Action</span>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              @for (request of deviceChangeRequests; track request.id) {
                <tr>
                  <td headers="request-type">{{ request.requestType }}</td>
                  <td headers="request-description">
                    {{ request.updateDifferences || "-" }}
                  </td>
                  <td headers="device-name">{{ request.name }}</td>
                  <td headers="mac-address">{{ request.macAddress }}</td>
                  <td headers="pump-tube">{{ request.pumpTubeLength }}</td>
                  <td headers="lab">{{ request.laboratoryName }}</td>
                  <td headers="status">{{ request.requestStatus }}</td>
                  <td headers="requested-by">{{ request.requestedBy }}</td>
                  <td headers="request-created">
                    {{ request.createdAt | date: "dd/MM/yy, HH:mm a" }}
                  </td>
                  <td headers="actioned-by">{{ request.actionedBy }}</td>
                  <td headers="request-actioned">
                    {{
                      request.updatedAt === request.createdAt
                        ? "-"
                        : (request.updatedAt | date: "dd/MM/yy, HH:mm a")
                    }}
                  </td>
                  <td headers="info">
                    {{ request.requestInfo }}
                  </td>
                  <td headers="action">
                    <div id="button--container">
                      @if (request.requestStatus === "Pending") {
                        <app-button
                          buttonId="approve-btn"
                          buttonStyle="positive"
                          label="Approve"
                          (buttonClicked)="approveDeviceRequest(request)"
                          [isDisabled]="
                            request.requestedBy === currentUser?.email
                          "
                        ></app-button>
                        <app-button
                          buttonId="reject-btn"
                          buttonStyle="negative"
                          label="Reject"
                          (buttonClicked)="openRejectRequestModal(request)"
                        ></app-button>
                      }
                    </div>
                  </td>
                </tr>
              }
            </tbody>
          </table>
          <div id="pagination--container">
            <div class="filter--container">
              <label for="approvals-action">Filter by action</label>
              <app-dropdown-form-field
                id="action-filter"
                [field]="requestActionFilters"
                [parentForm]="tableControls"
              >
              </app-dropdown-form-field>
            </div>
            <div class="filter--container">
              <label for="approvals-status">Filter by status</label>
              <app-dropdown-form-field
                id="status-filter"
                [field]="requestStatusFilters"
                [parentForm]="tableControls"
              >
              </app-dropdown-form-field>
            </div>
            <div class="filter--container">
              <label for="requests-per-page">Requests per page</label>
              <app-dropdown-form-field
                id="requests-per-page"
                [field]="requestsPerPageField"
                [parentForm]="tableControls"
              >
              </app-dropdown-form-field>
            </div>
            <div id="pagination--controls">
              <app-icon-button
                iconName="chevron_left"
                buttonId="previous"
                (buttonClicked)="getPrevious()"
                [isDisabled]="!hasPrevious"
              ></app-icon-button>
              <span> {{ currentPage }} / {{ totalPages }} </span>
              <app-icon-button
                iconName="chevron_right"
                buttonId="next"
                (buttonClicked)="getNext()"
                [isDisabled]="!hasNext"
              ></app-icon-button>
            </div>
          </div>
        </div>
      </div>
    }
    @if (
      !loading && (!deviceChangeRequests || deviceChangeRequests.length === 0)
    ) {
      <div id="cta--container">
        <h4>No device change requests detected</h4>
        @if (error) {
          <p class="error">Error: {{ error.message }}</p>
        }
      </div>
    }
  </div>
} @else {
  <div id="loading-spinner"><app-loading-spinner></app-loading-spinner></div>
}
<app-modal
  id="success"
  titleText="Request Approved"
  primaryText="The device request has been approved."
  primaryButtonText="Continue"
  primaryButtonId="close"
  modalStyle="success"
  (modalButtonClicked)="modalButtonClicked($event)"
></app-modal>
<app-modal
  id="rejection-success"
  titleText="Request Rejected"
  primaryText="The device request has been rejected."
  primaryButtonText="Continue"
  primaryButtonId="close-rejection-success"
  modalStyle="success"
  (modalButtonClicked)="modalButtonClicked($event)"
></app-modal>
<app-modal
  id="rejection-fail"
  titleText="Error"
  [primaryText]="error?.message || 'Something went wrong'"
  primaryButtonText="Continue"
  primaryButtonId="close-rejection-warning"
  modalStyle="error"
  (modalButtonClicked)="modalButtonClicked($event)"
></app-modal>
<app-provide-info-modal
  id="provide-info"
  [approvalRequest]="deviceApprovalUpdate"
  (rejectionConfirmed)="rejectRequest($event)"
></app-provide-info-modal>
