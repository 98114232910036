<section [ngClass]="classes">
  <label class="overline" [for]="field.key" [formGroup]="parentForm">
    {{ field.label }}
    <span *ngIf="field.required" class="required" aria-required="true">
      *
    </span>
    <div class="underlined-form-field-input-container">
      <textarea
        [formControlName]="field.key"
        class="body1 underlined-form-field-input"
        [placeholder]="field.placeholder"
        [attr.aria-required]="field.required"
        [attr.rows]="rows"
        [attr.cols]="columns"
      ></textarea>
    </div>
  </label>
  <ul *ngIf="hasErrors">
    <li
      class="underlined-form-field-error caption"
      *ngFor="let error of errorsList"
    >
      {{ error }}
    </li>
  </ul>
</section>
