/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormBase } from 'src/app/_models/form-base';
import { map, Observable, startWith } from 'rxjs';
import { ERROR_MESSAGE } from 'src/app/_helpers/utils';

interface Option {
  key: string | number;
  value: string;
  options?: string[];
}

@Component({
  selector: 'app-underlined-dropdown-form-field',
  templateUrl: './underlined-dropdown-form-field.component.html',
  styleUrls: ['./underlined-dropdown-form-field.component.scss'],
})
export class UnderlinedDropdownFormFieldComponent implements OnInit {
  /**
   * Form base
   * @required
   */
  @Input()
  field: FormBase<string> = new FormBase();

  /**
   * Parent form name
   */
  @Input()
  parentForm: FormGroup = new FormGroup({});

  /**
   * @param {boolean} isDataList Whether the dropdown is a datalist
   */
  @Input()
  isDataList = false;

  /**
   * @param {boolean} floatLabel Float label
   */
  @Input()
  floatLabel = true;

  filteredOptions: Observable<Option[]>;

  ngOnInit() {
    if (this.isDataList) {
      this.filteredOptions = this.parentForm.controls[
        this.field.key
      ].valueChanges.pipe(
        startWith(''),
        map((value) => this._filter(value || ''))
      );
    }
  }

  private _filter(value: string): Option[] {
    return this.field.options.filter((option) =>
      option.value.toLowerCase().includes(value)
    );
  }

  displayFn(option: Option): string {
    return option.value;
  }

  public get hasErrors(): boolean {
    return (
      this.parentForm.controls[this.field.key] &&
      this.parentForm.controls[this.field.key]?.errors &&
      this.parentForm.controls[this.field.key]?.touched
    );
  }

  public get errorsList(): string[] {
    return Object.keys(this.parentForm.controls[this.field.key].errors).map(
      (err: string) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        return ERROR_MESSAGE[err](
          this.parentForm.controls[this.field.key].getError(err)
        ) as string;
      }
    );
  }
}
