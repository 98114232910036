import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { integerValidator } from 'src/app/_helpers/integer-validator.validator';
import { stepValidator } from 'src/app/_helpers/step-validator.validator';
import { bufferRegex, twoDecimalPlaceRegex } from 'src/app/_helpers/utils';
import { ErrorObject } from 'src/app/_models/error';
import { FormBase } from 'src/app/_models/form-base';
import { Laboratory } from 'src/app/_models/laboratory';
import { PhBuffer, PhTempPair } from 'src/app/_models/ph-buffer';
import { TabOptions } from 'src/app/_models/tab-options';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { DeviceService } from 'src/app/_services/device.service';
import { LaboratoryService } from 'src/app/_services/laboratory.service';
import { ModalService } from 'src/app/_services/modal.service';

@Component({
  selector: 'app-create-buffer',
  templateUrl: './create-buffer.component.html',
  styleUrls: ['./create-buffer.component.scss'],
})
export class CreateBufferComponent implements OnInit {
  // Permissions
  laboratory: Laboratory;

  createBufferError: ErrorObject;
  isProcessing = false;
  isLoading = false;
  minNameLength = 3;
  maxNameLength = 16;
  minTemperature = 0;
  maxTemperature = 100;
  temperatureStep = 1;
  minPh = 0;
  maxPh = 14;
  pHStep = 0.01;
  phTempValueList: PhTempPair[] = [];
  navOptions: TabOptions[];
  currentUserOrgId: number;
  currentLabOrgId: number;
  createBufferSuccessMessage: string;

  allowNavigation: Subject<boolean> = new Subject<boolean>();

  bufferNameField = new FormBase({
    key: 'name',
    label: 'Name of buffer',
    type: 'text',
    placeholder: 'Name',
    disabled: false,
    required: true,
    value: '',
    options: [],
  });

  bufferTemperatureField = new FormBase<number>({
    key: 'temperature',
    label: 'Temperature (°C)',
    type: 'number',
    placeholder: 'Temperature',
    disabled: false,
    required: false,
    value: 0.0,
    options: [],
  });

  bufferPhField = new FormBase<number>({
    key: 'ph',
    label: 'pH',
    type: 'number',
    placeholder: 'pH',
    disabled: false,
    required: false,
    value: 0.0,
    options: [],
  });

  decimalPlaceRegex = twoDecimalPlaceRegex;

  bufferForm = this.formBuilder.group({
    [this.bufferNameField.key]: [
      this.bufferNameField.value,
      [
        Validators.required,
        Validators.minLength(this.minNameLength),
        Validators.maxLength(this.maxNameLength),
        Validators.pattern(bufferRegex),
      ],
    ],
    [this.bufferTemperatureField.key]: [
      this.bufferTemperatureField.value,
      [
        Validators.min(this.minTemperature),
        Validators.max(this.maxTemperature),
        stepValidator(this.temperatureStep),
        integerValidator(),
      ],
    ],
    [this.bufferPhField.key]: [
      this.bufferPhField.value,
      [
        Validators.min(this.minPh),
        Validators.max(this.maxPh),
        Validators.pattern(this.decimalPlaceRegex),
        stepValidator(this.pHStep),
      ],
    ],
  });

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly modalService: ModalService,
    private readonly deviceService: DeviceService,
    private readonly router: Router,
    private readonly authenticationService: AuthenticationService,
    private readonly laboratoryService: LaboratoryService
  ) {}

  ngOnInit(): void {
    this.laboratory = this.authenticationService.selectedLaboratory;

    this.navOptions = [
      {
        value: 'addBuffer',
        labelText: 'Add new buffer',
        link: '/buffer/create-buffer',
      },
      {
        value: 'viewBuffers',
        labelText: 'View buffers',
        link: '/buffer/view-buffers',
      },
    ];

    // Get current user org id
    this.currentUserOrgId =
      this.authenticationService.currentUserValue.organisationId;

    const labId = this.authenticationService.selectedLaboratory.laboratoryId;

    // Get current lab to get org id
    this.getLab(labId);
  }

  getLab(labId: number): void {
    this.isLoading = true;

    this.laboratoryService.getLab(labId).subscribe({
      next: (response) => {
        this.currentLabOrgId = response.organisationId;
        this.isLoading = false;
      },
      error: () => {
        this.isLoading = false;
      },
    });
  }

  addPhTempValuePairToBuffer(): void {
    this.createBufferError = null;
    if (
      this.phTempValueList.length <= 14 &&
      this.bufferForm.get(this.bufferTemperatureField.key).valid &&
      this.bufferForm.get(this.bufferPhField.key).valid
    ) {
      this.phTempValueList.push({
        temperature: this.bufferForm.value[
          this.bufferTemperatureField.key
        ] as number,
        phValue: this.bufferForm.value[this.bufferPhField.key] as number,
      });
      this.bufferForm.controls[this.bufferTemperatureField.key].setValue(
        this.bufferTemperatureField.value
      );
      this.bufferForm.controls[this.bufferPhField.key].setValue(
        this.bufferPhField.value
      );
    } else {
      if (
        this.bufferForm.get(this.bufferTemperatureField.key).invalid ||
        this.bufferForm.get(this.bufferPhField.key).invalid
      ) {
        this.createBufferError = {
          status: 400,
          message: 'Please enter valid values for pH and temperature.',
        };
      } else if (this.phTempValueList.length > 14) {
        this.createBufferError = {
          status: 400,
          message: 'You can only have 14 pH values in a buffer.',
        };
      }
    }
  }

  removePhTempValuePairFromBuffer(index: number): void {
    this.phTempValueList.splice(index, 1);
  }

  onSubmit(): void {
    this.createBufferError = null;
    this.createBufferSuccessMessage = '';
    if (this.bufferForm.valid) {
      this.isProcessing = true;
    } else {
      this.isProcessing = false;
    }

    // Only pass in org id if Ogi_Super_Administrator is viewing lab in different org
    const buffer: PhBuffer = {
      name: this.bufferForm.value[this.bufferNameField.key] as string,
      laboratoryId: null, // This gets set at the service level
      temperaturePHValuePairs: this.phTempValueList,
      id: null,
      devices: null,
      organisationId:
        this.currentLabOrgId.toString() !== this.currentUserOrgId.toString()
          ? this.currentLabOrgId
          : null,
    };

    this.deviceService.createBuffer(buffer).subscribe({
      next: (response: string) => {
        this.isProcessing = false;
        this.createBufferError = null;
        this.phTempValueList = [];
        this.bufferForm.reset();
        this.createBufferSuccessMessage = response;
        this.openModal('buffer-response');
      },
      error: (error: ErrorObject) => {
        this.isProcessing = false;
        this.createBufferError = error;
        this.openModal('error');
      },
    });
  }

  modalButtonClicked(buttonId: string): void {
    switch (buttonId) {
      case 'create':
        this.bufferForm.reset();
        this.bufferForm.controls[this.bufferTemperatureField.key].setValue(0.0);
        this.bufferForm.controls[this.bufferPhField.key].setValue(0.0);
        this.bufferForm.markAsUntouched();
        this.closeModal('buffer-response');
        break;
      case 'success-primary-button':
        this.closeModal('buffer-response');
        this.allowNavigation.next(true);
        void this.router.navigate(['/dashboard']);
        break;
      case 'confirm-navigation':
        this.allowNavigation.next(true);
        break;
      case 'close-button':
        this.closeModal('attention');
        this.closeModal('error');
        break;
      default:
        break;
    }
  }

  openModal(modalId: string): void {
    this.modalService.open(modalId);
  }

  closeModal(modalId: string): void {
    this.modalService.close(modalId);
  }

  canExit(): Promise<boolean> | boolean {
    return this.isNavigationAllowed();
  }

  private isNavigationAllowed(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      if (this.bufferForm.pristine) {
        resolve(true);
      } else {
        this.openModal('attention');
        this.allowNavigation.subscribe((isConfirmed) => resolve(isConfirmed));
      }
    });
  }
}
