import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy, inject } from '@angular/core';
import { BehaviorSubject, Observable, Subject, timer } from 'rxjs';
import {
  map,
  retry,
  share,
  startWith,
  switchMap,
  takeUntil,
} from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Device, DeviceUpdate, DeviceUpdateStatus } from '../_models/device';
import {
  DeviceCreateResponse,
  DeviceResponse,
  DeviceUpdateResponse,
} from '../_models/api-responses';
import { PaginationData } from '../_models/pagination-data.model';
import { DeviceSetupStatus } from '../_models/device-setup-status';
import { Module } from '../_models/module-enum';
import { httpOptions } from '../_helpers/utils';
import { AuthenticationService } from './authentication.service';
import { Laboratory } from '../_models/laboratory';

@Injectable({
  providedIn: 'root',
})
export class DeviceService implements OnDestroy {
  private readonly http = inject(HttpClient);
  private readonly authenticationService = inject(AuthenticationService);

  httpOptions = httpOptions;
  selectedlaboratoryId: number;

  // Pagination and sort/filter observables for dashboard index view
  private readonly tableSort$ = new BehaviorSubject<{
    sortBy: string;
    sortDirection: string;
  }>(null);
  private readonly tableFilter$ = new BehaviorSubject<string[]>([]);
  private readonly pageNumber$ = new BehaviorSubject<number>(null);
  private readonly pageSize$ = new BehaviorSubject<number>(null);
  private readonly createDeviceCache$ = new BehaviorSubject<Device>(null);

  // Device status polling
  public device$: Observable<Device[]>;

  private readonly stopPolling$: Subject<boolean> = new Subject<boolean>();
  private readonly destroy$ = new Subject<void>();

  constructor() {
    // Set up subscription to selected laboratory
    this.authenticationService.selectedLab
      .pipe(takeUntil(this.destroy$))
      .subscribe((laboratory: Laboratory) => {
        if (this.selectedlaboratoryId !== laboratory?.laboratoryId) {
          this.selectedlaboratoryId = laboratory?.laboratoryId;
        }
      });
  }

  onUpdateSort = (sort: { sortBy: string; sortDirection: string }): void => {
    this.tableSort$.next(sort);
  };

  onUpdateFilter = (filter: string[]): void => {
    this.tableFilter$.next(filter);
  };

  onUpdatePageNumber = (pageNumber: number): void => {
    this.pageNumber$.next(pageNumber);
  };

  onUpdatePageSize = (pageSize: number): void => {
    this.pageSize$.next(pageSize);
  };

  onUpdateCreateDeviceCache = (createDevice: Device): void => {
    this.createDeviceCache$.next(createDevice);
  };

  public get filter(): string[] {
    return this.tableFilter$.value;
  }

  public get sort(): { sortBy: string; sortDirection: string } {
    return this.tableSort$.value;
  }

  public get paginationCache(): { pageNumber: number; pageSize: number } {
    if (this.pageNumber$.value !== null && this.pageSize$.value !== null) {
      return {
        pageNumber: this.pageNumber$.value,
        pageSize: this.pageSize$.value,
      };
    } else {
      return null;
    }
  }

  public get createDeviceCache(): Device {
    return this.createDeviceCache$.value;
  }

  // HTTP GET /DEVICES
  getDevices(
    sortBy = 'DeviceStatusId',
    sortOrder = 'asc',
    pageSize = 5,
    pageNumber = 1,
    moduleList?: Module[],
    deviceStatusId: DeviceSetupStatus = null,
    isOffline: boolean = null,
    includeDeleted: boolean = null,
    search: string = null,
  ): Observable<DeviceResponse> {
    let url = `${environment.apiUrl}/devices?laboratoryId=${this.selectedlaboratoryId}&`;
    if (moduleList?.length > 0) {
      moduleList.forEach((m) => {
        url = url + `Modules=${m}&`;
      });
    }
    url = url + `OrderBy=${sortBy}&OrderByDirection=${sortOrder}`;
    if (pageNumber && pageSize) {
      url = url + `&PageSize=${pageSize}&PageNumber=${pageNumber}`;
    }
    if (deviceStatusId) {
      url = url + `&deviceStatus=${deviceStatusId}`;
    }
    if (isOffline !== null) {
      url = url + `&isOffline=${isOffline.toString()}`;
    }
    if (includeDeleted !== null) {
      url = url + `&includeDeleted=${includeDeleted.toString()}`;
    }
    if (search !== null) {
      url = url + `&search=${search}`;
    }

    return this.http.get<Device[]>(url, this.httpOptions).pipe(
      map((response) => {
        // Set pagination data
        const paginationData = JSON.parse(
          response.headers.get('X-Pagination'),
        ) as PaginationData;
        const deviceList: Device[] = [];
        if (response.status === 200) {
          response.body.forEach((d: Device) => {
            const device = new Device(
              d.id,
              d.name,
              d.laboratoryId,
              d.macAddress || null,
              d.hardwareVersion || null,
              d.installedSoftwareVersion || null,
              d.softwareWarning || false,
            );
            device.odCalibrationId = d.odCalibrationId;
            device.oxygenCalibrationId = d.oxygenCalibrationId;
            device.phCalibrationId = d.phCalibrationId;
            device.deviceStatusId = d.deviceStatusId;
            device.isOffline = d.isOffline;
            device.deviceModules = d.deviceModules;
            device.experiment = d.experiment;
            device.pumpTubeLength = d.pumpTubeLength;
            deviceList.push(device);
          });
        }
        return {
          paginationData,
          deviceList,
        };
      }),
    );
  }

  // HTTP GET /DEVICE/{id}
  getDeviceById(id: string): Observable<Device> {
    return this.http.get<Device>(`${environment.apiUrl}/devices/${id}`).pipe(
      map((response) => {
        return response;
      }),
    );
  }

  // HTTP POST /DEVICE - 200 returns device id, 204 is void
  createDevice(device: Device): Observable<DeviceCreateResponse | void> {
    return this.http
      .post<number>(`${environment.apiUrl}/devices`, device, this.httpOptions)
      .pipe(
        map((response) => {
          switch (response.status) {
            case 200:
              return {
                id: response.body,
                message: 'New device added.',
              };
            case 204:
              return {
                message:
                  'New device request is pending approval; the status can be checked on the approvals page.',
              };
            default:
              throw Error('Something went wrong');
          }
        }),
      );
  }

  // HTTP PATCH /DEVICE/{id}
  updateDevice(device: DeviceUpdate): Observable<DeviceUpdateResponse | void> {
    return this.http
      .patch<Device>(
        `${environment.apiUrl}/devices/${device.id}`,
        device,
        this.httpOptions,
      )
      .pipe(
        map((response) => {
          switch (response.status) {
            case 200:
              return {
                device: response.body,
                message: 'The device has been updated.',
              };
            case 204:
              return {
                message:
                  'Update device request is pending approval; the status can be checked on the approvals page.',
              };
            default:
              throw Error('Something went wrong');
          }
        }),
      );
  }

  // HTTP PATCH /DEVICE/{id}
  updateDeviceStatus(
    deviceId: string,
    deviceStatus: DeviceUpdateStatus,
  ): Observable<DeviceUpdateStatus> {
    return this.http
      .patch<DeviceUpdateStatus>(
        `${environment.apiUrl}/devices/${deviceId}`,
        deviceStatus,
      )
      .pipe(
        map((response) => {
          return response;
        }),
      );
  }

  // Polling for device status
  getDeviceStatus(): Observable<Device[]> {
    return this.device$;
  }

  stopDevicePolling(): void {
    this.stopPolling$.next(true);
  }

  startDevicePolling(deviceIdList: number[]): Observable<Device[]> {
    // Clear previous polling
    this.stopPolling$.next(true);

    let url = `${environment.apiUrl}/devices/by-device-array?`;

    deviceIdList.forEach((id) => {
      url = url + `deviceIds=${id}&`;
    });

    const poll$ = this.http.get<Device[]>(url);

    this.device$ = timer(30000, 30000).pipe(
      startWith(-1),
      switchMap(() => poll$),
      retry(),
      share(),
      takeUntil(this.stopPolling$),
    );
    return this.device$;
  }

  // HTTP PUT /device/deviceId?deviceId=1&isPausing=true/false
  pauseDeviceOnExperiment(
    deviceId: string,
    isPausing: boolean,
  ): Observable<string> {
    return this.http
      .put<string>(
        `${
          environment.apiUrl
        }/devices/${deviceId}?isPausing=${isPausing.toString()}`,
        null,
      )
      .pipe(
        map((response: string) => {
          return response;
        }),
      );
  }

  // HTTP DELETE /DEVICE/{id}
  deleteDevice(id: string): Observable<DeviceUpdateResponse> {
    return this.http
      .delete<void>(`${environment.apiUrl}/devices/${id}`, this.httpOptions)
      .pipe(
        map((response) => {
          switch (response.status) {
            case 204:
              return {
                message:
                  'Delete device request is pending approval; the status can be checked on the approvals page.',
              };
            case 200:
              return {
                message: 'Device deleted.',
              };
            default:
              throw Error('Something went wrong');
          }
        }),
      );
  }

  ngOnDestroy(): void {
    this.stopPolling$.next(true);
    this.stopPolling$.complete();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
