<div id="new-experiment--container">
  <h4>
    Select Devices - {{ experiment?.name }}
    @if (experiment) {
      <app-status-indicator
        [status]="ExperimentStatus[experiment?.experimentStatusId]"
      ></app-status-indicator>
    }
  </h4>
  @if (error) {
    <span class="error">{{ error?.message }} </span>
  }
  <div id="nav-bar-button--container">
    <app-button
      label="back"
      buttonId="back-btn"
      type="button"
      (buttonClicked)="goBack()"
    ></app-button>
    <app-button
      label="Delete Draft"
      buttonId="cancel-btn"
      buttonStyle="negative"
      type="button"
      (buttonClicked)="openModal('delete-draft')"
      [isDisabled]="
        isProcessing ||
        deleteIsProcessing ||
        (currentUser.role
          | roleCheck
            : [
                Role.Read_Access_User,
                Role.Run_Access_User,
                Role.Edit_Access_User,
              ])
      "
    ></app-button>
  </div>
</div>
<hr />
<div class="inner-page--container">
  <div id="calibration--container">
    @if (experiment?.experimentStatusId === ExperimentStatus.Draft) {
      <h6>Add New Device</h6>
    }
    @if (experiment?.experimentStatusId === ExperimentStatus.DraftLocked) {
      <h6>Selected Devices - Draft Locked</h6>
    }
    <div class="add-device--container">
      <app-slide-toggle
        [parentForm]="deviceFilterForm"
        [field]="inactiveDeviceToggleField"
      ></app-slide-toggle>
      <app-slide-toggle
        [parentForm]="deviceFilterForm"
        [field]="onlineDeviceToggleField"
      ></app-slide-toggle>
      @if (!devicesLoading && !deviceError && availableDeviceList.length > 0) {
        <app-dropdown-form-field
          [field]="deviceSelectField"
          [parentForm]="experimentSetupForm"
        ></app-dropdown-form-field>
      }
      @if (
        !deviceError && !devicesLoading && availableDeviceList.length === 0
      ) {
        <span class="subtitle1">No devices available</span>
      }
      @if (deviceError) {
        <span class="error">{{ deviceError.message }}</span>
      }
      @if (devicesLoading) {
        <div class="subtitle1">
          <app-small-loading-spinner></app-small-loading-spinner>
        </div>
      }
      <app-icon-button
        label="add device"
        labelClass="large"
        buttonId="add-device-btn"
        buttonShape="square"
        iconName="add_circle"
        (buttonClicked)="addDeviceToExperiment()"
        [isDisabled]="
          !experimentSetupForm.controls[deviceSelectField.key].value
        "
      ></app-icon-button>
    </div>
    <hr />
    @if (selectedDeviceList.length > 0) {
      <h6>Devices</h6>
      <div class="device-selection--container">
        <form [formGroup]="experimentSetupForm">
          @for (device of selectedDeviceList; track device.deviceId) {
            <div class="options--container">
              <div class="device-options">
                <div class="device-summary--container">
                  <span class="subtitle2">{{ device.deviceName }}</span>
                  @if (!device.isOffline) {
                    <mat-icon class="online">wifi</mat-icon>
                  }
                  @if (device.isOffline) {
                    <mat-icon class="offline">wifi_off</mat-icon>
                  }
                  @if (device) {
                    <app-status-indicator
                      [status]="DeviceSetupStatus[device.deviceStatusId]"
                    ></app-status-indicator>
                  }
                  @if (requiredDeviceModules.has(Module.LiquidControl)) {
                    <span class="row">
                      @if (device.pumpTubeLength) {
                        Pump tube length: {{ device.pumpTubeLength }} cm
                        <app-icon-button
                          buttonId="set-pump-tube-length-btn"
                          buttonStyle="transparent--blue"
                          buttonShape="square"
                          iconName="arrow_forward"
                          aria-label="Edit pump tube length"
                          label="Edit pump tube length"
                          [routerLink]="['/device', device.deviceId, 'details']"
                        >
                        </app-icon-button>
                      } @else {
                        @if (
                          experiment?.experimentStatusId !==
                          ExperimentStatus.DraftLocked
                        ) {
                          <app-icon-button
                            buttonId="set-pump-tube-length-btn"
                            buttonStyle="transparent"
                            buttonShape="square"
                            iconName="arrow_forward"
                            [routerLink]="[
                              '/device',
                              device.deviceId,
                              'details',
                            ]"
                            label="Please set pump tube length"
                          >
                          </app-icon-button>
                        }
                      }
                    </span>
                  }
                </div>
                <div class="add-device--container">
                  <div class="op-density--container">
                    @if (device.calibrationList.length > 0) {
                      <app-dynamic-dropdown-form-field
                        [parentForm]="experimentSetupForm"
                        [device]="device"
                        placeholder="Select OD Calibration"
                      ></app-dynamic-dropdown-form-field>
                    }
                    @if (
                      !odError &&
                      !odCalibrationLoading &&
                      (!device.calibrationList ||
                        device.calibrationList.length === 0)
                    ) {
                      <span class="subtitle1">
                        No optical density calibrations
                      </span>
                    }
                    @if (odError) {
                      <span class="error">{{ odError.message }}</span>
                    }
                    @if (odCalibrationLoading) {
                      <div class="subtitle1">
                        <app-small-loading-spinner></app-small-loading-spinner>
                      </div>
                    }
                  </div>
                  @if (experiment.useOxygen) {
                    <div class="ox-cal--container">
                      @if (device.oxygenCalibrationList?.length > 0) {
                        <app-dynamic-dropdown-form-field
                          [parentForm]="experimentSetupForm"
                          [device]="device"
                          [calibrationType]="1"
                          placeholder="Select Oxygen Calibration"
                        ></app-dynamic-dropdown-form-field>
                      }
                      @if (
                        !oxyError &&
                        !oxygenCalibrationLoading &&
                        (!device.oxygenCalibrationList ||
                          device.oxygenCalibrationList?.length === 0)
                      ) {
                        <span class="subtitle1"> No oxygen calibrations </span>
                      }
                      @if (oxyError) {
                        <span class="error">{{ oxyError.message }}</span>
                      }
                      @if (oxygenCalibrationLoading) {
                        <div class="subtitle1">
                          <app-small-loading-spinner></app-small-loading-spinner>
                        </div>
                      }
                    </div>
                  } @else {
                    <div class="subtitle1">Oxygen module not selected</div>
                  }
                  @if (experiment.usePH) {
                    <div class="ph-cal--container">
                      @if (device.pHCalibrationList?.length > 0) {
                        <app-dynamic-dropdown-form-field
                          [parentForm]="experimentSetupForm"
                          [device]="device"
                          [calibrationType]="6"
                          placeholder="Select pH Calibration"
                        ></app-dynamic-dropdown-form-field>
                      }
                      @if (
                        !pHError &&
                        !pHCalibrationLoading &&
                        (!device.pHCalibrationList ||
                          device.pHCalibrationList?.length === 0)
                      ) {
                        <span class="subtitle1"> No pH calibrations </span>
                      }
                      @if (pHError) {
                        <span class="error">{{ pHError.message }}</span>
                      }
                      @if (pHCalibrationLoading) {
                        <div class="subtitle1">
                          <app-small-loading-spinner></app-small-loading-spinner>
                        </div>
                      }
                    </div>
                  } @else {
                    <div class="subtitle1">pH module not selected</div>
                  }
                  <app-icon-button
                    buttonId="remove-btn"
                    buttonStyle="transparent"
                    buttonShape="square"
                    textColor="red"
                    iconName="close"
                    (buttonClicked)="
                      removeDeviceFromExperiment(device.deviceId)
                    "
                  ></app-icon-button>
                </div>
              </div>
              <hr />
            </div>
          }
        </form>
      </div>
      <hr />
    }
  </div>
  @if (experiment?.experimentStatusId === ExperimentStatus.Draft) {
    <div id="next-btn--container">
      <app-button
        label="discard changes"
        buttonId="discard-btn"
        buttonStyle="yellow"
        (buttonClicked)="onDiscardChanges()"
        [isDisabled]="isProcessing || experimentSetupForm.pristine"
      ></app-button>
      <app-button
        label="save devices"
        buttonId="save-btn"
        type="submit"
        (buttonClicked)="onSaveDevices()"
        [isProcessing]="isProcessing"
        [isDisabled]="
          experimentSetupForm.invalid ||
          (experimentSetupForm.pristine && !hasDevicesToRemove) ||
          isProcessing
        "
      ></app-button>
      <app-button
        label="lock draft"
        buttonId="draft-lock-btn"
        buttonStyle="positive"
        (buttonClicked)="onLockDraft()"
        [isDisabled]="
          experimentSetupForm.dirty ||
          selectedDeviceList.length === 0 ||
          experimentSetupForm.invalid
        "
      ></app-button>
    </div>
  } @else {
    <div id="next-btn--container">
      <app-button
        label="go to dashboard"
        buttonId="go-to-dashboard"
        buttonStyle="positive"
        (buttonClicked)="goToDashboard()"
      ></app-button>
      <app-button
        label="Set up devices"
        buttonId="set-devices-btn"
        (buttonClicked)="setUpDevices()"
      ></app-button>
    </div>
  }
</div>
<app-modal
  id="error"
  titleText="Error"
  [primaryText]="error?.message"
  buttonType="single-button"
  primaryButtonText="close"
  primaryButtonId="close-button"
  modalStyle="error"
  (modalButtonClicked)="modalButtonClicked($event)"
></app-modal>
<app-validation-modal
  id="delete-draft"
  titleText="Delete draft?"
  validationText="delete"
  buttonType="double-button"
  primaryButtonText="Confirm delete"
  primaryButtonId="confirm-delete-button"
  secondaryButtonText="Cancel"
  secondaryButtonId="cancel-delete-button"
  (modalButtonClicked)="deleteDraft()"
  [error]="deleteError"
></app-validation-modal>
<app-modal
  id="delete-success"
  titleText="Success"
  primaryText="Experiment draft was deleted."
  primaryButtonText="Go to dashboard"
  primaryButtonId="delete-success-button"
  modalStyle="success"
  (modalButtonClicked)="modalButtonClicked($event)"
  allowClose="false"
>
</app-modal>
<app-modal
  id="delete-error"
  titleText="Unable to delete draft"
  [primaryText]="deleteError?.message + '. Choose an action below'"
  buttonType="double-button"
  primaryButtonText="Go to dashboard"
  primaryButtonId="error-primary-button"
  secondaryButtonText="Close"
  secondaryButtonId="error-secondary-button"
  modalStyle="error"
  (modalButtonClicked)="modalButtonClicked($event)"
  allowClose="false"
>
</app-modal>
<app-modal
  id="success"
  titleText="Success"
  primaryText="Your devices have been updated"
  buttonType="single-button"
  primaryButtonText="close"
  primaryButtonId="close-sucess-button"
  modalStyle="success"
  (modalButtonClicked)="modalButtonClicked($event)"
></app-modal>
<app-modal
  id="lock-success"
  titleText="Draft Locked"
  buttonType="double-button"
  animationType="lock"
  primaryButtonText="Set up devices"
  primaryButtonId="primary-lock"
  secondaryButtonText="Go to dashboard"
  secondaryButtonId="secondary-lock"
  (modalButtonClicked)="modalButtonClicked($event)"
></app-modal>
<app-modal
  id="attention"
  titleText="Attention"
  primaryText="Your changes have not been saved. Press cancel to return to the form and save your changes, or continue to discard."
  buttonType="double-button"
  primaryButtonText="Continue"
  primaryButtonId="confirm-navigation"
  secondaryButtonText="Cancel"
  secondaryButtonId="cancel-button"
  modalStyle="warning"
  (modalButtonClicked)="modalButtonClicked($event)"
></app-modal>
