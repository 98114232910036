import { Component } from '@angular/core';
import { IconNames } from 'src/app/_components/icons/icon-names';

@Component({
  selector: 'app-reset-sent',
  templateUrl: './reset-sent.component.html',
  styleUrls: ['./reset-sent.component.scss'],
})
export class ResetSentComponent {
  logo = IconNames.LogoColour;
}
