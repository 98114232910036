<div id="page">
  <div id="container">
    <app-svg-template
      [iconName]="logo"
      width="249"
      height="60"
      customViewBox="0 0 249 60"
    ></app-svg-template>
    <h5>Check your inbox</h5>
    <p class="subtitle1">
      We&#x27;ve sent you a link to reset your password. If you didn&#x27;t
      receive anything press the button below to resend.
    </p>
    <a [routerLink]="'/login'" class="button-styling">Return to login</a>
    <a [routerLink]="'/forgot-password'" class="button-styling">
      Request another reset link
    </a>
    <p id="support" class="body2">
      If you haven’t received a password reset notification please contact
      help&#64;ogibio.co.uk
    </p>
  </div>
</div>
