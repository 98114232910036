import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Device } from 'src/app/_models/device';
import { DeviceSetupStatus } from 'src/app/_models/device-setup-status';
import { FormatRolePipe } from '../../../_helpers/format-role.pipe';
import { StatusIndicatorComponent } from '../../chips/status-indicator/status-indicator.component';
import { MatTooltip } from '@angular/material/tooltip';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'app-device-card',
  templateUrl: './device-card.component.html',
  styleUrl: './device-card.component.scss',
  standalone: true,
  imports: [MatIcon, MatTooltip, StatusIndicatorComponent, FormatRolePipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeviceCardComponent {
  DeviceStatus = DeviceSetupStatus;
  /**
   * @param {Device} device The device to be displayed
   * @requires
   */
  @Input()
  device: Device;
}
