<div id="help--container">
  <div id="left--container">
    <img src="assets/img/ogi-logo-tagline.png" alt="OGI logo" />
  </div>
  <div id="right--container">
    <h4>Let's get started</h4>
    <div class="step">
      <div class="circle">1</div>
      <p class="subtitle2">Create a laboratory</p>
      <app-mat-icon-button
        buttonStyle="primary"
        iconName="arrow_forward"
        (buttonClicked)="dashboardButtonClicked('add-lab')"
      ></app-mat-icon-button>
    </div>
    <div class="step">
      <div class="circle">2</div>
      <p class="subtitle2">Join the laboratory</p>
      <app-mat-icon-button
        buttonStyle="primary"
        iconName="arrow_forward"
        (buttonClicked)="dashboardButtonClicked('join-lab')"
      ></app-mat-icon-button>
    </div>
    <p class="body top">
      If you would like to add devices, create experiments, create calibrations
      and view experiment/device data you must join the laboratory. If you would
      just like to be able to add users, you can skip this step.
    </p>
    <p class="body">
      If you decide you want to join the laboratory at a later date, you can add
      yourself as a member on the 'view lab' page.
    </p>
    <div class="step">
      <div class="circle">3</div>
      <p class="subtitle2">Add users to the laboratory</p>
      <app-mat-icon-button
        buttonStyle="primary"
        iconName="arrow_forward"
        (buttonClicked)="dashboardButtonClicked('add-users')"
      ></app-mat-icon-button>
    </div>
    <div
      [class]="
        !laboratory()?.isOwnLab && Role.Super_User ? 'step disabled' : 'step'
      "
    >
      <div class="circle">4</div>
      <p class="subtitle2">Add devices to the laboratory</p>
      <app-mat-icon-button
        buttonStyle="primary"
        iconName="arrow_forward"
        [isDisabled]="!laboratory()?.isOwnLab"
        (buttonClicked)="dashboardButtonClicked('add-device')"
      ></app-mat-icon-button>
    </div>
    <p class="body top">
      You can only add devices if you are a member the laboratory.
    </p>
  </div>
</div>
