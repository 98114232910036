<div *ngIf="selectedLaboratory; else noLab">
  <div id="title--container">
    <h4>Add New Device</h4>
    <h5>{{ selectedLaboratory?.laboratoryName }}</h5>
  </div>
  <hr />
  <div id="new-device--container">
    <div class="body1">
      Instructions
      <ol type="1">
        <li>Turn on the device</li>
        <li>Connect the device to the wifi network</li>
        <li>Get the mac address of the device</li>
      </ol>
    </div>
    <div class="form--container">
      <form [formGroup]="createDeviceForm" (ngSubmit)="addDevice()">
        <app-icon-underlined-form-field
          [parentForm]="createDeviceForm"
          [field]="nameField"
        ></app-icon-underlined-form-field>
        <app-icon-underlined-form-field
          [parentForm]="createDeviceForm"
          [field]="macAddressField"
        ></app-icon-underlined-form-field>
        <div class="button--container">
          <app-icon-button
            label="add device"
            buttonId="add-device-btn"
            buttonShape="square"
            buttonType="submit"
            iconName="add_circle"
            [isDisabled]="createDeviceForm.invalid"
            [isProcessing]="isProcessing"
          ></app-icon-button>
        </div>
      </form>
    </div>
  </div>
</div>
<ng-template #noLab>
  <app-error-page
    title="No Lab found"
    primaryText="Sorry, you cannot access this page unless you are a member of a lab"
  ></app-error-page>
</ng-template>
<app-modal
  id="error"
  titleText="Error"
  [primaryText]="error?.message"
  primaryButtonText="close"
  primaryButtonId="close-button"
  modalStyle="error"
  (modalButtonClicked)="modalButtonClicked($event)"
></app-modal>
<app-modal
  id="attention"
  titleText="Attention"
  primaryText="Your changes have not been saved. Press cancel to return to the form and save your changes, or continue to discard."
  buttonType="double-button"
  primaryButtonText="Continue"
  primaryButtonId="confirm-navigation"
  secondaryButtonText="Cancel"
  secondaryButtonId="cancel-button"
  modalStyle="warning"
  (modalButtonClicked)="modalButtonClicked($event)"
></app-modal>
<app-modal
  id="change-request-success"
  titleText="Success"
  [primaryText]="addDeviceSuccessMessage"
  primaryButtonText="Close"
  primaryButtonId="close-approval"
  modalStyle="success"
  (modalButtonClicked)="modalButtonClicked($event)"
></app-modal>
