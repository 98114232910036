<button
  [class]="classes()"
  (click)="clickButton()"
  [attr.id]="buttonId()"
  [attr.type]="type()"
  [disabled]="isDisabled()"
>
  <span>{{ label() }}</span>
  <div>
    @if (isProcessing()) {
      <div class="loader"></div>
    }
  </div>
</button>
