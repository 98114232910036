<div class="icons">
  <div *ngFor="let module of activeModules; let i = index">
    <svg
      *ngIf="module === Module.BaseModule"
      [attr.width]="width"
      [attr.height]="height"
      [attr.viewBox]="customViewBox"
      xmlns="http://www.w3.org/2000/svg"
      [innerHTML]="icon(opticalDensity)"
      role="img"
    ></svg>
    <svg
      *ngIf="module === Module.Oxygen"
      [attr.width]="width"
      [attr.height]="height"
      [attr.viewBox]="customViewBox"
      xmlns="http://www.w3.org/2000/svg"
      [innerHTML]="icon(oxygen)"
      role="img"
    ></svg>
    <svg
      *ngIf="module === Module.Fluorescence"
      [attr.width]="width"
      [attr.height]="height"
      [attr.viewBox]="customViewBox"
      xmlns="http://www.w3.org/2000/svg"
      [innerHTML]="icon(fluo)"
      role="img"
    ></svg>
    <svg
      *ngIf="module === Module.LiquidControl"
      [attr.width]="width"
      [attr.height]="height"
      [attr.viewBox]="customViewBox"
      xmlns="http://www.w3.org/2000/svg"
      [innerHTML]="icon(liquidControl)"
      role="img"
    ></svg>
    <svg
      *ngIf="module === Module.AirControl"
      [attr.width]="width"
      [attr.height]="height"
      [attr.viewBox]="customViewBox"
      xmlns="http://www.w3.org/2000/svg"
      [innerHTML]="icon(airControl)"
      role="img"
    ></svg>
    <svg
      *ngIf="module === Module.pH"
      [attr.width]="width"
      [attr.height]="height"
      [attr.viewBox]="customViewBox"
      xmlns="http://www.w3.org/2000/svg"
      [innerHTML]="icon(pH)"
      role="img"
    ></svg>
  </div>
</div>
