export class Calibration {
  deviceId: number;
  name: string;
  saveLocationName: string;
  temperatureControlA: boolean;
  temperatureControlB: boolean;
  temperatureControlC: boolean;
  temperatureControlD: boolean;
  inputTemperatureA?: number;
  inputTemperatureB?: number;
  inputTemperatureC?: number;
  inputTemperatureD?: number;
  id?: number;
  isFinal?: boolean;
  laboratoryId?: number;
  createdAt?: Date;
  isDeleted?: boolean;

  constructor(
    deviceId: number,
    name: string,
    saveLocationName: string,
    temperatureControlA: boolean,
    temperatureControlB: boolean,
    temperatureControlC: boolean,
    temperatureControlD: boolean
  ) {
    this.deviceId = deviceId;
    this.name = name;
    this.saveLocationName = saveLocationName;
    this.temperatureControlA = temperatureControlA;
    this.temperatureControlB = temperatureControlB;
    this.temperatureControlC = temperatureControlC;
    this.temperatureControlD = temperatureControlD;
  }
}

export interface CalibrationSlot {
  slotName: string;
  displayName: string;
  calibrationName: string;
  isEmpty: boolean;
}

export class CalibrationResults extends Calibration {
  resultsTemperatureA?: number;
  resultsTemperatureB?: number;
  resultsTemperatureC?: number;
  resultsTemperatureD?: number;
  calFactor_A?: number;
  calFactor_B?: number;
  calFactor_C?: number;
  calFactor_D?: number;
  calOffset_A?: number;
  calOffset_B?: number;
  calOffset_C?: number;
  calOffset_D?: number;
  calPower_A?: number;
  calPower_B?: number;
  calPower_C?: number;
  calPower_D?: number;
  r2_A?: number;
  r2_B?: number;
  r2_C?: number;
  r2_D?: number;
}

export interface FinaliseCalibrationInputSet {
  flaskAReading1?: number;
  flaskAReading2?: number;
  flaskAReading3?: number;
  flaskAReading4?: number;
  flaskAReading5?: number;
  flaskAReading6?: number;
  flaskBReading1?: number;
  flaskBReading2?: number;
  flaskBReading3?: number;
  flaskBReading4?: number;
  flaskBReading5?: number;
  flaskBReading6?: number;
  flaskCReading1?: number;
  flaskCReading2?: number;
  flaskCReading3?: number;
  flaskCReading4?: number;
  flaskCReading5?: number;
  flaskCReading6?: number;
  flaskDReading1?: number;
  flaskDReading2?: number;
  flaskDReading3?: number;
  flaskDReading4?: number;
  flaskDReading5?: number;
  flaskDReading6?: number;
}

export interface VoltageReadings {
  flaskAVoltageValue1?: number;
  flaskAVoltageValue2?: number;
  flaskAVoltageValue3?: number;
  flaskAVoltageValue4?: number;
  flaskAVoltageValue5?: number;
  flaskAVoltageValue6?: number;
  flaskBVoltageValue1?: number;
  flaskBVoltageValue2?: number;
  flaskBVoltageValue3?: number;
  flaskBVoltageValue4?: number;
  flaskBVoltageValue5?: number;
  flaskBVoltageValue6?: number;
  flaskCVoltageValue1?: number;
  flaskCVoltageValue2?: number;
  flaskCVoltageValue3?: number;
  flaskCVoltageValue4?: number;
  flaskCVoltageValue5?: number;
  flaskCVoltageValue6?: number;
  flaskDVoltageValue1?: number;
  flaskDVoltageValue2?: number;
  flaskDVoltageValue3?: number;
  flaskDVoltageValue4?: number;
  flaskDVoltageValue5?: number;
  flaskDVoltageValue6?: number;
}
