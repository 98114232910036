<mat-form-field
  *ngIf="!isDataList"
  appearance="fill"
  [formGroup]="parentForm"
  [floatLabel]="floatLabel ? 'always' : 'auto'"
>
  <mat-label>{{ field.label }}</mat-label>
  <mat-select
    [formControlName]="field.key"
    [required]="field.required"
    [placeholder]="field.placeholder"
    [attr.aria-required]="field.required"
    [value]="field.value"
  >
    <mat-option *ngFor="let option of field.options" [value]="option.key">
      {{ option.value }}
    </mat-option>
  </mat-select>
  <mat-error *ngIf="hasErrors">{{ errorsList[0] }}</mat-error>
</mat-form-field>
<mat-form-field
  *ngIf="isDataList"
  appearance="fill"
  [formGroup]="parentForm"
  [floatLabel]="floatLabel ? 'always' : 'auto'"
>
  <mat-label>{{ field.label }}</mat-label>
  <input
    type="text"
    [required]="field.required"
    [placeholder]="field.placeholder"
    [attr.aria-required]="field.required"
    matInput
    [formControlName]="field.key"
    [matAutocomplete]="auto"
  />
  <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
    <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
      {{ option.value }}
    </mat-option>
  </mat-autocomplete>
  <mat-error *ngIf="hasErrors">{{ errorsList[0] }}</mat-error>
</mat-form-field>
