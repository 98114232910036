@if (laboratory) {
  <div>
    <div id="title--container">
      <h4>View Lab</h4>
      <h5>{{ laboratory?.laboratoryName }}</h5>
    </div>
    <hr />
    <div id="lab--container">
      <div class="upper-page--container">
        <div class="form--container">
          <form [formGroup]="editLabForm">
            <app-underlined-form-field
              [parentForm]="editLabForm"
              [field]="labNameField"
            ></app-underlined-form-field>
          </form>
          <div class="button--container">
            <app-button
              label="Update Laboratory"
              buttonId="update-btn"
              (buttonClicked)="onSubmit()"
              [isDisabled]="
                editLabForm.invalid ||
                editLabForm.pristine ||
                (currentUser.role === Role.Super_User &&
                  !laboratory.isOwnLab) ||
                (currentUser.role
                  | roleCheck
                    : [
                        Role.Read_Access_User,
                        Role.Run_Access_User,
                        Role.Edit_Access_User,
                      ])
              "
              [isProcessing]="isUpdateLabProcessing"
            >
            </app-button>
          </div>
        </div>
        @if (
          currentUser.role
            | roleCheck
              : [
                  Role.OGI_Super_Administrator,
                  Role.OGI_Laboratory_Administrator,
                  Role.Lab_Administrator,
                  Role.Super_User,
                ]
        ) {
          @if (availableUsers?.length > 0) {
            <div class="form--container">
              <form [formGroup]="addUserForm">
                <app-underlined-dropdown-form-field
                  [field]="addUserField"
                  [parentForm]="addUserForm"
                  [isDataList]="true"
                ></app-underlined-dropdown-form-field>
              </form>
              @if (addUserError?.message) {
                <span class="error">
                  {{ addUserError.message }}
                </span>
              }
              <div class="button--container">
                <app-button
                  label="Add User"
                  buttonId="add-btn"
                  (buttonClicked)="addUserToLab()"
                  [isDisabled]="addUserForm.pristine || addUserForm.invalid"
                  [isProcessing]="isAddUserToLabProcessing"
                >
                </app-button>
              </div>
            </div>
          } @else {
            <p>No available users to add to the laboratory.</p>
          }
        }
        @if (currentUser.role | roleCheck: [Role.OGI_Super_Administrator]) {
          <div id="delete-lab--container">
            <app-icon-button
              label="delete laboratory"
              buttonStyle="negative"
              buttonId="delete-lab-btn"
              iconName="delete"
              buttonShape="square"
              (buttonClicked)="openModal('delete-lab')"
              [isDisabled]="
                currentUser.role
                  | roleCheck
                    : [
                        Role.Read_Access_User,
                        Role.Run_Access_User,
                        Role.Edit_Access_User,
                        Role.Lab_Administrator,
                        Role.OGI_Laboratory_Administrator,
                      ]
              "
            ></app-icon-button>
          </div>
        }
      </div>
      @if ((!users || users?.length === 0) && !isLoading) {
        <div id="inner-page--container">
          <p id="no-users">Laboratory does not have any members.</p>
        </div>
      }
      @if ((!users || users?.length === 0) && isLoading) {
        <div>
          <app-loading-spinner label="Loading"></app-loading-spinner>
        </div>
      }
      @if (users?.length > 0) {
        <div id="inner-page--container">
          <p id="table-description" style="display: none">User table</p>
          <h6>Laboratory members</h6>
          <div id="table--container">
            <table aria-describedby="table-description">
              <thead>
                <tr>
                  <th
                    id="first-name"
                    scope="col"
                    (click)="onSort('FirstName')"
                    [class.active]="currentSort.sortBy === 'FirstName'"
                  >
                    <div id="table-header">
                      <span>First Name</span>
                      <mat-icon
                        aria-label="sort direction"
                        matTooltip="Sort direction"
                        >swap_vert</mat-icon
                      >
                    </div>
                  </th>
                  <th
                    id="last-name"
                    scope="col"
                    (click)="onSort('LastName')"
                    [class.active]="currentSort.sortBy === 'LastName'"
                  >
                    <div id="table-header">
                      <span>Last Name</span>
                      <mat-icon
                        aria-label="sort direction"
                        matTooltip="Sort direction"
                        >swap_vert</mat-icon
                      >
                    </div>
                  </th>
                  <th
                    id="email"
                    scope="col"
                    (click)="onSort('UserEmail')"
                    [class.active]="currentSort.sortBy === 'UserEmail'"
                  >
                    <div id="table-header">
                      <span>Email</span>
                      <mat-icon
                        aria-label="sort direction"
                        matTooltip="Sort direction"
                        >swap_vert</mat-icon
                      >
                    </div>
                  </th>
                  <th
                    id="role"
                    scope="col"
                    (click)="onSort('Role')"
                    [class.active]="currentSort.sortBy === 'Role'"
                  >
                    <div id="table-header">
                      <span>Role</span>
                      <mat-icon
                        aria-label="sort direction"
                        matTooltip="Sort direction"
                        >swap_vert</mat-icon
                      >
                    </div>
                  </th>
                  <th id="actions" scope="col">
                    <div id="table-header">
                      <span>Actions</span>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                @for (user of users; track user.id) {
                  <tr>
                    <td>{{ user.firstName }}</td>
                    <td>{{ user.lastName }}</td>
                    <td>{{ user.email }}</td>
                    <td>
                      @if (displayUserRoleDropdown(user)) {
                        {{ user.role | formatRole }}
                      } @else {
                        <div id="update-role">
                          {{ user.role | formatRole }}
                          @if (!user.isMemberOfMultipleLabGroups) {
                            <form
                              [formGroup]="editUserForm"
                              id="update-role-form"
                            >
                              <app-underlined-dropdown-form-field
                                [field]="userRoleField"
                                [parentForm]="editUserForm"
                              ></app-underlined-dropdown-form-field>
                              <app-button
                                label="Update Role"
                                buttonId="edit-btn"
                                (buttonClicked)="onUpdateUser(user.id)"
                                [isProcessing]="
                                  isUpdateRoleProcessing &&
                                  updateUserId === user.id
                                "
                                [isDisabled]="
                                  currentUser.role
                                    | roleCheck
                                      : [
                                          Role.OGI_Super_Administrator,
                                          Role.Super_User,
                                          Role.OGI_Laboratory_Administrator,
                                          Role.Read_Access_User,
                                          Role.Run_Access_User,
                                          Role.Edit_Access_User,
                                        ]
                                "
                              >
                              </app-button>
                            </form>
                          }
                        </div>
                      }
                    </td>
                    <td id="button--container">
                      <app-button
                        label="Edit User"
                        buttonId="edit-btn"
                        (buttonClicked)="onEditUser(user.id)"
                        [isDisabled]="
                          currentUser.role
                            | roleCheck
                              : [
                                  Role.Lab_Administrator,
                                  Role.OGI_Laboratory_Administrator,
                                  Role.Read_Access_User,
                                  Role.Run_Access_User,
                                  Role.Edit_Access_User,
                                ]
                        "
                      >
                      </app-button>
                      <app-button
                        label="Remove User From Laboratory"
                        buttonId="remove-btn"
                        (buttonClicked)="onRemoveUserFromLab(user.id)"
                        buttonStyle="negative"
                        [isProcessing]="
                          isRemoveUserFromLabProcessing &&
                          removeUserFromLabId === user.id
                        "
                        [isDisabled]="
                          currentUser.role
                            | roleCheck
                              : [
                                  Role.Read_Access_User,
                                  Role.Run_Access_User,
                                  Role.Edit_Access_User,
                                ]
                        "
                      >
                      </app-button>
                    </td>
                  </tr>
                }
              </tbody>
            </table>
            <div id="pagination--container">
              <div class="filter--container">
                <label for="approvals-action">Filter by role</label>
                <app-dropdown-form-field
                  id="action-filter"
                  [field]="userRolesFilter"
                  [parentForm]="usersTableControls"
                >
                </app-dropdown-form-field>
              </div>
              <div class="filter--container">
                <label for="users-per-page">Users per page</label>
                <app-dropdown-form-field
                  id="users-per-page"
                  [field]="usersPerPageField"
                  [parentForm]="usersTableControls"
                >
                </app-dropdown-form-field>
              </div>
              <div id="pagination--controls">
                <app-icon-button
                  iconName="chevron_left"
                  buttonId="previous"
                  (buttonClicked)="getPrevious()"
                  [isDisabled]="!hasPrevious"
                ></app-icon-button>
                <span> {{ currentPage }} / {{ totalPages }} </span>
                <app-icon-button
                  iconName="chevron_right"
                  buttonId="next"
                  (buttonClicked)="getNext()"
                  [isDisabled]="!hasNext"
                ></app-icon-button>
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  </div>
} @else {
  <app-error-page
    title="No Lab found"
    primaryText="Sorry, you cannot access this page unless you are a member of a lab"
  ></app-error-page>
}
<app-modal
  id="edit-lab"
  titleText="Success"
  [primaryText]="updateLabSuccessMessage"
  buttonType="single-button"
  primaryButtonText="close"
  primaryButtonId="dashboard-button"
  modalStyle="success"
  (modalButtonClicked)="modalButtonClicked($event)"
></app-modal>
<app-modal
  id="error"
  titleText="Error"
  [primaryText]="error?.message"
  buttonType="single-button"
  primaryButtonText="close"
  primaryButtonId="close-button"
  modalStyle="error"
  (modalButtonClicked)="modalButtonClicked($event)"
></app-modal>
<app-modal
  id="attention"
  titleText="Attention"
  primaryText="Your changes have not been saved. Press cancel to return to the form and save your changes, or continue to discard."
  buttonType="double-button"
  primaryButtonText="Continue"
  primaryButtonId="confirm-navigation"
  secondaryButtonText="Cancel"
  secondaryButtonId="cancel-button"
  modalStyle="warning"
  (modalButtonClicked)="modalButtonClicked($event)"
></app-modal>
<app-validation-modal
  id="delete-lab"
  titleText="Delete laboratory"
  validationText="delete"
  buttonType="double-button"
  primaryButtonText="Delete"
  primaryButtonId="primary-button"
  secondaryButtonText="Close"
  secondaryButtonId="secondary-button"
  (modalButtonClicked)="deleteLab()"
  [error]="error"
></app-validation-modal>
<app-modal
  id="success"
  titleText="Success"
  [primaryText]="successModalPrimaryText"
  buttonType="single-button"
  primaryButtonText="close"
  primaryButtonId="close-button"
  modalStyle="success"
  (modalButtonClicked)="modalButtonClicked($event)"
></app-modal>
