import {
  Component,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
} from '@angular/core';
import {
  FormGroup,
  FormControl,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { ERROR_MESSAGE } from 'src/app/_helpers/utils';
import { FormBase } from 'src/app/_models/form-base';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import {
  MatFormField,
  MatLabel,
  MatSuffix,
  MatError,
} from '@angular/material/form-field';

@Component({
  selector: 'app-underlined-form-field',
  templateUrl: './underlined-form-field.component.html',
  styleUrls: ['./underlined-form-field.component.scss'],
  standalone: true,
  imports: [
    MatFormField,
    FormsModule,
    ReactiveFormsModule,
    MatLabel,
    MatInput,
    MatIcon,
    MatSuffix,
    MatError,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UnderlinedFormFieldComponent {
  /**
   * Form field configuration object.
   * Defines properties like label, placeholder, validation, etc.
   */
  @Input() field!: FormBase<string>;

  /**
   * Parent `FormGroup` that contains the form control for this field.
   */
  @Input() parentForm!: FormGroup;

  /**
   * Name of the icon displayed within the input field (optional).
   */
  @Input() iconName?: string;

  /**
   * Minimum allowed value for number inputs (optional).
   */
  @Input() minValue?: number;

  /**
   * Maximum allowed value for number inputs (optional).
   */
  @Input() maxValue?: number;

  /**
   * Step value for number inputs (optional).
   */
  @Input() step?: number;

  /**
   * Determines if a tooltip should be displayed (default: `false`).
   */
  @Input() displayTooltip = false;

  /**
   * Disables the input field (default: `false`).
   */
  @Input() isDisabled = false;

  /**
   * Warning message displayed when validation fails (optional).
   */
  @Input() warningMessage?: string;

  /**
   * Controls whether the warning message should be shown (default: `false`).
   */
  @Input() showWarning = false;

  /**
   * Emits an event when the minus button is clicked (for number inputs).
   */
  @Output() readonly minusButtonClick = new EventEmitter<void>();

  /**
   * Emits an event when the plus button is clicked (for number inputs).
   */
  @Output() readonly plusButtonClick = new EventEmitter<void>();

  /**
   * Indicates whether the password field is in visible mode.
   */
  showing = false;

  /**
   * Retrieves the `FormControl` associated with this form field.
   * @returns The `FormControl` instance from the parent form.
   */
  public get control(): FormControl {
    return this.parentForm.controls[this.field.key] as FormControl;
  }

  /**
   * Determines whether the field has validation errors.
   * @returns `true` if the field is invalid and has been touched, otherwise `false`.
   */
  public get hasErrors(): boolean {
    return this.control.invalid && this.control.touched;
  }

  /**
   * Retrieves a list of validation error messages for the field.
   * @returns An array of error messages corresponding to validation failures.
   */
  public get errorsList(): string[] {
    return this.hasErrors
      ? Object.keys(this.control.errors || {}).map((err) => {
          const errorFn = ERROR_MESSAGE[err as keyof typeof ERROR_MESSAGE];
          return errorFn
            ? errorFn(this.control.getError(err) as never)
            : 'Unknown error';
        })
      : [];
  }

  /**
   * Retrieves the text for toggling password visibility.
   * @returns `'Hide'` if password is currently visible, otherwise `'Show'`.
   */
  public get showHideText(): string {
    return this.showing ? 'Hide' : 'Show';
  }

  /**
   * Determines the input type (e.g., `'password'`, `'text'`, etc.).
   * If the field type is `'password'`, it switches between `'text'` and `'password'` based on visibility.
   * @returns The appropriate input type string.
   */
  public get inputType(): string {
    return this.field.type === 'password' && !this.showing
      ? 'password'
      : 'text';
  }

  /**
   * Toggles the visibility of the password field.
   */
  togglePassword(): void {
    if (!this.field.disabled) {
      this.showing = !this.showing;
    }
  }

  /**
   * Emits the `minusButtonClick` event (used for decrementing number fields).
   */
  minusButtonClicked(): void {
    this.minusButtonClick.emit();
  }

  /**
   * Emits the `plusButtonClick` event (used for incrementing number fields).
   */
  plusButtonClicked(): void {
    this.plusButtonClick.emit();
  }
}
