import { Component, inject } from '@angular/core';
import {
  FormBuilder,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { ErrorObject } from 'src/app/_models/error';
import { FormBase } from 'src/app/_models/form-base';
import { ModalService } from 'src/app/_services/modal.service';
import { OrganisationService } from 'src/app/_services/organisation.service';
import { ModalComponent } from '../../_components/modals/modal.component';
import { ButtonComponent } from '../../_components/buttons/button/button.component';
import { UnderlinedDropdownFormFieldComponent } from '../../_components/forms/underlined-dropdown-form-field/underlined-dropdown-form-field.component';
import { UnderlinedFormFieldComponent } from '../../_components/forms/underlined-form-field/underlined-form-field.component';

@Component({
  selector: 'app-create-organisation',
  templateUrl: './create-organisation.component.html',
  styleUrls: ['./create-organisation.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    UnderlinedFormFieldComponent,
    UnderlinedDropdownFormFieldComponent,
    ButtonComponent,
    ModalComponent,
  ],
})
export class CreateOrganisationComponent {
  private readonly formBuilder = inject(FormBuilder);
  private readonly router = inject(Router);
  private readonly modalService = inject(ModalService);
  private readonly organisationService = inject(OrganisationService);

  isProcessing = false;
  error: ErrorObject;

  allowNavigation: Subject<boolean> = new Subject<boolean>();

  orgNameField = new FormBase({
    key: 'orgName',
    label: 'Organisation Name',
    required: true,
    type: 'text',
    placeholder: 'Organisation Name',
    value: '',
    options: [],
  });

  licenseField = new FormBase({
    key: 'license',
    label: 'License Type',
    required: true,
    type: 'text',
    value: '',
    placeholder: 'Select license type',
    options: [
      {
        key: '1',
        value: 'Basic License',
      },
    ],
  });

  superUserFirstNameField = new FormBase({
    key: 'superUserFirstName',
    label: 'First Name',
    required: true,
    type: 'text',
    placeholder: 'First Name',
    value: '',
    options: [],
  });

  superUserLastNameField = new FormBase({
    key: 'superUserLastName',
    label: 'Last Name',
    required: true,
    type: 'text',
    placeholder: 'Last Name',
    value: '',
    options: [],
  });

  superUserEmailField = new FormBase({
    key: 'superUserEmail',
    label: 'Email',
    required: true,
    type: 'email',
    placeholder: 'Email',
    value: '',
    options: [],
  });

  addOrgForm = this.formBuilder.group({
    [this.orgNameField.key]: ['', Validators.required],
    [this.licenseField.key]: ['', Validators.required],
    [this.superUserFirstNameField.key]: ['', Validators.required],
    [this.superUserLastNameField.key]: ['', Validators.required],
    [this.superUserEmailField.key]: [
      '',
      [Validators.required, Validators.email],
    ],
  });

  openModal(modalId: string) {
    this.modalService.open(modalId);
  }

  closeModal(modalId: string) {
    this.modalService.close(modalId);
  }

  modalButtonClicked(buttonId: string) {
    switch (buttonId) {
      case 'dashboard-button':
        void this.router.navigate(['dashboard/devices']);
        break;
      case 'close-button':
        this.closeModal('error');
        break;
      case 'cancel-button':
        this.closeModal('attention');
        break;
      case 'confirm-navigation':
        this.allowNavigation.next(true);
        this.closeModal('attention');
        break;
    }
  }

  onSubmit() {
    if (this.addOrgForm.invalid) {
      return;
    }

    this.isProcessing = true;

    this.organisationService
      .createOrganisation({
        organisationName: this.addOrgForm.value.orgName,
        licenseType: parseInt(this.addOrgForm.value.license),
        superUserFirstName: this.addOrgForm.value.superUserFirstName,
        superUserLastName: this.addOrgForm.value.superUserLastName,
        superUserEmail: this.addOrgForm.value.superUserEmail,
      })
      .pipe()
      .subscribe({
        next: () => {
          this.isProcessing = false;
          this.addOrgForm.markAsPristine();
          this.openModal('create-org');
        },
        error: (error: ErrorObject) => {
          this.isProcessing = false;
          this.error = error;
          this.openModal('error');
        },
      });
  }

  canExit(): Promise<boolean> | boolean {
    return this.isNavigationAllowed();
  }

  private isNavigationAllowed(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      if (this.addOrgForm.pristine) {
        resolve(true);
      } else {
        this.openModal('attention');
        this.allowNavigation.subscribe((isConfirmed) => resolve(isConfirmed));
      }
    });
  }
}
