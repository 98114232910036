<fieldset
  [class.tab--container]="checkboxStyle === 'tabs'"
  [class.tab-small--container]="checkboxStyle === 'tabs-small'"
  [class.checkbox__container]="
    checkboxStyle === 'default' || checkboxStyle === 'list'
  "
  [formGroup]="parentForm"
>
  <legend
    [class.body1]="
      checkboxStyle === 'default' ||
      checkboxStyle === 'list' ||
      checkboxStyle === 'tabs-small'
    "
    [class.radio-label-left]="checkboxStyle === 'tabs'"
  >
    {{ field.label }}
    @if (field.required) {
      <span class="overline" aria-required="true"> * </span>
    }
  </legend>

  <div
    [class.tabs__options-container]="checkboxStyle === 'tabs'"
    [class.tabs-small__options-container]="checkboxStyle === 'tabs-small'"
    [class.list__container]="checkboxStyle === 'list'"
    [class.checkbox-options__container]="checkboxStyle === 'default'"
    [formArrayName]="field.key"
  >
    @for (option of field.options; track $index) {
      <label
        [for]="'option-' + $index"
        [class.tabs--label]="checkboxStyle === 'tabs'"
        [class.tabs-small--label]="checkboxStyle === 'tabs-small'"
        [class.checkbox--label]="
          checkboxStyle === 'default' || checkboxStyle === 'list'
        "
        [class.body1]="checkboxStyle === 'default' || checkboxStyle === 'list'"
        [class.disabled]="isDisabled"
      >
        <input
          type="checkbox"
          [id]="'option-' + $index"
          [attr.name]="option.key"
          [formControlName]="$index"
          [checked]="initialiseCheckboxes($index)"
          [disabled]="isDisabled"
          (change)="updateValue($event)"
        />
        @if (checkboxStyle === "default" || checkboxStyle === "list") {
          <div class="checkmark--background">
            <div class="checkmark">
              <mat-icon>done</mat-icon>
            </div>
          </div>
        }
        @if (checkboxStyle === "tabs-small" || showModuleIcon) {
          <app-active-modules [activeModules]="[option.key]">
          </app-active-modules>
        }
        {{ option.value }}
        <div class="body3 options">
          @for (opt of option.options; track option.options) {
            <div>{{ opt }}</div>
          }
        </div>
      </label>
    }
  </div>

  <!-- Error messages -->
  @if (hasErrors) {
    <ul>
      @for (error of errorsList; track errorsList) {
        <li class="underlined-form-field-error caption error">
          {{ error }}
        </li>
      }
    </ul>
  }
</fieldset>
