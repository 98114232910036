import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { TabOptions } from 'src/app/_models/tab-options';
import { RouterLinkActive, RouterLink } from '@angular/router';

@Component({
  selector: 'app-option-tab-toggle',
  templateUrl: './option-tab-toggle.component.html',
  styleUrls: ['./option-tab-toggle.component.scss'],
  standalone: true,
  imports: [RouterLinkActive, RouterLink],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OptionTabToggleComponent {
  title: 'OptionTabToggle';
  component: '';

  /**
   * @requires
   * What is the name of the radio group?
   */
  @Input()
  radioGroup: string;

  /**
   * What value and text for each button?
   * @requires
   */
  @Input()
  selectOptions: TabOptions[] = [];

  /**
   * What type of button?
   */
  @Input()
  type: 'round' | 'square' = 'round';
}
