import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Checkbox } from '../../../_models/checkbox';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
})
export class CheckboxComponent {
  title: 'Checkbox';
  component: '';

  /**
   * What params for each checkbox?
   * @requires
   */
  @Input()
  checkbox: Checkbox;

  /**
   * Parent form group name
   * @requires
   */
  @Input()
  parentForm: string;

  /**
   * Is the input disabled?
   */
  @Input()
  isDisabled: boolean;

  /**
   * Emit selected option
   */
  @Output()
  checkboxSelected: EventEmitter<any> = new EventEmitter();

  public get classes(): string[] {
    const hasDisabledClass = this.isDisabled ? 'disabled' : '';
    return ['checkbox--label', 'body1', hasDisabledClass];
  }

  onValueChange = (event: Event): void => {
    const target = event.target as HTMLFormElement;
    this.checkboxSelected.emit(target.checked);
  };
}
