<nav class="navbar--container">
  <div [ngClass]="containerClasses">
    <div id="arrow--container">
      <app-nav-button
        *ngIf="sidebarCollapsed"
        (click)="buttonClicked()"
        iconName="chevron_right"
        buttonId="expand-btn"
        label="Expand"
      ></app-nav-button>
      <app-nav-button
        *ngIf="!sidebarCollapsed"
        (click)="buttonClicked()"
        iconName="chevron_left"
        buttonId="collapse-btn"
        label="Collapse"
      ></app-nav-button>
    </div>
    <div id="logo--container">
      <app-svg-template
        *ngIf="sidebarCollapsed"
        routerLink="dashboard/devices"
        [iconName]="logoSmall"
        height="38"
        width="38"
        customViewBox="0 0 38 38"
        label="Dashboard"
      ></app-svg-template>
      <app-svg-template
        *ngIf="!sidebarCollapsed"
        routerLink="dashboard/devices"
        [iconName]="logo"
        height="38"
        width="152"
        customViewBox="0 0 152 38"
      ></app-svg-template>
    </div>
    <div id="menu--container">
      <div id="options--container">
        <div class="subsection--container">
          <app-dropdown-form-field
            *ngIf="!sidebarCollapsed"
            id="lab-list"
            [field]="laboratorySelectField"
            [parentForm]="labSelectForm"
            dropdownStyle="nav"
          ></app-dropdown-form-field>
        </div>
        <app-nav-button
          routerLink="dashboard/devices"
          iconName="dashboard"
          buttonId="dashboard-btn"
          label="Dashboard"
          [displayText]="!sidebarCollapsed"
        ></app-nav-button>
        <app-nav-button
          routerLink="experiment/0/set-parameters"
          iconName="science"
          buttonId="experiment-btn"
          label="New Experiment"
          [displayText]="!sidebarCollapsed"
          [isDisabled]="
            currentUser.role
              | roleCheck : [Role.Read_Access_User, Role.Run_Access_User]
          "
        ></app-nav-button>
        <app-nav-button
          routerLink="calibration/create-calibration"
          iconName="timeline"
          buttonId="calibration-btn"
          label="New Calibration"
          [displayText]="!sidebarCollapsed"
          [isDisabled]="
            currentUser.role
              | roleCheck : [Role.Read_Access_User, Role.Run_Access_User]
          "
        ></app-nav-button>
        <app-nav-button
          routerLink="buffer/create-buffer"
          label="pH Buffers"
          iconName="opacity"
          buttonId="buffer-lrg-btn"
          [displayText]="!sidebarCollapsed"
          [isDisabled]="
            currentUser.role
              | roleCheck : [Role.Read_Access_User, Role.Run_Access_User]
          "
        ></app-nav-button>

        <app-nav-button
          routerLink="device/create-device"
          iconName="stream"
          buttonId="devices-btn"
          label="Add New Device"
          [displayText]="!sidebarCollapsed"
          [isDisabled]="
            currentUser.role
              | roleCheck
                : [
                    Role.Read_Access_User,
                    Role.Run_Access_User,
                    Role.Edit_Access_User
                  ]
          "
        >
        </app-nav-button>
        <app-nav-button
          routerLink="user/add-user"
          iconName="person_add"
          buttonId="add-user-btn"
          label="Add New User"
          [displayText]="!sidebarCollapsed"
          [isDisabled]="
            currentUser.role
              | roleCheck
                : [
                    Role.Read_Access_User,
                    Role.Run_Access_User,
                    Role.Edit_Access_User
                  ]
          "
        ></app-nav-button>
        <app-nav-button
          routerLink="laboratories/view-lab"
          iconName="home"
          buttonId="view-lab-btn"
          label="View Lab"
          [displayText]="!sidebarCollapsed"
        ></app-nav-button>
        <app-nav-button
          [routerLink]="
            'organisations/' + currentUser.organisationId + '/details'
          "
          iconName="location_city"
          buttonId="view-org-btn"
          label="View Organisation"
          [displayText]="!sidebarCollapsed"
          [isDisabled]="
            currentUser.role
              | roleCheck
                : [
                    Role.Read_Access_User,
                    Role.Run_Access_User,
                    Role.Edit_Access_User,
                    Role.Lab_Administrator,
                    Role.OGI_Laboratory_Administrator
                  ]
          "
        ></app-nav-button>
      </div>
      <div
        *ngIf="currentUser.role === Role.OGI_Super_Administrator"
        id="admin--container"
      >
        <app-nav-button
          routerLink="approvals/labs"
          iconName="admin_panel_settings"
          buttonId="approvals-btn"
          label="Approvals"
          [displayText]="!sidebarCollapsed"
        >
        </app-nav-button>
        <app-nav-button
          routerLink="organisations/administration"
          iconName="location_city"
          buttonId="view-orgs-btn"
          label="Organisations"
          [displayText]="!sidebarCollapsed"
        ></app-nav-button>
      </div>
      <div id="user--container">
        <app-nav-button
          routerLink="account/update-details"
          iconName="account_circle"
          buttonId="user-btn"
          label="Account"
          [displayText]="!sidebarCollapsed"
        ></app-nav-button>
        <app-nav-button
          (buttonClicked)="onLogout()"
          iconName="logout"
          buttonId="logout-btn"
          label="Logout"
          [displayText]="!sidebarCollapsed"
        ></app-nav-button>
      </div>
    </div>
  </div>
</nav>
