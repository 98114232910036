import { Component, OnInit, inject } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { ButtonComponent } from 'src/app/_components/buttons/button/button.component';
import { IconButtonComponent } from 'src/app/_components/buttons/icon-button/icon-button.component';
import { ErrorObject } from 'src/app/_models/error';
import { FormBase } from 'src/app/_models/form-base';
import { Laboratory } from 'src/app/_models/laboratory';
import { LicenseType } from 'src/app/_models/license-type';
import { OrganisationIndexView } from 'src/app/_models/organisation';
import { Role } from 'src/app/_models/role';
import { TabOptions } from 'src/app/_models/tab-options';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { ModalService } from 'src/app/_services/modal.service';
import { OrganisationService } from 'src/app/_services/organisation.service';
import { LoadingSpinnerComponent } from 'src/app/_components/loading-spinner/loading-spinner.component';
import { ModalComponent } from 'src/app/_components/modals/modal.component';
import { ValidationModalComponent } from 'src/app/_components/modals/validation-modal/validation-modal.component';
import { RoleCheckPipe } from 'src/app/_helpers/role-check.pipe';
import { AltOptionTabToggleComponent } from 'src/app/_components/selection-controls/alt-option-tab-toggle/alt-option-tab-toggle.component';
import { UserService } from 'src/app/_services/user.service';
import { User, UserIndex } from 'src/app/_models/user';
import { FormatRolePipe } from 'src/app/_helpers/format-role.pipe';
import { DropdownFormFieldComponent } from 'src/app/_components/forms/dropdown-form-field/dropdown-form-field.component';
import { MatIcon } from '@angular/material/icon';
import { ReducedSmallDataCardComponent } from 'src/app/_components/cards/reduced-small-data-card/reduced-small-data-card.component';
import { MatFormFieldComponent } from 'src/app/_components/forms/mat-form-field/mat-form-field.component';

@Component({
  selector: 'app-organisation',
  standalone: true,
  imports: [
    IconButtonComponent,
    ButtonComponent,
    LoadingSpinnerComponent,
    ModalComponent,
    ValidationModalComponent,
    RoleCheckPipe,
    AltOptionTabToggleComponent,
    FormatRolePipe,
    DropdownFormFieldComponent,
    MatIcon,
    ReducedSmallDataCardComponent,
    MatFormFieldComponent,
  ],
  templateUrl: './organisation.component.html',
  styleUrl: './organisation.component.scss',
})
export class OrganisationComponent implements OnInit {
  private readonly organisationService = inject(OrganisationService);
  private readonly userService = inject(UserService);
  private readonly authService = inject(AuthenticationService);
  private readonly modalService = inject(ModalService);
  private readonly formBuilder = inject(FormBuilder);
  private readonly router = inject(Router);
  private readonly route = inject(ActivatedRoute);

  organisation: OrganisationIndexView;
  organisationId: number;
  currentUser = this.authService.currentUserValue;
  Role = Role;
  successModalPrimaryText: string;
  navOptions: TabOptions[];
  selectedTabOption = 'members';
  users: User[] = [];

  // Controls
  isProcessing = false;
  isLoading = true;
  allowNavigation: Subject<boolean> = new Subject<boolean>();
  error: ErrorObject = null;

  // Make licence type enum available in the template
  LicenseType = LicenseType;

  // Table control variables
  currentFilters = [];
  currentSort: {
    sortBy: string;
    sortDirection: string;
  } = {
    sortBy: 'FirstName',
    sortDirection: 'asc',
  };

  // Pagination
  pageRequested = 1;
  pageSize = 5;
  totalCount = 0;
  totalPages = 0;
  currentPage = 1;
  hasNext = false;
  hasPrevious = false;

  usersPerPageField = new FormBase({
    key: 'usersPerPage',
    label: 'Page Size',
    type: 'dropdown',
    placeholder: '',
    disabled: false,
    required: false,
    value: '5', // Set default value to 5
    options: [
      { key: '5', value: '5' },
      { key: '10', value: '10' },
      { key: '25', value: '25' },
      { key: '50', value: '50' },
      { key: '100', value: '100' },
    ],
  });

  orgNameField = new FormBase({
    key: 'name',
    label: 'Organisation Name',
    type: 'text',
    placeholder: 'Organisation Name',
    disabled: false,
    required: true,
    value: '',
  });

  editOrgForm = this.formBuilder.group({
    [this.orgNameField.key]: [
      {
        value: this.orgNameField.value,
        disabled:
          this.currentUser.role !== Role.OGI_Super_Administrator &&
          this.currentUser.role !== Role.Super_User,
      },
      [
        (Validators.required,
        Validators.minLength(3),
        Validators.maxLength(100)),
      ],
    ],
  });

  usersTableControls = this.formBuilder.group({
    [this.usersPerPageField.key]: new FormControl(this.usersPerPageField.value),
  });

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      this.organisationId = params.id as number;
    });

    this.navOptions = [
      {
        value: 'members',
        labelText: 'Members',
      },
      {
        value: 'labs',
        labelText: 'Labs',
      },
    ];

    this.getOrganisation();
  }

  onEditUser(userId: number): void {
    if (this.currentUser.id.toString() === userId.toString()) {
      void this.router.navigate(['account/update-details']);
    } else {
      void this.router.navigate([`user/update-user/${userId}`]);
    }
  }

  getOrganisation(): void {
    this.organisationService.getOrganisation(this.organisationId).subscribe({
      next: (organisation: OrganisationIndexView) => {
        this.organisation = organisation;
        this.updateFormValues(organisation);
        this.isLoading = false;
        this.getOrganisationUsers();
      },
      error: (error: ErrorObject) => {
        this.error = error;
        this.isLoading = false;
      },
    });
  }

  updateFormValues(org: OrganisationIndexView): void {
    this.editOrgForm.reset();
    this.editOrgForm.patchValue({
      [this.orgNameField.key]: org.organisationName,
    });
    this.editOrgForm.markAsPristine();
  }

  getOrganisationUsers(): void {
    this.error = null;

    this.userService
      .getOrganisationUsers(
        this.organisationId,
        this.currentSort.sortBy,
        this.currentSort.sortDirection,
        this.pageRequested,
        this.pageSize,
      )
      .subscribe({
        next: (response: UserIndex) => {
          const { users, paginationData } = response;
          const { CurrentPage, PageSize, TotalPages, HasNext, HasPrevious } =
            paginationData;

          this.users = users;
          this.currentPage = CurrentPage;
          this.pageSize = PageSize;
          this.totalPages = TotalPages;
          this.hasNext = HasNext;
          this.hasPrevious = HasPrevious;
        },
        error: (error: ErrorObject) => {
          this.error = error;
        },
      });
  }

  onSubmit(): void {
    this.isProcessing = true;
    this.error = null;

    this.organisationService
      .updateOrganisation(this.editOrgForm.value.name, this.organisation.id)
      .subscribe({
        next: () => {
          this.isProcessing = false;
          this.organisation.organisationName = this.editOrgForm.value.name;
          this.successModalPrimaryText = 'Organisation successfully updated.';
          this.modalService.open('success');
        },
        error: (error: ErrorObject) => {
          this.error = error;
          this.isProcessing = false;
          this.modalService.open('update-error');
        },
      });
  }

  onSort(sortTerm: string): void {
    if (this.currentSort.sortBy === sortTerm) {
      if (this.currentSort.sortDirection === 'asc') {
        this.currentSort.sortDirection = 'desc';
      } else {
        this.currentSort.sortDirection = 'asc';
      }
    } else {
      this.currentSort.sortBy = sortTerm;
      this.currentSort.sortDirection = 'asc';
    }
    this.getOrganisationUsers();
  }

  getPrevious(): void {
    this.pageRequested = this.currentPage - 1;
    this.getOrganisationUsers();
  }

  getNext(): void {
    this.pageRequested = this.currentPage + 1;
    this.getOrganisationUsers();
  }

  deleteOrganisation(): void {
    this.isProcessing = true;
    this.error = null;

    this.organisationService
      .deleteOrganisation(this.organisation.id)
      .subscribe({
        next: () => {
          this.isProcessing = false;
          this.closeModal('delete-organisation');
          this.successModalPrimaryText =
            'Delete organisation request has been submitted for approval.';
          this.modalService.open('success');
        },
        error: (error: ErrorObject) => {
          this.error = error;
          this.isProcessing = false;
          this.modalService.open('update-error');
        },
      });
  }

  onViewLab(laboratory: Laboratory): void {
    this.authService.setSelectedLaboratory(laboratory);
    // as lab context has changed, navigate to dashboard
    if (this.currentUser.role === Role.Super_User && !laboratory.isOwnLab) {
      void this.router.navigate(['/laboratories/view-lab']);
    } else {
      void this.router.navigate(['/dashboard']);
    }
  }

  addLabButtonClicked(): void {
    void this.router.navigate(['/laboratories/add-lab'], {
      state: { data: this.organisation.id },
    });
  }

  openModal(modalId: string): void {
    this.modalService.open(modalId);
  }

  closeModal(modalId: string): void {
    this.modalService.close(modalId);
  }

  modalButtonClicked(buttonId: string): void {
    switch (buttonId) {
      case 'close-button':
        this.closeModal('success');
        this.closeModal('update-error');
        this.error = null;
        break;
    }
  }
}
