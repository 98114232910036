import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { map, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ModuleType } from '../_models/module-types';

@Injectable({
  providedIn: 'root',
})
export class CsvService {
  private readonly http = inject(HttpClient);

  downloadModuleCsv(
    moduleType: ModuleType,
    experimentId: number,
  ): Observable<void> {
    return this.http
      .get(
        `${environment.apiUrl}/${moduleType}-module/${experimentId}/download-experiment-data`,
        {
          responseType: 'blob',
          observe: 'response',
        },
      )
      .pipe(
        map((response) => {
          const blob = new Blob([response.body], { type: 'text/csv' });
          const filename =
            this.getFilenameFromHeader(response.headers) || 'data-export.csv';
          const url = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.download = filename;
          link.click();
          URL.revokeObjectURL(url);
        }),
      );
  }

  private getFilenameFromHeader(headers: HttpHeaders): string | null {
    const contentDisposition = headers.get('content-disposition');
    if (contentDisposition) {
      const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(
        contentDisposition,
      );
      if (matches != null && matches[1]) {
        return matches[1].replace(/['"]/g, '');
      }
    }
    return null;
  }
}
