<div class="title--container">
  <h4>Your user profile</h4>
  @if (currentUser.role | roleCheck: [Role.OGI_Super_Administrator,
  Role.OGI_Laboratory_Administrator, Role.Lab_Administrator]) {
  <app-icon-button
    label="delete account"
    buttonStyle="negative"
    buttonId="delete-user-btn"
    iconName="delete"
    buttonShape="square"
    (buttonClicked)="openModal('delete-account')"
  ></app-icon-button>
  }
</div>
<hr />
<div id="account--container">
  <div class="section--container">
    <h6>Update your details</h6>
    <div class="form--container">
      <form [formGroup]="updateDetailsForm">
        <app-underlined-form-field
          [parentForm]="updateDetailsForm"
          [field]="firstNameField"
        ></app-underlined-form-field>
        <app-underlined-form-field
          [parentForm]="updateDetailsForm"
          [field]="lastNameField"
        ></app-underlined-form-field>
      </form>
      <div class="button--container">
        <app-button
          label="update details"
          buttonId="add-btn"
          (buttonClicked)="onSubmit()"
          [isDisabled]="
            updateDetailsForm.invalid ||
            updateDetailsForm.pristine ||
            updateIsProcessing ||
            passwordIsProcessing
          "
          [isProcessing]="updateIsProcessing"
        >
        </app-button>
      </div>
    </div>
  </div>
  <div class="section--container">
    <h6>Update your password</h6>

    <ul>
      <li class="">Password must be at least 12 characters long</li>
      <li class="">Password must contain at least one uppercase letter</li>
      <li class="">Password must contain at least one lowercase letter</li>
      <li class="">Password must contain at least one number</li>
      <li class="">
        Password must contain at least one special character (&#64;#$%^&+!=)
      </li>
    </ul>

    <div class="form--container">
      <form [formGroup]="updatePasswordForm">
        <app-underlined-form-field
          [parentForm]="updatePasswordForm"
          [field]="passwordField"
        ></app-underlined-form-field>
        <app-underlined-form-field
          [parentForm]="updatePasswordForm"
          [field]="newPasswordField"
        ></app-underlined-form-field>
        <app-underlined-form-field
          [parentForm]="updatePasswordForm"
          [field]="confirmNewPasswordField"
        ></app-underlined-form-field>
      </form>
      <div class="button--container">
        <app-button
          label="update password"
          buttonId="pwd-btn"
          (buttonClicked)="onSubmitPassword()"
          [isDisabled]="
            updatePasswordForm.invalid ||
            passwordIsProcessing ||
            updateIsProcessing ||
            updatePasswordForm.pristine
          "
          [isProcessing]="passwordIsProcessing"
        >
        </app-button>
      </div>
    </div>
  </div>
  <div class="details--container">
    <div class="body1">
      <h6>Email:</h6>
      <span *ngIf="currentUser?.email">{{ currentUser?.email }}</span>
    </div>
    <div class="body1">
      <h6>Role:</h6>
      <span *ngIf="currentUser?.role">{{
        currentUser?.role | formatRole
      }}</span>
    </div>
    <div class="body1">
      <h6>Laboratories:</h6>
      <div *ngFor="let lab of currentUser?.laboratories; let i = index">
        {{ lab.laboratoryName }}
      </div>
    </div>
  </div>
</div>
<app-modal
  id="error"
  titleText="Error"
  [primaryText]="error?.message"
  buttonType="single-button"
  primaryButtonText="close"
  primaryButtonId="close-button"
  modalStyle="error"
  (modalButtonClicked)="modalButtonClicked($event)"
></app-modal>
<app-modal
  id="update-account"
  titleText="Success"
  [primaryText]="updateUserSuccessMessage"
  buttonType="single-button"
  primaryButtonText="close"
  primaryButtonId="close-button"
  modalStyle="success"
  (modalButtonClicked)="modalButtonClicked($event)"
></app-modal>
<app-modal
  id="update-password"
  titleText="Password Updated"
  primaryText="Your password has been updated."
  buttonType="single-button"
  primaryButtonText="close"
  primaryButtonId="close-button"
  modalStyle="success"
  (modalButtonClicked)="modalButtonClicked($event)"
></app-modal>
<app-modal
  id="attention"
  titleText="Attention"
  primaryText="Your changes have not been saved. Press cancel to return to the form and save your changes, or continue to discard."
  buttonType="double-button"
  primaryButtonText="Continue"
  primaryButtonId="confirm-navigation"
  secondaryButtonText="Cancel"
  secondaryButtonId="cancel-button"
  modalStyle="warning"
  (modalButtonClicked)="modalButtonClicked($event)"
></app-modal>
<app-validation-modal
  id="delete-account"
  titleText="Delete account"
  validationText="delete"
  buttonType="double-button"
  primaryButtonText="Delete"
  primaryButtonId="primary-button"
  secondaryButtonText="Close"
  secondaryButtonId="secondary-button"
  (modalButtonClicked)="deleteAccount()"
  [error]="error"
></app-validation-modal>
<app-modal
  id="logout"
  titleText="Logout"
  primaryText="Your account has been deleted. You will be logged out."
  buttonType="single-button"
  primaryButtonText="close"
  primaryButtonId="close-button"
  modalStyle="success"
  (modalButtonClicked)="modalButtonClicked('logout')"
></app-modal>
<app-modal
  id="success"
  titleText="Success"
  [primaryText]="updateUserSuccessMessage"
  buttonType="single-button"
  primaryButtonText="close"
  primaryButtonId="close-button"
  modalStyle="success"
></app-modal>
