import { Component, OnInit, inject, signal } from '@angular/core';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { Laboratory } from 'src/app/_models/laboratory';
import { Buffer } from 'src/app/_models/buffer';
import { MatIcon } from '@angular/material/icon';
import { PhTempPair } from 'src/app/_models/ph-buffer';

export interface PhBuffer {
  name: Buffer;
  temperaturePHValuePairs: PhTempPair[];
}

@Component({
  selector: 'app-view-buffers',
  templateUrl: './view-buffers.component.html',
  styleUrls: ['./view-buffers.component.scss'],
  standalone: true,
  imports: [MatIcon],
})
export class ViewBuffersComponent implements OnInit {
  private readonly authenticationService = inject(AuthenticationService);

  Buffer = Buffer;
  selectedBuffer = signal<PhBuffer>(null);
  laboratory = signal<Laboratory>(null);
  phBuffers = [
    {
      name: Buffer['pH 1.68'],
      temperaturePHValuePairs: [
        { temperature: 5, phValue: 1.668 },
        { temperature: 10, phValue: 1.669 },
        { temperature: 15, phValue: 1.672 },
        { temperature: 20, phValue: 1.675 },
        { temperature: 25, phValue: 1.678 },
        { temperature: 30, phValue: 1.683 },
        { temperature: 35, phValue: 1.688 },
        { temperature: 40, phValue: 1.693 },
        { temperature: 45, phValue: 1.7 },
        { temperature: 50, phValue: 1.707 },
        { temperature: 55, phValue: 1.715 },
        { temperature: 60, phValue: 1.715 },
        { temperature: 65, phValue: 1.724 },
        { temperature: 70, phValue: 1.733 },
      ],
    },
    {
      name: Buffer['pH 4.01'],
      temperaturePHValuePairs: [
        { temperature: 5, phValue: 3.999 },
        { temperature: 10, phValue: 3.999 },
        { temperature: 15, phValue: 4.001 },
        { temperature: 20, phValue: 4.005 },
        { temperature: 25, phValue: 4.01 },
        { temperature: 30, phValue: 4.016 },
        { temperature: 35, phValue: 4.024 },
        { temperature: 40, phValue: 4.033 },
        { temperature: 45, phValue: 4.044 },
        { temperature: 50, phValue: 4.057 },
        { temperature: 55, phValue: 4.071 },
        { temperature: 60, phValue: 4.086 },
        { temperature: 65, phValue: 4.103 },
        { temperature: 70, phValue: 4.122 },
      ],
    },
    {
      name: Buffer['pH 6.86'],
      temperaturePHValuePairs: [
        { temperature: 5, phValue: 6.949 },
        { temperature: 10, phValue: 6.923 },
        { temperature: 15, phValue: 6.9 },
        { temperature: 20, phValue: 6.881 },
        { temperature: 25, phValue: 6.865 },
        { temperature: 30, phValue: 6.852 },
        { temperature: 35, phValue: 6.843 },
        { temperature: 40, phValue: 6.837 },
        { temperature: 45, phValue: 6.834 },
        { temperature: 50, phValue: 6.834 },
        { temperature: 55, phValue: 6.837 },
        { temperature: 60, phValue: 6.844 },
        { temperature: 65, phValue: 6.854 },
        { temperature: 70, phValue: 6.867 },
      ],
    },
    {
      name: Buffer['pH 7.00'],
      temperaturePHValuePairs: [
        { temperature: 5, phValue: 7.082 },
        { temperature: 10, phValue: 7.056 },
        { temperature: 15, phValue: 7.033 },
        { temperature: 20, phValue: 7.013 },
        { temperature: 25, phValue: 6.997 },
        { temperature: 30, phValue: 6.984 },
        { temperature: 35, phValue: 6.974 },
        { temperature: 40, phValue: 6.967 },
        { temperature: 45, phValue: 6.964 },
        { temperature: 50, phValue: 6.963 },
        { temperature: 55, phValue: 6.966 },
        { temperature: 60, phValue: 6.973 },
        { temperature: 65, phValue: 6.982 },
        { temperature: 70, phValue: 6.995 },
      ],
    },
    {
      name: Buffer['pH 9.18'],
      temperaturePHValuePairs: [
        { temperature: 5, phValue: 9.389 },
        { temperature: 10, phValue: 9.332 },
        { temperature: 15, phValue: 9.279 },
        { temperature: 20, phValue: 9.23 },
        { temperature: 25, phValue: 9.185 },
        { temperature: 30, phValue: 9.144 },
        { temperature: 35, phValue: 9.107 },
        { temperature: 40, phValue: 9.074 },
        { temperature: 45, phValue: 9.045 },
        { temperature: 50, phValue: 9.02 },
        { temperature: 55, phValue: 8.999 },
        { temperature: 60, phValue: 8.983 },
        { temperature: 65, phValue: 8.97 },
        { temperature: 70, phValue: 8.961 },
      ],
    },
    {
      name: Buffer['pH 9.21'],
      temperaturePHValuePairs: [
        { temperature: 5, phValue: 9.45 },
        { temperature: 10, phValue: 9.38 },
        { temperature: 15, phValue: 9.32 },
        { temperature: 18, phValue: 9.28 },
        { temperature: 20, phValue: 9.26 },
        { temperature: 22, phValue: 9.24 },
        { temperature: 25, phValue: 9.21 },
        { temperature: 27, phValue: 9.19 },
        { temperature: 30, phValue: 9.16 },
        { temperature: 32, phValue: 9.14 },
        { temperature: 35, phValue: 9.11 },
        { temperature: 40, phValue: 9.06 },
        { temperature: 45, phValue: 9.03 },
        { temperature: 50, phValue: 8.99 },
      ],
    },
    {
      name: Buffer['pH 10.01'],
      temperaturePHValuePairs: [
        { temperature: 5, phValue: 10.249 },
        { temperature: 10, phValue: 10.182 },
        { temperature: 15, phValue: 10.121 },
        { temperature: 20, phValue: 10.064 },
        { temperature: 25, phValue: 10.013 },
        { temperature: 30, phValue: 9.967 },
        { temperature: 35, phValue: 9.925 },
        { temperature: 40, phValue: 9.889 },
        { temperature: 45, phValue: 9.858 },
        { temperature: 50, phValue: 9.832 },
        { temperature: 55, phValue: 9.811 },
        { temperature: 60, phValue: 9.794 },
        { temperature: 65, phValue: 9.783 },
        { temperature: 70, phValue: 9.777 },
      ],
    },
    {
      name: Buffer['pH 12.45'],
      temperaturePHValuePairs: [
        { temperature: 5, phValue: 13.18 },
        { temperature: 10, phValue: 12.99 },
        { temperature: 15, phValue: 12.8 },
        { temperature: 20, phValue: 12.62 },
        { temperature: 25, phValue: 12.45 },
        { temperature: 30, phValue: 12.29 },
        { temperature: 35, phValue: 12.13 },
        { temperature: 40, phValue: 11.98 },
        { temperature: 45, phValue: 11.83 },
        { temperature: 50, phValue: 11.7 },
        { temperature: 55, phValue: 11.57 },
        { temperature: 60, phValue: 11.44 },
        { temperature: 65, phValue: 11.32 },
        { temperature: 70, phValue: 11.21 },
      ],
    },
  ];

  ngOnInit(): void {
    this.laboratory.update(() => this.authenticationService.selectedLaboratory);
    this.selectedBuffer.update(() => this.phBuffers[0]);
  }

  setPhBuffer(buffer: PhBuffer): void {
    this.selectedBuffer.update(() => buffer);
  }
}
