<div id="new-experiment--container">
  <h4>
    Select Devices - {{ experiment?.name }}
    <app-status-indicator
      *ngIf="experiment"
      [status]="ExperimentStatus[experiment?.experimentStatusId]"
    ></app-status-indicator>
  </h4>
  <span *ngIf="error" class="error">{{ error.message }} </span>
  <div id="nav-bar-button--container">
    <app-button
      label="back"
      buttonId="back-btn"
      type="button"
      (buttonClicked)="goBack()"
    ></app-button>
    <app-button
      label="Delete Draft"
      buttonId="cancel-btn"
      buttonStyle="negative"
      type="button"
      (buttonClicked)="openModal('delete-draft')"
      [isDisabled]="
        isProcessing ||
        deleteIsProcessing ||
        (currentUser.role
          | roleCheck
            : [
                Role.Read_Access_User,
                Role.Run_Access_User,
                Role.Edit_Access_User
              ])
      "
    ></app-button>
  </div>
</div>
<hr />
<div class="inner-page--container">
  <div id="calibration--container">
    <h6 *ngIf="experiment?.experimentStatusId === ExperimentStatus.Draft">
      Add New Device
    </h6>
    <h6 *ngIf="experiment?.experimentStatusId === ExperimentStatus.DraftLocked">
      Selected Devices - Draft Locked
    </h6>
    <div class="add-device--container">
      <app-slide-toggle
        [parentForm]="deviceFilterForm"
        [field]="inactiveDeviceToggleField"
      ></app-slide-toggle>
      <app-slide-toggle
        [parentForm]="deviceFilterForm"
        [field]="onlineDeviceToggleField"
      ></app-slide-toggle>
      <app-dropdown-form-field
        *ngIf="
          !devicesLoading && !deviceError && availableDeviceList.length > 0
        "
        [field]="deviceSelectField"
        [parentForm]="experimentSetupForm"
      >
      </app-dropdown-form-field>
      <span
        *ngIf="
          !deviceError && !devicesLoading && availableDeviceList.length === 0
        "
        class="subtitle1"
      >
        No devices available
      </span>
      <span *ngIf="deviceError" class="error">{{ deviceError.message }}</span>
      <div *ngIf="devicesLoading" class="subtitle1">
        <app-small-loading-spinner></app-small-loading-spinner>
      </div>
      <app-icon-button
        label="add device"
        labelClass="large"
        buttonId="add-device-btn"
        buttonShape="square"
        iconName="add_circle"
        (buttonClicked)="addDeviceToExperiment()"
        [isDisabled]="
          !experimentSetupForm.controls[deviceSelectField.key].value
        "
      ></app-icon-button>
    </div>
    <h6 *ngIf="selectedDeviceList.length > 0">Devices</h6>
    <div class="device-selection--container">
      <form [formGroup]="experimentSetupForm">
        <div
          *ngFor="let device of selectedDeviceList"
          class="options--container"
        >
          <div class="device-options">
            <div class="device-summary--container">
              <span class="subtitle2">{{ device.deviceName }}</span>
              <mat-icon *ngIf="!device.isOffline" class="online">wifi</mat-icon>
              <mat-icon *ngIf="device.isOffline" class="offline"
                >wifi_off</mat-icon
              >
              <app-status-indicator
                *ngIf="device"
                [status]="DeviceSetupStatus[device.deviceStatusId]"
              ></app-status-indicator>
              @if (requiredDeviceModules.has(Module.LiquidControl)) {
              <span class="row">
                @if (device.pumpTubeLength) { Pump tube length:
                {{ device.pumpTubeLength }} cm
                <app-icon-button
                  buttonId="set-pump-tube-length-btn"
                  buttonStyle="transparent--blue"
                  buttonShape="square"
                  iconName="arrow_forward"
                  aria-label="Edit pump tube length"
                  label="Edit pump tube length"
                  [routerLink]="['/device', device.deviceId, 'details']"
                >
                </app-icon-button>
                } @else { @if (experiment?.experimentStatusId !==
                ExperimentStatus.DraftLocked) {
                <app-icon-button
                  buttonId="set-pump-tube-length-btn"
                  buttonStyle="transparent"
                  buttonShape="square"
                  iconName="arrow_forward"
                  [routerLink]="['/device', device.deviceId, 'details']"
                  label="Please set pump tube length"
                >
                </app-icon-button>
                } }
              </span>

              }
            </div>
            <div class="add-device--container">
              <div class="op-density--container">
                <app-dynamic-dropdown-form-field
                  *ngIf="device.calibrationList.length > 0"
                  [parentForm]="experimentSetupForm"
                  [device]="device"
                  placeholder="Select OD Calibration"
                ></app-dynamic-dropdown-form-field>
                <span
                  *ngIf="
                    !odError &&
                    !odCalibrationLoading &&
                    (!device.calibrationList ||
                      device.calibrationList.length === 0)
                  "
                  class="subtitle1"
                >
                  No optical density calibrations
                </span>
                <span *ngIf="odError" class="error">{{ odError.message }}</span>
                <div *ngIf="odCalibrationLoading" class="subtitle1">
                  <app-small-loading-spinner></app-small-loading-spinner>
                </div>
              </div>
              <div
                class="ox-cal--container"
                *ngIf="experiment.useOxygen; else oxyNoSelect"
              >
                <app-dynamic-dropdown-form-field
                  *ngIf="
                    device.oxygenCalibrationList?.length > 0 &&
                    !oxygenCalibrationLoading
                  "
                  [parentForm]="experimentSetupForm"
                  [device]="device"
                  [calibrationType]="1"
                  placeholder="Select Oxygen Calibration"
                ></app-dynamic-dropdown-form-field>
                <span
                  *ngIf="
                    !oxyError &&
                    !oxygenCalibrationLoading &&
                    (!device.oxygenCalibrationList ||
                      device.oxygenCalibrationList?.length === 0)
                  "
                  class="subtitle1"
                >
                  No oxygen calibrations
                </span>
                <span *ngIf="oxyError" class="error">{{
                  oxyError.message
                }}</span>
                <div *ngIf="oxygenCalibrationLoading" class="subtitle1">
                  <app-small-loading-spinner></app-small-loading-spinner>
                </div>
              </div>
              <ng-template #oxyNoSelect class="subtitle1">
                <div>Oxygen module not selected</div>
              </ng-template>
              <div
                class="ph-cal--container"
                *ngIf="experiment.usePH; else pHNoSelect"
              >
                <app-dynamic-dropdown-form-field
                  *ngIf="
                    device.pHCalibrationList?.length > 0 &&
                    !pHCalibrationLoading
                  "
                  [parentForm]="experimentSetupForm"
                  [device]="device"
                  [calibrationType]="6"
                  placeholder="Select pH Calibration"
                ></app-dynamic-dropdown-form-field>
                <span
                  *ngIf="
                    !pHError &&
                    !pHCalibrationLoading &&
                    (!device.pHCalibrationList ||
                      device.pHCalibrationList?.length === 0)
                  "
                  class="subtitle1"
                >
                  No pH calibrations
                </span>
                <span *ngIf="pHError" class="error">{{ pHError.message }}</span>
                <div *ngIf="pHCalibrationLoading" class="subtitle1">
                  <app-small-loading-spinner></app-small-loading-spinner>
                </div>
              </div>
              <ng-template #pHNoSelect class="subtitle1">
                <div>pH module not selected</div>
              </ng-template>
              <app-icon-button
                buttonId="remove-btn"
                buttonStyle="transparent"
                buttonShape="square"
                textColor="red"
                iconName="close"
                (buttonClicked)="removeDeviceFromExperiment(device.deviceId)"
              ></app-icon-button>
            </div>
          </div>
          <hr />
        </div>
      </form>
    </div>
    <hr />
  </div>
  <div
    id="next-btn--container"
    *ngIf="
      experiment?.experimentStatusId === ExperimentStatus.Draft;
      else draftLocked
    "
  >
    <app-button
      label="discard changes"
      buttonId="discard-btn"
      buttonStyle="yellow"
      (buttonClicked)="onDiscardChanges()"
      [isDisabled]="isProcessing || experimentSetupForm.pristine"
    ></app-button>
    <app-button
      label="save devices"
      buttonId="save-btn"
      type="submit"
      (buttonClicked)="onSaveDevices()"
      [isProcessing]="isProcessing"
      [isDisabled]="
        experimentSetupForm.invalid ||
        (experimentSetupForm.pristine && !hasDevicesToRemove) ||
        isProcessing
      "
    ></app-button>
    <app-button
      label="lock draft"
      buttonId="draft-lock-btn"
      buttonStyle="positive"
      (buttonClicked)="onLockDraft()"
      [isDisabled]="
        experimentSetupForm.dirty ||
        selectedDeviceList.length === 0 ||
        experimentSetupForm.invalid
      "
    ></app-button>
  </div>
  <ng-template #draftLocked>
    <div id="next-btn--container">
      <app-button
        label="go to dashboard"
        buttonId="go-to-dashboard"
        buttonStyle="positive"
        (buttonClicked)="goToDashboard()"
      ></app-button>
      <app-button
        label="Set up devices"
        buttonId="set-devices-btn"
        (buttonClicked)="setUpDevices()"
      ></app-button>
    </div>
  </ng-template>
</div>
<app-modal
  id="error"
  titleText="Error"
  [primaryText]="error?.message"
  buttonType="single-button"
  primaryButtonText="close"
  primaryButtonId="close-button"
  modalStyle="error"
  (modalButtonClicked)="modalButtonClicked($event)"
></app-modal>
<app-validation-modal
  id="delete-draft"
  titleText="Delete draft?"
  validationText="delete"
  buttonType="double-button"
  primaryButtonText="Confirm delete"
  primaryButtonId="confirm-delete-button"
  secondaryButtonText="Cancel"
  secondaryButtonId="cancel-delete-button"
  (modalButtonClicked)="deleteDraft()"
  [error]="deleteError"
></app-validation-modal>
<app-modal
  id="delete-success"
  titleText="Success"
  primaryText="Experiment draft was deleted."
  primaryButtonText="Go to dashboard"
  primaryButtonId="delete-success-button"
  modalStyle="success"
  (modalButtonClicked)="modalButtonClicked($event)"
  allowClose="false"
>
</app-modal>
<app-modal
  id="delete-error"
  titleText="Unable to delete draft"
  [primaryText]="deleteError?.message + '. Choose an action below'"
  buttonType="double-button"
  primaryButtonText="Go to dashboard"
  primaryButtonId="error-primary-button"
  secondaryButtonText="Close"
  secondaryButtonId="error-secondary-button"
  modalStyle="error"
  (modalButtonClicked)="modalButtonClicked($event)"
  allowClose="false"
>
</app-modal>
<app-modal
  id="success"
  titleText="Success"
  primaryText="Your devices have been updated"
  buttonType="single-button"
  primaryButtonText="close"
  primaryButtonId="close-sucess-button"
  modalStyle="success"
  (modalButtonClicked)="modalButtonClicked($event)"
></app-modal>
<app-modal
  id="lock-success"
  titleText="Draft Locked"
  buttonType="double-button"
  animationType="lock"
  primaryButtonText="Set up devices"
  primaryButtonId="primary-lock"
  secondaryButtonText="Go to dashboard"
  secondaryButtonId="secondary-lock"
  (modalButtonClicked)="modalButtonClicked($event)"
></app-modal>
<app-modal
  id="attention"
  titleText="Attention"
  primaryText="Your changes have not been saved. Press cancel to return to the form and save your changes, or continue to discard."
  buttonType="double-button"
  primaryButtonText="Continue"
  primaryButtonId="confirm-navigation"
  secondaryButtonText="Cancel"
  secondaryButtonId="cancel-button"
  modalStyle="warning"
  (modalButtonClicked)="modalButtonClicked($event)"
></app-modal>
