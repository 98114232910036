/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ERROR_MESSAGE } from 'src/app/_helpers/utils';
import { FormBase } from 'src/app/_models/form-base';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'app-mat-form-field',
  templateUrl: './mat-form-field.component.html',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInput,
    MatIcon,
  ],
  styleUrl: './mat-form-field.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MatFormFieldComponent {
  hide = true;

  /**
   * @param {FormBase<string>} field Form field base
   * @requires
   */
  @Input()
  field: FormBase<string>;

  /**
   * @param {FormGroup} parentForm The parent form
   * @requires
   */
  @Input()
  parentForm: FormGroup = new FormGroup({});

  /**
   * @param {string} iconName The name of the icon to be used in the form field
   */
  @Input()
  iconName: string;

  public get hasErrors(): boolean {
    return (
      this.parentForm?.controls[this.field.key] &&
      this.parentForm?.controls[this.field.key]?.errors &&
      this.parentForm?.controls[this.field.key]?.touched
    );
  }

  public get errorsList(): string[] {
    return Object.keys(this.parentForm?.controls[this.field.key].errors).map(
      (err: string) =>
        ERROR_MESSAGE[err](
          this.parentForm.controls[this.field.key].getError(err),
        ),
    );
  }
}
