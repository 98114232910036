import { AbstractControl, ValidationErrors } from '@angular/forms';

export function MustMatch(controlName: string, matchingControlName: string) {
  return (control: AbstractControl): ValidationErrors | null => {
    const controlValue = control.get(controlName)?.value as string;
    const matchingControlValue = control.get(matchingControlName)
      ?.value as string;

    if (controlValue !== matchingControlValue) {
      control.get(matchingControlName)?.setErrors({ mustMatch: true });
    } else {
      control.get(matchingControlName)?.setErrors(null);
    }
    return null;
  };
}
