<section
  class="underlined-form-field__container"
  [class.disabled]="field.disabled"
  [class.error]="hasErrors"
>
  <label class="overline" [for]="field.key" [formGroup]="parentForm">
    {{ field.label }}
    @if (field.required) {
      <span class="required" aria-required="true"> * </span>
    }
    <div class="underlined-form-field-input-container">
      <textarea
        [formControlName]="field.key"
        class="body1 underlined-form-field-input"
        [placeholder]="field.placeholder"
        [attr.aria-required]="field.required"
        [attr.rows]="rows"
        [attr.cols]="columns"
      ></textarea>
    </div>
  </label>
  @if (hasErrors) {
    <ul>
      @for (error of errorsList; track error) {
        <li class="underlined-form-field-error caption">{{ error }}</li>
      }
    </ul>
  }
</section>
