<div class="modal--container">
  <div class="modal-body">
    <div class="close-button--container">
      <app-icon-button
        id="hidden-btn"
        buttonId="remove-btn"
        buttonStyle="transparent"
        buttonShape="square"
        iconName="close"
        (buttonClicked)="close()"
      ></app-icon-button>
    </div>
    <h4>Filters</h4>
    <form [formGroup]="parentForm">
      @if (statusField) {
        <app-underlined-dropdown-form-field
          [parentForm]="parentForm"
          [field]="statusField"
        ></app-underlined-dropdown-form-field>
      }
      @if (modeField) {
        <app-underlined-dropdown-form-field
          [parentForm]="parentForm"
          [field]="modeField"
        ></app-underlined-dropdown-form-field>
      }
      @if (modulesField) {
        <app-checkbox-group
          id="module-select"
          [parentForm]="parentForm"
          [field]="modulesField"
          (checkboxClicked)="updateValue($event)"
        ></app-checkbox-group>
      }
      @if (offlineField) {
        <app-slide-toggle
          [parentForm]="parentForm"
          [field]="offlineField"
        ></app-slide-toggle>
      }
      @if (!hideDeletedField) {
        <app-slide-toggle
          [parentForm]="parentForm"
          [field]="deletedField"
        ></app-slide-toggle>
      }
    </form>
    <div
      class="button--container"
      [class.double]="buttonType === 'double-button'"
    >
      @if (buttonType === "double-button") {
        <app-button
          buttonStyle="secondary"
          [label]="secondaryButtonText"
          [buttonId]="secondaryButtonId"
          (buttonClicked)="buttonEventHandler(secondaryButtonId)"
          [isDisabled]="isProcessing"
        ></app-button>
      }
      <app-button
        [label]="primaryButtonText"
        [buttonId]="primaryButtonId"
        (buttonClicked)="buttonEventHandler(primaryButtonId)"
        [isDisabled]="isProcessing"
      ></app-button>
    </div>
  </div>
</div>
<div class="modal--backdrop"></div>
