<div class="search" [formGroup]="parentForm">
  <input
    [type]="field.type"
    [formControlName]="field.key"
    [placeholder]="field.placeholder"
    class="search-term"
  />
  <button (click)="clickButton()" type="submit" class="search-button">
    <mat-icon>search</mat-icon>
  </button>
</div>
