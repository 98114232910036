<section>
  <div class="title--container">
    @if (flask) {
      <h6 class="flask">{{ flask }}</h6>
    }
    @if (flaskIcon) {
      <app-svg-template
        [iconName]="flaskIcon"
        height="30"
        width="30"
        customViewBox="0 0 30 30"
      ></app-svg-template>
    }
  </div>
  <div>
    <span class="body2 title">OD: </span
    ><span class="body2 data">{{ dataReading | number: "1.2-2" }}</span>
  </div>
  <div>
    <span class="body2 title">Last OD reading: </span
    ><span class="body2 data"> {{ time | date: "HH:mm a, dd/MM/yy" }}</span>
  </div>
  <div>
    <span class="body2 title">Temperature: </span
    ><span class="body2 data">
      {{ temperatureReading | number: "1.2-2" }}
    </span>
  </div>
  <div>
    <span class="body2 title">Stirring speed: </span
    ><span class="body2 data"> {{ speedRPMReading }} </span>
  </div>
</section>
