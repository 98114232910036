import { Component } from '@angular/core';
import { IconNames } from 'src/app/_components/icons/icon-names';
import { RouterLink } from '@angular/router';
import { ButtonComponent } from '../../_components/buttons/button/button.component';

@Component({
  selector: 'app-reset-successful',
  templateUrl: './reset-successful.component.html',
  styleUrls: ['./reset-successful.component.scss'],
  standalone: true,
  imports: [ButtonComponent, RouterLink],
})
export class ResetSuccessfulComponent {
  logo = IconNames.LogoColour;
}
