<div id="experiment--container">
  <div id="nav--container">
    <app-option-tab-toggle
      id="toggle--container"
      radioGroup="navigationToggle"
      [selectOptions]="navOptions"
      type="round"
    >
    </app-option-tab-toggle>
  </div>
  <hr />
  <div id="inner-page--container">
    @if (error) {
      <div id="error--container">
        <h4>Something went wrong</h4>
        <p class="error">Error: {{ error.message }}</p>
      </div>
    }
    <div id="select-device--container">
      @if (deviceCheckboxesAreInitialised) {
        <app-checkbox-group
          id="checkbox--container"
          [parentForm]="deviceSelectorForm"
          [field]="deviceCheckboxesField"
          (checkboxClicked)="deviceCheckboxClicked($event)"
        ></app-checkbox-group>
      }
      <app-icon-button
        label="update"
        buttonId="update-btn"
        (buttonClicked)="getExperiment()"
        [isProcessing]="loadingDeviceSummary"
        iconName="autorenew"
        buttonShape="square"
        buttonStyle="outlined"
      ></app-icon-button>
    </div>
    <hr />
    <div id="device--container">
      @if (selectedDevices?.length > 0) {
        <section class="device-summary--container">
          @for (device of selectedDevices; track device.id) {
            <app-device-info-card
              [device]="device"
              [lastUpdateTime]="deviceListLastUpdated"
              [experiment]="experiment"
              (pauseDeviceClicked)="pauseRestartButtonClicked($event)"
            ></app-device-info-card>
          }
        </section>
      } @else {
        <section id="placeholder--container">
          <h4>No devices selected</h4>
          <p class="body1">
            Use the checkboxes above to choose device summaries to display
          </p>
        </section>
      }
    </div>
    <hr />
    <section id="data--container">
      <div class="experiment-overview--container">
        <div class="header--container">
          <h4>Experiment Overview</h4>
          @if (experiment?.isDeleted === false) {
            <app-status-indicator
              [status]="ExperimentStatus[experiment?.experimentStatusId]"
            ></app-status-indicator>
          }
          @if (experiment?.isDeleted) {
            <app-status-indicator status="Deleted"></app-status-indicator>
          }
          <div class="buttons--container">
            <app-icon-button
              label="Update Graphs"
              buttonId="update-btn"
              buttonStyle="outlined"
              (buttonClicked)="getExperimentData()"
              [isProcessing]="loadingOpticalDensity"
              iconName="autorenew"
              buttonShape="square"
            ></app-icon-button>
            <app-icon-button
              label="make parameter changes"
              buttonId="control-btn"
              [routerLink]="'/experiment/' + experiment?.id + '/control'"
              [isDisabled]="
                experiment?.experimentStatusId !== ExperimentStatus.Active
              "
              buttonStyle="outlined"
              iconName="swap_vert"
              buttonShape="square"
            ></app-icon-button>
          </div>
        </div>
        <div class="card--container">
          <app-reduced-small-data-card
            title="Experiment Mode"
            [dataReading]="ExperimentMode[experiment?.experimentMode]"
          >
          </app-reduced-small-data-card>
          <app-reduced-small-data-card
            title="Organism"
            [dataReading]="experiment?.organism"
          >
          </app-reduced-small-data-card>
          <app-reduced-small-data-card
            title="Strain"
            [dataReading]="experiment?.strain"
          >
          </app-reduced-small-data-card>
          <app-reduced-small-data-card
            title="Experiment Name"
            [dataReading]="experiment?.name"
          >
          </app-reduced-small-data-card>
          <app-reduced-small-data-card
            title="Media"
            [dataReading]="experiment?.media"
          >
          </app-reduced-small-data-card>
          <div class="time-left--container">
            <div>
              @if (
                experiment?.experimentStatusId === ExperimentStatus.Active &&
                (currentTime$ | async)
              ) {
                <span class="subtitle2">
                  {{
                    getTimeLeft(
                      experiment.startTime,
                      experiment.duration,
                      $any(currentTime$ | async)
                    ).days | number: "2.0"
                  }}:
                  {{
                    getTimeLeft(
                      experiment.startTime,
                      experiment.duration,
                      $any(currentTime$ | async)
                    ).hours | number: "2.0"
                  }}:
                  {{
                    getTimeLeft(
                      experiment.startTime,
                      experiment.duration,
                      $any(currentTime$ | async)
                    ).minutes | number: "2.0"
                  }}:
                  {{
                    getTimeLeft(
                      experiment.startTime,
                      experiment.duration,
                      $any(currentTime$ | async)
                    ).seconds | number: "2.0"
                  }}
                </span>
              }
              @if (
                experiment?.experimentStatusId === ExperimentStatus.Complete
              ) {
                <span>--:--</span>
              }
            </div>
            <div>
              <span class="body1">Time Left</span>
            </div>
          </div>
        </div>
      </div>
      <div id="lower">
        <!-- Optical Density graph -->
        <div class="graph--container-outer" aria-live="polite">
          <div class="graph--container-title">
            <h5>Optical Density</h5>
            <div class="download--container">
              <app-icon-button
                label="download base module data"
                buttonId="download-base-module-btn"
                buttonStyle="outlined"
                iconName="download"
                buttonShape="square"
                (buttonClicked)="downloadBaseModuleCsv()"
                [isProcessing]="isDownloadingBaseModuleCsv"
                [isDisabled]="isDownloadingBaseModuleCsv"
              ></app-icon-button>
              @if (
                experiment &&
                experiment.experimentMode === ExperimentMode.Turbidostat
              ) {
                <app-icon-button
                  label="download turbidostat data"
                  buttonId="download-turbidostat-btn"
                  buttonStyle="outlined"
                  iconName="download"
                  buttonShape="square"
                  (buttonClicked)="downloadTurbidostatCsv()"
                  [isProcessing]="isDownloadingTurbidostatCsv"
                  [isDisabled]="isDownloadingTurbidostatCsv"
                ></app-icon-button>
              }
            </div>
          </div>
          @if (renderODGraph && selectedODData) {
            <app-graph
              [experimentData]="selectedODData"
              [screenSize]="graphSize"
              [graphId]="odGraphId"
              [yAxisLabel]="'Optical Density'"
              [isLogarithmic]="isLogarithmicChecked"
            ></app-graph>
          } @else {
            <div class="loading--container">
              <app-loading-spinner
                [label]="opticalDensityLoadingText"
              ></app-loading-spinner>
            </div>
          }
          <div class="device-select__container">
            <app-checkbox
              [checkbox]="isLogarithmic"
              [parentForm]="graphCheckboxesForm"
              (checkboxSelected)="handleLogarithmicCheckboxClicked($event)"
            ></app-checkbox>
            @if (devices) {
              <app-graph-checkbox-group
                id="od--checks"
                [checkboxTitles]="odDeviceTitles"
                [parentForm]="graphCheckboxesForm"
                [field]="odFlaskCheckboxesField"
                [formArrayGroupName]="odFlaskCheckboxesField.key"
                (checkboxClicked)="handleFlaskCheckboxClicked($event, 'od')"
              ></app-graph-checkbox-group>
            }
          </div>
        </div>
        <!-- Temperature graph -->
        <div class="graph--container-outer" aria-live="polite">
          <div class="graph--container-title">
            <h5>Temperature</h5>
            <app-icon-button
              label="download data"
              buttonId="download-btn"
              buttonStyle="outlined"
              iconName="download"
              buttonShape="square"
              (buttonClicked)="downloadTemperatureCsv()"
              [isProcessing]="isDownloadingTemperatureCsv"
              [isDisabled]="isDownloadingTemperatureCsv"
            ></app-icon-button>
          </div>
          @if (renderTemperatureGraph && selectedTemperatureData) {
            <app-graph
              [experimentData]="selectedTemperatureData"
              [screenSize]="graphSize"
              [graphId]="temperatureGraphId"
              [yAxisLabel]="'Temperature'"
            ></app-graph>
          } @else {
            <div class="loading--container">
              <app-loading-spinner
                [label]="temperatureLoadingText"
              ></app-loading-spinner>
            </div>
          }
          <div class="device-select__container">
            @if (devices) {
              <app-graph-checkbox-group
                id="temperature--checks"
                [checkboxTitles]="temperatureDeviceTitles"
                [parentForm]="graphCheckboxesForm"
                [field]="temperatureFlaskCheckboxesField"
                [formArrayGroupName]="temperatureFlaskCheckboxesField.key"
                (checkboxClicked)="
                  handleFlaskCheckboxClicked($event, 'temperature')
                "
              ></app-graph-checkbox-group>
            }
          </div>
        </div>
        <!-- Oxygen graph -->
        @if (experiment?.useOxygen) {
          <div class="graph--container-outer" aria-live="polite">
            <div class="graph--container-title">
              <h5>Oxygen</h5>
              <app-icon-button
                label="download data"
                buttonId="download-btn"
                buttonStyle="outlined"
                iconName="download"
                buttonShape="square"
                (buttonClicked)="downloadOxygenCsv()"
                [isProcessing]="isDownloadingOxygenCsv"
                [isDisabled]="isDownloadingOxygenCsv"
              ></app-icon-button>
            </div>
            @if (renderOxygenGraph && selectedOxygenData) {
              <app-graph
                [experimentData]="selectedOxygenData"
                [screenSize]="graphSize"
                [graphId]="oxygenGraphId"
                [yAxisLabel]="'Oxygen'"
              ></app-graph>
            } @else {
              <div class="loading--container">
                <app-loading-spinner
                  [label]="oxygenLoadingText"
                ></app-loading-spinner>
              </div>
            }
            <div class="device-select__container">
              <app-slide-toggle-group
                [field]="oxygenDataFilterField"
                [parentForm]="graphCheckboxesForm"
                (toggleClicked)="handleOxygenToggleClicked()"
              ></app-slide-toggle-group>
              @if (devices) {
                <app-graph-checkbox-group
                  id="oxygen--checks"
                  [checkboxTitles]="oxygenDeviceTitles"
                  [parentForm]="graphCheckboxesForm"
                  [field]="oxygenFlaskCheckboxesField"
                  [formArrayGroupName]="oxygenFlaskCheckboxesField.key"
                  (checkboxClicked)="
                    handleFlaskCheckboxClicked($event, 'oxygen')
                  "
                ></app-graph-checkbox-group>
              }
            </div>
          </div>
        }
        <!-- pH graph -->
        @if (experiment?.usePH) {
          <div class="graph--container-outer" aria-live="polite">
            <div class="graph--container-title">
              <h5>pH</h5>
              <app-icon-button
                label="download data"
                buttonId="download-btn"
                buttonStyle="outlined"
                iconName="download"
                buttonShape="square"
                (buttonClicked)="downloadPhCsv()"
                [isProcessing]="isDownloadingPhCsv"
                [isDisabled]="isDownloadingPhCsv"
              ></app-icon-button>
            </div>
            @if (renderPHGraph && selectedPHData) {
              <app-graph
                [experimentData]="selectedPHData"
                [screenSize]="graphSize"
                [graphId]="pHGraphId"
                [yAxisLabel]="'pH'"
              ></app-graph>
            } @else {
              <div class="loading--container">
                <app-loading-spinner
                  [label]="pHLoadingText"
                ></app-loading-spinner>
              </div>
            }
            <div class="device-select__container">
              @if (devices) {
                <app-graph-checkbox-group
                  id="pH--checks"
                  [checkboxTitles]="pHDeviceTitles"
                  [parentForm]="graphCheckboxesForm"
                  [field]="pHFlaskCheckboxesField"
                  [formArrayGroupName]="pHFlaskCheckboxesField.key"
                  (checkboxClicked)="handleFlaskCheckboxClicked($event, 'pH')"
                ></app-graph-checkbox-group>
              }
            </div>
          </div>
        }
        <!-- Fluorescence graph -->
        @if (experiment?.useFluorescence) {
          <div class="graph--container-outer" aria-live="polite">
            <div class="graph--container-title">
              <h5>Fluorescence</h5>
              <app-icon-button
                label="download data"
                buttonId="download-btn"
                buttonStyle="outlined"
                iconName="download"
                buttonShape="square"
                (buttonClicked)="downloadFluorescenceCsv()"
                [isProcessing]="isDownloadingFluorescenceCsv"
                [isDisabled]="isDownloadingFluorescenceCsv"
              ></app-icon-button>
            </div>
            @if (renderFluorescenceGraph && selectedFluorescenceData) {
              <app-graph
                [experimentData]="selectedFluorescenceData"
                [screenSize]="graphSize"
                [graphId]="fluorescenceGraphId"
                [yAxisLabel]="'Fluorescence'"
              ></app-graph>
            } @else {
              <div class="loading--container">
                <app-loading-spinner
                  [label]="fluorescenceLoadingText"
                ></app-loading-spinner>
              </div>
            }
            <div class="fluoro-select__container">
              <div class="device-select__container-left">
                <div class="selection__container">
                  <span class="subtitle2"> Selection 1:</span>
                  <app-underlined-dropdown-form-field
                    id="led-selection-1"
                    [field]="ledSelection1Field"
                    [parentForm]="fluorescenceSelectionForm"
                  >
                  </app-underlined-dropdown-form-field>
                  <app-underlined-dropdown-form-field
                    id="fluoro-selection-1"
                    [field]="fluorescenceChannelSelection1Field"
                    [parentForm]="fluorescenceSelectionForm"
                  >
                  </app-underlined-dropdown-form-field>
                </div>
                <div class="selection__container">
                  <span class="subtitle2"> Selection 2:</span>
                  <app-underlined-dropdown-form-field
                    id="led-selection-2"
                    [field]="ledSelection2Field"
                    [parentForm]="fluorescenceSelectionForm"
                  >
                  </app-underlined-dropdown-form-field>
                  <app-underlined-dropdown-form-field
                    id="fluoro-selection-2"
                    [field]="fluorescenceChannelSelection2Field"
                    [parentForm]="fluorescenceSelectionForm"
                  >
                  </app-underlined-dropdown-form-field>
                </div>
                <div class="selection__container">
                  <span class="subtitle2"> Selection 3:</span>
                  <app-underlined-dropdown-form-field
                    id="led-selection-3"
                    [field]="ledSelection3Field"
                    [parentForm]="fluorescenceSelectionForm"
                  >
                  </app-underlined-dropdown-form-field>
                  <app-underlined-dropdown-form-field
                    id="fluoro-selection-3"
                    [field]="fluorescenceChannelSelection3Field"
                    [parentForm]="fluorescenceSelectionForm"
                  >
                  </app-underlined-dropdown-form-field>
                </div>
              </div>
              <app-button
                buttonStyle="standard"
                label="Graph selection"
                (buttonClicked)="getFluorescenceData()"
                [isProcessing]="
                  !renderFluorescenceGraph && isFetchingFluorescenceData
                "
                [isDisabled]="
                  !fluorescenceSelectionForm.valid ||
                  fluorescenceSelectionForm.pristine
                "
              ></app-button>
            </div>
            <div class="device-select__container">
              @if (devices) {
                <app-graph-checkbox-group
                  id="fluorescence--checks"
                  [checkboxTitles]="fluorescenceDeviceTitles"
                  [parentForm]="graphCheckboxesForm"
                  [field]="fluorescenceFlaskCheckboxesField"
                  [formArrayGroupName]="fluorescenceFlaskCheckboxesField.key"
                  (checkboxClicked)="
                    handleFlaskCheckboxClicked($event, 'fluorescence')
                  "
                ></app-graph-checkbox-group>
              }
            </div>
          </div>
        }
      </div>
    </section>
  </div>
</div>
<app-validation-modal
  id="pause-device"
  [titleText]="pauseDeviceTitleText"
  [validationText]="pauseDeviceValidationText"
  buttonType="double-button"
  [primaryButtonText]="pauseDeviceButtonText"
  primaryButtonId="primary-button"
  secondaryButtonText="Close"
  secondaryButtonId="secondary-button"
  (modalButtonClicked)="pauseRestartDevices()"
  [error]="error"
></app-validation-modal>
