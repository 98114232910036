import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormBase } from 'src/app/_models/form-base';

@Component({
  selector: 'app-dropdown-form-field',
  templateUrl: './dropdown-form-field.component.html',
  styleUrls: ['./dropdown-form-field.component.scss'],
})
export class DropdownFormFieldComponent {
  /**
   * @param {FormBase<string>} field Form base
   * @requires
   */
  @Input()
  field: FormBase<string> = new FormBase();

  /**
   * @param {FormGroup} parentForm Parent form name
   * @requires
   */
  @Input()
  parentForm: FormGroup = new FormGroup({});

  /**
   * @param {string} dropdownStyle Dropdown style
   */
  @Input()
  dropdownStyle: 'default' | 'nav' = 'default';

  /**
   * @returns {string[]} Returns a list of classes based on the status of the component inputs
   */
  public get classes(): string[] {
    const disabled = this.field?.disabled ? 'disabled' : '';
    const isNav = this.dropdownStyle === 'nav' ? 'nav--label' : '';
    return ['dropdown-form-field', 'body1', isNav, disabled];
  }

  public get selectClasses(): string[] {
    return ['select', `select--${this.dropdownStyle}`, 'body1'];
  }
}
