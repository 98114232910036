import { Component, input, model } from '@angular/core';
import { TabOptions } from 'src/app/_models/tab-options';

@Component({
  selector: 'app-alt-option-tab-toggle',
  standalone: true,
  imports: [],
  templateUrl: './alt-option-tab-toggle.component.html',
  styleUrl: './alt-option-tab-toggle.component.scss',
})
export class AltOptionTabToggleComponent {
  title: 'AltOptionTabToggle';
  component: '';

  /**
   * @requires
   * What is the name of the toggle group?
   */
  groupName = input.required<string>();

  /**
   * What value and text for each button?
   * @requires
   */
  selectOptions = input.required<TabOptions[]>();

  /**
   * What type of button?
   */
  type = input<'round' | 'square'>('round');

  /**
   * @param { string } currentValue
   */
  currentValue = model<string>();

  /**
   * Optional ARIA label for the entire toggle group
   */
  ariaLabel = input<string>('');

  /**
   * Optional description of the toggle's purpose for screen readers
   */
  ariaDescription = input<string>('');

  updateValue(value: string): void {
    this.currentValue.set(value);
  }
}
