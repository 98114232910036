<label
  class="dropdown-form-field body1"
  [class.disabled]="field.disabled"
  [class.nav--label]="dropdownStyle === 'nav'"
  [for]="field.key"
  [formGroup]="parentForm"
  [attr.aria-label]="field.key"
>
  <select
    class="select body1"
    [class.select--nav]="dropdownStyle === 'nav'"
    [class.select--default]="dropdownStyle === 'default'"
    [formControlName]="field.key"
    [id]="field.key"
  >
    <option disabled selected [value]="field.value">
      {{ field.placeholder }}
    </option>
    @for (option of field.options; track option.key) {
      <option [ngValue]="option.key">
        {{ option.value }}
      </option>
    }
  </select>
</label>
