<label
  class="dropdown-form-field body1"
  [class.disabled]="isDisabled"
  [for]="'device_' + device.deviceId.toString()"
  [formGroup]="parentForm"
  [attr.aria-label]="'device_' + device.deviceId.toString()"
>
  @switch (calibrationType) {
    @case (5) {
      <!-- BASE MODULE SELECT-->
      <select
        class="body1"
        [formControlName]="'device_' + device.deviceId.toString()"
        [id]="'device_' + device.deviceId.toString()"
      >
        <option disabled selected [ngValue]="null">{{ placeholder }}</option>
        @for (calibration of device.calibrationList; track calibration.id) {
          <option [value]="calibration.id">{{ calibration.name }}</option>
        }
      </select>
    }
    @case (1) {
      <!-- OXYGEN MODULE SELECT-->
      <select
        class="body1"
        [formControlName]="'device_' + device.deviceId.toString() + '_oxy'"
        [id]="'device_' + device.deviceId.toString() + '_oxy'"
      >
        <option disabled selected [ngValue]="null">{{ placeholder }}</option>
        @for (
          calibration of device.oxygenCalibrationList;
          track calibration.id
        ) {
          <option [value]="calibration.id">{{ calibration.name }}</option>
        }
      </select>
    }
    @case (6) {
      <!-- PH MODULE SELECT-->
      <select
        class="body1"
        [formControlName]="'device_' + device.deviceId.toString() + '_ph'"
        [id]="'device_' + device.deviceId.toString() + '_ph'"
      >
        <option disabled selected [ngValue]="null">{{ placeholder }}</option>
        @for (calibration of device.pHCalibrationList; track calibration.id) {
          <option [value]="calibration.id">{{ calibration.name }}</option>
        }
      </select>
    }
  }
</label>
