import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { ErrorObject } from 'src/app/_models/error';
import { FormBase } from 'src/app/_models/form-base';
import { Laboratory } from 'src/app/_models/laboratory';
import { Role } from 'src/app/_models/role';
import { User, UserUpdate } from 'src/app/_models/user';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { LaboratoryService } from 'src/app/_services/laboratory.service';
import { ModalService } from 'src/app/_services/modal.service';
import { formatLabel } from 'src/app/_helpers/format-role.pipe';
import { UserService } from 'src/app/_services/user.service';
import { UserUpdateResponse } from 'src/app/_models/api-responses';

@Component({
  selector: 'app-lab-details',
  templateUrl: './lab-details.component.html',
  styleUrls: ['./lab-details.component.scss'],
})
export class LabDetailsComponent implements OnInit {
  // Permissions
  laboratory: Laboratory;
  currentUser: User;
  users: User[] = [];
  availableUsers: User[] = [];
  addUserError: ErrorObject = null;
  selectedUserId: number;
  currentLabOrgId: number;
  updateUserId: number;

  // controls
  isUpdateLabProcessing = false;
  isUpdateRoleProcessing = false;
  isAddUserToLabProcessing = false;
  isLoading = true;
  allowNavigation: Subject<boolean> = new Subject<boolean>();
  error: ErrorObject = null;
  updateLabSuccessMessage: string;
  isRemoveUserFromLabProcessing = false;
  removeUserFromLabId: number;
  deleteLabSuccessMessage: string;
  successModalPrimaryText: string;
  isProcessing = false;

  // Table control variables
  currentFilters = [];
  currentSort: {
    sortBy: string;
    sortDirection: string;
  } = {
    sortBy: 'LastName',
    sortDirection: 'asc',
  };

  // Pagination
  pageRequested = 1;
  pageSize = 5;
  totalCount = 0;
  totalPages = 0;
  currentPage = 1;
  hasNext = false;
  hasPrevious = false;
  selectedRole: Role = null;

  // make role enum available in the template
  Role = Role;

  labNameField = new FormBase({
    key: 'labName',
    label: 'Lab Name',
    type: 'text',
    placeholder: 'Lab Name',
    disabled: false,
    required: true,
    value: '',
    options: [],
  });

  editLabForm = this.formBuilder.group({
    [this.labNameField.key]: ['', Validators.required],
  });

  addUserField = new FormBase({
    key: 'addUser',
    label: 'Select user',
    type: 'select',
    placeholder: 'Select user',
    disabled: false,
    required: false,
    value: '',
    options: [],
  });

  addUserForm = this.formBuilder.group({
    [this.addUserField.key]: [],
  });

  userRoleField = new FormBase({
    key: 'role',
    label: 'Role',
    type: 'select',
    placeholder: 'Select role',
    disabled: false,
    required: true,
    value: '',
    options: [
      { key: Role.Read_Access_User, value: 'Read Access User' },
      { key: Role.Run_Access_User, value: 'Run Access User' },
      { key: Role.Edit_Access_User, value: 'Edit Access User' },
      { key: Role.Lab_Administrator, value: 'Lab Administrator' },
    ],
  });

  editUserForm = this.formBuilder.group({
    [this.userRoleField.key]: ['', Validators.required],
  });

  usersPerPageField = new FormBase({
    key: 'usersPerPage',
    label: 'Page Size',
    type: 'dropdown',
    placeholder: '',
    disabled: false,
    required: false,
    value: '5', // Set default value to 5
    options: [
      { key: '5', value: '5' },
      { key: '10', value: '10' },
      { key: '25', value: '25' },
      { key: '50', value: '50' },
      { key: '100', value: '100' },
    ],
  });

  userRolesFilter = new FormBase({
    key: 'userRoleFilter',
    label: 'Select role to filter by:',
    type: 'dropdown',
    placeholder: 'Filter by role',
    disabled: false,
    required: false,
    value: null,
    options: [
      { key: null, value: 'All' },
      { key: 1, value: 'Ogi Super Administrator' },
      { key: 2, value: 'Ogi Laboratory Administrator' },
      { key: 7, value: 'Super User' },
      { key: 3, value: 'Lab Administrator' },
      { key: 4, value: 'Edit Access User' },
      { key: 6, value: 'Read Access User' },
      { key: 5, value: 'Run Access User' },
    ],
  });

  usersTableControls = this.formBuilder.group({
    [this.usersPerPageField.key]: new FormControl(this.usersPerPageField.value),
    [this.userRolesFilter.key]: new FormControl(this.userRolesFilter.value),
  });

  constructor(
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    private labService: LaboratoryService,
    private modalService: ModalService,
    private router: Router,
    private userService: UserService,
    private laboratoryService: LaboratoryService
  ) {}

  ngOnInit(): void {
    this.currentUser = this.authenticationService.currentUserValue;
    this.laboratory = this.authenticationService.selectedLaboratory;

    // Check for saved sort, filter, and pagination settings
    if (this.labService.paginationCache !== null) {
      this.pageSize = this.labService.paginationCache.pageSize;
      this.pageRequested = this.labService.paginationCache.pageNumber;
      this.usersTableControls.patchValue({
        [this.usersPerPageField.key]: this.pageSize.toString(),
      });
    }

    // Subscribe to changes in the users per page dropdown
    this.usersTableControls
      .get(this.usersPerPageField.key)
      .valueChanges.subscribe((numOfUsers) => {
        this.pageSize = numOfUsers as number;
        this.pageRequested = 1;
        this.getLabMembers();
      });

    // Subscribe to the value changes of the request status dropdown
    this.usersTableControls
      .get(this.userRolesFilter.key)
      .valueChanges.subscribe((status) => {
        this.selectedRole = status as Role;
        this.getLabMembers();
      });

    this.addUserForm
      .get(this.addUserField.key)
      .valueChanges.subscribe((value: { key: string; value: string }) => {
        const selectedUser = this.addUserField.options.find((option) => {
          return option.key === value.key;
        });
        this.selectedUserId = selectedUser?.key as number;
      });

    if (this.laboratory) {
      this.initialiseForm();
      this.getLab();
    } else {
      this.isLoading = false;
    }
  }

  initialiseForm(): void {
    this.editLabForm.setValue({
      labName: this.laboratory.laboratoryName,
    });

    if (
      this.currentUser.role ===
      (Role.Read_Access_User || Role.Run_Access_User || Role.Edit_Access_User)
    ) {
      this.editLabForm.disable();
    }
    this.editLabForm.markAsPristine();
  }

  getLab(): void {
    this.isLoading = true;

    this.laboratoryService.getLab(this.laboratory.laboratoryId).subscribe({
      next: (response: Laboratory) => {
        this.currentLabOrgId = response.organisationId;
        this.isLoading = false;
        this.getLabMembers();
      },
      error: () => {
        this.isLoading = false;
      },
    });
  }

  getAvailableUsersForLab(): void {
    const orgId =
      this.currentLabOrgId.toString() !==
      this.currentUser.organisationId.toString()
        ? this.currentLabOrgId
        : null;

    // Only pass in org id if ogi admin is viewing lab in different org
    this.labService
      .getAvailableUsersForLab(this.laboratory.laboratoryId, orgId)
      .subscribe({
        next: (response) => {
          this.addUserField.options = response.map((user) => {
            return {
              key: user.id,
              value:
                user.firstName +
                ' ' +
                user.lastName +
                ' - ' +
                formatLabel(user.role),
            };
          });
          this.availableUsers = response;
        },
        error: (error: ErrorObject) => {
          this.error = error;
        },
      });
  }

  addUserToLab(): void {
    if (this.isAddUserToLabProcessing || this.addUserForm.invalid) {
      return;
    }

    this.isAddUserToLabProcessing = true;
    this.error = null;
    this.updateLabSuccessMessage = '';

    this.labService
      .addUserToLab(this.selectedUserId, this.laboratory.laboratoryId)
      .subscribe({
        next: () => {
          // call to update user table/get lab members for dropdown
          this.getLabMembers();
          this.isAddUserToLabProcessing = false;
          this.selectedUserId = null;
          this.updateLabSuccessMessage = 'User added to lab.';
          this.addUserForm.reset();
          this.openModal('edit-lab');
        },
        error: (error: ErrorObject) => {
          this.isAddUserToLabProcessing = false;
          this.error = error;
          this.openModal('error');
        },
      });
  }

  getLabMembers(): void {
    this.labService
      .getLabMembers(
        this.laboratory.laboratoryId,
        this.currentSort.sortBy,
        this.currentSort.sortDirection,
        this.pageRequested,
        this.pageSize,
        this.selectedRole
      )
      .subscribe({
        next: (response) => {
          const { users, paginationData } = response;
          const { CurrentPage, PageSize, TotalPages, HasNext, HasPrevious } =
            paginationData;

          this.users = users;
          this.currentPage = CurrentPage;
          this.pageSize = PageSize;
          this.totalPages = TotalPages;
          this.hasNext = HasNext;
          this.hasPrevious = HasPrevious;

          this.labService.onUpdatePageNumber(this.currentPage);
          this.labService.onUpdatePageSize(this.pageSize);

          this.isLoading = false;
          if (
            this.currentUser.role === Role.Lab_Administrator ||
            this.currentUser.role === Role.Super_User ||
            this.currentUser.role === Role.OGI_Super_Administrator ||
            this.currentUser.role === Role.OGI_Laboratory_Administrator
          ) {
            this.getAvailableUsersForLab();
          }
        },
        error: (error: ErrorObject) => {
          this.error = error;
          this.isLoading = false;
        },
      });
  }

  displayUserRoleDropdown(user: User): boolean {
    if (
      this.currentUser.role !== Role.Lab_Administrator ||
      user.role === Role.OGI_Super_Administrator ||
      user.role === Role.Super_User ||
      user.role === Role.OGI_Laboratory_Administrator ||
      this.currentUser.id.toString() === user.id.toString()
    ) {
      return true;
    } else return false;
  }

  onSort(sortTerm: string): void {
    if (this.currentSort.sortBy === sortTerm) {
      if (this.currentSort.sortDirection === 'asc') {
        this.currentSort.sortDirection = 'desc';
      } else {
        this.currentSort.sortDirection = 'asc';
      }
    } else {
      this.currentSort.sortBy = sortTerm;
      this.currentSort.sortDirection = 'asc';
    }
    this.getLabMembers();
  }

  getClasses(sortTerm: string): string[] {
    const active = this.currentSort.sortBy === sortTerm ? 'active' : '';
    return [active];
  }

  onAddNewUser(): void {
    void this.router.navigate(['user/add-user']);
  }

  getPrevious(): void {
    this.pageRequested = this.currentPage - 1;
    this.getLabMembers();
  }

  getNext(): void {
    this.pageRequested = this.currentPage + 1;
    this.getLabMembers();
  }

  onSubmit(): void {
    if (this.editLabForm.invalid || this.isUpdateLabProcessing) {
      return;
    }

    this.isUpdateLabProcessing = true;
    this.error = null;
    this.updateLabSuccessMessage = '';

    // update lab
    this.labService
      .updateLab(this.laboratory.laboratoryId, this.editLabForm.value.labName)
      .subscribe({
        next: (response: string) => {
          this.isUpdateLabProcessing = false;
          this.laboratory.laboratoryName = this.editLabForm.value.labName;
          this.authenticationService.setSelectedLaboratory(this.laboratory);
          this.updateLabSuccessMessage = response;
          this.openModal('edit-lab');
        },
        error: (error: ErrorObject) => {
          this.isUpdateLabProcessing = false;
          this.error = error;
          this.openModal('error');
        },
      });
  }

  get f(): FormGroup['controls'] {
    return this.editUserForm.controls;
  }

  onUpdateUser(userId: number): void {
    if (this.editUserForm.invalid || this.isUpdateRoleProcessing) {
      return;
    }

    this.isUpdateRoleProcessing = true;
    this.updateUserId = userId;
    this.error = null;
    this.updateLabSuccessMessage = '';

    const userUpdate: UserUpdate = {
      id: userId,
      firstName: null,
      lastName: null,
      userRole: this.f.role.value as Role,
    };

    this.userService
      .updateUser(userUpdate)
      .pipe()
      .subscribe({
        next: (data: UserUpdateResponse) => {
          this.isUpdateRoleProcessing = false;
          this.updateUserId = null;
          this.updateLabSuccessMessage = data.message;
          this.getLabMembers();
          this.openModal('edit-lab');
        },
        error: (error: ErrorObject) => {
          this.isUpdateRoleProcessing = false;
          this.updateUserId = null;
          this.error = error;
          this.openModal('error');
        },
      });
  }

  onEditUser(userId: number): void {
    if (this.currentUser.id.toString() === userId.toString()) {
      void this.router.navigate(['account/update-details']);
    } else {
      void this.router.navigate([`user/update-user/${userId}`]);
    }
  }

  onRemoveUserFromLab(userId: number): void {
    if (this.isRemoveUserFromLabProcessing) {
      return;
    }

    this.error = null;
    this.isRemoveUserFromLabProcessing = true;
    this.removeUserFromLabId = userId;

    this.labService
      .removeUserFromLab(userId, this.laboratory.laboratoryId)
      .subscribe({
        next: () => {
          this.isRemoveUserFromLabProcessing = false;
          this.removeUserFromLabId = null;
          this.getLabMembers();
        },
        error: (error: ErrorObject) => {
          this.error = error;
          this.isRemoveUserFromLabProcessing = false;
          this.removeUserFromLabId = null;
          this.openModal('error');
        },
      });
  }

  deleteLab(): void {
    this.isProcessing = true;
    this.error = null;

    this.labService.deleteLab(this.laboratory.laboratoryId).subscribe({
      next: () => {
        this.isProcessing = false;
        this.closeModal('delete-lab');
        this.successModalPrimaryText =
          'Delete laboratory request has been submitted for approval.';
        this.modalService.open('success');
      },
      error: (error: ErrorObject) => {
        this.error = error;
        this.openModal('error');
      },
    });
  }

  openModal(modalId: string): void {
    this.modalService.open(modalId);
  }

  closeModal(modalId: string): void {
    this.modalService.close(modalId);
  }

  modalButtonClicked(buttonId: string): void {
    switch (buttonId) {
      case 'dashboard-button':
        this.closeModal('edit-lab');
        break;
      case 'close-button':
        this.closeModal('edit-lab');
        this.closeModal('error');
        this.closeModal('delete-lab');
        this.closeModal('success');
        this.error = null;
        break;
      case 'cancel-button':
        this.allowNavigation.next(false);
        this.closeModal('attention');
        break;
      case 'confirm-navigation':
        this.allowNavigation.next(true);
        this.closeModal('attention');
        break;
    }
  }
}
