import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IconNames } from 'src/app/_components/icons/icon-names';
import { MustMatch } from 'src/app/_helpers/must-match.validator';
import { passwordRegex } from 'src/app/_helpers/utils';
import { ErrorObject } from 'src/app/_models/error';
import { FormBase } from 'src/app/_models/form-base';
import { TokenStatus } from 'src/app/_models/token-status';
import { User } from 'src/app/_models/user';
import { AuthenticationService } from 'src/app/_services/authentication.service';

// TODO: improve password regex and validation

@Component({
  selector: 'app-add-user-confirmation',
  templateUrl: './add-user-confirmation.component.html',
  styleUrls: ['./add-user-confirmation.component.scss'],
})
export class AddUserConfirmationComponent implements OnInit {
  logo = IconNames.LogoColour;
  createUserError: ErrorObject;
  validationError: ErrorObject;
  token = null;
  tokenStatus = TokenStatus.Validating;
  email: string;
  user: User;
  isProcessing = false;
  accountCreated = false;

  passwordField = new FormBase({
    key: 'password',
    label: 'Password',
    type: 'password',
    placeholder: 'Password',
    disabled: false,
    required: true,
    value: '',
    options: [],
    autocomplete: 'new-password',
  });

  confirmPasswordField = new FormBase({
    key: 'confirmPassword',
    label: 'Confirm Password',
    type: 'password',
    placeholder: 'Re-enter Password',
    disabled: false,
    required: true,
    value: '',
    options: [],
  });

  createUserForm = this.formBuilder.group(
    {
      [this.passwordField.key]: [
        '',
        [
          Validators.required,
          Validators.minLength(8),
          Validators.pattern(passwordRegex),
        ],
      ],
      [this.confirmPasswordField.key]: ['', Validators.required],
    },
    {
      validators: MustMatch(
        this.passwordField.key,
        this.confirmPasswordField.key
      ),
    }
  );

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthenticationService
  ) {}

  ngOnInit(): void {
    this.token = this.route.snapshot.queryParams.invitationToken as string;

    // Remove token from URL to prevent http referrer leak
    void this.router.navigate([], { relativeTo: this.route, replaceUrl: true });

    this.authService
      .validateUserToken(this.token as string)
      .pipe()
      .subscribe({
        next: (response) => {
          this.tokenStatus = TokenStatus.Valid;
          this.user = response;
        },
        error: (error: ErrorObject) => {
          this.tokenStatus = TokenStatus.Invalid;
          this.validationError = error;
        },
      });
  }

  /**
   * @remarks
   * Convenience getter for easy access to form fields
   *
   */
  get f(): FormGroup['controls'] {
    return this.createUserForm.controls;
  }

  onSubmit(): void {
    // If form is already being submitted, exit
    if (this.isProcessing) {
      return;
    }

    this.isProcessing = true;
    const newUser = new User(
      this.user.email,
      this.user.firstName,
      this.user.lastName,
      '',
      this.user.id,
      null,
      this.token as string
    );
    this.authService
      .createAccount(newUser, this.f.password.value as string)
      .pipe()
      .subscribe({
        next: () => {
          this.isProcessing = false;
          this.accountCreated = true;
        },
        error: (error: ErrorObject) => {
          console.log(error);
          this.createUserError = error;
        },
      });
  }
}
