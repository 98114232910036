import { Component, OnInit, inject } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { ErrorObject } from 'src/app/_models/error';
import { FormBase } from 'src/app/_models/form-base';
import { Laboratory } from 'src/app/_models/laboratory';
import { Role } from 'src/app/_models/role';
import { User, UserUpdate } from 'src/app/_models/user';
import {
  UserDeleteResponse,
  UserUpdateResponse,
} from 'src/app/_models/api-responses';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { ModalService } from 'src/app/_services/modal.service';
import { UserService } from 'src/app/_services/user.service';
import { RoleCheckPipe } from '../../_helpers/role-check.pipe';
import { ValidationModalComponent } from '../../_components/modals/validation-modal/validation-modal.component';
import { ModalComponent } from '../../_components/modals/modal.component';
import { ButtonComponent } from '../../_components/buttons/button/button.component';
import { UnderlinedDropdownFormFieldComponent } from '../../_components/forms/underlined-dropdown-form-field/underlined-dropdown-form-field.component';
import { UnderlinedFormFieldComponent } from '../../_components/forms/underlined-form-field/underlined-form-field.component';
import { IconButtonComponent } from '../../_components/buttons/icon-button/icon-button.component';

@Component({
  selector: 'app-update-user',
  templateUrl: './update-user.component.html',
  styleUrls: ['./update-user.component.scss'],
  standalone: true,
  imports: [
    IconButtonComponent,
    FormsModule,
    ReactiveFormsModule,
    UnderlinedFormFieldComponent,
    UnderlinedDropdownFormFieldComponent,
    ButtonComponent,
    ModalComponent,
    ValidationModalComponent,
    RoleCheckPipe,
  ],
})
export class UpdateUserComponent implements OnInit {
  private readonly userService = inject(UserService);
  private readonly formBuilder = inject(FormBuilder);
  private readonly route = inject(ActivatedRoute);
  private readonly authenticationService = inject(AuthenticationService);
  private readonly modalService = inject(ModalService);
  private readonly router = inject(Router);

  // Control vars
  isProcessing = false;
  error: ErrorObject = null;
  user: User;
  userId: string;

  currentUser: User;
  Role = Role;

  availableLabs: Laboratory[] = [];
  labSelectError: string;
  updateUserSuccessMessage: string;

  allowNavigation: Subject<boolean> = new Subject<boolean>();

  firstNameField = new FormBase({
    key: 'firstName',
    label: 'First Name',
    type: 'text',
    placeholder: 'First name',
    disabled: false,
    required: true,
    value: '',
    options: [],
  });

  lastNameField = new FormBase({
    key: 'lastName',
    label: 'Last Name',
    type: 'text',
    placeholder: 'Last Name',
    disabled: false,
    required: true,
    value: '',
    options: [],
  });

  userRoleField = new FormBase({
    key: 'role',
    label: 'Role',
    type: 'select',
    placeholder: 'Select role',
    disabled: false,
    required: true,
    value: '',
    options: [
      { key: Role.Read_Access_User, value: 'Read Access User' },
      { key: Role.Run_Access_User, value: 'Run Access User' },
      { key: Role.Edit_Access_User, value: 'Edit Access User' },
      { key: Role.Lab_Administrator, value: 'Lab Administrator' },
    ],
  });

  availableLabsField = new FormBase({
    key: 'lab',
    label: 'User Laboratories',
    type: 'select',
    placeholder: 'Select lab',
    disabled: false,
    required: false,
    value: '',
    options: [],
  });

  editUserForm = this.formBuilder.group({
    [this.firstNameField.key]: ['', [Validators.required]],
    [this.lastNameField.key]: ['', [Validators.required]],
    [this.userRoleField.key]: ['', [Validators.required]],
    [this.availableLabsField.key]: [],
  });

  ngOnInit(): void {
    // Get current user
    this.currentUser = this.authenticationService.currentUserValue;
    // Now set the available roles based on the current user's role
    this.setAvailableUserRoles(this.currentUser.role);
    // Also set the available labs based on the current user's role
    this.setAvailableLabs(this.currentUser.role);
    // Get user id from route params
    this.route.params.subscribe((params) => {
      this.userId = params.id as string;
      this.getUserDetails(parseInt(this.userId));
    });

    if (this.currentUser.role === Role.Super_User) {
      this.f.firstName.disable();
      this.f.lastName.disable();
    }
  }

  openModal(modalId: string): void {
    this.modalService.open(modalId);
  }

  closeModal(modalId: string): void {
    this.modalService.close(modalId);
  }

  setAvailableUserRoles(role: Role): void {
    if (
      role === Role.OGI_Laboratory_Administrator ||
      role === Role.OGI_Super_Administrator
    ) {
      this.userRoleField.options.push({
        key: Role.OGI_Laboratory_Administrator,
        value: 'OGI Laboratory Administrator',
      });
    }

    if (role === Role.OGI_Super_Administrator) {
      this.userRoleField.options.push({
        key: Role.OGI_Super_Administrator,
        value: 'OGI Super Administrator',
      });
      this.userRoleField.options.push({
        key: Role.Super_User,
        value: 'Super User',
      });
    }
  }

  getUserDetails(userId: number): void {
    this.userService
      .getUserDetails(userId)
      .pipe()
      .subscribe({
        next: (response) => {
          this.user = response;
          this.updateFormValues(this.user);
        },
        error: (error: ErrorObject) => {
          this.error = error;
          this.openModal('error');
        },
      });
  }

  setAvailableLabs(role: Role): void {
    if (role === Role.OGI_Super_Administrator) {
      // If Ogi_Super_Administrator, they can add any lab from the current organisation context
      // Get all labs in the current organisation context
      this.availableLabs =
        this.authenticationService.adminAllLabsListValue || [];
      // TODO: If available labs is null, make a call to the API to get all labs
      // Needs to be filtered by the current organisation context
      this.availableLabsField.options = this.availableLabs?.map((lab) => {
        return { key: lab.laboratoryId, value: lab.laboratoryName };
      });
    } else {
      // If Laboratory_Administrator, Super_User, or Ogi_Laboratory_Administrator, they can only add labs they are associated with
      // Get all labs in the current organisation context
      this.availableLabs = this.currentUser.laboratories || [];
      this.availableLabsField.options = this.availableLabs.map((lab) => {
        return { key: lab.laboratoryId, value: lab.laboratoryName };
      });
    }
    // Now we can subscribe to the form changes
    this.editUserForm.get('lab').valueChanges.subscribe((labId) => {
      // Check if the lab is already in the selected labs
      const lab = this.availableLabs.find(
        (lab) => lab.laboratoryId === parseInt(labId),
      );
      if (
        lab &&
        !this.user.laboratories.some(
          (userLab) => userLab.laboratoryId === lab.laboratoryId,
        )
      ) {
        this.user.laboratories.push(lab);
      }
    });
  }

  removeLab(lab: Laboratory): void {
    // Remove from user.laboratories
    this.user.laboratories = this.user.laboratories.filter(
      (userLab) => userLab.laboratoryId !== lab.laboratoryId,
    );

    this.editUserForm.markAsDirty();
  }

  updateFormValues(user: User): void {
    this.editUserForm.patchValue({
      [this.firstNameField.key]: user.firstName,
      [this.lastNameField.key]: user.lastName,
      [this.userRoleField.key]: user.role,
    });

    this.editUserForm.markAsPristine();
  }

  get f(): FormGroup['controls'] {
    return this.editUserForm.controls;
  }

  onSubmit(): void {
    this.isProcessing = true;
    this.error = null;
    const userUpdate: UserUpdate = {
      id: this.user.id,
      laboratoryIds: this.user.laboratories.map((lab) => lab.laboratoryId),
    };

    if (this.f.firstName.value !== this.user.firstName) {
      userUpdate.firstName = this.f.firstName.value as string;
    }

    if (this.f.lastName.value !== this.user.lastName) {
      userUpdate.lastName = this.f.lastName.value as string;
    }

    if (this.f.role.value !== this.user.role) {
      userUpdate.userRole = this.f.role.value as Role;
    }

    this.userService
      .updateUser(userUpdate)
      .pipe()
      .subscribe({
        next: (data: UserUpdateResponse) => {
          this.isProcessing = false;
          this.error = null;
          this.updateUserSuccessMessage = data.message;
          if (data.user) {
            this.user = data.user;
            this.updateFormValues(this.user);
          }
          this.openModal('success');
        },
        error: (error: ErrorObject) => {
          this.error = error;
          this.openModal('error');
          this.isProcessing = false;
        },
      });
  }

  deleteUser(): void {
    this.error = null;
    // Response may be 204 in the case that an approval is pending
    // If 200, logout and navigate to login
    // If 204, open modal to confirm request is pending
    this.userService.deleteUser(this.user.id).subscribe({
      next: (data: UserDeleteResponse) => {
        this.closeModal('delete-user');
        if (data.logout) {
          this.updateUserSuccessMessage = data.message;
          this.openModal('success');
          return;
        }
        this.updateUserSuccessMessage = data.message;
        this.openModal('success');
      },
      error: (error: ErrorObject) => {
        this.error = error;
        this.closeModal('delete-user');
        this.openModal('error');
      },
    });
  }

  modalButtonClicked(buttonId: string): void {
    switch (buttonId) {
      case 'update-user-button':
        this.closeModal('success');
        break;
      case 'error-button':
        this.closeModal('error');
        break;
      case 'cancel-button':
        this.allowNavigation.next(false);
        this.closeModal('attention');
        break;
      case 'confirm-navigation':
        this.allowNavigation.next(true);
        this.closeModal('attention');
        break;
    }
  }

  canExit(): Promise<boolean> | boolean {
    return this.isNavigationAllowed();
  }

  private isNavigationAllowed(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      if (this.editUserForm.pristine) {
        resolve(true);
      } else {
        this.openModal('attention');
        this.allowNavigation.subscribe((isConfirmed) => resolve(isConfirmed));
      }
    });
  }
}
