import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormBase } from 'src/app/_models/form-base';
import { IconNames } from '../../icons/icon-names';
import { ERROR_MESSAGE } from 'src/app/_helpers/utils';

@Component({
  selector: 'app-graph-checkbox-group',
  templateUrl: './graph-checkbox-group.component.html',
  styleUrls: ['./graph-checkbox-group.component.scss'],
})
export class GraphCheckboxGroupComponent {
  title: 'Checkbox';
  component: '';
  tickIconName = IconNames.Tick;

  /**
   * @param {FormBase<string>} field Checkbox base field
   * @requires
   */
  @Input()
  field: FormBase<string>;

  /**
   * @param {FormGroup} parentForm Parent form group name
   * @requires
   */
  @Input()
  parentForm: FormGroup = new FormGroup({});

  /**
   * @param {string} formArrayGroupName Array group name
   * @requires
   */
  @Input()
  formArrayGroupName: string;

  /**
   * @param {string[]} checkboxTitles Array group name
   * @requires
   */
  @Input()
  checkboxTitles: string;

  /**
   * @param {IconNames} iconName Tick icon for checked state
   */
  @Input()
  iconName: IconNames.Tick;

  /**
   * @param {boolean} isDisabled Set whether checkboxes are disabled
   */
  @Input()
  isDisabled = false;

  @Output()
  checkboxClicked: EventEmitter<any> = new EventEmitter();

  updateValue(event: Event): void {
    this.checkboxClicked.emit(event);
  }

  initialiseCheckboxes(index: number): boolean {
    const controlAccessor = this.formArrayGroupName;
    const arrayKey = 'controls';
    if (
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      this.parentForm?.controls[controlAccessor][arrayKey][index]?.value ===
      true
    ) {
      return true;
    }
    return null;
  }

  public get hasErrors(): boolean {
    return (
      this.parentForm.controls[this.field.key] &&
      this.parentForm.controls[this.field.key]?.errors &&
      this.parentForm.controls[this.field.key]?.touched
    );
  }

  public get errorsList(): string[] {
    return Object.keys(this.parentForm.controls[this.field.key].errors).map(
      (err) =>
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        ERROR_MESSAGE[err](
          this.parentForm.controls[this.field.key].getError(err)
        ) as string
    );
  }

  public get classes(): string[] {
    const hasDisabledClass = this.isDisabled ? 'disabled' : '';
    return ['checkbox--label', 'body1', hasDisabledClass];
  }
}
