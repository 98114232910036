<div id="page">
  <div id="container">
    <app-svg-template
      [iconName]="logo"
      width="249"
      height="60"
      customViewBox="0 0 249 60"
    ></app-svg-template>
    <h5>Login to your account</h5>
    <span class="body2 error">
      <span *ngIf="loginError.message">Error: </span>{{ loginError.message }}
    </span>
    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
      <app-filled-form-field
        iconName="email"
        [field]="emailField"
        [parentForm]="loginForm"
      >
      </app-filled-form-field>
      <div>
        <app-filled-form-field
          iconName="lock"
          [field]="passwordField"
          [parentForm]="loginForm"
        ></app-filled-form-field>
        <div class="options__container">
          <app-checkbox
            [checkbox]="rememberMe"
            [parentForm]="loginForm"
          ></app-checkbox>
          <a [routerLink]="'/forgot-password'">Forgot password?</a>
        </div>
      </div>
      <app-button
        buttonType="submit"
        buttonStyle="standard"
        [label]="'Login'"
        buttonId="login"
        hasFill="true"
        (buttonClicked)="onSubmit()"
        [isDisabled]="loginForm.invalid"
        [isProcessing]="isProcessing"
      >
      </app-button>
    </form>
  </div>
</div>
