import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormBase } from 'src/app/_models/form-base';

@Component({
  selector: 'app-search-field',
  templateUrl: './search-field.component.html',
  styleUrl: './search-field.component.scss',
})
export class SearchFieldComponent {
  /**
   * Form base
   * @requires
   */
  @Input()
  field: FormBase<string>;

  /**
   * The form group from the parent form component
   * @requires
   */
  @Input()
  parentForm: FormGroup = new FormGroup({});

  /**
   * Output click event
   */
  @Output()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  buttonClicked: EventEmitter<any> = new EventEmitter();

  clickButton = (): void => {
    this.buttonClicked.emit();
  };
}
