import {
  Component,
  EventEmitter,
  Input,
  Output,
  ChangeDetectionStrategy,
} from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ERROR_MESSAGE } from 'src/app/_helpers/utils';
import { FormBase } from 'src/app/_models/form-base';
import { ActiveModulesComponent } from '../../chips/active-modules/active-modules.component';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'app-checkbox-group',
  templateUrl: './checkbox-group.component.html',
  styleUrls: ['./checkbox-group.component.scss'],
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, MatIcon, ActiveModulesComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckboxGroupComponent {
  title: 'Checkbox';
  component: '';

  /**
   * @param {FormBase<string>} field Checkbox base field
   * @requires
   */
  @Input()
  field: FormBase<string>;

  /**
   * @param {FormGroup} parentForm Parent form group name
   * @requires
   */
  @Input()
  parentForm: FormGroup = new FormGroup({});

  /**
   * @param {boolean} isDisabled Set whether checkboxes are disabled
   */
  @Input()
  isDisabled = false;

  /**
   * @param {boolean} style Set checkbox style
   **/
  @Input()
  checkboxStyle: 'default' | 'tabs' | 'tabs-small' | 'list' = 'default';

  /**
   * @param {boolean} showModuleIcon Set whether to show module icon
   */
  @Input()
  showModuleIcon: string;

  @Output()
  checkboxClicked: EventEmitter<Event> = new EventEmitter<Event>();

  updateValue(event: Event): void {
    this.checkboxClicked.emit(event);
  }

  initialiseCheckboxes(index: number): boolean {
    const formArray = this.parentForm.get(this.field.key);
    if (!formArray) {
      return false;
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return !!formArray.value[index];
  }

  public get hasErrors(): boolean {
    return (
      this.parentForm.controls[this.field.key] &&
      this.parentForm.controls[this.field.key]?.errors &&
      this.parentForm.controls[this.field.key]?.touched
    );
  }

  public get errorsList(): string[] {
    return Object.keys(this.parentForm.controls[this.field.key].errors).map(
      (err) =>
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        ERROR_MESSAGE[err](
          this.parentForm.controls[this.field.key].getError(err),
        ) as string,
    );
  }
}
