import { Injectable } from '@angular/core';
import { ModalComponent } from '../_components/modals/modal.component';
import { ValidationModalComponent } from '../_components/modals/validation-modal/validation-modal.component';
import { ProvideInfoModalComponent } from '../_components/modals/provide-info-modal/provide-info-modal.component';
import { FilterModalComponent } from '../_components/modals/filter-modal/filter-modal.component';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  private modals: (
    | ModalComponent
    | ValidationModalComponent
    | ProvideInfoModalComponent
    | FilterModalComponent
  )[] = [];

  add(
    modal:
      | ModalComponent
      | ValidationModalComponent
      | ProvideInfoModalComponent
      | FilterModalComponent,
  ): void {
    // Add modal to array of active modals
    this.modals.push(modal);
  }

  remove(id: string): void {
    this.modals = this.modals.filter((x) => x.id !== id);
  }

  open(id: string): void {
    const modal = this.modals.find((x) => x.id === id);
    modal.open();
  }

  close(id: string): void {
    const modal = this.modals.find((x) => x.id === id);
    modal.close();
  }
}
