import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormBase } from 'src/app/_models/form-base';
import { Router } from '@angular/router';
import { ErrorObject } from 'src/app/_models/error';
import { CalibrationService } from 'src/app/_services/calibration.service';
import { DeviceService } from 'src/app/_services/device.service';
import { Calibration, CalibrationSlot } from 'src/app/_models/calibration';
import { OxygenCalibration } from 'src/app/_models/oxygen-calibration';
import { Device } from 'src/app/_models/device';
import { DeviceResponse } from 'src/app/_models/api-responses';
import { Module } from 'src/app/_models/module-enum';
import { PhCalibration } from 'src/app/_models/ph-calibration';
import { PhBuffer } from 'src/app/_models/ph-buffer';
import { DeviceSetupStatus } from 'src/app/_models/device-setup-status';
import { advancedNameRegex } from 'src/app/_helpers/utils';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { Laboratory } from 'src/app/_models/laboratory';

// TODO
// 2. Add warning if user selects a slot that already has a calibration

@Component({
  selector: 'app-create-calibration',
  templateUrl: './create-calibration.component.html',
  styleUrls: ['./create-calibration.component.scss'],
})
export class CreateCalibrationComponent implements OnInit {
  // Permissions
  laboratory: Laboratory;

  // Control vars
  error: ErrorObject = null;
  loading = false;
  devicesLoading = false;
  saveLocationsLoading = false;
  isProcessing = false;
  noSlotsWarning = false;
  // temperature vars - used for OD and Oxygen calibrations
  minTemperatureOD = 20;
  maxTemperatureOD = 50;
  defaultTempValue = 37;
  temperatureStepOD = 0.1;
  // pH calibration vars
  controlTemperature = false;
  minTemperature = 15;
  maxTemperature = 50;
  tempStep = 0.1;
  buffersLoading = false;

  ModuleEnum = Module;

  // Create new OD calibration
  newODCalibration: Calibration;

  // Create new Oxygen calibration
  newOxygenCalibration: OxygenCalibration;

  // Create new pH calibration
  newPHCalibration: PhCalibration;

  /**
   * @param {boolean} calibrationModuleType Type of calibration module
   */
  calibrationModuleType = Module.BaseModule;

  /**
   * @param {Device[]} deviceList A list of devices that can be selected for new calibration
   */
  deviceList: Device[] = [];

  /**
   * @param {{key: string, value: string}[]} slotList A list of save locations that can be selected for new calibration
   */
  slotList: { key: string; value: string }[] = [];

  /**
   * @param {PhBuffer[]} pHBufferList A list of pH buffers loaded on the device
   */
  pHBufferList: PhBuffer[] = null;

  /**
   * @param {PhBuffer[]} selectedBufferList A list of pH buffers selected for the calibration
   */
  selectedBufferList: PhBuffer[] = [];

  // Shared params for all calibration types
  calibrationTypeField = new FormBase<string>({
    key: 'calibrationTypeCheckboxes',
    label: 'Select calibration type',
    type: 'radio',
    placeholder: '',
    disabled: false,
    required: false,
    value: 'Optical Density',
    options: [
      { key: Module.BaseModule, value: 'Optical Density' },
      { key: Module.Oxygen, value: 'Oxygen' },
      { key: Module.pH, value: 'pH' },
    ],
  });

  deviceField = new FormBase({
    key: 'device',
    label: 'Select Device',
    type: 'dropdown',
    placeholder: '',
    disabled: false,
    required: true,
    value: null,
    options: [],
  });

  calibrationNameField = new FormBase({
    key: 'calibrationName',
    label: 'Calibration Name',
    type: 'text',
    placeholder: 'Calibration Name',
    disabled: false,
    required: true,
    value: null,
    options: [],
  });

  saveLocationField = new FormBase({
    key: 'saveLocation',
    label: 'Select Save Location',
    type: 'dropdown',
    placeholder: '',
    disabled: false,
    required: true,
    value: null,
    options: [],
  });

  // Optical Density calibration params
  temperatureControlAField = new FormBase<boolean>({
    key: 'temperatureControlA',
    label: 'Temperature Control A',
    type: 'toggle',
    placeholder: '',
    disabled: false,
    required: false,
    value: false,
    options: [],
  });

  temperatureControlBField = new FormBase<boolean>({
    key: 'temperatureControlB',
    label: 'Temperature Control B',
    type: 'toggle',
    placeholder: '',
    disabled: false,
    required: false,
    value: false,
    options: [],
  });

  temperatureControlCField = new FormBase<boolean>({
    key: 'temperatureControlC',
    label: 'Temperature Control C',
    type: 'toggle',
    placeholder: '',
    disabled: false,
    required: false,
    value: false,
    options: [],
  });

  temperatureControlDField = new FormBase<boolean>({
    key: 'temperatureControlD',
    label: 'Temperature Control D',
    type: 'toggle',
    placeholder: '',
    disabled: false,
    required: false,
    value: false,
    options: [],
  });

  inputTemperatureAField = new FormBase<number>({
    key: 'inputTemperatureA',
    label: 'Temperature A (°C)',
    type: 'number',
    placeholder: 'Temperature A (°C)',
    disabled: true,
    required: false,
    value: null,
    options: [],
  });

  inputTemperatureBField = new FormBase<number>({
    key: 'inputTemperatureB',
    label: 'Temperature B (°C)',
    type: 'number',
    placeholder: 'Temperature B (°C)',
    disabled: true,
    required: false,
    value: null,
    options: [],
  });

  inputTemperatureCField = new FormBase<number>({
    key: 'inputTemperatureC',
    label: 'Temperature C (°C)',
    type: 'number',
    placeholder: 'Temperature C (°C)',
    disabled: true,
    required: false,
    value: null,
    options: [],
  });

  inputTemperatureDField = new FormBase<number>({
    key: 'inputTemperatureD',
    label: 'Temperature D (°C)',
    type: 'number',
    placeholder: 'Temperature D (°C)',
    disabled: true,
    required: false,
    value: null,
    options: [],
  });

  // pH calibration params
  temperatureField = new FormBase<number>({
    key: 'temperature',
    label: 'Temperature (°C)',
    type: 'number',
    placeholder: 'Temperature (°C)',
    disabled: false,
    required: false,
    value: null,
    options: [],
  });

  // TODO:
  // 2. Limit list selection to max 5, min 2 buffers
  pHBufferField = new FormBase({
    key: 'pHBuffer',
    label: 'Select pH Buffer',
    type: 'dropdown',
    placeholder: '',
    disabled: false,
    required: false,
    value: null,
    options: [],
  });

  calibrationForm = this.formBuilder.group({
    [this.calibrationTypeField.key]: [Module.BaseModule, []],
    [this.deviceField.key]: [
      this.deviceField.value,
      [Validators.minLength(1), Validators.maxLength(100), Validators.required],
    ],
    [this.calibrationNameField.key]: [
      this.calibrationNameField.value,
      [
        Validators.minLength(2),
        Validators.maxLength(10),
        Validators.required,
        Validators.pattern(advancedNameRegex),
      ],
    ],
    [this.saveLocationField.key]: [
      {
        value: null,
        disabled: true,
      },
      [Validators.minLength(1), Validators.maxLength(100), Validators.required],
    ],
  });

  temperatureValuesForm = this.formBuilder.group({
    [this.temperatureControlAField.key]: [false, []],
    [this.temperatureControlBField.key]: [false, []],
    [this.temperatureControlCField.key]: [false, []],
    [this.temperatureControlDField.key]: [false, []],
    [this.inputTemperatureAField.key]: [
      {
        value: this.inputTemperatureAField.value,
        disabled: this.inputTemperatureAField.disabled,
      },
      [
        Validators.min(this.minTemperatureOD),
        Validators.max(this.maxTemperatureOD),
        Validators.required,
      ],
    ],
    [this.inputTemperatureBField.key]: [
      {
        value: this.inputTemperatureBField.value,
        disabled: this.inputTemperatureBField.disabled,
      },
      [
        Validators.min(this.minTemperatureOD),
        Validators.max(this.maxTemperatureOD),
        Validators.required,
      ],
    ],
    [this.inputTemperatureCField.key]: [
      {
        value: this.inputTemperatureCField.value,
        disabled: this.inputTemperatureCField.disabled,
      },
      [
        Validators.min(this.minTemperatureOD),
        Validators.max(this.maxTemperatureOD),
        Validators.required,
      ],
    ],
    [this.inputTemperatureDField.key]: [
      {
        value: this.inputTemperatureDField.value,
        disabled: this.inputTemperatureDField.disabled,
      },
      [
        Validators.min(this.minTemperatureOD),
        Validators.max(this.maxTemperatureOD),
        Validators.required,
      ],
    ],
  });

  pHForm = this.formBuilder.group({
    [this.temperatureField.key]: [
      this.temperatureField.value,
      [
        Validators.minLength(0),
        Validators.maxLength(3),
        Validators.min(this.minTemperature),
        Validators.max(this.maxTemperature),
        Validators.required,
      ],
    ],
    [this.pHBufferField.key]: ['', [Validators.required]],
  });

  constructor(
    protected readonly formBuilder: FormBuilder,
    protected readonly calibrationService: CalibrationService,
    protected readonly deviceService: DeviceService,
    private readonly authenticationService: AuthenticationService,
    protected readonly router: Router
  ) {}

  ngOnInit(): void {
    this.laboratory = this.authenticationService.selectedLaboratory;

    if (this.laboratory) {
      const cacheCalibrationData =
        this.calibrationService.createCalibrationFormCache;
      if (cacheCalibrationData) {
        this.updateFormValues(cacheCalibrationData);
      }

      // API call to get optical density devices by default when page loads
      this.getDevicesFromApi();

      // Set up form subscriptions
      this.setUpFormSubscriptions();
    }
  }

  setUpFormSubscriptions(): void {
    // API calls to get save locations when the calibration type changes
    this.calibrationForm
      .get(this.calibrationTypeField.key)
      .valueChanges.subscribe((value) => {
        // Reset all dropdowns when user changes calibration type
        this.f[this.deviceField.key].reset();
        this.f[this.saveLocationField.key].reset();
        // Also reset memory slot list to empty
        this.slotList = [];
        switch (value) {
          case Module.BaseModule:
            this.calibrationModuleType = Module.BaseModule;
            this.calibrationForm.controls[
              this.calibrationNameField.key
            ].validator = Validators.compose([
              Validators.minLength(2),
              Validators.maxLength(10),
              Validators.required,
              Validators.pattern(advancedNameRegex),
            ]);
            this.getDevicesFromApi();
            break;
          case Module.Oxygen:
            this.calibrationModuleType = Module.Oxygen;
            this.calibrationForm.controls[
              this.calibrationNameField.key
            ].validator = Validators.compose([
              Validators.minLength(2),
              Validators.maxLength(10),
              Validators.required,
              Validators.pattern(advancedNameRegex),
            ]);
            this.getDevicesFromApi();
            break;
          case Module.pH:
            this.calibrationModuleType = Module.pH;
            this.calibrationForm.controls[
              this.calibrationNameField.key
            ].validator = Validators.compose([
              Validators.minLength(2),
              Validators.maxLength(16),
              Validators.required,
              Validators.pattern(advancedNameRegex),
            ]);
            this.getDevicesFromApi();
            break;
        }
      });

    // Fetch list of save locations when user selects device
    this.calibrationForm
      .get(this.deviceField.key)
      .valueChanges.subscribe((value) => {
        if (value) {
          this.getSaveLocations(value as number);
        }
      });

    /// Subscribe to temperature control changes for OD and Oxygen calibrations
    this.temperatureValuesForm
      .get(this.temperatureControlAField.key)
      .valueChanges.subscribe(() => {
        // If temperature control has been set to false, disable the input field
        if (!this.tempF.temperatureControlA.value) {
          this.tempF.inputTemperatureA.reset();
          this.tempF.inputTemperatureA.disable();
        } else {
          this.tempF.inputTemperatureA.enable();
          this.tempF.inputTemperatureA.setValue(this.defaultTempValue);
        }
      });

    this.temperatureValuesForm
      .get(this.temperatureControlBField.key)
      .valueChanges.subscribe(() => {
        if (!this.tempF.temperatureControlB.value) {
          this.tempF.inputTemperatureB.reset();
          this.tempF.inputTemperatureB.disable();
        } else {
          this.tempF.inputTemperatureB.enable();
          this.tempF.inputTemperatureB.setValue(this.defaultTempValue);
        }
      });

    this.temperatureValuesForm
      .get(this.temperatureControlCField.key)
      .valueChanges.subscribe(() => {
        if (!this.tempF.temperatureControlC.value) {
          this.tempF.inputTemperatureC.reset();
          this.tempF.inputTemperatureC.disable();
        } else {
          this.tempF.inputTemperatureC.enable();
          this.tempF.inputTemperatureC.setValue(this.defaultTempValue);
        }
      });

    this.temperatureValuesForm
      .get(this.temperatureControlDField.key)
      .valueChanges.subscribe(() => {
        if (!this.tempF.temperatureControlD.value) {
          this.tempF.inputTemperatureD.reset();
          this.tempF.inputTemperatureD.disable();
        } else {
          this.tempF.inputTemperatureD.enable();
          this.tempF.inputTemperatureD.setValue(this.defaultTempValue);
        }
      });

    this.pHForm
      .get(this.pHBufferField.key)
      .valueChanges.subscribe((value: number) => {
        if (value) {
          // Find the buffer object in the pH buffer list
          const selectedBuffer = this.pHBufferList.find(
            (buffer) => buffer.id === value
          );
          if (!this.selectedBufferList.includes(selectedBuffer)) {
            this.selectedBufferList.push(selectedBuffer);
          }
        }
      });
  }

  updateFormValues(
    cacheCalibrationData: Calibration | OxygenCalibration | PhCalibration
  ): void {
    // Patch the values common to all calibrations
    this.calibrationForm.patchValue({
      // device id
      calibrationName: cacheCalibrationData.name,
      saveLocation: cacheCalibrationData.saveLocationName,
    });
    this.calibrationForm.markAsDirty();

    // TODO: check the calibration type, cast the cacheCalibrationData to the correct type
    // and then patch the values specific to that calibration type
  }

  /**
   * @remarks
   * Convenience getter for easy access to form fields
   *
   */
  get f(): FormGroup['controls'] {
    return this.calibrationForm.controls;
  }

  get pHf(): FormGroup['controls'] {
    return this.pHForm.controls;
  }

  /**
   * @remarks
   * Convenience getter for easy access to temperature values form fields
   */
  get tempF(): FormGroup['controls'] {
    return this.temperatureValuesForm.controls;
  }

  resetForm(): void {
    this.calibrationForm.reset();
    this.calibrationService.onUpdateCreateCalibrationFormCache(null);
  }

  onSubmitCreateCalibrationForm(): void {
    this.isProcessing = true;
    this.error = null;
    const deviceId = this.f.device.value as number;

    switch (this.calibrationModuleType) {
      case Module.BaseModule:
        this.newODCalibration = new Calibration(
          deviceId,
          this.f.calibrationName.value as string,
          this.f.saveLocation.value as string,
          this.temperatureValuesForm.value.temperatureControlA as boolean,
          this.temperatureValuesForm.value.temperatureControlB as boolean,
          this.temperatureValuesForm.value.temperatureControlC as boolean,
          this.temperatureValuesForm.value.temperatureControlD as boolean
        );

        // Set conditional temperature values
        if (this.tempF.temperatureControlA.value) {
          this.newODCalibration.inputTemperatureA = this.tempF.inputTemperatureA
            .value as number;
        }

        if (this.tempF.temperatureControlB.value) {
          this.newODCalibration.inputTemperatureB = this.tempF.inputTemperatureB
            .value as number;
        }

        if (this.tempF.temperatureControlC.value) {
          this.newODCalibration.inputTemperatureC = this.tempF.inputTemperatureC
            .value as number;
        }

        if (this.tempF.temperatureControlD.value) {
          this.newODCalibration.inputTemperatureD = this.tempF.inputTemperatureD
            .value as number;
        }

        this.calibrationService
          .createODCalibration(this.newODCalibration)
          .subscribe({
            next: (response) => {
              this.isProcessing = false;
              this.calibrationService.onUpdateCreateCalibrationFormCache(null);
              void this.router.navigate([
                `calibration/optical-density/${response.id}/calibration-data`,
              ]);
            },
            error: (error: ErrorObject) => {
              this.isProcessing = false;
              this.error = error;
            },
          });
        break;
      case Module.Oxygen:
        this.newOxygenCalibration = new OxygenCalibration(
          deviceId,
          this.f.calibrationName.value as string,
          this.f.saveLocation.value as string,
          this.tempF.temperatureControlA.value as boolean,
          this.tempF.temperatureControlB.value as boolean,
          this.tempF.temperatureControlC.value as boolean,
          this.tempF.temperatureControlD.value as boolean
        );

        // Set conditional temperature values
        if (this.tempF.temperatureControlA.value) {
          this.newOxygenCalibration.inputTemperatureA = this.tempF
            .inputTemperatureA.value as number;
        }

        if (this.tempF.temperatureControlB.value) {
          this.newOxygenCalibration.inputTemperatureB = this.tempF
            .inputTemperatureB.value as number;
        }

        if (this.tempF.temperatureControlC.value) {
          this.newOxygenCalibration.inputTemperatureC = this.tempF
            .inputTemperatureC.value as number;
        }

        if (this.tempF.temperatureControlD.value) {
          this.newOxygenCalibration.inputTemperatureD = this.tempF
            .inputTemperatureD.value as number;
        }

        this.calibrationService
          .createOxygenCalibration(this.newOxygenCalibration)
          .subscribe({
            next: (response) => {
              this.isProcessing = false;
              this.calibrationService.onUpdateCreateCalibrationFormCache(null);
              void this.router.navigate([
                `calibration/oxygen/${response.id}/calibration-data`,
              ]);
            },
            error: (error: ErrorObject) => {
              this.isProcessing = false;
              this.error = error;
            },
          });
        break;
      case Module.pH:
        const bufferIds = [];
        this.selectedBufferList.forEach((buffer) => {
          bufferIds.push(buffer.id);
        });
        this.newPHCalibration = new PhCalibration(
          deviceId,
          this.f.calibrationName.value as string,
          this.f.saveLocation.value as string,
          false, // Control has been hidden and is always false per Ogi request
          this.pHf.temperature.value as number,
          bufferIds as PhBuffer[]
        );
        this.calibrationService
          .createPHCalibration(this.newPHCalibration)
          .subscribe({
            next: (response) => {
              this.isProcessing = false;
              this.calibrationService.onUpdateCreateCalibrationFormCache(null);
              void this.router.navigate([
                `calibration/ph/${response.id}/calibration-data`,
              ]);
            },
            error: (error: ErrorObject) => {
              this.isProcessing = false;
              this.error = error;
            },
          });
        break;
    }
  }

  isButtonDisabled(): boolean {
    // Oxygen calibration
    if (
      this.f.calibrationTypeCheckboxes.value === Module.Oxygen &&
      !this.calibrationForm.invalid
    ) {
      return false;
    }

    // Optical Density
    if (!this.calibrationForm.invalid && !this.temperatureValuesForm.invalid) {
      return false;
    }

    // pH calibration
    if (
      this.f.calibrationTypeCheckboxes.value === Module.pH &&
      !this.calibrationForm.invalid &&
      !this.pHForm.invalid &&
      this.selectedBufferList.length > 1 &&
      this.selectedBufferList.length < 6
    ) {
      return false;
    }

    return true;
  }

  getDevicesFromApi(): void {
    this.deviceField.options = [];
    this.devicesLoading = true;
    this.error = null;
    this.deviceService
      .getDevices(
        'Name',
        'desc',
        null,
        null,
        [this.calibrationModuleType],
        DeviceSetupStatus.Inactive, // Only return devices that are inactive
        false // Do not return devices that are not online
      )
      .subscribe({
        next: (response: DeviceResponse) => {
          this.deviceList = response.deviceList;
          response.deviceList.forEach((device: Device) => {
            this.deviceField.options.push({
              key: device.id,
              value: device.name,
            });
          });
          this.devicesLoading = false;
        },
        error: (error: ErrorObject) => {
          this.devicesLoading = false;
          this.error = error;
        },
      });
  }

  getSaveLocations(deviceId: number): void {
    this.saveLocationsLoading = true;
    // Disable dropdowns and clear previous values before fetching save slot locations again
    this.f.saveLocation.disable();
    this.slotList = [];
    this.saveLocationField.options = [];
    this.error = null;
    this.calibrationService
      .getSlotSaveLocations(deviceId, this.calibrationModuleType)
      .subscribe({
        next: (response: CalibrationSlot[]) => {
          if (response.length > 0) {
            response.forEach((slot: CalibrationSlot) => {
              this.slotList.push({
                key: slot.slotName,
                value: slot.displayName,
              });
            });
            this.saveLocationField.options = this.slotList;
            this.f.saveLocation.enable();
            this.saveLocationsLoading = false;
          } else {
            // If there are no calibrations set list to null not empty array
            // so the loading spinner/no calibrations message renders correctly
            this.slotList = null;
          }
          this.saveLocationsLoading = false;
          // If the ph module is selected, also fetch the list of pH buffers
          if (this.calibrationModuleType === Module.pH) {
            this.getPHBuffers(deviceId);
          }
        },
        error: (error: ErrorObject) => {
          this.saveLocationsLoading = false;
          this.error = error;
        },
      });
  }

  getPHBuffers(deviceId: number): void {
    this.buffersLoading = true;
    this.error = null;
    this.pHBufferField.options = [];
    this.deviceService.getBuffersByDeviceId(deviceId).subscribe({
      next: (response: PhBuffer[]) => {
        this.pHBufferList = response;
        response.forEach((buffer: PhBuffer) => {
          this.pHBufferField.options.push({
            key: buffer.id,
            value: buffer.name,
          });
        });
        // if selected buffer list has buffers not included in the pH buffer list
        // then remove them from the selected buffer list
        this.selectedBufferList.forEach((selectedBuffer: PhBuffer) => {
          if (!this.pHBufferList.some((buffer) => buffer === selectedBuffer)) {
            this.selectedBufferList.splice(
              this.selectedBufferList.indexOf(selectedBuffer),
              1
            );
          }
        });
        this.buffersLoading = false;
      },
      error: (error: ErrorObject) => {
        this.buffersLoading = false;
        this.error = error;
      },
    });
  }

  removeBuffer(buffer: PhBuffer): void {
    this.selectedBufferList.splice(this.selectedBufferList.indexOf(buffer), 1);
  }
}
