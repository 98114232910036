/* eslint-disable brace-style */
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

export interface CanComponentDeactivate {
  canExit: () => Observable<boolean> | Promise<boolean> | boolean;
}

// ET - TODO: possible fix for double activation on routerLinks:
// If anybody needs workaround follow this steps:

// Use rxjs share operator to reuse created observables.
// Create map in your guard RouterStateSnapshot.url->observable<boolean> and save created observables to this map.
// Add cleanup code to remove url from the map when observable completes (operators finally or do).

@Injectable({
  providedIn: 'root',
})
export class CanDeactivateGuard {
  canDeactivate(
    component: CanComponentDeactivate
  ): Observable<boolean> | Promise<boolean> | boolean {
    return component.canExit && component.canExit();
  }
}
