@if (
  (currentUser.role !== Role.Super_User && laboratory) ||
  (currentUser.role === Role.Super_User && laboratory?.isOwnLab)
) {
  <div id="dashboard--container">
    <div id="header--container">
      <h4>Welcome to your dashboard.</h4>
      <h5>{{ laboratory?.laboratoryName }}</h5>
    </div>
    <hr class="alt" />
    <div id="nav--container">
      <div class="button--container">
        <app-option-tab-toggle
          id="toggle--container"
          radioGroup="navigationToggle"
          [selectOptions]="navOptions"
          type="round"
        >
        </app-option-tab-toggle>
        <button
          mat-mini-fab
          aria-label="reset search form"
          (click)="getDevices()"
          title="Refresh"
          class="refresh-btn"
          color="primary"
        >
          <mat-icon>restart_alt</mat-icon>
        </button>
      </div>
      <div class="button--container">
        <app-search-field
          [field]="searchField"
          [parentForm]="deviceTableControls"
          (buttonClicked)="handleSearch($event)"
        >
        </app-search-field>
        <app-icon-button
          label="filters"
          buttonStyle="secondary"
          buttonId="filter-btn"
          iconName="filter_list"
          buttonShape="square"
          (buttonClicked)="openModal('filter')"
          title="Filters"
        ></app-icon-button>
        <button
          mat-mini-fab
          aria-label="new experiment"
          (click)="onNewExperiment()"
          title="New experiment"
          [disabled]="
            currentUser.role
              | roleCheck: [Role.Read_Access_User, Role.Run_Access_User]
          "
        >
          <mat-icon>science</mat-icon>
        </button>
        <button
          mat-mini-fab
          aria-label="new calibration"
          (click)="onNewCalibration()"
          title="New calibration"
          [disabled]="
            selectedDeviceList?.length > 1 || currentUser.role
              | roleCheck: [Role.Read_Access_User, Role.Run_Access_User]
          "
        >
          <mat-icon>timeline</mat-icon>
        </button>
      </div>
    </div>
    @if (devices?.length > 0) {
      <div id="inner-page--container">
        <p id="table-description" style="display: none">Device summary table</p>
        <div id="table--container">
          <table aria-describedby="table-description">
            <thead>
              <tr>
                <th
                  id="device"
                  scope="col"
                  (click)="onSort('Name')"
                  [class.active]="currentSort.sortBy === 'Name'"
                >
                  <div id="table-header">
                    <span>Device</span>
                    <mat-icon
                      aria-label="sort direction"
                      matTooltip="Sort direction"
                      >swap_vert</mat-icon
                    >
                  </div>
                </th>
                <th
                  id="status"
                  scope="col"
                  (click)="onSort('DeviceStatusId')"
                  [class.active]="currentSort.sortBy === 'DeviceStatusId'"
                >
                  <div id="table-header">
                    <span>Status</span>
                    <mat-icon
                      aria-label="sort direction"
                      matTooltip="Sort direction"
                      >swap_vert</mat-icon
                    >
                  </div>
                </th>
                <th
                  id="time"
                  scope="col"
                  (click)="onSort('TimeLeft')"
                  [class.active]="currentSort.sortBy === 'TimeLeft'"
                >
                  <div id="table-header">
                    <span>Time Left</span>
                    <mat-icon
                      aria-label="sort direction"
                      matTooltip="Sort direction"
                      >swap_vert</mat-icon
                    >
                  </div>
                </th>
                <th
                  id="experiment"
                  scope="col"
                  (click)="onSort('ExperimentName')"
                  [class.active]="currentSort.sortBy === 'ExperimentName'"
                >
                  <div id="table-header">
                    <span>Experiment</span>
                    <mat-icon
                      aria-label="sort direction"
                      matTooltip="Sort direction"
                      >swap_vert</mat-icon
                    >
                  </div>
                </th>
                <th id="view" scope="col"></th>
              </tr>
            </thead>
            <tbody>
              @for (device of devices; track device.id) {
                <tr>
                  <td headers="device">
                    <div id="device-name--container">
                      {{ device.name }}
                      @if (!device.isOffline) {
                        <mat-icon
                          class="online"
                          aria-label="online"
                          matTooltip="Online"
                          >wifi</mat-icon
                        >
                      }
                      @if (device.isOffline) {
                        <mat-icon
                          class="offline"
                          aria-label="offline"
                          matTooltip="Offline"
                          >wifi_off</mat-icon
                        >
                      }
                      @if (device?.softwareWarning) {
                        <mat-icon
                          class="warning"
                          aria-label="software update available"
                          matTooltip="Software update available"
                          >update</mat-icon
                        >
                      }
                    </div>
                    <app-active-modules
                      [activeModules]="device.deviceModules"
                    ></app-active-modules>
                  </td>
                  <td headers="status">
                    @if (device) {
                      <app-status-indicator
                        [status]="DeviceSetupStatus[device.deviceStatusId]"
                      ></app-status-indicator>
                    }
                  </td>
                  @if (
                    (device?.deviceStatusId === DeviceSetupStatus.Active ||
                      device?.deviceStatusId === DeviceSetupStatus.Paused) &&
                    (currentTime$ | async)
                  ) {
                    <td headers="time">
                      {{
                        getTimeLeft(
                          device?.experiment?.startTime,
                          device?.experiment?.duration,
                          $any(currentTime$ | async)
                        ).days | number: "2.0"
                      }}:
                      {{
                        getTimeLeft(
                          device.experiment?.startTime,
                          device.experiment?.duration,
                          $any(currentTime$ | async)
                        ).hours | number: "2.0"
                      }}:
                      {{
                        getTimeLeft(
                          device.experiment?.startTime,
                          device.experiment?.duration,
                          $any(currentTime$ | async)
                        ).minutes | number: "2.0"
                      }}:
                      {{
                        getTimeLeft(
                          device.experiment?.startTime,
                          device.experiment?.duration,
                          $any(currentTime$ | async)
                        ).seconds | number: "2.0"
                      }}
                    </td>
                  } @else {
                    <td headers="time">-</td>
                  }
                  <td headers="experiment">
                    {{ device.experiment?.name || "No experiment assigned" }}
                  </td>
                  <td headers="view" id="view-cell">
                    <div>
                      <app-button
                        label="View Device"
                        buttonId="device_{{ device?.id }}"
                        (buttonClicked)="onViewDeviceDetails(device.id)"
                      >
                      </app-button>
                      @if (
                        device?.deviceStatusId === DeviceSetupStatus.Active ||
                        device?.deviceStatusId === DeviceSetupStatus.Paused ||
                        device?.deviceStatusId === DeviceSetupStatus.Complete
                      ) {
                        <app-button
                          label="View Experiment"
                          buttonId="experiment_{{ device?.experiment?.id }}"
                          [routerLink]="
                            '/experiment/' + device?.experiment?.id + '/display'
                          "
                        ></app-button>
                      }
                      @if (
                        device?.deviceStatusId === 9 &&
                        device.odCalibrationId !== null
                      ) {
                        <app-button
                          label="View Active Calibration"
                          buttonId="calibration_{{ device?.odCalibrationId }}"
                          [routerLink]="
                            '/calibration/optical-density/' +
                            device?.odCalibrationId +
                            '/calibration-data'
                          "
                        ></app-button>
                      }
                      @if (
                        device?.deviceStatusId === 12 &&
                        device.oxygenCalibrationId !== (null || undefined)
                      ) {
                        <app-button
                          label="View Active Calibration"
                          buttonId="calibration_{{
                            device?.oxygenCalibrationId
                          }}"
                          [routerLink]="
                            '/calibration/oxygen/' +
                            device?.oxygenCalibrationId +
                            '/calibration-data'
                          "
                        ></app-button>
                      }
                      @if (
                        device?.deviceStatusId === 13 &&
                        device.phCalibrationId !== (null || undefined)
                      ) {
                        <app-button
                          label="View Active Calibration"
                          buttonId="calibration_{{ device?.phCalibrationId }}"
                          [routerLink]="
                            '/calibration/ph/' +
                            device?.phCalibrationId +
                            '/calibration-data'
                          "
                        ></app-button>
                      }
                      @if (
                        device?.experiment?.experimentStatusId ===
                        ExperimentStatus.Draft
                      ) {
                        <app-button
                          label="Start experiment"
                          buttonId="experiment_{{ device?.experiment?.id }}"
                          [routerLink]="
                            '/experiment/' +
                            device?.experiment?.id +
                            '/set-parameters'
                          "
                          [isDisabled]="
                            currentUser.role
                              | roleCheck: [Role.Read_Access_User]
                          "
                        ></app-button>
                      }
                      @if (
                        device?.experiment?.experimentStatusId ===
                        ExperimentStatus.DraftLocked
                      ) {
                        <app-button
                          label="Start experiment"
                          buttonId="experiment_{{ device?.experiment?.id }}"
                          [routerLink]="
                            '/experiment/' +
                            device?.experiment?.id +
                            '/device-setup'
                          "
                          [isDisabled]="
                            currentUser.role
                              | roleCheck: [Role.Read_Access_User]
                          "
                        ></app-button>
                      }
                    </div>
                  </td>
                </tr>
              }
            </tbody>
          </table>
          <div id="pagination--container">
            <div class="filter--container">
              <label for="devices-per-page">Devices per page</label>
              <app-dropdown-form-field
                id="devices-per-page"
                [field]="devicesPerPageField"
                [parentForm]="deviceTableControls"
              >
              </app-dropdown-form-field>
            </div>
            <div id="pagination--controls">
              <app-icon-button
                iconName="chevron_left"
                buttonId="previous"
                (buttonClicked)="getPrevious()"
                [isDisabled]="!hasPrevious"
              ></app-icon-button>
              <span> {{ currentPage }} / {{ totalPages }} </span>
              <app-icon-button
                iconName="chevron_right"
                buttonId="next"
                (buttonClicked)="getNext()"
                [isDisabled]="!hasNext"
              ></app-icon-button>
            </div>
          </div>
        </div>
      </div>
    }
    @if (!loading && (!devices || devices.length === 0)) {
      <div id="cta--container">
        <h4>No devices detected</h4>
        <app-icon-button
          label="reset"
          buttonStyle="secondary"
          buttonId="filter-btn"
          iconName="restart_alt"
          buttonShape="square"
          (buttonClicked)="resetForm()"
        ></app-icon-button>
        @if (error) {
          <p class="error">Error: {{ error.message }}</p>
        }
        @if (!devices) {
          <section id="button--container">
            <app-button
              label="Add New Device"
              buttonId="new-device-btn"
              hasFill="true"
              [routerLink]="'/device/create-device'"
              [isDisabled]="
                currentUser.role
                  | roleCheck
                    : [
                        Role.Read_Access_User,
                        Role.Run_Access_User,
                        Role.Edit_Access_User,
                      ]
              "
            >
            </app-button>
          </section>
        }
      </div>
    }
    @if (loading && !devices) {
      <div id="loading--container">
        <app-loading-spinner></app-loading-spinner>
      </div>
    }
  </div>
}
@if (
  (currentUser.role === Role.Super_User && !laboratory) ||
  (currentUser.role === Role.Super_User && !laboratory?.isOwnLab)
) {
  <div id="create-lab--container">
    <div id="left--container">
      <img src="assets/img/ogi-logo-tagline.png" alt="OGI logo" />
    </div>
    <div id="right--container">
      <h4>Let's get started</h4>
      <div class="step">
        <div class="circle">1</div>
        <p class="subtitle2">Create a laboratory</p>
        <app-mat-icon-button
          buttonStyle="primary"
          iconName="arrow_forward"
          (buttonClicked)="dashboardButtonClicked('add-lab')"
        ></app-mat-icon-button>
      </div>
      <div class="step">
        <div class="circle">2</div>
        <p class="subtitle2">Join the laboratory</p>
        <app-mat-icon-button
          buttonStyle="primary"
          iconName="arrow_forward"
          (buttonClicked)="dashboardButtonClicked('join-lab')"
        ></app-mat-icon-button>
      </div>
      <p class="body top">
        If you would like to add devices, create experiments, create
        calibrations and view experiment/device data you must join the
        laboratory. If you would just like to be able to add users, you can skip
        this step.
      </p>
      <p class="body">
        If you decide you want to join the laboratory at a later date, you can
        add yourself as a member on the 'view lab' page.
      </p>
      <div class="step">
        <div class="circle">3</div>
        <p class="subtitle2">Add users to the laboratory</p>
        <app-mat-icon-button
          buttonStyle="primary"
          iconName="arrow_forward"
          (buttonClicked)="dashboardButtonClicked('add-users')"
        ></app-mat-icon-button>
      </div>
      <!-- Disabled if super user is not a member of the lab -->
      <div [class]="!laboratory?.isOwnLab ? 'step disabled' : 'step'">
        <div class="circle">4</div>
        <p class="subtitle2">Add devices to the laboratory</p>
        <app-mat-icon-button
          buttonStyle="primary"
          iconName="arrow_forward"
          [isDisabled]="!laboratory?.isOwnLab"
          (buttonClicked)="dashboardButtonClicked('add-device')"
        ></app-mat-icon-button>
      </div>
      <p class="body top">
        You can only add devices if you are a member the laboratory.
      </p>
      <p class="help-text">
        You can go back to this page at any time by clicking
        <a href="./help">Help</a> in the menu.
      </p>
    </div>
  </div>
}
<app-filter-modal
  id="filter"
  buttonType="double-button"
  primaryButtonText="Apply Filters"
  primaryButtonId="continue-button"
  secondaryButtonText="Reset"
  secondaryButtonId="reset-button"
  [modulesField]="deviceModuleFilters"
  [statusField]="deviceStatusFilters"
  [offlineField]="deviceOfflineField"
  [deletedField]="includeDeletedDevicesField"
  [parentForm]="deviceTableControls"
  [hideDeletedField]="
    currentUser.role
      | roleCheck
        : [
            Role.Read_Access_User,
            Role.Run_Access_User,
            Role.Edit_Access_User,
            Role.Super_User,
            Role.Lab_Administrator,
            Role.OGI_Laboratory_Administrator,
          ]
  "
  (modalButtonClicked)="modalButtonClicked($event)"
  (moduleCheckboxClicked)="moduleCheckboxClicked($event)"
  [isProcessing]="loading"
></app-filter-modal>
