/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import {
  AfterViewInit,
  Component,
  Input,
  SimpleChanges,
  OnChanges,
  ChangeDetectionStrategy,
} from '@angular/core';
import * as d3 from 'd3';
import * as d3Regression from 'd3-regression';
import { AxisDomain, AxisScale, ScaleLinear, select, style, zoom } from 'd3';
import {
  IPhCalibrationDevice,
  IPhCalibrationFlask,
  IPhReading,
} from 'src/app/_models/experiment-data';
import { PhCalibration } from 'src/app/_models/ph-calibration';
import { fromEvent, debounceTime } from 'rxjs';
import { MatSlideToggle } from '@angular/material/slide-toggle';

@Component({
  selector: 'app-ph-calibration-graph',
  templateUrl: './ph-calibration-graph.component.html',
  styleUrls: ['./ph-calibration-graph.component.scss'],
  standalone: true,
  imports: [MatSlideToggle],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PhCalibrationGraphComponent implements AfterViewInit, OnChanges {
  // colour scale
  private readonly flaskColours: Record<string, string> = {
    A: '#6ECF9C', // Lighter green
    B: '#6C73C1', // Blue - distinct in both normal and colorblind vision
    C: '#BE4FC1', // Purple - different brightness and hue from others
    D: '#CB9F50', // Gold/ochre - very different brightness level
  };

  // Graph state
  private svg: d3.Selection<SVGGElement, unknown, HTMLElement, any>;
  private axisSvg: d3.Selection<SVGSVGElement, unknown, HTMLElement, any>;
  private x: ScaleLinear<number, number>;
  private y: ScaleLinear<number, number>;
  private readonly initialRadius = 4;
  private readonly maxRadius = 10;
  checked = false;

  private readonly margin = {
    left: 90,
    right: 5,
    top: 10,
    bottom: 50,
  };

  // Drawing state
  private isDrawing = false;
  private prevWidth: number | null = null;

  // Set to large by default
  private width: number;
  private height = 600;

  // Data state
  private readonly dataToRender: IRenderData[] = [];
  private readonly labelList: IPropertyColour[] = [];

  /**
   * @param {string} graphId The ID of the graph to be displayed
   * @description This is used to set the ID of the graph container
   * so that it can be referenced by the zoom function
   * @example 'scatter'
   */
  @Input()
  graphId = 'scatter';

  /**
   * @param {string} graphTitle The title of the graph to be displayed
   */
  @Input()
  graphTitle = 'Title Here';

  /**
   * @param {string} yAxisLabel The y-axis label to be used on the graph
   */
  @Input()
  yAxisLabel = 'Y Axis Label';

  /**
   * @param {string} dataType The type of data to be graphed
   */
  @Input()
  dataType = 'pH';

  /**
   * @param {IPhCalibrationDevice} calibrationData The calibration data from the API to be graphed
   */
  @Input()
  calibrationData: IPhCalibrationDevice;

  @Input()
  results: Partial<PhCalibration>;

  ngAfterViewInit(): void {
    this.initialiseGraph();
  }

  ngOnChanges(changes: SimpleChanges): void {
    // Check if calibrationData exists in changes
    if ('calibrationData' in changes) {
      const change = changes.calibrationData;
      // console.log('Detected change in calibrationData:', change);

      // Skip if this is the first change
      if (change.firstChange) {
        // console.log('First change detected, skipping update');
        return;
      }

      // Skip if new value is undefined
      if (!change.currentValue) {
        // console.log('New value is undefined, skipping update');
        return;
      }

      // Since we know there's a change and it has a value, update the graph
      // console.log('Updating graph with new calibration data');
      this.parseData(this.calibrationData, true);
    }
  }

  toggleChange(event: MatSlideToggle): void {
    this.checked = event.checked;
    if (this.checked) {
      this.svg.call(d3.zoom().on('zoom', null));
    } else {
      this.svg.call(
        d3
          .zoom()
          .extent([
            [0, 0],
            [this.width, this.height],
          ])
          .scaleExtent([1, 10])
          .on('zoom', this.updateChart),
      );
    }
  }

  private readonly initialiseGraph = (): void => {
    this.createSvg();

    if (this.calibrationData) {
      this.parseData(this.calibrationData);
    }

    // Set up the resize observer
    const resizeObservable$ = fromEvent(window, 'resize').pipe(
      debounceTime(300), // Adjust delay as needed
    );

    resizeObservable$.subscribe(() => {
      this.updateSvgDimensions();
    });
  };

  private readonly createSvg = (): void => {
    // Set the styling on the container to relative for the absolutely positioned tooltips
    d3.select('figure').style('position', 'relative');
    // Get the width of the parent container
    const containerWidth = (
      d3.select(`figure#${this.graphId}`).node() as HTMLElement
    ).offsetWidth;
    this.width = containerWidth - (this.margin.left + this.margin.right);
    // Create main svg element for the axes
    this.axisSvg = d3
      .select(`figure#${this.graphId}`)
      .append('svg')
      // Set the dimensions and margins of the graph
      .attr('width', this.width + (this.margin.left + this.margin.right))
      .attr('height', this.height + this.margin.top + this.margin.bottom)
      .attr('overflow', 'visible');

    // Create a group for the graph content
    this.svg = this.axisSvg
      .append('g')
      .attr(
        'transform',
        'translate(' +
          this.margin.left.toString() +
          ',' +
          this.margin.top.toString() +
          ')',
      );

    // Add a clip path: everything out of this area won't be drawn.
    this.svg
      .append('defs')
      .append('svg:clipPath')
      .attr('id', 'clip')
      .append('svg:rect')
      .attr('width', this.width)
      .attr('height', this.height)
      .attr('x', 0)
      .attr('y', 0);
    // Rectangle to capture pointer events for zoom
    this.svg
      .append('rect')
      .attr('width', this.width)
      .attr('height', this.height)
      .style('fill', 'none')
      .style('pointer-events', 'all');
    // Now let's add zoom to the svg element
    this.svg.call(
      d3
        .zoom()
        .extent([
          [0, 0],
          [this.width, this.height],
        ])
        .scaleExtent([1, 10])
        .on('zoom', this.updateChart),
    );

    // Append the axes to the main SVG (this.axisSvg)
    this.axisSvg
      .append('g')
      .attr('class', 'x-axis')
      .attr(
        'transform',
        `translate(${this.margin.left}, ${this.height + this.margin.top})`,
      );

    this.axisSvg
      .append('g')
      .attr('class', 'y-axis')
      .attr('transform', `translate(${this.margin.left}, ${this.margin.top})`);
  };

  private readonly parseData = (
    calibrationData: IPhCalibrationDevice,
    isUpdate = false,
  ): void => {
    const property = this.dataType;

    calibrationData.flasks.forEach((flask: IPhCalibrationFlask) => {
      const flaskLetter = flask.name.charAt(0);
      const deviceFlaskProperty = `${flask.name}_${property}`;

      flask.values?.forEach((calibrationValue: IPhReading) => {
        this.dataToRender.push({
          DeviceFlaskProperty: deviceFlaskProperty,
          Reading: calibrationValue.reading,
          pH: calibrationValue.ph,
          FlaskName: flask.name,
        });
      });

      this.labelList.push({
        deviceFlaskProperty,
        colour: this.flaskColours[flaskLetter],
      });
    });

    if (isUpdate) {
      // this.updateGraphWithNewData(this.dataToRender);
    } else {
      this.drawScatterGraph();
    }
  };

  private readonly updateSvgDimensions = (): void => {
    const containerWidth = (
      d3.select(`figure#${this.graphId}`).node() as HTMLElement
    ).offsetWidth;

    const containerHeight = (
      d3.select(`figure#${this.graphId}`).node() as HTMLElement
    ).offsetHeight;

    if (
      (this.prevWidth !== containerWidth || this.height !== containerHeight) &&
      !this.isDrawing
    ) {
      this.isDrawing = true;

      // Recalculate the width and height based on container size
      this.width = containerWidth - (this.margin.left + this.margin.right);
      this.height = containerHeight - (this.margin.top + this.margin.bottom);

      // Update the SVG dimensions
      this.axisSvg
        .attr('width', this.width + (this.margin.left + this.margin.right))
        .attr('height', this.height + (this.margin.top + this.margin.bottom))
        .attr('overflow', 'visible'); // Allow content to overflow the SVG boundaries

      // Preserve the zoom transform by applying it to the dots as well
      const zoomTransform = d3.zoomTransform(this.svg.node());

      // Recreate the scales based on new dimensions
      this.x.range([0, this.width]);
      this.y.range([this.height, 0]);

      // Update the clip path dimensions to match the new graph dimensions
      this.svg
        .select('clipPath rect')
        .attr('width', this.width)
        .attr('height', this.height);

      // Update the axes with the current zoom transformation
      this.axisSvg
        .select<SVGGElement>('.x-axis')
        .attr(
          'transform',
          `translate(${this.margin.left}, ${this.height + this.margin.top})`,
        )
        .call(d3.axisBottom(zoomTransform.rescaleX(this.x)));

      // Reposition the x-axis label to center it based on new width
      this.axisSvg.select('.x-axis-label').attr('x', this.width / 2);

      // Update the y-axis
      this.axisSvg
        .select<SVGGElement>('.y-axis')
        .attr('transform', `translate(${this.margin.left}, ${this.margin.top})`)
        .call(d3.axisLeft(zoomTransform.rescaleY(this.y)));

      // Also reposition the y-axis label
      const vertOffset =
        this.height / 2 - (this.margin.bottom + this.margin.top);
      const horzOffset = this.margin.left - 20;
      this.axisSvg
        .select('.y-axis-label')
        .attr(
          'transform',
          `translate(-${horzOffset}, ${vertOffset}) rotate(-90)`,
        );

      // Reapply zoom transform to the dots as well
      this.svg
        .selectAll('circle')
        .attr('cx', (d: IRenderData) => zoomTransform.rescaleX(this.x)(d.pH))
        .attr('cy', (d: IRenderData) =>
          zoomTransform.rescaleY(this.y)(d.Reading),
        );

      // Update regression lines during resize
      this.updateRegressionLines(zoomTransform);

      // Update legend position
      if (this.axisSvg.select('.legend').size() > 0) {
        const flaskLetters = Object.keys(this.flaskColours);
        const legendItemWidth = 40;
        const legendWidth = legendItemWidth * flaskLetters.length;

        // Position legend below the graph
        const legendX = (this.width - legendWidth) / 2;
        const legendY = this.height + this.margin.top + this.margin.bottom + 15;

        this.axisSvg
          .select('.legend')
          .attr(
            'transform',
            `translate(${legendX + this.margin.left}, ${legendY})`,
          );
      }

      // Save the new width for future resizing
      this.prevWidth = containerWidth;

      this.isDrawing = false;
    }
  };

  private readonly updateRegressionLines = (
    transform?: d3.ZoomTransform | null,
  ): void => {
    // Define the line generator function with transformed scales if transform is provided
    const regLine = d3
      .line()
      .x((d) => (transform ? transform.rescaleX(this.x)(d[0]) : this.x(d[0])))
      .y((d) => (transform ? transform.rescaleY(this.y)(d[1]) : this.y(d[1])));

    // Update each regression line
    const flaskLetters = ['A', 'B', 'C', 'D'];

    flaskLetters.forEach((flaskLetter) => {
      const flaskData = this.dataToRender.filter(
        (element) => element.FlaskName === flaskLetter,
      );

      if (flaskData.length === 0) return;

      const regression = d3Regression
        .regressionLinear()
        .x((d) => d.pH)
        .y((d) => d.Reading)
        .domain(d3.extent(flaskData, (d) => d.pH));

      this.svg
        .select(`#regression-line-${flaskLetter}`)
        .datum(regression(flaskData))
        .attr('d', regLine);
    });
  };

  private readonly updateChart = (
    event: d3.D3ZoomEvent<SVGSVGElement, any>,
  ): void => {
    // Rescale the x and y axes based on the zoom transformation
    const newX = event.transform.rescaleX(this.x);
    const newY = event.transform.rescaleY(this.y);

    // Get the zoom scale factor (k)
    const zoomScale = event.transform.k;

    // Update the axes with the rescaled axes
    this.axisSvg.select<SVGGElement>('.x-axis').call(d3.axisBottom(newX));
    this.axisSvg.select<SVGGElement>('.y-axis').call(d3.axisLeft(newY));

    // Apply the zoom transformation to the circles, including scaling the radius
    this.svg
      .selectAll('circle')
      .attr('cx', (d: IRenderData) => newX(d.pH))
      .attr('cy', (d: IRenderData) => newY(d.Reading))
      .attr('r', () => {
        // Calculate radius with zoom scale but cap at maximum
        const scaledRadius = this.initialRadius * zoomScale;
        return Math.min(
          Math.max(this.initialRadius, scaledRadius),
          this.maxRadius,
        );
      });

    // Update regression lines with the new zoom transform
    this.updateRegressionLines(event.transform);
  };

  private readonly drawScatterGraph = (): void => {
    this.isDrawing = true; // Set the flag to true to prevent resizing

    // Clear existing content of the graph SVG
    this.svg.select('#graph-content').selectAll('*').remove();

    // Create a group for all graph content including regression lines
    const graphContent = this.svg
      .append('g')
      .attr('clip-path', 'url(#clip)')
      .attr('id', 'graph-content');

    // First create x-axis
    this.x = d3
      .scaleLinear()
      .domain(d3.extent(this.dataToRender, (d) => d.pH))
      .range([0, this.width])
      .nice();

    const minY = d3.min(this.dataToRender, (d) => d.Reading);
    const maxY = d3.max(this.dataToRender, (d) => d.Reading);

    // Apply padding only as an absolute adjustment, depending on the sign
    const paddingFactor = 0.1; // 10% padding

    const paddedMinY = minY - Math.abs(minY * paddingFactor);
    const paddedMaxY = maxY + Math.abs(maxY * paddingFactor);

    // Limit decimal places
    const roundedMinY = parseFloat(paddedMinY.toFixed(2));
    const roundedMaxY = parseFloat(paddedMaxY.toFixed(2));

    // Now create the y axis
    this.y = d3
      .scaleLinear()
      .domain([roundedMinY, roundedMaxY])
      .range([this.height, 0])
      .nice();

    // Create the axes (but DON'T append them here)
    const xAxis = d3.axisBottom(this.x);
    const yAxis = d3.axisLeft(this.y);

    const applyAxis = (
      selection: d3.Selection<SVGGElement, unknown, HTMLElement, any>,
      axis: any,
    ) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      selection.call(axis);
    };

    // Add x-axis label
    this.axisSvg.select('.x-axis').selectAll('text').remove(); // Remove existing labels

    // Attach the x-axis to the svg group
    applyAxis(this.axisSvg.select('.x-axis'), xAxis);

    // Add x-axis label
    this.axisSvg
      .select('.x-axis')
      .append('text')
      .attr('class', 'x-axis-label')
      .attr('x', this.width / 2)
      .attr('y', this.margin.bottom - 10)
      .attr('fill', '#000000')
      .attr('text-anchor', 'middle')
      .text('pH')
      .style('font-family', '$proxima-nova')
      .style('font-weight', '300')
      .style('font-size', '2rem');

    // Attach the y-axis to the svg group
    const horzOffset = this.margin.left + 10;
    const vertOffset = this.height / 2 - (this.margin.bottom + this.margin.top);
    applyAxis(this.axisSvg.select('.y-axis'), yAxis);

    // Add y-axis label
    this.axisSvg
      .select('.y-axis')
      .append('text')
      .attr('class', 'y-axis-label')
      .attr('x', -this.margin.left + 10)
      .attr('y', 10)
      .attr('fill', '#000000')
      .attr('text-anchor', 'middle')
      .attr('transform', `translate(-${horzOffset}, ${vertOffset}) rotate(-90)`)
      .style('font-family', '$proxima-nova')
      .style('font-weight', '300')
      .style('font-size', '2rem')
      .text(this.yAxisLabel);

    // Colour set up - can't pass in objects so need flat lists
    const domainScale = [];
    const rangeColour = [];

    this.labelList.forEach((element) => {
      domainScale.push(element.deviceFlaskProperty);
      rangeColour.push(element.colour);
    });
    const colour = d3.scaleOrdinal().domain(domainScale).range(rangeColour);

    // Now let's build the tooltip, hidden by default (with opacity 0)
    const tooltip = d3
      .select(`figure#${this.graphId}`)
      .append('div')
      .style('opacity', 0)
      .attr('class', 'body3')
      .attr('class', 'tooltip')
      .style('background-color', '#B4DFFF')
      .style('border', 'solid')
      .style('border-width', '1px')
      .style('border-radius', '5px')
      .style('border-color', '#0C7BB4')
      .style('padding', '10px')
      .style('font-family', '$proxima-nova')
      .style('font-weight', '300')
      .style('font-size', '1rem')
      .style('position', 'absolute');

    // And when the user hovers over a point, let's make it visible
    const mouseover = function () {
      tooltip.style('opacity', 1);
      d3.select(this).style('stroke', 'black').style('opacity', '1');
    };

    // Insert text into the tooltip and position it relative to the point hovered over
    const mousemove = function (e: MouseEvent, d: IRenderData) {
      tooltip
        .html(
          `Reading: ${d.Reading} <br> pH: ${d.pH} <br> Flask: ${d.FlaskName}`,
        )
        .style('left', e.offsetX + 20 + 'px') //offset the tooltip to prevent clash with point
        .style('top', e.offsetY + 'px');
    };

    // Now hide the tooltip when the mouse leaves hoverover
    const mouseleave = function () {
      tooltip.style('opacity', 0);
      d3.select(this).style('stroke', 'none').style('opacity', 0.8);
    };

    graphContent
      .selectAll('circle')
      .data(this.dataToRender)
      .enter()
      .append('circle')
      .attr('cx', (d) => this.x(d.pH))
      .attr('cy', (d) => this.y(d.Reading))
      .attr('r', this.initialRadius)
      .style('fill', (d): string => {
        // Explicitly return a string
        return colour(d.DeviceFlaskProperty) as string;
      })
      .on('mouseover', mouseover)
      .on('mousemove', mousemove)
      .on('mouseleave', mouseleave);

    // Now let's draw our line of best fit per flask
    // Filter data by flask
    const flaskAData = this.dataToRender.filter(
      (element) => element.FlaskName === 'A',
    );

    const flaskBData = this.dataToRender.filter(
      (element) => element.FlaskName === 'B',
    );

    const flaskCData = this.dataToRender.filter(
      (element) => element.FlaskName === 'C',
    );

    const flaskDData = this.dataToRender.filter(
      (element) => element.FlaskName === 'D',
    );

    // Create regression lines
    const regLine = d3
      .line()
      .x((d) => this.x(d[0]))
      .y((d) => this.y(d[1]));

    // Add regression lines to the graphContent group with unique IDs
    const addRegressionLine = (
      data: IRenderData[],
      regressionFn: any,
      flaskLetter: string,
    ) => {
      if (data.length < 2) return; // Need at least 2 points for regression

      graphContent
        .append('path')
        .datum(regressionFn(data))
        .attr('id', `regression-line-${flaskLetter}`) // Add unique ID for later reference
        .attr('class', 'regression-line')
        .attr('d', regLine)
        .attr('stroke', colour(data[0].DeviceFlaskProperty) as string)
        .attr('stroke-width', 2)
        .attr('fill', 'none');
    };

    // Create linear regression functions for each flask
    const linearRegressionA = d3Regression
      .regressionLinear()
      .x((d) => d.pH)
      .y((d) => d.Reading)
      .domain(d3.extent(flaskAData, (d) => d.pH));

    const linearRegressionB = d3Regression
      .regressionLinear()
      .x((d) => d.pH)
      .y((d) => d.Reading)
      .domain(d3.extent(flaskBData, (d) => d.pH));

    const linearRegressionC = d3Regression
      .regressionLinear()
      .x((d) => d.pH)
      .y((d) => d.Reading)
      .domain(d3.extent(flaskCData, (d) => d.pH));

    const linearRegressionD = d3Regression
      .regressionLinear()
      .x((d) => d.pH)
      .y((d) => d.Reading)
      .domain(d3.extent(flaskDData, (d) => d.pH));

    // Add regression lines for each flask with their respective letter for ID
    addRegressionLine(flaskAData, linearRegressionA, 'A');
    addRegressionLine(flaskBData, linearRegressionB, 'B');
    addRegressionLine(flaskCData, linearRegressionC, 'C');
    addRegressionLine(flaskDData, linearRegressionD, 'D');

    // Create legend
    const flaskLetters = Object.keys(this.flaskColours);
    const legendItemWidth = 40;
    const legendWidth = legendItemWidth * flaskLetters.length;

    // Position legend below the graph
    const legendX = (this.width - legendWidth) / 2;
    const legendY = this.height + this.margin.top + this.margin.bottom + 15;

    // Remove any existing legend
    this.axisSvg.select('.legend').remove();

    // Create legend group
    const legendGroup = this.axisSvg
      .append('g')
      .attr('class', 'legend')
      .attr('transform', `translate(${legendX + this.margin.left}, ${legendY})`)
      .style('pointer-events', 'none');

    // Add legend items
    flaskLetters.forEach((flaskLetter, index) => {
      const itemX = index * legendItemWidth;
      const flaskColor = this.flaskColours[flaskLetter];

      // Create group for each legend item
      const legendItem = legendGroup
        .append('g')
        .attr('transform', `translate(${itemX}, 0)`);

      // Add color circle
      legendItem
        .append('circle')
        .attr('cx', 5)
        .attr('cy', 5)
        .attr('r', 5)
        .attr('fill', flaskColor);

      // Add flask label
      legendItem
        .append('text')
        .attr('x', 15)
        .attr('y', 9)
        .style('font-family', '$proxima-nova')
        .style('font-size', '11px')
        .style('font-weight', 'bold')
        .text(flaskLetter);
    });

    this.isDrawing = false; // Set the flag to false to allow resizing
  };
}

export interface IRenderData {
  DeviceFlaskProperty: string;
  Reading: number;
  pH: number;
  FlaskName: string;
}

export interface IPropertyColour {
  deviceFlaskProperty: string;
  colour: string;
}
