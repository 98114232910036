/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Component, OnInit, inject } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { IconNames } from 'src/app/_components/icons/icon-names';
import { advancedNameRegex } from 'src/app/_helpers/utils';
import { DeviceUpdateResponse } from 'src/app/_models/api-responses';
import { Device } from 'src/app/_models/device';
import { DeviceSetupStatus } from 'src/app/_models/device-setup-status';
import { ErrorObject } from 'src/app/_models/error';
import { FormBase } from 'src/app/_models/form-base';
import { Laboratory } from 'src/app/_models/laboratory';
import { Module } from 'src/app/_models/module-enum';
import { Role } from 'src/app/_models/role';
import { TabOptions } from 'src/app/_models/tab-options';
import { User } from 'src/app/_models/user';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { CalibrationService } from 'src/app/_services/calibration.service';
import { DeviceService } from 'src/app/_services/device.service';
import { LaboratoryService } from 'src/app/_services/laboratory.service';
import { ModalService } from 'src/app/_services/modal.service';
import { RoleCheckPipe } from '../../_helpers/role-check.pipe';
import { ValidationModalComponent } from '../../_components/modals/validation-modal/validation-modal.component';
import { ModalComponent } from '../../_components/modals/modal.component';
import { UnderlinedDropdownFormFieldComponent } from '../../_components/forms/underlined-dropdown-form-field/underlined-dropdown-form-field.component';
import { ButtonComponent } from '../../_components/buttons/button/button.component';
import { IconUnderlinedFormFieldComponent } from '../../_components/forms/icon-underlined-form-field/icon-underlined-form-field.component';
import { UnderlinedFormFieldComponent } from '../../_components/forms/underlined-form-field/underlined-form-field.component';
import { InstalledModulesCardComponent } from '../../_components/cards/installed-modules-card/installed-modules-card.component';
import { DeviceCardComponent } from '../../_components/cards/device-card/device-card.component';
import { IconButtonComponent } from '../../_components/buttons/icon-button/icon-button.component';
import { MatTooltip } from '@angular/material/tooltip';
import { MatIcon } from '@angular/material/icon';
import { OptionTabToggleComponent } from '../../_components/selection-controls/option-tab-toggle/option-tab-toggle.component';

@Component({
  selector: 'app-device-details',
  templateUrl: './device-details.component.html',
  styleUrls: ['./device-details.component.scss'],
  standalone: true,
  imports: [
    OptionTabToggleComponent,
    MatIcon,
    MatTooltip,
    IconButtonComponent,
    DeviceCardComponent,
    InstalledModulesCardComponent,
    FormsModule,
    ReactiveFormsModule,
    UnderlinedFormFieldComponent,
    IconUnderlinedFormFieldComponent,
    ButtonComponent,
    UnderlinedDropdownFormFieldComponent,
    ModalComponent,
    ValidationModalComponent,
    RoleCheckPipe,
  ],
})
export class DeviceDetailsComponent implements OnInit {
  private readonly formBuilder = inject(FormBuilder);
  private readonly modalService = inject(ModalService);
  private readonly deviceService = inject(DeviceService);
  private readonly route = inject(ActivatedRoute);
  private readonly calibrationService = inject(CalibrationService);
  private readonly router = inject(Router);
  private readonly authenticationService = inject(AuthenticationService);
  private readonly laboratoryService = inject(LaboratoryService);
  private readonly sanitizer = inject(DomSanitizer);

  // Control vars
  error: ErrorObject;
  bufferError: ErrorObject;
  isProcessing = false;
  isLoading = false;
  deviceId: string;
  device: Device;
  currentLabOrgId: number;
  updateDeviceSuccessMessage: string;
  hasPhModule = false;
  hasLCModule = false;

  minPumpTubeLength = 10;
  maxPumpTubeLength = 300;
  pumpTubeLengthStep = 1;

  // Navigation
  navOptions: TabOptions[];
  allowNavigation: Subject<boolean> = new Subject<boolean>();
  laboratory: Laboratory;

  currentUser: User;
  // Enums for use in the template
  Role = Role;
  Module = Module;
  DeviceSetupStatus = DeviceSetupStatus;

  /**
   * @param {IconNames} loadingSpinner The loading placeholder
   */
  spinner = IconNames.Sync;

  nameField = new FormBase({
    key: 'name',
    label: 'Name of device',
    type: 'text',
    placeholder: 'Name',
    disabled: false,
    required: true,
    value: '',
    options: [],
  });

  pumpTubeLengthField = new FormBase<number>({
    key: 'pumpTubeLength',
    label: 'Tube length (cm)',
    type: 'range',
    placeholder: '0',
    disabled: false,
    required: false,
    value: 85,
    options: [],
  });

  availableBuffersField = new FormBase<string>({
    key: 'availableBuffers',
    label: 'Available Buffers',
    type: 'select',
    placeholder: 'Available Buffers',
    disabled: false,
    required: false,
    value: '',
    options: [],
  });

  bufferMemorySlotField = new FormBase({
    key: 'bufferMemorySlot',
    label: 'Buffer Memory Slot',
    type: 'select',
    placeholder: 'Buffer Memory Slot',
    disabled: false,
    required: false,
    value: '',
    options: [],
  });

  deviceForm = this.formBuilder.group({
    // Explicit typing for string field
    [this.nameField.key]: new FormControl('', [
      Validators.minLength(2),
      Validators.maxLength(100),
      Validators.required,
      Validators.pattern(advancedNameRegex),
    ]),
    [this.pumpTubeLengthField.key]: new FormControl({
      value: this.pumpTubeLengthField.value,
      disabled: this.pumpTubeLengthField.disabled,
    }),
  });

  bufferForm = this.formBuilder.group({
    [this.availableBuffersField.key]: ['', Validators.required],
    [this.bufferMemorySlotField.key]: ['', Validators.required],
  });

  constructor() {
    this.currentUser = this.authenticationService.currentUserValue;
    this.laboratory = this.authenticationService.selectedLaboratory;
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      this.deviceId = params.get('id');
      this.getDeviceFromApi();
    });

    this.navOptions = [
      {
        value: 'details',
        labelText: 'Details',
        link: `/device/${this.deviceId}/details`,
      },
      {
        value: 'od-calibrations',
        labelText: 'OD Calibrations',
        link: `/device/${this.deviceId}/od-calibrations`,
      },
      {
        value: 'oxygen-calibrations',
        labelText: 'o2 Calibrations',
        link: `/device/${this.deviceId}/oxygen-calibrations`,
      },
      {
        value: 'ph-calibrations',
        labelText: 'pH Calibrations',
        link: `/device/${this.deviceId}/ph-calibrations`,
      },
    ];
  }

  icon(iconName: string): SafeHtml {
    let pathString = '';
    pathString = iconName.toString();
    return this.sanitizer.bypassSecurityTrustHtml(pathString);
  }

  getDeviceFromApi(): void {
    this.error = null;
    this.deviceService.getDeviceById(this.deviceId).subscribe({
      next: (device) => {
        this.device = device;
        this.hasPhModule = this.device.deviceModules.includes(Module.pH);
        this.hasLCModule = this.device.deviceModules.includes(
          Module.LiquidControl,
        );
        this.initialiseForm();
        this.getLab();
      },
      error: (error: ErrorObject) => {
        this.error = error;
      },
    });
  }

  getLab(): void {
    this.isLoading = true;
    this.error = null;

    this.laboratoryService.getLab(this.laboratory.laboratoryId).subscribe({
      next: (response: Laboratory) => {
        this.currentLabOrgId = response.organisationId;
        this.isLoading = false;
      },
      error: (error: ErrorObject) => {
        this.error = error;
        this.isLoading = false;
      },
    });
  }

  initialiseForm(): void {
    this.deviceForm.patchValue({
      [this.nameField.key]: this.device.name,
      [this.pumpTubeLengthField.key]: this.device.pumpTubeLength || null,
    });
    this.deviceForm.markAsPristine();

    // If user is not an admin, disable the form
    if (
      this.currentUser.role !==
      (Role.OGI_Super_Administrator ||
        Role.OGI_Laboratory_Administrator ||
        Role.Lab_Administrator)
    ) {
      this.deviceForm.disable();
    }
  }

  isUpdateButtonDisabled(): boolean {
    return (
      this.deviceForm.invalid ||
      !this.deviceForm.dirty ||
      this.isProcessing ||
      this.device.deviceStatusId !== DeviceSetupStatus.Inactive
    );
  }

  isSendBufferButtonDisabled(): boolean {
    return (
      this.bufferForm.invalid ||
      this.bufferForm.pristine ||
      this.isProcessing ||
      this.device.deviceStatusId !== DeviceSetupStatus.Inactive ||
      this.device.isDeleted
    );
  }

  updateDevice(): void {
    // If form is already being submitted, exit
    if (this.isProcessing) {
      return;
    }

    this.isProcessing = true;
    this.error = null;
    // Update device object
    const deviceUpdate = {
      id: this.device.id,
      name: this.deviceForm.value.name as string,
      pumpTubeLength: this.deviceForm.value.pumpTubeLength as number,
    };
    // Send to API
    this.deviceService.updateDevice(deviceUpdate).subscribe({
      next: (data: DeviceUpdateResponse) => {
        const { device, message } = data;
        this.device = device;
        this.initialiseForm();
        this.isProcessing = false;
        this.updateDeviceSuccessMessage = message;
        this.openModal('update-device');
      },
      error: (error: ErrorObject) => {
        this.error = error;
        this.isProcessing = false;
        this.openModal('error');
      },
    });
  }

  deleteDevice(): void {
    this.error = null;
    this.deviceService.deleteDevice(this.deviceId).subscribe({
      next: (data: DeviceUpdateResponse) => {
        this.updateDeviceSuccessMessage = data.message;
        this.modalService.close('delete-device');
        this.modalService.open('update-device');
      },
      error: (error: ErrorObject) => {
        this.error = error;
        this.modalService.close('delete-device');
        this.openModal('error');
      },
    });
  }

  modalButtonClicked(buttonId: string): void {
    switch (buttonId) {
      case 'close-error-button':
        this.modalService.close('error');
        break;
      case 'close-buffer-error-button':
        this.modalService.close('buffer-error');
        break;
      case 'close-success-button':
        this.modalService.close('update-device');
        break;
      case 'close-buffer-success-button':
        this.modalService.close('buffer-sent');
        break;
      case 'cancel-button':
        this.allowNavigation.next(false);
        this.modalService.close('attention');
        break;
      case 'confirm-navigation':
        this.allowNavigation.next(true);
        this.modalService.close('attention');
        break;
      case 'confirm-delete-button':
        this.deleteDevice();
        break;
      case 'cancel-delete-button':
        this.modalService.close('delete-device');
        break;
      default:
        break;
    }
  }

  openModal(modalId: string): void {
    this.modalService.open(modalId);
  }

  canExit(): Promise<boolean> | boolean {
    return this.isNavigationAllowed();
  }

  private isNavigationAllowed(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      if (this.deviceForm.pristine) {
        resolve(true);
      } else {
        this.openModal('attention');
        this.allowNavigation.subscribe((isConfirmed) => resolve(isConfirmed));
      }
    });
  }
}
