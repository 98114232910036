<div *ngIf="laboratory; else noLab">
  <div id="title--container">
    <h4>Add a new user</h4>
    <h5>{{ laboratory.laboratoryName }}</h5>
  </div>
  <hr />
  <div id="account--container">
    <div class="body1">
      Please complete the details below to add a new user.
    </div>
    <div class="form--container">
      <form [formGroup]="addUserForm">
        <app-underlined-form-field
          [parentForm]="addUserForm"
          [field]="firstNameField"
        ></app-underlined-form-field>
        <app-underlined-form-field
          [parentForm]="addUserForm"
          [field]="lastNameField"
        ></app-underlined-form-field>
        <app-underlined-form-field
          [parentForm]="addUserForm"
          [field]="emailField"
        ></app-underlined-form-field>
        <app-underlined-dropdown-form-field
          [field]="userRoleField"
          [parentForm]="addUserForm"
        ></app-underlined-dropdown-form-field>
        <app-underlined-dropdown-form-field
          [field]="availableLabsField"
          [parentForm]="addUserForm"
        ></app-underlined-dropdown-form-field>
      </form>
      <h6>Selected Labs</h6>
      <span *ngIf="selectedLabs?.length < 1 && !labSelectError">
        No labs selected</span
      >
      <div
        *ngFor="let lab of selectedLabs"
        id="lab-list--container"
        class="body1"
      >
        <span>{{ lab.laboratoryName }}</span>
        <app-icon-button
          buttonId="remove-lab-btn"
          buttonStyle="transparent"
          buttonShape="square"
          textColor="red"
          iconName="close"
          (buttonClicked)="removeLab(lab)"
        ></app-icon-button>
      </div>
      <span *ngIf="labSelectError" class="error"> {{ labSelectError }} </span>
      <div class="button--container">
        <app-button
          label="Add User"
          buttonId="add-btn"
          (buttonClicked)="onSubmit()"
          [isDisabled]="addUserForm.invalid"
          [isProcessing]="isProcessing"
        >
        </app-button>
      </div>
    </div>
  </div>
</div>
<ng-template #noLab>
  <app-error-page
    title="No Lab found"
    primaryText="Sorry, you cannot access this page unless you are a member of a lab"
  ></app-error-page>
</ng-template>
<app-modal
  id="create-account"
  titleText="Success"
  [primaryText]="addUserSuccessMessage"
  buttonType="single-button"
  primaryButtonText="return to dashboard"
  primaryButtonId="dashboard-button"
  modalStyle="success"
  (modalButtonClicked)="modalButtonClicked($event)"
></app-modal>
<app-modal
  id="error"
  titleText="Error"
  [primaryText]="error?.message"
  buttonType="single-button"
  primaryButtonText="close"
  primaryButtonId="close-button"
  modalStyle="error"
  (modalButtonClicked)="modalButtonClicked($event)"
></app-modal>
<app-modal
  id="attention"
  titleText="Attention"
  primaryText="Your changes have not been saved. Press cancel to return to the form and save your changes, or continue to discard."
  buttonType="double-button"
  primaryButtonText="Continue"
  primaryButtonId="confirm-navigation"
  secondaryButtonText="Cancel"
  secondaryButtonId="cancel-button"
  modalStyle="warning"
  (modalButtonClicked)="modalButtonClicked($event)"
></app-modal>
