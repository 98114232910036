import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function integerValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value as string | number;

    // Check if the value is a string or number and is an integer without decimals
    const isInteger = typeof value === 'number' && Number.isInteger(value);

    // Additionally, check for cases like "12.0" by testing the string representation
    const isValidIntegerString =
      typeof value === 'string' && /^\d+$/.test(value);

    // If it's not an integer or valid integer string, return the error; otherwise, return null
    return isInteger || isValidIntegerString ? null : { integer: { value } };
  };
}
