import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatCamelCase',
})
export class FormatCamelCasePipe implements PipeTransform {
  transform(value: string | null | undefined): string {
    if (!value) return '';

    // Preserve "pH" without adding spaces
    const preservedPH = value.replace(/pH/g, 'p_H'); // Temporarily replace "pH" with a placeholder

    // Add a space before each uppercase letter unless it's part of consecutive uppercase letters
    const result = preservedPH
      // Look for uppercase letters followed by lowercase letters and add a space before them
      .replace(/([A-Z]+)([A-Z][a-z])/g, '$1 $2')
      // Add a space before any standalone uppercase letter that is followed by a lowercase letter
      .replace(/([a-z])([A-Z])/g, '$1 $2')
      .trim();

    // Restore the placeholder "p_H" back to "pH"
    const finalResult = result.replace(/p_H/g, 'pH');

    // Capitalize the first letter of the result
    return finalResult.charAt(0).toUpperCase() + finalResult.slice(1);
  }
}
