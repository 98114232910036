<div id="title--container">
  <h4>Edit User</h4>
  @if (currentUser.role | roleCheck: [Role.OGI_Super_Administrator,
  Role.Super_User]) {
  <app-icon-button
    label="delete user"
    buttonStyle="negative"
    buttonId="delete-user-btn"
    iconName="delete"
    buttonShape="square"
    (buttonClicked)="openModal('delete-user')"
  ></app-icon-button>
  }
</div>
<hr />
<div id="account--container">
  <div class="form--container">
    <form [formGroup]="editUserForm">
      <app-underlined-form-field
        [parentForm]="editUserForm"
        [field]="firstNameField"
      ></app-underlined-form-field>
      <app-underlined-form-field
        [parentForm]="editUserForm"
        [field]="lastNameField"
      ></app-underlined-form-field>
      <app-underlined-dropdown-form-field
        [field]="userRoleField"
        [parentForm]="editUserForm"
      ></app-underlined-dropdown-form-field>
      <app-underlined-dropdown-form-field
        [field]="availableLabsField"
        [parentForm]="editUserForm"
      ></app-underlined-dropdown-form-field>
    </form>
    <h6>User Labs</h6>
    <span *ngIf="user?.laboratories?.length < 1">
      User not assigned to lab</span
    >
    <div
      *ngFor="let lab of user?.laboratories"
      id="lab-list--container"
      class="body1"
    >
      <span>{{ lab.laboratoryName }}</span>
      <app-icon-button
        buttonId="remove-lab-btn"
        buttonStyle="transparent"
        buttonShape="square"
        textColor="red"
        iconName="close"
        (buttonClicked)="removeLab(lab)"
      ></app-icon-button>
    </div>
    <span *ngIf="labSelectError" class="error"> {{ labSelectError }} </span>
    <div class="button--container">
      <app-button
        label="Update User"
        buttonId="update-btn"
        (buttonClicked)="onSubmit()"
        [isDisabled]="editUserForm.invalid || editUserForm.pristine"
        [isProcessing]="isProcessing"
      >
      </app-button>
    </div>
  </div>
</div>
<app-modal
  id="success"
  titleText="Success"
  [primaryText]="updateUserSuccessMessage"
  buttonType="single-button"
  primaryButtonText="return to dashboard"
  primaryButtonId="dashboard-button"
  modalStyle="success"
  (modalButtonClicked)="modalButtonClicked($event)"
></app-modal>
<app-modal
  id="error"
  titleText="Error"
  [primaryText]="error?.message"
  buttonType="single-button"
  primaryButtonText="close"
  primaryButtonId="close-button"
  modalStyle="error"
  (modalButtonClicked)="modalButtonClicked($event)"
></app-modal>
<app-modal
  id="attention"
  titleText="Attention"
  primaryText="Your changes have not been saved. Press cancel to return to the form and save your changes, or continue to discard."
  buttonType="double-button"
  primaryButtonText="Continue"
  primaryButtonId="confirm-navigation"
  secondaryButtonText="Cancel"
  secondaryButtonId="cancel-button"
  modalStyle="warning"
  (modalButtonClicked)="modalButtonClicked($event)"
></app-modal>
<app-validation-modal
  id="delete-user"
  titleText="Delete user"
  validationText="delete"
  buttonType="double-button"
  primaryButtonText="Delete"
  primaryButtonId="primary-button"
  secondaryButtonText="Close"
  secondaryButtonId="secondary-button"
  (modalButtonClicked)="deleteUser()"
  [error]="error"
></app-validation-modal>
<app-modal
  id="success"
  titleText="Success"
  [primaryText]="updateUserSuccessMessage"
  buttonType="single-button"
  primaryButtonText="close"
  primaryButtonId="close-button"
  modalStyle="success"
></app-modal>
