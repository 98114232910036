<button
  [type]="type"
  [id]="buttonId"
  (click)="clickButton()"
  [ngClass]="classes"
  [disabled]="isDisabled"
  [attr.aria-label]="label"
  [title]="label"
>
  <mat-icon *ngIf="iconName" [attr.aria-label]="label">{{ iconName }}</mat-icon>
  <span *ngIf="displayText" class="body2">{{ label }}</span>
</button>
