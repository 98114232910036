import { Component, inject, OnInit, signal } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '../_models/user';
import { AuthenticationService } from '../_services/authentication.service';
import { MatIconButtonComponent } from '../_components/buttons/mat-icon-button/mat-icon-button.component';
import { Laboratory } from '../_models/laboratory';
import { Role } from '../_models/role';

@Component({
  selector: 'app-help',
  standalone: true,
  imports: [MatIconButtonComponent],
  templateUrl: './help.component.html',
  styleUrl: './help.component.scss',
})
export class HelpComponent implements OnInit {
  private router = inject(Router);
  private authenticationService = inject(AuthenticationService);

  // Permissions
  laboratory = signal<Laboratory | undefined>(undefined);
  currentUser = signal<User | undefined>(undefined);

  // Makes the Role enum available in the template
  Role = Role;

  ngOnInit(): void {
    this.currentUser.set(this.authenticationService.currentUserValue);
    this.laboratory.set(this.authenticationService.selectedLaboratory);
  }

  dashboardButtonClicked(buttonId: string): void {
    switch (buttonId) {
      case 'add-lab':
        void this.router.navigate(['/laboratories/add-lab'], {
          state: { data: this.currentUser().organisationId },
        });
        break;
      case 'join-lab':
        void this.router.navigate(['/laboratories/view-lab']);
        break;
      case 'add-users':
        void this.router.navigate(['/user/add-user']);
        break;
      case 'add-device':
        void this.router.navigate(['/device/create-device']);
        break;
    }
  }
}
