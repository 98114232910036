<label [for]="checkbox.id" [ngClass]="classes" [formGroup]="parentForm">
  <input
    type="checkbox"
    [id]="checkbox.id"
    [formControlName]="checkbox.id"
    (change)="onValueChange($event)"
  />
  <div class="checkmark--background">
    <div class="checkmark">
      <mat-icon>done</mat-icon>
    </div>
  </div>
  {{ checkbox.labelText }}
</label>
