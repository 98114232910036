import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormBase } from 'src/app/_models/form-base';
import { IconNames } from 'src/app/_components/icons/icon-names';
import { ERROR_MESSAGE } from 'src/app/_helpers/utils';

@Component({
  selector: 'app-filled-form-field',
  templateUrl: './filled-form-field.component.html',
  styleUrls: ['./filled-form-field.component.scss'],
})
export class FilledFormFieldComponent {
  /**
   * Form base
   * @requires
   */
  @Input()
  field: FormBase<string>;

  /**
   * The form group from the parent form component
   * @requires
   */
  @Input()
  parentForm: FormGroup = new FormGroup({});

  /**
   * What type of icon
   *
   */
  @Input()
  iconName: IconNames;

  showing = false;

  public get classes(): string[] {
    const disabled = this.field.disabled ? 'disabled' : '';
    const error = this.hasErrors ? 'error' : '';
    const validated =
      !this.hasErrors && this.parentForm.controls[this.field.key]?.touched
        ? 'validated'
        : '';
    return ['filled-form-field', disabled, error, validated];
  }

  public get hasErrors(): boolean {
    return (
      this.parentForm.controls[this.field.key] &&
      this.parentForm.controls[this.field.key].errors &&
      this.parentForm.controls[this.field.key]?.touched
    );
  }

  public get errorsList(): string[] {
    return Object.keys(this.parentForm.controls[this.field.key].errors).map(
      (err) =>
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        ERROR_MESSAGE[err](
          this.parentForm.controls[this.field.key].getError(err)
        ) as string
    );
  }

  public get showHideText(): string {
    return this.showing ? 'Hide' : 'Show';
  }

  public get inputType(): string {
    if (this.field.type === 'password') {
      return this.showing ? 'text' : 'password';
    } else {
      return this.field.type;
    }
  }

  public get inputClasses(): string[] {
    const error = this.hasErrors ? 'error' : '';
    return ['body1', error];
  }

  togglePassword(): void {
    if (!this.field.disabled) {
      this.showing = !this.showing;
    }
  }
}
