import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { FormBase } from 'src/app/_models/form-base';

@Component({
  selector: 'app-dropdown-form-field',
  templateUrl: './dropdown-form-field.component.html',
  styleUrls: ['./dropdown-form-field.component.scss'],
  standalone: true,
  imports: [ReactiveFormsModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropdownFormFieldComponent {
  /**
   * @param {FormBase<string>} field Form base
   * @requires
   */
  @Input()
  field!: FormBase<string>;

  /**
   * @param {FormGroup} parentForm Parent form name
   * @requires
   */
  @Input()
  parentForm!: FormGroup;

  /**
   * @param {string} dropdownStyle Dropdown style
   */
  @Input()
  dropdownStyle: 'default' | 'nav' = 'default';
}
