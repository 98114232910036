import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-loading-spinner',
  templateUrl: './loading-spinner.component.html',
  styleUrls: ['./loading-spinner.component.scss'],
})
export class LoadingSpinnerComponent {
  /**
   * @param {string} label Label
   */
  @Input()
  label = 'Loading';

  /**
   * @param {boolean} isProcessing Is processing
   * @default false
   */
  @Input()
  isProcessing = true;
}
