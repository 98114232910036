import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  inject,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { FormBase } from 'src/app/_models/form-base';
import { ERROR_MESSAGE, ValidationErrorMessages } from 'src/app/_helpers/utils';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatError } from '@angular/material/form-field';
import { MatSlider, MatSliderThumb } from '@angular/material/slider';

@Component({
  selector: 'app-icon-underlined-form-field',
  templateUrl: './icon-underlined-form-field.component.html',
  styleUrls: ['./icon-underlined-form-field.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatSlider,
    MatSliderThumb,
    MatFormField,
    MatInput,
    MatIcon,
    MatError,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconUnderlinedFormFieldComponent implements OnInit {
  private readonly changeDetectorRef = inject(ChangeDetectorRef);

  /**
   * @param {FormBase<string>} field Form field base
   * @requires
   */
  @Input()
  field: FormBase<string>;

  /**
   * @param {FormGroup} parentForm The parent form
   * @requires
   */
  @Input()
  parentForm: FormGroup = new FormGroup({});

  /**
   * @param {number} minValue Min value for number field
   */
  @Input()
  minValue: number;

  /**
   * @param {number} maxValue Max value for number field
   */
  @Input()
  maxValue: number;

  /**
   * @param {number} step Intervals for number field
   */
  @Input()
  step: number;

  /**
   * @param {string} warningMessage Warning message for number field
   */
  @Input()
  warningMessage: string;

  /**
   * @param {boolean} showWarning Show warning for number field
   */
  @Input()
  showWarning = false;

  /**
   * Output minus click event for number fields
   */
  @Output()
  minusButtonClick: EventEmitter<void> = new EventEmitter<void>();

  /**
   * Output plus click event for number fields
   */
  @Output()
  plusButtonClick: EventEmitter<void> = new EventEmitter<void>();

  sliderValue: number;

  ngOnInit(): void {
    // Set initial value
    const control = this.parentForm.get(this.field.key);
    this.sliderValue = control?.value as number;

    // Subscribe to value changes
    if (control) {
      control.valueChanges.subscribe((value) => {
        this.sliderValue = value as number;
        this.changeDetectorRef.markForCheck();
      });
    }
  }

  // Cache form control reference
  public get control(): FormControl {
    return this.parentForm.controls[this.field.key] as FormControl;
  }

  public get hasErrors(): boolean {
    return this.control.invalid && this.control.touched;
  }

  public get errorsList(): string[] {
    return this.hasErrors
      ? Object.keys(this.control.errors || {}).map((err) => {
          const errorFn = ERROR_MESSAGE[err as keyof ValidationErrorMessages];
          return errorFn
            ? errorFn(this.control.getError(err) as never)
            : 'Unknown error';
        })
      : [];
  }

  minusButtonClicked(): void {
    this.minusButtonClick.emit();
  }

  plusButtonClicked(): void {
    this.plusButtonClick.emit();
  }
}
