@if (laboratory) {
  <div id="set-parameters--container">
    <div id="header--container">
      <h4>
        @if (!createdExperiment) {
          <span>New Experiment Setup</span>
        }
        @if (createdExperiment) {
          <span>
            {{ createdExperiment?.name }}
            <app-status-indicator
              [status]="ExperimentStatus[createdExperiment?.experimentStatusId]"
            ></app-status-indicator
          ></span>
        }
      </h4>
      <h5>{{ laboratory.laboratoryName }}</h5>
    </div>
    <hr />
    <div id="inner-page--container">
      <div class="selection--container">
        <app-radio-button
          id="toggle--container"
          [field]="experimentModeField"
          [parentForm]="experimentControlsForm"
          [currentValue]="
            experimentControlsForm?.controls[experimentModeField.key]?.value
          "
          type="round"
          (radioButtonChange)="
            handleExperimentModeChange(
              experimentControlsForm?.controls[experimentModeField.key]?.value
            )
          "
        ></app-radio-button>
        @if (createdExperiment) {
          <app-button
            label="Delete Draft"
            buttonId="delete-btn"
            buttonStyle="negative"
            (buttonClicked)="openModal('delete-draft')"
            [isDisabled]="
              isProcessing ||
              deleteIsProcessing ||
              (currentUser.role
                | roleCheck
                  : [
                      Role.Read_Access_User,
                      Role.Run_Access_User,
                      Role.Edit_Access_User,
                    ])
            "
          ></app-button>
        }
      </div>
      <div>
        <app-checkbox-group
          id="module-select"
          [parentForm]="moduleSelectForm"
          [field]="moduleCheckboxesField"
          [formArrayGroupName]="moduleCheckboxesField.key"
          (checkboxClicked)="moduleCheckboxClicked($event)"
          checkboxStyle="tabs"
          [isDisabled]="draftIsLocked"
        ></app-checkbox-group>
      </div>
      <div id="expansion-button--container">
        <h5>Set Parameters</h5>
        <button mat-button id="expand-button" (click)="accordion.openAll()">
          Expand all
        </button>
        <button mat-button id="expand-button" (click)="accordion.closeAll()">
          Collapse all
        </button>
      </div>
      <mat-accordion class="example-headers-align" multi>
        <mat-expansion-panel [expanded]="false">
          <mat-expansion-panel-header>
            <mat-panel-title><h6>Experiment Controls</h6></mat-panel-title>
          </mat-expansion-panel-header>
          <div id="experiment-controls--container">
            <form>
              <div class="control--container">
                <app-underlined-form-field
                  [parentForm]="experimentControlsForm"
                  [field]="experimentNameField"
                ></app-underlined-form-field>
                <app-underlined-form-field
                  [parentForm]="experimentControlsForm"
                  [field]="organismField"
                ></app-underlined-form-field>
                <app-underlined-form-field
                  [parentForm]="experimentControlsForm"
                  [field]="strainField"
                ></app-underlined-form-field>
                <app-underlined-form-field
                  [parentForm]="experimentControlsForm"
                  [field]="mediaField"
                ></app-underlined-form-field>
              </div>
            </form>
          </div>
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="false">
          <mat-expansion-panel-header>
            <mat-panel-title><h6>Base Unit</h6></mat-panel-title>
          </mat-expansion-panel-header>
          <div id="base-unit--container">
            <form>
              <div class="control--container">
                <app-underlined-form-field
                  [parentForm]="experimentControlsForm"
                  [field]="samplingTimeField"
                  [minValue]="minSampleTimeInMins"
                  [maxValue]="maxSampleTimeInMins"
                  [step]="sampleTimeStep"
                  [displayTooltip]="true"
                ></app-underlined-form-field>
                <app-underlined-form-field
                  [parentForm]="experimentControlsForm"
                  [field]="experimentTimeField"
                  [minValue]="minExperimentTimeInHours"
                  [maxValue]="maxExperimentTimeInHours"
                  [step]="experimentTimeStep"
                  [displayTooltip]="true"
                ></app-underlined-form-field>
                <app-underlined-form-field
                  [parentForm]="experimentControlsForm"
                  [field]="ambientTemperatureField"
                  [minValue]="minAmbientTemp"
                  [maxValue]="maxAmbientTemp"
                  [displayTooltip]="true"
                  [showWarning]="isAmbientTemperatureInWarningRange()"
                  [warningMessage]="ambientTempWarningMessage"
                  [step]="ambientTempStep"
                ></app-underlined-form-field>
              </div>
              <div class="control--container">
                <app-slide-toggle
                  [parentForm]="experimentControlsForm"
                  [field]="temperatureControlAField"
                ></app-slide-toggle>
                <app-icon-underlined-form-field
                  [parentForm]="experimentControlsForm"
                  [field]="temperatureAField"
                  [minValue]="minCultureTemp"
                  [maxValue]="maxCultureTemp"
                  [step]="cultureTempStep"
                ></app-icon-underlined-form-field>
              </div>
              <div class="control--container">
                <app-slide-toggle
                  [parentForm]="experimentControlsForm"
                  [field]="temperatureControlBField"
                ></app-slide-toggle>
                <app-icon-underlined-form-field
                  [parentForm]="experimentControlsForm"
                  [field]="temperatureBField"
                  [minValue]="minCultureTemp"
                  [maxValue]="maxCultureTemp"
                  [step]="cultureTempStep"
                ></app-icon-underlined-form-field>
              </div>
              <div class="control--container">
                <app-slide-toggle
                  [parentForm]="experimentControlsForm"
                  [field]="temperatureControlCField"
                ></app-slide-toggle>
                <app-icon-underlined-form-field
                  [parentForm]="experimentControlsForm"
                  [field]="temperatureCField"
                  [minValue]="minCultureTemp"
                  [maxValue]="maxCultureTemp"
                  [step]="cultureTempStep"
                ></app-icon-underlined-form-field>
              </div>
              <div class="control--container">
                <app-slide-toggle
                  [parentForm]="experimentControlsForm"
                  [field]="temperatureControlDField"
                ></app-slide-toggle>
                <app-icon-underlined-form-field
                  [parentForm]="experimentControlsForm"
                  [field]="temperatureDField"
                  [minValue]="minCultureTemp"
                  [maxValue]="maxCultureTemp"
                  [step]="cultureTempStep"
                ></app-icon-underlined-form-field>
              </div>
              <div class="control--container">
                <app-slide-toggle
                  [parentForm]="experimentControlsForm"
                  [field]="setStirringSpeedAField"
                ></app-slide-toggle>
                <app-icon-underlined-form-field
                  [parentForm]="experimentControlsForm"
                  [field]="speedAField"
                  [minValue]="minSpeed"
                  [maxValue]="maxSpeed"
                  [step]="speedStep"
                ></app-icon-underlined-form-field>
              </div>
              <div class="control--container">
                <app-slide-toggle
                  [parentForm]="experimentControlsForm"
                  [field]="setStirringSpeedBField"
                ></app-slide-toggle>
                <app-icon-underlined-form-field
                  [parentForm]="experimentControlsForm"
                  [field]="speedBField"
                  [minValue]="minSpeed"
                  [maxValue]="maxSpeed"
                  [step]="speedStep"
                ></app-icon-underlined-form-field>
              </div>
              <div class="control--container">
                <app-slide-toggle
                  [parentForm]="experimentControlsForm"
                  [field]="setStirringSpeedCField"
                ></app-slide-toggle>
                <app-icon-underlined-form-field
                  [parentForm]="experimentControlsForm"
                  [field]="speedCField"
                  [minValue]="minSpeed"
                  [maxValue]="maxSpeed"
                  [step]="speedStep"
                ></app-icon-underlined-form-field>
              </div>
              <div class="control--container">
                <app-slide-toggle
                  [parentForm]="experimentControlsForm"
                  [field]="setStirringSpeedDField"
                ></app-slide-toggle>
                <app-icon-underlined-form-field
                  [parentForm]="experimentControlsForm"
                  [field]="speedDField"
                  [minValue]="minSpeed"
                  [maxValue]="maxSpeed"
                  [step]="speedStep"
                ></app-icon-underlined-form-field>
              </div>
            </form>
          </div>
        </mat-expansion-panel>
        <mat-expansion-panel
          [expanded]="
            ExperimentMode[
              experimentControlsForm?.controls[experimentModeField.key]?.value
            ] === 'Turbidostat'
          "
          [disabled]="
            ExperimentMode[
              experimentControlsForm?.controls[experimentModeField.key]?.value
            ] !== 'Turbidostat'
          "
        >
          <mat-expansion-panel-header>
            <mat-panel-title>
              <h6>Turbidostat</h6>
            </mat-panel-title>
            @if (
              ExperimentMode[
                experimentControlsForm?.controls[experimentModeField.key]?.value
              ] !== "Turbidostat"
            ) {
              <mat-panel-description> Mode not selected </mat-panel-description>
            }
          </mat-expansion-panel-header>
          <div id="turbidostat--container">
            <form>
              <app-slide-toggle
                [parentForm]="experimentControlsForm"
                [field]="maintainODField"
              ></app-slide-toggle>
              <div>
                <div class="control--container">
                  <app-slide-toggle
                    [parentForm]="experimentControlsForm"
                    [field]="turbAActiveField"
                  ></app-slide-toggle>
                  <app-icon-underlined-form-field
                    [parentForm]="experimentControlsForm"
                    [field]="targetODAField"
                    [minValue]="minTargetOD"
                    [maxValue]="maxTargetOD"
                    [step]="targetODStep"
                  ></app-icon-underlined-form-field>
                </div>
                <div class="control--container">
                  <app-slide-toggle
                    [parentForm]="experimentControlsForm"
                    [field]="turbBActiveField"
                  ></app-slide-toggle>
                  <app-icon-underlined-form-field
                    [parentForm]="experimentControlsForm"
                    [field]="targetODBField"
                    [minValue]="minTargetOD"
                    [maxValue]="maxTargetOD"
                    [step]="targetODStep"
                  ></app-icon-underlined-form-field>
                </div>
                <div class="control--container">
                  <app-slide-toggle
                    [parentForm]="experimentControlsForm"
                    [field]="turbCActiveField"
                  ></app-slide-toggle>
                  <app-icon-underlined-form-field
                    [parentForm]="experimentControlsForm"
                    [field]="targetODCField"
                    [minValue]="minTargetOD"
                    [maxValue]="maxTargetOD"
                    [step]="targetODStep"
                  ></app-icon-underlined-form-field>
                </div>
                <div class="control--container">
                  <app-slide-toggle
                    [parentForm]="experimentControlsForm"
                    [field]="turbDActiveField"
                  ></app-slide-toggle>
                  <app-icon-underlined-form-field
                    [parentForm]="experimentControlsForm"
                    [field]="targetODDField"
                    [minValue]="minTargetOD"
                    [maxValue]="maxTargetOD"
                    [step]="targetODStep"
                  ></app-icon-underlined-form-field>
                </div>
              </div>
            </form>
          </div>
        </mat-expansion-panel>
        <mat-expansion-panel
          [expanded]="
            ExperimentMode[
              experimentControlsForm?.controls[experimentModeField.key]?.value
            ] === 'Chemostat'
          "
          [disabled]="
            ExperimentMode[
              experimentControlsForm?.controls[experimentModeField.key]?.value
            ] !== 'Chemostat'
          "
        >
          <mat-expansion-panel-header>
            <mat-panel-title>
              <h6>Chemostat</h6>
            </mat-panel-title>
            @if (
              ExperimentMode[
                experimentControlsForm?.controls[experimentModeField.key]?.value
              ] !== "Chemostat"
            ) {
              <mat-panel-description> Mode not selected </mat-panel-description>
            }
          </mat-expansion-panel-header>
          <div id="chemostat--container">
            <form>
              <div class="control--container">
                <app-slide-toggle
                  [parentForm]="experimentControlsForm"
                  [field]="chemoAActiveField"
                ></app-slide-toggle>
                <app-icon-underlined-form-field
                  [parentForm]="experimentControlsForm"
                  [field]="flowRateAField"
                  [minValue]="minFlowRate"
                  [maxValue]="maxFlowRate"
                  [step]="flowRateStep"
                ></app-icon-underlined-form-field>
              </div>
              <div class="control--container">
                <app-slide-toggle
                  [parentForm]="experimentControlsForm"
                  [field]="chemoBActiveField"
                ></app-slide-toggle>
                <app-icon-underlined-form-field
                  [parentForm]="experimentControlsForm"
                  [field]="flowRateBField"
                  [minValue]="minFlowRate"
                  [maxValue]="maxFlowRate"
                  [step]="flowRateStep"
                ></app-icon-underlined-form-field>
              </div>
              <div class="control--container">
                <app-slide-toggle
                  [parentForm]="experimentControlsForm"
                  [field]="chemoCActiveField"
                ></app-slide-toggle>
                <app-icon-underlined-form-field
                  [parentForm]="experimentControlsForm"
                  [field]="flowRateCField"
                  [minValue]="minFlowRate"
                  [maxValue]="maxFlowRate"
                  [step]="flowRateStep"
                ></app-icon-underlined-form-field>
              </div>
              <div class="control--container">
                <app-slide-toggle
                  [parentForm]="experimentControlsForm"
                  [field]="chemoDActiveField"
                ></app-slide-toggle>
                <app-icon-underlined-form-field
                  [parentForm]="experimentControlsForm"
                  [field]="flowRateDField"
                  [minValue]="minFlowRate"
                  [maxValue]="maxFlowRate"
                  [step]="flowRateStep"
                ></app-icon-underlined-form-field>
              </div>
            </form>
          </div>
        </mat-expansion-panel>
        <mat-expansion-panel
          [expanded]="
            ExperimentMode[
              experimentControlsForm?.controls[experimentModeField.key]?.value
            ] === 'PHControl'
          "
          [disabled]="
            ExperimentMode[
              experimentControlsForm?.controls[experimentModeField.key]?.value
            ] !== 'PHControl'
          "
        >
          <mat-expansion-panel-header>
            <mat-panel-title>
              <h6>pH Control</h6>
            </mat-panel-title>
            @if (
              ExperimentMode[
                experimentControlsForm?.controls[experimentModeField.key]?.value
              ] !== "PHControl"
            ) {
              <mat-panel-description> Mode not selected </mat-panel-description>
            }
          </mat-expansion-panel-header>
          <div id="ph--container">
            <form>
              <div class="control--container">
                <app-slide-toggle
                  [parentForm]="experimentControlsForm"
                  [field]="phControlAField"
                ></app-slide-toggle>
                <app-radio-button
                  id="ph-radio-a"
                  [field]="inputTypeAField"
                  [parentForm]="experimentControlsForm"
                  [currentValue]="
                    experimentControlsForm?.controls[inputTypeAField.key]?.value
                  "
                  legendStyle="small"
                ></app-radio-button>
              </div>
              <app-icon-underlined-form-field
                [parentForm]="experimentControlsForm"
                [field]="targetPHAField"
                [minValue]="minTargetPH"
                [maxValue]="maxTargetPH"
                [step]="targetPHStep"
              ></app-icon-underlined-form-field>
              <app-icon-underlined-form-field
                [parentForm]="experimentControlsForm"
                [field]="pumpDurationAField"
                [minValue]="minPumpDuration"
                [maxValue]="maxPumpDuration"
                [step]="pumpDurationStep"
              ></app-icon-underlined-form-field>
              <div class="control--container">
                <app-slide-toggle
                  [parentForm]="experimentControlsForm"
                  [field]="phControlBField"
                ></app-slide-toggle>
                <app-radio-button
                  id="ph-radio-b"
                  [field]="inputTypeBField"
                  [parentForm]="experimentControlsForm"
                  [currentValue]="
                    experimentControlsForm?.controls[inputTypeBField.key]?.value
                  "
                  legendStyle="small"
                ></app-radio-button>
              </div>
              <app-icon-underlined-form-field
                [parentForm]="experimentControlsForm"
                [field]="targetPHBField"
                [minValue]="minTargetPH"
                [maxValue]="maxTargetPH"
                [step]="targetPHStep"
              ></app-icon-underlined-form-field>
              <app-icon-underlined-form-field
                [parentForm]="experimentControlsForm"
                [field]="pumpDurationBField"
                [minValue]="minPumpDuration"
                [maxValue]="maxPumpDuration"
                [step]="pumpDurationStep"
              ></app-icon-underlined-form-field>
              <div class="control--container">
                <app-slide-toggle
                  [parentForm]="experimentControlsForm"
                  [field]="phControlCField"
                ></app-slide-toggle>
                <app-radio-button
                  id="ph-radio-c"
                  [field]="inputTypeCField"
                  [parentForm]="experimentControlsForm"
                  [currentValue]="
                    experimentControlsForm?.controls[inputTypeCField.key]?.value
                  "
                  legendStyle="small"
                ></app-radio-button>
              </div>
              <app-icon-underlined-form-field
                [parentForm]="experimentControlsForm"
                [field]="targetPHCField"
                [minValue]="minTargetPH"
                [maxValue]="maxTargetPH"
                [step]="targetPHStep"
              ></app-icon-underlined-form-field>
              <app-icon-underlined-form-field
                [parentForm]="experimentControlsForm"
                [field]="pumpDurationCField"
                [minValue]="minPumpDuration"
                [maxValue]="maxPumpDuration"
                [step]="pumpDurationStep"
              ></app-icon-underlined-form-field>
              <div class="control--container">
                <app-slide-toggle
                  [parentForm]="experimentControlsForm"
                  [field]="phControlDField"
                ></app-slide-toggle>
                <app-radio-button
                  id="ph-radio-d"
                  [field]="inputTypeDField"
                  [parentForm]="experimentControlsForm"
                  [currentValue]="
                    experimentControlsForm?.controls[inputTypeDField.key]?.value
                  "
                  legendStyle="small"
                ></app-radio-button>
              </div>
              <app-icon-underlined-form-field
                [parentForm]="experimentControlsForm"
                [field]="targetPHDField"
                [minValue]="minTargetPH"
                [maxValue]="maxTargetPH"
                [step]="targetPHStep"
              ></app-icon-underlined-form-field>
              <app-icon-underlined-form-field
                [parentForm]="experimentControlsForm"
                [field]="pumpDurationDField"
                [minValue]="minPumpDuration"
                [maxValue]="maxPumpDuration"
                [step]="pumpDurationStep"
              ></app-icon-underlined-form-field>
            </form>
          </div>
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="useOxygen" [disabled]="!useOxygen">
          <mat-expansion-panel-header>
            <mat-panel-title><h6>Oxygen</h6></mat-panel-title>
            @if (!useOxygen) {
              <mat-panel-description>
                Module not selected
              </mat-panel-description>
            }
          </mat-expansion-panel-header>
          <div id="oxygen--container">
            <form>
              <app-icon-underlined-form-field
                [parentForm]="experimentControlsForm"
                [field]="salinityAField"
                [minValue]="minSalinity"
                [maxValue]="maxSalinity"
                [step]="salinityStep"
              ></app-icon-underlined-form-field>
              <app-icon-underlined-form-field
                [parentForm]="experimentControlsForm"
                [field]="salinityBField"
                [minValue]="minSalinity"
                [maxValue]="maxSalinity"
                [step]="salinityStep"
              ></app-icon-underlined-form-field>
              <app-icon-underlined-form-field
                [parentForm]="experimentControlsForm"
                [field]="salinityCField"
                [minValue]="minSalinity"
                [maxValue]="maxSalinity"
                [step]="salinityStep"
              ></app-icon-underlined-form-field>
              <app-icon-underlined-form-field
                [parentForm]="experimentControlsForm"
                [field]="salinityDField"
                [minValue]="minSalinity"
                [maxValue]="maxSalinity"
                [step]="salinityStep"
              ></app-icon-underlined-form-field>
            </form>
          </div>
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="false">
          <mat-expansion-panel-header>
            <mat-panel-title><h6>Fluorescence</h6></mat-panel-title>
            @if (!useFluorescence) {
              <mat-panel-description>
                Module not selected for fluorescence measurement (LED controls
                still available)
              </mat-panel-description>
            }
          </mat-expansion-panel-header>
          <div id="fluo--container">
            <form>
              <div class="control--container">
                <app-slide-toggle
                  [parentForm]="experimentControlsForm"
                  [field]="led1OnField"
                ></app-slide-toggle>
                <app-icon-underlined-form-field
                  [parentForm]="experimentControlsForm"
                  [field]="led1IntensityField"
                  [minValue]="minLEDIntensity"
                  [maxValue]="maxLEDIntensity"
                  [step]="ledIntensityStep"
                ></app-icon-underlined-form-field>
              </div>
              <div class="control--container">
                <app-slide-toggle
                  [parentForm]="experimentControlsForm"
                  [field]="led2OnField"
                ></app-slide-toggle>
                <app-icon-underlined-form-field
                  [parentForm]="experimentControlsForm"
                  [field]="led2IntensityField"
                  [minValue]="minLEDIntensity"
                  [maxValue]="maxLEDIntensity"
                  [step]="ledIntensityStep"
                ></app-icon-underlined-form-field>
              </div>
              <div class="control--container">
                <app-slide-toggle
                  [parentForm]="experimentControlsForm"
                  [field]="led3OnField"
                ></app-slide-toggle>

                <app-icon-underlined-form-field
                  [parentForm]="experimentControlsForm"
                  [field]="led3IntensityField"
                  [minValue]="minLEDIntensity"
                  [maxValue]="maxLEDIntensity"
                  [step]="ledIntensityStep"
                ></app-icon-underlined-form-field>
              </div>
            </form>
          </div>
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="true">
          <mat-expansion-panel-header>
            <mat-panel-title><h6>Notes</h6></mat-panel-title>
          </mat-expansion-panel-header>
          <div id="notes--container">
            <form (ngSubmit)="handleSubmit()">
              <app-underlined-textarea-field
                [parentForm]="experimentControlsForm"
                [field]="noteField"
              ></app-underlined-textarea-field>
            </form>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
      <div id="button--container">
        <app-button
          label="reset"
          type="button"
          buttonId="reset-btn"
          buttonStyle="yellow"
          [isDisabled]="isProcessing"
          (buttonClicked)="resetForm()"
        ></app-button>
        <app-button
          label="save"
          type="submit"
          buttonId="save-btn"
          (buttonClicked)="handleSubmit()"
          [isProcessing]="isProcessing"
          [isDisabled]="
            isProcessing ||
            deleteIsProcessing ||
            draftIsLocked ||
            experimentControlsForm.invalid ||
            (experimentControlsForm.pristine && moduleSelectForm.pristine)
          "
        ></app-button>
        <app-button
          label="next"
          type="button"
          buttonId="next-btn"
          buttonStyle="positive"
          [isDisabled]="
            nextButtonDisabled ||
            !experimentControlsForm.pristine ||
            !moduleSelectForm.pristine
          "
          (buttonClicked)="onNext()"
        ></app-button>
      </div>
      @if (changelogList.length > 0) {
        <div id="history--container">
          <h6>History</h6>
          <p id="table-description" style="display: none">Changelog table</p>
          <table aria-describedby="table-description">
            <thead>
              <tr>
                <th
                  id="date"
                  scope="col"
                  (click)="onSort('CreatedAt')"
                  [class.active]="currentSort.sortBy === 'CreatedAt'"
                >
                  <div id="table-header">
                    Date
                    <mat-icon
                      aria-label="sort direction"
                      matTooltip="Sort direction"
                      >swap_vert</mat-icon
                    >
                  </div>
                </th>
                <th
                  id="note"
                  scope="col"
                  (click)="onSort('NoteText')"
                  [class.active]="currentSort.sortBy === 'NoteText'"
                >
                  <div id="table-header">
                    Note
                    <mat-icon
                      aria-label="sort direction"
                      matTooltip="Sort direction"
                      >swap_vert</mat-icon
                    >
                  </div>
                </th>
                <th
                  id="change"
                  scope="col"
                  (click)="onSort('ChangeText')"
                  [class.active]="currentSort.sortBy === 'ChangeText'"
                >
                  <div id="table-header">
                    Logged Change
                    <mat-icon
                      aria-label="sort direction"
                      matTooltip="Sort direction"
                      >swap_vert</mat-icon
                    >
                  </div>
                </th>
                <th
                  id="user"
                  scope="col"
                  (click)="onSort('CreatedBy')"
                  [class.active]="currentSort.sortBy === 'CreatedBy'"
                >
                  <div id="table-header">
                    User
                    <mat-icon
                      aria-label="sort direction"
                      matTooltip="Sort direction"
                      >swap_vert</mat-icon
                    >
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              @for (log of changelogList; track log.id) {
                <tr>
                  <td headers="date">
                    {{ log.createdAt | date: "dd/MM/YY - h:mm a" }}
                  </td>
                  <td headers="note">{{ log.noteText }}</td>
                  <td headers="change">
                    {{ log.changeText }}
                  </td>
                  <td headers="user">{{ log.createdBy }}</td>
                </tr>
              }
            </tbody>
          </table>
          <div id="pagination--container">
            <app-dropdown-form-field
              id="changelogs-per-page"
              [field]="changeLogsPerPageField"
              [parentForm]="paginationControls"
            >
            </app-dropdown-form-field>
            <div id="pagination--controls">
              <app-icon-button
                iconName="chevron_left"
                buttonId="previous"
                (buttonClicked)="getPrevious()"
                [isDisabled]="!hasPrevious"
              ></app-icon-button>
              <span> {{ currentPage }} / {{ totalPages }} </span>
              <app-icon-button
                iconName="chevron_right"
                buttonId="next"
                (buttonClicked)="getNext()"
                [isDisabled]="!hasNext"
              ></app-icon-button>
            </div>
          </div>
        </div>
      }
    </div>
  </div>
} @else {
  <app-error-page
    title="No Lab found"
    primaryText="Sorry, you cannot access this page unless you are a member of a lab"
  ></app-error-page>
}
<app-modal
  id="create-experiment"
  modalStyle="success"
  titleText="Draft saved"
  primaryText="Experiment draft created.  Do you want to select devices now?"
  buttonType="double-button"
  primaryButtonText="next"
  primaryButtonId="next-button"
  secondaryButtonText="close"
  secondaryButtonId="close-create-button"
  (modalButtonClicked)="modalButtonClicked($event)"
></app-modal>
<app-modal
  id="update-experiment"
  modalStyle="success"
  titleText="Success"
  primaryText="Experiment updated."
  buttonType="double-button"
  primaryButtonText="next"
  primaryButtonId="next-button"
  secondaryButtonText="close"
  secondaryButtonId="close-update-button"
  (modalButtonClicked)="modalButtonClicked($event)"
></app-modal>
<app-modal
  id="error"
  modalStyle="error"
  titleText="Error"
  [primaryText]="error?.message"
  buttonType="single-button"
  primaryButtonText="close"
  primaryButtonId="close-error-button"
  modalStyle="error"
  (modalButtonClicked)="modalButtonClicked($event)"
></app-modal>
<app-modal
  id="attention"
  modalStyle="warning"
  titleText="Attention"
  primaryText="Your changes have not been saved. Press cancel to return to the form and save your changes, or continue to discard."
  buttonType="double-button"
  primaryButtonText="Continue"
  primaryButtonId="confirm-navigation"
  secondaryButtonText="Cancel"
  secondaryButtonId="cancel-button"
  (modalButtonClicked)="modalButtonClicked($event)"
></app-modal>
<app-modal
  id="device-warning"
  modalStyle="warning"
  titleText="Attention"
  primaryText="This module is not supported on one or more of your devices. If you choose this module your devices will be deleted and you will need to select new devices."
  primaryButtonText="close"
  primaryButtonId="close-device-warning-button"
  (modalButtonClicked)="modalButtonClicked($event)"
></app-modal>
<app-validation-modal
  id="delete-draft"
  titleText="Delete draft?"
  validationText="delete"
  buttonType="double-button"
  primaryButtonText="Confirm delete"
  primaryButtonId="confirm-delete-button"
  (modalButtonClicked)="deleteDraft()"
  secondaryButtonText="Cancel"
  secondaryButtonId="cancel-delete-button"
  [error]="deleteError"
></app-validation-modal>
<app-modal
  id="delete-success"
  titleText="Success"
  primaryText="Experiment draft was deleted."
  primaryButtonText="Go to dashboard"
  primaryButtonId="delete-success-button"
  modalStyle="success"
  (modalButtonClicked)="modalButtonClicked($event)"
  allowClose="false"
>
</app-modal>
<app-modal
  id="delete-error"
  titleText="Unable to delete draft"
  [primaryText]="deleteError?.message + '. Choose an action below'"
  buttonType="double-button"
  primaryButtonText="Go to dashboard"
  primaryButtonId="error-primary-button"
  secondaryButtonText="Close"
  secondaryButtonId="error-secondary-button"
  modalStyle="error"
  (modalButtonClicked)="modalButtonClicked($event)"
  allowClose="false"
>
</app-modal>
