<div class="checkbox__container" [formGroup]="parentForm">
  <div class="device-title subtitle2">
    @for (title of checkboxTitles; track title) {
      <span>{{ title }}</span>
    }
  </div>
  <div class="checkbox-options__container">
    @for (option of field.options; track option.key) {
      <label
        [for]="option.key"
        class="checkbox--label body1"
        [class.disabled]="isDisabled"
      >
        <input
          type="checkbox"
          [id]="option.key"
          [attr.name]="option.key"
          class="body1"
          (change)="updateValue($event)"
          [attr.checked]="initialiseCheckboxes(i)"
          [disabled]="isDisabled"
        />
        <div class="checkmark--background">
          <div class="checkmark">
            <mat-icon>done</mat-icon>
          </div>
        </div>
        {{ option.value }}
      </label>
    }
  </div>
  @if (hasErrors) {
    <ul>
      @for (error of errorsList; track error) {
        <li class="underlined-form-field-error caption error">
          {{ error }}
        </li>
      }
    </ul>
  }
</div>
