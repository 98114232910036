import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormatCamelCasePipe } from '../../../_helpers/format-camel-case.pipe';

@Component({
  selector: 'app-status-indicator',
  templateUrl: './status-indicator.component.html',
  styleUrls: ['./status-indicator.component.scss'],
  standalone: true,
  imports: [FormatCamelCasePipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatusIndicatorComponent {
  /**
   * @param {string} status The status to be displayed
   */
  @Input()
  status: string;

  public get classes(): string[] {
    const cleanClass = this.status?.toLowerCase().split(' ').join('');
    return ['chip', `chip--${cleanClass}`, 'body1'];
  }
}
