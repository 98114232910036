<label
  [ngClass]="classes"
  [for]="field.key"
  [formGroup]="parentForm"
  [attr.aria-label]="field.key"
>
  <select
    [ngClass]="selectClasses"
    [formControlName]="field.key"
    [id]="field.key"
  >
    <option disabled selected [value]="field.value">
      {{ field.placeholder }}
    </option>
    <option *ngFor="let option of field.options" [ngValue]="option.key">
      {{ option.value }}
    </option>
  </select>
</label>
