<img id="logo" src="assets/img/ogi-logo.png" alt="" />
<div id="page">
  <div id="container">
    <h4>Forgot Password</h4>
    <form [formGroup]="resetForm" (ngSubmit)="onSubmit()">
      <app-mat-form-field
        iconName="email"
        [field]="emailField"
        [parentForm]="resetForm"
      >
      </app-mat-form-field>
      <span class="body2 error">
        <span *ngIf="requestError.message">Error: </span
        >{{ requestError.message }}
      </span>
      <div id="button--container">
        <app-button
          type="submit"
          buttonStyle="standard"
          label="Submit"
          buttonId="Submit"
          hasFill="true"
          (buttonClicked)="onSubmit()"
          [isDisabled]="resetForm.invalid"
          [isProcessing]="isProcessing"
        >
        </app-button>
      </div>
      <a [routerLink]="'/login'" class="button-styling">Return to login</a>
    </form>
  </div>
</div>
