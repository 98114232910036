import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormBase } from 'src/app/_models/form-base';
import { MatSlideToggle } from '@angular/material/slide-toggle';

@Component({
  selector: 'app-slide-toggle',
  templateUrl: './slide-toggle.component.html',
  styleUrls: ['./slide-toggle.component.scss'],
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, MatSlideToggle],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SlideToggleComponent {
  /**
   * @param {FormBase<string>} field Form field base
   * @requires
   */
  @Input()
  field: FormBase<string>;

  /**
   * @param {FormGroup} parentForm The parent form
   * @requires
   */
  @Input()
  parentForm: FormGroup = new FormGroup({});
}
