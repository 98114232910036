import { Component, Input } from '@angular/core';
import { Role } from 'src/app/_models/role';

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss'],
})
export class ErrorPageComponent {
  Role = Role;

  /**
   * @param {string} title Title
   * @requires
   */
  @Input()
  title: string;

  /**
   * @param {string} primaryText Primary text
   * @requires
   */
  @Input()
  primaryText: string;
}
