import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormBase } from 'src/app/_models/form-base';
import { ERROR_MESSAGE } from 'src/app/_helpers/utils';

@Component({
  selector: 'app-icon-underlined-form-field',
  templateUrl: './icon-underlined-form-field.component.html',
  styleUrls: ['./icon-underlined-form-field.component.scss'],
})
export class IconUnderlinedFormFieldComponent implements OnInit {
  /**
   * @param {FormBase<string>} field Form field base
   * @requires
   */
  @Input()
  field: FormBase<string>;

  /**
   * @param {FormGroup} parentForm The parent form
   * @requires
   */
  @Input()
  parentForm: FormGroup = new FormGroup({});

  /**
   * @param {number} minValue Min value for number field
   */
  @Input()
  minValue: number;

  /**
   * @param {number} maxValue Max value for number field
   */
  @Input()
  maxValue: number;

  /**
   * @param {number} step Intervals for number field
   */
  @Input()
  step: number;

  /**
   * @param {string} warningMessage Warning message for number field
   */
  @Input()
  warningMessage: string;

  /**
   * @param {boolean} showWarning Show warning for number field
   */
  @Input()
  showWarning = false;

  /**
   * Output minus click event for number fields
   */
  @Output()
  minusButtonClick: EventEmitter<any> = new EventEmitter();

  /**
   * Output plus click event for number fields
   */
  @Output()
  plusButtonClick: EventEmitter<any> = new EventEmitter();

  showing = false;
  sliderValue: number;

  ngOnInit(): void {
    // Set initial value
    this.sliderValue = this.parentForm.controls[this.field.key].value as number;
    // Subscribe to value change (i.e when form is reset)
    this.parentForm.get(this.field.key).valueChanges.subscribe((value) => {
      this.sliderValue = value as number;
    });
  }

  public get classes(): string[] {
    const disabled = this.parentForm.controls[this.field.key].disabled
      ? 'disabled'
      : '';
    const error = this.hasErrors ? 'error' : '';
    const validated =
      !this.hasErrors && this.parentForm.controls[this.field.key]?.touched
        ? 'validated'
        : '';
    return ['underlined-form-field__container', disabled, error, validated];
  }

  public get hasErrors(): boolean {
    return (
      this.parentForm.controls[this.field.key] &&
      this.parentForm.controls[this.field.key]?.errors &&
      this.parentForm.controls[this.field.key]?.touched
    );
  }

  public get errorsList(): string[] {
    return Object.keys(this.parentForm.controls[this.field.key].errors).map(
      (err: string) =>
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        ERROR_MESSAGE[err](
          this.parentForm.controls[this.field.key].getError(err)
        ) as string
    );
  }

  public get showHideText(): string {
    return this.showing ? 'Hide' : 'Show';
  }

  public get inputType(): string {
    if (this.field.type === 'password') {
      return this.showing ? 'text' : 'password';
    } else {
      return this.field.type;
    }
  }

  togglePassword(): void {
    if (!this.field.disabled) {
      this.showing = !this.showing;
    }
  }

  minusButtonClicked(): void {
    this.minusButtonClick.emit();
  }

  plusButtonClicked(): void {
    this.plusButtonClick.emit();
  }
}
