<div *ngIf="!isLoading" id="title--container">
  <h4>{{ organisation?.organisationName }}</h4>
  <div class="button--container">
    <app-icon-button
      iconName="add_circle"
      buttonId="add-lab-btn"
      buttonStyle="secondary"
      (buttonClicked)="addLabButtonClicked()"
      buttonShape="square"
      label="Add Laboratory"
      routerLink="/laboratories/add-lab"
      [isDisabled]="
        currentUser.role
          | roleCheck
            : [
                Role.Read_Access_User,
                Role.Run_Access_User,
                Role.Edit_Access_User,
                Role.Lab_Administrator,
                Role.OGI_Laboratory_Administrator
              ]
      "
    >
    </app-icon-button>
    <app-icon-button
      label="delete organisation"
      buttonStyle="negative"
      buttonId="delete-org-btn"
      iconName="delete"
      buttonShape="square"
      (buttonClicked)="openModal('delete-organisation')"
      [isDisabled]="
        currentUser.role
          | roleCheck
            : [
                Role.Read_Access_User,
                Role.Run_Access_User,
                Role.Edit_Access_User,
                Role.Lab_Administrator,
                Role.OGI_Laboratory_Administrator
              ]
      "
    ></app-icon-button>
  </div>
</div>
<hr *ngIf="!isLoading" />
<div *ngIf="!isLoading" id="org--container">
  <div class="form--container">
    <form [formGroup]="editOrgForm">
      <app-underlined-form-field
        [parentForm]="editOrgForm"
        [field]="orgNameField"
      ></app-underlined-form-field>
    </form>
    <div class="button--container">
      <app-button
        label="Update Organisation"
        buttonId="update-btn"
        (buttonClicked)="onSubmit()"
        [isDisabled]="
          isProcessing || editOrgForm.invalid || editOrgForm.pristine
        "
        [isProcessing]="isProcessing"
      >
      </app-button>
    </div>
  </div>
  <div id="org-details--container">
    <div>
      <span class="subtitle2">License type: </span>
      <span> {{ LicenseType[organisation.licenseType] }}</span>
    </div>
    <div>
      <span class="subtitle2">Number of devices: </span>
      <span>{{ organisation?.numberOfDevices }}</span>
    </div>
    <div>
      <span class="subtitle2">Number of users: </span>
      <span>{{ organisation?.numberOfUsers }}</span>
    </div>
  </div>
  <h6>Laboratories</h6>
  <p id="table-description" style="display: none">Laboratories table</p>
  <div id="table--container">
    <table aria-describedby="table-description">
      <thead>
        <tr>
          <th id="lab-name" scope="col">
            <div id="table-header">
              <span>Name</span>
            </div>
          </th>
          <th id="error-log-count" scope="col">
            <div id="table-header">
              <span>Total Error Log Count</span>
            </div>
          </th>
          <th id="unviewed-error-log-count" scope="col">
            <div id="table-header">
              <span>Unviewed Error Log Count</span>
            </div>
          </th>
          <!-- Only show if current user is a super admin -->
          @if (currentUser.role | roleCheck: [Role.OGI_Super_Administrator]) {
          <th id="is-deleted" scope="col">
            <div id="table-header">
              <span>Is Deleted</span>
            </div>
          </th>
          }
          <th>View Lab</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let lab of organisation?.labSummary">
          <td>{{ lab.laboratoryName }}</td>
          <td>{{ lab.totalErrorLogCount }}</td>
          <td>{{ lab.unviewedErrorLogCount }}</td>
          <!-- Only show if current user is a super admin -->
          @if (currentUser.role | roleCheck: [Role.OGI_Super_Administrator]) {
          <td>{{ lab.isDeleted }}</td>
          }
          <td>
            <app-icon-button
              buttonId="view-lab-btn"
              buttonStyle="transparent"
              buttonShape="square"
              iconName="arrow_forward"
              (buttonClicked)="onViewLab(lab)"
            ></app-icon-button>
          </td>
        </tr>
        <tr *ngIf="organisation?.labSummary.length === 0" class="no-labs-row">
          <td colspan="3">No labs</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<ng-template #loadingSpinner>
  <div id="loading-spinner"><app-loading-spinner></app-loading-spinner></div>
</ng-template>
<app-modal
  id="success"
  titleText="Success"
  [primaryText]="successModalPrimaryText"
  buttonType="single-button"
  primaryButtonText="close"
  primaryButtonId="close-button"
  modalStyle="success"
  (modalButtonClicked)="modalButtonClicked($event)"
></app-modal>
<app-modal
  id="update-error"
  titleText="Error"
  [primaryText]="error?.message"
  buttonType="single-button"
  primaryButtonText="close"
  primaryButtonId="close-button"
  modalStyle="error"
  (modalButtonClicked)="modalButtonClicked($event)"
></app-modal>
<app-validation-modal
  id="delete-organisation"
  titleText="Delete organisation"
  validationText="delete"
  buttonType="double-button"
  primaryButtonText="Delete"
  primaryButtonId="primary-button"
  secondaryButtonText="Close"
  secondaryButtonId="secondary-button"
  (modalButtonClicked)="deleteOrganisation()"
  [error]="error"
></app-validation-modal>
