<div id="page">
  <div id="container">
    <app-svg-template
      [iconName]="logo"
      width="249"
      height="60"
      customViewBox="0 0 249 60"
    ></app-svg-template>
    <h5>
      {{ tokenTitle }}
      <span *ngIf="tokenStatus === 0" class="validating">...</span>
    </h5>
    <p class="subtitle1 error" *ngIf="tokenStatus === 2">
      {{ requestError.message }}
    </p>
    <app-button
      *ngIf="tokenStatus === 2"
      [label]="'Request new reset link'"
      buttonId="forgot-password"
      hasFill="true"
      [routerLink]="'/forgot-password'"
    ></app-button>
    <div *ngIf="tokenStatus === 1" id="form--container">
      <span class="subtitle1 error">
        <span *ngIf="resetError.message">Error: </span>{{ resetError.message }}
      </span>
      <form [formGroup]="resetForm" (ngSubmit)="onSubmit()">
        <app-underlined-form-field
          iconName="lock"
          [field]="passwordField"
          [parentForm]="resetForm"
        ></app-underlined-form-field>
        <app-underlined-form-field
          iconName="lock"
          [field]="confirmPasswordField"
          [parentForm]="resetForm"
        >
        </app-underlined-form-field>
        <app-button
          type="submit"
          [label]="'Reset Password'"
          buttonId="submit-btn"
          [isDisabled]="resetForm.invalid"
          [isProcessing]="isProcessing"
        ></app-button>
      </form>
    </div>
    <app-button
      [label]="'Back to Login'"
      buttonId="login-btn"
      routerLink="'/login'"
    ></app-button>
  </div>
</div>
