export class FormBase<T> {
  value: T;
  key: string;
  label: string;
  required: boolean;
  type: string;
  options: { key: string | number; value: string; options?: string[] }[]; // If field is dropdown or checkbox group
  placeholder: string;
  disabled: boolean;
  autocomplete?: string;

  constructor(
    options: {
      value?: T;
      key?: string;
      label?: string;
      required?: boolean;
      type?: string;
      options?: { key: string | number; value: string; options?: string[] }[];
      placeholder?: string;
      disabled?: boolean;
      autocomplete?: string;
    } = {},
  ) {
    this.value = options.value;
    this.key = options.key;
    this.label = options.label || '';
    this.required = !!options.required;
    this.type = options.type || '';
    this.options = options.options || [];
    this.placeholder = options.placeholder || '';
    this.disabled = !!options.disabled;
    this.autocomplete = options.autocomplete || 'off';
  }
}
