import { Component, OnInit, inject } from '@angular/core';
import {
  FormBuilder,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { DeviceCreateResponse } from 'src/app/_models/api-responses';
import { Device } from 'src/app/_models/device';
import { ErrorObject } from 'src/app/_models/error';
import { FormBase } from 'src/app/_models/form-base';
import { Laboratory } from 'src/app/_models/laboratory';
import { Role } from 'src/app/_models/role';
import { User } from 'src/app/_models/user';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { DeviceService } from 'src/app/_services/device.service';
import { ModalService } from 'src/app/_services/modal.service';
import { ModalComponent } from '../../_components/modals/modal.component';
import { ErrorPageComponent } from '../../_components/error-page/error-page.component';
import { IconButtonComponent } from '../../_components/buttons/icon-button/icon-button.component';
import { IconUnderlinedFormFieldComponent } from '../../_components/forms/icon-underlined-form-field/icon-underlined-form-field.component';

@Component({
  selector: 'app-add-device',
  templateUrl: './add-device.component.html',
  styleUrls: ['./add-device.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    IconUnderlinedFormFieldComponent,
    IconButtonComponent,
    ErrorPageComponent,
    ModalComponent,
  ],
})
export class AddDeviceComponent implements OnInit {
  private readonly deviceService = inject(DeviceService);
  private readonly formBuilder = inject(FormBuilder);
  private readonly router = inject(Router);
  private readonly modalService = inject(ModalService);
  private readonly authenticationService = inject(AuthenticationService);

  // Control vars
  error: ErrorObject;
  isProcessing = false;
  currentUser: User;
  selectedLaboratory: Laboratory;
  addDeviceSuccessMessage: string;

  allowNavigation: Subject<boolean> = new Subject<boolean>();

  nameField = new FormBase({
    key: 'name',
    label: 'Name of device',
    type: 'text',
    placeholder: 'Name',
    disabled: false,
    required: true,
    value: '',
    options: [],
  });

  macAddressField = new FormBase({
    key: 'macAddress',
    label: 'MAC Address',
    type: 'text',
    placeholder: 'MAC Address',
    disabled: false,
    required: true,
    value: '',
    options: [],
  });

  createDeviceForm = this.formBuilder.group({
    name: ['', Validators.required],
    macAddress: ['', Validators.required],
  });

  ngOnInit(): void {
    // TODO: Check create device cache and instantiate form
    // Get current user
    this.currentUser = this.authenticationService.currentUserValue;
    this.selectedLaboratory = this.authenticationService.selectedLaboratory;
  }

  addDevice(): void {
    // If form is already being submitted, exit
    if (this.isProcessing) {
      return;
    }

    this.isProcessing = true;
    this.addDeviceSuccessMessage = '';
    const createDevice = new Device(
      undefined,
      this.createDeviceForm.value.name,
      this.selectedLaboratory.laboratoryId,
      this.createDeviceForm.value.macAddress,
    );
    this.deviceService.createDevice(createDevice).subscribe({
      next: (data: DeviceCreateResponse) => {
        this.isProcessing = false;
        this.createDeviceForm.markAsPristine();
        if (
          this.currentUser.role === Role.OGI_Super_Administrator &&
          !this.selectedLaboratory.isOwnLab
        ) {
          this.addDeviceSuccessMessage = data.message;
          this.openModal('change-request-success');
        } else {
          void this.router.navigate([`/device/${data.id}/details`]);
        }
      },
      error: (error: ErrorObject) => {
        this.isProcessing = false;
        this.error = error;
        this.openModal('error');
      },
    });
  }

  modalButtonClicked(buttonId: string): void {
    switch (buttonId) {
      case 'close-button':
        this.modalService.close('error');
        break;
      case 'close-approval':
        this.createDeviceForm.reset();
        this.modalService.close('change-request-success');
        break;
      case 'cancel-button':
        this.allowNavigation.next(false);
        this.modalService.close('attention');
        break;
      case 'confirm-navigation':
        this.allowNavigation.next(true);
        this.modalService.close('attention');
        break;
    }
  }

  openModal(modalId: string): void {
    this.modalService.open(modalId);
  }

  canExit(): Promise<boolean> | boolean {
    return this.isNavigationAllowed();
  }

  private isNavigationAllowed(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      if (this.createDeviceForm.pristine) {
        resolve(true);
      } else {
        this.openModal('attention');
        this.allowNavigation.subscribe((isConfirmed) => resolve(isConfirmed));
      }
    });
  }
}
