/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import * as d3 from 'd3';
import * as d3Regression from 'd3-regression';
import { AxisDomain, AxisScale, select, style, zoom } from 'd3';
import {
  ICalibrationData,
  ICalibrationDevice,
  ICalibrationFlask,
  IOpticalDensityVoltage,
} from 'src/app/_models/experiment-data';

@Component({
  selector: 'app-calibration-graph',
  templateUrl: './calibration-graph.component.html',
  styleUrls: ['./calibration-graph.component.scss'],
})
export class CalibrationGraphComponent implements OnInit, AfterViewInit {
  // colour scale
  private colours: string[] = [
    '#6ECF9C',
    '#6C73C1',
    '#CE517E',
    '#37494A',
    '#D2493A',
    '#BE4FC1',
    '#CB9F50',
    '#7FCE4C',
    '#9CADB7',
    '#496A35',
    '#6A3A2B',
    '#553056',
  ];

  private svg;
  private margin = {
    left: 40,
    right: 5,
    top: 10,
    bottom: 30,
  };

  // Set to large by default
  private width = 1366 - (this.margin.left + this.margin.right);
  private height = 768 - (this.margin.top + this.margin.bottom);

  private dataToRender: IRenderData[] = [];
  private labelList: IPropertyColour[] = [];

  /**
   * @param {string} graphId The ID of the graph to be displayed
   * @description This is used to set the ID of the graph container
   * so that it can be referenced by the zoom function
   * @example 'scatter'
   */
  @Input()
  graphId = 'scatter';

  /**
   * @param {string} graphTitle The title of the graph to be displayed
   */
  @Input()
  graphTitle = 'Title Here';

  /**
   * @param {string} yAxisLabel The y-axis label to be used on the graph
   */
  @Input()
  yAxisLabel = 'Y Axis Label';

  /**
   * @param {ICalibrationData} calibrationData The calibration data from the API to be graphed
   */
  @Input()
  calibrationData: ICalibrationData;

  /**
   * @param {string} screenSize The rough size of screen
   * @description This is used to determine the width and height of the graph to be displayed
   */
  @Input()
  screenSize: 'small' | 'medium' | 'large' = 'large';

  ngOnInit(): void {
    // Set width and height based on screen size
    switch (this.screenSize) {
      case 'small':
        this.width = 400 - (this.margin.left + this.margin.right);
        this.height = 350 - (this.margin.top + this.margin.bottom);
        break;
      case 'medium':
        this.width = 650 - (this.margin.left + this.margin.right);
        this.height = 500 - (this.margin.top + this.margin.bottom);
        break;
      case 'large':
        this.width = 940 - (this.margin.left + this.margin.right);
        this.height = 600 - (this.margin.top + this.margin.bottom);
        break;
    }
  }

  ngAfterViewInit(): void {
    this.createSvg();
    this.parseData(this.calibrationData);
  }

  private createSvg = (): void => {
    // Set the styling on the container to relative for the absolutely positioned tooltips
    d3.select('figure').style('position', 'relative');
    // Create the svg to hold the graph and append to the component
    this.svg = d3
      .select(`figure#${this.graphId}`)
      .append('svg')
      // ET - could replace the below width and height assignments with viewbox setting instead
      // if this works better for responsiveness
      // Set the dimensions and margins of the graph
      .attr('width', this.width + (this.margin.left + this.margin.right))
      .attr('height', this.height + this.margin.top + this.margin.bottom)
      .append('g')
      .attr(
        'transform',
        'translate(' + this.margin.left + ',' + this.margin.right + ')'
      );
    // Add a clip path: everything out of this area won't be drawn.
    this.svg
      .append('defs')
      .append('svg:clipPath')
      .attr('id', 'clip')
      .append('svg:rect')
      .attr('width', this.width)
      .attr('height', this.height)
      .attr('x', 0)
      .attr('y', 0);
    // Rectangle to capture pointer events for zoom
    this.svg
      .append('rect')
      .attr('width', this.width)
      .attr('height', this.height)
      .style('fill', 'none')
      .style('pointer-events', 'all');
    // Now let's add zoom to the svg element
    this.svg.call(
      d3
        .zoom()
        .extent([
          [0, 0],
          [
            // this.width + this.margin.left,
            // this.height - (this.margin.bottom + this.margin.top),
            this.width,
            this.height,
          ],
        ])
        .scaleExtent([1, 10])
        .on('zoom', this.updateChart)
    );
  };

  updateChart = (event): void => {
    d3.select(`figure#${this.graphId} > svg > g`).attr(
      'transform',
      event.transform
    );
    // TODO: continue refactoring this function to scale the axes when zooming
    // Issue exists where the use of a const on the axes causes the redraw of the
    // dots to fall over
    // const newX = event.transform.rescaleX(this.x);
    // const newY = event.transform.rescaleY(this.y);
    // this.svg.select('.x-axis').call(d3.axisBottom(newX));
  };

  parseData = (calibrationData: ICalibrationData): void => {
    let colourIndex = 0;
    const property = calibrationData.dataType;
    const deviceList: ICalibrationDevice[] = calibrationData.devices;
    deviceList?.forEach((dataSet, index) => {
      dataSet.flasks.forEach((flask: ICalibrationFlask) => {
        const deviceFlaskProperty = `${dataSet.deviceId}_${flask.name}_${property}`;
        flask.values?.forEach((calibrationValue: IOpticalDensityVoltage) => {
          const y = calibrationValue.opticalDensity;
          const x = calibrationValue.voltage;
          this.dataToRender.push({
            DeviceFlaskProperty: deviceFlaskProperty,
            OpticalDensity: y,
            Voltage: x,
            DeviceName: dataSet.deviceId.toString(),
            FlaskName: flask.name,
          });
        });
        this.labelList.push({
          deviceFlaskProperty,
          colour: this.colours[colourIndex],
        });
        colourIndex++;
        // If we've exceeded the colour sets available, reset and reuse
        if (colourIndex === 11) {
          colourIndex = 0;
        }
      });
      if (calibrationData.devices.length === index + 1) {
        // Now data is ready and formatted, let's render the graph
        this.drawScatterGraph();
      }
    });
  };

  drawScatterGraph = (): void => {
    // First create x-axis
    const x: AxisScale<AxisDomain> = d3
      .scaleLinear()
      .domain(d3.extent(this.dataToRender, (d) => d.Voltage))
      .nice()
      .range([this.margin.left, this.width - this.margin.right]);

    // Now create the y axis
    const y: AxisScale<AxisDomain> = d3
      .scaleLinear()
      .domain(d3.extent(this.dataToRender, (d) => d.OpticalDensity))
      .nice()
      .range([this.height - this.margin.bottom, this.margin.top]);

    // Attach the x-axis to the svg group
    const vertTranslation = this.height - this.margin.bottom;
    this.svg
      .append('g')
      .attr('transform', `translate(0, ${vertTranslation})`)
      .call(d3.axisBottom(x))
      .append('text')
      .attr('x', this.width / 2)
      .attr('y', this.margin.bottom + this.margin.top)
      .attr('fill', '#000000')
      .attr('text-anchor', 'middle')
      .text('Voltage [v]')
      .style('font-family', '$space-grotesk')
      .style('font-weight', '300')
      .style('font-size', '2rem');

    // Attach the y-axis to the svg group
    const horzOffset = this.margin.left + 10;
    const vertOffset = this.height / 2 - (this.margin.bottom + this.margin.top);
    // ET - TODO: revisit implementing previous yAxis func
    this.svg
      .append('g')
      .attr('transform', `translate(${this.margin.left}, 0)`)
      .call(d3.axisLeft(y))
      .append('text')
      .attr('x', -this.margin.left)
      .attr('y', 10)
      .attr('fill', '#000000')
      .attr('text-anchor', 'middle')
      .attr('transform', `translate(-${horzOffset}, ${vertOffset}) rotate(-90)`)
      .style('font-family', '$space-grotesk')
      .style('font-weight', '300')
      .style('font-size', '2rem')
      .text(this.yAxisLabel);

    // Colour set up - can't pass in objects so need flat lists
    const domainScale = [];
    const rangeColour = [];

    this.labelList.forEach((element) => {
      domainScale.push(element.deviceFlaskProperty);
      rangeColour.push(element.colour);
    });
    const colour = d3.scaleOrdinal().domain(domainScale).range(rangeColour);

    // Now let's build the tooltip, hidden by default (with opacity 0)
    const tooltip = d3
      .select(`figure#${this.graphId}`)
      .append('div')
      .style('opacity', 0)
      .attr('class', 'body3')
      .attr('class', 'tooltip')
      .style('background-color', '#B4DFFF')
      .style('border', 'solid')
      .style('border-width', '1px')
      .style('border-radius', '5px')
      .style('border-color', '#0C7BB4')
      .style('padding', '10px')
      .style('font-family', '$space-grotesk')
      .style('font-weight', '300')
      .style('font-size', '1rem')
      .style('position', 'absolute');

    // And when the user hovers over a point, let's make it visible
    const mouseover = function (e: MouseEvent) {
      tooltip.style('opacity', 1);
      d3.select(this).style('stroke', 'black').style('opacity', '1');
    };

    // Insert text into the tooltip and position it relative to the point hovered over
    const mousemove = function (e: MouseEvent, d: IRenderData) {
      tooltip
        .html(
          `Property: ${d.DeviceFlaskProperty} <br> Optical Density: ${d.OpticalDensity} <br> Voltage: ${d.Voltage} <br> Device: ${d.DeviceName} <br> Flask: ${d.FlaskName}`
        )
        .style('left', e.offsetX + 20 + 'px') //offset the tooltip to prevent clash with point
        .style('top', e.offsetY + 'px');
    };

    // Now hide the tooltip when the mouse leaves hoverover
    const mouseleave = function () {
      tooltip.style('opacity', 0);
      d3.select(this).style('stroke', 'none').style('opacity', 0.8);
    };

    // Now add the dots
    const dots = this.svg.append('g').attr('clip-path', 'url(#clip)'); //added clip path attribute to prevent dots from being drawn outside of the graph area
    dots
      .selectAll('circle')
      .data(this.dataToRender)
      .enter()
      .append('circle')
      .attr('cx', function (d) {
        return x(d.Voltage);
      })
      .attr('cy', function (d) {
        return y(d.OpticalDensity);
      })
      .attr('r', 10) // update to either use const or allow input of radius size value desired
      .style('fill', function (d) {
        return colour(d.DeviceFlaskProperty);
      })
      .on('mouseover', mouseover)
      .on('mousemove', mousemove)
      .on('mouseleave', mouseleave);

    // Now let's draw our line of best fit per flask
    // Flask A Data
    const flaskAData = this.dataToRender.filter(
      (element) => element.FlaskName === 'A'
    );

    // Flask B Data
    const flaskBData = this.dataToRender.filter(
      (element) => element.FlaskName === 'B'
    );

    // Flask C Data
    const flaskCData = this.dataToRender.filter(
      (element) => element.FlaskName === 'C'
    );

    // Flask D Data
    const flaskDData = this.dataToRender.filter(
      (element) => element.FlaskName === 'D'
    );

    const linearRegressionA = d3Regression
      .regressionLinear()
      .x((d) => d.Voltage)
      .y((d) => d.OpticalDensity)
      .domain(d3.extent(flaskAData, (d) => d.Voltage));

    const linearRegressionB = d3Regression
      .regressionLinear()
      .x((d) => d.Voltage)
      .y((d) => d.OpticalDensity)
      .domain(d3.extent(flaskBData, (d) => d.Voltage));

    const linearRegressionC = d3Regression
      .regressionLinear()
      .x((d) => d.Voltage)
      .y((d) => d.OpticalDensity)
      .domain(d3.extent(flaskCData, (d) => d.Voltage));

    const linearRegressionD = d3Regression
      .regressionLinear()
      .x((d) => d.Voltage)
      .y((d) => d.OpticalDensity)
      .domain(d3.extent(flaskDData, (d) => d.Voltage));

    const line = d3
      .line()
      .x((d) => x(d[0]))
      .y((d) => y(d[1]));

    const regressionLineA = this.svg
      .append('g')
      .attr('clip-path', 'url(#clip)');
    regressionLineA
      .append('path')
      .datum(linearRegressionA(flaskAData))
      .attr('class', 'regression-line')
      .attr('d', line)
      .attr('stroke', function (d) {
        return colour(flaskAData[0].DeviceFlaskProperty);
      })
      .attr('stroke-width', 2)
      .attr('fill', 'none');

    const regressionLineB = this.svg
      .append('g')
      .attr('clip-path', 'url(#clip)');
    regressionLineB
      .append('path')
      .datum(linearRegressionB(flaskBData))
      .attr('class', 'regression-line')
      .attr('d', line)
      .attr('stroke', function () {
        return colour(flaskBData[0].DeviceFlaskProperty);
      })
      .attr('stroke-width', 2)
      .attr('fill', 'none');

    const regressionLineC = this.svg
      .append('g')
      .attr('clip-path', 'url(#clip)');
    regressionLineC
      .append('path')
      .datum(linearRegressionC(flaskCData))
      .attr('class', 'regression-line')
      .attr('d', line)
      .attr('stroke', function () {
        return colour(flaskCData[0].DeviceFlaskProperty);
      })
      .attr('stroke-width', 2)
      .attr('fill', 'none');

    const regressionLineD = this.svg
      .append('g')
      .attr('clip-path', 'url(#clip)');
    regressionLineD
      .append('path')
      .datum(linearRegressionD(flaskDData))
      .attr('class', 'regression-line')
      .attr('d', line)
      .attr('stroke', function () {
        return colour(flaskDData[0].DeviceFlaskProperty);
      })
      .attr('stroke-width', 2)
      .attr('fill', 'none');
  };
}

export interface IRenderData {
  DeviceFlaskProperty: string;
  OpticalDensity: number;
  Voltage: number;
  DeviceName: string;
  FlaskName: string;
}

export interface IPropertyColour {
  deviceFlaskProperty: string;
  colour: string;
}
