import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DeviceSetupStatus } from 'src/app/_models/device-setup-status';
import { FormatCamelCasePipe } from '../../../_helpers/format-camel-case.pipe';
import { StatusIndicatorComponent } from '../../chips/status-indicator/status-indicator.component';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'app-reduced-small-data-card',
  templateUrl: './reduced-small-data-card.component.html',
  styleUrls: ['./reduced-small-data-card.component.scss'],
  standalone: true,
  imports: [MatIcon, StatusIndicatorComponent, FormatCamelCasePipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReducedSmallDataCardComponent {
  /**
   * @param {string} title name of data category to be displayed
   * @requires
   */
  @Input()
  title: string;

  /**
   * @param {string} dataReading data reading to be displayed
   * @requires
   */
  @Input()
  dataReading: string;

  /**
   * @param {string} iconName The required icon
   */
  @Input()
  iconName: string | undefined;

  /**
   * @param {string} deviceStatus The required icon
   */
  @Input()
  deviceStatus: DeviceSetupStatus | undefined;
}
