<section [ngClass]="classes">
  <label [for]="field.key" [formGroup]="parentForm">
    <div class="body1">
      {{ field.label }}
      <span *ngIf="field.required" class="required" aria-required="true">
        *
      </span>
    </div>
    <div class="underlined-form-field-input-container">
      <div *ngIf="field.type === 'range'; else textField" class="slider">
        <mat-slider
          [formControlName]="field.key"
          [min]="minValue"
          [max]="maxValue"
          [step]="step"
          [value]="sliderValue"
          [disabled]="this.parentForm.controls[this.field.key].disabled"
          ngDefaultControl
        >
          <input matSliderThumb [value]="sliderValue" />
        </mat-slider>
        <input
          class="slider-value"
          [type]="number"
          [formControlName]="field.key"
          [step]="step"
          [max]="maxValue"
          [min]="minValue"
          [value]="sliderValue"
        />
      </div>
      <ng-template #textField>
        <mat-form-field>
          <input
            matInput
            class="body1"
            [type]="inputType"
            [formControlName]="field.key"
            [placeholder]="field.placeholder"
            [autocomplete]="field.autocomplete"
            [attr.aria-required]="field.required"
            [required]="field.required"
            [step]="step"
            [max]="maxValue"
            [min]="minValue"
            [value]="value"
            [disabled]="this.parentForm.controls[this.field.key].disabled"
          />
          <mat-icon
            *ngIf="iconName && field.type === 'password'"
            matSuffix
            (click)="togglePassword()"
            >{{ iconName }}</mat-icon
          >
          <mat-icon *ngIf="iconName && field.type !== 'password'" matSuffix>{{
            iconName
          }}</mat-icon>
          <mat-error *ngIf="showWarning">{{ warningMessage }}</mat-error>
          <mat-error *ngIf="hasErrors">{{ errorsList[0] }}</mat-error>
        </mat-form-field>
      </ng-template>
    </div>
    <div *ngIf="minValue || maxValue" class="minMaxValues" role="tooltip">
      <mat-icon>info</mat-icon>
      <div class="top">
        Min: {{ minValue }}<br />Max: {{ maxValue }}
        <i></i>
      </div>
      <div class="tooltip-accessibility">
        Min: {{ minValue }}<br />Max: {{ maxValue }}
      </div>
    </div>
  </label>
</section>
