<div class="page--container">
  <div class="header--container">
    <h4>Calibrations</h4>
    <h5>{{ laboratory?.laboratoryName }}</h5>
  </div>
  <hr />
  <div class="inner-page--container">
    <div id="toggle--container">
      <app-option-tab-toggle
        id="toggle--container"
        radioGroup="navigationToggle"
        [selectOptions]="navOptions"
        type="round"
      >
      </app-option-tab-toggle>
    </div>
    <div *ngIf="calibrations?.length > 0" id="outer-table--container">
      <p id="table-description" style="display: none">
        Optical density calibrations table
      </p>
      <div id="table--container">
        <table aria-describedby="table-description">
          <thead>
            <tr>
              <th
                id="name"
                scope="col"
                (click)="onSort('Name')"
                [ngClass]="getClasses('Name')"
              >
                <div id="table-header">
                  <span>Name</span>
                  <mat-icon
                    aria-label="sort direction"
                    matTooltip="Sort direction"
                    >swap_vert</mat-icon
                  >
                </div>
              </th>
              <th
                id="save-location-name"
                scope="col"
                (click)="onSort('SaveLocationName')"
                [ngClass]="getClasses('SaveLocationName')"
              >
                <div id="table-header">
                  <span>Save Location</span>
                  <mat-icon
                    aria-label="sort direction"
                    matTooltip="Sort direction"
                    >swap_vert</mat-icon
                  >
                </div>
              </th>
              <th
                id="created-on"
                scope="col"
                (click)="onSort('CreatedAt')"
                [ngClass]="getClasses('CreatedAt')"
              >
                <div id="table-header">
                  <span>Created On</span>
                  <mat-icon
                    aria-label="sort direction"
                    matTooltip="Sort direction"
                    >swap_vert</mat-icon
                  >
                </div>
              </th>
              <th
                id="is-advanced"
                scope="col"
                (click)="onSort('IsAdvanced')"
                [ngClass]="getClasses('IsAdvanced')"
              >
                <div id="table-header">
                  <span>Is Advanced? </span>
                </div>
              </th>
              <th
                id="is-final"
                scope="col"
                (click)="onSort('IsFinal')"
                [ngClass]="getClasses('IsFinal')"
              >
                <div id="table-header">
                  <span>Is Final ?</span>
                  <mat-icon
                    aria-label="sort direction"
                    matTooltip="Sort direction"
                    >swap_vert</mat-icon
                  >
                </div>
              </th>
              <th id="action" scope="col">
                <div id="table-header"></div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let calibration of calibrations">
              <td headers="name">{{ calibration.name }}</td>
              <td headers="save-location-name">
                {{ calibration.saveLocationName }}
              </td>
              <td headers="created-on">
                {{ calibration.createdAt | date : "HH:mm dd/MM/yy" }}
              </td>
              <td headers="is-advanced">{{ calibration.isAdvanced }}</td>
              <td headers="is-final">{{ calibration.isFinal }}</td>
              <td headers="action">
                <div id="button--container">
                  <app-button
                    [buttonId]="'view-' + calibration.id"
                    label="View"
                    (buttonClicked)="
                      viewCalibration(calibration.id, 'optical-density')
                    "
                  ></app-button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div id="pagination--container">
          <div class="filter--container">
            <label for="calibrations-per-page">Calibrations per page</label>
            <app-dropdown-form-field
              id="calibrations-per-page"
              [field]="calibrationsPerPageField"
              [parentForm]="tableControls"
            >
            </app-dropdown-form-field>
          </div>
          <div id="pagination--controls">
            <app-icon-button
              iconName="chevron_left"
              buttonId="previous"
              (buttonClicked)="getPrevious()"
              [isDisabled]="!hasPrevious"
            ></app-icon-button>
            <span> {{ currentPage }} / {{ totalPages }} </span>
            <app-icon-button
              iconName="chevron_right"
              buttonId="next"
              (buttonClicked)="getNext()"
              [isDisabled]="!hasNext"
            ></app-icon-button>
          </div>
        </div>
      </div>
    </div>
    <div
      id="cta--container"
      *ngIf="!loading && (!calibrations || calibrations.length === 0)"
    >
      <h4>No optical density calibrations detected</h4>
      <p *ngIf="error" class="error">Error: {{ error.message }}</p>
    </div>
  </div>
</div>
