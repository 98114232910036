import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
  passwordRegex,
  phoneNumberRegex,
  nameRegex,
} from 'src/app/_helpers/utils';
import { RegexValidator } from 'src/app/_helpers/regex.validator';
import { ValidatorObject } from 'src/app/_models/validators';

@Component({
  selector: 'app-checkbox-group-no-label',
  templateUrl: './checkbox-group-no-label.component.html',
  styleUrls: ['./checkbox-group-no-label.component.scss'],
})
export class CheckboxGroupNoLabelComponent {
  ERROR_MESSAGE: ValidatorObject = {
    required: (): string => `Required field`,
    requiredTrue: (): string => `Please select at least one option`,
    minLength: (par: { requiredLength: number }): string =>
      `Field must be minimum ${par.requiredLength} characters long `,
    mustMatch: (): string => `Field does not match`,
    email: (): string => `Not a valid email address`,
    pattern: (par: RegexValidator): string => {
      switch (par.requiredPattern) {
        case passwordRegex.toString():
          return 'Must contain at least one uppercase letter, one lowercase letter, one special character and one number';
        case phoneNumberRegex:
          return 'Not a valid phone number';
        case nameRegex:
          return 'Field cannot contain numbers';
        default:
          return 'Not valid';
      }
    },
    requireCheckboxesToBeChecked: (par: number): string => {
      return `At least ${par} option(s) must be selected`;
    },
    isValidDate: (): string => {
      return `Date must be in the future`;
    },
    mustBeInFuture: (): string => 'Session must start in the future',
    min: (par: { min: number; actual: string }): string => {
      return `Must be a minimum of ${par.min}`;
    },
    max: (par: { max: number; actual: string }): string => {
      return `Maximum allowed is ${par.max}`;
    },
    maxLength: (par: { maxLength: number; actual: string }): string => {
      return `Maximum allowed is ${par.maxLength}`;
    },
  };

  /**
   * @param {key: string, value: string} option Checkbox base field
   * @requires
   */
  @Input()
  option: { key: string; value: string };

  /**
   * @param {FormGroup} parentForm Parent form group name
   * @requires
   */
  @Input()
  parentForm: FormGroup = new FormGroup({});

  /**
   * @param {string} formArrayGroupName The name of the form array group
   * @requires
   */
  @Input()
  formArrayGroupName: string;

  /**
   * @param {boolean} isDisabled Set whether checkboxes are disabled
   */
  @Input()
  isDisabled = false;

  @Output()
  checkboxClicked: EventEmitter<Event> = new EventEmitter<Event>();

  /**
   * Gets the value of the checkbox's corresponding form control
   * @returns {boolean} The checkbox value
   */
  getChecked(): boolean {
    const controlAccessor = this.formArrayGroupName;
    const arrayKey = 'controls';
    const arrayIndex = Number(this.option.value);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return this.parentForm.controls[controlAccessor][arrayKey][arrayIndex]
      .value as boolean;
  }

  /**
   * @param {Event} event The click event to be emitted
   */
  updateValue(event: Event): void {
    this.checkboxClicked.emit(event);
  }

  /**
   * Gets a list of classes to be applied to the checkbox label
   * @returns {string[]} css class list
   */
  public get classes(): string[] {
    const hasDisabledClass = this.isDisabled ? 'disabled' : '';
    return ['checkbox--label', 'body1', hasDisabledClass];
  }
}
