import { Injectable, inject } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { AuthenticationService } from '../_services/authentication.service';
import { catchError } from 'rxjs/operators';
import { ErrorObject } from '../_models/error';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  private readonly authenticationService = inject(AuthenticationService);
  private readonly router = inject(Router);

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        const errorMessage: ErrorObject = {
          status: err.status,
          message: '',
        };
        // Client side error
        if (
          err.error instanceof ErrorEvent ||
          err.error instanceof ProgressEvent
        ) {
          errorMessage.message = 'Check your network settings';
        } else {
          // server side error
          errorMessage.message =
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            (err.error?.errors
              ? // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                (err.error.title as string)
              : (err.error as string)) || 'Something went wrong';
          if ([403].indexOf(err.status) !== -1) {
            // auto logout if 403 Forbidden response returned from api
            this.authenticationService.logout();
            void this.router.navigate(['/login']);
          }

          if ([401].indexOf(err.status) !== -1) {
            this.authenticationService.logout();
            void this.router.navigate(['/login'], {
              queryParams: { returnUrl: this.router.url },
            });
          }

          if ([504].indexOf(err.status) !== -1) {
            errorMessage.message =
              'Check your network settings. If the error persists, please contact help@ogibiotec.com for support.';
          }
        }
        return throwError(() => errorMessage);
      }),
    );
  }
}
