<section>
  <div class="title--container">
    <span class="subtitle2">{{ device?.name }}</span>
    <mat-icon>stream</mat-icon>
  </div>
  <div class="row">
    <span class="body1 title">Online status: </span>
    @if (device && !device.isOffline) {
      <mat-icon class="online" matTooltip="Online">wifi</mat-icon>
    }
    @if (device && device.isOffline) {
      <mat-icon class="offline" matTooltip="Offline">wifi_off</mat-icon>
    }
  </div>
  <div class="row">
    <span class="body1 title">Device status: </span>
    @if (device) {
      <app-status-indicator
        [status]="DeviceStatus[device.deviceStatusId]"
      ></app-status-indicator>
    }
  </div>
  <div class="row">
    <span class="body1 title">Hardware version: </span
    ><span class="body1 data">
      {{
        device?.hardwareVersion !== "0"
          ? (device?.hardwareVersion | formatRole)
          : "N/A"
      }}</span
    >
  </div>
  <div class="row">
    <span class="body1 title">Software version: </span
    ><span class="body1 data">
      {{
        device?.installedSoftwareVersion
          ? device?.installedSoftwareVersion
          : "N/A"
      }}</span
    >
  </div>
  <div class="row">
    <span class="body1 title">Mac address: </span
    ><span class="body1 data">{{ device?.macAddress }}</span>
  </div>
</section>
