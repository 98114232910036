/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Component, OnInit, inject } from '@angular/core';
import {
  FormBuilder,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { ErrorObject } from 'src/app/_models/error';
import { FormBase } from 'src/app/_models/form-base';
import { Laboratory } from 'src/app/_models/laboratory';
import { User } from 'src/app/_models/user';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { LaboratoryService } from 'src/app/_services/laboratory.service';
import { ModalService } from 'src/app/_services/modal.service';
import { ModalComponent } from '../../_components/modals/modal.component';
import { ButtonComponent } from '../../_components/buttons/button/button.component';
import { UnderlinedFormFieldComponent } from '../../_components/forms/underlined-form-field/underlined-form-field.component';

@Component({
  selector: 'app-add-lab',
  templateUrl: './add-lab.component.html',
  styleUrls: ['./add-lab.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    UnderlinedFormFieldComponent,
    ButtonComponent,
    ModalComponent,
  ],
})
export class AddLabComponent implements OnInit {
  private readonly formBuilder = inject(FormBuilder);
  private readonly router = inject(Router);
  private readonly modalService = inject(ModalService);
  private readonly authenticationService = inject(AuthenticationService);
  private readonly laboratoryService = inject(LaboratoryService);

  // Control vars
  isProcessing = false;
  error: ErrorObject = null;
  currentUser: User;
  addLabSuccessMessage: string;
  organisationId: string;

  allowNavigation: Subject<boolean> = new Subject<boolean>();

  labNameField = new FormBase({
    key: 'labName',
    label: 'Lab Name',
    type: 'text',
    placeholder: 'Lab Name',
    disabled: false,
    required: true,
    value: '',
    options: [],
  });

  addLabForm = this.formBuilder.group({
    [this.labNameField.key]: ['', Validators.required],
  });

  ngOnInit(): void {
    this.currentUser = this.authenticationService.currentUserValue;
    // Use route data to get and set list of device ids
    if (window.history.state.data) {
      this.organisationId = window.history.state.data as string;
    }
  }

  openModal(modalId: string): void {
    this.modalService.open(modalId);
  }

  closeModal(modalId: string): void {
    this.modalService.close(modalId);
  }

  modalButtonClicked(buttonId: string): void {
    switch (buttonId) {
      case 'view-lab-button':
        void this.router.navigate(['laboratories/view-lab']);
        break;
      case 'add-users-button':
        void this.router.navigate(['user/add-user']);
        break;
      case 'error-button':
        this.closeModal('error');
        break;
      case 'cancel-button':
        this.allowNavigation.next(false);
        this.closeModal('attention');
        break;
      case 'confirm-navigation':
        this.allowNavigation.next(true);
        this.closeModal('attention');
        break;
    }
  }

  onSubmit(): void {
    // If form is already being submitted, exit
    if (this.isProcessing) {
      return;
    }

    this.isProcessing = true;
    this.error = null;
    this.addLabSuccessMessage = '';

    const orgId =
      this.currentUser.organisationId.toString() !==
      this.organisationId?.toString()
        ? this.organisationId
        : null;

    this.laboratoryService
      .addLab(this.addLabForm.value.labName, orgId)
      .subscribe({
        next: (response: Laboratory | null) => {
          if (response === null) {
            this.addLabSuccessMessage =
              'New laboratory request is pending approval; the status can be checked on the approvals page.';
            this.openModal('create-lab');
            this.isProcessing = false;
            this.addLabForm.reset();
          } else {
            response.isOwnLab = false;
            this.authenticationService.setSelectedLaboratory(response);
            this.currentUser.laboratories.push(response);
            this.authenticationService.updateCurrentUser(this.currentUser);
            this.addLabSuccessMessage = 'New laboratory has been created.';
            this.isProcessing = false;
            this.addLabForm.reset();
            this.openModal('create-lab');
          }
        },
        error: (error: ErrorObject) => {
          this.isProcessing = false;
          this.error = error;
          this.openModal('error');
        },
      });
  }
}
