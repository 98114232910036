<div class="modal--container">
  <div class="modal-body">
    @if (animationType === "test-tubes") {
      <div #lottieContainer id="lottie-container"></div>
    }
    @if (iconName) {
      <mat-icon>{{ iconName }}</mat-icon>
    }
    <form [formGroup]="validationForm">
      <h4>{{ titleText }}</h4>
      <span class="body1">
        Type
        <strong> {{ validationText.toUpperCase() }} </strong> into the field
        below.</span
      >
      <label class="overline" [for]="validationText">
        <div class="underlined-form-field-input-container">
          <input
            class="body1 underlined-form-field-input"
            type="text"
            formControlName="validationText"
            [placeholder]="validationText"
          />
        </div>
      </label>
      @if (error) {
        <div class="error--container">
          <p class="error">Error: {{ error.message }}</p>
        </div>
      }
      <div class="button--container">
        @if (buttonType === "double-button") {
          <app-button
            class="modal-button"
            buttonStyle="standard"
            [label]="secondaryButtonText"
            [buttonId]="secondaryButtonId"
            (buttonClicked)="close()"
          ></app-button>
        }
        <app-button
          class="modal-button"
          type="submit"
          buttonStyle="standard"
          [label]="primaryButtonText"
          [buttonId]="primaryButtonId"
          (buttonClicked)="buttonEventHandler(primaryButtonId)"
          [isDisabled]="buttonIsDisabled"
        ></app-button>
      </div>
    </form>
  </div>
</div>
<div class="modal--backdrop"></div>
