import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { Role } from '../_models/role';
import { AuthenticationService } from '../_services/authentication.service';

export interface RouteData {
  roles: Role[];
}

interface CustomActivatedRoute extends ActivatedRouteSnapshot {
  data: RouteData;
}

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService
  ) {}
  canActivate(
    route: CustomActivatedRoute,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const currentUser = this.authenticationService.currentUserValue;

    if (currentUser) {
      // Check if route is restricted by role
      if (
        route.data.roles &&
        route.data.roles.indexOf(currentUser.role) === -1
      ) {
        // If different roles require different redirects or actions
        // then add that code here
        return false;
      }
      // Otherwise role is permitted to access route
      return true;
    } else {
      this.authenticationService.logout();
      // not logged in so redirect to login page with the return url
      void this.router.navigate(['/login'], {
        queryParams: { returnUrl: state.url },
      });
      return false;
    }
  }
}
