import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IconNames } from 'src/app/_components/icons/icon-names';
import { MustMatch } from 'src/app/_helpers/must-match.validator';
import { ErrorObject } from 'src/app/_models/error';
import { FormBase } from 'src/app/_models/form-base';
import { TokenStatus } from 'src/app/_models/token-status';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { passwordRegex } from 'src/app/_helpers/utils';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  logo = IconNames.LogoColour;
  requestError = new ErrorObject(null, '');
  resetError = new ErrorObject(null, '');
  errors: string[] = [];
  tokenStatus = TokenStatus.Validating;
  tokenTitle = 'Validating reset token';
  token = null;
  email: string;
  isProcessing = false;
  submitted = false;

  passwordField = new FormBase<string>({
    key: 'password',
    label: 'Password',
    type: 'password',
    placeholder: 'Password',
    disabled: false,
    required: true,
    value: '',
    options: [],
    autocomplete: 'new-password',
  });

  confirmPasswordField = new FormBase<string>({
    key: 'confirmPassword',
    label: 'Confirm Password',
    type: 'password',
    placeholder: 'Re-enter Password',
    disabled: false,
    required: true,
    value: '',
    options: [],
    autocomplete: 'new-password',
  });

  resetForm = this.formBuilder.group(
    {
      [this.passwordField.key]: [
        '',
        [
          Validators.required,
          Validators.pattern(passwordRegex),
          Validators.minLength(8),
        ],
      ],
      [this.confirmPasswordField.key]: ['', Validators.required],
    },
    {
      validators: MustMatch(
        this.passwordField.key,
        this.confirmPasswordField.key
      ),
    }
  );

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService
  ) {}

  ngOnInit(): void {
    // Check that this way of accessing defintely works (usually do queryParams['token'])
    this.token = this.route.snapshot.queryParams.token as string;
    this.email = this.route.snapshot.queryParams.email as string;

    // // Remove token from URL to prevent http referrer leak
    void this.router.navigate([], { relativeTo: this.route, replaceUrl: true });

    this.authenticationService
      .validateResetToken(this.token as string, this.email)
      .pipe()
      .subscribe({
        next: (response: boolean) => {
          if (response === true) {
            this.tokenStatus = TokenStatus.Valid;
            this.tokenTitle = 'Enter new password';
          } else {
            this.tokenStatus = TokenStatus.Invalid;
            this.tokenTitle = 'Link Expired';
            this.requestError = new ErrorObject(
              400,
              'Password resets are only valid for 30 mins. Please request a new link to continue'
            );
          }
        },
        error: (error: ErrorObject) => {
          this.tokenStatus = TokenStatus.Invalid;
          this.tokenTitle = 'Something went wrong';
          this.resetError = error;
        },
      });
  }

  /**
   * @remarks
   * Convenience getter for easy access to form fields
   *
   */
  get f(): FormGroup['controls'] {
    return this.resetForm.controls;
  }

  onSubmit(): void {
    // If form is already being submitted, exit
    if (this.isProcessing) {
      return;
    }

    // Reset error object
    this.resetError.message = '';
    if (this.resetForm.invalid) {
      return;
    }

    this.isProcessing = true;
    this.authenticationService
      .resetPassword(
        this.token as string,
        this.f.password.value as string,
        this.email
      )
      .pipe()
      .subscribe({
        next: () => {
          void this.router.navigate(['/reset-successful']);
        },
        error: (error: ErrorObject) => {
          this.resetError = error;
          this.isProcessing = false;
        },
      });
  }
}
