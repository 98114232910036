import { Laboratory } from './laboratory';
import { Organisation } from './organisation';
import { PaginationData } from './pagination-data.model';

export class LaboratoryChangeRequest {
  id: number;
  name: string;
  requestedBy: string;
  requestType: string; // TODO: Enum
  requestStatus: string; // TODO: Enum
  requestInfo: string;
  approvedBy: string;
  createdAt: Date;
  updatedAt: Date;
  updateDifferences: string;
  organisation: Organisation;
}

export class LaboratoryIndexResponse {
  laboratoryChangeRequests: LaboratoryChangeRequest[];
  paginationData: PaginationData;
}

export interface UserChangeRequest {
  id: number;
  userId: number;
  firstName: string;
  lastName: string;
  userEmail: string;
  labIds: number[];
  userLaboratories: Laboratory[];
  requestedBy: string;
  requestType: string; // TODO: Enum
  requestStatus: string; // TODO: Enum
  requestInfo: string;
  approvedBy: string;
  createdAt: Date;
  updatedAt: Date;
  roleName: string;
  roleId: number;
  updateDifferences: string;
}

export class UserIndexResponse {
  userChangeRequests: UserChangeRequest[];
  paginationData: PaginationData;
}

export class DeviceChangeRequest {
  id: number;
  deviceId: number;
  name: string;
  macAddress: string;
  laboratoryName: string;
  deviceModuleIds: number[];
  requestedBy: string;
  requestType: string; // TODO: Enum
  requestStatus: string; // TODO: Enum
  requestInfo: string;
  approvedBy: string;
  createdAt: Date;
  updatedAt: Date;
  updateDifferences: string;
  pumpTubeLength: number;
}

export class DeviceIndexResponse {
  deviceChangeRequests: DeviceChangeRequest[];
  paginationData: PaginationData;
}

export class OrganisationChangeRequest {
  id: number;
  organisationName: string;
  licenceType: string;
  superUserFirstName: string;
  superUserLastName: string;
  superUserEmail: string;
  requestedBy: string;
  requestType: string; // TODO: Enum
  requestStatus: string; // TODO: Enum
  requestInfo: string;
  approvedBy: string;
  createdAt: Date;
  updatedAt: Date;
  updateDifferences: string;
}

export class OrganisationIndexResponse {
  organisationChangeRequests: OrganisationChangeRequest[];
  paginationData: PaginationData;
}

export class BufferChangeRequest {
  id: number;
  bufferId: number;
  name: string;
  organisationId: number;
  organisationName: string;
  requestedBy: string;
  requestType: string;
  requestStatus: string;
  requestInfo: string;
  actionedBy: string;
  createdAt: Date;
  updatedAt: Date;
  updateDifferences: string;
}

export class BufferIndexResponse {
  bufferChangeRequests: BufferChangeRequest[];
  paginationData: PaginationData;
}

export class ApprovalUpdate {
  id: number;
  requestStatus: RequestStatus;
  requestInfo?: string;
}

export enum RequestType {
  Create = 1,
  Update = 2,
  Delete = 3,
}

export enum RequestStatus {
  Pending = 1,
  Approved = 2,
  Rejected = 3,
}
