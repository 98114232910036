/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import * as d3 from 'd3';
import { ScaleLinear, ScaleLogarithmic } from 'd3';
import { fromEvent, debounceTime } from 'rxjs';
import {
  ExperimentData,
  IDevice,
  IFlask,
  ITimeValue,
} from 'src/app/_models/experiment-data';
import { MatSlideToggle } from '@angular/material/slide-toggle';

@Component({
  selector: 'app-graph',
  templateUrl: './graph.component.html',
  styleUrls: ['./graph.component.scss'],
  standalone: true,
  imports: [MatSlideToggle],
})
export class GraphComponent implements OnChanges, AfterViewInit {
  // colour scale
  private readonly colours: string[] = [
    '#6ECF9C',
    '#6C73C1',
    '#CE517E',
    '#37494A',
    '#D2493A',
    '#BE4FC1',
    '#CB9F50',
    '#7FCE4C',
    '#9CADB7',
    '#496A35',
    '#6A3A2B',
    '#553056',
  ];

  checked = false;
  private graphSvg: d3.Selection<SVGGElement, unknown, HTMLElement, any>;
  private axisSvg: d3.Selection<SVGSVGElement, unknown, HTMLElement, any>;
  private x: ScaleLinear<number, number>;
  private y: ScaleLinear<number, number> | ScaleLogarithmic<number, number>;
  private readonly logMin = 0.0001;
  private readonly margin = {
    left: 70,
    right: 5,
    top: 10,
    bottom: 60,
  };
  private isDrawing = false; // Flag to track if the graph is being drawn

  private prevWidth: number | null = null;

  private width: number;
  private height = 700;

  private dataToRender: IRenderData[] = [];
  private labelList: IPropertyColour[] = [];

  /**
   * @param {string} graphId The ID of the graph to be displayed
   * @description This is used to set the ID of the graph container
   * so that it can be referenced by the zoom function
   * @example 'scatter'
   */
  @Input()
  graphId = 'scatter';

  /**
   * @param {string} graphTitle The title of the graph to be displayed
   */
  @Input()
  graphTitle = 'Title Here';

  /**
   * @param {string} yAxisLabel The y-axis label to be used on the graph
   */
  @Input()
  yAxisLabel = 'Y Axis Label';

  /**
   * @param {ExperimentData} experimentData The experiment data from the API to be graphed
   */
  @Input()
  experimentData: ExperimentData;

  /**
   * @param {boolean} isLogarithmic Whether the graph should be displayed on a logarithmic scale
   */
  @Input()
  isLogarithmic = false;

  ngAfterViewInit(): void {
    this.createSvg();
    this.parseData(this.experimentData);

    // Resize observer to update the graph when the window is resized
    const resizeObservable$ = fromEvent(window, 'resize').pipe(
      debounceTime(300), // Adjust delay as needed
    );

    resizeObservable$.subscribe(() => {
      this.updateSvgDimensions();
    });
  }

  ngOnChanges(simpleChanges: SimpleChanges): void {
    if (
      simpleChanges.experimentData &&
      !simpleChanges.experimentData.firstChange
    ) {
      this.dataToRender = [];
      this.labelList = [];
      this.parseData(this.experimentData, true);
    }
  }

  toggleChange(event: MatSlideToggle): void {
    this.checked = event.checked;
    if (this.checked) {
      this.graphSvg.call(d3.zoom().on('zoom', null));
    } else {
      this.graphSvg.call(
        d3
          .zoom()
          .extent([
            [0, 0],
            [this.width, this.height],
          ])
          .scaleExtent([1, 10])
          .on('zoom', this.updateChart),
      );
    }
  }

  private updateSvgDimensions(): void {
    const containerWidth = (
      d3.select(`figure#${this.graphId}`).node() as HTMLElement
    ).offsetWidth;

    const containerHeight = (
      d3.select(`figure#${this.graphId}`).node() as HTMLElement
    ).offsetHeight;

    if (
      (this.prevWidth !== containerWidth || this.height !== containerHeight) &&
      !this.isDrawing
    ) {
      this.isDrawing = true;

      // Recalculate the width and height based on container size
      this.width = containerWidth - (this.margin.left + this.margin.right);
      this.height = containerHeight - (this.margin.top + this.margin.bottom);

      // Update the SVG dimensions
      this.axisSvg
        .attr('width', this.width + (this.margin.left + this.margin.right))
        .attr('height', this.height + (this.margin.top + this.margin.bottom));

      // Preserve the zoom transform by applying it to the dots as well
      const zoomTransform = d3.zoomTransform(this.graphSvg.node());

      // Recreate the scales based on new dimensions
      this.x.range([0, this.width]);
      this.y.range([this.height, 0]);

      // Update the clip path dimensions to match the new graph dimensions
      this.graphSvg
        .select('clipPath rect')
        .attr('width', this.width)
        .attr('height', this.height); // Ensure the clip path is resized properly

      // Update the axes with the current zoom transformation
      this.axisSvg
        .select<SVGGElement>('.x-axis')
        .attr(
          'transform',
          `translate(${this.margin.left}, ${this.height + this.margin.top})`,
        )
        .call(d3.axisBottom(zoomTransform.rescaleX(this.x)));

      this.axisSvg
        .select<SVGGElement>('.y-axis')
        .attr('transform', `translate(${this.margin.left}, ${this.margin.top})`)
        .call(d3.axisLeft(zoomTransform.rescaleY(this.y)));

      // Reapply zoom transform to the dots as well
      this.graphSvg
        .selectAll('circle')
        .attr('cx', (d: IRenderData) => zoomTransform.rescaleX(this.x)(d.Time))
        .attr('cy', (d: IRenderData) =>
          zoomTransform.rescaleY(this.y)(d.Value),
        );

      // Save the new width for future resizing
      this.prevWidth = containerWidth;

      this.isDrawing = false;
    }
  }

  private readonly createSvg = (): void => {
    // Set the styling on the container to relative for the absolutely positioned tooltips
    d3.select('figure').style('position', 'relative');
    // Get the width of the parent container
    const containerWidth = (
      d3.select(`figure#${this.graphId}`).node() as HTMLElement
    ).offsetWidth; // Get the width of the parent container
    this.width = containerWidth - (this.margin.left + this.margin.right);
    // Create the main SVG element for the axes
    this.axisSvg = d3
      .select(`figure#${this.graphId}`)
      .append('svg')
      .attr('width', this.width + (this.margin.left + this.margin.right))
      .attr('height', this.height + (this.margin.top + this.margin.bottom));

    // Create a group for the graph content
    this.graphSvg = this.axisSvg
      .append('g')
      .attr(
        'transform',
        'translate(' +
          this.margin.left.toString() +
          ',' +
          this.margin.top.toString() +
          ')',
      );

    // Add a clip path: everything out of this area won't be drawn.
    this.graphSvg
      .append('defs')
      .append('svg:clipPath')
      .attr('id', 'clip')
      .append('svg:rect')
      .attr('width', this.width)
      .attr('height', this.height)
      .attr('x', 0)
      .attr('y', 0);

    // Rectangle to capture pointer events for zoom
    this.graphSvg
      .append('rect')
      .attr('width', this.width)
      .attr('height', this.height)
      .style('fill', 'none')
      .style('pointer-events', 'all');

    // Now let's add zoom to the svg element
    this.graphSvg.call(
      d3
        .zoom()
        .extent([
          [0, 0],
          [this.width, this.height],
        ])
        .scaleExtent([1, 10])
        .on('zoom', this.updateChart),
    );

    // Append the axes to the main SVG (this.axisSvg)
    this.axisSvg
      .append('g')
      .attr('class', 'x-axis')
      .attr(
        'transform',
        `translate(${this.margin.left}, ${this.height + this.margin.top})`,
      );

    this.axisSvg
      .append('g')
      .attr('class', 'y-axis')
      .attr('transform', `translate(${this.margin.left}, ${this.margin.top})`);
  };

  private readonly updateChart = (
    event: d3.D3ZoomEvent<SVGSVGElement, any>,
  ): void => {
    // Rescale the x and y axes based on the zoom transformation
    const newX = event.transform.rescaleX(this.x);
    const newY = event.transform.rescaleY(this.y);

    // Get the zoom scale factor (k)
    const zoomScale = event.transform.k;

    // Minimum radius to prevent dots from shrinking too much
    const initialRadius = 2; // Initial dot size when rendered

    // Update the axes with the rescaled axes
    this.axisSvg.select<SVGGElement>('.x-axis').call(d3.axisBottom(newX));
    this.axisSvg.select<SVGGElement>('.y-axis').call(d3.axisLeft(newY));

    // Apply the zoom transformation to the circles, including scaling the radius
    this.graphSvg
      .selectAll('circle')
      .attr('cx', (d: IRenderData) => newX(d.Time))
      .attr('cy', (d: IRenderData) => {
        if (this.isLogarithmic) {
          // Ensure no negative or zero values are passed to the logarithmic scale
          return d.Value > 0 ? newY(d.Value) : newY(this.logMin);
        }
        return newY(d.Value ?? 0); // Default for linear scale
      })
      .attr('r', () => Math.max(initialRadius, initialRadius * zoomScale)); // Ensure radius doesn't drop below the initial size
  };

  private readonly parseData = (
    experimentData: ExperimentData,
    isUpdate = false,
  ): void => {
    let colourIndex = 0;
    const property = experimentData.dataType;
    const deviceList: IDevice[] = experimentData.devices;

    deviceList?.forEach((dataSet, index) => {
      // Filter out empty flask objects
      const validFlasks = dataSet.flasks.filter(
        (flask) =>
          // Check if flask is not empty and has required properties
          flask &&
          Object.keys(flask).length > 0 &&
          flask.name &&
          Array.isArray(flask.values),
      );

      validFlasks.forEach((flask: IFlask) => {
        const deviceFlaskProperty = `${dataSet.deviceId}_${flask.name}_${property}`;

        // Ensure flask.values is an array and has items
        if (
          flask.values &&
          Array.isArray(flask.values) &&
          flask.values.length > 0
        ) {
          flask.values.forEach((timeValue: ITimeValue) => {
            // Validate timeValue has required properties
            if (
              timeValue &&
              typeof timeValue.time === 'number' &&
              typeof timeValue.value === 'number'
            ) {
              const y = timeValue.value;
              const x = timeValue.time;
              const tOD = timeValue.targetOD || null;

              this.dataToRender.push({
                DeviceFlaskProperty: deviceFlaskProperty,
                Time: x,
                Value: y,
                DeviceName: dataSet.deviceId.toString(),
                FlaskName: flask.name,
                TargetOD: tOD,
              });
            }
          });

          this.labelList.push({
            deviceFlaskProperty,
            colour: this.colours[colourIndex],
          });

          colourIndex++;
          // If we've exceeded the colour sets available, reset and reuse
          if (colourIndex === this.colours.length) {
            colourIndex = 0;
          }
        }
      });

      if (experimentData.devices.length === index + 1) {
        // Now data is ready and formatted, let's render the graph
        if (isUpdate) {
          this.updateGraphWithNewData(this.dataToRender);
        } else {
          this.drawScatterGraph();
        }
      }
    });
  };

  private readonly drawScatterGraph = (): void => {
    this.isDrawing = true; // Set the flag to true to prevent resizing
    // Clear existing content of the graph SVG
    this.graphSvg.select('#graph-content').selectAll('*').remove();
    // First create x-axis
    this.x = d3
      .scaleLinear()
      .domain(d3.extent(this.dataToRender, (d) => d.Time) as [number, number])
      .range([0, this.width]);

    const minY = d3.min(this.dataToRender, (d) => d.Value);
    const maxY = d3.max(this.dataToRender, (d) => d.Value);

    // Apply padding only as an absolute adjustment, depending on the sign
    const paddingFactor = 0.1; // 10% padding

    const paddedMinY = minY - Math.abs(minY * paddingFactor);
    const paddedMaxY = maxY + Math.abs(maxY * paddingFactor);

    // Optionally limit decimal places (e.g., to 2 decimal places)
    const roundedMinY = parseFloat(paddedMinY.toFixed(2));
    const roundedMaxY = parseFloat(paddedMaxY.toFixed(2));

    // Log(0) is undefined so if we are plotting logarithmically, we need to set min to a sensible value
    if (this.isLogarithmic) {
      this.y = d3
        .scaleLog()
        .domain([this.logMin, d3.max(this.dataToRender, (d) => d.Value) * 1.1])
        .range([this.height, 0]);
    } else {
      this.y = d3
        .scaleLinear()
        .domain(
          [roundedMinY, roundedMaxY] as [number, number], // Set the domain to the min and max values
        )
        .range([this.height, 0]);
    }

    // Create the axes (but DON'T append them here)
    const xAxis = d3.axisBottom(this.x);
    const yAxis = d3.axisLeft(this.y);

    const applyAxis = (
      selection: d3.Selection<SVGGElement, unknown, HTMLElement, any>,
      axis: any,
    ) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      selection.call(axis);
    };

    // Add x-axis label
    this.axisSvg.select('.x-axis').selectAll('text').remove(); // Remove existing labels

    // Attach the x-axis to the svg group
    applyAxis(this.axisSvg.select('.x-axis'), xAxis); // Use the helper function
    this.axisSvg
      .select('.x-axis')
      .append('text')
      .attr('x', this.width / 2)
      .attr('y', this.margin.bottom - 10)
      .attr('fill', '#000000')
      .attr('text-anchor', 'middle')
      .text('Time [h]')
      .style('font-family', '$proxima-nova')
      .style('font-weight', '300')
      .style('font-size', '2rem');

    // Attach the y-axis to the svg group
    const horzOffset = this.margin.left - 20;
    const vertOffset = this.height / 2 - (this.margin.bottom + this.margin.top);

    applyAxis(this.axisSvg.select('.y-axis'), yAxis);
    this.axisSvg
      .select('.y-axis')
      .append('text')
      .attr('x', -this.margin.left + 10)
      .attr('y', 10)
      .attr('fill', '#000000')
      .attr('text-anchor', 'middle')
      .attr('transform', `translate(-${horzOffset}, ${vertOffset}) rotate(-90)`)
      .style('font-family', '$proxima-nova')
      .style('font-weight', '300')
      .style('font-size', '2rem')
      .text(this.yAxisLabel);

    // Colour set up - can't pass in objects so need flat lists
    const domainScale = [];
    const rangeColour = [];

    this.labelList.forEach((element) => {
      domainScale.push(element.deviceFlaskProperty);
      rangeColour.push(element.colour);
    });
    const colour = d3.scaleOrdinal().domain(domainScale).range(rangeColour);

    // Now let's build the tooltip, hidden by default (with opacity 0)
    const tooltip = d3
      .select(`figure#${this.graphId}`)
      .append('div')
      .style('opacity', 0)
      .attr('class', 'body3')
      .attr('class', 'tooltip')
      .style('background-color', '#B4DFFF')
      .style('border', 'solid')
      .style('border-width', '1px')
      .style('border-radius', '5px')
      .style('border-color', '#0C7BB4')
      .style('padding', '10px')
      .style('font-family', '$proxima-nova')
      .style('font-weight', '300')
      .style('font-size', '1rem')
      .style('position', 'absolute');

    // And when the user hovers over a point, let's make it visible
    const mouseover = function () {
      tooltip.style('opacity', 1);
      d3.select(this).style('stroke', 'black').style('opacity', '1');
    };

    // Insert text into the tooltip and position it relative to the point hovered over
    const mousemove = function (e: MouseEvent, d: IRenderData) {
      const tooltipX = e.offsetX + 20;
      // If target OD is availabe, add to the tooltip
      if (d.TargetOD) {
        const tooltipText = `Device: ${d.DeviceName}<br> Flask: ${d.FlaskName}<br> Property: ${d.DeviceFlaskProperty}<br> Time: ${d.Time}<br> Value: ${d.Value}<br> Target OD: ${d.TargetOD}`;
        tooltip
          .html(tooltipText)
          .style('left', tooltipX.toString() + 'px') //offset the tooltip to prevent clash with point
          .style('top', e.offsetY.toString() + 'px');
      } else {
        tooltip
          .html(
            `Device: ${d.DeviceName}<br> Flask: ${d.FlaskName}<br> Property: ${d.DeviceFlaskProperty}<br> Time: ${d.Time}<br> Value: ${d.Value}`,
          )
          .style('left', tooltipX.toString() + 'px') //offset the tooltip to prevent clash with point
          .style('top', e.offsetY.toString() + 'px');
      }
    };

    // Now hide the tooltip when the mouse leaves hoverover
    const mouseleave = function () {
      tooltip.style('opacity', 0);
      d3.select(this).style('stroke', 'none');
    };

    // Now add the dots
    const dots = this.graphSvg
      .append('g')
      .attr('clip-path', 'url(#clip)')
      .attr('id', 'graph-content'); // Add an ID to the graph content group

    dots
      .selectAll('circle')
      .data(this.dataToRender)
      .enter()
      .append('circle')
      .attr('cx', (d) => this.x(d.Time))
      .attr('cy', (d) => {
        if (this.isLogarithmic) {
          // Check for zero, negative, undefined, or null values
          return d.Value > 0 ? this.y(d.Value) : this.y(this.logMin);
        }
        // Default for linear scale (allow zero and negative values)
        return this.y(d.Value ?? 0); // Fallback to 0 if value is undefined or null
      })
      .attr('r', 2)
      .style('fill', (d): string => {
        // Explicitly return a string
        return colour(d.DeviceFlaskProperty) as string;
      })
      .on('mouseover', mouseover)
      .on('mousemove', mousemove)
      .on('mouseleave', mouseleave);

    this.isDrawing = false; // Set the flag to false to allow resizing
  };

  private showTooltip(e: MouseEvent, d: IRenderData): void {
    const tooltipX = e.offsetX + 20;
    const tooltip = d3.select(`figure#${this.graphId} .tooltip`);
    const tooltipText = d.TargetOD
      ? `Device: ${d.DeviceName}<br> Flask: ${d.FlaskName}<br> Property: ${d.DeviceFlaskProperty}<br> Time: ${d.Time}<br> Value: ${d.Value}<br> Target OD: ${d.TargetOD}`
      : `Device: ${d.DeviceName}<br> Flask: ${d.FlaskName}<br> Property: ${d.DeviceFlaskProperty}<br> Time: ${d.Time}<br> Value: ${d.Value}`;

    tooltip
      .html(tooltipText)
      .style('left', tooltipX.toString() + 'px')
      .style('top', e.offsetY.toString() + 'px')
      .style('opacity', 1);
  }

  private hideTooltip(): void {
    d3.select(`figure#${this.graphId} .tooltip`).style('opacity', 0);
  }

  private readonly updateGraphWithNewData = (newData: IRenderData[]): void => {
    this.isDrawing = true; // Prevent resizing during update

    // Update the scales (use the same domain and range from drawScatterGraph)
    const xAxis = d3.axisBottom(this.x);
    const yAxis = d3.axisLeft(this.y);

    // Apply axes (if needed, but usually they should already be rendered correctly)
    const applyAxis = (
      selection: d3.Selection<SVGGElement, unknown, HTMLElement, any>,
      axis: any,
    ) => {
      selection.call(axis);
    };

    // Reapply the axes to ensure proper rendering
    applyAxis(this.axisSvg.select('.x-axis'), xAxis);
    applyAxis(this.axisSvg.select('.y-axis'), yAxis);

    // Colour set up (reuse the same color scheme as before)
    const domainScale = this.labelList.map((e) => e.deviceFlaskProperty);
    const rangeColour = this.labelList.map((e) => e.colour);
    const colour = d3.scaleOrdinal().domain(domainScale).range(rangeColour);

    // Select the existing graph content (or create it if missing)
    let graphContent = this.graphSvg.select('#graph-content');
    if (graphContent.empty()) {
      graphContent = this.graphSvg
        .append('g')
        .attr('clip-path', 'url(#clip)')
        .attr('id', 'graph-content');
    }

    // Bind new data
    const dots = graphContent
      .selectAll('circle')
      .data(newData, (d: IRenderData) => d.DeviceFlaskProperty);

    // **Enter**: Add new dots for new data points
    dots
      .enter()
      .append('circle')
      .attr('cx', (d) => this.x(d.Time))
      .attr(
        'cy',
        (d) =>
          d.Value > 0 && this.isLogarithmic
            ? this.y(d.Value)
            : this.y(this.logMin), // Fallback to logMin if value is zero (log scale only)
      )
      .attr('r', 2)
      .style('fill', (d) => colour(d.DeviceFlaskProperty) as string)
      .on('mouseover', (e: MouseEvent, d: IRenderData) => {
        this.showTooltip(e, d);
      })
      .on('mouseleave', () => this.hideTooltip());

    // **Update**: Update existing dots with new data
    dots
      .attr('cx', (d) => this.x(d.Time))
      .attr(
        'cy',
        (d) =>
          d.Value > 0 && this.isLogarithmic
            ? this.y(d.Value)
            : this.y(this.logMin), // Fallback to logMin if value is zero (log scale only)
      )
      .style('fill', (d) => colour(d.DeviceFlaskProperty) as string);

    // **Exit**: Remove dots no longer in the data
    dots.exit().remove();

    // Apply zoom transformations if any exist
    const zoomTransform = d3.zoomTransform(this.graphSvg.node());
    this.graphSvg
      .selectAll('circle')
      .attr('cx', (d: IRenderData) => zoomTransform.rescaleX(this.x)(d.Time))
      .attr('cy', (d: IRenderData) => zoomTransform.rescaleY(this.y)(d.Value));

    this.isDrawing = false; // Allow resizing after drawing is complete
  };
}

export interface IRenderData {
  DeviceFlaskProperty: string;
  Time: number;
  Value: number;
  DeviceName: string;
  FlaskName: string;
  TargetOD?: number;
}

export interface IPropertyColour {
  deviceFlaskProperty: string;
  colour: string;
}
