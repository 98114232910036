<img id="logo" src="assets/img/ogi-logo.png" alt="" />
<div id="page">
  <div id="container">
    <h4>Password reset</h4>
    <p>Your password was successfully changed.</p>
    <form>
      <app-button
        type="submit"
        buttonStyle="standard"
        label="Return to login"
        buttonId="login"
        hasFill="true"
        [routerLink]="'/login'"
      >
      </app-button>
    </form>
  </div>
</div>
