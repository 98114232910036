import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import {
  ExperimentData,
  ICalibrationDevice,
  IDevice,
  IOxygenCalibrationDevice,
  IPhCalibrationDevice,
} from '../_models/experiment-data';
import { ExperimentMode } from '../_models/experiment-mode';
import { httpOptions } from '../_helpers/utils';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  private readonly http = inject(HttpClient);

  httpOptions = httpOptions;

  // ET: could refactor this to a larger function that accepts all data types used in an experiment, makes the calls to each relevant endpoint
  // and patches the responses together in a switchMap
  // Base module data
  getBaseModuleData(
    id: number,
    experimentMode: ExperimentMode,
  ): Observable<ExperimentData> {
    const dataType = 'Optical Density';
    const experimentData: ExperimentData = {
      dataType,
      devices: [],
      experimentMode,
    };
    switch (experimentMode) {
      case ExperimentMode.Turbidostat: {
        return this.http
          .get<
            IDevice[]
          >(`${environment.apiUrl}/turbidostat-module/${id}/data`, this.httpOptions)
          .pipe(
            map((response) => {
              if (response.status === 200) {
                experimentData.devices = response.body;
                return experimentData;
              } else {
                return null;
              }
            }),
          );
      }
      case ExperimentMode.BatchCulture:
      case ExperimentMode.Chemostat:
      case ExperimentMode.PHControl:
        return this.http
          .get<
            IDevice[]
          >(`${environment.apiUrl}/base-module/${id}/data`, this.httpOptions)
          .pipe(
            map((response) => {
              if (response.status === 200) {
                experimentData.devices = response.body;
                return experimentData;
              } else {
                return null;
              }
            }),
          );
      default:
        return null;
    }
  }

  getTemperatureModuleData(id: number): Observable<ExperimentData> {
    return this.http
      .get<
        IDevice[]
      >(`${environment.apiUrl}/temperature-module/${id}/data`, this.httpOptions)
      .pipe(
        map((response) => {
          if (response.status === 200) {
            const dataType = 'Temperature';
            const experimentData: ExperimentData = {
              dataType,
              devices: response.body,
            };
            return experimentData;
          } else {
            return null;
          }
        }),
      );
  }

  getOxygenModuleData(
    id: number,
    isEarly?: string,
  ): Observable<ExperimentData> {
    let url = `${environment.apiUrl}/oxygen-module/${id}/data`;
    if (isEarly !== null) {
      url += `?isEarly=${isEarly}`;
    }
    return this.http.get<IDevice[]>(url, this.httpOptions).pipe(
      map((response) => {
        if (response.status === 200) {
          const dataType = 'Oxygen';
          const experimentData: ExperimentData = {
            dataType,
            devices: response.body,
          };
          return experimentData;
        } else {
          return null;
        }
      }),
    );
  }

  getPhModuleData(id: number): Observable<ExperimentData> {
    return this.http
      .get<
        IDevice[]
      >(`${environment.apiUrl}/ph-module/${id}/data`, this.httpOptions)
      .pipe(
        map((response) => {
          if (response.status === 200) {
            const dataType = 'pH';
            const experimentData: ExperimentData = {
              dataType,
              devices: response.body,
            };
            return experimentData;
          } else {
            return null;
          }
        }),
      );
  }

  getFluorescenceModuleData(
    id: number,
    firstSelection: string,
    secondSelection?: string,
    thirdSelection?: string,
  ): Observable<ExperimentData> {
    let url = `${environment.apiUrl}/fluorescence-module/${id}/data`;
    if (firstSelection !== null) {
      url += `?firstColumnIn=${firstSelection}`;
    }
    if (secondSelection !== null) {
      url += `&secondColumnIn=${secondSelection}`;
    }
    if (thirdSelection !== null) {
      url += `&thirdColumnIn=${thirdSelection}`;
    }
    return this.http.get<IDevice[]>(url, this.httpOptions).pipe(
      map((response) => {
        if (response.status === 200) {
          const dataType = 'Fluorescence';
          const experimentData: ExperimentData = {
            dataType,
            devices: response.body,
          };
          return experimentData;
        } else {
          return null;
        }
      }),
    );
  }

  getODCalibrationData(id: number): Observable<ICalibrationDevice> {
    return this.http
      .get<ICalibrationDevice>(`${environment.apiUrl}/calibrations/${id}/data`)
      .pipe(
        map((response: ICalibrationDevice) => {
          return response;
        }),
      );
  }

  getOxygenCalibrationData(
    id: number,
    saturationType = 0,
  ): Observable<IOxygenCalibrationDevice> {
    return this.http
      .get<IOxygenCalibrationDevice>(
        `${environment.apiUrl}/oxygen-calibrations/${id}/data/${saturationType}`,
      )
      .pipe(
        map((response: IOxygenCalibrationDevice) => {
          return response;
        }),
      );
  }

  getPhCalibrationData(id: number): Observable<IPhCalibrationDevice> {
    return this.http
      .get<IPhCalibrationDevice>(
        `${environment.apiUrl}/ph-calibrations/${id}/data`,
      )
      .pipe(
        map((response: IPhCalibrationDevice) => {
          return response;
        }),
      );
  }
}
