<div id="page">
  <div id="container">
    <app-svg-template
      [iconName]="logo"
      width="249"
      height="60"
      customViewBox="0 0 249 60"
    ></app-svg-template>
    <h5>Password Reset</h5>
    <p class="subtitle1">Your password was successfully changed.</p>
    <app-button
      label="Back to login"
      buttonId="login"
      [routerLink]="'/login'"
    ></app-button>
  </div>
</div>
