<section class="toggle--container">
  <label [for]="field.key" [formGroup]="parentForm">
    <div class="label--container body1">
      {{ field.label }}
      @if (field.required) {
      <span class="required" aria-required="true"> * </span>
      }
    </div>
    <div class="toggle">
      <mat-slide-toggle
        [formControlName]="field.key"
        [disabled]="field.disabled"
        ngDefaultControl
        color="primary"
      ></mat-slide-toggle>
    </div>
  </label>
</section>
