import { Injectable, OnDestroy } from '@angular/core';
import { httpOptions } from '../_helpers/utils';
import { BehaviorSubject, Observable, map, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from './authentication.service';
import { Laboratory } from '../_models/laboratory';
import { environment } from 'src/environments/environment';
import { ErrorLog, ErrorLogIndexResponse } from '../_models/error-log';
import { PaginationData } from '../_models/pagination-data.model';
import { takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ErrorLogService implements OnDestroy {
  httpOptions = httpOptions;
  selectedlaboratoryId: number;

  // Pagination and sort/filter observables for dashboard index view
  private readonly tableSort$ = new BehaviorSubject<{
    sortBy: string;
    sortDirection: string;
  }>(null);
  private readonly tableFilter$ = new BehaviorSubject<string[]>([]);
  private readonly pageNumber$ = new BehaviorSubject<number>(null);
  private readonly pageSize$ = new BehaviorSubject<number>(null);

  private readonly destroy$ = new Subject<void>();

  constructor(
    private readonly httpClient: HttpClient,
    private readonly authenticationService: AuthenticationService
  ) {
    // Set up subscription to selected laboratory
    this.authenticationService.selectedLab
      .pipe(takeUntil(this.destroy$))
      .subscribe((laboratory: Laboratory) => {
        if (this.selectedlaboratoryId !== laboratory.laboratoryId) {
          this.selectedlaboratoryId = laboratory.laboratoryId;
        }
      });
  }

  onUpdateSort = (sort: { sortBy: string; sortDirection: string }): void => {
    this.tableSort$.next(sort);
  };

  onUpdateFilter = (filter: string[]): void => {
    this.tableFilter$.next(filter);
  };

  onUpdatePageNumber = (pageNumber: number): void => {
    this.pageNumber$.next(pageNumber);
  };

  onUpdatePageSize = (pageSize: number): void => {
    this.pageSize$.next(pageSize);
  };

  public get filter(): string[] {
    return this.tableFilter$.value;
  }

  public get sort(): { sortBy: string; sortDirection: string } {
    return this.tableSort$.value;
  }

  public get paginationCache(): { pageNumber: number; pageSize: number } {
    if (this.pageNumber$.value !== null && this.pageSize$.value !== null) {
      return {
        pageNumber: this.pageNumber$.value,
        pageSize: this.pageSize$.value,
      };
    } else {
      return null;
    }
  }

  getErrorLogs(
    sortBy = 'CreatedAt',
    sortDirection = 'desc',
    pageSize = 5,
    pageNumber = 1,
    search: string = null
  ): Observable<ErrorLogIndexResponse> {
    let url = `${environment.apiUrl}/error-logs/${this.selectedlaboratoryId}?OrderBy=${sortBy}&OrderByDirection=${sortDirection}&PageNumber=${pageNumber}&PageSize=${pageSize}`;
    if (search) {
      url = url + `&search=${search}`;
    }
    return this.httpClient.get<ErrorLog[]>(url, this.httpOptions).pipe(
      map((response) => {
        // Set pagination data
        const paginationData = JSON.parse(
          response.headers.get('X-Pagination')
        ) as PaginationData;
        const errorLogs = response.body;
        return { errorLogs, paginationData };
      })
    );
  }

  updateErrorLogViewed(
    errorLogIds: number[],
    isViewed: boolean
  ): Observable<boolean> {
    const url = `${environment.apiUrl}/error-logs`;
    return this.httpClient
      .patch<boolean>(url, { errorLogIds, isViewed }, this.httpOptions)
      .pipe(
        map((response) => {
          return response.body;
        })
      );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
