import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Buffer } from '../_models/buffer';

interface BufferOption {
  key: string;
  value: Buffer;
}

export function BufferValidator(
  buffers: BufferOption[],
  fieldId: string,
): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value as string | number;
    if (control.value === null || control.value === undefined) {
      return null; // Don't validate empty values
    }
    const filteredBuffers = buffers.filter(
      (buffer: BufferOption) => buffer.value !== Buffer.Custom,
    );
    const alreadySelected = filteredBuffers.find(
      (buffer: BufferOption) =>
        buffer.value === control.value && buffer.key !== fieldId,
    );
    return alreadySelected ? { buffer: { value: value } } : null;
  };
}
