import { Component, OnDestroy, OnInit } from '@angular/core';
import { ErrorObject } from 'src/app/_models/error';
import {
  CalibrationResults,
  VoltageReadings,
} from 'src/app/_models/calibration';
import { OxygenCalibration } from 'src/app/_models/oxygen-calibration';
import { CalibrationService } from 'src/app/_services/calibration.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Device } from 'src/app/_models/device';
import {
  ICalibrationData,
  ICalibrationDevice,
  IOxygenCalibrationData,
  IOxygenCalibrationDevice,
  IPhCalibrationData,
  IPhCalibrationDevice,
} from 'src/app/_models/experiment-data';
import { DataService } from 'src/app/_services/data.service';
import { ModalService } from 'src/app/_services/modal.service';
import { FormBase } from 'src/app/_models/form-base';
import { FormBuilder } from '@angular/forms';
import { PhCalibration } from 'src/app/_models/ph-calibration';
import { User } from 'src/app/_models/user';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { Role } from 'src/app/_models/role';
import {
  interval,
  startWith,
  Subscription,
  switchMap,
  takeWhile,
  tap,
} from 'rxjs';

@Component({
  selector: 'app-calibration-data',
  templateUrl: './calibration-data.component.html',
  styleUrls: ['./calibration-data.component.scss'],
})
export class CalibrationDataComponent implements OnInit, OnDestroy {
  // Control vars
  fetchCalibrationError: ErrorObject = null;
  deviceStatusError: ErrorObject = null;
  dataError: ErrorObject = null;
  discardError: ErrorObject = null;
  voltageError: ErrorObject = null;
  loading = false;
  dataFirstLoad = true;
  dataIsProcessing = false;
  discardIsProcessing = false;
  currentUser: User;
  hasSentFinalisationSignal = false;

  // Make role enum available to template
  Role = Role;

  calibrationType: string;
  calibrationId: string;

  odCalibration: CalibrationResults;
  voltageReadings: VoltageReadings | null = null;
  voltagePollingSubscription: Subscription | null = null;
  voltagePollingShouldContinue = false;
  voltageLabel = 'Poll for voltage';
  calibrationPollingSubscription: Subscription | null = null;
  calibrationPollingShouldContinue = false;
  calibrationLabel = 'Poll for calibration data';
  oxygenPollingSubscription: Subscription | null = null;
  oxygenPollingShouldContinue = false;
  phPollingSubscription: Subscription | null = null;
  phPollingShouldContinue = false;

  minODValue = 0;
  maxODValue = 5;
  odStep = 0.01;
  finalisationIsProcessing = false;
  finalisationError: ErrorObject = null;

  oxygenCalibration: OxygenCalibration;
  phCalibration: PhCalibration;
  calibrationDevice: Device;
  calibrationLoadingText = 'Fetching calibration data...';

  // Oxygen vars
  oxygenSaturation = 1;
  oxygenDataReloading = false;

  // TODO: update the below to set data type based on calibration type
  calibrationData: ICalibrationData = {
    dataType: 'OD',
    devices: [],
  };
  oxygenCalibrationData: IOxygenCalibrationData = {
    dataType: 'Oxygen',
    devices: [],
  };
  phCalibrationData: IPhCalibrationData = {
    dataType: 'pH',
    devices: [],
  };
  loadingCalibrationData = false;
  renderCalibrationGraph = false;
  timeOfLastUpdate: Date = null;

  flaskAValue1Field = new FormBase<number>({
    key: 'flaskAValue1',
    label: 'OD Value 1',
    type: 'number',
    placeholder: '',
    disabled: true,
    required: false,
    value: 0,
  });

  flaskAValue2Field = new FormBase<number>({
    key: 'flaskAValue2',
    label: 'OD Value 2',
    type: 'number',
    placeholder: '',
    disabled: true,
    required: false,
    value: 0,
  });

  flaskAValue3Field = new FormBase<number>({
    key: 'flaskAValue3',
    label: 'OD Value 3',
    type: 'number',
    placeholder: '',
    disabled: true,
    required: false,
    value: 0,
  });

  flaskAValue4Field = new FormBase<number>({
    key: 'flaskAValue4',
    label: 'OD Value 4',
    type: 'number',
    placeholder: '',
    disabled: true,
    required: false,
    value: 0,
  });

  flaskAValue5Field = new FormBase<number>({
    key: 'flaskAValue5',
    label: 'OD Value 5',
    type: 'number',
    placeholder: '',
    disabled: true,
    required: false,
    value: 0,
  });

  flaskAValue6Field = new FormBase<number>({
    key: 'flaskAValue6',
    label: 'OD Value 6',
    type: 'number',
    placeholder: '',
    disabled: true,
    required: false,
    value: 0,
  });

  flaskBValue1Field = new FormBase<number>({
    key: 'flaskBValue1',
    label: 'OD Value 1',
    type: 'number',
    placeholder: '',
    disabled: true,
    required: false,
    value: 0,
  });

  flaskBValue2Field = new FormBase<number>({
    key: 'flaskBValue2',
    label: 'OD Value 2',
    type: 'number',
    placeholder: '',
    disabled: true,
    required: false,
    value: 0,
  });

  flaskBValue3Field = new FormBase<number>({
    key: 'flaskBValue3',
    label: 'OD Value 3',
    type: 'number',
    placeholder: '',
    disabled: true,
    required: false,
    value: 0,
  });

  flaskBValue4Field = new FormBase<number>({
    key: 'flaskBValue4',
    label: 'OD Value 4',
    type: 'number',
    placeholder: '',
    disabled: true,
    required: false,
    value: 0,
  });

  flaskBValue5Field = new FormBase<number>({
    key: 'flaskBValue5',
    label: 'OD Value 5',
    type: 'number',
    placeholder: '',
    disabled: true,
    required: false,
    value: 0,
  });

  flaskBValue6Field = new FormBase<number>({
    key: 'flaskBValue6',
    label: 'OD Value 6',
    type: 'number',
    placeholder: '',
    disabled: true,
    required: false,
    value: 0,
  });

  flaskCValue1Field = new FormBase<number>({
    key: 'flaskCValue1',
    label: 'OD Value 1',
    type: 'number',
    placeholder: '',
    disabled: true,
    required: false,
    value: 0,
  });

  flaskCValue2Field = new FormBase<number>({
    key: 'flaskCValue2',
    label: 'OD Value 2',
    type: 'number',
    placeholder: '',
    disabled: true,
    required: false,
    value: 0,
  });

  flaskCValue3Field = new FormBase<number>({
    key: 'flaskCValue3',
    label: 'OD Value 3',
    type: 'number',
    placeholder: '',
    disabled: true,
    required: false,
    value: 0,
  });

  flaskCValue4Field = new FormBase<number>({
    key: 'flaskCValue4',
    label: 'OD Value 4',
    type: 'number',
    placeholder: '',
    disabled: true,
    required: false,
    value: 0,
  });

  flaskCValue5Field = new FormBase<number>({
    key: 'flaskCValue5',
    label: 'OD Value 5',
    type: 'number',
    placeholder: '',
    disabled: true,
    required: false,
    value: 0,
  });

  flaskCValue6Field = new FormBase<number>({
    key: 'flaskCValue6',
    label: 'OD Value 6',
    type: 'number',
    placeholder: '',
    disabled: true,
    required: false,
    value: 0,
  });

  flaskDValue1Field = new FormBase<number>({
    key: 'flaskDValue1',
    label: 'OD Value 1',
    type: 'number',
    placeholder: '',
    disabled: true,
    required: false,
    value: 0,
  });

  flaskDValue2Field = new FormBase<number>({
    key: 'flaskDValue2',
    label: 'OD Value 2',
    type: 'number',
    placeholder: '',
    disabled: true,
    required: false,
    value: 0,
  });

  flaskDValue3Field = new FormBase<number>({
    key: 'flaskDValue3',
    label: 'OD Value 3',
    type: 'number',
    placeholder: '',
    disabled: true,
    required: false,
    value: 0,
  });

  flaskDValue4Field = new FormBase<number>({
    key: 'flaskDValue4',
    label: 'OD Value 4',
    type: 'number',
    placeholder: '',
    disabled: true,
    required: false,
    value: 0,
  });

  flaskDValue5Field = new FormBase<number>({
    key: 'flaskDValue5',
    label: 'OD Value 5',
    type: 'number',
    placeholder: '',
    disabled: true,
    required: false,
    value: 0,
  });

  flaskDValue6Field = new FormBase<number>({
    key: 'flaskDValue6',
    label: 'OD Value 6',
    type: 'number',
    placeholder: '',
    disabled: true,
    required: false,
    value: 0,
  });

  odCalibrationForm = this.formBuilder.group({
    [this.flaskAValue1Field.key]: [{ value: null, disabled: true }, []],
    [this.flaskAValue2Field.key]: [{ value: null, disabled: true }, []],
    [this.flaskAValue3Field.key]: [{ value: null, disabled: true }, []],
    [this.flaskAValue4Field.key]: [{ value: null, disabled: true }, []],
    [this.flaskAValue5Field.key]: [{ value: null, disabled: true }, []],
    [this.flaskAValue6Field.key]: [{ value: null, disabled: true }, []],
    [this.flaskBValue1Field.key]: [{ value: null, disabled: true }, []],
    [this.flaskBValue2Field.key]: [{ value: null, disabled: true }, []],
    [this.flaskBValue3Field.key]: [{ value: null, disabled: true }, []],
    [this.flaskBValue4Field.key]: [{ value: null, disabled: true }, []],
    [this.flaskBValue5Field.key]: [{ value: null, disabled: true }, []],
    [this.flaskBValue6Field.key]: [{ value: null, disabled: true }, []],
    [this.flaskCValue1Field.key]: [{ value: null, disabled: true }, []],
    [this.flaskCValue2Field.key]: [{ value: null, disabled: true }, []],
    [this.flaskCValue3Field.key]: [{ value: null, disabled: true }, []],
    [this.flaskCValue4Field.key]: [{ value: null, disabled: true }, []],
    [this.flaskCValue5Field.key]: [{ value: null, disabled: true }, []],
    [this.flaskCValue6Field.key]: [{ value: null, disabled: true }, []],
    [this.flaskDValue1Field.key]: [{ value: null, disabled: true }, []],
    [this.flaskDValue2Field.key]: [{ value: null, disabled: true }, []],
    [this.flaskDValue3Field.key]: [{ value: null, disabled: true }, []],
    [this.flaskDValue4Field.key]: [{ value: null, disabled: true }, []],
    [this.flaskDValue5Field.key]: [{ value: null, disabled: true }, []],
    [this.flaskDValue6Field.key]: [{ value: null, disabled: true }, []],
  });

  /** @param {FormBase} experimentModeField The radio group for experiment mode selection */
  saturationToggle = new FormBase<string>({
    key: 'saturationToggle',
    label: 'Select oxygen saturation percentage',
    type: 'radio',
    placeholder: '',
    disabled: false,
    required: true,
    value: '',
    options: [
      { key: 1, value: '100%' },
      { key: 0, value: '0%' },
    ],
  });

  /**
   *  @param {FormGroup} selectSaturationForm Toggles the oxygen view between 0% and 100%
   */
  selectSaturationForm = this.formBuilder.group({
    [this.saturationToggle.key]: [1, []],
  });

  constructor(
    private route: ActivatedRoute,
    private calibrationService: CalibrationService,
    private dataService: DataService,
    private modalService: ModalService,
    private formBuilder: FormBuilder,
    private router: Router,
    private authService: AuthenticationService
  ) {}

  ngOnInit(): void {
    // Get calibration type and calibration ID from route parameters
    this.route.params.subscribe((params) => {
      this.calibrationType = params.calibrationType as string;
      this.calibrationId = params.id as string;
    });

    this.currentUser = this.authService.currentUserValue;
    this.getCalibrationFromApi(this.calibrationId, this.calibrationType);
  }

  getCalibrationFromApi(calibrationId: string, calibrationType: string): void {
    this.loading = true;
    this.fetchCalibrationError = null;
    switch (calibrationType) {
      case 'optical-density':
        this.calibrationService.getODCalibrationById(calibrationId).subscribe({
          next: (response: CalibrationResults) => {
            this.odCalibration = response;
            // If the calibration data has not been loaded yet, load it
            if (this.dataFirstLoad) {
              this.dataFirstLoad = false;
              this.getCalibrationDataFromApi();
            }
          },
          error: (error: ErrorObject) => {
            this.fetchCalibrationError = error;
            this.loading = false;
          },
        });
        break;
      case 'oxygen':
        this.calibrationService
          .getOxygenCalibrationById(calibrationId)
          .subscribe({
            next: (response: OxygenCalibration) => {
              this.oxygenCalibration = response;
              // Try to get the calibration data on first load of the page
              if (this.dataFirstLoad) {
                this.dataFirstLoad = false;
                this.getCalibrationDataFromApi();
              }
            },
            error: (error: ErrorObject) => {
              this.fetchCalibrationError = error;
              this.loading = false;
            },
          });
        break;
      case 'ph':
        this.calibrationService.getPhCalibrationById(calibrationId).subscribe({
          next: (response: PhCalibration) => {
            this.phCalibration = response;
            // If the data hasn't been loaded yet, load it
            if (this.dataFirstLoad) {
              this.dataFirstLoad = false;
              this.getCalibrationDataFromApi();
            }
          },
          error: (error: ErrorObject) => {
            this.fetchCalibrationError = error;
            this.loading = false;
          },
        });
        break;
      default:
        break;
    }
  }

  getCalibrationDataFromApi(): void {
    this.dataError = null;
    this.dataIsProcessing = true;
    this.dataFirstLoad = false;
    this.renderCalibrationGraph = false;
    switch (this.calibrationType) {
      case 'optical-density':
        this.dataService
          .getODCalibrationData(parseInt(this.calibrationId))
          .subscribe({
            next: (response: ICalibrationDevice) => {
              this.dataIsProcessing = false;
              this.timeOfLastUpdate = new Date();
              this.calibrationData.devices = [];
              if (response !== null) {
                const calDevice: ICalibrationDevice = {
                  deviceId: this.odCalibration.deviceId,
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                  flasks: response.flasks,
                };
                this.calibrationData.devices.push(calDevice);
                this.renderCalibrationGraph = true;
              }
            },
            error: (error: ErrorObject) => {
              this.dataError = error;
              this.dataIsProcessing = false;
              this.timeOfLastUpdate = new Date();
              this.renderCalibrationGraph = false;
            },
          });
        break;
      case 'oxygen':
        this.dataService
          .getOxygenCalibrationData(
            parseInt(this.calibrationId),
            this.oxygenSaturation
          )
          .subscribe({
            next: (response: IOxygenCalibrationDevice) => {
              this.dataIsProcessing = false;
              this.timeOfLastUpdate = new Date();
              this.oxygenCalibrationData.devices = [];
              if (response === null) {
                this.renderCalibrationGraph = false;
                return;
              }
              const calDevice: IOxygenCalibrationDevice = {
                deviceId: this.oxygenCalibration.deviceId,
                flasks: response.flasks,
              };
              this.oxygenCalibrationData.devices.push(calDevice);
              this.renderCalibrationGraph = true;
            },
            error: (error: ErrorObject) => {
              this.dataError = error;
              this.dataIsProcessing = false;
              this.timeOfLastUpdate = new Date();
              this.renderCalibrationGraph = false;
            },
          });
        break;
      case 'ph':
        this.dataService
          .getPhCalibrationData(parseInt(this.calibrationId))
          .subscribe({
            next: (response: IPhCalibrationDevice) => {
              this.dataIsProcessing = false;
              this.timeOfLastUpdate = new Date();
              this.calibrationData.devices = [];
              if (response === null) {
                this.renderCalibrationGraph = false;
                return;
              }
              const calDevice: IPhCalibrationDevice = {
                deviceId: this.phCalibration.deviceId,
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                flasks: response.flasks,
              };
              this.phCalibrationData.devices.push(calDevice);
              this.renderCalibrationGraph = true;
            },
            error: (error: ErrorObject) => {
              this.dataError = error;
              this.dataIsProcessing = false;
              this.timeOfLastUpdate = new Date();
              this.renderCalibrationGraph = false;
            },
          });
        break;
      default:
        break;
    }
  }

  discardCalibration(): void {
    this.discardIsProcessing = true;
    this.discardError = null;
    // Ensure that all polling is stopped
    this.stopVoltagePolling();
    this.stopCalibrationPolling();
    this.stopOxygenPolling();
    this.stopPHPolling();
    switch (this.calibrationType) {
      case 'optical-density':
        this.calibrationService
          .deleteODCalibrationById(this.odCalibration.id.toString())
          .subscribe({
            next: (response: boolean) => {
              if (response) {
                this.odCalibration = null;
                this.discardIsProcessing = false;
                this.closeModal('confirm-discard');
                this.closeModal('delete-calibration');
                // Now that the calibration has been deleted, open modal and prompt user to create a new calibration
                // or return to dashboard
                this.openModal('discard-success-response');
              } else {
                throw new Error('Unable to delete calibration');
              }
            },
            error: (error: ErrorObject) => {
              this.discardError = error;
              this.discardIsProcessing = false;
              this.closeModal('confirm-discard');
              this.openModal('discard-error-response');
            },
          });
        break;
      case 'oxygen':
        this.calibrationService
          .deleteOxygenCalibrationById(this.oxygenCalibration.id.toString())
          .subscribe({
            next: (response: boolean) => {
              if (response) {
                this.oxygenCalibration = null;
                this.closeModal('confirm-discard');
                this.closeModal('delete-calibration');
                // Now that the calibration has been deleted, open modal and prompt user to create a new calibration
                // or return to dashboard
                this.openModal('discard-success-response');
              } else {
                throw new Error('Unable to delete calibration');
              }
            },
            error: (error: ErrorObject) => {
              this.discardError = error;
              this.discardIsProcessing = false;
              this.openModal('discard-error-response');
            },
          });
        break;
      case 'ph':
        this.calibrationService
          .deletePhCalibrationById(this.phCalibration.id.toString())
          .subscribe({
            next: (response: boolean) => {
              if (response) {
                this.phCalibration = null;
                this.closeModal('confirm-discard');
                this.closeModal('delete-calibration');
                // Now that the calibration has been deleted, open modal and prompt user to create a new calibration
                // or return to dashboard
                this.openModal('discard-success-response');
              } else {
                throw new Error('Unable to delete calibration');
              }
            },
            error: (error: ErrorObject) => {
              this.discardError = error;
              this.discardIsProcessing = false;
              this.openModal('discard-error-response');
            },
          });
        break;
    }
  }

  getVoltageValues(): void {
    this.voltageError = null;
    this.voltagePollingShouldContinue = true;
    this.voltageLabel = 'Stop polling for voltage';
    switch (this.calibrationType) {
      case 'optical-density':
        this.voltagePollingSubscription = interval(30000)
          .pipe(
            startWith(0), // Emit immediately to trigger the first call
            switchMap(() => {
              return this.calibrationService.getVoltageReadings(
                this.odCalibration.id
              );
            }),
            tap((response) => {
              this.voltageReadings = response;
              if (this.voltageReadings) {
                this.mapVoltageReadingsToFormControls(this.voltageReadings);
              }
            }),
            takeWhile((response) => {
              this.voltagePollingShouldContinue =
                this.shouldContinueVoltagePolling(response);
              return this.voltagePollingShouldContinue;
            })
          )
          .subscribe({
            complete: () => {
              console.log(
                'Polling stopped. Final voltageReadings:',
                this.voltageReadings
              );
              this.voltageLabel = 'Polling Complete';
              this.startCalibrationPolling();
            },
            error: (error) => {
              console.error('Error fetching voltage readings:', error);
              // TODO: think this should set voltageError and stop polling
            },
          });
        break;
      case 'oxygen':
        break;
      case 'ph':
        break;
      default:
        break;
    }
  }

  mapVoltageReadingsToFormControls(voltageReadings: VoltageReadings): void {
    Object.keys(voltageReadings).forEach((key) => {
      const correspondingFormControlName = key.replace('Voltage', ''); // e.g., flaskAVoltageValue1 -> flaskAValue1
      const formControl = this.odCalibrationForm.get(
        correspondingFormControlName
      );

      if (formControl) {
        if (voltageReadings[key] === null) {
          formControl.disable(); // Disable if the voltage reading is null
        } else {
          formControl.enable(); // Enable if the voltage reading is not null
        }
      }
    });
  }

  finaliseCalibration(): void {
    this.finalisationIsProcessing = true;
    this.finalisationError = null;
    // Only implemented for OD calibration but likely to be implemented for other calibration types
    switch (this.calibrationType) {
      case 'optical-density':
        this.calibrationService
          .finaliseODCalibrationById(this.odCalibration.id.toString(), {
            flaskAReading1: this.odCalibrationForm.get('flaskAValue1')
              .value as number,
            flaskAReading2: this.odCalibrationForm.get('flaskAValue2')
              .value as number,
            flaskAReading3: this.odCalibrationForm.get('flaskAValue3')
              .value as number,
            flaskAReading4: this.odCalibrationForm.get('flaskAValue4')
              .value as number,
            flaskAReading5: this.odCalibrationForm.get('flaskAValue5')
              .value as number,
            flaskAReading6: this.odCalibrationForm.get('flaskAValue6')
              .value as number,
            flaskBReading1: this.odCalibrationForm.get('flaskBValue1')
              .value as number,
            flaskBReading2: this.odCalibrationForm.get('flaskBValue2')
              .value as number,
            flaskBReading3: this.odCalibrationForm.get('flaskBValue3')
              .value as number,
            flaskBReading4: this.odCalibrationForm.get('flaskBValue4')
              .value as number,
            flaskBReading5: this.odCalibrationForm.get('flaskBValue5')
              .value as number,
            flaskBReading6: this.odCalibrationForm.get('flaskBValue6')
              .value as number,
            flaskCReading1: this.odCalibrationForm.get('flaskCValue1')
              .value as number,
            flaskCReading2: this.odCalibrationForm.get('flaskCValue2')
              .value as number,
            flaskCReading3: this.odCalibrationForm.get('flaskCValue3')
              .value as number,
            flaskCReading4: this.odCalibrationForm.get('flaskCValue4')
              .value as number,
            flaskCReading5: this.odCalibrationForm.get('flaskCValue5')
              .value as number,
            flaskCReading6: this.odCalibrationForm.get('flaskCValue6')
              .value as number,
            flaskDReading1: this.odCalibrationForm.get('flaskDValue1')
              .value as number,
            flaskDReading2: this.odCalibrationForm.get('flaskDValue2')
              .value as number,
            flaskDReading3: this.odCalibrationForm.get('flaskDValue3')
              .value as number,
            flaskDReading4: this.odCalibrationForm.get('flaskDValue4')
              .value as number,
            flaskDReading5: this.odCalibrationForm.get('flaskDValue5')
              .value as number,
            flaskDReading6: this.odCalibrationForm.get('flaskDValue6')
              .value as number,
          })
          .subscribe({
            next: (response: boolean) => {
              this.hasSentFinalisationSignal = response;
              this.finalisationIsProcessing = false;
            },
            error: (error: ErrorObject) => {
              this.finalisationError = error;
              this.finalisationIsProcessing = false;
            },
          });
        break;
      case 'oxygen':
        break;
      case 'ph':
        break;
      default:
        break;
    }
  }

  startCalibrationPolling(): void {
    this.calibrationPollingShouldContinue = true;
    this.calibrationLabel = 'Polling for calibration data';
    // Also ensure that the voltage polling is stopped
    this.stopVoltagePolling();
    this.calibrationPollingSubscription = interval(30000)
      .pipe(
        startWith(0), // Emit immediately to trigger the first call
        switchMap(() => {
          return this.dataService.getODCalibrationData(this.odCalibration.id);
        }),
        tap((response) => {
          this.timeOfLastUpdate = new Date();
          this.calibrationData.devices = [];
          if (response !== null) {
            const calDevice: ICalibrationDevice = {
              deviceId: this.odCalibration.deviceId,
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              flasks: response.flasks,
            };
            this.calibrationData.devices.push(calDevice);
            this.renderCalibrationGraph = true;
          }
        }),
        switchMap(() => {
          return this.calibrationService.getODCalibrationById(
            this.odCalibration.id.toString()
          );
        }),
        tap((response) => {
          this.odCalibration = response;
        }),
        takeWhile(() => {
          this.calibrationPollingShouldContinue =
            this.shouldContinueCalibrationPolling();
          return this.calibrationPollingShouldContinue;
        })
      )
      .subscribe({
        complete: () => {
          console.log(
            'Polling stopped. Final calibration data:',
            this.odCalibration
          );
          this.calibrationLabel = 'Polling Complete';
        },
        error: (error: ErrorObject) => {
          console.error('Error fetching calibration data:', error);
          this.dataError = error;
          this.dataIsProcessing = false;
          this.timeOfLastUpdate = new Date();
          this.renderCalibrationGraph = false;
          this.calibrationPollingShouldContinue = false;
          this.stopCalibrationPolling();
        },
      });
  }

  startOxygenPolling(): void {
    this.oxygenPollingShouldContinue = true;
    this.oxygenPollingSubscription = interval(30000)
      .pipe(
        startWith(0), // Emit immediately to trigger the first call
        switchMap(() => {
          return this.dataService.getOxygenCalibrationData(
            parseInt(this.calibrationId),
            this.oxygenSaturation
          );
        }),
        tap((response) => {
          this.renderCalibrationGraph = false;
          this.timeOfLastUpdate = new Date();
          this.oxygenCalibrationData.devices = [];
          if (response === null) {
            this.renderCalibrationGraph = false;
            return;
          }
          const calDevice: IOxygenCalibrationDevice = {
            deviceId: this.oxygenCalibration.deviceId,
            flasks: response.flasks,
          };
          this.oxygenCalibrationData.devices.push(calDevice);
          this.renderCalibrationGraph = true;
        }),
        switchMap(() => {
          return this.calibrationService.getOxygenCalibrationById(
            this.oxygenCalibration.id.toString()
          );
        }),
        tap((response) => {
          this.oxygenCalibration = response;
        }),
        takeWhile(() => {
          this.oxygenPollingShouldContinue = !this.oxygenCalibration.isFinal;
          return this.oxygenPollingShouldContinue;
        })
      )
      .subscribe({
        complete: () => {
          console.log(
            'Polling stopped. Final oxygen data:',
            this.oxygenCalibration
          );
        },
        error: (error: ErrorObject) => {
          console.error('Error fetching oxygen data:', error);
          this.dataError = error;
          this.dataIsProcessing = false;
          this.timeOfLastUpdate = new Date();
          this.renderCalibrationGraph = false;
          this.oxygenPollingShouldContinue = false;
          this.stopOxygenPolling();
        },
      });
  }

  startPHPolling(): void {
    this.phPollingShouldContinue = true;
    this.phPollingSubscription = interval(30000)
      .pipe(
        startWith(0), // Emit immediately to trigger the first call
        switchMap(() => {
          return this.dataService.getPhCalibrationData(
            parseInt(this.calibrationId)
          );
        }),
        tap((response) => {
          this.renderCalibrationGraph = false;
          this.timeOfLastUpdate = new Date();
          this.calibrationData.devices = [];
          if (response === null) {
            this.renderCalibrationGraph = false;
            return;
          }
          const calDevice: IPhCalibrationDevice = {
            deviceId: this.phCalibration.deviceId,
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            flasks: response.flasks,
          };
          this.phCalibrationData.devices.push(calDevice);
          this.renderCalibrationGraph = true;
        }),
        switchMap(() => {
          return this.calibrationService.getPhCalibrationById(
            this.phCalibration.id.toString()
          );
        }),
        tap((response) => {
          this.phCalibration = response;
        }),
        takeWhile(() => {
          this.phPollingShouldContinue = !this.phCalibration.isFinal;
          return this.phPollingShouldContinue;
        })
      )
      .subscribe({
        complete: () => {
          console.log('Polling stopped. Final pH data:', this.phCalibration);
        },
        error: (error: ErrorObject) => {
          console.error('Error fetching pH data:', error);
          this.dataError = error;
          this.dataIsProcessing = false;
          this.timeOfLastUpdate = new Date();
          this.renderCalibrationGraph = false;
          this.phPollingShouldContinue = false;
          this.stopPHPolling();
        },
      });
  }

  handleOxygenSaturationToggle(oxygenSaturationValue: number): void {
    this.renderCalibrationGraph = false;
    this.oxygenDataReloading = true;
    this.oxygenSaturation = oxygenSaturationValue;
    this.stopOxygenPolling();
    this.startOxygenPolling();
  }

  stopCalibrationPolling() {
    if (this.calibrationPollingSubscription) {
      this.calibrationPollingSubscription.unsubscribe();
      this.calibrationPollingSubscription = null;
      this.calibrationPollingShouldContinue = false;
      this.calibrationLabel = 'Poll for calibration data';
    }
  }

  stopOxygenPolling() {
    if (this.oxygenPollingSubscription) {
      this.oxygenPollingSubscription.unsubscribe();
      this.oxygenPollingSubscription = null;
      this.oxygenPollingShouldContinue = false;
    }
  }

  stopPHPolling() {
    if (this.phPollingSubscription) {
      this.phPollingSubscription.unsubscribe();
      this.phPollingSubscription = null;
      this.phPollingShouldContinue = false;
    }
  }

  stopVoltagePolling() {
    if (this.voltagePollingSubscription) {
      this.voltagePollingSubscription.unsubscribe();
      this.voltagePollingSubscription = null;
      this.voltagePollingShouldContinue = false;
      if (this.voltageReadings === null) {
        this.voltageLabel = 'Poll for voltage';
      }
    }
  }

  private shouldContinueCalibrationPolling(): boolean {
    return !this.odCalibration.isFinal;
  }

  private shouldContinueVoltagePolling(response: VoltageReadings): boolean {
    if (response === null) {
      return true;
    }
    return (
      Object.values(response).every((value) => value === null) &&
      !this.odCalibration.isFinal
    );
  }

  // TODO: add canDeactivate guard to ensure we unsubscribe from polling

  ngOnDestroy(): void {
    this.stopVoltagePolling();
    this.stopCalibrationPolling();
    this.stopOxygenPolling();
    this.stopPHPolling();
  }

  openModal(modalId: string): void {
    this.modalService.open(modalId);
  }

  closeModal(modalId: string): void {
    if (modalId === 'discard-error-response') {
      // Reset discard response modal text
      this.discardError = null;
    }
    this.modalService.close(modalId);
  }

  modalButtonClicked(buttonId: string): void {
    switch (buttonId) {
      case 'success-primary-button':
      case 'error-primary-button':
        void this.router.navigate(['/dashboard']);
        break;
      case 'success-secondary-button':
        void this.router.navigate(['calibration/create-calibration']);
        break;
      case 'error-secondary-button':
        this.discardError = null;
        this.closeModal('discard-error-response');
        break;
      case 'discard-confirm-button':
        this.discardIsProcessing = true;
        this.discardCalibration();
        break;
      case 'discard-cancel-button':
        this.closeModal('confirm-discard');
        break;
      default:
        break;
    }
  }
}
