import { Component, Input } from '@angular/core';
import { TabOptions } from 'src/app/_models/tab-options';

@Component({
  selector: 'app-option-tab-toggle',
  templateUrl: './option-tab-toggle.component.html',
  styleUrls: ['./option-tab-toggle.component.scss'],
})
export class OptionTabToggleComponent {
  title: 'OptionTabToggle';
  component: '';

  /**
   * @requires
   * What is the name of the radio group?
   */
  @Input()
  radioGroup: string;

  /**
   * What value and text for each button?
   * @requires
   */
  @Input()
  selectOptions: TabOptions[] = [];

  /**
   * What type of button?
   */
  @Input()
  type: 'round' | 'square' = 'round';

  public get buttonClasses(): string[] {
    return ['radio-item', 'body2', `radio-item--${this.type}`];
  }

  public get containerClasses(): string[] {
    return ['select-container', `select-container--${this.type}`];
  }

  public get labelClasses(): string[] {
    return ['label', `label--${this.type}`, 'subtitle1'];
  }
}
