import { Component } from '@angular/core';
import { IconNames } from 'src/app/_components/icons/icon-names';

@Component({
  selector: 'app-reset-successful',
  templateUrl: './reset-successful.component.html',
  styleUrls: ['./reset-successful.component.scss'],
})
export class ResetSuccessfulComponent {
  logo = IconNames.LogoColour;
}
