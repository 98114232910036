export class Checkbox {
  id: string;
  labelText: string;
  iconName: string;

  constructor(id: string, labelText: string, iconName: string) {
    this.id = id;
    this.labelText = labelText;
    this.iconName = iconName;
  }
}
