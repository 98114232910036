import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import {
  Calibration,
  CalibrationResults,
  FinaliseCalibrationInputSet,
  VoltageReadings,
  CalibrationSlot,
} from '../_models/calibration';
import { DeviceCalibrationSummary } from '../_models/device-calibration-summary';
import { OxygenCalibration } from '../_models/oxygen-calibration';
import { Module } from '../_models/module-enum';
import { PhCalibration } from '../_models/ph-calibration';
import { httpOptions } from '../_helpers/utils';
import { AuthenticationService } from './authentication.service';
import {
  CalibrationResponse,
  OxygenCalibrationResponse,
  PhCalibrationResponse,
} from '../_models/api-responses';
import { PaginationData } from '../_models/pagination-data.model';

@Injectable({
  providedIn: 'root',
})
export class CalibrationService {
  httpOptions = httpOptions;
  selectedlaboratoryId: number;

  // Pagination and sort/filter observables for device calibration index views
  private readonly tableSort$ = new BehaviorSubject<{
    sortBy: string;
    sortDirection: string;
  }>(null);
  private readonly tableFilter$ = new BehaviorSubject<string[]>([]);
  private readonly pageNumber$ = new BehaviorSubject<number>(null);
  private readonly pageSize$ = new BehaviorSubject<number>(null);

  private readonly createCalibrationFormSubject$ =
    new BehaviorSubject<Calibration>(null);

  constructor(
    private readonly http: HttpClient,
    private readonly authenticationService: AuthenticationService
  ) {
    this.selectedlaboratoryId =
      this.authenticationService.selectedLaboratory?.laboratoryId;
  }

  onUpdateSort = (sort: { sortBy: string; sortDirection: string }): void => {
    this.tableSort$.next(sort);
  };

  onUpdateFilter = (filter: string[]): void => {
    this.tableFilter$.next(filter);
  };

  onUpdatePageNumber = (pageNumber: number): void => {
    this.pageNumber$.next(pageNumber);
  };

  onUpdatePageSize = (pageSize: number): void => {
    this.pageSize$.next(pageSize);
  };

  onUpdateCreateCalibrationFormCache = (
    createCalibrationFormCache: Calibration
  ): void => {
    this.createCalibrationFormSubject$.next(createCalibrationFormCache);
  };

  public get filter(): string[] {
    return this.tableFilter$.value;
  }

  public get sort(): { sortBy: string; sortDirection: string } {
    return this.tableSort$.value;
  }

  public get paginationCache(): { pageNumber: number; pageSize: number } {
    if (this.pageNumber$.value !== null && this.pageSize$.value !== null) {
      return {
        pageNumber: this.pageNumber$.value,
        pageSize: this.pageSize$.value,
      };
    } else {
      return null;
    }
  }

  public get createCalibrationFormCache(): Calibration {
    return this.createCalibrationFormSubject$.value;
  }

  // HTTP GET /CALIBRATION/byDeviceArray
  getCalibrationsByDeviceArray(
    deviceList: DeviceCalibrationSummary[],
    isLoadedOnDevice = true,
    isFinalOnly = false
  ): Observable<Calibration[]> {
    let url = `${environment.apiUrl}/calibrations/by-device-array?`;
    deviceList.forEach((device: DeviceCalibrationSummary) => {
      url = url + `deviceIds=${device.deviceId}&`;
    });
    url = url + `loadedOnDeviceOnly=${isLoadedOnDevice ? 'true' : 'false'}`;
    url = url + `&isFinalOnly=${isFinalOnly ? 'true' : 'false'}`;
    return this.http.get<Calibration[]>(url, this.httpOptions).pipe(
      map((response: HttpResponse<Calibration[]>) => {
        // Try explicitly building the response object due to issue with find index
        const calibrationList: Calibration[] = [];
        if (response.status === 204) {
          return calibrationList;
        }
        if (response.status === 200) {
          response?.body?.forEach((c) => {
            const calibration = new Calibration(
              c.deviceId,
              c.name,
              c.saveLocationName,
              c.temperatureControlA,
              c.temperatureControlB,
              c.temperatureControlC,
              c.temperatureControlD
            );
            calibration.id = c.id;
            calibrationList.push(calibration);
          });
          return calibrationList;
        }
        return null;
      })
    );
  }

  getOxygenCalibrationsByDeviceArray(
    deviceList: DeviceCalibrationSummary[],
    isLoadedOnDevice = true,
    isFinalOnly = false
  ): Observable<OxygenCalibration[]> {
    let url = `${environment.apiUrl}/oxygen-calibrations/by-device-array?`;
    deviceList.forEach((device: DeviceCalibrationSummary) => {
      url = url + `deviceIds=${device.deviceId}&`;
    });
    url = url + `loadedOnDeviceOnly=${isLoadedOnDevice ? 'true' : 'false'}`;
    url = url + `&isFinalOnly=${isFinalOnly ? 'true' : 'false'}`;
    return this.http.get<OxygenCalibration[]>(url, this.httpOptions).pipe(
      map((response: HttpResponse<OxygenCalibration[]>) => {
        // Try explicitly building the response object due to issue with find index
        const calibrationList: OxygenCalibration[] = [];
        if (response.status === 204) {
          return calibrationList;
        }
        if (response.status === 200) {
          return response?.body;
        }
        return null;
      })
    );
  }

  getPhCalibrationsByDeviceArray(
    deviceList: DeviceCalibrationSummary[],
    isLoadedOnDevice = true,
    isFinalOnly = false
  ): Observable<PhCalibration[]> {
    let url = `${environment.apiUrl}/ph-calibrations/by-device-array?`;
    deviceList.forEach((device: DeviceCalibrationSummary) => {
      url = url + `deviceIds=${device.deviceId}&`;
    });
    url = url + `loadedOnDeviceOnly=${isLoadedOnDevice ? 'true' : 'false'}`;
    url = url + `&isFinalOnly=${isFinalOnly ? 'true' : 'false'}`;
    return this.http.get<PhCalibration[]>(url, this.httpOptions).pipe(
      map((response: HttpResponse<PhCalibration[]>) => {
        // Try explicitly building the response object due to issue with find index
        const calibrationList: PhCalibration[] = [];
        if (response.status === 204) {
          return calibrationList;
        }
        if (response.status === 200) {
          response?.body?.forEach((c) => {
            const calibration = new PhCalibration(
              c.deviceId,
              c.name,
              c.saveLocationName,
              c.controlTemperature,
              c.temperature,
              c.bufferIds,
              c.slope_A,
              c.slope_B,
              c.slope_C,
              c.slope_D,
              c.offset_A,
              c.offset_B,
              c.offset_C,
              c.offset_D,
              c.calFactor_A,
              c.calFactor_B,
              c.calFactor_C,
              c.calFactor_D,
              c.calOffset_A,
              c.calOffset_B,
              c.calOffset_C,
              c.calOffset_D,
              c.r2_A,
              c.r2_B,
              c.r2_C,
              c.r2_D,
              c.id,
              c.isFinal
            );
            calibrationList.push(calibration);
          });
          return calibrationList;
        }
        return null;
      })
    );
  }

  // HTTP POST /calibration
  createODCalibration(calibration: Calibration): Observable<Calibration> {
    calibration.laboratoryId = this.selectedlaboratoryId;
    return this.http
      .post<Calibration>(`${environment.apiUrl}/calibrations`, calibration)
      .pipe(
        map((response: Calibration) => {
          return response;
        })
      );
  }

  // HTTP POST /oxygenCalibration
  createOxygenCalibration(
    calibration: OxygenCalibration
  ): Observable<OxygenCalibration> {
    calibration.laboratoryId = this.selectedlaboratoryId;
    return this.http
      .post<OxygenCalibration>(
        `${environment.apiUrl}/oxygen-calibrations`,
        calibration
      )
      .pipe(
        map((response: OxygenCalibration) => {
          return response;
        })
      );
  }

  // HTTP POST /PHCalibration
  createPHCalibration(calibration: PhCalibration): Observable<PhCalibration> {
    calibration.laboratoryId = this.selectedlaboratoryId;
    return this.http
      .post<PhCalibration>(`${environment.apiUrl}/ph-calibrations`, calibration)
      .pipe(
        map((response: PhCalibration) => {
          return response;
        })
      );
  }

  // HTTP GET /memorySlot/deviceId/moduleId/memorySlotDetails
  getSlotSaveLocations(
    deviceId: number,
    moduleId: Module
  ): Observable<CalibrationSlot[]> {
    return this.http
      .get<CalibrationSlot[]>(
        `${environment.apiUrl}/memory-slots/${deviceId}/${moduleId}`
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getCalibrationsByDeviceId(
    deviceId: number,
    sortBy = 'Name',
    sortOrder = 'asc',
    pageSize?: number,
    pageNumber?: number
  ): Observable<CalibrationResponse> {
    let url = `${environment.apiUrl}/calibrations/${deviceId}/calibrations?OrderBy=${sortBy}&OrderByDirection=${sortOrder}`;

    if (pageNumber && pageSize) {
      url = url + `&PageSize=${pageSize}&PageNumber=${pageNumber}`;
    }

    return this.http.get<Calibration[]>(url, this.httpOptions).pipe(
      map((response) => {
        // Set pagination data
        const paginationData = JSON.parse(
          response.headers.get('X-Pagination')
        ) as PaginationData;
        // Try explicitly building the response object due to issue with find index
        const calibrationList: Calibration[] = [];
        if (response.status === 204) {
          return {
            paginationData,
            calibrationList,
          };
        }
        if (response.status === 200) {
          response?.body?.forEach((c) => {
            const calibration = new Calibration(
              c.deviceId,
              c.name,
              c.saveLocationName,
              c.temperatureControlA,
              c.temperatureControlB,
              c.temperatureControlC,
              c.temperatureControlD
            );
            calibration.id = c.id;
            calibration.isFinal = c.isFinal;
            calibration.createdAt = c.createdAt;
            calibrationList.push(calibration);
          });
        }
        return {
          paginationData,
          calibrationList,
        };
      })
    );
  }

  getOxygenCalibrationsByDeviceId(
    deviceId: string,
    sortBy = 'Name',
    sortOrder = 'asc',
    pageSize = 5,
    pageNumber = 1
  ): Observable<OxygenCalibrationResponse> {
    let url = `${environment.apiUrl}/oxygen-calibrations/${deviceId}/oxygen-calibrations?OrderBy=${sortBy}&OrderByDirection=${sortOrder}`;
    if (pageNumber && pageSize) {
      url = url + `&PageSize=${pageSize}&PageNumber=${pageNumber}`;
    }
    return this.http.get<OxygenCalibration[]>(url, this.httpOptions).pipe(
      map((response: HttpResponse<OxygenCalibration[]>) => {
        // Set pagination data
        const paginationData = JSON.parse(
          response.headers.get('X-Pagination')
        ) as PaginationData;
        // Try explicitly building the response object due to issue with find index
        const calibrationList: OxygenCalibration[] = [];
        if (response.status === 204) {
          return { paginationData, calibrationList };
        }
        if (response.status === 200) {
          response?.body.forEach((c) => {
            const oxygenCalibration = new OxygenCalibration(
              c.deviceId,
              c.name,
              c.saveLocationName,
              c.temperatureControlA,
              c.temperatureControlB,
              c.temperatureControlC,
              c.temperatureControlD
            );
            oxygenCalibration.id = c.id;
            oxygenCalibration.isFinal = c.isFinal;
            oxygenCalibration.createdAt = c.createdAt;
            oxygenCalibration.isZeroPercent = c.isZeroPercent;
            oxygenCalibration.is100Percent = c.is100Percent;
            calibrationList.push(oxygenCalibration);
          });
          return { paginationData, calibrationList };
        }
        return null;
      })
    );
  }

  getPHCalibrationsByDeviceId(
    deviceId: string,
    sortBy = 'Name',
    sortOrder = 'asc',
    pageSize = 5,
    pageNumber = 1
  ): Observable<PhCalibrationResponse> {
    let url = `${environment.apiUrl}/ph-calibrations/${deviceId}/ph-calibrations?OrderBy=${sortBy}&OrderByDirection=${sortOrder}`;
    if (pageNumber && pageSize) {
      url = url + `&PageSize=${pageSize}&PageNumber=${pageNumber}`;
    }
    return this.http.get<PhCalibration[]>(url, this.httpOptions).pipe(
      map((response: HttpResponse<PhCalibration[]>) => {
        // Set pagination data
        const paginationData = JSON.parse(
          response.headers.get('X-Pagination')
        ) as PaginationData;
        const calibrationList: PhCalibration[] = [];
        if (response.status === 204) {
          return { calibrationList, paginationData };
        }
        if (response.status === 200) {
          // Try explicitly building the response object due to issue with find index
          response?.body?.forEach((c) => {
            const phCalibration = new PhCalibration(
              c.deviceId,
              c.name,
              c.saveLocationName,
              c.controlTemperature,
              c.temperature,
              c.bufferIds
            );
            phCalibration.id = c.id;
            phCalibration.isFinal = c.isFinal;
            phCalibration.createdAt = c.createdAt;
            calibrationList.push(phCalibration);
          });
          return { calibrationList, paginationData };
        }
        return null;
      })
    );
  }

  getODCalibrationById(id: string): Observable<CalibrationResults> {
    return this.http
      .get<CalibrationResults>(`${environment.apiUrl}/calibrations/${id}`)
      .pipe(
        map((response: CalibrationResults) => {
          // TODO check all properties are getting filled
          return response;
        })
      );
  }

  getOxygenCalibrationById(id: string): Observable<OxygenCalibration> {
    return this.http
      .get<OxygenCalibration>(`${environment.apiUrl}/oxygen-calibrations/${id}`)
      .pipe(
        map((response: OxygenCalibration) => {
          return response;
        })
      );
  }

  getPhCalibrationById(id: string): Observable<PhCalibration> {
    return this.http
      .get<PhCalibration>(`${environment.apiUrl}/ph-calibrations/${id}`)
      .pipe(
        map((response: PhCalibration) => {
          return response;
        })
      );
  }

  // DELETE /calibration/{id} - only works for calibrations that are not final
  deleteODCalibrationById(id: string): Observable<boolean> {
    return this.http
      .delete<boolean>(`${environment.apiUrl}/calibrations/${id}`)
      .pipe(
        map((response: boolean) => {
          return response;
        })
      );
  }

  // DELETE /oxygenCalibration/{id} - only works for calibrations that are not final
  deleteOxygenCalibrationById(id: string): Observable<boolean> {
    return this.http
      .delete<boolean>(`${environment.apiUrl}/oxygen-calibrations/${id}`)
      .pipe(
        map((response: boolean) => {
          return response;
        })
      );
  }

  // DELETE /PHCalibration/{id} - only works for calibrations that are not final
  deletePhCalibrationById(id: string): Observable<boolean> {
    return this.http
      .delete<boolean>(`${environment.apiUrl}/ph-calibrations/${id}`)
      .pipe(
        map((response: boolean) => {
          return response;
        })
      );
  }

  finaliseODCalibrationById(
    id: string,
    inputValues: FinaliseCalibrationInputSet
  ): Observable<boolean> {
    return this.http
      .patch<boolean>(
        `${environment.apiUrl}/calibrations/${id}/finalise`,
        inputValues
      )
      .pipe(
        map((response: boolean) => {
          return response;
        })
      );
  }

  getVoltageReadings(id: number): Observable<VoltageReadings> {
    return this.http
      .get<VoltageReadings>(
        `${environment.apiUrl}/calibrations/${id}/voltage-readings`
      )
      .pipe(
        map((response: VoltageReadings) => {
          return response;
        })
      );
  }
}
