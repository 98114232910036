@if (laboratory) {
  <div>
    <div id="title--container">
      <h4>Add a new user</h4>
      <h5>{{ laboratory.laboratoryName }}</h5>
    </div>
    <hr />
    <div id="account--container">
      <div class="body1">
        Please complete the details below to add a new user.
      </div>
      <div class="form--container">
        <form [formGroup]="addUserForm">
          <app-underlined-form-field
            [parentForm]="addUserForm"
            [field]="firstNameField"
          ></app-underlined-form-field>
          <app-underlined-form-field
            [parentForm]="addUserForm"
            [field]="lastNameField"
          ></app-underlined-form-field>
          <app-underlined-form-field
            [parentForm]="addUserForm"
            [field]="emailField"
          ></app-underlined-form-field>
          <app-underlined-dropdown-form-field
            [field]="userRoleField"
            [parentForm]="addUserForm"
          ></app-underlined-dropdown-form-field>
          <app-underlined-dropdown-form-field
            [field]="availableLabsField"
            [parentForm]="addUserForm"
          ></app-underlined-dropdown-form-field>
        </form>
        <h6>Selected Labs</h6>
        @if (selectedLabs?.length < 1 && !labSelectError) {
          <span>No labs selected</span>
        }
        @if (selectedLabs?.length > 0) {
          <div id="lab-list--container" class="body1">
            @for (lab of selectedLabs; track lab) {
              <span>{{ lab.laboratoryName }}</span>
              <app-icon-button
                buttonId="remove-lab-btn"
                buttonStyle="transparent"
                buttonShape="square"
                textColor="red"
                iconName="close"
                (buttonClicked)="removeLab(lab)"
              ></app-icon-button>
            }
          </div>
        }
        @if (labSelectError) {
          <span class="error"> {{ labSelectError }} </span>
        }
        <div class="button--container">
          <app-button
            label="Add User"
            buttonId="add-btn"
            (buttonClicked)="onSubmit()"
            [isDisabled]="addUserForm.invalid"
            [isProcessing]="isProcessing"
          >
          </app-button>
        </div>
      </div>
    </div>
  </div>
} @else {
  <app-error-page
    title="No Lab found"
    primaryText="Sorry, you cannot access this page unless you are a member of a lab"
  ></app-error-page>
}
<app-modal
  id="create-account"
  titleText="Success"
  [primaryText]="addUserSuccessMessage"
  buttonType="single-button"
  primaryButtonText="close"
  primaryButtonId="add-user-button"
  modalStyle="success"
  (modalButtonClicked)="modalButtonClicked($event)"
></app-modal>
<app-modal
  id="error"
  titleText="Error"
  [primaryText]="error?.message"
  buttonType="single-button"
  primaryButtonText="close"
  primaryButtonId="error-button"
  modalStyle="error"
  (modalButtonClicked)="modalButtonClicked($event)"
></app-modal>
<app-modal
  id="attention"
  titleText="Attention"
  primaryText="Your changes have not been saved. Press cancel to return to the form and save your changes, or continue to discard."
  buttonType="double-button"
  primaryButtonText="Continue"
  primaryButtonId="confirm-navigation"
  secondaryButtonText="Cancel"
  secondaryButtonId="cancel-button"
  modalStyle="warning"
  (modalButtonClicked)="modalButtonClicked($event)"
></app-modal>
