import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
  /**
   * @param {string} buttonStyle The button style required
   * @requires
   */
  @Input()
  buttonStyle:
    | 'standard'
    | 'secondary'
    | 'positive'
    | 'negative'
    | 'yellow'
    | 'green' = 'standard';

  /**
   * @param {string} type The button type required
   * @requires
   */
  @Input()
  type: 'button' | 'submit' | 'reset' = 'button';

  /**
   * @param {string} label The button text
   * @requires
   */
  @Input()
  label = 'BUTTON';

  /**
   * @param {string} buttonId The id of the button
   * @requires
   */
  @Input()
  buttonId: string | undefined;

  /**
   * @param {string} labelClass The button label styling
   * @requires
   */
  @Input()
  labelClass: 'large' | '' = '';

  /**
   * @param {boolean} isDisabled Is the button disabled
   */
  @Input()
  isDisabled = false;

  /**
   * @param {boolean} isProcessing Is the button processing
   */
  @Input()
  isProcessing = false;

  /**
   * Output click event
   */
  @Output()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  buttonClicked: EventEmitter<any> = new EventEmitter();

  public get classes(): string[] {
    const loading = this.isProcessing ? 'loading-button' : '';
    const disabled = this.isDisabled ? 'disabled' : '';
    const labelClass =
      this.labelClass === 'large' ? 'button-large' : 'button-text';
    return [
      'button',
      `button--${this.labelClass}`,
      loading,
      `button--${this.buttonStyle}`,
      labelClass,
      disabled,
    ];
  }

  // just to test crlf change
  clickButton = (): void => {
    this.buttonClicked.emit();
  };
}
