import { Component, input } from '@angular/core';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'app-validation-row',
  standalone: true,
  imports: [MatIcon],
  templateUrl: './validation-row.component.html',
  styleUrl: './validation-row.component.scss',
})
export class ValidationRowComponent {
  /** The validation text */
  validationText = input<string | undefined>(undefined);

  /** Whether the item passes validation */
  passesValidation = input<boolean>(false);
}
