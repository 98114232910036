<img id="logo" src="assets/img/ogi-logo.png" alt="" />
<div id="page">
  <div id="container">
    <h4>Login</h4>
    <div class="error">
      @if (loginError.message) {
        <span>Error: </span>{{ loginError.message }}
      }
    </div>
    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
      <app-mat-form-field
        iconName="email"
        [field]="emailField"
        [parentForm]="loginForm"
      >
      </app-mat-form-field>
      <div>
        <app-mat-form-field
          iconName="lock"
          [field]="passwordField"
          [parentForm]="loginForm"
        ></app-mat-form-field>
        <div class="options__container">
          <app-checkbox
            [checkbox]="rememberMe"
            [parentForm]="loginForm"
          ></app-checkbox>
          <a [routerLink]="'/forgot-password'">Forgot password?</a>
        </div>
      </div>
      <app-button
        type="submit"
        buttonStyle="standard"
        label="Login"
        buttonId="login"
        hasFill="true"
        (buttonClicked)="onSubmit()"
        [isDisabled]="loginForm.invalid"
        [isProcessing]="isProcessing"
      >
      </app-button>
    </form>
  </div>
</div>
