export class PhBuffer {
  name: string;
  laboratoryId: number;
  temperaturePHValuePairs: PhTempPair[];
  id?: number;
  devices?: DeviceSummary[];
  organisationId?: number;

  constructor(
    name: string,
    laboratoryId: number,
    temperaturePHValuePairs: PhTempPair[],
    id?: number,
    devices?: DeviceSummary[],
    organisationId?: number
  ) {
    this.name = name;
    this.laboratoryId = laboratoryId;
    this.temperaturePHValuePairs = temperaturePHValuePairs;
    this.id = id;
    this.devices = devices;
    this.organisationId = organisationId || null;
  }
}

export interface PhTempPair {
  temperature: number;
  phValue: number;
}

export interface DeviceSummary {
  name: string;
}
