import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-icon-button',
  templateUrl: './icon-button.component.html',
  styleUrls: ['./icon-button.component.scss'],
})
export class IconButtonComponent {
  /**
   * @param {string} iconName The required icon
   * @requires
   */
  @Input()
  iconName: string;

  @Input()
  /**
   * @param {string} buttonShape The button shape
   */
  @Input()
  buttonShape: 'round' | 'square' = 'round';

  /**
   * @param {string} buttonStyle The button style
   */
  @Input()
  buttonStyle:
    | 'transparent--blue'
    | 'transparent'
    | 'primary'
    | 'secondary'
    | 'pause'
    | 'outlined'
    | 'positive'
    | 'negative' = 'primary';

  @Input()
  /**
   * @param {string} textColor The colour of text
   */
  @Input()
  textColor: 'red' | 'standard' = 'standard';

  /**
   * @param {string} label The button text
   */
  @Input()
  label: string;

  /**
   * @param {string} labelClass The button label styling
   * @requires
   */
  @Input()
  labelClass: 'large' | '' = '';

  /**
   * @param {string} buttonType The type of button required
   */
  @Input()
  buttonType: 'button' | 'submit' | 'reset' = 'button';

  /**
   * @param {string} buttonId The button id
   * @requires
   */
  @Input()
  buttonId: string | undefined;

  /**
   * @param {boolean} isDisabled Is the button disabled
   */
  @Input()
  isDisabled = false;

  /**
   * @param {boolean} isProcessing Is the button processing
   */
  @Input()
  isProcessing = false;

  /**
   * Output event on click
   */
  @Output()
  buttonClicked: EventEmitter<void> = new EventEmitter<void>();

  public get classes(): string[] {
    const disabled = this.isDisabled ? 'disabled' : '';
    const hasText = this.label ? 'icon-button--text' : '';
    const textColor = this.textColor
      ? `icon-button--text--${this.textColor}`
      : '';
    const labelClass =
      this.labelClass === 'large' ? 'button-large' : 'button-text';

    return [
      'icon-button',
      this.buttonShape,
      'button-text',
      `icon-button--${this.labelClass}`,
      `icon-button--${this.buttonStyle}`,
      textColor,
      hasText,
      labelClass,
      disabled,
    ];
  }

  public get iconClasses(): string[] {
    const processing = this.isProcessing ? 'spin' : '';
    return [processing];
  }

  clickButton = (): void => {
    this.buttonClicked.emit();
  };
}
