import {
  Component,
  EventEmitter,
  Input,
  Output,
  ElementRef,
  OnDestroy,
  OnInit,
  ChangeDetectionStrategy,
  inject,
} from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormBase } from 'src/app/_models/form-base';
import { ModalService } from 'src/app/_services/modal.service';
import { ButtonComponent } from '../../buttons/button/button.component';
import { SlideToggleComponent } from '../../selection-controls/slide-toggle/slide-toggle.component';
import { CheckboxGroupComponent } from '../../selection-controls/checkbox-group/checkbox-group.component';
import { UnderlinedDropdownFormFieldComponent } from '../../forms/underlined-dropdown-form-field/underlined-dropdown-form-field.component';
import { IconButtonComponent } from '../../buttons/icon-button/icon-button.component';

@Component({
  selector: 'app-filter-modal',
  templateUrl: './filter-modal.component.html',
  styleUrl: './filter-modal.component.scss',
  standalone: true,
  imports: [
    IconButtonComponent,
    FormsModule,
    ReactiveFormsModule,
    UnderlinedDropdownFormFieldComponent,
    CheckboxGroupComponent,
    SlideToggleComponent,
    ButtonComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterModalComponent implements OnInit, OnDestroy {
  private readonly elementRef = inject<ElementRef<HTMLElement>>(ElementRef);
  private readonly modalService = inject(ModalService);

  private readonly element: HTMLElement;
  /**
   * @param {FormBase<string>} statusField Checkbox base field
   */
  @Input()
  statusField: FormBase<string>;

  /**
   * @param {FormBase<string>} modeField Select base field
   */
  @Input()
  modeField: FormBase<string>;

  /**
   * @param {FormBase<string>} modulesField Checkbox base field
   */
  @Input()
  modulesField: FormBase<string>;

  /**
   * @param {FormBase<string>} offlineField Checkbox base field
   */
  @Input()
  offlineField: FormBase<string>;

  /**
   * @param {FormBase<string>} deletedField Checkbox base field
   * @requires
   */
  @Input()
  deletedField: FormBase<string>;

  /**
   * The parent form
   */
  @Input()
  parentForm: FormGroup = new FormGroup({});

  /**
   * @param {string} id What is the id of the modal container?
   * @required
   */
  @Input()
  id: string;

  /**
   * @param {string} buttonType Does the modal have a single or double button?
   * @required
   */
  @Input()
  buttonType: 'single-button' | 'double-button' = 'single-button';

  /**
   * @param {string} primaryButtonText What is the text for the primary button?
   * @required
   */
  @Input()
  primaryButtonText: 'Primary Button';

  /**
   * @param {string} primaryButtonId What is the id for the primary button?
   * @required
   */
  @Input()
  primaryButtonId: string;

  /**
   * @param {string} secondaryButtonText What is the text for the secondary button?
   */
  @Input()
  secondaryButtonText: string;

  /**
   * @param {string} secondaryButtonId What is the id for the secondary button?
   */
  @Input()
  secondaryButtonId: string;

  /**
   * @param {boolean} isProcessing Is the modal processing
   */
  @Input()
  isProcessing = false;

  /**
   * @param {boolean} hideDeletedField Hide deleted field
   */
  @Input()
  hideDeletedField = false;

  /**
   * Output event for clicked button on modal
   */
  @Output()
  modalButtonClicked: EventEmitter<string> = new EventEmitter<string>();

  /**
   * Output event for clicked button on modal
   */
  @Output()
  moduleCheckboxClicked: EventEmitter<Event> = new EventEmitter<Event>();

  constructor() {
    this.element = this.elementRef.nativeElement;
  }

  ngOnInit(): void {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const modal = this;

    // Close modal on background click - only if not processing a rejection
    if (!this.isProcessing) {
      this.element.addEventListener('click', (e: Event) => {
        const target = e.target as HTMLElement;
        if (target.className === 'modal--container') {
          modal.close();
        }
      });
    }
    // add self (this modal instance) to the modal service so it's accessible from controllers
    this.modalService.add(this);
    document.body.appendChild(this.element);
  }

  updateValue(event: Event): void {
    this.moduleCheckboxClicked.emit(event);
  }

  // open modal
  open(): void {
    this.element.style.display = 'block';
  }

  // close modal
  close(): void {
    // Reset valdiation text value and close modal
    this.element.style.display = 'none';
  }

  buttonEventHandler(valueEmitted: string): void {
    this.modalButtonClicked.emit(valueEmitted);
  }

  // Remove self from modal service when component is destroyed
  ngOnDestroy(): void {
    this.modalService.remove(this.id);
    this.element.remove();
  }
}
