import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-loading-spinner',
  templateUrl: './loading-spinner.component.html',
  styleUrls: ['./loading-spinner.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingSpinnerComponent {
  /**
   * @param {string} label Label
   */
  @Input()
  label = 'Loading';

  /**
   * @param {boolean} isProcessing Is processing
   * @default false
   */
  @Input()
  isProcessing = true;
}
