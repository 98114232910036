<div class="modal--container">
  <div class="modal-body">
    <div
      #lottieContainer
      id="lottie-container"
      *ngIf="animationType === 'test-tubes'"
    ></div>
    <mat-icon *ngIf="iconName">{{ iconName }}</mat-icon>
    <form [formGroup]="validationForm">
      <h4>{{ titleText }}</h4>
      <span class="body1">
        Type
        <strong> {{ validationText.toUpperCase() }} </strong> into the field
        below.</span
      >
      <label class="overline" [for]="validationText">
        <div class="underlined-form-field-input-container">
          <input
            class="body1 underlined-form-field-input"
            type="text"
            formControlName="validationText"
            [placeholder]="validationText"
          />
        </div>
      </label>
      <div class="error--container" *ngIf="error">
        <p class="error">Error: {{ error.message }}</p>
      </div>
      <div class="button--container">
        <app-button
          class="modal-button"
          *ngIf="buttonType === 'double-button'"
          buttonStyle="standard"
          [label]="secondaryButtonText"
          [buttonId]="secondaryButtonId"
          (buttonClicked)="close()"
        ></app-button>
        <app-button
          class="modal-button"
          type="submit"
          *ngIf="buttonType"
          buttonStyle="standard"
          [label]="primaryButtonText"
          [buttonId]="primaryButtonId"
          (buttonClicked)="buttonEventHandler(primaryButtonId)"
          [isDisabled]="buttonIsDisabled"
        ></app-button>
      </div>
    </form>
  </div>
</div>
<div class="modal--backdrop"></div>
