<div id="title--container">
  <h4>pH Buffers</h4>
  <h5>{{ laboratory()?.laboratoryName }}</h5>
</div>
<hr />
<div id="ph-buffer--container">
  <div id="list--container">
    <table aria-describedby="table-description">
      <tbody>
        @for (buffer of phBuffers; track buffer.name) {
          <tr
            (click)="setPhBuffer(buffer)"
            [class.selected]="selectedBuffer() === buffer"
          >
            <td>
              <h6>{{ Buffer[buffer.name] }}</h6>
              <div class="icon"><mat-icon>chevron_right</mat-icon></div>
            </td>
          </tr>
        }
      </tbody>
    </table>
  </div>
  <div id="details--container">
    <h6>{{ Buffer[selectedBuffer().name] }}</h6>
    <table>
      <thead>
        <tr>
          <th id="temp" scope="col">Temperature</th>
          <th id="pH" scope="col">pH</th>
        </tr>
      </thead>
      <tbody>
        @for (
          pair of selectedBuffer().temperaturePHValuePairs;
          track pair.temperature
        ) {
          <tr>
            <td headers="temp">{{ pair.temperature }}</td>
            <td headers="pH">{{ pair.phValue }}</td>
          </tr>
        }
      </tbody>
    </table>
  </div>
</div>
