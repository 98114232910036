export enum DeviceSetupStatus {
  'Active' = 1,
  'Complete' = 2, // No longer used
  'Inactive' = 3,
  'Awaiting' = 4,
  'Flasks Inserted' = 5,
  'Experiment Cancelled' = 6, // No longer used
  'Awaiting Calibration' = 7, // No longer used
  'Calibration Flask Inserted' = 8, // No longer used
  'Active OD Calibration' = 9,
  'Paused' = 10,
  'Offline' = 11, // No longer used (tracked in a seperate device variable)
  'Active Oxygen Calibration' = 12,
  'Active pH Calibration' = 13,
}
