<mat-form-field
  style="width: 100%"
  [formGroup]="parentForm"
  floatLabel="always"
>
  <mat-label>{{ field.label }}</mat-label>
  <input
    matInput
    [placeholder]="field.placeholder"
    [type]="
      field.type === 'password' ? (hide ? 'password' : 'text') : field.type
    "
    [formControlName]="field.key"
    [autocomplete]="field.autocomplete"
    [required]="field.required"
    [attr.aria-required]="field.required"
    [disabled]="this.parentForm.controls[this.field.key].disabled"
  />
  @if (iconName && field.type !== "password") {
    <mat-icon matSuffix>{{ iconName }}</mat-icon>
  }
  @if (iconName && field.type === "password") {
    <button
      mat-icon-button
      matSuffix
      (click)="hide = !hide"
      [attr.aria-label]="'Hide password'"
      [attr.aria-pressed]="hide"
      type="button"
    >
      <mat-icon>{{ hide ? "visibility_off" : "visibility" }}</mat-icon>
    </button>
  }
  @if (hasErrors) {
    <mat-error>{{ errorsList[0] }}</mat-error>
  }
</mat-form-field>
