import { Injectable } from '@angular/core';
import { httpOptions } from '../_helpers/utils';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {
  Organisation,
  OrganisationCreate,
  OrganisationIndex,
  OrganisationIndexView,
} from '../_models/organisation';
import { environment } from 'src/environments/environment';
import { PaginationData } from '../_models/pagination-data.model';

@Injectable({
  providedIn: 'root',
})
export class OrganisationService {
  httpOptions = httpOptions;

  private pageNumber$ = new BehaviorSubject<number>(null);
  private pageSize$ = new BehaviorSubject<number>(null);

  constructor(private http: HttpClient) {}

  onUpdatePageNumber = (pageNumber: number): void => {
    this.pageNumber$.next(pageNumber);
  };

  onUpdatePageSize = (pageSize: number): void => {
    this.pageSize$.next(pageSize);
  };

  public get paginationCache(): { pageNumber: number; pageSize: number } {
    if (this.pageNumber$.value !== null && this.pageSize$.value !== null) {
      return {
        pageNumber: this.pageNumber$.value,
        pageSize: this.pageSize$.value,
      };
    } else {
      return null;
    }
  }

  getOrganisations(
    sortBy = 'OrganisationName',
    sortDirection = 'asc',
    pageNumber = 1,
    pageSize = 5
  ): Observable<OrganisationIndex> {
    const url = `${environment.apiUrl}/organisations?sortBy=${sortBy}&sortDirection=${sortDirection}&pageNumber=${pageNumber}&pageSize=${pageSize}`;
    return this.http.get<OrganisationIndexView[]>(url, this.httpOptions).pipe(
      map((response) => {
        // Set pagination data
        const paginationData = JSON.parse(
          response.headers.get('X-Pagination')
        ) as PaginationData;
        const organisations = response.body;
        return { organisations, paginationData };
      })
    );
  }

  getOrganisation(id: number): Observable<OrganisationIndexView> {
    return this.http
      .get<OrganisationIndexView>(
        `${environment.apiUrl}/organisations/${id}`,
        this.httpOptions
      )
      .pipe(
        map((response) => {
          return response.body;
        })
      );
  }

  createOrganisation(orgCreate: OrganisationCreate): Observable<boolean> {
    return this.http
      .post<boolean>(
        `${environment.apiUrl}/organisations`,
        orgCreate,
        this.httpOptions
      )
      .pipe(
        map((response) => {
          return response.body;
        })
      );
  }

  updateOrganisation(orgName: string, orgId: number): Observable<Organisation> {
    return this.http
      .patch<Organisation>(
        `${environment.apiUrl}/organisations/${orgId}`,
        { organisationName: orgName },
        this.httpOptions
      )
      .pipe(
        map((response) => {
          return response.body;
        })
      );
  }

  deleteOrganisation(orgId: number): Observable<boolean> {
    return this.http
      .delete<boolean>(`${environment.apiUrl}/organisations/${orgId}`)
      .pipe(
        map((response: boolean) => {
          return response;
        })
      );
  }
}
