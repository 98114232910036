import { Laboratory } from './laboratory';
import { PaginationData } from './pagination-data.model';
import { Role } from './role';

export class User {
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber?: string;
  id?: number;
  role?: Role;
  token?: string;
  laboratories: Laboratory[];
  organisationId: number;
  isMemberOfMultipleLabGroups?: boolean;

  constructor(
    email: string,
    firstName: string,
    lastName: string,
    phoneNumber?: string,
    id?: number,
    role?: Role,
    token?: string,
  ) {
    this.email = email;
    this.firstName = firstName;
    this.lastName = lastName;
    this.phoneNumber = phoneNumber;
    this.id = id || null;
    this.role = role || null;
    this.token = token || null;
  }
}

export class UserCreate {
  firstName: string;
  lastName: string;
  email: string;
  userType: Role;
  laboratoryIds: number[];
}

export class UserUpdate {
  id: number;
  firstName?: string;
  lastName?: string;
  userRole?: Role;
  laboratoryIds?: number[];
}

export class UserIndex {
  users: User[];
  paginationData: PaginationData;
}
