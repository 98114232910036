<section
  class="underlined-form-field__container"
  [class.disabled]="this.parentForm.controls[this.field.key].disabled"
  [class.error]="hasErrors"
>
  <label [for]="field.key" [formGroup]="parentForm">
    <div class="body1">
      {{ field.label }}
      @if (field.required) {
        <span class="required" aria-required="true"> * </span>
      }
    </div>
    <div class="underlined-form-field-input-container">
      @if (field.type === "range") {
        <div class="slider">
          <mat-slider
            [formControlName]="field.key"
            [min]="minValue"
            [max]="maxValue"
            [step]="step"
            [value]="sliderValue"
            [disabled]="this.parentForm.controls[this.field.key].disabled"
            ngDefaultControl
          >
            <input matSliderThumb [value]="sliderValue" />
          </mat-slider>
          <input
            class="slider-value"
            [type]="number"
            [formControlName]="field.key"
            [step]="step"
            [max]="maxValue"
            [min]="minValue"
            [value]="sliderValue"
          />
        </div>
      } @else {
        <mat-form-field>
          <input
            matInput
            class="body1"
            [class.error]="hasErrors"
            [type]="field.type"
            [formControlName]="field.key"
            [placeholder]="field.placeholder"
            [autocomplete]="field.autocomplete"
            [attr.aria-required]="field.required"
            [required]="field.required"
            [step]="step"
            [max]="maxValue"
            [min]="minValue"
            [value]="value"
            [disabled]="this.parentForm.controls[this.field.key].disabled"
          />
          @if (showWarning) {
            <mat-error>{{ warningMessage }}</mat-error>
          }
          @if (hasErrors) {
            <mat-error>{{ errorsList[0] }}</mat-error>
          }
        </mat-form-field>
      }
    </div>
    @if (minValue || maxValue) {
      <div class="minMaxValues" role="tooltip">
        <mat-icon>info</mat-icon>
        <div class="top">
          Min: {{ minValue }}<br />Max: {{ maxValue }}
          <i></i>
        </div>
        <div class="tooltip-accessibility">
          Min: {{ minValue }}<br />Max: {{ maxValue }}
        </div>
      </div>
    }
  </label>
</section>
