<div id="title--container">
  <h4>Add a laboratory</h4>
</div>
<hr />
<div id="lab--container">
  <div class="body1">
    Please complete the details below to add a new laboratory.
  </div>
  <div class="form--container">
    <form [formGroup]="addLabForm">
      <app-underlined-form-field
        [parentForm]="addLabForm"
        [field]="labNameField"
      ></app-underlined-form-field>
    </form>
    <div class="button--container">
      <app-button
        label="Add Laboratory"
        buttonId="add-btn"
        (buttonClicked)="onSubmit()"
        [isDisabled]="addLabForm.invalid"
        [isProcessing]="isProcessing"
      >
      </app-button>
    </div>
  </div>
</div>
<app-modal
  id="create-lab"
  titleText="Success"
  [primaryText]="addLabSuccessMessage"
  buttonType="single-button"
  primaryButtonText="return to dashboard"
  primaryButtonId="dashboard-button"
  modalStyle="success"
  (modalButtonClicked)="modalButtonClicked($event)"
></app-modal>
<app-modal
  id="error"
  titleText="Error"
  [primaryText]="error?.message"
  buttonType="single-button"
  primaryButtonText="close"
  primaryButtonId="close-button"
  modalStyle="error"
  (modalButtonClicked)="modalButtonClicked($event)"
></app-modal>
<app-modal
  id="attention"
  titleText="Attention"
  primaryText="Your changes have not been saved. Press cancel to return to the form and save your changes, or continue to discard."
  buttonType="double-button"
  primaryButtonText="Continue"
  primaryButtonId="confirm-navigation"
  secondaryButtonText="Cancel"
  secondaryButtonId="cancel-button"
  modalStyle="warning"
  (modalButtonClicked)="modalButtonClicked($event)"
></app-modal>
