import { Component, Input, OnInit } from '@angular/core';
import { DeviceSetupStatus } from 'src/app/_models/device-setup-status';
import { IconNames } from '../../icons/icon-names';
import { Experiment } from 'src/app/_models/experiment';

@Component({
  selector: 'app-small-data-card',
  templateUrl: './small-data-card.component.html',
  styleUrls: ['./small-data-card.component.scss'],
})
export class SmallDataCardComponent implements OnInit {
  /**
   * @param {string} dataCategory name of data category to be displayed
   * @requires
   */
  @Input()
  dataCategory: string;

  /**
   * @param {number} dataReading data reading to be displayed
   * @requires
   */
  @Input()
  dataReading: number;

  /**
   * @param {string} temperatureReading temperature value to be displayed
   */
  @Input()
  temperatureReading: number;

  /**
   * @param {string} dataReadingTime of time of the data reading
   * @requires
   */
  @Input()
  dataReadingTime: string;

  @Input()
  experiment: Experiment;

  /**
   * @param {string} flask flask name to be displayed
   */
  @Input()
  flask: 'Flask A' | 'Flask B' | 'Flask C' | 'Flask D';

  /**
   * @param {string} temperature temperature to be displayed
   */
  @Input()
  temperature: string;

  /**
   * @param {number} deviceStatus current status of the flask
   */
  @Input()
  deviceStatus: number;

  time: Date;

  ngOnInit(): void {
    this.time = new Date(this.dataReadingTime);
  }

  public get statusClass(): string {
    const indicator = this.deviceStatus;
    switch (indicator) {
      // 1 - Active
      case 1:
        return DeviceSetupStatus[1].toString();
      // 10 - Paused
      case 10:
        return DeviceSetupStatus[10].toString();
      default:
        return '';
    }
    // TO DO
    // Handle offline devices
  }

  public get categoryString(): string {
    switch (this.dataCategory) {
      case 'opticalDensity':
        return 'OD';
      case 'pH':
        return 'pH';
      case 'greenFluoresence':
        return 'GF';
      case 'redFluoresence':
        return 'RF';
      // ET - is temp selectable? As it is on permanent display as well
      case 'temperature':
        return '\xB0C C';
      default:
        return this.dataCategory;
    }
  }

  public get flaskIcon(): string {
    switch (this.flask) {
      case 'Flask A':
        return IconNames.FlaskA;
      case 'Flask B':
        return IconNames.FlaskB;
      case 'Flask C':
        return IconNames.FlaskC;
      case 'Flask D':
        return IconNames.FlaskD;
      default:
        return '';
    }
  }

  public get speedRPMReading(): number {
    switch (this.flask) {
      case 'Flask A':
        return this.experiment.speedRPMA;
      case 'Flask B':
        return this.experiment.speedRPMB;
      case 'Flask C':
        return this.experiment.speedRPMC;
      case 'Flask D':
        return this.experiment.speedRPMD;
      default:
        return null;
    }
  }
}
