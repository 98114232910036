import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { Checkbox } from '../../../_models/checkbox';
import { MatIcon } from '@angular/material/icon';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, MatIcon],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckboxComponent {
  title: 'Checkbox';
  component: '';

  /**
   * What params for each checkbox?
   * @requires
   */
  @Input()
  checkbox: Checkbox;

  /**
   * Parent form group name
   * @requires
   */
  @Input()
  parentForm: string;

  /**
   * Is the input disabled?
   */
  @Input()
  isDisabled: boolean;

  /**
   * Emit selected option
   */
  @Output()
  checkboxSelected: EventEmitter<boolean> = new EventEmitter<boolean>();

  onValueChange = (event: Event): void => {
    const target = event.target as HTMLFormElement;
    this.checkboxSelected.emit(target.checked as boolean);
  };
}
