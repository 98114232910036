<div class="modal--container">
  <div
    class="modal-body"
    [class.animation--container]="
      animationType === 'test-tubes' || animationType === 'lock'
    "
  >
    @if (displayLogo) {
      <div class="icon--container">
        <app-svg-template
          [iconName]="logo"
          width="108"
          height="27"
          customViewBox="0 0 108 27"
        ></app-svg-template>
      </div>
    }
    @if (animationType === "test-tubes" || animationType === "lock") {
      <div #lottieContainer id="lottie-container"></div>
    }
    @switch (modalStyle) {
      @case ("success") {
        <mat-icon class="green-icon">check_circle</mat-icon>
      }
      @case ("error") {
        <mat-icon class="red-icon">error</mat-icon>
      }
      @case ("warning") {
        <mat-icon class="red-icon">warning</mat-icon>
      }
    }
    <h4>{{ titleText }}</h4>
    <p class="body1 text">
      {{ primaryText }}
    </p>
    @if (secondaryText) {
      <p class="body1 text">
        {{ secondaryText }}
      </p>
    }
    @if (animationType === "spinner") {
      <div id="icon--container">
        <svg
          version="1.1"
          id="tick"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 37 37"
          xml:space="preserve"
        >
          <defs>
            <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
              <stop offset="0%" stop-color="#0c7bb4" />
              <stop offset="100%" stop-color="#5cba9a" />
            </linearGradient>
          </defs>
          <path
            [class.path]="isProcessing || hasCompleted"
            [class.circ]="isProcessing"
            d="M30.5,6.5L30.5,6.5c6.6,6.6,6.6,17.4,0,24l0,0c-6.6,6.6-17.4,6.6-24,0l0,0c-6.6-6.6-6.6-17.4,0-24l0,0C13.1-0.2,23.9-0.2,30.5,6.5z"
          />
          <polyline
            [class.path]="isProcessing || hasCompleted"
            [class.circ]="isProcessing"
            [class.tick]="hasCompleted"
            points="11.6,20 15.9,24.2 26.4,13.8"
            stroke-linecap="round"
          />
        </svg>
      </div>
    }
    <div
      class="button--container"
      [class.double]="buttonType === 'double-button'"
    >
      @if (buttonType === "double-button") {
        <app-button
          buttonStyle="secondary"
          [label]="secondaryButtonText"
          [buttonId]="secondaryButtonId"
          (buttonClicked)="buttonEventHandler(secondaryButtonId)"
          [isDisabled]="isProcessing"
        ></app-button>
      }
      <app-button
        buttonStyle="standard"
        [label]="primaryButtonText"
        [buttonId]="primaryButtonId"
        (buttonClicked)="buttonEventHandler(primaryButtonId)"
        [isDisabled]="isProcessing"
      ></app-button>
    </div>
  </div>
</div>
<div class="modal--backdrop"></div>
