<div class="modal--container">
  <div class="modal-body">
    <div class="close-button--container">
      <app-icon-button
        id="hidden-btn"
        buttonId="remove-btn"
        buttonStyle="transparent"
        buttonShape="square"
        iconName="close"
        (buttonClicked)="close()"
      ></app-icon-button>
    </div>
    <h4>Filters</h4>
    <form [formGroup]="parentForm">
      <app-underlined-dropdown-form-field
        [parentForm]="parentForm"
        [field]="statusField"
      ></app-underlined-dropdown-form-field>
      <app-checkbox-group
        id="module-select"
        [parentForm]="parentForm"
        [field]="modulesField"
        [formArrayGroupName]="modulesField.key"
        (checkboxClicked)="updateValue($event)"
      ></app-checkbox-group>
      <app-slide-toggle
        [parentForm]="parentForm"
        [field]="offlineField"
      ></app-slide-toggle>
      <app-slide-toggle
        *ngIf="!hideDeletedField"
        [parentForm]="parentForm"
        [field]="deletedField"
      ></app-slide-toggle>
    </form>
    <div [ngClass]="buttonContainerClasses">
      @if (buttonType === 'double-button') {
      <app-button
        buttonStyle="secondary"
        [label]="secondaryButtonText"
        [buttonId]="secondaryButtonId"
        (buttonClicked)="buttonEventHandler(secondaryButtonId)"
        [isDisabled]="isProcessing"
      ></app-button>
      }
      <app-button
        [label]="primaryButtonText"
        [buttonId]="primaryButtonId"
        (buttonClicked)="buttonEventHandler(primaryButtonId)"
        [isDisabled]="isProcessing"
      ></app-button>
    </div>
  </div>
</div>
<div class="modal--backdrop"></div>
