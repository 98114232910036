import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { ErrorObject } from 'src/app/_models/error';
import { FormBase } from 'src/app/_models/form-base';
import { Laboratory } from 'src/app/_models/laboratory';
import { LicenseType } from 'src/app/_models/license-type';
import { OrganisationIndexView } from 'src/app/_models/organisation';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { ModalService } from 'src/app/_services/modal.service';
import { OrganisationService } from 'src/app/_services/organisation.service';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss'],
})
export class OverviewComponent implements OnInit {
  // Permissions
  laboratory: Laboratory;
  isFirstLoad = true;
  error: ErrorObject = null;
  organisations: OrganisationIndexView[];
  selectedOrganisation: OrganisationIndexView;
  checkboxesInitialised = false;
  selectedLaboratory: Laboratory;
  currentUser = this.authenticationService.currentUserValue;

  // Make the enum available to the template
  LicenseType = LicenseType;

  // Table control variables
  currentFilters = [];
  currentSort: {
    sortBy: string;
    sortDirection: string;
  } = {
    sortBy: 'Name',
    sortDirection: 'asc',
  };

  // Pagination
  pageRequested = 1;
  pageSize = 5;
  totalCount = 0;
  totalPages = 0;
  currentPage = 1;
  hasNext = false;
  hasPrevious = false;

  orgsPerPageField = new FormBase({
    key: 'orgsPerPage',
    label: 'Organisations Per Page',
    type: 'number',
    placeholder: 'Organisations Per Page',
    disabled: false,
    required: true,
    value: '5',
    options: [
      { key: '5', value: '5' },
      { key: '10', value: '10' },
      { key: '25', value: '25' },
      { key: '50', value: '50' },
      { key: '100', value: '100' },
    ],
  });

  orgTableControls = this.formBuilder.group({
    [this.orgsPerPageField.key]: new FormControl(this.orgsPerPageField.value),
  });

  constructor(
    private formBuilder: FormBuilder,
    private organisationService: OrganisationService,
    private router: Router,
    private authenticationService: AuthenticationService,
    private modalService: ModalService
  ) {}

  ngOnInit(): void {
    // Check for saved sort, filter, and pagination settings
    if (this.organisationService.paginationCache !== null) {
      this.pageRequested = this.organisationService.paginationCache.pageNumber;
      this.pageSize = this.organisationService.paginationCache.pageSize;
      this.orgTableControls.patchValue({
        [this.orgsPerPageField.key]: this.pageSize.toString(),
      });
    }

    this.laboratory = this.authenticationService.selectedLaboratory;

    // Subscribe to changes in the orgs per page dropdown
    this.orgTableControls
      .get(this.orgsPerPageField.key)
      .valueChanges.subscribe((numOfUsers) => {
        this.pageSize = numOfUsers as number;
        this.pageRequested = 1;
        this.getOrganisations();
      });

    this.getOrganisations();
  }

  getOrganisations(): void {
    this.organisationService
      .getOrganisations(
        this.currentSort.sortBy,
        this.currentSort.sortDirection,
        this.pageRequested,
        this.pageSize
      )
      .subscribe({
        next: (response) => {
          this.organisations = response.organisations;
          this.totalCount = response.paginationData.TotalCount;
          this.totalPages = response.paginationData.TotalPages;
          this.currentPage = response.paginationData.CurrentPage;
          this.hasNext = response.paginationData.HasNext;
          this.hasPrevious = response.paginationData.HasPrevious;
          this.isFirstLoad = false;
        },
        error: (error: ErrorObject) => {
          this.error = error;
          this.isFirstLoad = false;
        },
      });
  }

  getClasses(sortTerm: string): string[] {
    const active = this.currentSort.sortBy === sortTerm ? 'active' : '';
    return [active];
  }

  onSort(sortTerm: string): void {
    if (this.currentSort.sortBy === sortTerm) {
      if (this.currentSort.sortDirection === 'asc') {
        this.currentSort.sortDirection = 'desc';
      } else {
        this.currentSort.sortDirection = 'asc';
      }
    } else {
      this.currentSort.sortBy = sortTerm;
      this.currentSort.sortDirection = 'asc';
    }
    this.getOrganisations();
  }

  viewOrgDetails(id: number): void {
    this.selectedOrganisation = this.organisations.find((org) => org.id === id);
  }

  getPrevious(): void {
    this.pageRequested = this.currentPage - 1;
    this.getOrganisations();
  }

  getNext(): void {
    this.pageRequested = this.currentPage + 1;
    this.getOrganisations();
  }

  onCreateNewOrgansation(): void {
    void this.router.navigate(['organisations/create-organisation']);
  }

  viewOrganisation(id: string): void {
    void this.router.navigate([`organisations/${id}/details`]);
  }

  openModal(modalId: string): void {
    this.modalService.open(modalId);
  }

  closeModal(modalId: string): void {
    this.modalService.close(modalId);
  }

  modalButtonClicked(buttonId: string): void {
    switch (buttonId) {
      case 'cancel-button':
        this.closeModal('attention');
        break;
      case 'confirm-navigation':
        this.authenticationService.setSelectedLaboratory(
          this.selectedLaboratory
        );
        void this.router.navigate(['/dashboard']);
        this.closeModal('attention');
        break;
    }
  }

  onViewLab(laboratory: Laboratory): void {
    const isLabInOwnOrg = this.currentUser.laboratories.find(
      (x) => x.laboratoryId === laboratory.laboratoryId
    );

    if (isLabInOwnOrg) {
      this.authenticationService.setSelectedLaboratory(laboratory);
      void this.router.navigate(['/dashboard']);
    } else {
      this.selectedLaboratory = laboratory;
      this.openModal('attention');
    }
  }
}
