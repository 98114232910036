import {
  ChangeDetectionStrategy,
  Component,
  Input,
  inject,
} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Module } from 'src/app/_models/module-enum';
import { IconNames } from '../../icons/icon-names';

@Component({
  selector: 'app-installed-modules-card',
  templateUrl: './installed-modules-card.component.html',
  styleUrl: './installed-modules-card.component.scss',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InstalledModulesCardComponent {
  private readonly sanitizer = inject(DomSanitizer);

  /**
   * @param {number[]} activeModules List of active modules
   */
  @Input()
  activeModules: number[];

  /**
   * @param {string} width Custom svg width
   */
  @Input()
  width = '25';

  /**
   * @param {string} height Custom svg height
   */
  @Input()
  height = '25';

  /**
   * @param {string} customViewBox Custom viewbox dimensions
   */
  @Input()
  customViewBox = `0 0 25 25`;

  opticalDensity = IconNames.OpticalDensity;
  oxygen = IconNames.Oxygen;
  fluo = IconNames.Fluorescence;
  liquidControl = IconNames.LiquidControl;
  pH = IconNames.pH;
  airControl = IconNames.AirControl;

  // Makes the Module enum available in the template
  Module = Module;

  icon(iconName: string): SafeHtml {
    let pathString = '';
    pathString = iconName.toString();
    return this.sanitizer.bypassSecurityTrustHtml(pathString);
  }
}
