<div *ngIf="laboratory; else noLab">
  <div id="title--container">
    <h4>New Calibration Setup</h4>
    <h5>{{ laboratory.laboratoryName }}</h5>
  </div>
  <hr />
  <div id="new-calibration--container">
    <app-radio-button
      id="toggle--container"
      [field]="calibrationTypeField"
      [parentForm]="calibrationForm"
      [currentValue]="
        calibrationForm?.controls[calibrationTypeField.key]?.value
      "
      type="round"
    ></app-radio-button>
    <div class="form-field--container">
      <div>
        <app-underlined-dropdown-form-field
          *ngIf="!devicesLoading && deviceList?.length > 0"
          [parentForm]="calibrationForm"
          [field]="deviceField"
        ></app-underlined-dropdown-form-field>
        <span
          *ngIf="!devicesLoading && (!deviceList || deviceList.length === 0)"
          class="subtitle1"
        >
          No devices available
        </span>
        <div *ngIf="devicesLoading" class="subtitle1">
          <app-small-loading-spinner></app-small-loading-spinner>
        </div>
      </div>
      <app-underlined-form-field
        [parentForm]="calibrationForm"
        [field]="calibrationNameField"
      ></app-underlined-form-field>
      <div *ngIf="!saveLocationsLoading; else loading">
        <app-underlined-dropdown-form-field
          *ngIf="slotList?.length > 0"
          [parentForm]="calibrationForm"
          [field]="saveLocationField"
        ></app-underlined-dropdown-form-field>
        <span *ngIf="!slotList">No save locations reported</span>
        <span *ngIf="slotList?.length === 0"
          >Select a device to view save locations</span
        >
      </div>
      @if (calibrationModuleType === ModuleEnum.pH) {
      <app-underlined-form-field
        [parentForm]="pHForm"
        [field]="temperatureField"
        [step]="tempStep"
      ></app-underlined-form-field>
      }
      <ng-template #loading>
        <app-small-loading-spinner></app-small-loading-spinner>
      </ng-template>
    </div>
    <div
      *ngIf="
        calibrationModuleType === ModuleEnum.BaseModule ||
        calibrationModuleType === ModuleEnum.Oxygen
      "
      class="advanced--container"
    >
      <div class="form-field--container">
        <div>
          <app-slide-toggle
            [parentForm]="temperatureValuesForm"
            [field]="temperatureControlAField"
          ></app-slide-toggle>
          <app-underlined-form-field
            [parentForm]="temperatureValuesForm"
            [field]="inputTemperatureAField"
            [step]="temperatureStepOD"
          ></app-underlined-form-field>
        </div>
        <div>
          <app-slide-toggle
            [parentForm]="temperatureValuesForm"
            [field]="temperatureControlBField"
          ></app-slide-toggle>
          <app-underlined-form-field
            [parentForm]="temperatureValuesForm"
            [field]="inputTemperatureBField"
            [step]="temperatureStepOD"
          ></app-underlined-form-field>
        </div>
        <div>
          <app-slide-toggle
            [parentForm]="temperatureValuesForm"
            [field]="temperatureControlCField"
          ></app-slide-toggle>
          <app-underlined-form-field
            [parentForm]="temperatureValuesForm"
            [field]="inputTemperatureCField"
            [step]="temperatureStepOD"
          ></app-underlined-form-field>
        </div>
        <div>
          <app-slide-toggle
            [parentForm]="temperatureValuesForm"
            [field]="temperatureControlDField"
          ></app-slide-toggle>
          <app-underlined-form-field
            [parentForm]="temperatureValuesForm"
            [field]="inputTemperatureDField"
            [step]="temperatureStepOD"
          ></app-underlined-form-field>
        </div>
      </div>
    </div>
    <div
      *ngIf="calibrationModuleType === ModuleEnum.pH"
      class="advanced--container-ph"
    >
      <div class="form-field--container"></div>
    </div>
    <div
      *ngIf="calibrationModuleType === ModuleEnum.pH"
      class="advanced--container-buffer"
    >
      <div>
        <h6>Add 2-5 buffers</h6>
        <div
          *ngIf="!buffersLoading; else bufferLoadingHolder"
          class="form-field--container"
        >
          <app-underlined-dropdown-form-field
            *ngIf="pHBufferList?.length > 0"
            [parentForm]="pHForm"
            [field]="pHBufferField"
          ></app-underlined-dropdown-form-field>
          <span *ngIf="!pHBufferList"
            >Select a device to view loaded buffers</span
          >
          <span *ngIf="pHBufferList?.length === 0">
            No buffers loaded on device
          </span>
          <div>
            <p id="table-description" style="display: none">
              Buffers to be used for pH calibration
            </p>
            <table aria-describedby="table-description">
              <thead>
                <tr>
                  <th id="name" scope="col">Selected buffers</th>
                  <th id="remove" scope="col"></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let buffer of selectedBufferList; let i = index">
                  <td headers="name">{{ buffer.name }}</td>
                  <td headers="remove">
                    <app-icon-button
                      id="hidden-btn"
                      buttonId="remove-btn"
                      buttonStyle="transparent"
                      buttonShape="square"
                      textColor="red"
                      iconName="close"
                      (buttonClicked)="removeBuffer(buffer)"
                    ></app-icon-button>
                  </td>
                </tr>
                <tr *ngIf="selectedBufferList?.length < 1">
                  <td headers="name">-</td>
                  <td headers="remove"></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <ng-template #bufferLoadingHolder>
          <app-small-loading-spinner></app-small-loading-spinner>
        </ng-template>
      </div>
    </div>
    <div id="error--container" *ngIf="error">
      <p class="error">Error: {{ error.message }}</p>
    </div>
    <div id="button--container">
      <app-button
        label="return to dashboard"
        buttonId="dashboard-btn"
        buttonStyle="yellow"
        routerLink="dashboard"
      ></app-button>
      <app-button
        label="continue"
        type="submit"
        buttonId="continue-btn"
        [isProcessing]="isProcessing"
        [isDisabled]="isButtonDisabled()"
        (buttonClicked)="onSubmitCreateCalibrationForm()"
      ></app-button>
    </div>
  </div>
</div>
<ng-template #noLab>
  <app-error-page
    title="No Lab found"
    primaryText="Sorry, you cannot access this page unless you are a member of a lab"
  ></app-error-page>
</ng-template>
