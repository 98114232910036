<div class="modal--container">
  <div class="modal-body">
    <form [formGroup]="infoForm">
      <h4>Info required</h4>
      <app-underlined-textarea-field
        [field]="infoField"
        [parentForm]="infoForm"
      ></app-underlined-textarea-field>
      <div class="button--container">
        <app-button
          class="modal-button"
          buttonStyle="secondary"
          label="Cancel"
          buttonId="cancel-rejection"
          (buttonClicked)="close()"
          [isDisabled]="isProcessing"
        ></app-button>
        <app-button
          class="modal-button"
          type="submit"
          buttonStyle="standard"
          label="Confirm rejection"
          buttonId="confirm-rejection"
          (buttonClicked)="buttonEventHandler()"
          [isDisabled]="infoForm.invalid"
          [isProcessing]="isProcessing"
        ></app-button>
      </div>
    </form>
  </div>
</div>
<div class="modal--backdrop"></div>
