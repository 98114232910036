import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ERROR_MESSAGE } from 'src/app/_helpers/utils';
import { FormBase } from 'src/app/_models/form-base';

@Component({
  selector: 'app-checkbox-group',
  templateUrl: './checkbox-group.component.html',
  styleUrls: ['./checkbox-group.component.scss'],
})
export class CheckboxGroupComponent {
  title: 'Checkbox';
  component: '';

  /**
   * @param {FormBase<string>} field Checkbox base field
   * @requires
   */
  @Input()
  field: FormBase<string>;

  /**
   * @param {FormGroup} parentForm Parent form group name
   * @requires
   */
  @Input()
  parentForm: FormGroup = new FormGroup({});

  /**
   * @param {string} formArrayGroupName Array group name
   * @requires
   */
  @Input()
  formArrayGroupName: string;

  /**
   * @param {boolean} isDisabled Set whether checkboxes are disabled
   */
  @Input()
  isDisabled = false;

  /**
   * @param {boolean} style Set checkbox style
   **/
  @Input()
  checkboxStyle: 'default' | 'tabs' | 'tabs-small' | 'list' = 'default';

  /**
   * @param {boolean} showModuleIcon Set whether to show module icon
   */
  @Input()
  showModuleIcon: string;

  @Output()
  checkboxClicked: EventEmitter<Event> = new EventEmitter<Event>();

  updateValue(event: Event): void {
    this.checkboxClicked.emit(event);
  }

  initialiseCheckboxes(index: number): boolean {
    const controlAccessor = this.formArrayGroupName;
    const arrayKey = 'controls';
    // Ensure the value is being accessed correctly and return a boolean
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return !!this.parentForm?.controls[controlAccessor][arrayKey][index]?.value;
  }

  public get hasErrors(): boolean {
    return (
      this.parentForm.controls[this.field.key] &&
      this.parentForm.controls[this.field.key]?.errors &&
      this.parentForm.controls[this.field.key]?.touched
    );
  }

  public get errorsList(): string[] {
    return Object.keys(this.parentForm.controls[this.field.key].errors).map(
      (err) =>
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        ERROR_MESSAGE[err](
          this.parentForm.controls[this.field.key].getError(err)
        ) as string
    );
  }

  public get containerClasses(): string[] {
    switch (this.checkboxStyle) {
      case 'tabs':
        return ['tab--container'];
      case 'tabs-small':
        return ['tab-small--container'];
      default:
        return ['checkbox__container'];
    }
  }

  public get labelClasses(): string[] {
    switch (this.checkboxStyle) {
      case 'tabs':
        return ['radio-label'];
      case 'tabs-small':
        return ['tabs-small--label'];
      default:
        return ['body1'];
    }
  }

  public get optionsContainerClasses(): string[] {
    switch (this.checkboxStyle) {
      case 'tabs':
        return ['tabs__options-container'];
      case 'tabs-small':
        return ['tabs-small__options-container'];
      case 'list':
        return ['list__container'];
      default:
        return ['checkbox-options__container'];
    }
  }

  public get classes(): string[] {
    const hasDisabledClass = this.isDisabled ? 'disabled' : '';
    switch (this.checkboxStyle) {
      case 'tabs':
        return ['tabs--label', hasDisabledClass];
      case 'tabs-small':
        return ['tabs-small--label', hasDisabledClass];
      default:
        return ['checkbox--label', 'body1', hasDisabledClass];
    }
  }

  public get inputClasses(): string[] {
    switch (this.checkboxStyle) {
      case 'tabs':
        return [''];
      case 'tabs-small':
        return [''];
      default:
        return ['body1'];
    }
  }
}
