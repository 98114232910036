import { Component, OnInit, ViewChild, inject } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormGroup,
  Validators,
  FormsModule,
} from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ErrorObject } from 'src/app/_models/error';
import { FormBase } from 'src/app/_models/form-base';
import { CreateExperiment } from 'src/app/_models/experiment-create';
import { ExperimentUpdate } from 'src/app/_models/experiment-update';
import { ExperimentService } from 'src/app/_services/experiment.service';
import { ModalService } from 'src/app/_services/modal.service';
import { Experiment } from 'src/app/_models/experiment';
import { ExperimentStatus } from 'src/app/_models/experiment-status';
import { ChangelogEntry } from 'src/app/_models/changelog';
import { ChangelogResponse } from 'src/app/_models/api-responses';
import {
  MatAccordion,
  MatExpansionPanel,
  MatExpansionPanelHeader,
  MatExpansionPanelTitle,
  MatExpansionPanelDescription,
} from '@angular/material/expansion';
import { ExperimentMode } from 'src/app/_models/experiment-mode';
import { Subject } from 'rxjs';
import { Module } from 'src/app/_models/module-enum';
import {
  allDevicesHaveRequiredModules,
  clearFormArray,
  advancedNameRegex,
} from 'src/app/_helpers/utils';
import { Laboratory } from 'src/app/_models/laboratory';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { stepValidator } from 'src/app/_helpers/step-validator.validator';
import { User } from 'src/app/_models/user';
import { Role } from 'src/app/_models/role';
import { RoleCheckPipe } from '../../_helpers/role-check.pipe';
import { ValidationModalComponent } from '../../_components/modals/validation-modal/validation-modal.component';
import { ModalComponent } from '../../_components/modals/modal.component';
import { ErrorPageComponent } from '../../_components/error-page/error-page.component';
import { IconButtonComponent } from '../../_components/buttons/icon-button/icon-button.component';
import { DropdownFormFieldComponent } from '../../_components/forms/dropdown-form-field/dropdown-form-field.component';
import { MatTooltip } from '@angular/material/tooltip';
import { MatIcon } from '@angular/material/icon';
import { UnderlinedTextareaFieldComponent } from '../../_components/forms/underlined-textarea-field/underlined-textarea-field.component';
import { IconUnderlinedFormFieldComponent } from '../../_components/forms/icon-underlined-form-field/icon-underlined-form-field.component';
import { SlideToggleComponent } from '../../_components/selection-controls/slide-toggle/slide-toggle.component';
import { UnderlinedFormFieldComponent } from '../../_components/forms/underlined-form-field/underlined-form-field.component';
import { MatButton } from '@angular/material/button';
import { CheckboxGroupComponent } from '../../_components/selection-controls/checkbox-group/checkbox-group.component';
import { ButtonComponent } from '../../_components/buttons/button/button.component';
import { RadioButtonComponent } from '../../_components/selection-controls/radio-button/radio-button.component';
import { StatusIndicatorComponent } from '../../_components/chips/status-indicator/status-indicator.component';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-set-parameters',
  templateUrl: './set-parameters.component.html',
  styleUrls: ['./set-parameters.component.scss'],
  standalone: true,
  imports: [
    StatusIndicatorComponent,
    RadioButtonComponent,
    ButtonComponent,
    CheckboxGroupComponent,
    MatButton,
    MatAccordion,
    MatExpansionPanel,
    MatExpansionPanelHeader,
    MatExpansionPanelTitle,
    FormsModule,
    UnderlinedFormFieldComponent,
    SlideToggleComponent,
    IconUnderlinedFormFieldComponent,
    MatExpansionPanelDescription,
    UnderlinedTextareaFieldComponent,
    MatIcon,
    MatTooltip,
    DropdownFormFieldComponent,
    IconButtonComponent,
    ErrorPageComponent,
    ModalComponent,
    ValidationModalComponent,
    DatePipe,
    RoleCheckPipe,
  ],
})
export class SetParametersComponent implements OnInit {
  private readonly router = inject(Router);
  protected route = inject(ActivatedRoute);
  protected formBuilder = inject(FormBuilder);
  protected experimentService = inject(ExperimentService);
  protected modalService = inject(ModalService);
  private readonly authenticationService = inject(AuthenticationService);

  @ViewChild(MatAccordion) accordion: MatAccordion;
  // Permissions
  laboratory: Laboratory;
  currentUser: User;

  // Control vars
  error: ErrorObject = null;
  deleteError: ErrorObject = null;
  // Possibly delete isLoading - unless we want to hide form if theres an error getting experiment?
  loading = false;
  isProcessing = false;
  deleteIsProcessing = false;
  nextButtonDisabled = true;
  draftIsLocked = false;

  useFluorescence = false;
  useOxygen = false;
  usePH = false;

  allowNavigation: Subject<boolean> = new Subject<boolean>();

  // Create new experiment
  newExperiment: CreateExperiment;
  // Created experiment
  createdExperiment: Experiment;
  experimentId: number = null;
  // Make experiment status enum available in template
  ExperimentStatus = ExperimentStatus;
  // Make enums available in template
  ExperimentMode = ExperimentMode;
  Role = Role;

  /** @param {number[]} requiredDeviceModules A list of required modules on device index */
  requiredDeviceModules: number[] = [];

  // experiment controls form
  minSampleTimeInMins = 1;
  maxSampleTimeInMins = 1000;
  sampleTimeStep = 1;
  minExperimentTimeInHours = 1;
  maxExperimentTimeInHours = 1000;
  experimentTimeStep = 1;
  minSpeed = 300; // min range slider speed
  maxSpeed = 6520;
  speedStep = 20;
  minAmbientTemp = 0;
  maxAmbientTemp = 50;
  ambientTempStep = 0.5;
  // This will update dynamically based on the value of the ambient temperature field
  // Initialised to 37 to match default ambient temp value
  minCultureTemp = 37;
  maxCultureTemp = 50;
  cultureTempStep = 0.1;
  // TODO: dynamically change max temp based on presence of oxygen module
  // 60 degrees if module present, 75 if not
  ambientTempWarningMessage =
    'Warning: Temperature is outside of recommended range';
  // Turbidostat
  minTargetOD = 0.1;
  maxTargetOD = 2;
  targetODStep = 0.05;

  // Chemostat
  minFlowRate = 0;
  maxFlowRate = 100;
  flowRateStep = 0.1;
  // pH Control
  minTargetPH = 0;
  maxTargetPH = 14;
  targetPHStep = 0.05;
  minPumpDuration = 0.3;
  maxPumpDuration = 15;
  pumpDurationStep = 0.1;
  // Fluoro
  minLEDIntensity = 0;
  maxLEDIntensity = 255;
  ledIntensityStep = 1;
  // Oxygen
  minSalinity = 0;
  maxSalinity = 350;
  salinityStep = 1;

  // Table vars
  changelogList: ChangelogEntry[] = [];
  currentFilters = [];
  currentSort: {
    sortBy: string;
    sortDirection: string;
  } = {
    sortBy: 'CreatedAt',
    sortDirection: 'asc',
  };

  // Pagination
  pageRequested = 1;
  pageSize = 5;
  totalCount = 0;
  totalPages = 0;
  currentPage = 1;
  hasNext = false;
  hasPrevious = false;

  // Experiment Controls
  experimentModeField = new FormBase<string>({
    key: 'experimentMode',
    label: 'Select experiment mode',
    type: 'radio',
    placeholder: '',
    disabled: false,
    required: true,
    value: '',
    options: [
      { key: ExperimentMode.BatchCulture, value: 'Batch Culture' },
      { key: ExperimentMode.Turbidostat, value: 'Turbidostat' },
      { key: ExperimentMode.Chemostat, value: 'Chemostat' },
      { key: ExperimentMode.PHControl, value: 'pH Control' },
    ],
  });

  moduleCheckboxesField = new FormBase<string>({
    key: 'moduleCheckboxes',
    label: 'Select modules',
    type: 'checkbox',
    placeholder: '',
    disabled: false,
    required: false,
    value: '',
    options: [
      { key: 'Oxygen', value: 'Oxygen' },
      { key: 'Fluorescence', value: 'Fluorescence' },
      { key: 'pH', value: 'pH' },
    ],
  });

  experimentNameField = new FormBase<string>({
    key: 'name',
    label: 'Experiment',
    type: 'text',
    placeholder: 'Experiment name',
    disabled: false,
    required: true,
    value: '',
    options: [],
  });

  organismField = new FormBase<string>({
    key: 'organism',
    label: 'Organism',
    type: 'text',
    placeholder: 'Organism name',
    disabled: false,
    required: true,
    value: '',
    options: [],
  });

  strainField = new FormBase<string>({
    key: 'strain',
    label: 'Strain',
    type: 'text',
    placeholder: 'Strain name',
    disabled: false,
    required: true,
    value: '',
    options: [],
  });

  mediaField = new FormBase<string>({
    key: 'media',
    label: 'Media',
    type: 'text',
    placeholder: 'Media name',
    disabled: false,
    required: true,
    value: '',
    options: [],
  });

  // Base Unit
  ambientTemperatureField = new FormBase<number>({
    key: 'ambientTemperature',
    label: 'Ambient Temperature (°C)',
    type: 'number',
    placeholder: '0',
    disabled: false,
    required: true,
    value: 37,
    options: [],
  });

  temperatureControlAField = new FormBase<boolean>({
    key: 'temperatureControlA',
    label: 'Temperature Control A',
    type: 'toggle',
    placeholder: '',
    disabled: false,
    required: false,
    value: true,
    options: [],
  });

  temperatureControlBField = new FormBase<boolean>({
    key: 'temperatureControlB',
    label: 'Temperature Control B',
    type: 'toggle',
    placeholder: '',
    disabled: false,
    required: false,
    value: true,
    options: [],
  });

  temperatureControlCField = new FormBase<boolean>({
    key: 'temperatureControlC',
    label: 'Temperature Control C',
    type: 'toggle',
    placeholder: '',
    disabled: false,
    required: false,
    value: true,
    options: [],
  });

  temperatureControlDField = new FormBase<boolean>({
    key: 'temperatureControlD',
    label: 'Temperature Control D',
    type: 'toggle',
    placeholder: '',
    disabled: false,
    required: false,
    value: true,
    options: [],
  });

  temperatureAField = new FormBase<number>({
    key: 'cultureTemperatureA',
    label: 'Culture Temperature A (°C)',
    type: 'range',
    placeholder: '0',
    disabled: false,
    required: false,
    value: 37,
    options: [],
  });

  temperatureBField = new FormBase<number>({
    key: 'cultureTemperatureB',
    label: 'Culture Temperature B (°C)',
    type: 'range',
    placeholder: '0',
    disabled: false,
    required: false,
    value: 37,
    options: [],
  });

  temperatureCField = new FormBase<number>({
    key: 'cultureTemperatureC',
    label: 'Culture Temperature C (°C)',
    type: 'range',
    placeholder: '0',
    disabled: false,
    required: false,
    value: 37,
    options: [],
  });

  temperatureDField = new FormBase<number>({
    key: 'cultureTemperatureD',
    label: 'Culture Temperature D (°C)',
    type: 'range',
    placeholder: '0',
    disabled: false,
    required: false,
    value: 37,
    options: [],
  });

  samplingTimeField = new FormBase<number>({
    key: 'samplingTimeField',
    label: 'Sampling Time (mins)',
    type: 'number',
    placeholder: '0',
    disabled: false,
    required: true,
    value: 5,
    options: [],
  });

  experimentTimeField = new FormBase<number>({
    key: 'experimentTimeField',
    label: 'Experiment Time (hours)',
    type: 'number',
    placeholder: '0',
    disabled: false,
    required: true,
    value: 96,
    options: [],
  });

  speedAField = new FormBase<number>({
    key: 'speedAField',
    label: 'Stirring speed - flask A (RPM)',
    type: 'range',
    placeholder: '4000',
    disabled: false,
    required: true,
    value: 4000,
    options: [],
  });

  speedBField = new FormBase<number>({
    key: 'speedBField',
    label: 'Stirring speed - flask B (RPM)',
    type: 'range',
    placeholder: '4000',
    disabled: false,
    required: true,
    value: 4000,
    options: [],
  });

  speedCField = new FormBase<number>({
    key: 'speedCField',
    label: 'Stirring speed - flask C (RPM)',
    type: 'range',
    placeholder: '4000',
    disabled: false,
    required: true,
    value: 4000,
    options: [],
  });

  speedDField = new FormBase<number>({
    key: 'speedDField',
    label: 'Stirring speed - flask D (RPM)',
    type: 'range',
    placeholder: '4000',
    disabled: false,
    required: true,
    value: 4000,
    options: [],
  });

  // If setStirringSpeedField is true, set stirring speed to 0
  setStirringSpeedAField = new FormBase<boolean>({
    key: 'setStirringSpeedAField',
    label: 'Set stirring speed on flask A to 0',
    type: 'toggle',
    placeholder: '',
    disabled: false,
    required: true,
    value: false,
    options: [],
  });

  setStirringSpeedBField = new FormBase<boolean>({
    key: 'setStirringSpeedBField',
    label: 'Set stirring speed on flask B to 0',
    type: 'toggle',
    placeholder: '',
    disabled: false,
    required: true,
    value: false,
    options: [],
  });

  setStirringSpeedCField = new FormBase<boolean>({
    key: 'setStirringSpeedCField',
    label: 'Set stirring speed on flask C to 0',
    type: 'toggle',
    placeholder: '',
    disabled: false,
    required: true,
    value: false,
    options: [],
  });

  setStirringSpeedDField = new FormBase<boolean>({
    key: 'setStirringSpeedDField',
    label: 'Set stirring speed on flask D to 0',
    type: 'toggle',
    placeholder: '',
    disabled: false,
    required: true,
    value: false,
    options: [],
  });

  // Turbidostat
  maintainODField = new FormBase<boolean>({
    key: 'maintainOD',
    label: 'Maintain Initial OD',
    type: 'toggle',
    placeholder: '',
    disabled: false,
    required: false,
    value: false,
    options: [],
  });

  targetODAField = new FormBase<number>({
    key: 'targetODA',
    label: 'Target OD A',
    type: 'range',
    placeholder: '0',
    disabled: false,
    required: false,
    value: 0.3,
    options: [],
  });

  targetODBField = new FormBase<number>({
    key: 'targetODB',
    label: 'Target OD B',
    type: 'range',
    placeholder: '0',
    disabled: false,
    required: false,
    value: 0.3,
    options: [],
  });

  targetODCField = new FormBase<number>({
    key: 'targetODC',
    label: 'Target OD C',
    type: 'range',
    placeholder: '0',
    disabled: false,
    required: false,
    value: 0.3,
    options: [],
  });

  targetODDField = new FormBase<number>({
    key: 'targetODD',
    label: 'Target OD D',
    type: 'range',
    placeholder: '0',
    disabled: false,
    required: false,
    value: 0.3,
    options: [],
  });

  turbAActiveField = new FormBase<boolean>({
    key: 'turbAActive',
    label: 'Turbidostat Control A',
    type: 'toggle',
    placeholder: '',
    disabled: false,
    required: false,
    value: true,
    options: [],
  });

  turbBActiveField = new FormBase<boolean>({
    key: 'turbBActive',
    label: 'Turbidostat Control B',
    type: 'toggle',
    placeholder: '',
    disabled: false,
    required: false,
    value: true,
    options: [],
  });

  turbCActiveField = new FormBase<boolean>({
    key: 'turbCActive',
    label: 'Turbidostat Control C',
    type: 'toggle',
    placeholder: '',
    disabled: false,
    required: false,
    value: true,
    options: [],
  });

  turbDActiveField = new FormBase<boolean>({
    key: 'turbDActive',
    label: 'Turbidostat Control D',
    type: 'toggle',
    placeholder: '',
    disabled: false,
    required: false,
    value: true,
    options: [],
  });

  // Chemostat
  chemoAActiveField = new FormBase<boolean>({
    key: 'chemoAActive',
    label: 'Chemostat Control A',
    type: 'toggle',
    placeholder: '',
    disabled: false,
    required: false,
    value: true,
    options: [],
  });

  chemoBActiveField = new FormBase<boolean>({
    key: 'chemoBActive',
    label: 'Chemostat Control B',
    type: 'toggle',
    placeholder: '',
    disabled: false,
    required: false,
    value: true,
    options: [],
  });

  chemoCActiveField = new FormBase<boolean>({
    key: 'chemoCActive',
    label: 'Chemostat Control C',
    type: 'toggle',
    placeholder: '',
    disabled: false,
    required: false,
    value: true,
    options: [],
  });

  chemoDActiveField = new FormBase<boolean>({
    key: 'chemoDActive',
    label: 'Chemostat Control D',
    type: 'toggle',
    placeholder: '',
    disabled: false,
    required: false,
    value: true,
    options: [],
  });

  flowRateAField = new FormBase<number>({
    key: 'flowRateA',
    label: 'Flow Rate A (ml/h)',
    type: 'range',
    placeholder: '0',
    disabled: false,
    required: false,
    value: 2,
    options: [],
  });

  flowRateBField = new FormBase<number>({
    key: 'flowRateB',
    label: 'Flow Rate B (ml/h)',
    type: 'range',
    placeholder: '0',
    disabled: false,
    required: false,
    value: 2,
    options: [],
  });

  flowRateCField = new FormBase<number>({
    key: 'flowRateC',
    label: 'Flow Rate C (ml/h)',
    type: 'range',
    placeholder: '0',
    disabled: false,
    required: false,
    value: 2,
    options: [],
  });

  flowRateDField = new FormBase<number>({
    key: 'flowRateD',
    label: 'Flow Rate D (ml/h)',
    type: 'range',
    placeholder: '0',
    disabled: false,
    required: false,
    value: 2,
    options: [],
  });

  // pH Control
  phControlAField = new FormBase<boolean>({
    key: 'phControlA',
    label: 'pH Control A',
    type: 'toggle',
    placeholder: '',
    disabled: false,
    required: false,
    value: true,
    options: [],
  });

  phControlBField = new FormBase<boolean>({
    key: 'phControlB',
    label: 'pH Control B',
    type: 'toggle',
    placeholder: '',
    disabled: false,
    required: false,
    value: true,
    options: [],
  });

  phControlCField = new FormBase<boolean>({
    key: 'phControlC',
    label: 'pH Control C',
    type: 'toggle',
    placeholder: '',
    disabled: false,
    required: false,
    value: true,
    options: [],
  });

  phControlDField = new FormBase<boolean>({
    key: 'phControlD',
    label: 'pH Control D',
    type: 'toggle',
    placeholder: '',
    disabled: false,
    required: false,
    value: true,
    options: [],
  });

  targetPHAField = new FormBase<number>({
    key: 'targetPHA',
    label: 'Target pH A',
    type: 'range',
    placeholder: '0',
    disabled: false,
    required: false,
    value: 7,
    options: [],
  });

  targetPHBField = new FormBase<number>({
    key: 'targetPHB',
    label: 'Target pH B',
    type: 'range',
    placeholder: '0',
    disabled: false,
    required: false,
    value: 7,
    options: [],
  });

  targetPHCField = new FormBase<number>({
    key: 'targetPHC',
    label: 'Target pH C',
    type: 'range',
    placeholder: '0',
    disabled: false,
    required: false,
    value: 7,
    options: [],
  });

  targetPHDField = new FormBase<number>({
    key: 'targetPHD',
    label: 'Target pH D',
    type: 'range',
    placeholder: '0',
    disabled: false,
    required: false,
    value: 7,
    options: [],
  });

  inputTypeAField = new FormBase<string>({
    key: 'inputTypeA',
    label: 'Input Type A',
    type: 'radio',
    placeholder: '',
    disabled: false,
    required: false,
    value: 'BaseA',
    options: [
      { key: 'BaseA', value: 'Base' },
      { key: 'AcidA', value: 'Acid' },
    ],
  });

  inputTypeBField = new FormBase<string>({
    key: 'inputTypeB',
    label: 'Input Type B',
    type: 'select',
    placeholder: '',
    disabled: false,
    required: false,
    value: 'BaseB',
    options: [
      { key: 'BaseB', value: 'Base' },
      { key: 'AcidB', value: 'Acid' },
    ],
  });

  inputTypeCField = new FormBase<string>({
    key: 'inputTypeC',
    label: 'Input Type C',
    type: 'select',
    placeholder: '',
    disabled: false,
    required: false,
    value: 'BaseC',
    options: [
      { key: 'BaseC', value: 'Base' },
      { key: 'AcidC', value: 'Acid' },
    ],
  });

  inputTypeDField = new FormBase<string>({
    key: 'inputTypeD',
    label: 'Input Type D',
    type: 'select',
    placeholder: '',
    disabled: false,
    required: false,
    value: 'BaseD',
    options: [
      { key: 'BaseD', value: 'Base' },
      { key: 'AcidD', value: 'Acid' },
    ],
  });

  pumpDurationAField = new FormBase<number>({
    key: 'pumpDurationA',
    label: 'Pump Duration A (seconds)',
    type: 'range',
    placeholder: '0',
    disabled: false,
    required: false,
    value: 5,
    options: [],
  });

  pumpDurationBField = new FormBase<number>({
    key: 'pumpDurationB',
    label: 'Pump Duration B (seconds)',
    type: 'range',
    placeholder: '0',
    disabled: false,
    required: false,
    value: 5,
    options: [],
  });

  pumpDurationCField = new FormBase<number>({
    key: 'pumpDurationC',
    label: 'Pump Duration C (seconds)',
    type: 'range',
    placeholder: '0',
    disabled: false,
    required: false,
    value: 5,
    options: [],
  });

  pumpDurationDField = new FormBase<number>({
    key: 'pumpDurationD',
    label: 'Pump Duration D (seconds)',
    type: 'range',
    placeholder: '0',
    disabled: false,
    required: false,
    value: 5,
    options: [],
  });

  // Fluro
  led1OnField = new FormBase<boolean>({
    key: 'led1On',
    label: 'LED 1 Always On',
    type: 'toggle',
    placeholder: '',
    disabled: false,
    required: false,
    value: false,
    options: [],
  });

  led2OnField = new FormBase<boolean>({
    key: 'led2On',
    label: 'LED 2 Always On',
    type: 'toggle',
    placeholder: '',
    disabled: false,
    required: false,
    value: false,
    options: [],
  });

  led3OnField = new FormBase<boolean>({
    key: 'led3On',
    label: 'LED 3 Always On',
    type: 'toggle',
    placeholder: '',
    disabled: false,
    required: false,
    value: false,
    options: [],
  });

  led1IntensityField = new FormBase<number>({
    key: 'led1Intensity',
    label: 'LED 1 Intensity',
    type: 'range',
    placeholder: '0',
    disabled: false,
    required: false,
    value: 255,
    options: [],
  });

  led2IntensityField = new FormBase<number>({
    key: 'led2Intensity',
    label: 'LED 2 Intensity',
    type: 'range',
    placeholder: '0',
    disabled: false,
    required: false,
    value: 255,
    options: [],
  });

  led3IntensityField = new FormBase<number>({
    key: 'led3Intensity',
    label: 'LED 3 Intensity',
    type: 'range',
    placeholder: '0',
    disabled: false,
    required: false,
    value: 255,
    options: [],
  });

  // Oxygen
  salinityAField = new FormBase<number>({
    key: 'salinityA',
    label: 'Salinity A (g/l)',
    type: 'range',
    placeholder: '0',
    disabled: false,
    required: false,
    value: 0,
    options: [],
  });

  salinityBField = new FormBase<number>({
    key: 'salinityB',
    label: 'Salinity B (g/l)',
    type: 'range',
    placeholder: '0',
    disabled: false,
    required: false,
    value: 0,
    options: [],
  });

  salinityCField = new FormBase<number>({
    key: 'salinityC',
    label: 'Salinity C (g/l)',
    type: 'range',
    placeholder: '0',
    disabled: false,
    required: false,
    value: 0,
    options: [],
  });

  salinityDField = new FormBase<number>({
    key: 'salinityD',
    label: 'Salinity D (g/l)',
    type: 'range',
    placeholder: '0',
    disabled: false,
    required: false,
    value: 0,
    options: [],
  });

  // Notes
  noteField = new FormBase({
    key: 'noteField',
    label: 'Note',
    type: 'textarea',
    placeholder: 'Enter notes here...',
    disabled: false,
    required: false,
    value: '',
    options: [],
  });

  experimentControlsForm = this.formBuilder.group({
    // General experiment controls
    [this.experimentNameField.key]: [
      '',
      [
        Validators.minLength(2),
        Validators.maxLength(100),
        Validators.required,
        Validators.pattern(advancedNameRegex),
      ],
    ],
    [this.organismField.key]: [
      '',
      [Validators.minLength(2), Validators.maxLength(100), Validators.required],
    ],
    [this.strainField.key]: [
      '',
      [Validators.minLength(2), Validators.maxLength(100), Validators.required],
    ],
    [this.mediaField.key]: [
      '',
      [Validators.minLength(2), Validators.maxLength(100), Validators.required],
    ],
    [this.samplingTimeField.key]: [
      this.samplingTimeField.value,
      Validators.compose([
        Validators.min(this.minSampleTimeInMins),
        Validators.max(this.maxSampleTimeInMins),
        Validators.required,
        stepValidator(this.sampleTimeStep),
      ]),
    ],
    [this.experimentTimeField.key]: [
      this.experimentTimeField.value,
      Validators.compose([
        Validators.min(this.minExperimentTimeInHours),
        Validators.max(this.maxExperimentTimeInHours),
        Validators.required,
        stepValidator(this.experimentTimeStep),
      ]),
    ],
    [this.speedAField.key]: [
      this.speedAField.value,
      Validators.compose([
        Validators.min(this.minSpeed),
        Validators.max(this.maxSpeed),
        stepValidator(this.speedStep),
      ]),
    ],
    [this.speedBField.key]: [
      this.speedBField.value,
      Validators.compose([
        Validators.min(this.minSpeed),
        Validators.max(this.maxSpeed),
        stepValidator(this.speedStep),
      ]),
    ],
    [this.speedCField.key]: [
      this.speedCField.value,
      Validators.compose([
        Validators.min(this.minSpeed),
        Validators.max(this.maxSpeed),
        stepValidator(this.speedStep),
      ]),
    ],
    [this.speedDField.key]: [
      this.speedDField.value,
      Validators.compose([
        Validators.min(this.minSpeed),
        Validators.max(this.maxSpeed),
        stepValidator(this.speedStep),
      ]),
    ],
    [this.setStirringSpeedAField.key]: [this.setStirringSpeedAField.value],
    [this.setStirringSpeedBField.key]: [this.setStirringSpeedBField.value],
    [this.setStirringSpeedCField.key]: [this.setStirringSpeedCField.value],
    [this.setStirringSpeedDField.key]: [this.setStirringSpeedDField.value],
    [this.ambientTemperatureField.key]: [
      this.ambientTemperatureField.value,
      [
        Validators.min(this.minAmbientTemp),
        Validators.max(this.maxAmbientTemp),
        stepValidator(this.ambientTempStep),
      ],
    ],
    [this.temperatureControlAField.key]: [this.temperatureControlAField.value],
    [this.temperatureControlBField.key]: [this.temperatureControlBField.value],
    [this.temperatureControlCField.key]: [this.temperatureControlCField.value],
    [this.temperatureControlDField.key]: [this.temperatureControlDField.value],
    [this.temperatureAField.key]: [
      this.temperatureAField.value,
      [
        Validators.min(this.minCultureTemp),
        Validators.max(this.maxAmbientTemp),
        stepValidator(this.cultureTempStep),
      ],
    ],
    [this.temperatureBField.key]: [
      this.temperatureBField.value,
      [
        Validators.min(this.minCultureTemp),
        Validators.max(this.maxAmbientTemp),
        stepValidator(this.cultureTempStep),
      ],
    ],
    [this.temperatureCField.key]: [
      this.temperatureCField.value,
      [
        Validators.min(this.minCultureTemp),
        Validators.max(this.maxAmbientTemp),
        stepValidator(this.cultureTempStep),
      ],
    ],
    [this.temperatureDField.key]: [
      this.temperatureDField.value,
      [
        Validators.min(this.minCultureTemp),
        Validators.max(this.maxAmbientTemp),
        stepValidator(this.cultureTempStep),
      ],
    ],
    [this.maintainODField.key]: [this.maintainODField.value],
    // Turbidostat controls
    [this.targetODAField.key]: [
      this.targetODAField.value,
      [
        Validators.min(this.minTargetOD),
        Validators.max(this.maxTargetOD),
        stepValidator(this.targetODStep),
      ],
    ],
    [this.targetODBField.key]: [
      this.targetODBField.value,
      [
        Validators.min(this.minTargetOD),
        Validators.max(this.maxTargetOD),
        stepValidator(this.targetODStep),
      ],
    ],
    [this.targetODCField.key]: [
      this.targetODCField.value,
      [
        Validators.min(this.minTargetOD),
        Validators.max(this.maxTargetOD),
        stepValidator(this.targetODStep),
      ],
    ],
    [this.targetODDField.key]: [
      this.targetODDField.value,
      [
        Validators.min(this.minTargetOD),
        Validators.max(this.maxTargetOD),
        stepValidator(this.targetODStep),
      ],
    ],

    [this.turbAActiveField.key]: [this.turbAActiveField.value],
    [this.turbBActiveField.key]: [this.turbBActiveField.value],
    [this.turbCActiveField.key]: [this.turbCActiveField.value],
    [this.turbDActiveField.key]: [this.turbDActiveField.value],
    // Chemostat controls
    [this.chemoAActiveField.key]: [this.chemoAActiveField.value],
    [this.chemoBActiveField.key]: [this.chemoBActiveField.value],
    [this.chemoCActiveField.key]: [this.chemoCActiveField.value],
    [this.chemoDActiveField.key]: [this.chemoDActiveField.value],
    [this.flowRateAField.key]: [
      this.flowRateAField.value,
      Validators.compose([
        Validators.min(this.minFlowRate),
        Validators.max(this.maxFlowRate),
        stepValidator(this.flowRateStep),
      ]),
    ],
    [this.flowRateBField.key]: [
      this.flowRateBField.value,
      Validators.compose([
        Validators.min(this.minFlowRate),
        Validators.max(this.maxFlowRate),
        stepValidator(this.flowRateStep),
      ]),
    ],
    [this.flowRateCField.key]: [
      this.flowRateCField.value,
      Validators.compose([
        Validators.min(this.minFlowRate),
        Validators.max(this.maxFlowRate),
        stepValidator(this.flowRateStep),
      ]),
    ],
    [this.flowRateDField.key]: [
      this.flowRateDField.value,
      Validators.compose([
        Validators.min(this.minFlowRate),
        Validators.max(this.maxFlowRate),
        stepValidator(this.flowRateStep),
      ]),
    ],
    // pH controls
    [this.phControlAField.key]: [this.phControlAField.value],
    [this.phControlBField.key]: [this.phControlBField.value],
    [this.phControlCField.key]: [this.phControlCField.value],
    [this.phControlDField.key]: [this.phControlDField.value],
    [this.targetPHAField.key]: [
      this.targetPHAField.value,
      Validators.compose([
        Validators.min(this.minTargetPH),
        Validators.max(this.maxTargetPH),
        stepValidator(this.targetPHStep),
      ]),
    ],
    [this.targetPHBField.key]: [
      this.targetPHBField.value,
      Validators.compose([
        Validators.min(this.minTargetPH),
        Validators.max(this.maxTargetPH),
        stepValidator(this.targetPHStep),
      ]),
    ],
    [this.targetPHCField.key]: [
      this.targetPHCField.value,
      Validators.compose([
        Validators.min(this.minTargetPH),
        Validators.max(this.maxTargetPH),
        stepValidator(this.targetPHStep),
      ]),
    ],
    [this.targetPHDField.key]: [
      this.targetPHDField.value,
      Validators.compose([
        Validators.min(this.minTargetPH),
        Validators.max(this.maxTargetPH),
        stepValidator(this.targetPHStep),
      ]),
    ],
    [this.inputTypeAField.key]: [this.inputTypeAField.value],
    [this.inputTypeBField.key]: [this.inputTypeBField.value],
    [this.inputTypeCField.key]: [this.inputTypeCField.value],
    [this.inputTypeDField.key]: [this.inputTypeDField.value],
    [this.pumpDurationAField.key]: [
      this.pumpDurationAField.value,
      Validators.compose([
        Validators.min(this.minPumpDuration),
        Validators.max(this.maxPumpDuration),
        stepValidator(this.pumpDurationStep),
      ]),
    ],
    [this.pumpDurationBField.key]: [
      this.pumpDurationBField.value,
      Validators.compose([
        Validators.min(this.minPumpDuration),
        Validators.max(this.maxPumpDuration),
        stepValidator(this.pumpDurationStep),
      ]),
    ],
    [this.pumpDurationCField.key]: [
      this.pumpDurationCField.value,
      Validators.compose([
        Validators.min(this.minPumpDuration),
        Validators.max(this.maxPumpDuration),
        stepValidator(this.pumpDurationStep),
      ]),
    ],
    [this.pumpDurationDField.key]: [
      this.pumpDurationDField.value,
      Validators.compose([
        Validators.min(this.minPumpDuration),
        Validators.max(this.maxPumpDuration),
        stepValidator(this.pumpDurationStep),
      ]),
    ],
    // Fluoro controls
    [this.led1OnField.key]: [this.led1OnField.value],
    [this.led2OnField.key]: [this.led2OnField.value],
    [this.led3OnField.key]: [this.led3OnField.value],
    [this.led1IntensityField.key]: [
      this.led1IntensityField.value,
      Validators.compose([
        Validators.min(this.minLEDIntensity),
        Validators.max(this.maxLEDIntensity),
        stepValidator(this.ledIntensityStep),
      ]),
    ],
    [this.led2IntensityField.key]: [
      this.led2IntensityField.value,
      Validators.compose([
        Validators.min(this.minLEDIntensity),
        Validators.max(this.maxLEDIntensity),
        stepValidator(this.ledIntensityStep),
      ]),
    ],
    [this.led3IntensityField.key]: [
      this.led3IntensityField.value,
      Validators.compose([
        Validators.min(this.minLEDIntensity),
        Validators.max(this.maxLEDIntensity),
        stepValidator(this.ledIntensityStep),
      ]),
    ],
    [this.salinityAField.key]: [
      this.salinityAField.value,
      Validators.compose([
        Validators.min(this.minSalinity),
        Validators.max(this.maxSalinity),
        stepValidator(this.salinityStep),
      ]),
    ],
    [this.salinityBField.key]: [
      this.salinityBField.value,
      Validators.compose([
        Validators.min(this.minSalinity),
        Validators.max(this.maxSalinity),
        stepValidator(this.salinityStep),
      ]),
    ],
    [this.salinityCField.key]: [
      this.salinityCField.value,
      Validators.compose([
        Validators.min(this.minSalinity),
        Validators.max(this.maxSalinity),
        stepValidator(this.salinityStep),
      ]),
    ],
    [this.salinityDField.key]: [
      this.salinityDField.value,
      Validators.compose([
        Validators.min(this.minSalinity),
        Validators.max(this.maxSalinity),
        stepValidator(this.salinityStep),
      ]),
    ],
    [this.noteField.key]: ['', [Validators.min(1), Validators.max(10000)]],
    [this.experimentModeField.key]: [ExperimentMode.BatchCulture, []],
  });

  // TODO: Figure out why adding this to the experimentSetupForm causes an error
  // when trying to access other controls
  moduleSelectForm = this.formBuilder.group({
    [this.moduleCheckboxesField.key]: new FormArray([]),
  });

  // Pagination form controls
  changeLogsPerPageField = new FormBase({
    key: 'changeLogsPerPage',
    label: 'Page size',
    type: 'dropdown',
    placeholder: '',
    disabled: false,
    required: false,
    value: '',
    options: [
      { key: '5', value: '5' },
      { key: '10', value: '10' },
      { key: '25', value: '25' },
      { key: '50', value: '50' },
      { key: '100', value: '100' },
    ],
  });
  paginationControls = this.formBuilder.group({
    [this.changeLogsPerPageField.key]: [this.pageSize.toString()],
  });

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      this.experimentId = parseInt(params.id as string, 10);
    });
    if (this.experimentId !== 0 && this.experimentId !== null) {
      this.getExperimentFromApi(this.experimentId);
    } else {
      this.initialiseSubscriptions();
      this.initialiseCheckboxes();
    }
    this.laboratory = this.authenticationService.selectedLaboratory;
    this.currentUser = this.authenticationService.currentUserValue;
  }

  initialiseSubscriptions(): void {
    // If maintainOD is true, target OD fields to be disabled
    // If maintainOD is false, enable target OD fields
    this.experimentControlsForm
      .get(this.maintainODField.key)
      .valueChanges.subscribe((value) => {
        if (value === true) {
          this.f[this.targetODAField.key].disable();
          this.f[this.targetODBField.key].disable();
          this.f[this.targetODCField.key].disable();
          this.f[this.targetODDField.key].disable();
        } else if (value === false) {
          this.f[this.targetODAField.key].enable();
          this.f[this.targetODBField.key].enable();
          this.f[this.targetODCField.key].enable();
          this.f[this.targetODDField.key].enable();
        }
      });

    // If stirring speed is set to 0, disabled stirring speed slider field
    this.experimentControlsForm
      .get(this.setStirringSpeedAField.key)
      .valueChanges.subscribe((value) => {
        if (value === true) {
          this.f[this.speedAField.key].setValue(0);
          this.f[this.speedAField.key].disable();
        } else if (value === false) {
          this.f[this.speedAField.key].setValue(this.speedAField.value);
          this.f[this.speedAField.key].enable();
        }
      });

    this.experimentControlsForm
      .get(this.setStirringSpeedBField.key)
      .valueChanges.subscribe((value) => {
        if (value === true) {
          this.f[this.speedBField.key].setValue(0);
          this.f[this.speedBField.key].disable();
        } else if (value === false) {
          this.f[this.speedBField.key].setValue(this.speedBField.value);
          this.f[this.speedBField.key].enable();
        }
      });

    this.experimentControlsForm
      .get(this.setStirringSpeedCField.key)
      .valueChanges.subscribe((value) => {
        if (value === true) {
          this.f[this.speedCField.key].setValue(0);
          this.f[this.speedCField.key].disable();
        } else if (value === false) {
          this.f[this.speedCField.key].setValue(this.speedCField.value);
          this.f[this.speedCField.key].enable();
        }
      });

    this.experimentControlsForm
      .get(this.setStirringSpeedDField.key)
      .valueChanges.subscribe((value) => {
        if (value === true) {
          this.f[this.speedDField.key].setValue(0);
          this.f[this.speedDField.key].disable();
        } else if (value === false) {
          this.f[this.speedDField.key].setValue(this.speedDField.value);
          this.f[this.speedDField.key].enable();
        }
      });

    // If control temperature is set to false, disable temperature field
    this.experimentControlsForm
      .get(this.temperatureControlAField.key)
      .valueChanges.subscribe((value) => {
        if (value === false) {
          this.f[this.temperatureAField.key].setValue(null);
          this.f[this.temperatureAField.key].disable();
        } else if (value === true) {
          this.f[this.temperatureAField.key].setValue(
            this.temperatureAField.value,
          );
          this.f[this.temperatureAField.key].enable();
        }
      });

    this.experimentControlsForm
      .get(this.temperatureControlBField.key)
      .valueChanges.subscribe((value) => {
        if (value === false) {
          this.f[this.temperatureBField.key].setValue(null);
          this.f[this.temperatureBField.key].disable();
        } else if (value === true) {
          this.f[this.temperatureBField.key].setValue(
            this.temperatureBField.value,
          );
          this.f[this.temperatureBField.key].enable();
        }
      });

    this.experimentControlsForm
      .get(this.temperatureControlCField.key)
      .valueChanges.subscribe((value) => {
        if (value === false) {
          this.f[this.temperatureCField.key].setValue(null);
          this.f[this.temperatureCField.key].disable();
        } else if (value === true) {
          this.f[this.temperatureCField.key].setValue(
            this.temperatureCField.value,
          );
          this.f[this.temperatureCField.key].enable();
        }
      });

    this.experimentControlsForm
      .get(this.temperatureControlDField.key)
      .valueChanges.subscribe((value) => {
        if (value === false) {
          this.f[this.temperatureDField.key].setValue(null);
          this.f[this.temperatureDField.key].disable();
        } else if (value === true) {
          this.f[this.temperatureDField.key].setValue(
            this.temperatureDField.value,
          );
          this.f[this.temperatureDField.key].enable();
        }
      });

    // If turbidostat control is set to false, disable target OD fields
    this.experimentControlsForm
      .get(this.turbAActiveField.key)
      .valueChanges.subscribe((value) => {
        if (value === false) {
          this.f[this.targetODAField.key].setValue(null);
          this.f[this.targetODAField.key].disable();
        } else if (value === true) {
          this.f[this.targetODAField.key].setValue(this.targetODAField.value);
          this.f[this.targetODAField.key].enable();
        }
      });

    this.experimentControlsForm
      .get(this.turbBActiveField.key)
      .valueChanges.subscribe((value) => {
        if (value === false) {
          this.f[this.targetODBField.key].setValue(null);
          this.f[this.targetODBField.key].disable();
        } else if (value === true) {
          this.f[this.targetODBField.key].setValue(this.targetODBField.value);
          this.f[this.targetODBField.key].enable();
        }
      });

    this.experimentControlsForm
      .get(this.turbCActiveField.key)
      .valueChanges.subscribe((value) => {
        if (value === false) {
          this.f[this.targetODCField.key].setValue(null);
          this.f[this.targetODCField.key].disable();
        } else if (value === true) {
          this.f[this.targetODCField.key].setValue(this.targetODCField.value);
          this.f[this.targetODCField.key].enable();
        }
      });

    this.experimentControlsForm
      .get(this.turbDActiveField.key)
      .valueChanges.subscribe((value) => {
        if (value === false) {
          this.f[this.targetODDField.key].setValue(null);
          this.f[this.targetODDField.key].disable();
        } else if (value === true) {
          this.f[this.targetODDField.key].setValue(this.targetODDField.value);
          this.f[this.targetODDField.key].enable();
        }
      });

    // If chemostat control is set to false, disable flow rate fields
    this.experimentControlsForm
      .get(this.chemoAActiveField.key)
      .valueChanges.subscribe((value) => {
        if (value === false) {
          this.f[this.flowRateAField.key].setValue(null);
          this.f[this.flowRateAField.key].disable();
        } else if (value === true) {
          this.f[this.flowRateAField.key].setValue(this.flowRateAField.value);
          this.f[this.flowRateAField.key].enable();
        }
      });

    this.experimentControlsForm
      .get(this.chemoBActiveField.key)
      .valueChanges.subscribe((value) => {
        if (value === false) {
          this.f[this.flowRateBField.key].setValue(null);
          this.f[this.flowRateBField.key].disable();
        } else if (value === true) {
          this.f[this.flowRateBField.key].setValue(this.flowRateBField.value);
          this.f[this.flowRateBField.key].enable();
        }
      });

    this.experimentControlsForm
      .get(this.chemoCActiveField.key)
      .valueChanges.subscribe((value) => {
        if (value === false) {
          this.f[this.flowRateCField.key].setValue(null);
          this.f[this.flowRateCField.key].disable();
        } else if (value === true) {
          this.f[this.flowRateCField.key].setValue(this.flowRateCField.value);
          this.f[this.flowRateCField.key].enable();
        }
      });

    this.experimentControlsForm
      .get(this.chemoDActiveField.key)
      .valueChanges.subscribe((value) => {
        if (value === false) {
          this.f[this.flowRateDField.key].setValue(null);
          this.f[this.flowRateDField.key].disable();
        } else if (value === true) {
          this.f[this.flowRateDField.key].setValue(this.flowRateDField.value);
          this.f[this.flowRateDField.key].enable();
        }
      });

    // If control pH is set to false, disable target pH and input type fields
    this.experimentControlsForm
      .get(this.phControlAField.key)
      .valueChanges.subscribe((value) => {
        if (value === false) {
          this.f[this.targetPHAField.key].setValue(null);
          this.f[this.targetPHAField.key].disable();
          this.f[this.inputTypeAField.key].setValue(null);
          this.f[this.inputTypeAField.key].disable();
          this.f[this.pumpDurationAField.key].setValue(null);
          this.f[this.pumpDurationAField.key].disable();
        } else if (value === true) {
          this.f[this.targetPHAField.key].setValue(this.targetPHAField.value);
          this.f[this.targetPHAField.key].enable();
          this.f[this.inputTypeAField.key].setValue(this.inputTypeAField.value);
          this.f[this.inputTypeAField.key].enable();
          this.f[this.pumpDurationAField.key].setValue(
            this.pumpDurationAField.value,
          );
          this.f[this.pumpDurationAField.key].enable();
        }
      });

    this.experimentControlsForm
      .get(this.phControlBField.key)
      .valueChanges.subscribe((value) => {
        if (value === false) {
          this.f[this.targetPHBField.key].setValue(null);
          this.f[this.targetPHBField.key].disable();
          this.f[this.inputTypeBField.key].setValue(null);
          this.f[this.inputTypeBField.key].disable();
          this.f[this.pumpDurationBField.key].setValue(null);
          this.f[this.pumpDurationBField.key].disable();
        } else if (value === true) {
          this.f[this.targetPHBField.key].setValue(this.targetPHBField.value);
          this.f[this.targetPHBField.key].enable();
          this.f[this.inputTypeBField.key].setValue(this.inputTypeBField.value);
          this.f[this.inputTypeBField.key].enable();
          this.f[this.pumpDurationBField.key].setValue(
            this.pumpDurationBField.value,
          );
          this.f[this.pumpDurationBField.key].enable();
        }
      });

    this.experimentControlsForm
      .get(this.phControlCField.key)
      .valueChanges.subscribe((value) => {
        if (value === false) {
          this.f[this.targetPHCField.key].setValue(null);
          this.f[this.targetPHCField.key].disable();
          this.f[this.inputTypeCField.key].setValue(null);
          this.f[this.inputTypeCField.key].disable();
          this.f[this.pumpDurationCField.key].setValue(null);
          this.f[this.pumpDurationCField.key].disable();
        } else if (value === true) {
          this.f[this.targetPHCField.key].setValue(this.targetPHCField.value);
          this.f[this.targetPHCField.key].enable();
          this.f[this.inputTypeCField.key].setValue(this.inputTypeCField.value);
          this.f[this.inputTypeCField.key].enable();
          this.f[this.pumpDurationCField.key].setValue(
            this.pumpDurationCField.value,
          );
          this.f[this.pumpDurationCField.key].enable();
        }
      });

    this.experimentControlsForm
      .get(this.phControlDField.key)
      .valueChanges.subscribe((value) => {
        if (value === false) {
          this.f[this.targetPHDField.key].setValue(null);
          this.f[this.targetPHDField.key].disable();
          this.f[this.inputTypeDField.key].setValue(null);
          this.f[this.inputTypeDField.key].disable();
          this.f[this.pumpDurationDField.key].setValue(null);
          this.f[this.pumpDurationDField.key].disable();
        } else if (value === true) {
          this.f[this.targetPHDField.key].setValue(this.targetPHDField.value);
          this.f[this.targetPHDField.key].enable();
          this.f[this.inputTypeDField.key].setValue(this.inputTypeDField.value);
          this.f[this.inputTypeDField.key].enable();
          this.f[this.pumpDurationDField.key].setValue(
            this.pumpDurationDField.value,
          );
          this.f[this.pumpDurationDField.key].enable();
        }
      });

    // When ambient temperature is set, update minCultureTemp
    this.experimentControlsForm
      .get(this.ambientTemperatureField.key)
      .valueChanges.subscribe((value: number) => {
        this.minCultureTemp = value;
        // Now, if any of the culture temps are set to a value below the new minCultureTemp, update them
        if (this.f[this.temperatureAField.key].value < this.minCultureTemp) {
          this.f[this.temperatureAField.key].setValue(this.minCultureTemp);
        }
        if (this.f[this.temperatureBField.key].value < this.minCultureTemp) {
          this.f[this.temperatureBField.key].setValue(this.minCultureTemp);
        }
        if (this.f[this.temperatureCField.key].value < this.minCultureTemp) {
          this.f[this.temperatureCField.key].setValue(this.minCultureTemp);
        }
        if (this.f[this.temperatureDField.key].value < this.minCultureTemp) {
          this.f[this.temperatureDField.key].setValue(this.minCultureTemp);
        }
      });
  }

  getExperimentFromApi(experimentId: number): void {
    this.error = null;
    this.experimentService.getExperimentById(experimentId).subscribe({
      next: (response) => {
        this.createdExperiment = response;
        if (
          this.createdExperiment.experimentStatusId !==
          ExperimentStatus.DraftLocked
        ) {
          this.initialiseSubscriptions();
        }
        this.draftIsLocked =
          this.createdExperiment.experimentStatusId ===
          ExperimentStatus.DraftLocked;
        this.getExperimentChangelog();
        this.nextButtonDisabled = false;
        this.useFluorescence = response.useFluorescence;
        this.useOxygen = response.useOxygen;
        this.usePH = response.usePH;
        this.initialiseCheckboxes();
        this.updateFormValues(response);
      },
      error: (error: ErrorObject) => {
        this.error = error;
        this.openModal('error');
      },
    });
  }

  /**
   * @remarks
   * Convenience getter for easy access to form fields
   *
   */
  get f(): FormGroup['controls'] {
    return this.experimentControlsForm.controls;
  }

  get checkboxesFormArray(): FormArray {
    return this.moduleSelectForm.get('moduleCheckboxes') as FormArray;
  }

  initialiseCheckboxes(): void {
    // Reset form array
    clearFormArray(this.checkboxesFormArray);
    this.moduleCheckboxesField.options.forEach(() => {
      const control = this.formBuilder.control(false);
      this.checkboxesFormArray.push(control);
    });
  }

  updateFormValues(experimentData: CreateExperiment | Experiment): void {
    let notes: string = null;
    if ('notes' in experimentData) {
      notes = experimentData.notes;
    }
    this.experimentControlsForm.patchValue({
      [this.experimentModeField.key]: experimentData.experimentMode,
      [this.experimentNameField.key]: experimentData.name,
      [this.organismField.key]: experimentData.organism,
      [this.strainField.key]: experimentData.strain,
      [this.mediaField.key]: experimentData.media,
      // Default module values
      [this.ambientTemperatureField.key]: experimentData.ambientTemperature,
      [this.temperatureControlAField.key]: experimentData.temperatureControlA,
      [this.temperatureControlBField.key]: experimentData.temperatureControlB,
      [this.temperatureControlCField.key]: experimentData.temperatureControlC,
      [this.temperatureControlDField.key]: experimentData.temperatureControlD,
      [this.temperatureAField.key]: experimentData.cultureTemperatureA,
      [this.temperatureBField.key]: experimentData.cultureTemperatureB,
      [this.temperatureCField.key]: experimentData.cultureTemperatureC,
      [this.temperatureDField.key]: experimentData.cultureTemperatureD,
      [this.samplingTimeField.key]: experimentData.samplingInterval,
      [this.experimentTimeField.key]: experimentData.duration,
      [this.setStirringSpeedAField.key]: experimentData.speedRPMA === 0,
      [this.setStirringSpeedBField.key]: experimentData.speedRPMB === 0,
      [this.setStirringSpeedCField.key]: experimentData.speedRPMC === 0,
      [this.setStirringSpeedDField.key]: experimentData.speedRPMD === 0,
      [this.speedAField.key]: experimentData.speedRPMA,
      [this.speedBField.key]: experimentData.speedRPMB,
      [this.speedCField.key]: experimentData.speedRPMC,
      [this.speedDField.key]: experimentData.speedRPMD,
      // turbidostat
      [this.maintainODField.key]: experimentData.maintainOD,
      [this.turbAActiveField.key]: experimentData.turbAActive,
      [this.turbBActiveField.key]: experimentData.turbBActive,
      [this.turbCActiveField.key]: experimentData.turbCActive,
      [this.turbDActiveField.key]: experimentData.turbDActive,
      [this.targetODAField.key]: experimentData.targetODA,
      [this.targetODBField.key]: experimentData.targetODB,
      [this.targetODCField.key]: experimentData.targetODC,
      [this.targetODDField.key]: experimentData.targetODD,
      // chemostat
      [this.chemoAActiveField.key]: experimentData.chemoAActive,
      [this.chemoBActiveField.key]: experimentData.chemoBActive,
      [this.chemoCActiveField.key]: experimentData.chemoCActive,
      [this.chemoDActiveField.key]: experimentData.chemoDActive,
      [this.flowRateAField.key]: experimentData.flowRateA,
      [this.flowRateBField.key]: experimentData.flowRateB,
      [this.flowRateCField.key]: experimentData.flowRateC,
      [this.flowRateDField.key]: experimentData.flowRateD,
      // pH controls
      [this.phControlAField.key]: experimentData.phControlA,
      [this.phControlBField.key]: experimentData.phControlB,
      [this.phControlCField.key]: experimentData.phControlC,
      [this.phControlDField.key]: experimentData.phControlD,
      [this.targetPHAField.key]: experimentData.targetPHA,
      [this.targetPHBField.key]: experimentData.targetPHB,
      [this.targetPHCField.key]: experimentData.targetPHC,
      [this.targetPHDField.key]: experimentData.targetPHD,
      [this.inputTypeAField.key]:
        experimentData.inputTypeA !== null
          ? experimentData.inputTypeA
            ? 'BaseA'
            : 'AcidA'
          : undefined,
      [this.inputTypeBField.key]:
        experimentData.inputTypeB !== null
          ? experimentData.inputTypeB
            ? 'BaseB'
            : 'AcidB'
          : undefined,
      [this.inputTypeCField.key]:
        experimentData.inputTypeC !== null
          ? experimentData.inputTypeC
            ? 'BaseC'
            : 'AcidC'
          : undefined,
      [this.inputTypeDField.key]:
        experimentData.inputTypeD !== null
          ? experimentData.inputTypeD
            ? 'BaseD'
            : 'AcidD'
          : undefined,
      [this.pumpDurationAField.key]: experimentData.pumpDurationA,
      [this.pumpDurationBField.key]: experimentData.pumpDurationB,
      [this.pumpDurationCField.key]: experimentData.pumpDurationC,
      [this.pumpDurationDField.key]: experimentData.pumpDurationD,
      // fluoro
      [this.led1OnField.key]: experimentData.led1On,
      [this.led2OnField.key]: experimentData.led2On,
      [this.led3OnField.key]: experimentData.led3On,
      [this.led1IntensityField.key]: experimentData.led1Intensity,
      [this.led2IntensityField.key]: experimentData.led2Intensity,
      [this.led3IntensityField.key]: experimentData.led3Intensity,
      // oxygen
      [this.salinityAField.key]: experimentData.salinityA,
      [this.salinityBField.key]: experimentData.salinityB,
      [this.salinityCField.key]: experimentData.salinityC,
      [this.salinityDField.key]: experimentData.salinityD,
      [this.noteField.key]: notes,
    });

    this.setModuleCheckboxes();
    this.experimentControlsForm.markAsPristine();
    if (this.draftIsLocked) {
      this.experimentControlsForm.disable();
      this.moduleSelectForm.disable();
    }
  }

  setModuleCheckboxes(): void {
    const oxygenControlIndex = this.moduleCheckboxesField.options.findIndex(
      (option) => option.key === 'Oxygen',
    );
    if (oxygenControlIndex > -1) {
      this.checkboxesFormArray.controls[oxygenControlIndex].setValue(
        this.createdExperiment.useOxygen,
      );
    }

    const pHControlIndex = this.moduleCheckboxesField.options.findIndex(
      (option) => option.key === 'pH',
    );

    if (pHControlIndex > -1) {
      this.checkboxesFormArray.controls[pHControlIndex].setValue(
        this.createdExperiment.usePH,
      );
    }

    const fluorescenceControlIndex =
      this.moduleCheckboxesField.options.findIndex(
        (option) => option.key === 'Fluorescence',
      );
    if (fluorescenceControlIndex > -1) {
      this.checkboxesFormArray.controls[fluorescenceControlIndex].setValue(
        this.createdExperiment.useFluorescence,
      );
    }

    this.moduleSelectForm.markAsPristine();
    if (this.draftIsLocked) {
      this.moduleSelectForm.disable();
    }
  }

  resetForm(): void {
    // If experiment ID, reset form values to original experiment value
    // else reset form & set default values
    this.experimentControlsForm.reset();

    if (this.experimentId !== 0 && this.experimentId !== null) {
      this.experimentControlsForm.patchValue({
        [this.experimentModeField.key]: this.createdExperiment.experimentMode,
        [this.experimentNameField.key]: this.createdExperiment.name,
        [this.organismField.key]: this.createdExperiment.organism,
        [this.strainField.key]: this.createdExperiment.strain,
        [this.mediaField.key]: this.createdExperiment.media,
        // Default module values
        [this.ambientTemperatureField.key]:
          this.createdExperiment.ambientTemperature,
        [this.temperatureControlAField.key]:
          this.createdExperiment.temperatureControlA,
        [this.temperatureControlBField.key]:
          this.createdExperiment.temperatureControlB,
        [this.temperatureControlCField.key]:
          this.createdExperiment.temperatureControlC,
        [this.temperatureControlDField.key]:
          this.createdExperiment.temperatureControlD,
        [this.temperatureAField.key]:
          this.createdExperiment.cultureTemperatureA,
        [this.temperatureBField.key]:
          this.createdExperiment.cultureTemperatureB,
        [this.temperatureCField.key]:
          this.createdExperiment.cultureTemperatureC,
        [this.temperatureDField.key]:
          this.createdExperiment.cultureTemperatureD,
        [this.samplingTimeField.key]: this.createdExperiment.samplingInterval,
        [this.experimentTimeField.key]: this.createdExperiment.duration,
        [this.setStirringSpeedAField.key]:
          this.createdExperiment.speedRPMA === 0,
        [this.setStirringSpeedBField.key]:
          this.createdExperiment.speedRPMB === 0,
        [this.setStirringSpeedCField.key]:
          this.createdExperiment.speedRPMC === 0,
        [this.setStirringSpeedDField.key]:
          this.createdExperiment.speedRPMD === 0,
        [this.speedAField.key]: this.createdExperiment.speedRPMA,
        [this.speedBField.key]: this.createdExperiment.speedRPMB,
        [this.speedCField.key]: this.createdExperiment.speedRPMC,
        [this.speedDField.key]: this.createdExperiment.speedRPMD,
        // turbidostat
        [this.maintainODField.key]: this.createdExperiment.maintainOD,
        [this.turbAActiveField.key]: this.createdExperiment.turbAActive,
        [this.turbBActiveField.key]: this.createdExperiment.turbBActive,
        [this.turbCActiveField.key]: this.createdExperiment.turbCActive,
        [this.turbDActiveField.key]: this.createdExperiment.turbDActive,
        [this.targetODAField.key]: this.createdExperiment.targetODA,
        [this.targetODBField.key]: this.createdExperiment.targetODB,
        [this.targetODCField.key]: this.createdExperiment.targetODC,
        [this.targetODDField.key]: this.createdExperiment.targetODD,
        // chemostat
        [this.chemoAActiveField.key]: this.createdExperiment.chemoAActive,
        [this.chemoBActiveField.key]: this.createdExperiment.chemoBActive,
        [this.chemoCActiveField.key]: this.createdExperiment.chemoCActive,
        [this.chemoDActiveField.key]: this.createdExperiment.chemoDActive,
        [this.flowRateAField.key]: this.createdExperiment.flowRateA,
        [this.flowRateBField.key]: this.createdExperiment.flowRateB,
        [this.flowRateCField.key]: this.createdExperiment.flowRateC,
        [this.flowRateDField.key]: this.createdExperiment.flowRateD,
        // fluoro
        [this.led1OnField.key]: this.createdExperiment.led1On,
        [this.led2OnField.key]: this.createdExperiment.led2On,
        [this.led3OnField.key]: this.createdExperiment.led3On,
        [this.led1IntensityField.key]: this.createdExperiment.led1Intensity,
        [this.led2IntensityField.key]: this.createdExperiment.led2Intensity,
        [this.led3IntensityField.key]: this.createdExperiment.led3Intensity,
        // oxygen
        [this.salinityAField.key]: this.createdExperiment.salinityA,
        [this.salinityBField.key]: this.createdExperiment.salinityB,
        [this.salinityCField.key]: this.createdExperiment.salinityC,
        [this.salinityDField.key]: this.createdExperiment.salinityD,
        [this.noteField.key]: '',
      });
    } else {
      this.experimentControlsForm.patchValue({
        [this.experimentModeField.key]: ExperimentMode.BatchCulture,
        [this.experimentNameField.key]: this.experimentNameField.value,
        [this.organismField.key]: this.organismField.value,
        [this.strainField.key]: this.strainField.value,
        [this.mediaField.key]: this.mediaField.value,
        [this.ambientTemperatureField.key]: this.ambientTemperatureField.value,
        [this.temperatureControlAField.key]:
          this.temperatureControlAField.value,
        [this.temperatureControlBField.key]:
          this.temperatureControlBField.value,
        [this.temperatureControlCField.key]:
          this.temperatureControlCField.value,
        [this.temperatureControlDField.key]:
          this.temperatureControlDField.value,
        [this.temperatureAField.key]: this.temperatureAField.value,
        [this.temperatureBField.key]: this.temperatureBField.value,
        [this.temperatureCField.key]: this.temperatureCField.value,
        [this.temperatureDField.key]: this.temperatureDField.value,
        [this.samplingTimeField.key]: this.samplingTimeField.value,
        [this.experimentTimeField.key]: this.experimentTimeField.value,
        [this.samplingTimeField.key]: false,
        [this.speedAField.key]: this.speedAField.value,
        [this.speedBField.key]: this.speedBField.value,
        [this.speedCField.key]: this.speedCField.value,
        [this.speedDField.key]: this.speedDField.value,
        // turbidostat
        [this.maintainODField.key]: this.maintainODField.value,
        [this.turbAActiveField.key]: this.turbAActiveField.value,
        [this.turbBActiveField.key]: this.turbBActiveField.value,
        [this.turbCActiveField.key]: this.turbCActiveField.value,
        [this.turbDActiveField.key]: this.turbDActiveField.value,
        [this.targetODAField.key]: this.targetODAField.value,
        [this.targetODBField.key]: this.targetODBField.value,
        [this.targetODCField.key]: this.targetODCField.value,
        [this.targetODDField.key]: this.targetODDField.value,
        // chemostat
        [this.chemoAActiveField.key]: this.chemoAActiveField.value,
        [this.chemoBActiveField.key]: this.chemoBActiveField.value,
        [this.chemoCActiveField.key]: this.chemoCActiveField.value,
        [this.chemoDActiveField.key]: this.chemoDActiveField.value,
        [this.flowRateAField.key]: this.flowRateAField.value,
        [this.flowRateBField.key]: this.flowRateBField.value,
        [this.flowRateCField.key]: this.flowRateCField.value,
        [this.flowRateDField.key]: this.flowRateDField.value,
        // fluoro
        [this.led1OnField.key]: this.led1OnField.value,
        [this.led2OnField.key]: this.led2OnField.value,
        [this.led3OnField.key]: this.led3OnField.value,
        [this.led1IntensityField.key]: this.led1IntensityField.value,
        [this.led2IntensityField.key]: this.led2IntensityField.value,
        [this.led3IntensityField.key]: this.led3IntensityField.value,
        // oxygen
        [this.salinityAField.key]: this.salinityAField.value,
        [this.salinityBField.key]: this.salinityBField.value,
        [this.salinityCField.key]: this.salinityCField.value,
        [this.salinityDField.key]: this.salinityDField.value,
        [this.noteField.key]: '',
      });
    }
    this.experimentControlsForm.markAsPristine();
  }

  /**
   * @remarks
   * Show warning if ambient temperature is below 15°C or above 40°C
   * @returns boolean - true if ambient temperature is below 15°C or above 40°C
   */
  isAmbientTemperatureInWarningRange(): boolean {
    return (
      this.f[this.ambientTemperatureField.key].value < 15 ||
      this.f[this.ambientTemperatureField.key].value > 40
    );
  }

  modalButtonClicked(buttonId: string): void {
    switch (buttonId) {
      case 'next-button':
        void this.router.navigate([
          `experiment/${this.experimentId}/select-device`,
        ]);
        break;
      case 'close-create-button':
        this.closeModal('create-experiment');
        break;
      case 'close-error-button':
        this.closeModal('error');
        break;
      case 'close-update-button':
        this.closeModal('update-experiment');
        break;
      case 'close-device-warning-button':
        this.closeModal('device-warning');
        break;
      case 'cancel-button':
        this.allowNavigation.next(false);
        this.closeModal('attention');
        break;
      case 'confirm-navigation':
        this.allowNavigation.next(true);
        this.closeModal('attention');
        break;
      case 'delete-success-button':
        this.closeModal('delete-success');
        this.allowNavigation.next(true);
        void this.router.navigate(['dashboard']);
        break;
    }
  }

  handleSubmit(): void {
    if (this.experimentId) {
      this.updateExperiment();
    } else {
      this.createExperiment();
    }
  }

  createExperiment(): void {
    // If form is already being submitted, exit
    if (this.isProcessing) {
      return;
    }

    this.isProcessing = true;
    this.error = null;

    this.newExperiment = new CreateExperiment(
      this.useFluorescence,
      this.useOxygen,
      this.usePH,
      this.f[this.experimentModeField.key].value as number,
      this.f[this.experimentNameField.key].value as string,
      this.f[this.organismField.key].value as string,
      this.f[this.strainField.key].value as string,
      this.f[this.mediaField.key].value as string,
      this.f[this.ambientTemperatureField.key].value as number,
      this.f[this.samplingTimeField.key].value as number,
      this.f[this.experimentTimeField.key].value as number,
      this.f[this.speedAField.key].value as number,
      this.f[this.speedBField.key].value as number,
      this.f[this.speedCField.key].value as number,
      this.f[this.speedDField.key].value as number,
      this.f[this.temperatureControlAField.key].value as boolean,
      this.f[this.temperatureControlBField.key].value as boolean,
      this.f[this.temperatureControlCField.key].value as boolean,
      this.f[this.temperatureControlDField.key].value as boolean,
      this.f[this.temperatureAField.key].value as number,
      this.f[this.temperatureBField.key].value as number,
      this.f[this.temperatureCField.key].value as number,
      this.f[this.temperatureDField.key].value as number,
      // Turbidostat values
      this.f[this.experimentModeField.key].value === ExperimentMode.Turbidostat
        ? (this.f[this.maintainODField.key].value as boolean)
        : undefined,
      this.f[this.maintainODField.key].value === false &&
      this.f[this.experimentModeField.key].value === ExperimentMode.Turbidostat
        ? (this.f[this.targetODAField.key].value as number)
        : undefined,
      this.f[this.maintainODField.key].value === false &&
      this.f[this.experimentModeField.key].value === ExperimentMode.Turbidostat
        ? (this.f[this.targetODBField.key].value as number)
        : undefined,
      this.f[this.maintainODField.key].value === false &&
      this.f[this.experimentModeField.key].value === ExperimentMode.Turbidostat
        ? (this.f[this.targetODCField.key].value as number)
        : undefined,
      this.f[this.maintainODField.key].value === false &&
      this.f[this.experimentModeField.key].value === ExperimentMode.Turbidostat
        ? (this.f[this.targetODDField.key].value as number)
        : undefined,
      this.f[this.experimentModeField.key].value === ExperimentMode.Turbidostat
        ? (this.f[this.turbAActiveField.key].value as boolean)
        : undefined,
      this.f[this.experimentModeField.key].value === ExperimentMode.Turbidostat
        ? (this.f[this.turbBActiveField.key].value as boolean)
        : undefined,
      this.f[this.experimentModeField.key].value === ExperimentMode.Turbidostat
        ? (this.f[this.turbCActiveField.key].value as boolean)
        : undefined,
      this.f[this.experimentModeField.key].value === ExperimentMode.Turbidostat
        ? (this.f[this.turbDActiveField.key].value as boolean)
        : undefined,
      // Chemostat values
      this.f[this.experimentModeField.key].value === ExperimentMode.Chemostat
        ? (this.f[this.chemoAActiveField.key].value as boolean)
        : undefined,
      this.f[this.experimentModeField.key].value === ExperimentMode.Chemostat
        ? (this.f[this.chemoBActiveField.key].value as boolean)
        : undefined,
      this.f[this.experimentModeField.key].value === ExperimentMode.Chemostat
        ? (this.f[this.chemoCActiveField.key].value as boolean)
        : undefined,
      this.f[this.experimentModeField.key].value === ExperimentMode.Chemostat
        ? (this.f[this.chemoDActiveField.key].value as boolean)
        : undefined,
      this.f[this.experimentModeField.key].value === ExperimentMode.Chemostat
        ? (this.f[this.flowRateAField.key].value as number)
        : undefined,
      this.f[this.experimentModeField.key].value === ExperimentMode.Chemostat
        ? (this.f[this.flowRateBField.key].value as number)
        : undefined,
      this.f[this.experimentModeField.key].value === ExperimentMode.Chemostat
        ? (this.f[this.flowRateCField.key].value as number)
        : undefined,
      this.f[this.experimentModeField.key].value === ExperimentMode.Chemostat
        ? (this.f[this.flowRateDField.key].value as number)
        : undefined,
      // pH controls
      this.f[this.experimentModeField.key].value === ExperimentMode.PHControl
        ? (this.f[this.phControlAField.key].value as boolean)
        : undefined,
      this.f[this.experimentModeField.key].value === ExperimentMode.PHControl
        ? (this.f[this.phControlBField.key].value as boolean)
        : undefined,
      this.f[this.experimentModeField.key].value === ExperimentMode.PHControl
        ? (this.f[this.phControlCField.key].value as boolean)
        : undefined,
      this.f[this.experimentModeField.key].value === ExperimentMode.PHControl
        ? (this.f[this.phControlDField.key].value as boolean)
        : undefined,
      this.f[this.experimentModeField.key].value === ExperimentMode.PHControl
        ? (this.f[this.targetPHAField.key].value as number)
        : undefined,
      this.f[this.experimentModeField.key].value === ExperimentMode.PHControl
        ? (this.f[this.targetPHBField.key].value as number)
        : undefined,
      this.f[this.experimentModeField.key].value === ExperimentMode.PHControl
        ? (this.f[this.targetPHCField.key].value as number)
        : undefined,
      this.f[this.experimentModeField.key].value === ExperimentMode.PHControl
        ? (this.f[this.targetPHDField.key].value as number)
        : undefined,
      this.f[this.experimentModeField.key].value === ExperimentMode.PHControl &&
        this.f[this.inputTypeAField.key].value === 'BaseA',
      this.f[this.experimentModeField.key].value === ExperimentMode.PHControl &&
        this.f[this.inputTypeBField.key].value === 'BaseB',
      this.f[this.experimentModeField.key].value === ExperimentMode.PHControl &&
        this.f[this.inputTypeCField.key].value === 'BaseC',
      this.f[this.experimentModeField.key].value === ExperimentMode.PHControl &&
        this.f[this.inputTypeDField.key].value === 'BaseD',
      this.f[this.experimentModeField.key].value === ExperimentMode.PHControl
        ? (this.f[this.pumpDurationAField.key].value as number)
        : undefined,
      this.f[this.experimentModeField.key].value === ExperimentMode.PHControl
        ? (this.f[this.pumpDurationBField.key].value as number)
        : undefined,
      this.f[this.experimentModeField.key].value === ExperimentMode.PHControl
        ? (this.f[this.pumpDurationCField.key].value as number)
        : undefined,
      this.f[this.experimentModeField.key].value === ExperimentMode.PHControl
        ? (this.f[this.pumpDurationDField.key].value as number)
        : undefined,
      // Fluorescence controls
      this.f[this.led1OnField.key].value as boolean,
      this.f[this.led2OnField.key].value as boolean,
      this.f[this.led3OnField.key].value as boolean,
      this.f[this.led1IntensityField.key].value as number,
      this.f[this.led2IntensityField.key].value as number,
      this.f[this.led3IntensityField.key].value as number,
      this.useOxygen
        ? (this.f[this.salinityAField.key].value as number)
        : undefined,
      this.useOxygen
        ? (this.f[this.salinityBField.key].value as number)
        : undefined,
      this.useOxygen
        ? (this.f[this.salinityCField.key].value as number)
        : undefined,
      this.useOxygen
        ? (this.f[this.salinityDField.key].value as number)
        : undefined,
      // Notes
      this.f[this.noteField.key].value as string,
    );

    this.experimentService.createExperiment(this.newExperiment).subscribe({
      next: (response) => {
        this.isProcessing = false;
        this.nextButtonDisabled = false;
        this.createdExperiment = response;
        this.draftIsLocked = false;
        this.experimentId = response.id;
        this.updateIdInUrl(response.id);
        this.getExperimentChangelog();
        this.updateFormValues(this.createdExperiment);
        this.experimentControlsForm.markAsPristine(); // TODO this should be handled by the form service
        this.openModal('create-experiment');
      },
      error: (error: ErrorObject) => {
        this.error = error;
        this.isProcessing = false;
        this.openModal('error');
      },
    });
  }

  updateExperiment(): void {
    // If form is already being submitted, exit
    if (this.isProcessing) {
      return;
    }

    this.isProcessing = true;
    this.error = null;

    const updateExperiment = new ExperimentUpdate(
      this.experimentId,
      ExperimentStatus.Draft,
      this.useFluorescence,
      this.useOxygen,
      this.usePH,
      this.f[this.experimentModeField.key].value as number,
      this.f[this.experimentNameField.key].value as string,
      this.f[this.organismField.key].value as string,
      this.f[this.strainField.key].value as string,
      this.f[this.mediaField.key].value as string,
      // Default module values
      this.f[this.ambientTemperatureField.key].value as number,
      this.f[this.temperatureControlAField.key].value as boolean,
      this.f[this.temperatureControlBField.key].value as boolean,
      this.f[this.temperatureControlCField.key].value as boolean,
      this.f[this.temperatureControlDField.key].value as boolean,
      this.f[this.temperatureAField.key].value as number,
      this.f[this.temperatureBField.key].value as number,
      this.f[this.temperatureCField.key].value as number,
      this.f[this.temperatureDField.key].value as number,
      this.f[this.samplingTimeField.key].value as number,
      this.f[this.experimentTimeField.key].value as number,
      this.f[this.speedAField.key].value as number,
      this.f[this.speedBField.key].value as number,
      this.f[this.speedCField.key].value as number,
      this.f[this.speedDField.key].value as number,
      // Turbidostat controls
      this.f[this.experimentModeField.key].value === ExperimentMode.Turbidostat
        ? (this.f[this.maintainODField.key].value as boolean)
        : undefined,
      this.f[this.maintainODField.key].value === false &&
      this.f[this.experimentModeField.key].value === ExperimentMode.Turbidostat
        ? (this.f[this.targetODAField.key].value as number)
        : undefined,
      this.f[this.maintainODField.key].value === false &&
      this.f[this.experimentModeField.key].value === ExperimentMode.Turbidostat
        ? (this.f[this.targetODBField.key].value as number)
        : undefined,
      this.f[this.maintainODField.key].value === false &&
      this.f[this.experimentModeField.key].value === ExperimentMode.Turbidostat
        ? (this.f[this.targetODCField.key].value as number)
        : undefined,
      this.f[this.maintainODField.key].value === false &&
      this.f[this.experimentModeField.key].value === ExperimentMode.Turbidostat
        ? (this.f[this.targetODDField.key].value as number)
        : undefined,
      this.f[this.experimentModeField.key].value === ExperimentMode.Turbidostat
        ? (this.f[this.turbAActiveField.key].value as boolean)
        : undefined,
      this.f[this.experimentModeField.key].value === ExperimentMode.Turbidostat
        ? (this.f[this.turbBActiveField.key].value as boolean)
        : undefined,
      this.f[this.experimentModeField.key].value === ExperimentMode.Turbidostat
        ? (this.f[this.turbCActiveField.key].value as boolean)
        : undefined,
      this.f[this.experimentModeField.key].value === ExperimentMode.Turbidostat
        ? (this.f[this.turbDActiveField.key].value as boolean)
        : undefined,
      // Chemostat controls
      this.f[this.experimentModeField.key].value === ExperimentMode.Chemostat
        ? (this.f[this.chemoAActiveField.key].value as boolean)
        : undefined,
      this.f[this.experimentModeField.key].value === ExperimentMode.Chemostat
        ? (this.f[this.chemoBActiveField.key].value as boolean)
        : undefined,
      this.f[this.experimentModeField.key].value === ExperimentMode.Chemostat
        ? (this.f[this.chemoCActiveField.key].value as boolean)
        : undefined,
      this.f[this.experimentModeField.key].value === ExperimentMode.Chemostat
        ? (this.f[this.chemoDActiveField.key].value as boolean)
        : undefined,
      this.f[this.experimentModeField.key].value === ExperimentMode.Chemostat
        ? (this.f[this.flowRateAField.key].value as number)
        : undefined,
      this.f[this.experimentModeField.key].value === ExperimentMode.Chemostat
        ? (this.f[this.flowRateBField.key].value as number)
        : undefined,
      this.f[this.experimentModeField.key].value === ExperimentMode.Chemostat
        ? (this.f[this.flowRateCField.key].value as number)
        : undefined,
      this.f[this.experimentModeField.key].value === ExperimentMode.Chemostat
        ? (this.f[this.flowRateDField.key].value as number)
        : undefined,
      // pH controls
      this.f[this.experimentModeField.key].value === ExperimentMode.PHControl
        ? (this.f[this.phControlAField.key].value as boolean)
        : undefined,
      this.f[this.experimentModeField.key].value === ExperimentMode.PHControl
        ? (this.f[this.phControlBField.key].value as boolean)
        : undefined,
      this.f[this.experimentModeField.key].value === ExperimentMode.PHControl
        ? (this.f[this.phControlCField.key].value as boolean)
        : undefined,
      this.f[this.experimentModeField.key].value === ExperimentMode.PHControl
        ? (this.f[this.phControlDField.key].value as boolean)
        : undefined,
      this.f[this.experimentModeField.key].value === ExperimentMode.PHControl
        ? (this.f[this.targetPHAField.key].value as number)
        : undefined,
      this.f[this.experimentModeField.key].value === ExperimentMode.PHControl
        ? (this.f[this.targetPHBField.key].value as number)
        : undefined,
      this.f[this.experimentModeField.key].value === ExperimentMode.PHControl
        ? (this.f[this.targetPHCField.key].value as number)
        : undefined,
      this.f[this.experimentModeField.key].value === ExperimentMode.PHControl
        ? (this.f[this.targetPHDField.key].value as number)
        : undefined,
      this.f[this.experimentModeField.key].value === ExperimentMode.PHControl &&
        this.f[this.inputTypeAField.key].value === 'BaseA',
      this.f[this.experimentModeField.key].value === ExperimentMode.PHControl &&
        this.f[this.inputTypeAField.key].value === 'BaseB',
      this.f[this.experimentModeField.key].value === ExperimentMode.PHControl &&
        this.f[this.inputTypeAField.key].value === 'BaseC',
      this.f[this.experimentModeField.key].value === ExperimentMode.PHControl &&
        this.f[this.inputTypeAField.key].value === 'BaseD',
      this.f[this.experimentModeField.key].value === ExperimentMode.PHControl
        ? (this.f[this.pumpDurationAField.key].value as number)
        : undefined,
      this.f[this.experimentModeField.key].value === ExperimentMode.PHControl
        ? (this.f[this.pumpDurationBField.key].value as number)
        : undefined,
      this.f[this.experimentModeField.key].value === ExperimentMode.PHControl
        ? (this.f[this.pumpDurationCField.key].value as number)
        : undefined,
      this.f[this.experimentModeField.key].value === ExperimentMode.PHControl
        ? (this.f[this.pumpDurationDField.key].value as number)
        : undefined,
      // LED controls, submitted even if fluorescence is not being measured
      this.f[this.led1OnField.key].value as boolean,
      this.f[this.led2OnField.key].value as boolean,
      this.f[this.led3OnField.key].value as boolean,
      this.f[this.led1IntensityField.key].value as number,
      this.f[this.led2IntensityField.key].value as number,
      this.f[this.led3IntensityField.key].value as number,
      // Oxygen controls
      this.useOxygen
        ? (this.f[this.salinityAField.key].value as number)
        : undefined,
      this.useOxygen
        ? (this.f[this.salinityBField.key].value as number)
        : undefined,
      this.useOxygen
        ? (this.f[this.salinityCField.key].value as number)
        : undefined,
      this.useOxygen
        ? (this.f[this.salinityDField.key].value as number)
        : undefined,
      this.f[this.noteField.key].value as string,
    );
    this.experimentService.updateDraftExperiment(updateExperiment).subscribe({
      next: (response) => {
        this.isProcessing = false;
        this.nextButtonDisabled = false;
        this.createdExperiment = response;
        this.draftIsLocked =
          this.createdExperiment.experimentStatusId ===
          ExperimentStatus.DraftLocked;
        this.experimentId = response.id;
        this.getExperimentChangelog();
        this.updateFormValues(this.createdExperiment);
        this.openModal('update-experiment');
      },
      error: (error: ErrorObject) => {
        this.error = error;
        this.isProcessing = false;
        this.openModal('error');
      },
    });
  }

  deleteDraft(): void {
    // If request has already been submitted, exit
    if (this.isProcessing || this.deleteIsProcessing) {
      return;
    }

    this.deleteError = null;
    this.deleteIsProcessing = true;

    this.experimentService.deleteExperimentById(this.experimentId).subscribe({
      next: () => {
        this.deleteIsProcessing = false;
        this.closeModal('delete-draft');
        this.openModal('delete-success');
      },
      error: (error: ErrorObject) => {
        this.deleteIsProcessing = false;
        this.deleteError = error;
        this.closeModal('delete-draft');
        this.openModal('delete-error');
      },
    });
  }

  updateIdInUrl(id: number): void {
    window.history.replaceState({}, '', `/experiment/${id}/set-parameters`);
  }

  getExperimentChangelog(): void {
    this.error = null;
    this.experimentService
      .getExperimentChangelog(
        this.experimentId,
        this.currentSort.sortBy,
        this.currentSort.sortDirection,
        this.pageSize,
        this.pageRequested,
      )
      .subscribe({
        // If successful, update the changelogList and pagination vars
        // If error, set error to be displayed
        next: (response: ChangelogResponse) => {
          this.error = null;
          this.changelogList = response.changelogList;
          // Set pagination vars from response headers
          this.currentPage = response.paginationData.CurrentPage;
          this.pageSize = response.paginationData.PageSize;
          this.totalPages = response.paginationData.TotalPages;
          this.hasNext = response.paginationData.HasNext;
          this.hasPrevious = response.paginationData.HasPrevious;
        },
        error: (error: ErrorObject) => {
          this.error = error;
        },
      });
  }

  getPrevious(): void {
    this.pageRequested = this.currentPage - 1;
    this.getExperimentChangelog();
  }

  getNext(): void {
    this.pageRequested = this.currentPage + 1;
    this.getExperimentChangelog();
  }

  getClasses(sortTerm: string): string[] {
    const active = this.currentSort.sortBy === sortTerm ? 'active' : '';
    return [active];
  }

  onSort(sortTerm: string): void {
    if (this.currentSort.sortBy === sortTerm) {
      if (this.currentSort.sortDirection === 'asc') {
        this.currentSort.sortDirection = 'desc';
      } else {
        this.currentSort.sortDirection = 'asc';
      }
    } else {
      this.currentSort.sortBy = sortTerm;
      this.currentSort.sortDirection = 'asc';
    }
    this.getExperimentChangelog();
  }

  handleExperimentModeChange(value: number): void {
    const liquidControlModuleExists = this.requiredDeviceModules.find(
      (x: Module) => x === Module.LiquidControl,
    );
    const phModuleExists = this.requiredDeviceModules.find(
      (x: Module) => x === Module.pH,
    );
    const pHControlIndex = this.moduleCheckboxesField.options.findIndex(
      (option) => option.key === 'pH',
    );
    switch (value) {
      case ExperimentMode.BatchCulture as number:
        if (liquidControlModuleExists) {
          const index = this.requiredDeviceModules.findIndex(
            (x: Module) => x === Module.LiquidControl,
          );
          this.requiredDeviceModules.splice(index, 1);
        }
        if (phModuleExists) {
          const index = this.requiredDeviceModules.findIndex(
            (x: Module) => x === Module.pH,
          );
          this.requiredDeviceModules.splice(index, 1);
        }
        // Reenable the pH checkbox
        this.checkboxesFormArray.controls[pHControlIndex].enable();
        break;
      case ExperimentMode.Chemostat as number:
      case ExperimentMode.Turbidostat as number:
        if (!liquidControlModuleExists) {
          this.requiredDeviceModules.push(Module.LiquidControl);
        }
        // Reeable the pH checkbox
        this.checkboxesFormArray.controls[pHControlIndex].enable();
        break;
      case ExperimentMode.PHControl as number:
        if (!liquidControlModuleExists) {
          this.requiredDeviceModules.push(Module.LiquidControl);
        }
        if (!phModuleExists) {
          this.requiredDeviceModules.push(Module.pH);
        }
        // Set pH to true
        this.usePH = true;
        // Set pH checkbox to true
        this.checkboxesFormArray.controls[pHControlIndex].setValue(true);
        // Also disable the pH checkbox
        this.checkboxesFormArray.controls[pHControlIndex].disable();
        break;
      default:
        break;
    }
    if (this.createdExperiment?.devices?.length > 0) {
      this.checkRequiredModules();
    }
    this.moduleSelectForm.markAsDirty();
  }

  moduleCheckboxClicked(event: Event): void {
    const target = event.target as HTMLFormElement;
    switch (target.name) {
      case 'Oxygen':
        this.useOxygen = target.checked as boolean;
        if (target.checked) {
          this.requiredDeviceModules.push(Module.Oxygen);
        } else {
          const index = this.requiredDeviceModules.findIndex(
            (x: Module) => x === Module.Oxygen,
          );
          this.requiredDeviceModules.splice(index, 1);
        }
        break;
      case 'Fluorescence':
        this.useFluorescence = target.checked as boolean;
        if (target.checked) {
          this.requiredDeviceModules.push(Module.Fluorescence);
        } else {
          const index = this.requiredDeviceModules.findIndex(
            (x: Module) => x === Module.Fluorescence,
          );
          this.requiredDeviceModules.splice(index, 1);
        }
        break;
      case 'pH':
        this.usePH = target.checked as boolean;
        if (target.checked) {
          this.requiredDeviceModules.push(Module.pH);
        } else {
          const index = this.requiredDeviceModules.findIndex(
            (x: Module) => x === Module.pH,
          );
          this.requiredDeviceModules.splice(index, 1);
        }
        break;
      default:
        break;
    }
    if (this.createdExperiment?.devices?.length > 0) {
      this.checkRequiredModules();
    }
    this.moduleSelectForm.markAsDirty();
  }

  checkRequiredModules(): void {
    const haveRequiredModules = allDevicesHaveRequiredModules(
      this.createdExperiment.devices,
      this.requiredDeviceModules,
    );
    if (!haveRequiredModules) {
      this.openModal('device-warning');
    }
  }

  onNext(): void {
    // Route to the device setup page
    void this.router.navigate([
      `experiment/${this.experimentId}/select-device`,
    ]);
  }

  openModal(modalId: string): void {
    this.modalService.open(modalId);
  }

  closeModal(modalId: string): void {
    this.modalService.close(modalId);
  }

  canExit(): Promise<boolean> | boolean {
    return this.isNavigationAllowed();
  }

  private isNavigationAllowed(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      if (this.experimentControlsForm.pristine) {
        resolve(true);
      } else {
        this.openModal('attention');
        this.allowNavigation.subscribe((isConfirmed) => resolve(isConfirmed));
      }
    });
  }
}
