import { Component, inject } from '@angular/core';
import { environment } from 'src/environments/environment';
import { User } from './_models/user';
import { AuthenticationService } from './_services/authentication.service';
import { RouterOutlet } from '@angular/router';
import { NavbarComponent } from './_components/navbar/navbar.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [NavbarComponent, RouterOutlet],
})
export class AppComponent {
  private readonly authenticationService = inject(AuthenticationService);

  title = 'Ogi Bio';
  appVersion = environment.appVersion;
  backendVersionString = environment.apiUrl;
  currentUser: User;

  constructor() {
    this.authenticationService.currentUser.subscribe((x) => {
      this.currentUser = x;
    });
  }
}
