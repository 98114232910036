<div id="title--container">
  <h4>Approvals</h4>
  <h5>{{ laboratory?.laboratoryName }}</h5>
</div>
<hr />
<div *ngIf="!loading; else loadingSpinner" id="approvals--container">
  <div id="nav--container">
    <app-option-tab-toggle
      id="toggle--container"
      radioGroup="navigationToggle"
      [selectOptions]="navOptions"
      type="round"
    >
    </app-option-tab-toggle>
  </div>
  <div
    *ngIf="organisationChangeRequests?.length > 0"
    id="inner-page--container"
  >
    <p id="table-description" style="display: none">
      Organisation change requests table
    </p>
    <div id="table--container">
      <table aria-describedby="table-description">
        <thead>
          <tr>
            <th
              id="request-type"
              scope="col"
              (click)="onSort('RequestType')"
              [ngClass]="getClasses('RequestType')"
            >
              <div id="table-header">
                <span>Action</span>
                <mat-icon
                  aria-label="sort direction"
                  matTooltip="Sort direction"
                  >swap_vert</mat-icon
                >
              </div>
            </th>
            <th id="request-description" scope="col">
              <div id="table-header">
                <span>Description</span>
              </div>
            </th>
            <th
              id="org-name"
              scope="col"
              (click)="onSort('OrganisationName')"
              [ngClass]="getClasses('OrganisationName')"
            >
              <div id="table-header">
                <span>Name</span>
                <mat-icon
                  aria-label="sort direction"
                  matTooltip="Sort direction"
                  >swap_vert</mat-icon
                >
              </div>
            </th>
            <th
              id="license-type"
              scope="col"
              (click)="onSort('LicenseType')"
              [ngClass]="getClasses('LicenseType')"
            >
              <div id="table-header">
                <span>License Type</span>
                <mat-icon
                  aria-label="sort direction"
                  matTooltip="Sort direction"
                  >swap_vert</mat-icon
                >
              </div>
            </th>
            <th
              id="super-user"
              scope="col"
              (click)="onSort('SuperUserEmail')"
              [ngClass]="getClasses('SuperUserEmail')"
            >
              <div id="table-header">
                <span>Super User</span>
                <mat-icon
                  aria-label="sort direction"
                  matTooltip="Sort direction"
                  >swap_vert</mat-icon
                >
              </div>
            </th>
            <th
              id="status"
              scope="col"
              (click)="onSort('RequestStatus')"
              [ngClass]="getClasses('RequestStatus')"
            >
              <div id="table-header">
                <span>Status</span>
                <mat-icon
                  aria-label="sort direction"
                  matTooltip="Sort direction"
                  >swap_vert</mat-icon
                >
              </div>
            </th>
            <th
              id="requested-by"
              scope="col"
              (click)="onSort('RequestedBy')"
              [ngClass]="getClasses('RequestedBy')"
            >
              <div id="table-header">
                <span>Requested By</span>
                <mat-icon
                  aria-label="sort direction"
                  matTooltip="Sort direction"
                  >swap_vert</mat-icon
                >
              </div>
            </th>
            <th
              id="request-created"
              scope="col"
              (click)="onSort('CreatedAt')"
              [ngClass]="getClasses('RequestCreated')"
            >
              <div id="table-header">
                <span>Requested On</span>
                <mat-icon
                  aria-label="sort direction"
                  matTooltip="Sort direction"
                  >swap_vert</mat-icon
                >
              </div>
            </th>
            <th
              id="actioned-by"
              scope="col"
              (click)="onSort('ActionedBy')"
              [ngClass]="getClasses('ActionedBy')"
            >
              <div id="table-header">
                <span>Actioned By</span>
                <mat-icon
                  aria-label="sort direction"
                  matTooltip="Sort direction"
                  >swap_vert</mat-icon
                >
              </div>
            </th>
            <th
              id="request-actioned"
              scope="col"
              (click)="onSort('UpdatedAt')"
              [ngClass]="getClasses('UpdatedAt')"
            >
              <div id="table-header">
                <span>Actioned On</span>
                <mat-icon
                  aria-label="sort direction"
                  matTooltip="Sort direction"
                  >swap_vert</mat-icon
                >
              </div>
            </th>
            <th id="info" scope="col" [ngClass]="getClasses('Info')">
              <div id="table-header">
                <span>Info</span>
              </div>
            </th>

            <th id="action" scope="col">
              <div id="table-header">
                <span>Action</span>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let request of organisationChangeRequests">
            <td headers="request-type">{{ request.requestType }}</td>
            <td headers="request-description">
              {{ request.updateDifferences || "-" }}
            </td>
            <td headers="org-name">{{ request.organisationName }}</td>
            <td headers="license-type">
              {{ LicenseType[request.licenseType] }}
            </td>
            <td headers="super-user">
              {{ request.superUserFirstName }}
              {{ request.superUserLastName }} <br />
              {{ request.superUserEmail }}
            </td>
            <td headers="status">{{ request.requestStatus }}</td>
            <td headers="requested-by">{{ request.requestedBy }}</td>
            <td headers="request-created">
              {{ request.createdAt | date : "HH:mm dd/MM/yy" }}
            </td>
            <td headers="actioned-by">{{ request.actionedBy }}</td>
            <td headers="request-actioned">
              {{
                request.updatedAt === request.createdAt
                  ? "-"
                  : (request.updatedAt | date : "HH:mm dd/MM/yy")
              }}
            </td>
            <td headers="info">
              {{ request.requestInfo }}
            </td>
            <td headers="action">
              <div id="button--container">
                <app-button
                  *ngIf="request.requestStatus === 'Pending'"
                  buttonId="approve-btn"
                  buttonStyle="positive"
                  label="Approve"
                  (buttonClicked)="approveOrgRequest(request)"
                  [isDisabled]="request.requestedBy === currentUser?.email"
                ></app-button>
                <app-button
                  *ngIf="request.requestStatus === 'Pending'"
                  buttonId="reject-btn"
                  buttonStyle="negative"
                  label="Reject"
                  (buttonClicked)="openRejectRequestModal(request)"
                ></app-button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div id="pagination--container">
        <div class="filter--container">
          <label for="approvals-action">Filter by action</label>
          <app-dropdown-form-field
            id="action-filter"
            [field]="requestActionFilters"
            [parentForm]="tableControls"
          >
          </app-dropdown-form-field>
        </div>
        <div class="filter--container">
          <label for="approvals-status">Filter by status</label>
          <app-dropdown-form-field
            id="status-filter"
            [field]="requestStatusFilters"
            [parentForm]="tableControls"
          >
          </app-dropdown-form-field>
        </div>
        <div class="filter--container">
          <label for="requests-per-page">Requests per page</label>
          <app-dropdown-form-field
            id="requests-per-page"
            [field]="requestsPerPageField"
            [parentForm]="tableControls"
          >
          </app-dropdown-form-field>
        </div>
        <div id="pagination--controls">
          <app-icon-button
            iconName="chevron_left"
            buttonId="previous"
            (buttonClicked)="getPrevious()"
            [isDisabled]="!hasPrevious"
          ></app-icon-button>
          <span> {{ currentPage }} / {{ totalPages }} </span>
          <app-icon-button
            iconName="chevron_right"
            buttonId="next"
            (buttonClicked)="getNext()"
            [isDisabled]="!hasNext"
          ></app-icon-button>
        </div>
      </div>
    </div>
  </div>
  <div
    id="cta--container"
    *ngIf="
      !loading &&
      (!organisationChangeRequests || organisationChangeRequests.length === 0)
    "
  >
    <h4>No organisation change requests detected</h4>
    <p *ngIf="error" class="error">Error: {{ error.message }}</p>
  </div>
</div>
<ng-template #loadingSpinner>
  <div id="loading-spinner"><app-loading-spinner></app-loading-spinner></div>
</ng-template>
<app-modal
  id="success"
  titleText="Request Approved"
  primaryText="The organisation request has been approved."
  primaryButtonText="Continue"
  primaryButtonId="close"
  modalStyle="success"
  (modalButtonClicked)="modalButtonClicked($event)"
></app-modal>
<app-modal
  id="rejection-success"
  titleText="Request Rejected"
  primaryText="The organisation request has been rejected."
  primaryButtonText="Continue"
  primaryButtonId="close-rejection-success"
  modalStyle="success"
  (modalButtonClicked)="modalButtonClicked($event)"
></app-modal>
<app-modal
  id="rejection-fail"
  titleText="Error"
  primaryText="Something went wrong"
  primaryButtonText="Continue"
  primaryButtonId="close-rejection-warning"
  modalStyle="error"
  (modalButtonClicked)="modalButtonClicked($event)"
></app-modal>
<app-provide-info-modal
  id="provide-info"
  [approvalRequest]="orgApprovalUpdate"
  (rejectionConfirmed)="rejectRequest($event)"
></app-provide-info-modal>
