import { Component, Input } from '@angular/core';
import { Device } from 'src/app/_models/device';

@Component({
  selector: 'app-device-setup-row',
  templateUrl: './device-setup-row.component.html',
  styleUrls: ['./device-setup-row.component.scss'],
})
export class DeviceSetupRowComponent {
  /**
   * @param {Device} device The calibration summary for the device
   * @required
   */
  @Input()
  device: Device;

  /**
   * @param {boolean} isDisabled Is the button disabled
   */
  @Input()
  deviceComplete = false;

  /**
   * @returns {string[]} Returns a list of classes based on the status of the device
   */
  public get classes(): string[] {
    const complete = this.deviceComplete === true ? 'complete' : '';
    return [`status--${this.device.deviceStatusId}`, complete];
  }

  /**
   * @returns {string[]} Returns a the animation class based on the status of the device
   */
  public get chevronClasses(): string[] {
    const animate = this.device.deviceStatusId === 4 ? 'animate' : '';
    return [animate];
  }
}
