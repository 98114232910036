<section>
  <div class="title--container">
    <h6 *ngIf="flask" class="flask">{{ flask }}</h6>
    <app-svg-template
      *ngIf="flask"
      [iconName]="flaskIcon"
      height="30"
      width="30"
      customViewBox="0 0 30 30"
    ></app-svg-template>
  </div>
  <div>
    <span class="body2 title">{{ categoryString }}: </span
    ><span class="body2 data">{{ dataReading | number : "1.2-2" }}</span>
  </div>
  <div>
    <span class="body2 title">Last OD reading: </span
    ><span class="body2 data"> {{ time | date : "HH:mm:ss, dd/MM/yy" }}</span>
  </div>
  <div>
    <span class="body2 title">Temperature: </span
    ><span class="body2 coming-soon">
      {{ temperatureReading | number : "1.2-2" }}
    </span>
  </div>
  <div>
    <span class="body2 title">Stirring speed: </span
    ><span class="body2 coming-soon"> {{ speedRPMReading }} </span>
  </div>
  <app-status-indicator
    *ngIf="deviceStatus"
    [experimentStatus]="statusClass"
  ></app-status-indicator>
</section>
