import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-small-loading-spinner',
  templateUrl: './small-loading-spinner.component.html',
  styleUrls: ['./small-loading-spinner.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SmallLoadingSpinnerComponent {}
