import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';

@Component({
  selector: 'app-mat-icon-button',
  templateUrl: './mat-icon-button.component.html',
  styleUrl: './mat-icon-button.component.scss',
  standalone: true,
  imports: [MatIconModule, MatButtonModule, MatRippleModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MatIconButtonComponent {
  /**
   * @param {string} buttonId The id of the button
   * @requires
   */
  @Input()
  buttonId: string | undefined;

  /**
   * @param {string} buttonStyle The button style required
   * @requires
   */
  @Input()
  buttonStyle: 'primary' | 'accent' | 'warn' = 'primary';

  /**
   * @param {boolean} isDisabled Is the button disabled
   */
  @Input()
  isDisabled = false;

  /**
   * @param {string} iconName The icon name
   * @requires
   */
  @Input()
  iconName: string | undefined;

  /**
   * @param {string} ariaLabel The aria label
   * @requires
   */
  @Input()
  ariaLabel: string | undefined;

  /**
   * Output click event
   */
  @Output()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  buttonClicked: EventEmitter<any> = new EventEmitter();

  clickButton = (): void => {
    this.buttonClicked.emit();
  };
}
