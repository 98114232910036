<div id="title--container">
  <!-- Optical Density Calibration Header -->
  @if (calibrationType === "optical-density") {
    <h4>Optical Density Calibration - {{ odCalibration?.deviceName }}</h4>
  }
  <!-- Oxygen Saturation Calibration Header -->
  @if (calibrationType === "oxygen") {
    <h4>Oxygen Saturation Calibration - {{ oxygenCalibration?.deviceName }}</h4>
    @if (oxygenCalibration?.isDeleted) {
      <app-status-indicator status="Deleted"></app-status-indicator>
    }
  }
  <!-- pH Calibration Header -->
  @if (calibrationType === "ph") {
    <h4>pH Calibration - {{ phCalibration?.deviceName }}</h4>
    @if (phCalibration?.isDeleted) {
      <app-status-indicator status="Deleted"></app-status-indicator>
    }
  }
  <h5>{{ laboratory?.laboratoryName }}</h5>
</div>
<hr />
<div id="calibration--container">
  <div id="calibration-data--container">
    @if (calibrationType === "optical-density" && odCalibration) {
      <app-reduced-small-data-card
        title="Created At"
        [dataReading]="odCalibration?.createdAt | date: 'HH:mm, dd/MM/yy'"
      >
      </app-reduced-small-data-card>
      <app-reduced-small-data-card
        title="Name"
        [dataReading]="odCalibration?.name || 'loading'"
      >
      </app-reduced-small-data-card>
      <app-reduced-small-data-card
        title="Save Location"
        [dataReading]="odCalibration?.saveLocationName || 'loading'"
      >
      </app-reduced-small-data-card>
      <app-reduced-small-data-card
        title="Complete?"
        [dataReading]="odCalibration?.isFinal ? 'Yes' : 'No'"
      >
      </app-reduced-small-data-card>
      @if (odCalibration?.isFinal && !odCalibration?.isDeleted) {
        <app-icon-button
          label="Delete"
          buttonId="delete-calibration-btn"
          buttonStyle="negative"
          iconName="delete"
          buttonShape="square"
          (buttonClicked)="openModal('delete-calibration')"
          [isDisabled]="
            currentUser.role
              | roleCheck
                : [
                    Role.Read_Access_User,
                    Role.Run_Access_User,
                    Role.Edit_Access_User,
                  ]
          "
        ></app-icon-button>
      }
    }
    @if (calibrationType === "oxygen") {
      <app-reduced-small-data-card
        title="Created At"
        [dataReading]="
          oxygenCalibration?.createdAt | date: 'HH:mm, dd/MM/yy' || 'loading'
        "
      >
      </app-reduced-small-data-card>
      <app-reduced-small-data-card
        title="Name"
        [dataReading]="oxygenCalibration?.name || 'loading'"
      >
      </app-reduced-small-data-card>
      <app-reduced-small-data-card
        title="Save Location"
        [dataReading]="oxygenCalibration?.saveLocationName || 'loading'"
      >
      </app-reduced-small-data-card>
      <app-reduced-small-data-card
        title="Complete?"
        [dataReading]="oxygenCalibration?.isFinal ? 'Yes' : 'No'"
      >
      </app-reduced-small-data-card>
      @if (oxygenCalibration?.isFinal && !oxygenCalibration?.isDeleted) {
        <app-icon-button
          label="Delete"
          buttonId="delete-calibration-btn"
          buttonStyle="negative"
          iconName="delete"
          buttonShape="square"
          (buttonClicked)="openModal('delete-calibration')"
          [isDisabled]="
            currentUser.role
              | roleCheck: [Role.Read_Access_User, Role.Run_Access_User]
          "
        ></app-icon-button>
      }
    }
    @if (calibrationType === "ph") {
      <app-reduced-small-data-card
        title="Created At"
        [dataReading]="
          phCalibration?.createdAt | date: 'HH:mm, dd/MM/yy' || 'loading'
        "
      >
      </app-reduced-small-data-card>
      <app-reduced-small-data-card
        title="Name"
        [dataReading]="phCalibration?.name || 'loading'"
      >
      </app-reduced-small-data-card>
      <app-reduced-small-data-card
        title="Save Location"
        [dataReading]="phCalibration?.saveLocationName || 'loading'"
      >
      </app-reduced-small-data-card>
      <app-reduced-small-data-card
        title="Complete?"
        [dataReading]="phCalibration?.isFinal ? 'Yes' : 'No'"
      >
      </app-reduced-small-data-card>
      @if (phCalibration?.isFinal && !phCalibration?.isDeleted) {
        <app-icon-button
          label="Delete"
          buttonId="delete-calibration-btn"
          buttonStyle="negative"
          iconName="delete"
          buttonShape="square"
          (buttonClicked)="openModal('delete-calibration')"
          [isDisabled]="
            currentUser.role
              | roleCheck: [Role.Read_Access_User, Role.Run_Access_User]
          "
        ></app-icon-button>
      }
    }
  </div>
  <hr />
  <p id="table-description" style="display: none">Calibration results table</p>
  @if (calibrationType === "optical-density") {
    <div class="section--container">
      @if (odCalibration?.isFinal) {
        <div class="subtitle1">
          <h6>Results</h6>
          <table aria-describedby="table-description">
            <thead>
              <tr>
                <th id="flasks" scope="col">Flasks</th>
                <th id="calibration-offset" scope="col">Offset (p0)</th>
                <th id="calibration-factor" scope="col">Factor (p1)</th>
                <th id="calibration-power" scope="col">Power (p2)</th>
                <th id="r-squared" scope="col">R<sup>2</sup></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td headers="flasks">A</td>
                <td headers="calibration-offset">
                  {{
                    odCalibration?.isFinal ? odCalibration?.calOffset_A : "-"
                  }}
                </td>
                <td headers="calibration-factor">
                  {{
                    odCalibration?.isFinal ? odCalibration?.calFactor_A : "-"
                  }}
                </td>
                <td headers="calibration-power">
                  {{ odCalibration?.isFinal ? odCalibration?.calPower_A : "-" }}
                </td>
                <td headers="r-squared">
                  {{ odCalibration?.isFinal ? odCalibration?.r2_A : "-" }}
                </td>
              </tr>
              <tr>
                <td headers="flasks">B</td>
                <td headers="calibration-offset">
                  {{
                    odCalibration?.isFinal ? odCalibration?.calOffset_B : "-"
                  }}
                </td>
                <td headers="calibration-factor">
                  {{
                    odCalibration?.isFinal ? odCalibration?.calFactor_B : "-"
                  }}
                </td>
                <td headers="calibration-power">
                  {{ odCalibration?.isFinal ? odCalibration?.calPower_B : "-" }}
                </td>
                <td headers="r-squared">
                  {{ odCalibration?.isFinal ? odCalibration?.r2_B : "-" }}
                </td>
              </tr>
              <tr>
                <td headers="flasks">C</td>
                <td headers="calibration-offset">
                  {{
                    odCalibration?.isFinal ? odCalibration?.calOffset_C : "-"
                  }}
                </td>
                <td headers="calibration-factor">
                  {{
                    odCalibration?.isFinal ? odCalibration?.calFactor_C : "-"
                  }}
                </td>
                <td headers="calibration-power">
                  {{ odCalibration?.isFinal ? odCalibration?.calPower_C : "-" }}
                </td>
                <td headers="r-squared">
                  {{ odCalibration?.isFinal ? odCalibration?.r2_C : "-" }}
                </td>
              </tr>
              <tr>
                <td headers="flasks">D</td>
                <td headers="calibration-offset">
                  {{
                    odCalibration?.isFinal ? odCalibration?.calOffset_D : "-"
                  }}
                </td>
                <td headers="calibration-factor">
                  {{
                    odCalibration?.isFinal ? odCalibration?.calFactor_D : "-"
                  }}
                </td>
                <td headers="calibration-power">
                  {{ odCalibration?.isFinal ? odCalibration?.calPower_D : "-" }}
                </td>
                <td headers="r-squared">
                  {{ odCalibration?.isFinal ? odCalibration?.r2_D : "-" }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      }
      @if (!odCalibration?.isFinal) {
        <div class="button--container no-padding">
          <app-icon-button
            [label]="voltageLabel"
            buttonId="poll-voltage-btn"
            (buttonClicked)="getVoltageValues()"
            [isProcessing]="voltagePollingShouldContinue"
            iconName="autorenew"
            buttonShape="square"
            buttonStyle="outlined"
            [isDisabled]="
              voltageReadings !== null ||
              voltagePollingShouldContinue ||
              calibrationPollingSubscription !== null
            "
          ></app-icon-button>
          <app-button
            label="Stop Polling"
            buttonId="stop-voltage-polling-btn"
            [isDisabled]="voltagePollingSubscription === null"
            (buttonClicked)="stopVoltagePolling()"
          >
          </app-button>
        </div>
        <div class="subtitle1">
          <mat-accordion class="example-headers-align" multi>
            <mat-expansion-panel [expanded]="false">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <h6>Reactor A Input Values</h6>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="flask--container">
                <div>
                  Voltage: {{ voltageReadings?.flaskAVoltageValue1 }}
                  <app-underlined-form-field
                    [parentForm]="odCalibrationForm"
                    [field]="flaskAValue1Field"
                  ></app-underlined-form-field>
                </div>
                <div>
                  Voltage: {{ voltageReadings?.flaskAVoltageValue2 }}
                  <app-underlined-form-field
                    [parentForm]="odCalibrationForm"
                    [field]="flaskAValue2Field"
                  ></app-underlined-form-field>
                </div>
                <div>
                  Voltage: {{ voltageReadings?.flaskAVoltageValue3 }}
                  <app-underlined-form-field
                    [parentForm]="odCalibrationForm"
                    [field]="flaskAValue3Field"
                  ></app-underlined-form-field>
                </div>
                <div>
                  Voltage: {{ voltageReadings?.flaskAVoltageValue4 }}
                  <app-underlined-form-field
                    [parentForm]="odCalibrationForm"
                    [field]="flaskAValue4Field"
                  ></app-underlined-form-field>
                </div>
                <div>
                  Voltage: {{ voltageReadings?.flaskAVoltageValue5 }}
                  <app-underlined-form-field
                    [parentForm]="odCalibrationForm"
                    [field]="flaskAValue5Field"
                  ></app-underlined-form-field>
                </div>
                <div>
                  Voltage: {{ voltageReadings?.flaskAVoltageValue6 }}
                  <app-underlined-form-field
                    [parentForm]="odCalibrationForm"
                    [field]="flaskAValue6Field"
                  ></app-underlined-form-field>
                </div>
              </div>
            </mat-expansion-panel>
            <mat-expansion-panel [expanded]="false">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <h6>Reactor B Input Values</h6>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="flask--container">
                <div>
                  Voltage: {{ voltageReadings?.flaskBVoltageValue1 }}
                  <app-underlined-form-field
                    [parentForm]="odCalibrationForm"
                    [field]="flaskBValue1Field"
                  ></app-underlined-form-field>
                </div>
                <div>
                  Voltage: {{ voltageReadings?.flaskBVoltageValue2 }}
                  <app-underlined-form-field
                    [parentForm]="odCalibrationForm"
                    [field]="flaskBValue2Field"
                  ></app-underlined-form-field>
                </div>
                <div>
                  Voltage: {{ voltageReadings?.flaskBVoltageValue3 }}
                  <app-underlined-form-field
                    [parentForm]="odCalibrationForm"
                    [field]="flaskBValue3Field"
                  ></app-underlined-form-field>
                </div>
                <div>
                  Voltage: {{ voltageReadings?.flaskBVoltageValue4 }}
                  <app-underlined-form-field
                    [parentForm]="odCalibrationForm"
                    [field]="flaskBValue4Field"
                  ></app-underlined-form-field>
                </div>
                <div>
                  Voltage: {{ voltageReadings?.flaskBVoltageValue5 }}
                  <app-underlined-form-field
                    [parentForm]="odCalibrationForm"
                    [field]="flaskBValue5Field"
                  ></app-underlined-form-field>
                </div>
                <div>
                  Voltage: {{ voltageReadings?.flaskBVoltageValue6 }}
                  <app-underlined-form-field
                    [parentForm]="odCalibrationForm"
                    [field]="flaskBValue6Field"
                  ></app-underlined-form-field>
                </div>
              </div>
            </mat-expansion-panel>
            <mat-expansion-panel [expanded]="false">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <h6>Reactor C Input Values</h6>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="flask--container">
                <div>
                  Voltage: {{ voltageReadings?.flaskCVoltageValue1 }}
                  <app-underlined-form-field
                    [parentForm]="odCalibrationForm"
                    [field]="flaskCValue1Field"
                  ></app-underlined-form-field>
                </div>
                <div>
                  Voltage: {{ voltageReadings?.flaskCVoltageValue2 }}
                  <app-underlined-form-field
                    [parentForm]="odCalibrationForm"
                    [field]="flaskCValue2Field"
                  ></app-underlined-form-field>
                </div>
                <div>
                  Voltage: {{ voltageReadings?.flaskCVoltageValue3 }}
                  <app-underlined-form-field
                    [parentForm]="odCalibrationForm"
                    [field]="flaskCValue3Field"
                  ></app-underlined-form-field>
                </div>
                <div>
                  Voltage: {{ voltageReadings?.flaskCVoltageValue4 }}
                  <app-underlined-form-field
                    [parentForm]="odCalibrationForm"
                    [field]="flaskCValue4Field"
                  ></app-underlined-form-field>
                </div>
                <div>
                  Voltage: {{ voltageReadings?.flaskCVoltageValue5 }}
                  <app-underlined-form-field
                    [parentForm]="odCalibrationForm"
                    [field]="flaskCValue5Field"
                  ></app-underlined-form-field>
                </div>
                <div>
                  Voltage: {{ voltageReadings?.flaskCVoltageValue6 }}
                  <app-underlined-form-field
                    [parentForm]="odCalibrationForm"
                    [field]="flaskCValue6Field"
                  ></app-underlined-form-field>
                </div>
              </div>
            </mat-expansion-panel>
            <mat-expansion-panel [expanded]="false">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <h6>Reactor D Input Values</h6>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="flask--container">
                <div>
                  Voltage: {{ voltageReadings?.flaskDVoltageValue1 }}
                  <app-underlined-form-field
                    [parentForm]="odCalibrationForm"
                    [field]="flaskDValue1Field"
                  ></app-underlined-form-field>
                </div>
                <div>
                  Voltage: {{ voltageReadings?.flaskDVoltageValue2 }}
                  <app-underlined-form-field
                    [parentForm]="odCalibrationForm"
                    [field]="flaskDValue2Field"
                  ></app-underlined-form-field>
                </div>
                <div>
                  Voltage: {{ voltageReadings?.flaskDVoltageValue3 }}
                  <app-underlined-form-field
                    [parentForm]="odCalibrationForm"
                    [field]="flaskDValue3Field"
                  ></app-underlined-form-field>
                </div>
                <div>
                  Voltage: {{ voltageReadings?.flaskDVoltageValue4 }}
                  <app-underlined-form-field
                    [parentForm]="odCalibrationForm"
                    [field]="flaskDValue4Field"
                  ></app-underlined-form-field>
                </div>
                <div>
                  Voltage: {{ voltageReadings?.flaskDVoltageValue5 }}
                  <app-underlined-form-field
                    [parentForm]="odCalibrationForm"
                    [field]="flaskDValue5Field"
                  ></app-underlined-form-field>
                </div>
                <div>
                  Voltage: {{ voltageReadings?.flaskDVoltageValue6 }}
                  <app-underlined-form-field
                    [parentForm]="odCalibrationForm"
                    [field]="flaskDValue6Field"
                  ></app-underlined-form-field>
                </div>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      }
    </div>
  }
  @if (calibrationType === "oxygen") {
    <div class="section--container">
      <table aria-describedby="table-description">
        <thead>
          <tr>
            <th id="flasks" scope="col">Flasks</th>
            <th id="dphi" scope="col">dphi</th>
            <th id="temperature" scope="col">Temperature</th>
            <th id="pressure" scope="col">Pressure</th>
            <th id="has-error" scope="col">Error</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td headers="flasks">A</td>
            <td headers="dphi">
              {{
                oxygenCalibration?.isFinal
                  ? oxygenSaturation === 0
                    ? oxygenCalibration?.dphi_0_A
                    : oxygenCalibration?.dphi_100_A
                  : "-"
              }}
            </td>
            <td headers="temperature">
              {{
                oxygenCalibration?.isFinal
                  ? oxygenSaturation === 0
                    ? oxygenCalibration?.temperature_0_A
                    : oxygenCalibration?.temperature_100_A
                  : "-"
              }}
            </td>
            <td headers="pressure">
              {{
                oxygenCalibration?.isFinal
                  ? oxygenSaturation === 0
                    ? oxygenCalibration?.pressure_0_A
                    : oxygenCalibration?.pressure_100_A
                  : "-"
              }}
            </td>
            <td headers="has-error">
              {{
                oxygenCalibration?.isFinal
                  ? oxygenSaturation === 0
                    ? oxygenCalibration?.hasError_0_A
                    : oxygenCalibration?.hasError_100_A
                  : "-"
              }}
            </td>
          </tr>
          <tr>
            <td headers="flasks">B</td>
            <td headers="dphi">
              {{
                oxygenCalibration?.isFinal
                  ? oxygenSaturation === 0
                    ? oxygenCalibration?.dphi_0_B
                    : oxygenCalibration?.dphi_100_B
                  : "-"
              }}
            </td>
            <td headers="temperature">
              {{
                oxygenCalibration?.isFinal
                  ? oxygenSaturation === 0
                    ? oxygenCalibration?.temperature_0_B
                    : oxygenCalibration?.temperature_100_B
                  : "-"
              }}
            </td>
            <td headers="pressure">
              {{
                oxygenCalibration?.isFinal
                  ? oxygenSaturation === 0
                    ? oxygenCalibration?.pressure_0_B
                    : oxygenCalibration?.pressure_100_B
                  : "-"
              }}
            </td>
            <td headers="has-error">
              {{
                oxygenCalibration?.isFinal
                  ? oxygenSaturation === 0
                    ? oxygenCalibration?.hasError_0_B
                    : oxygenCalibration?.hasError_100_B
                  : "-"
              }}
            </td>
          </tr>
          <tr>
            <td headers="flasks">C</td>
            <td headers="dphi">
              {{
                oxygenCalibration?.isFinal
                  ? oxygenSaturation === 0
                    ? oxygenCalibration?.dphi_0_C
                    : oxygenCalibration?.dphi_100_C
                  : "-"
              }}
            </td>
            <td headers="temperature">
              {{
                oxygenCalibration?.isFinal
                  ? oxygenSaturation === 0
                    ? oxygenCalibration?.temperature_0_C
                    : oxygenCalibration?.temperature_100_C
                  : "-"
              }}
            </td>
            <td headers="pressure">
              {{
                oxygenCalibration?.isFinal
                  ? oxygenSaturation === 0
                    ? oxygenCalibration?.pressure_0_C
                    : oxygenCalibration?.pressure_100_C
                  : "-"
              }}
            </td>
            <td headers="has-error">
              {{
                oxygenCalibration?.isFinal
                  ? oxygenSaturation === 0
                    ? oxygenCalibration?.hasError_0_C
                    : oxygenCalibration?.hasError_100_C
                  : "-"
              }}
            </td>
          </tr>
          <tr>
            <td headers="flasks">D</td>
            <td headers="dphi">
              {{
                oxygenCalibration?.isFinal
                  ? oxygenSaturation === 0
                    ? oxygenCalibration?.dphi_0_D
                    : oxygenCalibration?.dphi_100_D
                  : "-"
              }}
            </td>
            <td headers="temperature">
              {{
                oxygenCalibration?.isFinal
                  ? oxygenSaturation === 0
                    ? oxygenCalibration?.temperature_0_D
                    : oxygenCalibration?.temperature_100_D
                  : "-"
              }}
            </td>
            <td headers="pressure">
              {{
                oxygenCalibration?.isFinal
                  ? oxygenSaturation === 0
                    ? oxygenCalibration?.pressure_0_D
                    : oxygenCalibration?.pressure_100_D
                  : "-"
              }}
            </td>
            <td headers="has-error">
              {{
                oxygenCalibration?.isFinal
                  ? oxygenSaturation === 0
                    ? oxygenCalibration?.hasError_0_D
                    : oxygenCalibration?.hasError_100_D
                  : "-"
              }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  }
  @if (calibrationType === "ph") {
    <div class="section--container">
      <table aria-describedby="table-description">
        <thead>
          <tr>
            <th id="ph-flasks" scope="col">Flasks</th>
            <th id="slope" scope="col">Slope [%]</th>
            <th id="offset" scope="col">Offset [mV]</th>
            <th id="ph-calibration-factor" scope="col">Calibration Factor</th>
            <th id="ph-calibration-offset" scope="col">Calibration Offset</th>
            <th id="ph-r-squared" scope="col">R<sup>2</sup></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td headers="ph-flasks">A</td>
            <td headers="slope">
              {{ phCalibration?.isFinal ? phCalibration?.slope_A : "-" }}
            </td>
            <td headers="offset">
              {{ phCalibration?.isFinal ? phCalibration?.offset_A : "-" }}
            </td>
            <td headers="ph-calibration-factor">
              {{ phCalibration?.isFinal ? phCalibration?.calFactor_A : "-" }}
            </td>
            <td headers="ph-calibration-offset">
              {{ phCalibration?.isFinal ? phCalibration?.calOffset_A : "-" }}
            </td>
            <td headers="ph-r-squared">
              {{ phCalibration?.isFinal ? phCalibration?.r2_A : "-" }}
            </td>
          </tr>
          <tr>
            <td headers="ph-flasks">B</td>
            <td headers="slope">
              {{ phCalibration?.isFinal ? phCalibration?.slope_B : "-" }}
            </td>
            <td headers="offset">
              {{ phCalibration?.isFinal ? phCalibration?.offset_B : "-" }}
            </td>
            <td headers="ph-calibration-factor">
              {{ phCalibration?.isFinal ? phCalibration?.calFactor_B : "-" }}
            </td>
            <td headers="ph-calibration-offset">
              {{ phCalibration?.isFinal ? phCalibration?.calOffset_B : "-" }}
            </td>
            <td headers="ph-r-squared">
              {{ phCalibration?.isFinal ? phCalibration?.r2_B : "-" }}
            </td>
          </tr>
          <tr>
            <td headers="ph-flasks">C</td>
            <td headers="slope">
              {{ phCalibration?.isFinal ? phCalibration?.slope_C : "-" }}
            </td>
            <td headers="offset">
              {{ phCalibration?.isFinal ? phCalibration?.offset_C : "-" }}
            </td>
            <td headers="ph-calibration-factor">
              {{ phCalibration?.isFinal ? phCalibration?.calFactor_C : "-" }}
            </td>
            <td headers="ph-calibration-offset">
              {{ phCalibration?.isFinal ? phCalibration?.calOffset_C : "-" }}
            </td>
            <td headers="ph-r-squared">
              {{ phCalibration?.isFinal ? phCalibration?.r2_C : "-" }}
            </td>
          </tr>
          <tr>
            <td headers="ph-flasks">D</td>
            <td headers="slope">
              {{ phCalibration?.isFinal ? phCalibration?.slope_D : "-" }}
            </td>
            <td headers="offset">
              {{ phCalibration?.isFinal ? phCalibration?.offset_D : "-" }}
            </td>
            <td headers="ph-calibration-factor">
              {{ phCalibration?.isFinal ? phCalibration?.calFactor_D : "-" }}
            </td>
            <td headers="ph-calibration-offset">
              {{ phCalibration?.isFinal ? phCalibration?.calOffset_D : "-" }}
            </td>
            <td headers="ph-r-squared">
              {{ phCalibration?.isFinal ? phCalibration?.r2_D : "-" }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  }
  @if (calibrationType === "optical-density" && !odCalibration?.isFinal) {
    @if (!hasSentFinalisationSignal) {
      <div class="finalise--container">
        <p class="subtitle1">
          When finalising your calibration, you may input up to 6 values for
          each reactor
        </p>
        <app-button
          label="Finalise Calibration"
          buttonId="finalise-calibration-btn"
          [isDisabled]="
            odCalibration?.isFinal ||
            (currentUser.role
              | roleCheck: [Role.Read_Access_User, Role.Run_Access_User]) ||
            finalisationIsProcessing
          "
          (buttonClicked)="finaliseCalibration()"
          [isProcessing]="finalisationIsProcessing"
        ></app-button>
      </div>
    } @else {
      <span class="body2"> Finalise message has been sent to device </span>
    }
    @if (finalisationError) {
      <span class="error">
        {{ finalisationError.message }}
      </span>
    }
  }
  <div class="button--container">
    @if (calibrationType === "optical-density") {
      @if (!odCalibration?.isFinal && !odCalibration?.isDeleted) {
        <app-icon-button
          [label]="calibrationLabel"
          buttonId="poll-cal-btn"
          (buttonClicked)="startCalibrationPolling()"
          [isProcessing]="calibrationPollingShouldContinue"
          iconName="autorenew"
          buttonShape="square"
          buttonStyle="outlined"
          [isDisabled]="
            calibrationPollingSubscription !== null ||
            calibrationPollingShouldContinue
          "
        ></app-icon-button>
        <app-button
          label="Stop Polling"
          buttonId="stop-cal-polling-btn"
          [isDisabled]="calibrationPollingSubscription === null"
          (buttonClicked)="stopCalibrationPolling()"
        >
        </app-button>
      }
      @if (!odCalibration?.isFinal && !odCalibration?.isDeleted) {
        <app-button
          label="Discard Calibration"
          buttonId="discard-calibration-btn"
          buttonStyle="negative"
          [isDisabled]="
            currentUser.role
              | roleCheck: [Role.Read_Access_User, Role.Run_Access_User]
          "
          (buttonClicked)="openModal('confirm-discard')"
        ></app-button>
      }
      @if (odCalibration?.isDeleted) {
        <app-status-indicator status="Deleted"></app-status-indicator>
      }
    }
    @if (calibrationType === "oxygen") {
      @if (!oxygenCalibration?.isFinal && !oxygenCalibration?.isDeleted) {
        <app-icon-button
          [label]="calibrationLabel"
          buttonId="poll-cal-btn"
          (buttonClicked)="startOxygenPolling()"
          [isProcessing]="oxygenPollingShouldContinue"
          iconName="autorenew"
          buttonShape="square"
          buttonStyle="outlined"
          [isDisabled]="
            oxygenCalibration?.isFinal ||
            oxygenPollingSubscription !== null ||
            oxygenPollingShouldContinue
          "
        ></app-icon-button>
        <app-button
          label="Stop Polling"
          buttonId="stop-cal-polling-btn"
          [isDisabled]="oxygenPollingSubscription === null"
          (buttonClicked)="stopOxygenPolling()"
        >
        </app-button>
      }
      @if (!oxygenCalibration?.isFinal && !oxygenCalibration?.isDeleted) {
        <app-button
          label="Discard Calibration"
          buttonId="discard-calibration-btn"
          buttonStyle="negative"
          [isDisabled]="
            currentUser.role
              | roleCheck: [Role.Read_Access_User, Role.Run_Access_User]
          "
          (buttonClicked)="openModal('confirm-discard')"
        ></app-button>
      }
      @if (oxygenCalibration?.isDeleted) {
        <app-status-indicator status="Deleted"></app-status-indicator>
      }
    }
    @if (calibrationType === "ph") {
      @if (!phCalibration?.isFinal && !phCalibration?.isDeleted) {
        <app-icon-button
          [label]="calibrationLabel"
          buttonId="poll-cal-btn"
          (buttonClicked)="startPHPolling()"
          [isProcessing]="phPollingShouldContinue"
          iconName="autorenew"
          buttonShape="square"
          buttonStyle="outlined"
        ></app-icon-button>
        <app-button
          label="Stop Polling"
          buttonId="stop-cal-polling-btn"
          [isDisabled]="phPollingSubscription === null"
          (buttonClicked)="stopPHPolling()"
        >
        </app-button>
        <app-button
          label="Discard Calibration"
          buttonId="discard-calibration-btn"
          buttonStyle="negative"
          [isDisabled]="
            currentUser.role
              | roleCheck: [Role.Read_Access_User, Role.Run_Access_User]
          "
          (buttonClicked)="openModal('confirm-discard')"
        ></app-button>
      }
      @if (phCalibration?.isDeleted) {
        <app-status-indicator status="Deleted"></app-status-indicator>
      }
    }
  </div>
  @if (calibrationType === "optical-density") {
    <div class="section--container">
      <div class="subtitle1">
        Data last updated: {{ timeOfLastUpdate | date: "HH:mm a, dd/MM/yy" }}
      </div>
      @if (renderCalibrationGraph) {
        <app-calibration-graph
          [calibrationData]="calibrationData"
          [graphId]="'calibration-graph'"
          [yAxisLabel]="'Voltage [V]'"
          [results]="odCalibration"
        >
        </app-calibration-graph>
      } @else {
        <div class="loading--container">
          @if (dataIsProcessing) {
            <app-loading-spinner
              [label]="calibrationLoadingText"
            ></app-loading-spinner>
          } @else {
            <div>{{ dataError?.message }}</div>
          }
        </div>
      }
    </div>
  }
  @if (calibrationType === "oxygen") {
    <div class="section--container">
      <div class="subtitle1">
        Data last updated: {{ timeOfLastUpdate | date: "HH:mm a, dd/MM/yy" }}
      </div>
      <div class="selection--container">
        <app-radio-button
          id="toggle--container"
          [field]="saturationToggle"
          [parentForm]="selectSaturationForm"
          [currentValue]="
            selectSaturationForm?.controls[saturationToggle.key]?.value
          "
          type="round"
          (radioButtonChange)="
            handleOxygenSaturationToggle(
              selectSaturationForm?.controls[saturationToggle.key]?.value
            )
          "
        ></app-radio-button>
      </div>
      @if (renderCalibrationGraph) {
        <app-oxygen-calibration-graph
          [calibrationData]="oxygenCalibrationData"
          [graphId]="'calibration-graph'"
          [yAxisLabel]="'dphi'"
        ></app-oxygen-calibration-graph>
      } @else {
        <div class="loading--container">
          @if (dataIsProcessing) {
            <app-loading-spinner
              [label]="calibrationLoadingText"
            ></app-loading-spinner>
          } @else {
            @if (dataError) {
              <div>{{ dataError.message }}</div>
            } @else {
              <div class="subtitle1">No data received</div>
            }
          }
        </div>
      }
    </div>
  }
  @if (calibrationType === "ph") {
    <div class="section--container">
      <div class="subtitle1">
        Data last updated: {{ timeOfLastUpdate | date: "HH:mm a, dd/MM/yy" }}
      </div>
      @if (renderCalibrationGraph) {
        <app-ph-calibration-graph
          [calibrationData]="phCalibrationData"
          [graphId]="'calibration-graph'"
          [yAxisLabel]="'Reading'"
        >
        </app-ph-calibration-graph>
      } @else {
        <div class="loading--container">
          @if (dataIsProcessing) {
            <app-loading-spinner
              [label]="calibrationLoadingText"
            ></app-loading-spinner>
          } @else {
            @if (dataError) {
              <div>{{ dataError.message }}</div>
            } @else {
              <div class="subtitle1">No data received</div>
            }
          }
        </div>
      }
    </div>
  }
</div>
<app-validation-modal
  id="confirm-discard"
  titleText="Confirm Discard"
  primaryText="Are you sure you want to discard this calibration?"
  buttonType="double-button"
  primaryButtonText="Confirm"
  primaryButtonId="discard-confirm-button"
  secondaryButtonId="discard-cancel-button"
  secondaryButtonText="Cancel"
  (modalButtonClicked)="discardCalibration()"
  validationText="delete"
  [isProcessing]="discardIsProcessing"
></app-validation-modal>
<app-modal
  id="discard-success-response"
  titleText="Calibration deleted"
  primaryText="Choose an action below"
  buttonType="double-button"
  primaryButtonText="Go to dashboard"
  primaryButtonId="success-primary-button"
  secondaryButtonText="Create new calibration"
  secondaryButtonId="success-secondary-button"
  modalStyle="success"
  (modalButtonClicked)="modalButtonClicked($event)"
  allowClose="false"
>
</app-modal>
<app-modal
  id="discard-error-response"
  titleText="Unable to delete calibration"
  [primaryText]="discardError?.message + '. Choose an action below'"
  buttonType="double-button"
  primaryButtonText="Go to dashboard"
  primaryButtonId="error-primary-button"
  secondaryButtonText="Close"
  secondaryButtonId="error-secondary-button"
  modalStyle="error"
  (modalButtonClicked)="modalButtonClicked($event)"
  allowClose="false"
>
</app-modal>
<app-validation-modal
  id="delete-calibration"
  titleText="Delete calibration?"
  validationText="delete"
  buttonType="double-button"
  primaryButtonText="Confirm delete"
  primaryButtonId="confirm-delete-button"
  secondaryButtonText="Cancel"
  secondaryButtonId="cancel-delete-button"
  (modalButtonClicked)="discardCalibration()"
  [error]="discardError"
></app-validation-modal>
