<div id="app">
  <!-- HEADER -->
  @if (currentUser) {
    <app-navbar></app-navbar>
  }
  <div class="page--container">
    <!-- ROUTES RENDERED HERE -->
    <router-outlet></router-outlet>

    <!-- FOOTER -->
    @if (currentUser) {
      <div class="footer--container">
        <a
          id="footer--left"
          class="body2"
          href=""
          target="_blank"
          rel="noopener noreferrer"
          >Terms and Conditions</a
        >
        <div id="footer--center" class="body2">{{ title }}</div>
        <div id="footer--right" class="body2">
          frontend: {{ appVersion }} backend: {{ backendVersionString }}
        </div>
      </div>
    }
  </div>
</div>
