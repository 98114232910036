import { Component, Input } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { IconNames } from '../icons/icon-names';

@Component({
  selector: 'app-svg-template',
  templateUrl: './svg-template.component.html',
  styleUrls: ['./svg-template.component.scss'],
})
export class SvgTemplateComponent {
  /**
   * @param {string} focusFillColour Icon fill colour
   */
  @Input()
  focusFillColour: 'blue' | 'white' = 'white';

  /**
   * @param {IconName} iconName Icon name
   */
  @Input()
  iconName: IconNames;

  /**
   * @param {string} width Custom svg width
   */
  @Input()
  width = '32';

  /**
   * @param {string} height Custom svg height
   */
  @Input()
  height = '32';

  /**
   * @param {string} customViewBox Custom viewbox dimensions
   */
  @Input()
  customViewBox = `0 0 32 32`;

  /**
   * @param {string} title The title ref for the svg. Please match to the title
   * set within the icon name being used (important for accessibility)
   */
  @Input()
  title = 'title';

  constructor(private sanitizer: DomSanitizer) {}

  public get classes(): string[] {
    return ['base-icon'];
  }

  public get viewBox(): string {
    return this.customViewBox;
  }

  public get icon(): SafeHtml {
    let pathString = '';
    pathString = this.iconName.toString();
    return this.sanitizer.bypassSecurityTrustHtml(pathString);
  }
}
