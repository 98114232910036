import {
  Component,
  EventEmitter,
  Input,
  Output,
  ChangeDetectionStrategy,
} from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import {
  MatButtonToggle,
  MatButtonToggleGroup,
} from '@angular/material/button-toggle';
import { FormBase } from 'src/app/_models/form-base';

@Component({
  selector: 'app-slide-toggle-group',
  standalone: true,
  imports: [MatButtonToggleGroup, MatButtonToggle, ReactiveFormsModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './slide-toggle-group.component.html',
  styleUrl: './slide-toggle-group.component.scss',
})
export class SlideToggleGroupComponent {
  /**
   * @param {FormBase<string>} field Form field base
   * @requires
   */
  @Input()
  field: FormBase<string>;

  /**
   * @param {FormGroup} parentForm The parent form
   * @requires
   */
  @Input()
  parentForm: FormGroup = new FormGroup({});

  @Output() toggleClicked = new EventEmitter<Event>();

  onToggleClick(event: Event): void {
    this.toggleClicked.emit(event);
  }
}
