import {
  Component,
  EventEmitter,
  Input,
  Output,
  ChangeDetectionStrategy,
} from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormBase } from 'src/app/_models/form-base';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'app-search-field',
  templateUrl: './search-field.component.html',
  styleUrl: './search-field.component.scss',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, MatIcon],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchFieldComponent {
  /**
   * Form base
   * @requires
   */
  @Input()
  field: FormBase<string>;

  /**
   * The form group from the parent form component
   * @requires
   */
  @Input()
  parentForm: FormGroup = new FormGroup({});

  /**
   * Output click event
   */
  @Output()
  buttonClicked: EventEmitter<string> = new EventEmitter<string>();

  clickButton = (action: string): void => {
    this.buttonClicked.emit(action);
  };
}
