/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { ErrorObject } from 'src/app/_models/error';
import { FormBase } from 'src/app/_models/form-base';
import { User } from 'src/app/_models/user';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { LaboratoryService } from 'src/app/_services/laboratory.service';
import { ModalService } from 'src/app/_services/modal.service';

@Component({
  selector: 'app-add-lab',
  templateUrl: './add-lab.component.html',
  styleUrls: ['./add-lab.component.scss'],
})
export class AddLabComponent implements OnInit {
  // Control vars
  isProcessing = false;
  error: ErrorObject = null;
  currentUser: User;
  addLabSuccessMessage: string;
  organisationId: string;

  allowNavigation: Subject<boolean> = new Subject<boolean>();

  labNameField = new FormBase({
    key: 'labName',
    label: 'Lab Name',
    type: 'text',
    placeholder: 'Lab Name',
    disabled: false,
    required: true,
    value: '',
    options: [],
  });

  addLabForm = this.formBuilder.group({
    [this.labNameField.key]: ['', Validators.required],
  });

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private modalService: ModalService,
    private authenticationService: AuthenticationService,
    private laboratoryService: LaboratoryService
  ) {
    this.currentUser = this.authenticationService.currentUserValue;
  }

  ngOnInit(): void {
    // Use route data to get and set list of device ids
    if (window.history.state.data) {
      this.organisationId = window.history.state.data as string;
    }
  }

  openModal(modalId: string): void {
    this.modalService.open(modalId);
  }

  closeModal(modalId: string): void {
    this.modalService.close(modalId);
  }

  modalButtonClicked(buttonId: string): void {
    switch (buttonId) {
      case 'dashboard-button':
        void this.router.navigate(['dashboard/devices']);
        break;
      case 'close-button':
        this.closeModal('create-lab');
        this.closeModal('error');
        break;
      case 'cancel-button':
        this.allowNavigation.next(false);
        this.closeModal('attention');
        break;
      case 'confirm-navigation':
        this.allowNavigation.next(true);
        this.closeModal('attention');
        break;
    }
  }

  onSubmit(): void {
    // If form is already being submitted, exit
    if (this.isProcessing) {
      return;
    }

    this.isProcessing = true;
    this.error = null;
    this.addLabSuccessMessage = '';

    const orgId =
      this.currentUser.organisationId.toString() !==
      this.organisationId.toString()
        ? this.organisationId
        : null;

    this.laboratoryService
      .addLab(this.addLabForm.value.labName, orgId)
      .subscribe({
        next: (response: string) => {
          this.addLabSuccessMessage = response;
          this.isProcessing = false;
          this.addLabForm.reset();
          this.openModal('create-lab');
        },
        error: (error: ErrorObject) => {
          this.isProcessing = false;
          this.error = error;
          this.openModal('error');
        },
      });
  }
}
