export class OxygenCalibration {
  deviceId: number;
  name: string;
  saveLocationName: string;
  temperatureControlA: boolean;
  temperatureControlB: boolean;
  temperatureControlC: boolean;
  temperatureControlD: boolean;
  inputTemperatureA?: number;
  inputTemperatureB?: number;
  inputTemperatureC?: number;
  inputTemperatureD?: number;
  isZeroPercent?: boolean;
  is100Percent?: boolean;
  temperature_0_A?: number;
  temperature_0_B?: number;
  temperature_0_C?: number;
  temperature_0_D?: number;
  temperature_100_A?: number;
  temperature_100_B?: number;
  temperature_100_C?: number;
  temperature_100_D?: number;
  pressure_0_A?: number;
  pressure_0_B?: number;
  pressure_0_C?: number;
  pressure_0_D?: number;
  pressure_100_A?: number;
  pressure_100_B?: number;
  pressure_100_C?: number;
  pressure_100_D?: number;
  dphi_0_A?: number;
  dphi_0_B?: number;
  dphi_0_C?: number;
  dphi_0_D?: number;
  dphi_100_A?: number;
  dphi_100_B?: number;
  dphi_100_C?: number;
  dphi_100_D?: number;
  hasError_0_A?: boolean;
  hasError_0_B?: boolean;
  hasError_0_C?: boolean;
  hasError_0_D?: boolean;
  hasError_100_A?: boolean;
  hasError_100_B?: boolean;
  hasError_100_C?: boolean;
  hasError_100_D?: boolean;
  id?: number;
  isFinal?: boolean;
  laboratoryId?: number;
  createdAt?: Date;
  isDeleted?: boolean;

  constructor(
    deviceId: number,
    name: string,
    saveLocationName: string,
    temperatureControlA: boolean,
    temperatureControlB: boolean,
    temperatureControlC: boolean,
    temperatureControlD: boolean,
    inputTemperatureA?: number,
    inputTemperatureB?: number,
    inputTemperatureC?: number,
    inputTemperatureD?: number
  ) {
    this.deviceId = deviceId;
    this.name = name;
    this.saveLocationName = saveLocationName;
    this.temperatureControlA = temperatureControlA;
    this.temperatureControlB = temperatureControlB;
    this.temperatureControlC = temperatureControlC;
    this.temperatureControlD = temperatureControlD;
    this.inputTemperatureA = inputTemperatureA;
    this.inputTemperatureB = inputTemperatureB;
    this.inputTemperatureC = inputTemperatureC;
    this.inputTemperatureD = inputTemperatureD;
  }
}
