@if (!isDataList) {
  <mat-form-field
    appearance="fill"
    [formGroup]="parentForm"
    [floatLabel]="floatLabel ? 'always' : 'auto'"
  >
    <mat-label>{{ field.label }}</mat-label>
    <mat-select
      [formControlName]="field.key"
      [required]="field.required"
      [placeholder]="field.placeholder"
      [attr.aria-required]="field.required"
      [value]="field.value"
    >
      @for (option of field.options; track option.key) {
        <mat-option [value]="option.key">
          {{ option.value }}
        </mat-option>
      }
    </mat-select>
    @if (hasErrors) {
      <mat-error>{{ errorsList[0] }}</mat-error>
    }
  </mat-form-field>
}
@if (isDataList) {
  <mat-form-field
    appearance="fill"
    [formGroup]="parentForm"
    [floatLabel]="floatLabel ? 'always' : 'auto'"
  >
    <mat-label>{{ field.label }}</mat-label>
    <input
      type="text"
      [required]="field.required"
      [placeholder]="field.placeholder"
      [attr.aria-required]="field.required"
      matInput
      [formControlName]="field.key"
      [matAutocomplete]="auto"
    />
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
      @for (option of filteredOptions | async; track option.key) {
        <mat-option [value]="option">
          {{ option.value }}
        </mat-option>
      }
    </mat-autocomplete>
    @if (hasErrors) {
      <mat-error>{{ errorsList[0] }}</mat-error>
    }
  </mat-form-field>
}
