<div
  class="select-container"
  [class.select-container--round]="type === 'round'"
  [class.select-container--square]="type === 'square'"
>
  @for (option of selectOptions; track option.value) {
    <div
      class="radio-item body2"
      [class.radio-item--round]="type === 'round'"
      [class.radio-item--square]="type === 'square'"
      [routerLink]="option.link"
      routerLinkActive="selected"
    >
      <input
        [id]="option.value"
        [value]="option.value"
        type="radio"
        [attr.name]="radioGroup"
      />
      <label
        [for]="option.value"
        class="label subtitle1"
        [class.label--round]="type === 'round'"
        [class.label--square]="type === 'square'"
      >
        {{ option.labelText }}
      </label>
    </div>
  }
</div>
