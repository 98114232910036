import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ERROR_MESSAGE } from 'src/app/_helpers/utils';
import { FormBase } from 'src/app/_models/form-base';

@Component({
  selector: 'app-underlined-textarea-field',
  templateUrl: './underlined-textarea-field.component.html',
  styleUrls: ['./underlined-textarea-field.component.scss'],
})
export class UnderlinedTextareaFieldComponent {
  /**
   * @param {FormBase<string>} filed Form field base
   * @requires
   */
  @Input()
  field: FormBase<string>;

  /**
   * @param { FormGroup } parentForm The parent form
   * @requires
   */
  @Input()
  parentForm: FormGroup = new FormGroup({});

  /**
   * @param {number} rows Number of rows
   */
  @Input()
  rows = 5;

  /**
   * @param {number} columns Number of columns
   */
  @Input()
  columns = 30;

  public get classes(): string[] {
    const disabled = this.field.disabled ? 'disabled' : '';
    const error = this.hasErrors ? 'error' : '';
    const validated =
      !this.hasErrors && this.parentForm.controls[this.field.key]?.touched
        ? 'validated'
        : '';
    return ['underlined-form-field__container', disabled, error, validated];
  }

  public get hasErrors(): boolean {
    return (
      this.parentForm.controls[this.field.key] &&
      this.parentForm.controls[this.field.key]?.errors &&
      this.parentForm.controls[this.field.key]?.touched
    );
  }

  public get errorsList(): string[] {
    return Object.keys(this.parentForm.controls[this.field.key].errors).map(
      (err) =>
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        ERROR_MESSAGE[err](
          this.parentForm.controls[this.field.key].getError(err)
        ) as string
    );
  }
}
