import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormBase } from 'src/app/_models/form-base';

@Component({
  selector: 'app-slide-toggle',
  templateUrl: './slide-toggle.component.html',
  styleUrls: ['./slide-toggle.component.scss'],
})
export class SlideToggleComponent {
  /**
   * @param {FormBase<string>} field Form field base
   * @requires
   */
  @Input()
  field: FormBase<string>;

  /**
   * @param {FormGroup} parentForm The parent form
   * @requires
   */
  @Input()
  parentForm: FormGroup = new FormGroup({});
}
