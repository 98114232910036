<div id="dashboard--container">
  <div id="header--container">
    <h4>Welcome to your dashboard.</h4>
    <h5>{{ laboratory?.laboratoryName }}</h5>
  </div>
  <hr class="alt" />
  <div id="nav--container">
    <div class="button--container">
      <app-option-tab-toggle
        id="toggle--container"
        radioGroup="navigationToggle"
        [selectOptions]="navOptions"
        type="round"
      >
      </app-option-tab-toggle>
      <button
        mat-mini-fab
        aria-label="reset search form"
        (click)="getLogs()"
        title="Refresh"
        color="primary"
      >
        <mat-icon>restart_alt</mat-icon>
      </button>
    </div>
    <div id="nav-button-container">
      <app-search-field
        [field]="searchField"
        [parentForm]="errorLogTableControls"
        (buttonClicked)="handleSearch($event)"
      >
      </app-search-field>
      <app-icon-button
        label="filters"
        buttonStyle="secondary"
        buttonId="filter-btn"
        iconName="filter_list"
        buttonShape="square"
        title="Filters"
        (buttonClicked)="openModal('filter')"
      ></app-icon-button>
      <div class="button--container">
        <app-icon-button
          iconName="visibility"
          label="mark all as viewed"
          buttonStyle="secondary"
          buttonId="viewed-btn"
          buttonShape="square"
          title="Mark all as viewed"
          (buttonClicked)="onMarkAllAsViewed()"
          [isDisabled]="
            (currentUser.role
              | roleCheck: [Role.Read_Access_User, Role.Run_Access_User]) ||
            selectedLogs.length === 0
          "
        ></app-icon-button>
      </div>
    </div>
  </div>
  @if (errorLogs?.length > 0) {
    <div id="inner-page--container">
      <p id="table-description" style="display: none">
        Error log summary table
      </p>
      <div id="table--container">
        <table aria-describedby="table-description">
          <thead>
            <tr>
              <th id="check" scope="col"></th>
              <th id="device-name" scope="col">
                <div id="table-header">
                  <span>Device</span>
                </div>
              </th>
              <th id="error-text" scope="col">
                <div id="table-header">
                  <span>Error Text</span>
                </div>
              </th>
              <th
                id="created-at"
                scope="col"
                (click)="onSort('CreatedAt')"
                [class.active]="currentSort.sortBy === 'CreatedAt'"
              >
                <div id="table-header">
                  <span>Created At</span>
                  <mat-icon
                    aria-label="sort direction"
                    matTooltip="Sort direction"
                    >swap_vert</mat-icon
                  >
                </div>
              </th>
              <th
                id="viewed"
                scope="col"
                (click)="onSort('Viewed')"
                [class.active]="currentSort.sortBy === 'Viewed'"
              >
                <div id="table-header">
                  <span>Viewed?</span>
                  <mat-icon
                    aria-label="sort direction"
                    matTooltip="Sort direction"
                    >swap_vert</mat-icon
                  >
                </div>
              </th>
              <th id="viewed-btn" scope="col"></th>
            </tr>
          </thead>
          <tbody>
            @for (errorLog of errorLogs; track errorLog.id; let i = $index) {
              <tr>
                <td headers="check" id="checkbox-column">
                  <app-checkbox-group-no-label
                    [option]="getCheckbox(i)"
                    [parentForm]="errorLogTableControls"
                    [formArrayGroupName]="errorLogCheckboxes.key"
                    (checkboxClicked)="errorLogCheckboxClicked($event)"
                    [isDisabled]="errorLog.viewed"
                  >
                  </app-checkbox-group-no-label>
                </td>
                <td headers="device-name">{{ errorLog.deviceName }}</td>
                <td headers="error-text" id="error-text-col">
                  <span class="error-text">{{ errorLog.errorText }}</span>
                  <app-icon-button
                    label="view detail"
                    buttonStyle="transparent--blue"
                    buttonId="detail-btn"
                    buttonShape="square"
                    (buttonClicked)="onViewErrorLog(errorLog)"
                  ></app-icon-button>
                </td>
                <td headers="created-at">
                  {{ errorLog.createdAt | date: "dd/MM/yy, HH:mm a" }}
                </td>
                <td headers="viewed" id="viewed-col">
                  @if (errorLog.viewed) {
                    <mat-icon class="positive">check</mat-icon>
                  }
                  @if (!errorLog.viewed) {
                    <mat-icon class="negative">close</mat-icon>
                  }
                </td>
                <td headers="viewed-btn" id="view-cell">
                  <div>
                    @if (!errorLog.viewed) {
                      <app-button
                        label="Mark as Viewed"
                        [buttonId]="errorLog?.id"
                        (buttonClicked)="
                          onSetErrorLogAsRead([errorLog.id], true)
                        "
                      >
                      </app-button>
                    }
                    @if (errorLog.viewed) {
                      <app-button
                        label="Mark as Unviewed"
                        [buttonId]="errorLog?.id"
                        (buttonClicked)="
                          onSetErrorLogAsRead([errorLog.id], false)
                        "
                      >
                      </app-button>
                    }
                  </div>
                </td>
              </tr>
            }
          </tbody>
        </table>
        <div id="pagination--container">
          <div class="logs-per-page--container">
            <label for="logs-per-page">Error logs per page</label>
            <app-dropdown-form-field
              id="logs-per-page"
              [field]="errorLogsPerPageField"
              [parentForm]="errorLogTableControls"
            >
            </app-dropdown-form-field>
          </div>
          <div id="pagination--controls">
            <app-icon-button
              iconName="chevron_left"
              buttonId="previous"
              (buttonClicked)="getPrevious()"
              [isDisabled]="!hasPrevious"
            ></app-icon-button>
            <span> {{ currentPage }} / {{ totalPages }} </span>
            <app-icon-button
              iconName="chevron_right"
              buttonId="next"
              (buttonClicked)="getNext()"
              [isDisabled]="!hasNext"
            ></app-icon-button>
          </div>
        </div>
      </div>
    </div>
  }
  @if (!isLoading && (!errorLogs || errorLogs.length === 0)) {
    <div id="cta--container">
      <h4>No error logs detected</h4>
      @if (displayResetButton) {
        <app-icon-button
          label="reset"
          buttonStyle="secondary"
          buttonId="filter-btn"
          iconName="restart_alt"
          buttonShape="square"
          (buttonClicked)="resetSearch()"
        ></app-icon-button>
      }
    </div>
    @if (error) {
      <p class="error">Error: {{ error.message }}</p>
    }
  }
</div>
@if (isLoading && !errorLogs) {
  <div id="loading--container">
    <app-loading-spinner></app-loading-spinner>
  </div>
}
<app-modal
  id="error-log-modal"
  titleText="Error Log"
  [primaryText]="detailViewLog?.errorText"
  [secondaryText]="detailViewLog?.detailedErrorText"
  primaryButtonText="Close"
  primaryButtonId="close"
  (modalButtonClicked)="modalButtonClicked('close')"
></app-modal>
<app-filter-modal
  id="filter"
  buttonType="double-button"
  primaryButtonText="Apply Filters"
  primaryButtonId="continue-button"
  secondaryButtonText="Reset"
  secondaryButtonId="reset-button"
  [parentForm]="errorLogTableControls"
  [statusField]="errorLogViewedField"
  [hideDeletedField]="true"
  (modalButtonClicked)="modalButtonClicked($event)"
  [isProcessing]="isLoading"
></app-filter-modal>
