/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Component, Input } from '@angular/core';
import { IconNames } from '../../icons/icon-names';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Module } from 'src/app/_models/module-enum';

@Component({
  selector: 'app-active-modules',
  templateUrl: './active-modules.component.html',
  styleUrls: ['./active-modules.component.scss'],
})
export class ActiveModulesComponent {
  /**
   * @param {number[]} activeModules List of active modules
   */
  @Input()
  activeModules: number[];

  /**
   * @param {string} width Custom svg width
   */
  @Input()
  width = '25';

  /**
   * @param {string} height Custom svg height
   */
  @Input()
  height = '25';

  /**
   * @param {string} customViewBox Custom viewbox dimensions
   */
  @Input()
  customViewBox = `0 0 25 25`;

  opticalDensity = IconNames.OpticalDensity;
  oxygen = IconNames.Oxygen;
  fluo = IconNames.Fluorescence;
  liquidControl = IconNames.LiquidControl;
  pH = IconNames.pH;
  airControl = IconNames.AirControl;

  // Makes the Module enum available in the template
  Module = Module;

  constructor(private sanitizer: DomSanitizer) {}

  icon(iconName: string): SafeHtml {
    let pathString = '';
    pathString = iconName.toString();
    return this.sanitizer.bypassSecurityTrustHtml(pathString);
  }
}
