import { UntypedFormArray } from '@angular/forms';
import { TimeComponent, TimeString } from '../_models/time-component';
import { RegexValidator } from './regex.validator';
import { HttpHeaders } from '@angular/common/http';
import { Device } from '../_models/device';

const msPerSecond = 1000;
const secondsPerMinute = 60;
const minsPerHour = 60;
const hoursPerDay = 24;

// Uppercase letter, one lowercase letter, one number, and one special character (&#64;#$%^&+!=)
export const passwordRegex =
  /(?=[A-Za-z0-9@#$%^&+!=]+$)^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$%^&+!=])(?=.{12,}).*$/;
export const phoneNumberRegex = '^[0-9 ()+-]+$';
export const nameRegex = '^([^0-9]*)$';
export const twoDecimalPlaceRegex = '^\\d+(?:\\.\\d{1,2})?$';
export const integerOnlyRegex = '^[0-9]*$';

// Additional name regex
export const advancedNameRegex =
  // eslint-disable-next-line prettier/prettier
  '^(?![. ])(?!.*[. ]$)(?!.*[\x00-\x1F\x7F\"*/:<>?\\|])[_a-zA-Z0-9. ]*$';
export const bufferRegex = '^[\x20-\x7E]*$';

// Helper method to clear form array without breaking subscriptions to value changed
export const clearFormArray = (formArray: UntypedFormArray): void => {
  while (formArray.length !== 0) {
    formArray.removeAt(0);
  }
};

export function allDevicesHaveRequiredModules(
  devices: Device[],
  requiredModules: number[],
): boolean {
  return devices.every((device) =>
    requiredModules.every((module) => device.deviceModules.includes(module)),
  );
}

// Helper method to get remaining time
export const calculateTimeLeft = (
  startTime: string,
  duration: number,
): TimeComponent => {
  const endDate = new Date(startTime);
  endDate.setHours(endDate.getHours() + duration);
  const currentDate = new Date();
  const timeDifference = endDate.getTime() - currentDate.getTime();

  let milliseconds = Math.floor(timeDifference);
  let seconds = Math.floor((timeDifference / msPerSecond) % secondsPerMinute);
  let minutes = Math.floor(
    (timeDifference / (msPerSecond * secondsPerMinute)) % minsPerHour,
  );
  let hours = Math.floor(
    (timeDifference / (msPerSecond * secondsPerMinute * minsPerHour)) %
      hoursPerDay,
  );
  let days = Math.floor(
    timeDifference /
      (msPerSecond * secondsPerMinute * minsPerHour * hoursPerDay),
  );

  milliseconds = milliseconds > 0 ? milliseconds : 0;
  seconds = seconds > 0 ? seconds : 0;
  minutes = minutes > 0 ? minutes : 0;
  hours = hours > 0 ? hours : 0;
  days = days > 0 ? days : 0;

  return { milliseconds, seconds, minutes, hours, days };
};

export const getConvertedTimeFromMs = (timeinMs: number): TimeComponent => {
  let days = Math.floor(
    timeinMs / (msPerSecond * secondsPerMinute * minsPerHour * hoursPerDay),
  );
  let hours = Math.floor(
    (timeinMs / (msPerSecond * secondsPerMinute * minsPerHour)) % hoursPerDay,
  );
  let minutes =
    Math.floor(timeinMs / (msPerSecond * secondsPerMinute)) % minsPerHour;
  let seconds = Math.floor(timeinMs / msPerSecond) % secondsPerMinute;
  let milliseconds = Math.floor(timeinMs);

  // ET - TODO: have a look at handling negative time
  milliseconds = milliseconds > 0 ? milliseconds : 0;
  seconds = seconds > 0 ? seconds : 0;
  minutes = minutes > 0 ? minutes : 0;
  hours = hours > 0 ? hours : 0;
  days = days > 0 ? days : 0;

  return {
    milliseconds,
    seconds,
    minutes,
    hours,
    days,
  };
};

export const getConvertedTimeStringFromMs = (timeinMs: number): TimeString => {
  let days = Math.floor(
    timeinMs / (msPerSecond * secondsPerMinute * minsPerHour * hoursPerDay),
  );
  let hours = Math.floor(
    (timeinMs / (msPerSecond * secondsPerMinute * minsPerHour)) % hoursPerDay,
  );
  let minutes =
    Math.floor(timeinMs / (msPerSecond * secondsPerMinute)) % minsPerHour;
  let seconds = Math.floor(timeinMs / msPerSecond) % secondsPerMinute;
  let milliseconds = Math.floor(timeinMs);

  milliseconds = milliseconds > 0 ? milliseconds : 0;
  seconds = seconds > 0 ? seconds : 0;
  minutes = minutes > 0 ? minutes : 0;
  hours = hours > 0 ? hours : 0;
  days = days > 0 ? days : 0;

  const msString =
    milliseconds > 9
      ? milliseconds.toString()
      : '0' + milliseconds.toString().slice(-2);

  const secString =
    seconds > 9 ? seconds.toString() : '0' + seconds.toString().slice(-2);

  const minString =
    minutes > 9 ? minutes.toString() : '0' + minutes.toString().slice(-2);

  const hrString =
    hours > 9 ? hours.toString() : '0' + hours.toString().slice(-2);

  const dayString =
    days > 9 ? days.toString() : '0' + days.toString().slice(-2);

  return {
    milliseconds: msString,
    seconds: secString,
    minutes: minString,
    hours: hrString,
    days: dayString,
  };
};

export interface ValidationErrorMessages {
  required: () => string;
  requiredTrue: () => string;
  minlength: (par: { requiredLength: number }) => string;
  maxlength: (par: { requiredLength: number }) => string;
  mustMatch: () => string;
  email: () => string;
  pattern: (par: RegexValidator) => string;
  min: (par: { min: number; actual: string }) => string;
  minLength: (par: { minLength: number; actual: string }) => string;
  max: (par: { max: number; actual: string }) => string;
  maxLength: (par: { maxLength: number; actual: string }) => string;
  stepError: (par: { value: number; step: number }) => string;
  step: (par: { value: number; step: number }) => string;
  invalidNumber: () => string;
  integer: () => string;
  buffer: () => string;
}

export const ERROR_MESSAGE: ValidationErrorMessages = {
  required: (): string => `Required field`,
  requiredTrue: (): string => `Please select at least one option`,
  minlength: (par: { requiredLength: number }): string =>
    `Field must be minimum ${par.requiredLength} characters long `,
  maxlength: (par: { requiredLength: number }): string =>
    `Field must be max ${par.requiredLength} characters long `,
  mustMatch: (): string => `Field does not match`,
  email: (): string => `Not a valid email address`,
  pattern: (par: RegexValidator): string => {
    switch (par.requiredPattern) {
      case passwordRegex.toString():
        return `Password must be at least 12 characters long, contain at least one
          uppercase letter, one lowercase letter, one number, and one special
          character (&#64;#$%^&+!=)`;
      case phoneNumberRegex:
        return 'Not a valid phone number';
      case nameRegex:
        return 'Field cannot contain numbers';
      case advancedNameRegex:
        // eslint-disable-next-line prettier/prettier
        return 'Field cannot contain special characters " * / : < > ?  |, control characters ASCII codes 0-31 and 127 or start or end with a . or space';
      case bufferRegex:
        return 'Field can only contain characters ASCII 0x20-0x7E';
      case twoDecimalPlaceRegex:
        return 'Maximum of 2 decimal places allowed';
      case integerOnlyRegex:
        return 'Only integers allowed';
      default:
        return 'Not valid';
    }
  },
  min: (par: { min: number; actual: string }): string => {
    return `Must be a minimum of ${par.min}`;
  },
  minLength: (par: { minLength: number; actual: string }): string => {
    return `Must be a minimum length of ${par.minLength}`;
  },
  max: (par: { max: number; actual: string }): string => {
    return `Must be a maximum of ${par.max}`;
  },
  maxLength: (par: { maxLength: number; actual: string }): string => {
    return `Must be a maximum length of ${par.maxLength}`;
  },
  stepError: (par: { value: number; step: number }): string => {
    return `Value must be a multiple of ${par.step}`;
  },
  step: (par: { value: number; step: number }): string => {
    return `Value must be a multiple of ${par.step}`;
  },
  invalidNumber: (): string => `Value must be a valid number`,
  integer: (): string => {
    return `Value must be an integer`;
  },
  buffer: (): string => {
    return `Buffer already selected`;
  },
};

export const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    Accept: 'application/json',
  }),
  observe: 'response' as const,
};

export const passesLengthValidation = (text: string): boolean => {
  return text.length >= 12;
};

export const passesNumberValidation = (text: string): boolean => {
  return /\d/.test(text);
};

export const passesSpecialCharValidation = (text: string): boolean => {
  return /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(text);
};

export const passesUpperCaseValidation = (text: string): boolean => {
  return /[A-Z]/.test(text);
};

export const passesLowerCaseValidation = (text: string): boolean => {
  return /[a-z]/.test(text);
};
