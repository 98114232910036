import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'localTime',
})
export class LocalDateTimePipe implements PipeTransform {
  transform(value: string | Date): string {
    if (!value) {
      return '';
    }

    let date: Date;

    // If value is a string, ensure it's treated as UTC
    if (typeof value === 'string') {
      // Append 'Z' if it's not already a UTC time with 'Z'
      if (!value.endsWith('Z')) {
        value += 'Z';
      }
      date = new Date(value);
    } else {
      date = value;
    }

    // Check if the date is valid
    if (isNaN(date.getTime())) {
      return value as string; // Return the original value if not a valid date
    }

    // Convert to local time
    return date.toLocaleString();
  }
}
