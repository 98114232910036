<div id="device-setup--container">
  <h4>Device Setup</h4>
  <div class="button--container">
    <app-button
      label="back"
      buttonId="back-btn"
      type="button"
      (buttonClicked)="goBack()"
    ></app-button>
    <app-button
      label="Delete draft"
      buttonId="delete-btn"
      buttonStyle="negative"
      type="button"
      (buttonClicked)="openModal('delete-draft')"
      [isDisabled]="
        isProcessing ||
        deleteIsProcessing ||
        (currentUser.role
          | roleCheck
            : [
                Role.Read_Access_User,
                Role.Run_Access_User,
                Role.Edit_Access_User,
              ])
      "
    ></app-button>
  </div>
</div>
<hr />
@if (experiment) {
  <div id="inner-page--container">
    @if (devices) {
      <div id="device--container">
        @for (device of devices; track device.id) {
          <app-device-setup-row
            [isProcessing]="true"
            [device]="device"
            [deviceComplete]="
              device.deviceStatusId === DeviceSetupStatus['Flasks Inserted']
            "
          ></app-device-setup-row>
        }
      </div>
      <div id="button--container">
        <app-button
          label="start experiment"
          buttonId="start-btn"
          buttonStyle="positive"
          type="submit"
          (buttonClicked)="startExperiment()"
          [isDisabled]="startExperimentButtonDisabled"
          [isProcessing]="isProcessing"
        ></app-button>
      </div>
    }
  </div>
}
@if ((isProcessing || pageIsLoading) && !experiment) {
  <div id="loading--container">
    <app-loading-spinner></app-loading-spinner>
  </div>
}
@if (getExperimentError && !pageIsLoading) {
  <div id="error--container">
    <h4>Something went wrong</h4>
    <p class="error">Error: {{ getExperimentError.message }}</p>
  </div>
}
@if (deviceError && !pageIsLoading) {
  <div id="error--container">
    <p class="error">Error: {{ deviceError.message }}</p>
  </div>
}
<app-modal
  id="experiment-started"
  titleText="Experiment Started"
  animationType="test-tubes"
  buttonType="double-button"
  primaryButtonText="View active experiment"
  primaryButtonId="view-exp-btn"
  secondaryButtonText="Go to dashboard"
  secondaryButtonId="dashboard-btn"
  (modalButtonClicked)="modalButtonClicked($event)"
></app-modal>
<app-modal
  id="error"
  titleText="Error"
  [primaryText]="error?.message"
  buttonType="single-button"
  primaryButtonText="close"
  primaryButtonId="close-button"
  modalStyle="error"
  (modalButtonClicked)="modalButtonClicked($event)"
></app-modal>
<app-validation-modal
  id="delete-draft"
  titleText="Delete draft?"
  validationText="delete"
  buttonType="double-button"
  primaryButtonText="Confirm delete"
  primaryButtonId="confirm-delete-button"
  secondaryButtonText="Cancel"
  secondaryButtonId="cancel-delete-button"
  (modalButtonClicked)="deleteDraft()"
  [error]="deleteError"
></app-validation-modal>
<app-modal
  id="delete-success"
  titleText="Success"
  primaryText="Experiment draft was deleted."
  primaryButtonText="Go to dashboard"
  primaryButtonId="delete-success-button"
  modalStyle="success"
  (modalButtonClicked)="modalButtonClicked($event)"
  allowClose="false"
>
</app-modal>
<app-modal
  id="delete-error"
  titleText="Unable to delete draft"
  [primaryText]="deleteError?.message + '. Choose an action below'"
  buttonType="double-button"
  primaryButtonText="Go to dashboard"
  primaryButtonId="error-primary-button"
  secondaryButtonText="Close"
  secondaryButtonId="error-secondary-button"
  modalStyle="error"
  (modalButtonClicked)="modalButtonClicked($event)"
  allowClose="false"
>
</app-modal>
