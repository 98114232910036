import { Component, Input, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { IconNames } from 'src/app/_components/icons/icon-names';
import { Role } from 'src/app/_models/role';
import { Laboratory } from 'src/app/_models/laboratory';
import { FormBase } from 'src/app/_models/form-base';
import { FormBuilder } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnDestroy {
  private readonly destroy$ = new Subject<void>();
  sidebarCollapsed = true;
  // Permissions
  currentUser = this.authenticationService.currentUserValue;
  Role = Role;
  selectedLab: Laboratory;
  labList: Laboratory[];

  hasLabs =
    this.currentUser.laboratories && this.currentUser.laboratories.length > 0;

  laboratorySelectField = new FormBase<number>({
    key: 'labSelect',
    label: '',
    type: 'select',
    placeholder: 'Select lab',
    disabled: false,
    required: false,
    value: null,
    options: [],
  });

  labSelectForm = this.formBuilder.group({
    [this.laboratorySelectField.key]: [''],
  });

  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly router: Router,
    private readonly formBuilder: FormBuilder
  ) {
    this.selectedLab = this.authenticationService.selectedLaboratory;
    // Subscribe to changes
    this.authenticationService.selectedLab
      .pipe(takeUntil(this.destroy$))
      .subscribe((lab) => {
        this.selectedLab = lab;

        const labInOwnOrg = this.labList?.find(
          (x) => x.laboratoryId === this.selectedLab?.laboratoryId
        );

        const selectLabToReturnOptionIndex =
          this.laboratorySelectField.options.findIndex((x) => x.key === '0');

        // If Ogi_Super_Administrator loads into lab view from a different organisation add additional dropdown option to lab options
        if (!labInOwnOrg && selectLabToReturnOptionIndex === -1) {
          this.laboratorySelectField.options.push({
            key: '0',
            value: 'Select lab to return',
          });
        }

        // Set dropdown value
        if (!labInOwnOrg) {
          this.labSelectForm.controls[this.laboratorySelectField.key].setValue(
            '0'
          );
        } else {
          this.labSelectForm.controls[this.laboratorySelectField.key].setValue(
            this.selectedLab?.laboratoryId?.toString()
          );
        }

        // Remove additional dropdown option in lab options if selected lab is in lablist
        if (labInOwnOrg && selectLabToReturnOptionIndex !== -1) {
          this.laboratorySelectField.options.splice(
            selectLabToReturnOptionIndex,
            1
          );
        }
      });

    // Now grab the lab list from local storage
    this.labList = this.currentUser.laboratories;
    if (this.labList && this.labList.length > 0) {
      this.laboratorySelectField.options = this.labList.map((lab) => {
        return {
          key: lab.laboratoryId.toString(),
          value: lab.laboratoryName + (lab.isOwnLab ? ' - Member' : ''),
        };
      });
      // Let's set the default lab to selectedLab
      this.labSelectForm.controls[this.laboratorySelectField.key].setValue(
        this.selectedLab?.laboratoryId?.toString()
      );
    }

    // Now subscribe to value changes in the lab select form
    this.labSelectForm.valueChanges.subscribe((val) => {
      const labId = val.labSelect;
      const selectedLab = this.labList.find(
        (lab) => lab.laboratoryId === parseInt(labId)
      );
      if (selectedLab && selectedLab !== this.selectedLab) {
        this.authenticationService.setSelectedLaboratory(selectedLab);
        // as lab context has changed, navigate to dashboard
        void this.router.navigate(['/dashboard']);
      }
    });
  }

  /**
   * @param {IconName} logo Logo to be included at the top of the navbar
   */
  @Input()
  logo: IconNames = IconNames.LogoLarge;

  /**
   * @param {IconName} logo Logo to be included at the top of the navbar
   */
  @Input()
  logoSmall: IconNames = IconNames.LogoSmall;

  buttonClicked(): void {
    this.sidebarCollapsed = !this.sidebarCollapsed;
  }

  onLogout(): void {
    this.authenticationService.logout();
    void this.router.navigate(['/login']);
  }

  public get containerClasses(): string {
    return this.sidebarCollapsed
      ? 'container--collapsed'
      : 'container--expanded';
  }

  public get labContainerClasses(): string {
    return this.sidebarCollapsed ? 'lab-container' : 'lab-container--expanded';
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
