import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ExperimentService } from 'src/app/_services/experiment.service';
import { DeviceService } from 'src/app/_services/device.service';
import { Experiment } from 'src/app/_models/experiment';
import { ModalService } from 'src/app/_services/modal.service';
import { Device } from 'src/app/_models/device';
import { ErrorObject } from 'src/app/_models/error';
import { ExperimentStatus } from 'src/app/_models/experiment-status';
import { Subject } from 'rxjs';
import { DeviceSetupStatus } from 'src/app/_models/device-setup-status';
import { Role } from 'src/app/_models/role';
import { User } from 'src/app/_models/user';
import { AuthenticationService } from 'src/app/_services/authentication.service';

@Component({
  selector: 'app-device-setup',
  templateUrl: './device-setup.component.html',
  styleUrls: ['./device-setup.component.scss'],
})
export class DeviceSetupComponent implements OnInit, OnDestroy {
  // Control vars
  getExperimentError: ErrorObject = null;
  deviceError: ErrorObject = null;
  deleteError: ErrorObject = null;
  // Handles update device/start experiment errors
  error: ErrorObject = null;

  pageIsLoading = true;
  isProcessing = false;
  deleteIsProcessing = false;
  startExperimentButtonDisabled = true;
  allowNavigation: Subject<boolean> = new Subject<boolean>();

  ExperimentStatus = ExperimentStatus;
  DeviceSetupStatus = DeviceSetupStatus;
  Role = Role;

  /**
   *  @param {Experiment} experiment The experiment
   */
  experiment: Experiment = null;

  /**
   *  @param {number} experimentId The experiment id
   */
  experimentId: number;

  devices: Device[]; // List of devices assigned to the experiment
  currentUser: User;
  deviceIdList: number[] = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private modalService: ModalService,
    protected experimentService: ExperimentService,
    protected deviceService: DeviceService,
    private authenticationService: AuthenticationService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      this.experimentId = parseInt(params.id as string, 10);
    });

    this.currentUser = this.authenticationService.currentUserValue;

    this.getExperimentFromApi(this.experimentId);
  }

  getExperimentFromApi(experimentId: number): void {
    this.pageIsLoading = true;
    this.getExperimentError = null;
    this.deviceError = null;
    this.experimentService.getExperimentById(experimentId).subscribe({
      next: (response) => {
        this.experiment = response;
        this.pageIsLoading = false;
        switch (this.experiment.experimentStatusId) {
          case ExperimentStatus.DraftLocked:
            if (this.experiment.devices.length === 0) {
              this.deviceError = {
                message:
                  'No devices are assigned to this experiment. Please contact support.',
                status: 400,
              };
              return;
            }
            this.initialiseDevices(this.experiment);
            break;
          case ExperimentStatus.Active:
            this.deviceError = {
              message:
                'This experiment is already active. Please return to dashboard.',
              status: 400,
            };
            return;
          case ExperimentStatus.Complete:
            this.deviceError = {
              message:
                'This experiment is already complete. Please return to dashboard.',
              status: 400,
            };
            break;
          default:
            break;
        }
      },
      error: (error: ErrorObject) => {
        this.getExperimentError = error;
        this.pageIsLoading = false;
      },
    });
  }

  initialiseDevices(experiment: Experiment): void {
    experiment.devices.map((device) => {
      this.deviceIdList.push(device.id);
    });
    this.startPolling();
  }

  startPolling(): void {
    // Start polling
    this.deviceService
      .startDevicePolling(this.deviceIdList)
      .subscribe((response: Device[]) => {
        this.devices = response;
        this.pageIsLoading = false;
        // Check if all flasks are inserted, if so enable start experiment button
        const devicesReady = this.devices.filter(
          (x) => x.deviceStatusId === DeviceSetupStatus['Flasks Inserted']
        );
        if (devicesReady.length === this.devices.length) {
          this.startExperimentButtonDisabled = false;
          this.stopPolling();
        }
      });
  }

  confirmFlaskIsInserted(deviceId: string): void {
    this.error = null;
    this.stopPolling();

    const deviceStatusId = DeviceSetupStatus['Flasks Inserted'];
    // api call - update device status
    this.deviceService
      .updateDeviceStatus(deviceId, { deviceStatusId })
      .subscribe({
        next: () => {
          // Update the device status
          const device = this.devices.find(
            (x) => x.id === parseInt(deviceId, 10)
          );
          device.deviceStatusId = deviceStatusId;
          // If all devices are ready, enable start experiment button
          const devicesReady = this.devices.filter(
            (x) => x.deviceStatusId === DeviceSetupStatus['Flasks Inserted']
          );
          if (devicesReady.length === this.devices.length) {
            this.startExperimentButtonDisabled = false;
          } else {
            this.startExperimentButtonDisabled = true;
            this.startPolling();
          }
        },
        error: (error: ErrorObject) => {
          this.error = error;
          this.openModal('error');
        },
      });
  }

  startExperiment(): void {
    // If form is already being submitted, exit
    if (this.isProcessing) {
      return;
    }

    this.stopPolling();

    this.isProcessing = true;
    this.error = null;
    // To start the experiment the status must be updated to active
    this.experimentService.startExperiment(this.experiment.id).subscribe({
      next: () => {
        this.isProcessing = false;
        this.openModal('experiment-started');
      },
      error: (error: ErrorObject) => {
        this.error = error;
        this.isProcessing = false;
        this.openModal('error');
      },
    });
  }

  deleteDraft(): void {
    // If request has already been submitted, exit
    if (this.isProcessing || this.deleteIsProcessing) {
      return;
    }

    this.stopPolling();

    this.deleteError = null;
    this.deleteIsProcessing = true;

    this.experimentService.deleteExperimentById(this.experimentId).subscribe({
      next: () => {
        this.deleteIsProcessing = false;
        this.closeModal('delete-draft');
        this.openModal('delete-success');
      },
      error: (error: ErrorObject) => {
        this.deleteIsProcessing = false;
        this.deleteError = error;
        this.closeModal('delete-draft');
        this.openModal('delete-error');
        this.startPolling();
      },
    });
  }

  goBack(): void {
    // Route to the set params page
    void this.router.navigate([
      `experiment/${this.experimentId}/select-device`,
    ]);
  }

  openModal(modalId: string): void {
    this.modalService.open(modalId);
  }

  closeModal(modalId: string): void {
    this.modalService.close(modalId);
  }

  stopPolling(): void {
    this.deviceService.stopDevicePolling();
  }

  modalButtonClicked(buttonId: string): void {
    switch (buttonId) {
      case 'dashboard-btn':
        void this.router.navigate(['/dashboard']);
        break;
      case 'view-exp-btn':
        void this.router.navigate([`/experiment/${this.experimentId}/display`]);
        break;
      case 'close-button':
        this.error = null;
        this.closeModal('error');
        break;
      case 'cancel-button':
        this.allowNavigation.next(false);
        this.closeModal('attention');
        break;
      case 'confirm-navigation':
        this.allowNavigation.next(true);
        this.stopPolling();
        this.error = null;
        this.closeModal('attention');
        break;
      case 'delete-success-button':
        this.closeModal('delete-success');
        this.allowNavigation.next(true);
        void this.router.navigate(['dashboard']);
        break;
      case 'error-primary-button':
        this.error = null;
        this.closeModal('delete-error');
        break;
      case 'error-secondary-button':
        this.error = null;
        this.allowNavigation.next(true);
        this.closeModal('delete-error');
        void this.router.navigate(['dashboard']);
        break;
      default:
        break;
    }
  }

  canExit(): Promise<boolean> | boolean {
    return this.isNavigationAllowed();
  }

  // Ensure we always stop polling when navigating away
  private isNavigationAllowed(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      this.stopPolling();
      resolve(true);
    });
  }

  ngOnDestroy(): void {
    this.stopPolling();
  }
}
