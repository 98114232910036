import {
  Component,
  ElementRef,
  Input,
  Output,
  OnInit,
  EventEmitter,
  OnDestroy,
  ViewChild,
  AfterViewInit,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ModalService } from 'src/app/_services/modal.service';
import lottie from 'lottie-web';
import { ErrorObject } from 'src/app/_models/error';

@Component({
  selector: 'app-validation-modal',
  templateUrl: './validation-modal.component.html',
  styleUrls: ['./validation-modal.component.scss'],
})
export class ValidationModalComponent
  implements OnInit, AfterViewInit, OnDestroy {
  private element: HTMLElement;
  private valueEmittedFromChildComponent = '';
  // validationForm: FormGroup;
  buttonIsDisabled = true;

  validationForm = this.fb.group({
    validationText: ['', [Validators.required]],
  });

  /**
   * @param {string} id What is the id of the modal container?
   * @required
   */
  @Input()
  id: string;

  /**
   * @param {string} titleText What is the modal title?
   * @required
   */
  @Input()
  titleText: string;

  /**
   * @param {string} validationText What is the secondary modal text?
   */
  @Input()
  validationText: string;

  /**
   * @param {string} iconName What is the name of the material icon?
   */
  @Input()
  iconName: string;

  /**
   * @param {string} buttonType Does the modal have a single or double button?
   * @required
   */
  @Input()
  buttonType: 'single-button' | 'double-button';

  /**
   * @param {string} primaryButtonText What is the text for the primary button?
   * @required
   */
  @Input()
  primaryButtonText: 'Primary Button';

  /**
   * @param {string} primaryButtonId What is the id for the primary button?
   * @required
   */
  @Input()
  primaryButtonId: string;

  /**
   * @param {string} secondaryButtonText What is the text for the secondary button?
   */
  @Input()
  secondaryButtonText: string;

  /**
   * @param {string} secondaryButtonId What is the id for the secondary button?
   */
  @Input()
  secondaryButtonId: string;

  /**
   * @param {boolean} isProcessing Is the modal processing
   */
  @Input()
  isProcessing = false;

  /**
   * @param {string} animationType Does the modal have an animation?
   * @required
   */
  @Input()
  animationType: 'spinner' | 'test-tubes' | '' = '';

  /**
   * @param {boolean} allowClose Whether user can close modal
   */
  @Input()
  allowClose = true;

  @Input()
  error: ErrorObject;

  /**
   * Output event for clicked button on modal
   */
  // tslint:disable-next-line: no-output-on-prefix
  @Output()
  modalButtonClicked: EventEmitter<string> = new EventEmitter<string>();

  /**
   * @param {ElementRef} animationContainer Reference to html element containing the animation.
   * @required
   */
  @ViewChild('lottieContainer') animationContainer: ElementRef<HTMLElement>;

  constructor(
    private el: ElementRef<HTMLElement>,
    private fb: FormBuilder,
    private modalService: ModalService
  ) {
    this.element = el.nativeElement;
  }

  ngOnInit(): void {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const modal = this;

    if (this.allowClose) {
      // Close modal on background click
      this.element.addEventListener('click', (e: Event) => {
        const target = e.target as HTMLElement;
        if (target.className === 'modal--container') {
          modal.close();
        }
      });
    }

    // add self (this modal instance) to the modal service so it's accessible from controllers
    this.modalService.add(this);

    // Now set validation rules for the modal validation form

    const validationControl = 'validationText';
    this.validationForm.controls[validationControl].valueChanges.subscribe(
      (value) => {
        if (
          value?.toLocaleLowerCase() ===
          this.validationText?.toLocaleLowerCase()
        ) {
          this.buttonIsDisabled = false;
        } else {
          this.buttonIsDisabled = true;
        }
      }
    );
  }

  ngAfterViewInit(): void {
    if (this.animationType === 'test-tubes') {
      this.initAnimation();
    }
  }

  initAnimation(): void {
    const animation = lottie.loadAnimation({
      container: this.animationContainer.nativeElement, // the dom element that will contain the animation
      renderer: 'svg',
      loop: true,
      autoplay: true,
      path: '../../assets/lottie/test-tube.json', // the path to the animation json
    });

    animation.play();
  }

  buttonEventHandler(valueEmitted: string): void {
    this.valueEmittedFromChildComponent = valueEmitted;
    this.modalButtonClicked.emit(valueEmitted);
  }

  // open modal
  open(): void {
    this.element.style.display = 'block';
  }

  // close modal
  close(): void {
    // Reset valdiation text value and close modal
    this.validationForm.reset();
    this.element.style.display = 'none';
  }

  // Remove self from modal service when component is destroyed
  ngOnDestroy(): void {
    this.modalService.remove(this.id);
    this.element.remove();
  }
}
