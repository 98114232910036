<section class="filled-form-field-container">
  <label [ngClass]="classes" [for]="field.key" [formGroup]="parentForm">
    <div *ngIf="iconName" class="filled-form-field-icon-container">
      <mat-icon>{{ iconName }}</mat-icon>
    </div>
    <input
      [ngClass]="inputClasses"
      [type]="showing ? 'text' : field.type"
      [placeholder]="field.placeholder"
      [formControlName]="field.key"
      [autocomplete]="field.autocomplete"
      [attr.aria-required]="field.required"
    />
    <span
      class="filled-form-field-show body2"
      *ngIf="field.type === 'password'"
      (click)="togglePassword()"
      >{{ showHideText }}</span
    >
    <div
      class="filled-form-field-right-icon"
      *ngIf="
        !hasErrors &&
        parentForm.controls[this.field.key]?.touched &&
        field.type !== 'password'
      "
    >
      <mat-icon>check_circle</mat-icon>
    </div>
    <div
      class="filled-form-field-right-icon"
      *ngIf="hasErrors && field.type !== 'password'"
    >
      <mat-icon>cancel</mat-icon>
    </div>
  </label>
  <ul *ngIf="hasErrors">
    <li class="filled-form-field-error body2" *ngFor="let error of errorsList">
      {{ error }}
    </li>
  </ul>
</section>
