<div id="dashboard--container">
  <div id="header--container">
    <h4>Welcome to your dashboard.</h4>
    <h5>{{ laboratory?.laboratoryName }}</h5>
  </div>
  <hr class="alt" />
  <div id="nav--container">
    <app-option-tab-toggle
      id="toggle--container"
      radioGroup="navigationToggle"
      [selectOptions]="navOptions"
      type="round"
    >
    </app-option-tab-toggle>
    <app-search-field
      [field]="searchField"
      [parentForm]="experimentTableControls"
      (buttonClicked)="getExperiments()"
    >
    </app-search-field>
  </div>
  <div id="inner-page--container" *ngIf="experiments?.length > 0">
    <p id="table-description" style="display: none">
      Experiments summary table
    </p>
    <div id="table--container">
      <table aria-describedby="table-description">
        <thead>
          <tr>
            <th
              id="experiment"
              scope="col"
              (click)="onSort('Name')"
              [ngClass]="getClasses('Name')"
            >
              <div id="table-header">
                <span>Experiment</span>
                <mat-icon
                  aria-label="sort direction"
                  matTooltip="Sort direction"
                  >swap_vert</mat-icon
                >
              </div>
            </th>
            <th
              id="startDate"
              scope="col"
              (click)="onSort('StartTime')"
              [ngClass]="getClasses('StartTime')"
            >
              <div id="table-header">
                <span>Start Date</span>
                <mat-icon
                  aria-label="sort direction"
                  matTooltip="Sort direction"
                  >swap_vert</mat-icon
                >
              </div>
            </th>
            <th
              id="time"
              scope="col"
              (click)="onSort('TimeLeft')"
              [ngClass]="getClasses('TimeLeft')"
            >
              <div id="table-header">
                <span>Time Left</span>
                <mat-icon
                  aria-label="sort direction"
                  matTooltip="Sort direction"
                  >swap_vert</mat-icon
                >
              </div>
            </th>
            <th
              id="status"
              scope="col"
              (click)="onSort('ExperimentStatusId')"
              [ngClass]="getClasses('ExperimentStatusId')"
            >
              <div id="table-header">
                <span>Status</span>
                <mat-icon
                  aria-label="sort direction"
                  matTooltip="Sort direction"
                  >swap_vert</mat-icon
                >
              </div>
            </th>
            <th
              id="createdBy"
              scope="col"
              (click)="onSort('CreatedBy')"
              [ngClass]="getClasses('CreatedBy')"
            >
              <div id="table-header">
                <span>Created By</span>
                <mat-icon
                  aria-label="sort direction"
                  matTooltip="Sort direction"
                  >swap_vert</mat-icon
                >
              </div>
            </th>
            <th id="view" scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let experiment of experiments; let i = index">
            <td headers="experiment">{{ experiment.name }}</td>
            <td headers="startDate">
              {{
                experiment.startTime
                  ? (experiment.startTime | date : "short")
                  : "-"
              }}
            </td>
            <td
              headers="time"
              *ngIf="
                experiment.experimentStatusId === ExperimentStatus.Active &&
                  currentTime$ | async as t;
                else complete
              "
            >
              {{
                getTimeLeft(experiment.startTime, experiment.duration, t).days
                  | number : "2.0"
              }}
              :
              {{
                getTimeLeft(experiment.startTime, experiment.duration, t).hours
                  | number : "2.0"
              }}
              :
              {{
                getTimeLeft(experiment.startTime, experiment.duration, t)
                  .minutes | number : "2.0"
              }}
              :
              {{
                getTimeLeft(experiment.startTime, experiment.duration, t)
                  .seconds | number : "2.0"
              }}
            </td>
            <ng-template #complete>
              <td headers="time">-</td>
            </ng-template>
            <td headers="status">
              <app-status-indicator
                [status]="ExperimentStatus[experiment.experimentStatusId]"
              ></app-status-indicator>
            </td>
            <td headers="createdBy">{{ experiment.createdBy }}</td>
            <td headers="view">
              <div>
                <app-button
                  *ngIf="
                    experiment.experimentStatusId === ExperimentStatus.Draft
                  "
                  label="View Draft"
                  buttonId="experiment_{{ experiment?.id }}"
                  [routerLink]="
                    '/experiment/' + experiment.id + '/set-parameters'
                  "
                  [isDisabled]="
                    currentUser.role | roleCheck : [Role.Read_Access_User]
                  "
                ></app-button>
                <app-button
                  *ngIf="
                    experiment.experimentStatusId ===
                    ExperimentStatus.DraftLocked
                  "
                  label="View Draft"
                  buttonId="experiment_{{ experiment?.id }}"
                  [routerLink]="
                    '/experiment/' + experiment.id + '/device-setup'
                  "
                  [isDisabled]="
                    currentUser.role | roleCheck : [Role.Read_Access_User]
                  "
                ></app-button>
                <app-button
                  *ngIf="
                    experiment.experimentStatusId === ExperimentStatus.Active ||
                    experiment.experimentStatusId ===
                      ExperimentStatus.Complete ||
                    experiment.experimentStatusId === ExperimentStatus.Paused
                  "
                  label="View"
                  buttonId="experiment_{{ experiment?.id }}"
                  [routerLink]="'/experiment/' + experiment.id + '/display'"
                ></app-button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <div id="pagination--container">
        <div class="experiment-status-filter--container">
          <label>Filter by experiment status</label>
          <app-dropdown-form-field
            id="experiment-status-filter"
            [field]="experimentStatusFilters"
            [parentForm]="experimentTableControls"
          >
          </app-dropdown-form-field>
        </div>
        <div class="experiment-status-filter--container">
          <legend>Experiments per page</legend>
          <app-dropdown-form-field
            id="experiments-per-page"
            [field]="experimentsPerPageField"
            [parentForm]="experimentTableControls"
          >
          </app-dropdown-form-field>
        </div>
        <div id="pagination--controls">
          <app-icon-button
            iconName="chevron_left"
            buttonId="previous"
            (buttonClicked)="getPrevious()"
            [isDisabled]="!hasPrevious"
          ></app-icon-button>
          <span> {{ currentPage }} / {{ totalPages }} </span>
          <app-icon-button
            iconName="chevron_right"
            buttonId="next"
            (buttonClicked)="getNext()"
            [isDisabled]="!hasNext"
          ></app-icon-button>
        </div>
      </div>
    </div>
  </div>
  <div
    id="cta--container"
    *ngIf="!loading && (!experiments || experiments.length === 0)"
  >
    <h4>No experiments to display</h4>
    <app-icon-button
      label="reset"
      buttonStyle="secondary"
      buttonId="filter-btn"
      iconName="restart_alt"
      buttonShape="square"
      (buttonClicked)="resetSearch()"
    ></app-icon-button>
    <p *ngIf="error" class="error">Error: {{ error.message }}</p>
  </div>
  <div
    id="loading--container"
    *ngIf="loading && (!experiments || experiments.length === 0)"
  >
    <app-loading-spinner></app-loading-spinner>
  </div>
</div>
