import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { LayoutModule } from '@angular/cdk/layout';
import { environment } from 'src/environments/environment';
import { CanDeactivateGuard } from './_guards/can-deactivate.guard';
import { ButtonComponent } from './_components/buttons/button/button.component';
import {
  DevicesComponent,
  ExperimentsComponent,
  DashboardComponent,
  ErrorLogsComponent,
} from './dashboard/dashboard.component';
import { IconButtonComponent } from './_components/buttons/icon-button/icon-button.component';
import { LoginComponent } from './account/login/login.component';
import { ForgotPasswordComponent } from './account/forgot-password/forgot-password.component';
import { ResetSentComponent } from './account/reset-sent/reset-sent.component';
import { ResetPasswordComponent } from './account/reset-password/reset-password.component';
import { ResetSuccessfulComponent } from './account/reset-successful/reset-successful.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ErrorInterceptor } from './_helpers/error.interceptor';
import { JwtInterceptor } from './_helpers/jwt.interceptor';
import { NavbarComponent } from './_components/navbar/navbar.component';
import { SvgTemplateComponent } from './_components/svg-template/svg-template.component';
import { FilledFormFieldComponent } from './_components/forms/filled-form-field/filled-form-field.component';
import { CheckboxComponent } from './_components/selection-controls/checkbox/checkbox.component';
import { UnderlinedFormFieldComponent } from './_components/forms/underlined-form-field/underlined-form-field.component';
import { CheckboxGroupNoLabelComponent } from './_components/selection-controls/checkbox-group-no-label/checkbox-group-no-label.component';
import { CheckboxGroupComponent } from './_components/selection-controls/checkbox-group/checkbox-group.component';
import { SelectDeviceComponent } from './experiments/select-device/select-device.component';
import { OptionTabToggleComponent } from './_components/selection-controls/option-tab-toggle/option-tab-toggle.component';
import {
  DisplayExperimentComponent,
  ControlExperimentComponent,
} from './experiments/active-experiment/active-experiment.component';
import { SmallDataCardComponent } from './_components/cards/small-data-card/small-data-card.component';
import { BasicCardComponent } from './_components/cards/basic-card/basic-card.component';
import { DeviceInfoCardComponent } from './_components/cards/device-info-card/device-info-card.component';
import { ReducedSmallDataCardComponent } from './_components/cards/reduced-small-data-card/reduced-small-data-card.component';
import { GraphComponent } from './_components/graph/graph/graph.component';
import { DropdownFormFieldComponent } from './_components/forms/dropdown-form-field/dropdown-form-field.component';
import { UnderlinedTextareaFieldComponent } from './_components/forms/underlined-textarea-field/underlined-textarea-field.component';
import { RadioButtonComponent } from './_components/selection-controls/radio-button/radio-button.component';
import { IconUnderlinedFormFieldComponent } from './_components/forms/icon-underlined-form-field/icon-underlined-form-field.component';
import { CreateCalibrationComponent } from './calibration/create-calibration/create-calibration.component';
import { CalibrationDataComponent } from './calibration/calibration-data/calibration-data.component';
import { SetParametersComponent } from './experiments/set-parameters/set-parameters.component';
import { UnderlinedDropdownFormFieldComponent } from './_components/forms/underlined-dropdown-form-field/underlined-dropdown-form-field.component';
import { DynamicDropdownFormFieldComponent } from './_components/forms/dynamic-dropdown-form-field/dynamic-dropdown-form-field.component';
import { ModalComponent } from './_components/modals/modal.component';
import { DeviceSetupComponent } from './experiments/device-setup/device-setup.component';
import { DeviceSetupRowComponent } from './_components/device-setup-row/device-setup-row.component';
import { ValidationModalComponent } from './_components/modals/validation-modal/validation-modal.component';
import { LoadingSpinnerComponent } from './_components/loading-spinner/loading-spinner.component';
import { MatSliderModule } from '@angular/material/slider';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NavButtonComponent } from './_components/buttons/nav-button/nav-button.component';
import { MatIconModule } from '@angular/material/icon';
import { UpdateDetailsComponent } from './user/update-details/update-details.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { SlideToggleComponent } from './_components/selection-controls/slide-toggle/slide-toggle.component';
import { AddUserComponent } from './user/add-user/add-user.component';
import { ActiveModulesComponent } from './_components/chips/active-modules/active-modules.component';
import { GraphCheckboxGroupComponent } from './_components/selection-controls/graph-checkbox-group/graph-checkbox-group.component';
import { AddUserConfirmationComponent } from './account/add-user-confirmation/add-user-confirmation.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatButtonModule } from '@angular/material/button';
import { AddDeviceComponent } from './devices/add-device/add-device.component';
import { DeviceDetailsComponent } from './devices/device-details/device-details.component';
import { CalibrationGraphComponent } from './_components/graph/calibration-graph/calibration-graph.component';
import { SmallLoadingSpinnerComponent } from './_components/small-loading-spinner/small-loading-spinner.component';
import { OxygenCalibrationGraphComponent } from './_components/graph/oxygen-calibration-graph/oxygen-calibration-graph.component';
import { PhCalibrationGraphComponent } from './_components/graph/ph-calibration-graph/ph-calibration-graph.component';
import { StatusIndicatorComponent } from './_components/chips/status-indicator/status-indicator.component';
import { CreateBufferComponent } from './buffer/create-buffer/create-buffer.component';
import { ViewBuffersComponent } from './buffer/view-buffers/view-buffers.component';
import { RoleCheckPipe } from './_helpers/role-check.pipe';
import {
  ApprovalsComponent,
  DevicesApprovalComponent,
  LabsApprovalComponent,
  UsersApprovalComponent,
  OrganisationsApprovalComponent,
  BuffersApprovalComponent,
} from './approvals/approvals.component';
import { AddLabComponent } from './laboratories/add-lab/add-lab.component';
import { LabDetailsComponent } from './laboratories/lab-details/lab-details.component';
import { FormatRolePipe } from './_helpers/format-role.pipe';
import { RoleEnumToStringPipe } from './_helpers/role-enum-to-string.pipe';
import { ProvideInfoModalComponent } from './_components/modals/provide-info-modal/provide-info-modal.component';
import { UpdateUserComponent } from './user/update-user/update-user.component';
import { FormatCamelCasePipe } from './_helpers/format-camel-case.pipe';
import {
  DeviceCalibrationsComponent,
  OdCalibrationsComponent,
  OxygenCalibrationsComponent,
  PhCalibrationsComponent,
} from './devices/device-calibrations/device-calibrations.component';
import { OrganisationDetailsComponent } from './organisations/organisation-details/organisation-details.component';
import { OverviewComponent } from './organisations/overview/overview.component';
import { CreateOrganisationComponent } from './organisations/create-organisation/create-organisation.component';
import { ErrorPageComponent } from './_components/error-page/error-page.component';
import { LocalDateTimePipe } from './_helpers/local-time.pipe';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { DeviceCardComponent } from './_components/cards/device-card/device-card.component';
import { InstalledModulesCardComponent } from './_components/cards/installed-modules-card/installed-modules-card.component';
import { FilterModalComponent } from './_components/modals/filter-modal/filter-modal.component';
import { SearchFieldComponent } from './_components/forms/search-field/search-field.component';
import { MatMenuModule } from '@angular/material/menu';
import { SlideToggleGroupComponent } from './_components/selection-controls/slide-toggle-group/slide-toggle-group.component';

@NgModule({
  declarations: [
    AppComponent,
    ButtonComponent,
    DashboardComponent,
    DevicesComponent,
    ExperimentsComponent,
    ErrorLogsComponent,
    IconButtonComponent,
    LoginComponent,
    ForgotPasswordComponent,
    ResetSentComponent,
    ResetPasswordComponent,
    ResetSuccessfulComponent,
    NavbarComponent,
    SvgTemplateComponent,
    FilledFormFieldComponent,
    CheckboxComponent,
    UnderlinedFormFieldComponent,
    CheckboxGroupNoLabelComponent,
    StatusIndicatorComponent,
    CheckboxGroupComponent,
    SelectDeviceComponent,
    OptionTabToggleComponent,
    DisplayExperimentComponent,
    ControlExperimentComponent,
    SmallDataCardComponent,
    BasicCardComponent,
    DeviceInfoCardComponent,
    ReducedSmallDataCardComponent,
    GraphComponent,
    CalibrationGraphComponent,
    DropdownFormFieldComponent,
    UnderlinedTextareaFieldComponent,
    RadioButtonComponent,
    IconUnderlinedFormFieldComponent,
    CreateCalibrationComponent,
    CalibrationDataComponent,
    SetParametersComponent,
    UnderlinedDropdownFormFieldComponent,
    DynamicDropdownFormFieldComponent,
    ModalComponent,
    DeviceSetupComponent,
    DeviceSetupRowComponent,
    ValidationModalComponent,
    LoadingSpinnerComponent,
    NavButtonComponent,
    UpdateDetailsComponent,
    SlideToggleComponent,
    NavButtonComponent,
    AddUserComponent,
    ActiveModulesComponent,
    GraphCheckboxGroupComponent,
    AddUserConfirmationComponent,
    AddDeviceComponent,
    DeviceDetailsComponent,
    SmallLoadingSpinnerComponent,
    OxygenCalibrationGraphComponent,
    PhCalibrationGraphComponent,
    StatusIndicatorComponent,
    CreateBufferComponent,
    ViewBuffersComponent,
    RoleCheckPipe,
    FormatRolePipe,
    RoleEnumToStringPipe,
    ApprovalsComponent,
    DevicesApprovalComponent,
    LabsApprovalComponent,
    UsersApprovalComponent,
    OrganisationsApprovalComponent,
    BuffersApprovalComponent,
    AddLabComponent,
    LabDetailsComponent,
    ProvideInfoModalComponent,
    UpdateUserComponent,
    FormatCamelCasePipe,
    OdCalibrationsComponent,
    DeviceCalibrationsComponent,
    OxygenCalibrationsComponent,
    PhCalibrationsComponent,
    OrganisationDetailsComponent,
    OverviewComponent,
    CreateOrganisationComponent,
    ErrorPageComponent,
    LocalDateTimePipe,
    DeviceCardComponent,
    InstalledModulesCardComponent,
    FilterModalComponent,
    SearchFieldComponent,
  ],
  imports: [
    BrowserModule,
    LayoutModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    AppRoutingModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    MatSliderModule,
    BrowserAnimationsModule,
    MatIconModule,
    MatSlideToggleModule,
    MatButtonToggleModule,
    MatExpansionModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatTooltipModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatMenuModule,
    SlideToggleGroupComponent,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    CanDeactivateGuard,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
