<label
  [for]="checkbox.id"
  class="checkbox--label body1"
  [class.disabled]="isDisabled"
  [formGroup]="parentForm"
>
  <input
    type="checkbox"
    [id]="checkbox.id"
    [formControlName]="checkbox.id"
    (change)="onValueChange($event)"
  />
  <div class="checkmark--background">
    <div class="checkmark">
      <mat-icon>done</mat-icon>
    </div>
  </div>
  {{ checkbox.labelText }}
</label>
