import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { Device } from 'src/app/_models/device';
import { getConvertedTimeStringFromMs } from 'src/app/_helpers/utils';
import { DeviceSetupStatus } from 'src/app/_models/device-setup-status';
import { Experiment } from 'src/app/_models/experiment';
import { SmallDataCardComponent } from '../small-data-card/small-data-card.component';
import { IconButtonComponent } from '../../buttons/icon-button/icon-button.component';
import { BasicCardComponent } from '../basic-card/basic-card.component';
import { MatIcon } from '@angular/material/icon';
import { DatePipe } from '@angular/common';
import { StatusIndicatorComponent } from '../../chips/status-indicator/status-indicator.component';

@Component({
  selector: 'app-device-info-card',
  templateUrl: './device-info-card.component.html',
  styleUrls: ['./device-info-card.component.scss'],
  standalone: true,
  imports: [
    MatIcon,
    BasicCardComponent,
    IconButtonComponent,
    SmallDataCardComponent,
    DatePipe,
    StatusIndicatorComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeviceInfoCardComponent {
  DeviceStatus = DeviceSetupStatus;
  /**
   * @param {Device} device The device to be displayed
   * @requires
   */
  @Input()
  device: Device;

  /**
   * @param {string} selectedDataCategory Which data category to display
   * @requires
   */
  @Input()
  selectedDataCategory: string;

  /**
   * @param {Experiment} experiment Exxperiment object to extract values from per flask
   * @requires
   */
  @Input()
  experiment: Experiment;

  /**
   * @param {Date} lastUpdated Time of last fetch
   * @requires
   */
  @Input()
  lastUpdateTime: Date;

  @Output()
  pauseDeviceClicked: EventEmitter<number> = new EventEmitter<number>();

  getConvertedTime(timeInMs: number): string {
    // Use utility helper func to convert time in ms to time object and return formatted string
    const timeObject = getConvertedTimeStringFromMs(timeInMs);
    return `${timeObject.days}:${timeObject.hours}:${timeObject.minutes}:${timeObject.seconds}`;
  }

  public get statusClass(): string {
    const indicator = this.device?.deviceStatusId;
    switch (indicator) {
      case DeviceSetupStatus.Active:
        return DeviceSetupStatus[1].toString();
      case DeviceSetupStatus.Paused:
        return DeviceSetupStatus[10].toString();
      default:
        return '';
    }
  }

  clickButton = (value: number): void => {
    this.pauseDeviceClicked.emit(value);
  };
}
