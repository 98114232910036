<label
  [for]="option.key"
  class="checkbox--label body1"
  [class.disabled]="isDisabled"
  [attr.aria-label]="option.key"
>
  <input
    type="checkbox"
    [id]="option.key"
    [attr.name]="option.key"
    class="body1"
    (change)="updateValue($event)"
    [checked]="getChecked() === true ? true : null"
    [disabled]="isDisabled"
  />
  <div class="checkmark--background">
    <div class="checkmark">
      <mat-icon>done</mat-icon>
    </div>
  </div>
</label>
