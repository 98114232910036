<div class="icons">
  @for (module of activeModules; track module) {
    <div>
      @switch (module) {
        @case (Module.BaseModule) {
          <svg
            [attr.width]="width"
            [attr.height]="height"
            [attr.viewBox]="customViewBox"
            xmlns="http://www.w3.org/2000/svg"
            [innerHTML]="icon(opticalDensity)"
            role="img"
          ></svg>
        }
        @case (Module.Oxygen) {
          <svg
            [attr.width]="width"
            [attr.height]="height"
            [attr.viewBox]="customViewBox"
            xmlns="http://www.w3.org/2000/svg"
            [innerHTML]="icon(oxygen)"
            role="img"
          ></svg>
        }
        @case (Module.Fluorescence) {
          <svg
            [attr.width]="width"
            [attr.height]="height"
            [attr.viewBox]="customViewBox"
            xmlns="http://www.w3.org/2000/svg"
            [innerHTML]="icon(fluo)"
            role="img"
          ></svg>
        }
        @case (Module.LiquidControl) {
          <svg
            [attr.width]="width"
            [attr.height]="height"
            [attr.viewBox]="customViewBox"
            xmlns="http://www.w3.org/2000/svg"
            [innerHTML]="icon(liquidControl)"
            role="img"
          ></svg>
        }
        @case (Module.AirControl) {
          <svg
            [attr.width]="width"
            [attr.height]="height"
            [attr.viewBox]="customViewBox"
            xmlns="http://www.w3.org/2000/svg"
            [innerHTML]="icon(airControl)"
            role="img"
          ></svg>
        }
        @case (Module.pH) {
          <svg
            [attr.width]="width"
            [attr.height]="height"
            [attr.viewBox]="customViewBox"
            xmlns="http://www.w3.org/2000/svg"
            [innerHTML]="icon(pH)"
            role="img"
          ></svg>
        }
      }
    </div>
  }
</div>
