import { Component, OnInit } from '@angular/core';
import { FormBase } from 'src/app/_models/form-base';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalService } from 'src/app/_services/modal.service';
import { ErrorObject } from 'src/app/_models/error';
import { User, UserUpdate } from 'src/app/_models/user';
import { UserService } from 'src/app/_services/user.service';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { MustMatch } from 'src/app/_helpers/must-match.validator';
import { Subject } from 'rxjs';
import { passwordRegex } from 'src/app/_helpers/utils';
import {
  UserDeleteResponse,
  UserUpdateResponse,
} from 'src/app/_models/api-responses';
import { Role } from 'src/app/_models/role';
import { Router } from '@angular/router';

@Component({
  selector: 'app-update-details',
  templateUrl: './update-details.component.html',
  styleUrls: ['./update-details.component.scss'],
})
export class UpdateDetailsComponent implements OnInit {
  // Control vars
  error: ErrorObject = null;
  currentUser: User;
  updateIsProcessing = false;
  passwordIsProcessing = false;
  updateUserSuccessMessage: string;
  Role = Role;

  allowNavigation: Subject<boolean> = new Subject<boolean>();

  firstNameField = new FormBase({
    key: 'firstName',
    label: 'First Name',
    type: 'text',
    placeholder: 'First name',
    disabled: false,
    required: true,
    value: '',
    options: [],
  });

  lastNameField = new FormBase({
    key: 'lastName',
    label: 'Last Name',
    type: 'text',
    placeholder: 'Last Name',
    disabled: false,
    required: true,
    value: '',
    options: [],
  });

  passwordField = new FormBase({
    key: 'password',
    label: 'Current Password',
    type: 'password',
    placeholder: 'Password',
    disabled: false,
    required: true,
    value: '',
    options: [],
    autocomplete: 'current-password',
  });

  newPasswordField = new FormBase({
    key: 'newPassword',
    label: 'New Password',
    type: 'password',
    placeholder: 'New password',
    disabled: false,
    required: false,
    value: '',
    options: [],
    autocomplete: 'new-password',
  });

  confirmNewPasswordField = new FormBase({
    key: 'confirmNewPassword',
    label: 'Confirm New Password',
    type: 'password',
    placeholder: 'Confirm new password',
    disabled: false,
    required: false,
    value: '',
    options: [],
  });

  updateDetailsForm = this.formBuilder.group(
    {
      [this.firstNameField.key]: ['', [Validators.min(1), Validators.max(100)]],
      [this.lastNameField.key]: ['', [Validators.min(1), Validators.max(100)]],
    },
    {
      validators: MustMatch(
        this.newPasswordField.key,
        this.confirmNewPasswordField.key
      ),
    }
  );

  updatePasswordForm = this.formBuilder.group(
    {
      [this.passwordField.key]: ['', [Validators.required]],
      [this.newPasswordField.key]: [
        '',
        [Validators.required, Validators.pattern(passwordRegex)],
      ],
      [this.confirmNewPasswordField.key]: [
        '',
        [Validators.required, Validators.pattern(passwordRegex)],
      ],
    },
    {
      validators: MustMatch(
        this.newPasswordField.key,
        this.confirmNewPasswordField.key
      ),
    }
  );

  constructor(
    protected formBuilder: FormBuilder,
    protected modalService: ModalService,
    protected userService: UserService,
    protected authService: AuthenticationService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.currentUser = this.authService.currentUserValue;
    this.getUser();
  }

  getUser(): void {
    this.userService.getUserDetails(this.currentUser.id).subscribe({
      next: (data: User) => {
        this.currentUser = data;
        this.updateFormValues(this.currentUser);
      },
      error: (error: ErrorObject) => {
        this.error = error;
        this.openModal('error');
      },
    });
  }

  openModal(modalId: string): void {
    this.modalService.open(modalId);
  }

  closeModal(modalId: string): void {
    this.modalService.close(modalId);
  }

  modalButtonClicked(buttonId: string): void {
    switch (buttonId) {
      case 'update-successful':
        this.closeModal('update-successful');
        break;
      case 'close-button':
        this.closeModal('update-account');
        this.closeModal('error');
        this.closeModal('update-password');
        break;
      case 'cancel-button':
        this.allowNavigation.next(false);
        this.closeModal('attention');
        break;
      case 'confirm-navigation':
        this.allowNavigation.next(true);
        this.closeModal('attention');
        break;
      case 'logout':
        void this.router.navigate(['/login']);
        break;
    }
  }

  get f(): FormGroup['controls'] {
    return this.updateDetailsForm.controls;
  }

  get pf(): FormGroup['controls'] {
    return this.updatePasswordForm.controls;
  }

  updateFormValues(userDetails: User): void {
    this.updateDetailsForm.reset();
    this.updateDetailsForm.patchValue({
      [this.firstNameField.key]: userDetails.firstName,
      [this.lastNameField.key]: userDetails.lastName,
      [this.passwordField.key]: '',
      [this.newPasswordField.key]: '',
      [this.confirmNewPasswordField.key]: '',
    });
    this.updateDetailsForm.markAsPristine();
  }

  onSubmit(): void {
    // If form is already being submitted, exit
    if (this.updateIsProcessing || this.passwordIsProcessing) {
      return;
    }

    this.updateIsProcessing = true;

    const userUpdate: UserUpdate = {
      id: this.currentUser.id,
    };
    if (this.f.firstName.value !== this.currentUser.firstName) {
      userUpdate.firstName = this.f.firstName.value as string;
    }
    if (this.f.lastName.value !== this.currentUser.lastName) {
      userUpdate.lastName = this.f.lastName.value as string;
    }
    this.userService
      .updateUser(userUpdate)
      .pipe()
      .subscribe({
        next: (data: UserUpdateResponse) => {
          this.updateIsProcessing = false;
          this.updateUserSuccessMessage = data.message;
          this.openModal('update-account');
          this.currentUser.firstName = data.user.firstName;
          this.currentUser.lastName = data.user.lastName;
          this.updateDetailsForm.markAsPristine();
        },
        error: (error: ErrorObject) => {
          this.error = error;
          this.updateIsProcessing = false;
          this.openModal('error');
        },
      });
  }

  onSubmitPassword(): void {
    // If form is already being submitted, exit
    if (this.passwordIsProcessing || this.updateIsProcessing) {
      return;
    }

    this.passwordIsProcessing = true;

    this.userService
      .updatePassword(
        this.currentUser.id,
        this.pf.password.value as string,
        this.pf.newPassword.value as string
      )
      .pipe()
      .subscribe({
        next: () => {
          this.passwordIsProcessing = false;
          this.openModal('update-password');
        },
        error: (error: ErrorObject) => {
          this.error = error;
          this.passwordIsProcessing = false;
          this.openModal('error');
        },
      });
  }

  deleteAccount(): void {
    this.error = null;
    // Response may be 204 in the case that an approval is pending
    // If 200, logout and navigate to login
    // If 204, open modal to confirm request is pending
    this.userService.deleteUser(this.currentUser.id).subscribe({
      next: (data: UserDeleteResponse) => {
        this.closeModal('delete-account');
        if (data.logout) {
          this.authService.logout();
          // Add modal to confirm logout
          this.openModal('logout');
          return;
        }
        this.updateUserSuccessMessage = data.message;
        this.openModal('success');
      },
      error: (error: ErrorObject) => {
        this.error = error;
        this.closeModal('delete-account');
        this.openModal('error');
      },
    });
  }

  canExit(): Promise<boolean> | boolean {
    return this.isNavigationAllowed();
  }

  private isNavigationAllowed(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      if (this.updateDetailsForm.pristine) {
        resolve(true);
      } else {
        this.openModal('attention');
        this.allowNavigation.subscribe((isConfirmed) => resolve(isConfirmed));
      }
    });
  }
}
